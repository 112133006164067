/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { isAccountRep, isAdmin } from '../../Utils';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { DocsIcon, RightArrowIcon } from '../../components/ui/icons';
import { AuthContext } from '../../components/AuthContext';

const linkStyle = {
  alignItems: 'center',
  borderBottom: '1px solid #e5e5ea',
  display: 'flex',
  fontSize: 16,
  padding: 16,
  color: '#663cff',
  transition: 'all 0.02s',
  svg: {
    marginRight: 8,
    transform: 'scale(1.2)',
    ':last-child': {
      marginRight: 0,
      marginLeft: 16,
    },
  },
  ':last-child': {
    borderBottom: 'none',
  },
  '&:hover': { color: '#0828cc' },
};

const fileList = {
  marginTop: 16,
  a: linkStyle,
  button: linkStyle,
};

export default () => {
  const { roles } = useContext(AuthContext);

  return (
    <Fragment>
      {
        <Panel styles={{ margin: 40 }}>
          <PanelHeader text="Reports" largeText />
          <div css={fileList}>
            <Link to="/reports/outstanding-loans">
              <DocsIcon />
              Outstanding Loans (Excluding Loans In The Pipeline)
              <RightArrowIcon />
            </Link>
            <Link to="reports/not-founds">
              <DocsIcon />
              Not Founds
              <RightArrowIcon />
            </Link>
            <Link to="/reports/shipment-tracking">
              <DocsIcon />
              Shipping Tracking Report
              <RightArrowIcon />
            </Link>
            <Link to="/reports/correction-credits">
              <DocsIcon />
              Correction Credits
              <RightArrowIcon />
            </Link>
            {isAdmin(roles) && (
              <Link to="/reports/loans-received-count">
                <DocsIcon />
                Loans Received Count (By Client)
                <RightArrowIcon />
              </Link>
            )}
            {isAccountRep(roles) && (
              <Link to="/reports/corrections-pipeline">
                <DocsIcon />
                Corrections Pipeline
                <RightArrowIcon />
              </Link>
            )}
          </div>
        </Panel>
      }
    </Fragment>
  );
};
