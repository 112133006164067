/** @jsx jsx */
import { jsx } from '@emotion/core';
import DataTable from '../ui/DataTable/DataTable';

export type RowData = {
  loanNumber: string;
  clientId: number;
  borrowerFullName: string;
  propertyAddress: string;
  propertyCity: string;
  propertyState: string;
  propertyZip: string;
  propertyType: string;
  propertyCounty: string;
  investorName: string;
  servicer: string;
  subServicer: string;
  custodian: string;
  trailingDocsAddress: string;
  investorCommitmentNumber: string;
  loanAmount: number;
  paymentStream: string;
  dateDocumentsDrawn: string;
  titleCompanyName: string;
  titleOrderNumber: string;
  titleCompanyAddress: string;
  titleCompanyCity: string;
  titleCompanyState: string;
  titleCompanyZip: string;
  titleCompanyPhone: string;
  titleCompanyFax: string;
  titleCompanyEmail: string;
  additionalInformation: string;
  documentUploadLocation: string;
  poa: number;
  cema: number;
  mortgage: number;
  policy: number;
  secondMortgage: number;
  assignment: number;
  mortgageType: string;
  isGinnieMaeLoanType: boolean;
  poolNumber: string;
  lienPosition: number;
};

type Props = {
  datatableData: RowData[];
  exportFileName?: string;
};

const columns = [
  {
    field: 'loanNumber',
    title: 'Loan Number',
  },
  {
    field: 'clientId',
    title: 'Client Id',
  },
  {
    field: 'borrowerFullName',
    title: 'Borrower Full Name',
  },
  {
    field: 'propertyAddress',
    title: 'Property Address',
  },
  {
    field: 'propertyCity',
    title: 'Property City',
  },
  {
    field: 'propertyState',
    title: 'Property State',
  },
  {
    field: 'propertyZip',
    title: 'Property Zip',
  },
  {
    field: 'propertyType',
    title: 'Property Type',
  },
  {
    field: 'propertyCounty',
    title: 'Property County',
  },
  {
    field: 'investorName',
    title: 'Investor Name',
  },
  {
    field: 'servicer',
    title: 'Servicer',
  },
  {
    field: 'subServicer',
    title: 'SubServicer',
  },
  {
    field: 'custodian',
    title: 'Custodian',
  },
  {
    field: 'trailingDocsAddress',
    title: 'Trailing Docs Address',
  },
  {
    field: 'investorCommitmentNumber',
    title: 'Investor Commitment Number',
  },
  {
    field: 'loanAmount',
    title: 'Loan Amount',
  },
  {
    field: 'paymentStream',
    title: 'Payment Stream',
  },
  {
    field: 'dateDocumentsDrawn',
    title: 'Date Documents Drawn',
  },
  {
    field: 'titleCompanyName',
    title: 'Title Company Name',
  },
  {
    field: 'titleOrderNumber',
    title: 'Title Order Number',
  },
  {
    field: 'titleCompanyAddress',
    title: 'Title Company Address',
  },
  {
    field: 'titleCompanyCity',
    title: 'Title Company City',
  },
  {
    field: 'titleCompanyState',
    title: 'Title Company State',
  },
  {
    field: 'titleCompanyZip',
    title: 'Title Company Zip',
  },
  {
    field: 'titleCompanyPhone',
    title: 'Title Company Phone',
  },
  {
    field: 'titleCompanyFax',
    title: 'Title Company Fax',
  },
  {
    field: 'titleCompanyEmail',
    title: 'Title Company Email',
  },
  {
    field: 'additionalInformation',
    title: 'Additional Information',
  },
  {
    field: 'documentUploadLocation',
    title: 'Document Upload Location',
  },
  {
    field: 'poa',
    title: 'Poa',
  },
  {
    field: 'cema',
    title: 'Cema',
  },
  {
    field: 'mortgage',
    title: 'Mortgage',
  },
  {
    field: 'policy',
    title: 'Policy',
  },
  {
    field: 'secondMortgage',
    title: 'Second Mortgage',
  },
  {
    field: 'assignment',
    title: 'Assignment',
  },
  {
    field: 'mortgageType',
    title: 'Loan Type',
    render: (rowData: RowData) => {
      if (rowData.mortgageType) {
        return rowData.mortgageType;
      }

      return rowData.isGinnieMaeLoanType ? 'FHA/VA/USDA' : '';
    },
  },
  {
    field: 'poolNumber',
    title: 'Pool Number',
  },
  {
    field: 'lienPosition',
    title: 'Lien Position',
  },
  {
    field: 'channel',
    title: 'Loan Channel',
  },
  {
    field: 'secondInvestor',
    title: 'Second Investor',
  },
];

const MappedLoanImportDataTable = ({ datatableData, exportFileName }: Props) => (
  <DataTable<RowData>
    title="Imported Loans"
    columns={columns}
    data={datatableData}
    options={{ exportFileName: exportFileName ?? 'Loan Import Data' }}
  />
);

export default MappedLoanImportDataTable;
