/** @jsx jsx */
import { jsx, CSSObject, ClassNames } from '@emotion/core';

import React, { FunctionComponent, Children, Fragment, ReactElement } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import colors from '../../../styles/colors';

export const wrapperStyles: CSSObject = {
  display: 'inline-block',
  left: 248,
  position: 'absolute',
  top: 25,
  zIndex: 300,
  '&:before': {
    borderLeft: `1px solid ${colors.gray}`,
    content: '""',
    height: 32,
    left: -32,
    position: 'absolute',
    top: -8,
  },
};

export const linkStyles: CSSObject = {
  color: colors.grayDark,
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 1,
  position: 'relative',
  textTransform: 'uppercase',
  transition: 'all .2s',
  span: {
    display: 'inline-block',
    paddingBottom: 19,
    ':hover': {
      borderBottom: `2px solid ${colors.blueLight}`,
      color: colors.black,
    },
  },
};

export const activeStyle: CSSObject = {
  span: {
    borderBottom: `2px solid ${colors.blueLight}`,
    color: colors.black,
    transition: 'all .2s',
    ':hover': { borderBottom: `2px solid ${colors.blueLight}` },
  },
};

const HeaderLinkGroup: FunctionComponent = ({ children, styles }) => {
  return (
    <div css={[wrapperStyles, styles]}>
      {React.Children.map(children, child => (
        <Fragment>
          <span>{child}</span>
          <span css={{ marginLeft: 32 }} />
        </Fragment>
      ))}
    </div>
  );
};

const HeaderLink: FunctionComponent<NavLinkProps> = ({ children, ...props }) => {
  return (
    <ClassNames>
      {({ css }) => (
        <NavLink
          className={css`
            ${linkStyles}
          `}
          activeClassName={css`
            ${activeStyle}
          `}
          {...props}
        >
          {children}
        </NavLink>
      )}
    </ClassNames>
  );
};

export { HeaderLink, HeaderLinkGroup };
