/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import React from 'react';
import { ConsolidationMode, useTitleCompanies } from './useTitleCompanies';
import { SaveButton } from './ConsolidationModal';
import { useStyles } from './TitleCompanyStyles';

export default function EditTitleCompanyModal() {
  const [
    { consolidationMode, mainConsolidatedTitleCompany, data },
    setTitleCompanies,
  ] = useTitleCompanies();
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.dialogSpacing }}
      open={consolidationMode === ConsolidationMode.Edit}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <span css={{ fontSize: 20, fontWeight: 'bold', color: '#686868' }}>Edit Details</span>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <DialogContentText classes={{ root: classes.dialogContentText }}>
          Edit consolidated company details
        </DialogContentText>
        <FormField field="Name" />
        <FormField field="Phone" />
        <FormField field="Email" />
        <FormField field="Address" />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsSpacing }}>
        <Button
          classes={{ root: classes.editButton }}
          onClick={() =>
            setTitleCompanies({
              consolidationMode: ConsolidationMode.True,
              mainConsolidatedTitleCompany: data.find(
                d => d.id === mainConsolidatedTitleCompany?.id,
              ),
            })
          }
        >
          Back
        </Button>
        <SaveButton />
      </DialogActions>
    </Dialog>
  );
}

function FormField({ field }) {
  const [{ mainConsolidatedTitleCompany }, setTitleCompanies] = useTitleCompanies();
  const classes = useStyles();

  return (
    <TextField
      variant="standard"
      InputProps={{
        style: { color: '#686868', fontSize: 14, fontWeight: 'bold' },
        classes: { underline: classes.textFieldUnderline },
      }}
      InputLabelProps={{
        style: { fontSize: 16, color: 'rgba(104, 104, 104, 0.6)' },
      }}
      classes={{ root: classes.formFieldRoot }}
      placeholder={field}
      label={field}
      fullWidth
      value={mainConsolidatedTitleCompany?.[field.toLowerCase()]}
      onChange={e =>
        setTitleCompanies({
          mainConsolidatedTitleCompany: {
            ...mainConsolidatedTitleCompany,
            [field.toLowerCase()]: e.target.value,
          },
        })
      }
    />
  );
}
