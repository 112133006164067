/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { ClientData, Sorting } from './types';
import {
  cardWrap,
  greenWords,
  month,
  number,
  thirdPart,
  today,
  todayWrap,
  topPart,
} from './styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CircularProgressWithLabel from './CircularProgressWithLabel';

export const SortingDisplayCard = (data: Sorting) => (
  <Card css={cardWrap}>
    <div css={topPart}>Sort</div>
    <div css={{ marginTop: 40 }}>
      <div css={todayWrap}>
        <Typography css={today}>today</Typography>
        <Typography css={number}>{data.sortedToday.toLocaleString()}</Typography>
        <div css={{ display: 'inline-flex', marginTop: 8 }}>
          <div>
            <span css={greenWords}>que</span>
            <span style={{ fontWeight: 'bold' }}>{data.readyToBeSorted.toLocaleString()}</span>
          </div>
          <div css={{ marginLeft: 20 }}>
            <span css={greenWords}>new</span>
            <span style={{ fontWeight: 'bold' }}>
              {data.readyToBeSortedAsOfToday.toLocaleString()}
            </span>
          </div>
        </div>
      </div>

      <div css={thirdPart}>
        <div css={{ marginLeft: 33 }}>
          <div>
            <Typography css={month}>month</Typography>
            <Typography css={{ fontWeight: 'bold', paddingBottom: 19 }}>
              {data.totalDocsSortedThisMonth.toLocaleString()}
            </Typography>
            <CircularProgressWithLabel percent={data.percentOfMonthlyGoal} />
          </div>
          <div
            css={{
              paddingTop: 15,
              borderTop: '0.8px solid #e5e5e3',
            }}
          >
            <Typography css={month}>year</Typography>
            <Typography css={{ fontWeight: 'bold' }}>
              {data.totalYearly.toLocaleString()}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

export const TopFiveClients = ({ data }: { data: ClientData[] }) => (
  <Card css={cardWrap}>
    <div css={topPart}>Top 5 Clients</div>
    <div css={{ marginTop: 40 }}>
      <div css={todayWrap}>
        <div css={{ display: 'block', marginTop: 8 }}>
          {data.map(clientData => (
            <div key={clientData.Client}>
              <span css={greenWords}>{clientData.Client}</span>
              {clientData.ReadyToBeSorted}
            </div>
          ))}
        </div>
      </div>
    </div>
  </Card>
);
