﻿/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';

import colors from '../../../styles/colors';
import Icon, { IconType } from '../Icon';

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;
const spinner = { animation: `${rotate} 2s infinite linear` };

const circleStyles = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  backgroundColor: colors.blue,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type IconCircleProps = {
  icon: IconType;
  styleOverrides?: object;
};
export default function IconCircle({ icon, styleOverrides }: IconCircleProps) {
  return (
    <div css={[circleStyles, styleOverrides, icon === IconType.Processing && spinner]}>
      <Icon icon={icon} />
    </div>
  );
}
