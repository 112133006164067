/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { Component } from 'react';
import ShortId from 'shortid';
import { XIcon, DownIcon } from '../../../ui/icons';
import colors from '../../../../styles/colors';
import SearchSelect from '../../../ui/SearchSelect';

const clientTagsWrap: CSSObject = {
  maxWidth: 688,
  width: 'auto',
  height: 56,
  overflow: 'hidden',
};

const multipleCountCircle: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'inline-flex',
  height: 40,
  justifyContent: 'center',
  verticalAlign: 'top',
  width: 40,
  ' svg': { pointerEvents: 'none' },
};

// #endregion
type SearchBoxSectionState = {
  expandTags: boolean;
  isHover: boolean;
};
type SearchBoxSectionProps = {
  clients: { name: string; id: number }[];
  selectedClientIds?: number[];
  setSelectedClient: (clientId: number) => any;
  removeSelectedClient: (clientId: number) => any;
};
export default class SearchBoxSection extends Component<
  SearchBoxSectionProps,
  SearchBoxSectionState
> {
  state = {
    expandTags: false,
    isHover: false,
  };

  render() {
    const { expandTags, isHover } = this.state;
    const { clients, selectedClientIds, setSelectedClient, removeSelectedClient } = this.props;
    const arrowIcon = expandTags ? <XIcon /> : <DownIcon />;
    const text = expandTags ? (
      <XIcon />
    ) : (
      selectedClientIds && selectedClientIds.length > 2 && `+${selectedClientIds.length - 2}`
    );
    return (
      <div css={{ display: 'flex' }}>
        <SearchSelect
          css={{ width: 377, marginRight: 16 }}
          options={clients
            .filter(c => selectedClientIds && !selectedClientIds.includes(c.id))
            .map(client => ({
              label: client.name,
              value: client.id,
            }))}
          placeholder="Search clients"
          onChange={selection => {
            selection && setSelectedClient(selection.value);
          }}
          value={null}
        />
        {/* need to set the width to account for small tags, so that it still shows only 2 */}
        <div css={[clientTagsWrap, expandTags && { height: 'auto' }]}>
          {selectedClientIds &&
            selectedClientIds.map(id => (
              <ClientTag
                key={ShortId.generate()}
                clientName={clients.find(c => c.id === id)!.name}
                removeSelectedClient={() => removeSelectedClient(id)}
              />
            ))}
        </div>
        {selectedClientIds && selectedClientIds.length > 2 && (
          <div
            onClick={() => this.setState({ expandTags: !expandTags })}
            onMouseOver={() => this.setState({ isHover: true })}
            onMouseOut={() => this.setState({ isHover: false })}
            css={[multipleCountCircle, isHover && { backgroundColor: colors.gray }]}
          >
            {isHover ? arrowIcon : text}
          </div>
        )}
      </div>
    );
  }
}

const clientTag = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: `1px solid ${colors.gray}`,
  borderRadius: 24,
  display: 'inline-flex',
  height: 48,
  marginRight: 8,
  marginBottom: 8,
  padding: '0 24px',
  width: 'auto',
  ' svg': {
    color: colors.gray,
    marginTop: 2,
    marginRight: 16,
    cursor: 'pointer',
  },
};

function ClientTag({ clientName, removeSelectedClient }) {
  return (
    <div css={clientTag}>
      <XIcon onClick={removeSelectedClient} />
      <span>{clientName}</span>
    </div>
  );
}
