/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Button from '../../ui/Button';

const buttonCon = {
  position: 'absolute',
  right: 56,
  top: 11,
};

export default function HeaderButton({ onClick }: { onClick: () => void }) {
  return (
    <div css={buttonCon}>
      <Button secondary onClick={onClick} styleOverrides={{ height: 40 }}>
        Clear
      </Button>
    </div>
  );
}
