/** @jsx jsx */
import React, { ChangeEvent } from 'react';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { jsx } from '@emotion/core';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';

type Props = {
  documentTypeSettings: DocumentTypeSetting[];
  updateDocumentTypeSetting: (newState: DocumentTypeSetting) => void;
};

const DocumentTypeOverrideSettings = ({
  documentTypeSettings,
  updateDocumentTypeSetting,
}: Props) => {
  return (
    <div
      style={{
        height: '300px',
        overflowY: 'auto',
      }}
    >
      <Paper elevation={2} className="p2">
        {documentTypeSettings.map(documentTypeSetting => (
          <div className="df col" key={documentTypeSetting.documentTypeId}>
            <div
              key={documentTypeSetting.documentTypeId}
              title={
                documentTypeSetting.shouldShipDigitally
                  ? 'Enabled - Uncheck to Disable Digital Shipping'
                  : 'Disabled - Check to Enable Digital Shipping'
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={documentTypeSetting.shouldShipDigitally}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newState: DocumentTypeSetting = {
                        ...documentTypeSetting,
                        shouldShipDigitally: e.target.checked,
                        onlyDigital: e.target.checked ? documentTypeSetting.onlyDigital : false,
                      };
                      updateDocumentTypeSetting(newState);
                    }}
                    color="primary"
                  />
                }
                label={documentTypeSetting.documentTypeLabel}
              />
            </div>
            {documentTypeSetting.shouldShipDigitally && (
              <div className="ml3">
                <FormControl>
                  <RadioGroup
                    row
                    value={documentTypeSetting.onlyDigital ? 'true' : 'false'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const newState: DocumentTypeSetting = {
                        ...documentTypeSetting,
                        onlyDigital: e.target.value === 'true',
                      };
                      updateDocumentTypeSetting(newState);
                    }}
                  >
                    <FormControlLabel value="false" control={<Radio />} label="All" />
                    <FormControlLabel value="true" control={<Radio />} label="Digital Docs Only" />
                  </RadioGroup>
                </FormControl>
              </div>
            )}
          </div>
        ))}
      </Paper>
    </div>
  );
};

export default DocumentTypeOverrideSettings;
