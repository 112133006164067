/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { CallCenterDocumentsFromCallsHeader as Header } from './types';

type Props = {
  callCenterDocumentsFromCallsHeader: Header;
};

const CallCenterDocumentsFromCallsHeader = ({ callCenterDocumentsFromCallsHeader }: Props) => {
  const columns = [
    {
      field: 'rep',
      title: 'User',
    },
    {
      field: 'date1',
      title: `${callCenterDocumentsFromCallsHeader?.dateOneName}`,
    },
    {
      field: 'date2',
      title: `${callCenterDocumentsFromCallsHeader.dateTwoName}`,
    },
    {
      field: 'date3',
      title: `${callCenterDocumentsFromCallsHeader.dateThreeName}`,
    },
    {
      field: 'date4',
      title: `${callCenterDocumentsFromCallsHeader.dateFourName}`,
    },
    {
      field: 'date5',
      title: `${callCenterDocumentsFromCallsHeader.dateFiveName}`,
    },
    {
      field: 'date6',
      title: `${callCenterDocumentsFromCallsHeader.dateSixName}`,
    },
  ];

  return (
    <Fragment>
      {callCenterDocumentsFromCallsHeader?.data && (
        <DataTable<Header>
          title={`Outreach Documents Received - ${callCenterDocumentsFromCallsHeader.reportType}`}
          columns={columns}
          data={callCenterDocumentsFromCallsHeader.data}
          headerStyle={{ whiteSpace: 'normal' }}
          options={{
            maxBodyHeight: '500px',
            search: false,
            exportFileName: `Outreach Documents Received - ${callCenterDocumentsFromCallsHeader.reportType}`,
          }}
        />
      )}
    </Fragment>
  );
};

export default CallCenterDocumentsFromCallsHeader;
