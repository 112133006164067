/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { DocumentsAgingForShipping as RowData } from './types';
import useFileExport from '../../components/ui/DataTable/FileExport';

type Props = {
  documentsAgingForShipping: RowData[];
};

const DocumentsAgingForShipping = ({ documentsAgingForShipping }: Props) => {
  const columns = [
    {
      field: 'id',
      title: 'Barcode Number',
    },
    {
      field: 'client',
      title: 'Client',
    },
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'docType',
      title: 'Doc Type',
    },
    {
      field: 'investor',
      title: 'Investor',
    },
    {
      field: 'daysAging',
      title: 'Stall Days',
    },
    {
      field: 'dateFunded',
      title: 'DateFunded',
      render: (rowData: RowData) =>
        rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'dateUploaded',
      title: 'Barcode Created',
      render: (rowData: RowData) =>
        rowData.dateUploaded && new Date(rowData.dateUploaded).toLocaleDateString(),
    },
    {
      field: 'dateStapled',
      title: 'Date Stapled',
      render: (rowData: RowData) =>
        rowData.dateStapled && new Date(rowData.dateStapled).toLocaleDateString(),
    },
    {
      field: 'dateMatched',
      title: 'Date Matched',
      render: (rowData: RowData) =>
        rowData.dateMatched && new Date(rowData.dateMatched).toLocaleDateString(),
    },
    {
      field: 'dateAudited',
      title: 'Date Audited',
      render: (rowData: RowData) =>
        rowData.dateAudited && new Date(rowData.dateAudited).toLocaleDateString(),
    },
    {
      field: 'dateSorted',
      title: 'Date Sorted',
      render: (rowData: RowData) =>
        rowData.dateSorted && new Date(rowData.dateSorted).toLocaleDateString(),
    },
    {
      field: 'investorSetAt',
      title: 'Investor Added To Loan',
      render: ({ investorSetAt }: RowData) =>
        investorSetAt && new Date(investorSetAt).toLocaleDateString(),
    },
    {
      field: 'datePdfImageSaved',
      title: 'Date Scanned',
      render: (rowData: RowData) =>
        rowData.datePdfImageSaved && new Date(rowData.datePdfImageSaved).toLocaleDateString(),
    },
    {
      field: 'dateCorrected',
      title: 'Date Corrected',
      render: (rowData: RowData) =>
        rowData.dateCorrected && new Date(rowData.dateCorrected).toLocaleDateString(),
    },
    {
      field: 'format',
      title: 'Document Format',
    },
  ];
  const fileExport = useFileExport<any>();
  const exportDatatable = (columnsParam: any[], dataTableRows: RowData[]) => {
    let firstRow = {} as RowData;

    const exportData = dataTableRows.map(row => {
      if (!firstRow.id) {
        firstRow = row;
      }
      const exportRow = {
        'Barcode Number': row.id,
        Client: row.client,
        'Loan Number': row.loanNumber,
        'Document Type': row.docType,
        Investor: row.investor,
        'Days Stalled': row.daysAging,
        'Date Funded': row.dateFunded && new Date(row.dateFunded).toLocaleDateString(),
        'Date Uploaded': row.dateUploaded && new Date(row.dateUploaded).toLocaleDateString(),
        'Date Stapled': row.dateStapled && new Date(row.dateStapled).toLocaleDateString(),
        'Date Matched': row.dateAudited && new Date(row.dateMatched).toLocaleDateString(),
        'Date Audited': row.dateAudited && new Date(row.dateAudited).toLocaleDateString(),
        'Date Sorted': row.dateSorted && new Date(row.dateSorted).toLocaleDateString(),
        'Date Scanned':
          row.datePdfImageSaved && new Date(row.datePdfImageSaved).toLocaleDateString(),
        'Date Corrected': row.dateCorrected && new Date(row.dateCorrected).toLocaleDateString(),
        'Document Format': row.format,
      };
      return exportRow;
    });

    const fileName = 'Documents Aging For Shipping';

    fileExport(exportData, fileName);
  };

  return (
    <Fragment>
      (
      <DataTable<RowData>
        title="Documents Aging for Shipping"
        columns={columns}
        data={documentsAgingForShipping}
        headerStyle={{ whiteSpace: 'normal' }}
        options={{
          selection: true,
          exportCsv: exportDatatable,
        }}
      />
      )
    </Fragment>
  );
};
export default DocumentsAgingForShipping;
