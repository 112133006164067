import { useState, useEffect } from 'react';
import { Document, DocumentType } from '../globalTypes/objects';
import { apiFetch } from '../adalConfig';
import { Failure, Loading, NotAsked, RemoteData, Success } from '../globalTypes/RemoteData';
import { isDocument } from '../Utils';

export type DocTypeUpdated = { updated: false } | { updated: true; documentType: DocumentType };

async function getDocTypeUpdated(docId: number) {
  const { data } = await apiFetch<DocTypeUpdated>(`/api/documents/DocTypeUpdated/${docId}`);
  return data;
}

export default function useCheckIfDocTypeUpdated(document?: Document | number) {
  const [docTypeUpdated, setDocTypeUpdated] = useState<RemoteData<DocTypeUpdated, Error>>(NotAsked);

  const docId = isDocument(document) ? document.id : document;

  useEffect(() => {
    setDocTypeUpdated(NotAsked);
    if (docId) {
      setDocTypeUpdated(Loading);
      getDocTypeUpdated(docId).then(
        res => setDocTypeUpdated(Success(res)),
        e => setDocTypeUpdated(Failure(e)),
      );
    }
  }, [docId]);

  return docTypeUpdated;
}
