/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Children, FunctionComponent, ReactElement, useState } from 'react';

import {
  borderStyles,
  tabStyles,
  selectedTabStyles,
  numStyles,
  selectedNumStyles,
  disabledTab,
} from './css';

interface TabGroupProps {
  children: JSX.Element[];
  disableBorder?: boolean;
  selectionDefaultIdx?: number;
}

interface TabProps {
  disabled?: boolean;
  number?: number;
  selected?: boolean;
  onClick: Function;
  idx?: number;
  children: string;
}

export const TabGroup: FunctionComponent<TabGroupProps> = ({ children, disableBorder , selectionDefaultIdx}) => {
  const [selected, setSelected] = useState(selectionDefaultIdx || 0);

  return (
    <span css={!disableBorder && borderStyles}>
      {Children.map(children, (child: ReactElement, idx: number) =>
        React.cloneElement(child, {
          idx,
          onClick: index => {
            setSelected(index);
            child.props.onClick();
          },
          selected: idx === selected,
        }),
      )}
    </span>
  );
};

export default function Tab({
  disabled,
  children,
  number,
  selected,
  onClick,
  idx,
  ...props
}: TabProps) {
  return (
    <span
      {...props}
      onClick={() => onClick(idx)}
      css={[tabStyles, selected && selectedTabStyles, disabled && disabledTab]}
    >
      {children}
      {(number || number === 0) && (
        <span css={[numStyles, selected && selectedNumStyles]}>{number}</span>
      )}
    </span>
  );
}
