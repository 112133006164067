/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import Button from './Button';
import Modal from './Modal';
import { ConfirmModal as ConfirmModalType } from '../../globalTypes/objects';

type Props = {
  modalData: ConfirmModalType;
};
const header = {
  display: 'flex',
  fontSize: '20px',
  fontWeight: 'bold' as 'bold',
};
const buttonText: CSSObject = {
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: 16,
};
const message = {
  marginTop: '33px',
  marginBottom: '33px',
};

const ConfirmModal = ({ modalData }: Props) => {
  const [modal, setModal] = useState<ConfirmModalType>({ isOpen: false, message: '', header: '' });

  useEffect(() => {
    setModal(modalData);
  }, [modalData]);

  const closeModal = () => {
    setModal({ isOpen: false, message: '', header: '' });
  };
  const customFooter = (
    <div>
      {modalData.cancelText ? (
        <Button
          styleOverrides={buttonText}
          disabled={modalData.cancelDisabled}
          secondary
          onClick={modalData.handleCancel}
        >
          {modalData.cancelText}
        </Button>
      ) : null}
      {modalData.confirmText ? (
        <Button onClick={modalData.handleConfirm ? modalData.handleConfirm : closeModal}>
          {modalData.confirmText}
        </Button>
      ) : null}
    </div>
  );
  return modal.isOpen ? (
    <Modal customFooter={customFooter}>
      <div css={header}>{modalData.header}</div>
      <div css={message}>
        <p>{modalData.message}</p>
      </div>
    </Modal>
  ) : null;
};

export default ConfirmModal;
