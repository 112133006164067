/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { apiFetch } from '../../adalConfig';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import MappedLoanImportDataTable, { RowData } from './MappedLoanImportDataTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      minHeight: '300px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    },
  }),
);

type Props = {
  batchId: number;
  viewOnlyImportedLoans: boolean;
};

const ImportedLoansModal = ({ batchId, viewOnlyImportedLoans }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [datatableData, setDatatableData] = useState<RowData[]>([]);

  useEffect(() => {
    const getDatatableData = async () => {
      setIsLoading(true);
      const endPoint = viewOnlyImportedLoans ? 'total-imported' : 'raw-loan-data';
      const { data } = await apiFetch<RowData[]>(`/api/loans/${endPoint}?batchId=${batchId}`);
      setDatatableData(data);
      setIsLoading(false);
    };

    getDatatableData();
  }, [batchId]);

  const classes = useStyles();

  return (
    <Fragment>
      {isLoading ? (
        <div className="center-in-parent">
          <CircularProgress size="40" disableShrink />
        </div>
      ) : (
        <div className={classes.paper}>
          <MappedLoanImportDataTable
            datatableData={datatableData}
            exportFileName={`Imported Loans - Batch ID ${batchId}`}
          />
        </div>
      )}
    </Fragment>
  );
};

export default ImportedLoansModal;
