import { useContext } from 'react';
import { ClientsContext } from '../ClientsContext';

export function useClient(clientId: number) {
  const clients = useContext(ClientsContext);
  return clients.find(client => client.id === clientId);
}

export function useClients() {
  return useContext(ClientsContext);
}
