/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Drawer } from '@mui/material';
import produce from 'immer';
import { Filter, FiltersForFilterType, FilterType, FilterTypes } from './types';
import RadioFilters from './RadioFilters';
import CheckboxFilters from './CheckboxFilters';
import SelectFilters from './SelectFilters';

type Props = {
  open: boolean;
  onClose: () => void;
  onClick: () => void;
  dataTableFilterTypes: FilterTypes;
  setDataTableFilterTypes: (filterTypes: FilterTypes) => void;
  children?: JSX.Element;
};

function getFilterByType(filterTypes: FilterTypes, filterType: FilterType): Filter[] {
  return (
    filterTypes.find(
      (filtersForFilterType: FiltersForFilterType) =>
        filtersForFilterType.filterType === filterType,
    )?.filters ?? []
  );
}

const Filters = ({
  open,
  onClose,
  onClick,
  dataTableFilterTypes,
  setDataTableFilterTypes,
  children,
}: Props) => {
  const updateRadioFilters = (filters: Filter[]) => {
    const newFilterValues = produce(dataTableFilterTypes, (draft: FilterTypes) => {
      const radioFilterIndex = draft.findIndex(
        (filtersForFilterType: FiltersForFilterType) =>
          filtersForFilterType.filterType === FilterType.Radio,
      );
      if (radioFilterIndex !== -1) {
        draft[radioFilterIndex].filters = filters;
      }
    });

    setDataTableFilterTypes(newFilterValues);
  };

  const updateCheckboxFilters = (filters: Filter[]) => {
    const newFilterValues = produce(dataTableFilterTypes, (draft: FilterTypes) => {
      const radioFilterIndex = draft.findIndex(
        (filtersForFilterType: FiltersForFilterType) =>
          filtersForFilterType.filterType === FilterType.Checkbox,
      );
      if (radioFilterIndex !== -1) {
        draft[radioFilterIndex].filters = filters;
      }
    });

    setDataTableFilterTypes(newFilterValues);
  };

  const updateSelectFilters = (filters: Filter[]) => {
    const newFilterValues = produce(dataTableFilterTypes, (draft: FilterTypes) => {
      const radioFilterIndex = draft.findIndex(
        (filtersForFilterType: FiltersForFilterType) =>
          filtersForFilterType.filterType === FilterType.Select,
      );
      if (radioFilterIndex !== -1) {
        draft[radioFilterIndex].filters = filters;
      }
    });

    setDataTableFilterTypes(newFilterValues);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div style={{ width: 250 }} role="presentation" onClick={onClick} onKeyDown={onClick}>
        <RadioFilters
          filters={getFilterByType(dataTableFilterTypes, FilterType.Radio)}
          updateRadioFilters={updateRadioFilters}
        />
        <CheckboxFilters
          filters={getFilterByType(dataTableFilterTypes, FilterType.Checkbox)}
          updateCheckboxFilters={updateCheckboxFilters}
        />
        <SelectFilters
          filters={getFilterByType(dataTableFilterTypes, FilterType.Select)}
          updateSelectFilters={updateSelectFilters}
        />
        {children}
      </div>
    </Drawer>
  );
};

export default Filters;
