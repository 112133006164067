/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  client: string;
  count: number;
};

const columns = [
  {
    field: 'client',
    title: 'Lender',
  },
  {
    field: 'count',
    title: 'Pending Association',
  },
];

const InvestorsPendingAssociationByClient = () => (
  <DataTable<RowData>
    title="Investors Pending Association Per Lender"
    columns={columns}
    url="/api/associations/investors-pending-association-by-client"
  />
);

export default InvestorsPendingAssociationByClient;
