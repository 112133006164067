/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useContext, useState } from 'react';
import GradingIcon from '@mui/icons-material/Grading';
import MailIcon from '@mui/icons-material/Mail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import compareAsc from 'date-fns/compareAsc';
import { AuthContext } from '../AuthContext';
import { roleTypes } from '../../constants';
import useFileExport from '../ui/DataTable/FileExport';
import { RowData, SidebarOptions } from './types';
import DataTable from '../ui/DataTable/DataTable';
import colors from '../../styles/colors';
import { poBoxDisplay } from '../../pages/CallCenter';
import differenceInDays from 'date-fns/differenceInDays';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    linkStyle: {
      color: 'blue',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export const sortDataByPhoneAndClient = (a: RowData, b: RowData): number => {
  const phoneComparisonResult = comparePhoneNumbers(a, b);
  if (phoneComparisonResult !== 0) {
    return phoneComparisonResult;
  }

  const clientComparisonResult = compareClients(a, b);
  if (clientComparisonResult !== 0) {
    return clientComparisonResult;
  }

  return compareAsc(new Date(a.dateFunded), new Date(b.dateFunded));
};

const comparePhoneNumbers = (a: RowData, b: RowData) => {
  if (a.loanLevelContactInformation.phone < b.loanLevelContactInformation.phone) {
    return -1;
  }

  if (a.loanLevelContactInformation.phone > b.loanLevelContactInformation.phone) {
    return 1;
  }

  return 0;
};

const compareClients = (a: RowData, b: RowData) => {
  if (a.clientInfo.client < b.clientInfo.client) {
    return -1;
  }

  if (a.clientInfo.client > b.clientInfo.client) {
    return 1;
  }

  return 0;
};

export type ExportData = {
  'Loan Id': number;
  Stage?: number;
  'Loan Number': string;
  'Title Company': string;
  'Title Number': string;
  Borrower: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  County: string;
  'Date Funded': string;
  'Days Aged': string;
  'Missing Docs': string;
  'Requires Original': string;
  Lender?: string;
  'Lender Inbox'?: string;
  'Lender Address'?: string;
  'Lender PO Box'?: string | null;
  'Lender City/State/Zip'?: string;
  'Latest Disposition': string;
  'Last Note': string;
};

type Props = {
  datatableData: RowData[];
  isClientMode: boolean;
  isLoading: boolean;
  setSelectedRows: Dispatch<SetStateAction<RowData[]>>;
  selectedRows: RowData[];
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
};

export const formatNotes = (notes: RowData['pastNotes']) =>
  notes.map((note, i) => (
    <p key={i}>
      <b>{new Date(note.dateEntered).toLocaleDateString()}</b> -{' '}
      {note.username ? (
        <Fragment>
          <b>{note.username}</b> -{' '}
        </Fragment>
      ) : (
        ''
      )}
      {note.text}
    </p>
  ));

export const exportDatatable = (
  columns: any[],
  dataTableRows: RowData[],
  isClientMode: boolean,
  fileExport: ReturnType<typeof useFileExport<ExportData>>,
  fileName = '',
) => {
  let firstRow = {} as RowData;

  const exportData = dataTableRows.map(row => {
    if (!firstRow.id) {
      firstRow = row;
    }

    const { borrower, address, city, state, zip, county } = row.propertyInfo;
    let lastNote = '';
    if (row.pastNotes.length > 0) {
      const { dateEntered, username, text } = row.pastNotes[0];
      lastNote += new Date(dateEntered).toLocaleDateString() + ' - ';
      lastNote += username ? `${username} - ` : '';
      lastNote += text;
    }

    const daysAged = row.dateFunded ? differenceInDays(new Date(), new Date(row.dateFunded)) : 0;

    const exportRow: ExportData = {
      'Loan Id': row.id,
      Stage: row.calculatedOutreachStage,
      'Loan Number': row.appNumber,
      'Title Company': row.titleCompany,
      'Title Number': row.titleNumber,
      Borrower: borrower,
      Address: address,
      City: city,
      State: state,
      Zip: zip,
      County: county,
      'Date Funded': new Date(row.dateFunded).toLocaleDateString(),
      'Days Aged': `${daysAged}/${row.daysAgedDeadline}`,
      'Missing Docs': row.missingDocuments,
      'Requires Original': row.requiresOriginalDoc ? 'Yes' : 'No',
      'Latest Disposition': row.latestDisposition?.reason || '',
      'Last Note': lastNote,
    };

    if (!isClientMode) {
      exportRow.Lender = row.clientInfo.client;
      exportRow['Lender Inbox'] = row.clientInfo.clientInbox;
      exportRow['Lender Address'] = row.clientInfo.address;
      exportRow['Lender PO Box'] = row.clientInfo.poBox;
      exportRow[
        'Lender City/State/Zip'
      ] = `${row.clientInfo.city}, ${row.clientInfo.state} ${row.clientInfo.zip}`;
    }

    return exportRow;
  });

  if (fileName === '') {
    fileName = isClientMode
      ? `${firstRow.clientInfo.client} - ${firstRow.titleCompany}`
      : firstRow.titleCompany;
  }

  fileExport(exportData, fileName);
};

export const actions = (
  allSelectedResolved: boolean,
  someSelectedResolved: boolean,
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>,
) => {
  const conflictingResolutions = someSelectedResolved && !allSelectedResolved;

  return [
    {
      key: 'updateContactInfo',
      tooltip: 'Update contact information',
      icon: () => <ContactPageIcon style={{ color: 'black' }} />,
      onClick: () => setSidebarOption(prevValue => (prevValue === 'other' ? undefined : 'other')),
    },
    {
      key: 'dispositions',
      tooltip: conflictingResolutions
        ? 'Disabled - one or more loans already have a disposition set '
        : allSelectedResolved
        ? 'Disabled - disposition is already set'
        : 'Set disposition',
      icon: () => (
        <GradingIcon
          style={{
            color:
              !allSelectedResolved && !conflictingResolutions ? 'black' : 'rgba(0, 0, 0, 0.25)',
          }}
        />
      ),
      disabled: allSelectedResolved || conflictingResolutions,
      onClick: () =>
        setSidebarOption(prevValue => (prevValue === 'disposition' ? undefined : 'disposition')),
    },
    {
      key: 'email',
      tooltip: conflictingResolutions
        ? `Disabled - one or more loans don't have a disposition set`
        : !allSelectedResolved
        ? 'Disabled - Please set the disposition'
        : 'Send email',
      icon: () => (
        <MailIcon
          style={{
            color: allSelectedResolved && !conflictingResolutions ? 'black' : 'rgba(0, 0, 0, 0.25)',
          }}
        />
      ),
      disabled: !allSelectedResolved || conflictingResolutions,
      onClick: () => setSidebarOption(prevValue => (prevValue === 'email' ? undefined : 'email')),
    },
  ];
};

const Datatable = ({
  datatableData,
  isClientMode,
  isLoading,
  setSelectedRows,
  selectedRows,
  setSidebarOption,
}: Props) => {
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);
  const fileExport = useFileExport<ExportData>();

  const authContext = useContext(AuthContext);

  const classes = useStyles();

  const columns = [
    {
      field: 'id',
      title: 'ID',
      hidden: !authContext.roles.includes(roleTypes.Dev),
    },
    {
      field: 'calculatedOutreachStage',
      title: 'Stage',
    },
    {
      field: 'appNumber',
      title: 'Loan/Title Number',
      render: (rowData: RowData) => (
        <Fragment>
          <div>{rowData.appNumber}</div>
          <div style={{ color: 'grey' }}>{rowData.titleNumber}</div>
        </Fragment>
      ),
    },
    {
      field: 'clientInformation',
      title: 'Client',
      hidden: isClientMode,
      render: (rowData: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{rowData.clientInfo.client}</div>
          <span css={{ color: 'blue' }}>{rowData.clientInfo.clientInbox}</span>
          <div style={{ color: 'grey' }}>
            <div>{poBoxDisplay(rowData.clientInfo)}</div>
          </div>
        </div>
      ),
    },
    {
      field: 'propertyInfo',
      title: 'Property Info',
      render: (rowData: RowData) => (
        <Fragment>
          <div>
            <b>{rowData.propertyInfo.borrower}</b>
          </div>
          <div>{rowData.propertyInfo.address}</div>
          <div>
            <span>{rowData.propertyInfo.city}</span>&nbsp;
            <span>{rowData.propertyInfo.state}</span>&nbsp;
            <span>{rowData.propertyInfo.zip}</span>&nbsp;
          </div>
          <div>{rowData.propertyInfo.county}</div>
        </Fragment>
      ),
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: (rowData: RowData) => rowData && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'dateFunded',
      title: 'Days Aged',
      render: ({ dateFunded, daysAgedDeadline }: RowData) => {
        if (!dateFunded) {
          return null;
        }

        const daysAged = differenceInDays(new Date(), new Date(dateFunded));
        return `${daysAged}/${daysAgedDeadline}`;
      },
    },
    {
      field: 'missingDocuments',
      title: 'Missing Documents',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.missingDocuments.split(',').map((doc, i, arr) => (
            <Fragment key={doc}>
              <span
                style={{
                  color: rowData.requiresOriginalDoc && doc.trim() !== 'Policy' ? 'red' : 'inherit',
                }}
              >
                {doc}
              </span>
              {i !== arr.length - 1 && ', '}
            </Fragment>
          ))}
        </Fragment>
      ),
    },
    {
      field: 'loanLevelContactInformation',
      title: 'Loan Contacts',
      customSort: sortDataByPhoneAndClient,
      render: (rowData: RowData) => (
        <div css={{ maxHeight: 128, overflow: 'auto' }}>
          {(rowData.loanLevelContactInformation.phone ||
            rowData.loanLevelContactInformation.email) && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark }}>Loan Level:</div>
              <div>
                <a
                  className={classes.linkStyle}
                  href={`tel:${rowData.loanLevelContactInformation.phone?.replace(/\D/g, '')}`}
                >
                  {rowData.loanLevelContactInformation.phone}
                </a>
              </div>
              <div>{rowData.loanLevelContactInformation.email}</div>
            </Fragment>
          )}
          {rowData.titlePortalContactEmails.length > 0 && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark, paddingTop: 8 }}>
                Title Portal Contacts:
              </div>
              {rowData.titlePortalContactEmails.map(e => (
                <div key={e}>{e}</div>
              ))}
            </Fragment>
          )}
        </div>
      ),
    },
    {
      field: 'pastNotes',
      title: 'Past Notes',
      render: (rowData: RowData) =>
        !!rowData.pastNotes.length && (
          <div className="df">
            <div
              style={{ width: '310px', height: '66px', overflowY: 'auto', whiteSpace: 'normal' }}
            >
              {formatNotes(rowData.pastNotes)}
            </div>
            <div>
              <OpenWithIcon style={{ fontSize: '10px' }} onClick={() => setSelectedRow(rowData)} />
            </div>
          </div>
        ),
    },
  ];

  if (isLoading) {
    return (
      <CircularProgress
        css={{ position: 'fixed', top: '50%', left: '50%', zIndex: 11 }}
        size="75"
        disableShrink
      />
    );
  }

  return (
    <Fragment>
      <DataTable<RowData>
        title="Outstanding Loans"
        columns={columns}
        data={datatableData}
        condensed
        options={{
          selection: true,
          rowStyle: (rowData: RowData) => ({
            backgroundColor: rowData.isResolved ? 'rgb(237, 247, 237)' : '#FFF',
          }),
          exportCsv: (columns, dataTableRows) =>
            exportDatatable(columns, dataTableRows, isClientMode, fileExport),
        }}
        onSelectionChange={rows => {
          setSelectedRows(rows);
          setSidebarOption(undefined);
        }}
        actions={() =>
          actions(
            selectedRows.every(r => r.isResolved),
            selectedRows.some(r => r.isResolved),
            setSidebarOption,
          )
        }
      />

      <Modal open={!!selectedRow} onClose={() => setSelectedRow(null)}>
        <div className={classes.paper}>{selectedRow && formatNotes(selectedRow.pastNotes)}</div>
      </Modal>
    </Fragment>
  );
};

export default Datatable;
