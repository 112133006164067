/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import ClientFeesDisplayCard from './ClientFeesDisplayCard';
import { EditTwoIcon } from '../../components/ui/icons';
import InputNumber from '../../components/ui/Inputs/InputNumber';
import { ActionButton } from './cardStyles';
import { ChargeData } from './types';
import { twoColGrid } from '../styles/clientStyles';

type Props = {
  charges: ChargeData[][];
  setCharges: Dispatch<SetStateAction<ChargeData[][]>>;
  isNewClient: boolean;
};

const ClientFees = ({ charges, setCharges, isNewClient }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const handleChargeChange = (
    newCharge: number | undefined,
    feeTypeId: number,
    chargeTypeIndex: number,
  ) => {
    setCharges(c =>
      c.map((chargeType, i) => {
        if (chargeTypeIndex !== i) {
          return chargeType;
        }

        return chargeType.map(chargeData => {
          if (chargeData.feeTypeId === feeTypeId) {
            chargeData = { ...chargeData, amount: newCharge || 0 };
          }

          return chargeData;
        });
      }),
    );
  };

  return (
    <Fragment>
      {editMode || isNewClient ? (
        <div css={{ '&&': { padding: 16, position: 'relative' } }}>
          <ActionButton
            onClick={() => setEditMode(!editMode)}
            css={{ position: 'absolute', right: 0, top: 0 }}
          >
            <EditTwoIcon />
          </ActionButton>
          {charges.map((chargeGroup, index) => (
            <div
              key={index}
              css={[
                twoColGrid,
                {
                  padding: 16,
                  label: { marginTop: 16 },
                },
              ]}
            >
              <h3 className="bold">
                {chargeGroup.length && `${chargeGroup[0].description.slice(0, 8)}s`}
              </h3>
              <div>
                {chargeGroup.map(charge => (
                  <InputNumber
                    key={charge.description}
                    thousandSeparator
                    prefix={
                      ['percent', 'threshold'].findIndex(x =>
                        charge.description.toLowerCase().includes(x),
                      ) !== -1
                        ? ''
                        : '$'
                    }
                    suffix={charge.description.toLowerCase().includes('percent') ? '%' : ''}
                    value={charge.amount === 0 ? '' : charge.amount}
                    label={charge.description.slice(11)}
                    decimalScale={2}
                    isNumericString
                    onValueChange={({ floatValue }) =>
                      handleChargeChange(floatValue, charge.feeTypeId, index)
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ClientFeesDisplayCard charges={charges} editOnclick={() => setEditMode(!editMode)} />
      )}
    </Fragment>
  );
};

export default ClientFees;
