/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { errorType } from '../ImportErrorsUtils';
import colors from '../../../../styles/colors';
import { FormatPhoneWithSpaces, StripPhone } from '../../../../Utils';
import { ContactInformation } from '../../../../globalTypes/objects';

const mainWrap: CSSObject = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  display: 'inline-block',
  margin: '32px auto 8px',
  padding: '32px',
  width: '100%',
};

const investorWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  fontSize: '16',
  fontWeight: 700,
  justifyContent: 'space-between',
  textTransform: 'capitalize',
};

const nameWrap: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  ' div': { display: 'inline-block' },
  ' p': { fontWeight: 700 },
};

const infoContainer: CSSObject = {
  height: 42,
  marginBottom: 16,
};

const errorCircle: CSSObject = {
  backgroundColor: colors.red,
  borderRadius: '50%',
  display: 'inline-block',
  height: '13px',
  width: '13px',
};

type AssociationContactInformation = ContactInformation & {
  servicer: string;
  subServicer: string;
  custodian: string;
};

type Props = { contactInformation: AssociationContactInformation };

export default function UnidentifiedTile({ contactInformation }: Props) {
  const {
    name,
    address,
    city,
    state,
    zip,
    phone,
    fax,
    email,
    servicer,
    subServicer,
    custodian,
  } = contactInformation;
  const formattedPhone = phone && FormatPhoneWithSpaces(StripPhone(phone));
  const formattedFax = fax && FormatPhoneWithSpaces(StripPhone(fax));
  const cityName = `${city},`;
  const isTitleError = contactInformation.type === 'titleError';
  return (
    <div css={[mainWrap, isTitleError && { height: 224 }]} data-test="match unidentified">
      {isTitleError ? (
        <div>
          <div css={[nameWrap, infoContainer]}>
            <div css={{ width: 'calc(100 - 20px)' }}>
              {name && <p>{name}</p>}
              {city && contactInformation.type === errorType.title ? (
                <p>
                  {cityName} {state}
                </p>
              ) : null}
            </div>
            <div css={errorCircle} />
          </div>
          {(address || city || state || zip) && (
            <div css={infoContainer}>
              {address && <p>{address}</p>}
              <p>
                {city && cityName}
                {state} {zip}
              </p>
            </div>
          )}
          {(formattedPhone || formattedFax || email) && (
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                height: 42,
              }}
            >
              <div>
                {formattedPhone && (
                  <p>
                    <span css={{ color: colors.blue }}>P&nbsp;&nbsp;</span>
                    {formattedPhone}
                  </p>
                )}
                {formattedFax && (
                  <p>
                    <span css={{ color: colors.blue }}>F&nbsp;&nbsp;</span>
                    {formattedFax}
                  </p>
                )}
              </div>
              {email && <div css={{ display: 'inline-block', verticalAlign: 'top' }}>{email}</div>}
            </div>
          )}
        </div>
      ) : (
        <Fragment>
          <div css={investorWrap}>
            <span css={{ width: 'calc(100% - 20px)', display: 'inline-block' }}>{name}</span>
            <div css={errorCircle} />
          </div>
          {servicer && (
            <div>
              <b>Servicer</b>: {servicer}
            </div>
          )}
          {subServicer && (
            <div>
              <b>SubServicer</b>: {subServicer}
            </div>
          )}
          {custodian && (
            <div>
              <b>Custodian</b>: {custodian}
            </div>
          )}
          {address && (
            <div>
              <b>Address</b>: {address}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
