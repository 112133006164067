import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

type Props = {
  percent: number;
};

const CircularProgressWithLabel = ({ percent }: Props) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress
      variant="determinate"
      value={percent < 100 ? percent : 100}
      style={{ color: '#f7b500', width: 66, height: 66 }}
    />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary">
        {percent}%
      </Typography>
    </Box>
  </Box>
);

export default CircularProgressWithLabel;
