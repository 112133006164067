/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';
import Icon, { IconType } from './Icon';

const checkBoxContainer: CSSObject = {
  height: 27,
  position: 'relative',
  width: 27,
};

const uncheckedStyles: CSSObject = {
  boxShadow: `0 0 0 1.4px ${colors.grayLight}`,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'inline-block',
  height: 25,
  width: 25,
  transition: 'all .2s',
  ':hover': {
    boxShadow: `0 0 0 1.4px ${colors.blue}`,
  },
};

const checkedStyles: CSSObject = {
  borderRadius: '50%',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'inline-block',
  height: 25,
  width: 25,
};

const disabledUncheckedStyles: CSSObject = {
  cursor: 'not-allowed',
  ':hover': {
    boxShadow: `0 0 0 1.4px ${colors.grayLight}`,
  },
};

const disabledCheckedStyles: CSSObject = {
  cursor: 'not-allowed',
  ellipse: { fill: `${colors.grayLight}` },
};

const labelStyles: CSSObject = {
  fontFamily: 'AktivGrotesk, sans-serif',
  fontSize: 14,
  left: 35,
  lineHeight: '27px',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
};

const disabledLabelStyles: CSSObject = {
  color: colors.grayDark,
};

type CheckboxProps = {
  checked: boolean;
  text?: string;
  labelOverrides?: object;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
};

export default function Checkbox({
  checked,
  text,
  onClick,
  disabled,
  labelOverrides,
}: CheckboxProps) {
  return (
    <div css={checkBoxContainer} onClick={!disabled ? onClick : () => {}}>
      {!checked ? (
        <span css={[uncheckedStyles, disabled && disabledUncheckedStyles]} data-style />
      ) : (
        <Icon icon={IconType.Active} css={[checkedStyles, disabled && disabledCheckedStyles]} />
      )}
      <div css={[labelStyles, labelOverrides, disabled && disabledLabelStyles]}>{text}</div>
    </div>
  );
}
