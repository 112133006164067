/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { Filter } from './types';
import produce from 'immer';

type Props = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
};

const CheckboxFilter = ({ filter, setFilter }: Props) => {
  const filterLabel = filter.label.replace(/([A-Z])/g, ' $1');

  return (
    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
      <FormControl component="fieldset" style={{ paddingLeft: 32 }}>
        <FormLabel component="legend" style={{ paddingTop: '9px', textTransform: 'capitalize' }}>
          {filterLabel}
        </FormLabel>
        <FormGroup>
          {filter.filterOptions.map(option => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={
                <Checkbox
                  color="error"
                  checked={option.active}
                  onChange={e => {
                    const newlySetFilter = produce(filter, (draft: Filter) => {
                      const updatedIndex = draft.filterOptions.findIndex(o => o.id === option.id);

                      if (updatedIndex !== -1) {
                        draft.filterOptions[updatedIndex].active = e.target.checked;
                      }
                    });

                    setFilter(newlySetFilter);
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default CheckboxFilter;
