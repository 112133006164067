/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CreateIcon from '@mui/icons-material/Create';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import { AuthContext } from '../components/AuthContext';
import { roleTypes } from '../constants';
import DataTable from '../components/ui/DataTable/DataTable';
import AddNoteInput from '../components/Notes/AddNoteInput';
import { apiPost } from '../adalConfig';
import { hasAccessToRoute, isAdmin, isTeamLead } from '../Utils';
import useDataTableFilters from '../components/ui/DataTable/UseDataTableFilters';
import { useToaster } from '../Hooks/toasters';
import { PODNumbers } from './Reports/ClientSearchReportTemplate';

const btn: CSSObject = {
  backgroundColor: '#007BFF',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  color: '#FFF',
  display: 'inline-block',
  padding: '.375rem .75rem',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: 'rgb(17, 82, 147)',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerScreen: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type ClientNote = {
  id: number;
  note: string;
  createdAt: Date;
  modifiedBy: string;
};

type RowData = {
  id: number;
  company: string;
  accountRep: string;
  auditor: string;
  arSupport: string;
  poBox: string | null;
  startDate: Date | null;
  podNumber: number | null;
  docsRequiringDetermination: number;
  clientNotes: ClientNote[];
};

const clientRouteUrl = '/clients/:clientId(\\d+)';

export default ({ history }) => {
  const [selectedClientId, setSelectedClientId] = useState(0);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});

  const classes = useStyles();

  const authContext = useContext(AuthContext);

  const { successToaster, errorToaster } = useToaster();

  const PODs = Object.entries(PODNumbers)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key, value: val }));

  const columns = [
    {
      field: 'id',
      title: 'ID',
      hidden: !authContext.roles.includes(roleTypes.Dev),
    },
    {
      field: 'company',
      title: 'Client',
    },
    {
      field: 'accountManager',
      title: 'Account Manager',
    },
    {
      field: 'arSupport',
      title: 'AR Support',
    },
    {
      field: 'auditor',
      title: 'Auditor',
    },
    {
      field: 'clientSuccessManager',
      title: 'Client Success Manager',
    },
    {
      field: 'poBox',
      title: 'POBox',
      render: rowData => (rowData.poBox ? rowData.poBox : 1125),
    },
    {
      field: 'startDate',
      title: 'Start Date',
      render: rowData =>
        rowData.startDate === null ? null : new Date(rowData.startDate).toLocaleDateString(),
    },
    {
      field: 'podNumber',
      title: 'POD',
      render: rowData =>
        rowData.podNumber ? PODs.filter(({ value }) => value === rowData.podNumber)[0].label : '',
    },
    {
      field: 'clientNotes',
      title: 'Notes',
      hidden: true,
    },
  ];

  const addClientNote = async (note: string) => {
    try {
      const { data: response } = await apiPost('/api/clients-notes/add-note', {
        clientId: selectedClientId,
        note,
      });

      if (response === 1) {
        successToaster('Successfully added the client note');
        setSelectedClientId(0);
        refreshDatatable();
      } else {
        errorToaster('Failed to add the client note');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const notesDisplay = (rowData: RowData) => (
    <Grid container spacing={3}>
      <Grid item xs={10} style={{ minHeight: '66px', overflowY: 'auto' }}>
        {rowData.clientNotes.length ? (
          rowData.clientNotes.map((clientNote: ClientNote) => (
            <div key={clientNote.id} style={{ padding: '10px 60px 0px' }}>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <b>{new Date(clientNote.createdAt).toLocaleDateString()}</b>
                </Grid>
                <Grid item xs={1}>
                  {clientNote.modifiedBy ? (
                    <Fragment>
                      <b>{clientNote.modifiedBy}</b>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={10}>
                  {clientNote.note.split('\n').map((notePart, i) => (
                    <p key={i}>{notePart}</p>
                  ))}
                </Grid>
              </Grid>
            </div>
          ))
        ) : (
          <div className={classes.centerScreen} style={{ minHeight: 'inherit' }}>
            No Notes
          </div>
        )}
      </Grid>
      <Grid item xs={2} className={classes.centerScreen}>
        <Tooltip title="Add Note">
          <AddCircleIcon color="primary" onClick={() => setSelectedClientId(rowData.id)} />
        </Tooltip>
      </Grid>
    </Grid>
  );

  const { dataTableFilterTypes, setDataTableFilterTypes } =
    useDataTableFilters('/api/clients/filters');

  return (
    <Fragment>
      {hasAccessToRoute(authContext.roles, clientRouteUrl) && (
        <NavLink
          to="/clients/new-client"
          css={[
            btn,
            {
              position: 'fixed',
              right: 32,
              top: 14,
              zIndex: 250,
              backgroundColor: '#2196f3',
              fontWeight: 600,
            },
          ]}
        >
          Add New Client
        </NavLink>
      )}
      <DataTable<RowData>
        title="Clients"
        columns={columns}
        url="/api/clients/client-datatable"
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        onRowClick={(event, rowData) => history.push(`/clients/${rowData.id}/loans`)}
        detailPanel={(rowData: RowData) =>
          (isAdmin(authContext.roles) || isTeamLead(authContext.roles)) && notesDisplay(rowData)
        }
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        actions={() => [
          {
            icon: () => <CreateIcon color="primary" />,
            tooltip: 'Edit Client',
            onClick: (event, rowData) => history.push(`/clients/${rowData.id}`),
            hidden: !hasAccessToRoute(authContext.roles, clientRouteUrl),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />

      <Modal open={selectedClientId !== 0} onClose={() => setSelectedClientId(0)}>
        <div className={classes.paper}>
          <AddNoteInput noteType="Client" addNote={addClientNote} />
        </div>
      </Modal>
    </Fragment>
  );
};
