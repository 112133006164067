/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { apiFetch } from '../../adalConfig';

interface PropTypes {
  docId: number;
}

async function getIsStitchedPolicy(docId: number) {
  const { data } = await apiFetch<boolean>(`/Api/DocumentEmail/IsStitchedPolicy/${docId}`);
  return data;
}

export default function StitchedPolicyDisclaimer({ docId }: PropTypes) {
  const [isStitched, setIsStitched] = useState(false);

  useEffect(() => {
    getIsStitchedPolicy(docId).then(s => {
      setIsStitched(s);
    });
  }, [docId]);

  return (
    <div>
      {isStitched && (
        <div
          css={{
            fontSize: 12,
            fontWeight: 600,
            color: '#f7a01b',
            border: '1px solid #E8E8E8',
          }}
        >
          This policy has been stitched from 2 attachments
        </div>
      )}
    </div>
  );
}
