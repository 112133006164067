/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext } from 'react';
import DataTable from '../components/ui/DataTable/DataTable';
import { AuthContext } from '../components/AuthContext';
import { roleTypes } from '../constants';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useDataTableFilters from '../components/ui/DataTable/UseDataTableFilters';
import ClientSearch from '../components/Utilities/ClientSearch';
import { Chip } from '@mui/material';

type RowData = {
  id: number;
  name?: string;
  address?: string;
  requiresOriginalDoc?: boolean;
};

const InvestorsTable = () => {
  const authContext = useContext(AuthContext);

  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    clearCustomFilter,
  } = useDataTableFilters('/api/investors/filters');

  const clientFilter = customFilters.find(filter => filter.label === 'Clients');

  const activeClientFilterOption = clientFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const client: { name: string; id: number } | null = activeClientFilterOption
    ? { id: activeClientFilterOption.id, name: activeClientFilterOption.label }
    : null;

  const iconRender = (rowData: RowData, property: string) =>
    rowData[property] ? (
      <CheckCircleOutlineIcon color="primary" />
    ) : (
      <RadioButtonUncheckedIcon color="action" />
    );

  const columns = [
    {
      field: 'id',
      title: 'ID',
      hidden: !authContext.roles.includes(roleTypes.Dev),
    },
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'address',
      title: 'Address',
      sorting: false,
    },
    {
      field: 'requiresOriginalDoc',
      title: 'Requires Original',
      render: (rowData: RowData) => iconRender(rowData, 'requiresOriginalDoc'),
    },
  ];

  function selectClient() {
    return selection => {
      clientFilter &&
        onCustomOptionSelected(clientFilter, {
          id: selection.id,
          label: selection.company,
          active: true,
        });
    };
  }

  return (
    <Fragment>
      {clientFilter?.filterOptions && clientFilter.filterOptions.length > 0 && client?.id && (
        <div css={{ marginTop: 32, marginLeft: 32 }}>
          <Chip
            variant="outlined"
            onClick={() => clearCustomFilter(clientFilter)}
            label="Clear Client Filter"
          />
        </div>
      )}
      <DataTable<RowData>
        title="Investors"
        columns={columns}
        url="/api/investors/DetailsWithShipments"
        onRowClick={(event, rowData) => window.open(`/investors/${rowData.id}`)}
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        renderCustomFilters={
          <div className="m2">
            Client
            {clientFilter && (
              <ClientSearch
                selectedClient={client?.id}
                onChange={selectClient()}
                styleOverrides={{ marginTop: 16 }}
              />
            )}
          </div>
        }
      />
    </Fragment>
  );
};

export default InvestorsTable;
