/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { match } from 'react-router';
import Paper from '@mui/material/Paper';
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CreateIcon from '@mui/icons-material/Create';
import { apiFetch, apiPost } from '../adalConfig';
import { AuthContext } from '../components/AuthContext';
import Header from '../components/ui/Header';
import TextInput from '../components/ui/Inputs/TextInput';
import { RightCaretLightIcon } from '../components/ui/icons';
import Notes from '../components/Notes';
import Alert, { defaultAlertData, warningAlert } from '../components/ui/Alert';
import { formatPhoneWithParenthesis, isDataAssociation, StripPhone } from '../Utils';
import { UnlinkAliasDialog } from '../components/UnlinkAliasDialog';
import { Alert as AlertType } from '../globalTypes/objects';
import { errorText } from '../components/CallCenter/UpdateContactInfoPanel';
import { useToaster } from '../Hooks/toasters';
import { roleTypes } from '../constants';
import { useQuery } from '@tanstack/react-query';
import Modal from '@mui/material/Modal';
import DataTable from '../components/ui/DataTable/DataTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    grid: {
      maxWidth: 1200,
      margin: 32,
      marginTop: 84,
    },
    paper: {
      padding: theme.spacing(3),
      border: '1px solid #EEEEEE',
      '& h2': { fontSize: 24, fontWeight: 600 },
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    buttonWrap: {
      paddingRight: 16,
      paddingBottom: 16,
      '& button:last-child': { marginLeft: 24 },
    },
  }),
);

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  div: { display: 'flex', alignItems: 'center', fontSize: 20 },
  '> div:first-of-type': {
    fontWeight: 400,
    paddingRight: 12,
  },
  svg: { marginRight: 12, marginTop: 2 },
};

const associatedContacts = {
  display: 'grid',
  gridTemplateColumns: '50% 25% 25%',
  marginTop: 20,
  wordWrap: 'break-word',
};

type Props = {
  match: match<{ titleCompanyId: string }>;
};

type TitleCompanyAssociation = {
  id: number;
  name: string;
};

type TitleCompanyNote = {
  id: number;
  note: string;
  modifiedBy: string;
  createdAt: string;
};

type TitleCompanyContact = {
  email: string;
  phone: string;
  loans: string;
};

export type TitleCompanyData = {
  id: number;
  name: string;
  phone: string;
  email: string;
  address: string;
  sendToGlobal: boolean;
  excludeFromFollowupsAndCC: boolean;
  preAssignedUser: string;
  additionalEmail: string;
  associations: TitleCompanyAssociation[];
  notes: TitleCompanyNote[];
  contacts: TitleCompanyContact[];
};

export type AssociateModalData = {
  association: TitleCompanyAssociation;
  isOpen: boolean;
};

type User = {
  id: number;
  fullName: string;
  userNameWithoutExtension: string;
};

type TitleCompanyContactsDrilldown = {
  loanId: string;
  client: string;
  loanNumber: string;
  dateFunded: string;
  dateImported: string;
  emailAddress: string;
};

const columns = [
  {
    title: 'LoanId',
    field: 'loanId',
  },
  {
    title: 'Client',
    field: 'client',
  },
  {
    title: 'Loan Number',
    field: 'loanNumber',
  },
  {
    title: 'Date Funded',
    field: 'dateFunded',
    render: ({ dateFunded }: TitleCompanyContactsDrilldown) =>
      new Date(dateFunded).toLocaleDateString(),
  },
  {
    title: 'Date Imported',
    field: 'dateImported',
    render: ({ dateImported }: TitleCompanyContactsDrilldown) =>
      new Date(dateImported).toLocaleDateString(),
  },
  {
    title: 'Email Address',
    field: 'emailAddress',
  },
];

const TitleCompany = ({ match }: Props) => {
  const authContext = useContext(AuthContext);
  const [titleCompanyData, setTitleCompanyData] = useState<TitleCompanyData>({
    id: 0,
    name: '',
    phone: '',
    email: '',
    address: '',
    sendToGlobal: false,
    excludeFromFollowupsAndCC: false,
    preAssignedUser: '',
    additionalEmail: '',
    associations: [],
    notes: [],
    contacts: [],
  });
  const [originalAdditionalEmail, setOriginalAdditionalEmail] = useState('');
  const [canEditTitleData, setCanEditTitleData] = useState(false);
  const [associateModalData, setAssociateModalData] = useState<AssociateModalData>({
    association: { id: 0, name: '' },
    isOpen: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [secondAlert, setSecondAlert] = useState<AlertType>(defaultAlertData);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [noUserSelectedError, setNoUserSelectedError] = useState(false);
  const [isDrilldownModalOpen, setIsDrilldownModalOpen] = useState(false);

  const { successToaster, errorToaster } = useToaster();

  const classes = useStyles();

  const getAssociatedContactsDrilldown = async (titleCompanyId: string) => {
    setIsDrilldownModalOpen(true);
    const { data } = await apiFetch<TitleCompanyContactsDrilldown[]>(
      '/api/titleCompanies/get-title-company-contacts-drilldown',
      { params: { titleCompanyId } },
    );

    return data;
  };

  const {
    data: drilldownData,
    refetch: loadDrilldown,
    isFetching: isLoadingDrilldown,
  } = useQuery<TitleCompanyContactsDrilldown[]>(
    ['associated-contacts-drilldown', { titleCompanyId: match.params.titleCompanyId }],
    () => getAssociatedContactsDrilldown(match.params.titleCompanyId),
    {
      enabled: false,
    },
  );

  useEffect(() => {
    const getTitleCompanyData = async () => {
      setIsLoading(true);
      try {
        const { data } = await apiFetch<TitleCompanyData>(
          `/api/titleCompanies/${match.params.titleCompanyId}/GetWithAssociationsAndNotes`,
        );

        setTitleCompanyData({
          id: data.id,
          name: data.name,
          address: data.address ?? '',
          email: data.email ?? '',
          phone: data.phone ?? '',
          sendToGlobal: data.sendToGlobal,
          excludeFromFollowupsAndCC: data.excludeFromFollowupsAndCC,
          preAssignedUser: data.preAssignedUser ?? '',
          additionalEmail: data.additionalEmail || '',
          associations: data.associations || [],
          notes: data.notes || [],
          contacts: data.contacts || [],
        });
        setOriginalAdditionalEmail(data.additionalEmail);
      } catch (e) {
        if (e.response) {
          const errorMessage = await new Response(e.response.data).text();
          errorToaster(errorMessage);
        } else {
          errorToaster(e.message);
        }
      } finally {
        setIsLoading(false);
      }
    };
    getTitleCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.titleCompanyId]);

  useEffect(() => {
    const getUsers = async () => {
      const { data } = await apiFetch<User[]>('/api/users/getUsers');
      setUsersList(data);
    };
    getUsers();
  }, []);

  const unlinkAssociation = async (url: string) => {
    setIsLoading(true);
    setAssociateModalData({ ...associateModalData, ...{ isOpen: false } });

    await apiPost(url, { int: associateModalData.association.id });

    const { data: titleCompanyAssociations } = await apiFetch(
      `/api/titleCompanies/${titleCompanyData.id}/Associations`,
    );
    setTitleCompanyData({ ...titleCompanyData, ...{ associations: titleCompanyAssociations } });
    setIsLoading(false);
  };

  const closeModal = () => setAssociateModalData({ ...associateModalData, ...{ isOpen: false } });

  const updateTitleCompany = async () => {
    if (titleCompanyData.excludeFromFollowupsAndCC && !titleCompanyData.preAssignedUser) {
      setNoUserSelectedError(true);
      return;
    }

    const titleCompany = {
      id: titleCompanyData.id,
      address: titleCompanyData.address,
      email: titleCompanyData.email,
      phone: titleCompanyData.phone,
      sendToGlobal: titleCompanyData.sendToGlobal,
      additionalEmail: titleCompanyData.additionalEmail,
      excludeFromFollowupsAndCC: titleCompanyData.excludeFromFollowupsAndCC,
      preAssignedUser: titleCompanyData.preAssignedUser || null,
    };

    try {
      const { data: sendToGlobalAlert } = await apiPost('/api/titleCompanies/update', titleCompany);
      successToaster('Successfully updated company info');
      setCanEditTitleData(false);
      if (sendToGlobalAlert) {
        setSecondAlert(
          warningAlert(
            "Title Company was removed from outreach and followups, but is not set to 'send to global'",
          ),
        );
      }
    } catch (e) {
      const errorMessage = errorText(e);
      errorToaster(errorMessage);
    }
    fetchNotes();
  };

  const addNote = async (note: string) => {
    const { data: response } = await apiPost('/api/title-companies-notes/add', {
      titleCompanyId: titleCompanyData.id,
      note,
    });

    if (response === 1) {
      successToaster('Successfully added the new note');
    }

    fetchNotes();
  };

  const fetchNotes = async () => {
    const { data: notes } = await apiFetch('/api/title-companies-notes/notes', {
      params: { titleCompanyId: titleCompanyData.id },
    });

    setTitleCompanyData({ ...titleCompanyData, ...{ notes } });
  };

  return (
    <Fragment>
      <Header styles={headerStyles} headerText="Title Companies" fixed>
        <RightCaretLightIcon /> {titleCompanyData.name}
      </Header>
      <Alert alertData={secondAlert} position={{ vertical: 'top', horizontal: 'center' }} />
      <div className="roboto roboto">
        <Grid container spacing={3} className={classes.grid}>
          <Grid item xs={6}>
            <Paper className={classes.paper} elevation={0}>
              <span css={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2 css={{ paddingBottom: 24 }}>Information</h2>
                {isDataAssociation(authContext.roles) ||
                authContext.roles.includes(roleTypes.TitlePortalSupport) ? (
                  <CreateIcon color="action" onClick={() => setCanEditTitleData(true)} />
                ) : null}
              </span>
              <div className="mb5">
                <div className="mb5">
                  <TextInput
                    type="text"
                    label="Phone Number"
                    value={formatPhoneWithParenthesis(titleCompanyData.phone)}
                    disabled={!canEditTitleData}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTitleCompanyData({
                        ...titleCompanyData,
                        ...{ phone: StripPhone(e.target.value) },
                      })
                    }
                  />
                  <TextInput
                    type="email"
                    label="Email"
                    value={titleCompanyData.email}
                    disabled={!canEditTitleData}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTitleCompanyData({ ...titleCompanyData, ...{ email: e.target.value } })
                    }
                    labelOverrides={{ marginTop: 16 }}
                  />
                  <TextInput
                    type="text"
                    label="Address"
                    value={titleCompanyData.address}
                    disabled={!canEditTitleData}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setTitleCompanyData({ ...titleCompanyData, ...{ address: e.target.value } })
                    }
                    labelOverrides={{ marginTop: 16 }}
                  />
                  <FormControlLabel
                    label="Send to Global"
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        color="primary"
                        checked={titleCompanyData.sendToGlobal}
                        disabled={
                          !canEditTitleData ||
                          titleCompanyData.name.toLowerCase().includes('dp client')
                        }
                        onClick={() =>
                          setTitleCompanyData({
                            ...titleCompanyData,
                            sendToGlobal: !titleCompanyData.sendToGlobal,
                            // this is the value that hasn't been switched yet, so if it's true, it means it WILL BE set to false,
                            // so we want to clear the value of additionalEmail
                            additionalEmail: titleCompanyData.sendToGlobal
                              ? ''
                              : originalAdditionalEmail,
                          })
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    label="Remove from followups and outreach queue"
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        color="primary"
                        checked={titleCompanyData.excludeFromFollowupsAndCC}
                        disabled={!canEditTitleData}
                        onClick={() =>
                          setTitleCompanyData({
                            ...titleCompanyData,
                            excludeFromFollowupsAndCC: !titleCompanyData.excludeFromFollowupsAndCC,
                            preAssignedUser: '',
                          })
                        }
                      />
                    }
                  />
                  {titleCompanyData.excludeFromFollowupsAndCC && (
                    <Autocomplete
                      disabled={!canEditTitleData}
                      options={usersList}
                      getOptionLabel={option => option.fullName}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.fullName}
                          </li>
                        );
                      }}
                      value={
                        usersList.find(
                          user =>
                            user.userNameWithoutExtension === titleCompanyData.preAssignedUser,
                        ) || null
                      }
                      onChange={(_, value) =>
                        setTitleCompanyData({
                          ...titleCompanyData,
                          preAssignedUser: value?.userNameWithoutExtension ?? '',
                        })
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Pre-Assigned User"
                          variant="outlined"
                          error={noUserSelectedError}
                          onClick={() => setNoUserSelectedError(false)}
                        />
                      )}
                    />
                  )}
                  {titleCompanyData.sendToGlobal && (
                    <TextInput
                      type="email"
                      label="Additional Email"
                      value={titleCompanyData.additionalEmail}
                      disabled={!canEditTitleData}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setTitleCompanyData({
                          ...titleCompanyData,
                          ...{ additionalEmail: e.target.value },
                        })
                      }
                      labelOverrides={{ marginTop: 16 }}
                    />
                  )}
                </div>
                {canEditTitleData ? (
                  <div className="df jcfe mb2">
                    <Button onClick={() => setCanEditTitleData(false)} color="primary">
                      Cancel
                    </Button>
                    <Button
                      onClick={updateTitleCompany}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      Update
                    </Button>
                  </div>
                ) : null}
              </div>
              <Divider />
              <h2 className="mb2 mt5">Aliases</h2>
              {!titleCompanyData.associations.length ? (
                <Typography variant="body2" component="p" className="fs16 bold">
                  No Aliases
                </Typography>
              ) : (
                <div>
                  {titleCompanyData.associations.map(associate => (
                    <div
                      key={associate.id}
                      css={{ display: 'grid', gridTemplateColumns: '50% 46px', marginTop: 20 }}
                    >
                      <span>{associate.name}</span>
                      {!isDataAssociation(authContext.roles) ? null : (
                        <div
                          css={{
                            fontWeight: 500,
                            fontSize: 16,
                            cursor: 'pointer',
                            color: '#F73378',
                          }}
                          onClick={() =>
                            setAssociateModalData({ association: associate, isOpen: true })
                          }
                        >
                          Unlink
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper} elevation={0}>
              <Notes addNote={note => addNote(note)} notes={titleCompanyData.notes} />
            </Paper>
            <br />
            <Paper className={classes.paper} elevation={0}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <h2 css={{ paddingBottom: 24 }}>Associated Contacts</h2>
                </div>
                <div>
                  <Button onClick={() => loadDrilldown()}>View drilldown</Button>
                </div>
              </div>
              <div css={associatedContacts}>
                <span>
                  <b>Email Address</b>
                </span>
                <span>
                  <b>Phone Number</b>
                </span>
                <span>
                  <b>Total Loans</b>
                </span>
              </div>
              <div css={{ maxHeight: 300, overflow: 'auto' }}>
                {titleCompanyData.contacts.map(contact => (
                  <div key={contact.email} css={associatedContacts}>
                    <span>{contact.email}</span>
                    <span>{formatPhoneWithParenthesis(contact.phone)}</span>
                    <span css={{ textAlign: 'center' }}>{contact.loans}</span>
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <UnlinkAliasDialog
        type="Title"
        associateModalData={associateModalData}
        data={titleCompanyData}
        onClick={unlinkAssociation}
        onClose={closeModal}
      />
      {isLoading && (
        <CircularProgress css={{ position: 'fixed', left: '50%', top: '50%' }} size={50} />
      )}

      <Modal onClose={() => setIsDrilldownModalOpen(false)} open={isDrilldownModalOpen}>
        <div className={classes.modal}>
          {isLoadingDrilldown ? (
            <div className="center-in-parent">
              <CircularProgress size="20" disableShrink />
            </div>
          ) : (
            <DataTable<TitleCompanyContactsDrilldown>
              title="Title company contact loans"
              columns={columns}
              data={drilldownData}
              options={{
                exportFileName: 'Title company contact loans',
              }}
            />
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default TitleCompany;
