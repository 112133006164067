/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/ui/Tabs/MaterialTabPanel';
import { AuthContext } from '../components/AuthContext';
import { isBasicUser } from '../Utils';
import SortClientDocuments from '../components/Documents/Sort/3.9/SortClientDocuments';
import Dashboard from '../components/Documents/Sort/3.9/Dashboard';
import { PrintingContextProvider } from '../PrintingContext';

const Sort = () => {
  const [tab, setTab] = useState(0);

  const { roles } = useContext(AuthContext);
  return (
    <div className="m1">
      {!isBasicUser(roles) ? (
        <SortClientDocuments />
      ) : (
        <Fragment>
          <Tabs
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTab(newValue)}
          >
            {['Sort', 'Dashboard'].map((tabLabel, i) => (
              <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
            ))}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <PrintingContextProvider>
              <SortClientDocuments />
            </PrintingContextProvider>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Dashboard />
          </TabPanel>
        </Fragment>
      )}
    </div>
  );
};

export default Sort;
