/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { useState } from 'react';
import { AuthConsumer } from '../../AuthContext';
import colors from '../../../styles/colors';
import { AccountIcon } from '../../ui/icons';
import { useMsal } from '@azure/msal-react';

const avatarStyles: CSSObject = {
  alignItems: 'center',
  borderRadius: 35,
  color: colors.blue,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
};

const avatarIcon: CSSObject = {
  alignItems: 'center',
  background: '#ECEDF8',
  borderRadius: '50%',
  color: colors.blue,
  display: 'inline-flex',
  fontWeight: 600,
  height: 48,
  justifyContent: 'center',
  width: 48,
};

const signoutStyles = {
  backgroundColor: '#fff',
  bottom: 72,
  color: '#0828cc',
  fontWeight: 500,
  left: 12,
  overflow: 'hidden',
  position: 'absolute',
  textTransform: 'uppercase',
  transition: 'all .2s',
  width: 0,
  '> div': {
    alignItems: 'center',
    border: '1px solid #0828cc',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const buttonStyles = {
  alignItems: 'center',
  backgroundColor: '#0828cc',
  border: '1px solid #0828cc',
  borderRadius: 4,
  color: '#fff',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  margin: 8,
  minWidth: 99,
  padding: '8px 16px',
  position: 'relative',
  transition: 'all .2s',
  zIndex: '50',
  '&:hover': { backgroundColor: '#00009a' },
};

const cancelButton = {
  backgroundColor: '#fff',
  border: '1px solid #0828cc',
  color: '#0828cc',
  '&:hover': { backgroundColor: '#e5e5ea' },
};

export default function Avatar({ isOpen }) {
  const [signOut, openSignout] = useState(false);
  const { instance, accounts } = useMsal();

  return (
    <AuthConsumer>
      {({ user }) => (
        <div css={isOpen ? { margin: '0 16px' } : { margin: '0 8px' }}>
          <div
            onClick={() => openSignout(!signOut)}
            css={[avatarStyles, isOpen && { justifyContent: 'flex-start', padding: 8 }]}
          >
            {isOpen && user ? (
              <div className="df aic hidden" css={{ padding: 3, svg: { transform: 'scale(1.2)' } }}>
                <AccountIcon />
                <span css={{ marginLeft: 8, fontWeight: 700, fontSize: 16 }}>
                  {user.givenName} {user.surname}
                </span>
              </div>
            ) : (
              <div css={avatarIcon}>
                {user &&
                  user.givenName &&
                  user.surname &&
                  `${user.givenName.charAt(0)}${user.surname.charAt(0)}`}
              </div>
            )}
          </div>
          <div css={[signoutStyles, signOut && { width: 232 }]}>
            <div>
              <div
                css={buttonStyles}
                onClick={() => {
                  const account = instance.getActiveAccount();
                  return instance.logoutRedirect({
                    account: account,
                    logoutHint: account?.idTokenClaims?.login_hint,
                  });
                }}
              >
                Sign out
              </div>
              <div css={[buttonStyles, cancelButton]} onClick={() => openSignout(false)}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </AuthConsumer>
  );
}
