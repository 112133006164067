import { useState, useEffect } from 'react';
import { apiFetch } from '../adalConfig';
import { Failure, Loading, NotAsked, RemoteData, Success } from '../globalTypes/RemoteData';
import { Document } from '../globalTypes/objects';
import { isDocument } from '../Utils';

async function getIsFaultyDoc(docId: number): Promise<boolean> {
  const { data } = await apiFetch<boolean>(`/api/documents/IsFaultyDocument/${docId}`);
  return data;
}

export default function useIsFaultyDoc(document?: Document | number) {
  const [isFaultyDoc, setIsFaultyDoc] = useState<RemoteData<boolean, Error>>(NotAsked);

  const docId = isDocument(document) ? document.id : document;

  useEffect(() => {
    setIsFaultyDoc(NotAsked);
    if (docId) {
      setIsFaultyDoc(Loading);
      getIsFaultyDoc(docId).then(
        res => setIsFaultyDoc(Success(res)),
        e => setIsFaultyDoc(Failure(e)),
      );
    }
  }, [docId]);

  return isFaultyDoc;
}
