/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Shipping } from '../../components/ReportsDashboard/types';
import { roleTypes } from '../../constants';
import { AuthContext } from '../../components/AuthContext';
import Grid from '@mui/material/Grid';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import DataTable from '../../components/ui/DataTable/DataTable';
import Modal from '@mui/material/Modal';
import ShippingDrillDown from './ShippingDrillDown';

export enum ReportType {
  Client,
  Investor,
}

type ClientAndInvestorData = {
  id: number;
  documentCountReadyToBeShipped: number;
  digitalsNotBeingShipped: number;
};

type ClientData = ClientAndInvestorData & {
  kind: 'client';
  client: string;
};

type InvestorData = ClientAndInvestorData & {
  kind: 'investor';
  investor: string;
};

type RowData = ClientData | InvestorData;

export type DrillDownData = {
  id: number;
  reportType?: ReportType;
  name: string;
};

export type ShippingDrillDownRowData = {
  loanNumber: string;
  barcode: number;
  docType: string;
  format: string;
  dateUploaded: Date;
  client: string;
  investor: string;
  requiresOriginal: boolean;
};

const DetailedShippingReport = () => {
  const [shippingReport, setShippingReport] = useState<Shipping>({
    percentOfMonthlyGoal: 0,
    percentThatAreHardCopies: 0,
    readyForShippingGroupedByClients: [],
    readyForShippingGroupedByInvestors: [],
    readyToBeShipped: 0,
    digitalsNotBeingShipped: 0,
    readyToBeShippedAsOfToday: 0,
    digitalExclusionsAsOfToday: 0,
    topFiveInvestors: [],
    totalDocsShippedThisMonth: 0,
    totalDocsShippedToday: 0,
    totalYearlyToDate: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const { roles } = useContext(AuthContext);

  useEffect(() => {
    const getReportData = async () => {
      const { data } = await apiFetch<Shipping>('/api/reports/detailed-shipping-report');
      setShippingReport(data);
      setIsLoading(false);
    };

    getReportData();
  }, []);

  const columns = (reportType: ReportType) => [
    {
      field: 'id',
      title: 'ID',
      hidden: !roles.includes(roleTypes.Dev),
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    {
      field: reportType == ReportType.Investor ? 'investor' : 'client',
      title: reportType == ReportType.Investor ? 'Investor' : 'Client',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    {
      field: 'documentCountReadyToBeShipped',
      title: 'Document Count Ready To Be Shipped',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
      render: (rowData: RowData) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setDrillDownData({
              id: rowData.id,
              reportType,
              name: rowData.kind === 'client' ? rowData.client : rowData.investor,
            })
          }
        >
          {rowData.documentCountReadyToBeShipped}
        </Link>
      ),
    },
    {
      field: 'digitalsNotBeingShipped',
      title: 'Digitals Not Being Shipped',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
  ];

  return (
    <Fragment>
      {isLoading ? (
        <div className="center-in-parent">
          <CircularProgress size="75" disableShrink />
        </div>
      ) : (
        <div style={{ padding: '10px', marginTop: '22px' }}>
          <Paper style={{ padding: '20px' }}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <b>Total Docs Shipped Today</b>:{' '}
                {shippingReport.totalDocsShippedToday.toLocaleString()}
              </Grid>
              <Grid item xs={3}>
                <b>Percent That are hard copies</b>: {shippingReport.percentThatAreHardCopies}
              </Grid>
              <Grid item xs={3}>
                <b>Ready To Be Shipped</b>:{' '}
                <Link
                  component="button"
                  underline="hover"
                  variant="body2"
                  onClick={() => setDrillDownData({ id: 0, reportType: undefined, name: '' })}
                >
                  {shippingReport.readyToBeShipped.toLocaleString()}
                </Link>
              </Grid>
              <Grid item xs={3}>
                <b>Digitals Not Being Shipped</b>:{' '}
                {shippingReport.digitalsNotBeingShipped.toLocaleString()}
              </Grid>
              <Grid item xs={3}>
                <b>Ready To Be Shipped As Of Today</b>:{' '}
                {shippingReport.readyToBeShippedAsOfToday.toLocaleString()}
              </Grid>
              <Grid item xs={3}>
                <b>Digital Exclusions As Of Today</b>:{' '}
                {shippingReport.digitalExclusionsAsOfToday.toLocaleString()}
              </Grid>
              <Grid item xs={3}>
                <b>Total Docs Shipped This Month</b>:{' '}
                {shippingReport.totalDocsShippedThisMonth.toLocaleString()}
              </Grid>
              <Grid item xs={3}>
                <b>Percent Of Monthly Goal</b>: {shippingReport.percentOfMonthlyGoal}
              </Grid>
              <Grid item xs={3}>
                <b>Total Yearly To Date</b>: {shippingReport.totalYearlyToDate.toLocaleString()}
              </Grid>
            </Grid>
          </Paper>
          <div style={{ marginTop: '10px', marginLeft: '-32px' }}>
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <DataTable<RowData>
                  title="Top 5 Investor"
                  columns={columns(ReportType.Investor)}
                  data={shippingReport.topFiveInvestors}
                  headerStyle={{ whiteSpace: 'normal' }}
                  options={{
                    search: false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <DataTable<RowData>
                  title="Ready To Be Shipped Grouped By Investor"
                  columns={columns(ReportType.Investor)}
                  data={shippingReport.readyForShippingGroupedByInvestors}
                  headerStyle={{ whiteSpace: 'normal' }}
                  options={{
                    search: false,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <DataTable<RowData>
                  title="Ready To Be Shipped Grouped By Client"
                  columns={columns(ReportType.Client)}
                  data={shippingReport.readyForShippingGroupedByClients}
                  headerStyle={{ whiteSpace: 'normal' }}
                  options={{
                    search: false,
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      )}

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <ShippingDrillDown drillDownData={drillDownData} />
      </Modal>
    </Fragment>
  );
};

export default DetailedShippingReport;
