/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';

import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { SidebarConsumer } from '../components/Layout/Sidebar/SidebarContext';
import AssociationPanel from '../components/Imports/ImportErrors/AssociationErrors/AssociationPanel';
import Header from '../components/ui/Header';
import ImportHeader from '../components/Imports/ImportHeader';
import {
  AssociationError,
  AssocState,
  errorType,
  SuccessTimeout,
} from '../components/Imports/ImportErrors/ImportErrorsUtils';
import ErrorPanel from '../components/Imports/ImportErrors/AssociationErrors/ErrorPanel';
import MainContentWrap from '../components/MainContentWrap';
import StatusButton, { StatusTypes } from '../components/ui/StatusButton';
import AddNewContactForm from '../components/Utilities/Forms/AddNewContactForm';
import Paginator from '../components/Imports/ImportErrors/Paginator';
import { panelWrap } from '../components/ui/Panel';

import ErrorPanelSkeleton from '../components/Imports/ImportErrors/AssociationErrors/SkeletonComponents/ErrorPanelSkeleton';
import NoData from '../components/ui/NoData';
import { apiFetch, apiPost } from '../adalConfig';
import { IconType } from '../components/ui/Icon';
import Tab, { TabGroup } from '../components/ui/Tabs/Tab';
import { Client } from '../globalTypes/objects';
import { ClientsConsumer } from '../ClientsContext';

// #region css
const buttonSection = {
  marginTop: 32,
  display: 'flex',
  justifyContent: 'center',
};

const holderDiv = {
  display: 'flex',
  justifyContent: 'space-between',
};
// #endregion

// #region pure functions

const getErrorIdCount = async (mode, clientId) => {
  const url = `/api/associations/GetAssociationCount?type=${mode}&clientId=${clientId}`;

  const { data: count } = await apiFetch<number>(url);
  return count;
};

const getAssociationError = async (errorIndex, mode, clientId) => {
  const url =
    mode === errorType.title
      ? `/api/associations/getNextTitleAssociation?clientId=${clientId}`
      : `/api/associations/getNextInvestorAssociation?clientId=${clientId}`;
  const {
    data: { association, associationInformation },
  } = await apiFetch(url);

  return {
    ...association,
    associationInformation,
    type: mode,
    errorIndex,
    currentAssocState: AssocState.unmatched,
    currentAddState: undefined,
  };
};

const getAssociationErrorById = async (id, errorIndex, mode, selectedClient) => {
  const url =
    mode === errorType.title
      ? `/api/associations/getTitleAssociationById?id=${id}&clientId=${selectedClient}`
      : `/api/associations/getInvestorAssociationById?id=${id}&clientId=${selectedClient}`;
  const {
    data: { association, associationInformation },
  } = await apiFetch(url);

  return {
    ...association,
    associationInformation,
    type: mode,
    errorIndex,
    currentAssocState: AssocState.unmatched,
    currentAddState: undefined,
  };
};

const setAssociation = async (associationError, selectedMatchId) => {
  const url =
    associationError.type === errorType.title
      ? '/api/associations/associateTitleCompany'
      : '/api/associations/associateInvestor';
  await apiPost(url, [associationError.id, selectedMatchId]);
};

const createNewContact = async associationError => {
  const url =
    associationError.type === errorType.title
      ? '/api/associations/insertTitleCompany'
      : '/api/associations/insertInvestor';
  await apiPost(url, associationError, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getAddButtonText = currentAddState => {
  if (currentAddState === AssocState.adding) {
    return 'Adding New Contact';
  }
  return currentAddState === AssocState.added ? 'New Contact Added' : 'Add New Contact';
};

const getAddButtonStatus = currentAddState => {
  if (currentAddState === AssocState.addError) {
    return StatusTypes.error;
  }
  if (currentAddState === AssocState.added) {
    return StatusTypes.success;
  }
  if (currentAddState === AssocState.adding) {
    return StatusTypes.loading;
  }
  return StatusTypes.initial;
};

// #endregion

type AssociationErrorsState = {
  associationError: AssociationError;
  selectedMatchId?: number;
  errorIdObjs: number[];
  errorIdCount: number;
  showForm: boolean;
  loading: boolean;
  disablePaginator: boolean;
  disablePreviousPaginator: boolean;
  disableNextPaginator: boolean;
  mode: string;
};

export default class AssociationErrors extends Component<any, AssociationErrorsState> {
  state = {
    associationError: {} as AssociationError,
    selectedMatchId: undefined,
    errorIdObjs: [] as number[],
    errorIdCount: 0,
    showForm: false,
    loading: false,
    disablePaginator: false,
    disablePreviousPaginator: false,
    disableNextPaginator: false,
    mode: errorType.title,
    selectedClient: {} as Client,
  };

  async componentDidMount() {
    try {
      const { mode, selectedClient } = this.state;
      this.setState({ loading: true });
      const errorIdCount = await getErrorIdCount(mode, selectedClient?.id);
      const errorIdObjs = [];
      this.setState({ loading: false, errorIdObjs, errorIdCount });
      if (errorIdCount === 0) {
        return;
      }
      const associationError = await getAssociationError(0, mode, selectedClient?.id);
      await this.setState({
        associationError,
        disablePreviousPaginator: true,
        disableNextPaginator: associationError.id == null,
      });
    } catch (error) {
      // display 500 page
      this.setState({ loading: false });
      throw new Error(error);
    }
  }

  // #region functions
  refreshFullState = async index => {
    const { mode, errorIdObjs, associationError, selectedClient } = this.state;
    this.setState({ disablePaginator: true });

    const errorIdCount = await getErrorIdCount(mode, selectedClient?.id);

    if (errorIdCount === 0) {
      this.setState({ errorIdCount: 0, disablePaginator: false });
      return;
    }

    await this.setState({
      errorIdObjs,
      errorIdCount,
      selectedMatchId: undefined,
      disablePaginator: false,
      disablePreviousPaginator: index === 0,
      disableNextPaginator: associationError.id == null,
    });
  };

  refreshErrorCount = async () => {
    const { mode, selectedClient } = this.state;
    this.setState({ disablePaginator: true });

    const errorIdCount = await getErrorIdCount(mode, selectedClient?.id);
    this.setState({ errorIdCount, disablePaginator: false });
  };

  refreshState = async index => {
    const { errorIdObjs, associationError, errorIdCount } = this.state;

    if (errorIdCount !== 0) {
      this.setState({ disablePaginator: true });
      await this.setState({
        errorIdObjs,
        errorIdCount,
        selectedMatchId: undefined,
        disablePaginator: false,
        disablePreviousPaginator: index === 0,
        disableNextPaginator: associationError.id == null,
      });
    }
  };

  setNewAssociationError = async index => {
    const { mode, errorIdObjs, selectedClient } = this.state;

    const associationError = await getAssociationError(index, mode, selectedClient?.id);
    errorIdObjs.push(associationError.id);

    await this.setState({ errorIdObjs, associationError });
  };

  setPreviousAssociationError = async index => {
    const { mode, errorIdObjs, selectedClient } = this.state;

    const originalId = errorIdObjs[index];
    const associationError = await getAssociationErrorById(
      originalId,
      index,
      mode,
      selectedClient?.id,
    );

    if (originalId !== associationError.id) {
      // record was locked so new record instead
      errorIdObjs[index] = associationError.id;
    }

    await this.setState({ errorIdObjs, associationError });
  };

  setNewAfterAssociation = async index => {
    const { mode, errorIdObjs, selectedClient } = this.state;

    const associationError = await getAssociationError(index, mode, selectedClient?.id);
    errorIdObjs[index] = associationError.id;

    await this.setState({ errorIdObjs, associationError });
  };

  onPreviousClick = async () => {
    const {
      errorIdCount,
      associationError: { errorIndex },
    } = this.state;
    const prevIndex = errorIndex - 1 >= 0 ? errorIndex - 1 : errorIdCount - 1;
    await this.setPreviousAssociationError(prevIndex);
    await this.refreshFullState(prevIndex);
  };

  onNextClick = async () => {
    const {
      errorIdObjs,
      errorIdCount,
      selectedClient,
      associationError: { errorIndex },
    } = this.state;
    const nextIndex = errorIndex + 1 < errorIdCount ? errorIndex + 1 : 0;
    const isLoadedId = errorIdObjs.length > nextIndex;

    if (isLoadedId) {
      await this.setPreviousAssociationError(nextIndex);
    } else {
      await this.setNewAssociationError(nextIndex);
    }

    await this.refreshFullState(nextIndex);
  };

  onChangeMode = async mode => {
    const errorIdObjs = [];
    const associationError = {} as AssociationError;
    this.setState({ mode, errorIdObjs, associationError }, async () => {
      await this.refreshErrorCount();
      await this.setNewAssociationError(0);
      await this.refreshState(0);
    });
  };

  onChangeClient = async selectedClient => {
    const errorIdObjs = [];
    const associationError = {} as AssociationError;
    this.setState({ errorIdObjs, associationError, selectedClient }, async () => {
      await this.refreshErrorCount();
      await this.setNewAssociationError(0);
      await this.refreshState(0);
    });
  };

  nextError = async () => {
    const { associationError, errorIdCount } = this.state;
    await setTimeout(async () => {
      const nextIndex =
        associationError.errorIndex === errorIdCount - 1 ? 0 : associationError.errorIndex;
      await this.setNewAfterAssociation(nextIndex);
      await this.refreshFullState(nextIndex);
    }, SuccessTimeout);
  };

  onAddNewContactClick = async () => {
    this.setState({ showForm: true });
  };

  onSaveContact = async ({ contactInformation, updateAssociationErrorCount }) => {
    await this.setState({
      associationError: {
        ...contactInformation,
        currentAddState: AssocState.adding,
      },
      showForm: false,
    });
    const { associationError, errorIdObjs } = this.state;
    try {
      await createNewContact(associationError);

      this.setState({
        associationError: {
          ...associationError,
          currentAddState: AssocState.added,
        },
      });
      await this.nextError();
    } catch (error) {
      this.setState({
        associationError: {
          ...associationError,
          currentAddState: AssocState.addError,
        },
      });
    }
    await updateAssociationErrorCount();
  };

  onCancelContact = () => {
    this.setState({ showForm: false });
  };

  onAssociateClick = async () => {
    const { associationError, selectedMatchId, errorIdObjs } = this.state;
    this.setState({
      associationError: {
        ...associationError,
        currentAssocState: AssocState.associating,
      },
    });
    try {
      await setAssociation(associationError, selectedMatchId);

      this.setState({
        associationError: {
          ...associationError,
          currentAssocState: AssocState.associated,
        },
      });
      await this.nextError();
    } catch (error) {
      await this.setState({
        associationError: {
          ...associationError,
          currentAssocState: AssocState.assocError,
        },
      });
    }
  };

  onMatchSelect = async id => {
    const { selectedMatchId, associationError } = this.state;
    await this.setState({ selectedMatchId: id !== selectedMatchId && id });
    await this.setState({
      associationError: {
        ...associationError,
        currentAssocState: this.state.selectedMatchId ? AssocState.matched : AssocState.unmatched,
      },
    });
  };

  downloadReport = async () => {
    try {
      const { headers, data } = await apiFetch('/api/associations/unassociated-investor-report', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      let fileName;
      const fileParts = headers['content-disposition'].match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (fileParts.length >= 2) {
        fileName = fileParts[1].replace(/"/g, '');
      }

      a.download = fileName || 'Export Pending Investors.xlsx';

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        alert(errorMessage || e.message);
      } else {
        alert(e.message);
      }
    }
  };
  // #endregion

  render() {
    const {
      associationError,
      errorIdObjs,
      errorIdCount,
      selectedMatchId,
      showForm,
      loading,
      disablePaginator,
      disablePreviousPaginator,
      disableNextPaginator,
    } = this.state;
    const totalAssociationErrors = errorIdCount;
    const emptyAssociation = associationError?.id == null;
    const isAssociate =
      associationError.currentAssocState === AssocState.associating ||
      associationError.currentAssocState === AssocState.associated;
    const loadingSkeleton = !Object.keys(associationError).length;
    const isBusy =
      associationError.currentAddState === AssocState.adding ||
      associationError.currentAddState === AssocState.added ||
      isAssociate;

    return (
      <ClientsConsumer>
        {clients => (
          <div css={{ position: 'relative', paddingTop: 56 }}>
            <Header headerText="Import Errors" fixed />
            <div css={{ position: 'fixed', width: 800, top: 0, zIndex: 250 }}>
              <ImportHeader />
            </div>
            <div css={{ padding: '24 40 0' }}>
              <div
                css={{
                  maxWidth: 1280,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <TabGroup selectionDefaultIdx={1}>
                  <Tab
                    onClick={() => this.onChangeMode(errorType.investor, this.state.selectedClient)}
                  >
                    Investors
                  </Tab>
                  <Tab
                    onClick={() => this.onChangeMode(errorType.title, this.state.selectedClient)}
                  >
                    Title Companies
                  </Tab>
                </TabGroup>
                <Autocomplete
                  style={{ height: 40, width: 250 }}
                  options={clients}
                  getOptionLabel={(client: Client) => client.company ?? ''}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Select A Client" />
                  )}
                  value={this.state.selectedClient}
                  onChange={(e, newValue) => this.onChangeClient(newValue)}
                />
              </div>
            </div>
            {loading ? null : (
              <SidebarConsumer>
                {({ updateAssociationErrorCount }) => (
                  <MainContentWrap>
                    {!totalAssociationErrors ? (
                      <NoData
                        message="No association errors"
                        subMessage="Check back when the next spreadsheet is imported"
                      />
                    ) : (
                      <div css={{ maxWidth: 1280 }}>
                        <Paginator
                          totalNum={totalAssociationErrors}
                          currentNum={associationError.errorIndex + 1}
                          onPreviousClick={this.onPreviousClick}
                          onNextClick={this.onNextClick}
                          isDisabled={isBusy || disablePaginator}
                          isDisabledForPrevious={disablePreviousPaginator}
                          isDisabledForNext={disableNextPaginator}
                          loadingSkeleton={loadingSkeleton}
                        />

                        {emptyAssociation && !loadingSkeleton && (
                          <div
                            css={{
                              maxWidth: 1280,
                              textAlign: 'center',
                              color: 'red',
                              marginBottom: '32px',
                              marginTop: '8px',
                            }}
                          >
                            All remaining associations locked! Please try again soon
                          </div>
                        )}

                        <div css={holderDiv}>
                          <div css={panelWrap}>
                            {loadingSkeleton ? (
                              <ErrorPanelSkeleton />
                            ) : (
                              <ErrorPanel
                                associationError={associationError}
                                refresh={this.nextError}
                                disablePaginator={() => this.setState({ disablePaginator: true })}
                              />
                            )}

                            <div css={buttonSection}>
                              <StatusButton
                                width={230}
                                {...(!loadingSkeleton && { onClick: this.onAddNewContactClick })}
                                status={getAddButtonStatus(associationError.currentAddState)}
                                initialIcon={IconType.Plus}
                                disabled={isAssociate || emptyAssociation}
                                text={getAddButtonText(associationError.currentAddState)}
                              />
                            </div>
                          </div>
                          <AssociationPanel
                            associationError={associationError}
                            onMatchSelect={this.onMatchSelect}
                            onAssociateClick={this.onAssociateClick}
                            selectedMatchId={selectedMatchId}
                            updateAssociationErrorCount={updateAssociationErrorCount}
                            loadingSkeleton={loadingSkeleton}
                            disablePaginator={(disable: boolean) =>
                              this.setState({ disablePaginator: disable })
                            }
                          />
                        </div>
                        {showForm ? (
                          <AddNewContactForm
                            contactInformation={associationError}
                            onCancelContact={this.onCancelContact}
                            onSaveContact={contactInformation =>
                              this.onSaveContact({
                                contactInformation,
                                updateAssociationErrorCount,
                              })
                            }
                          />
                        ) : null}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingTop: '15px',
                          }}
                        >
                          <Button variant="contained" onClick={this.downloadReport}>
                            Download all
                          </Button>
                        </div>
                      </div>
                    )}
                  </MainContentWrap>
                )}
              </SidebarConsumer>
            )}
          </div>
        )}
      </ClientsConsumer>
    );
  }
}
