/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';

import { Component } from 'react';

import Panel, { PanelHeader } from '../ui/Panel';
import Button from '../ui/Button';
import TextInput from '../ui/Inputs/TextInput';
import zIndex from '../../styles/zIndex';
import PhoneInput from '../ui/Inputs/PhoneInput';
import { apiPost } from '../../adalConfig';
import { ContactState, StateErrors } from '../Utilities/Forms/AddNewContactForm';
import { ContactInformation } from '../../globalTypes/objects';
import Checkbox from '../ui/Checkbox';
import { ValidateEmail } from '../../Utils';
import { errorText } from '../CallCenter/UpdateContactInfoPanel';

// #region css
const screen: CSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  zIndex: zIndex.modal,
  display: 'flex',
  justifyContent: 'center',
};

const inputSmall: CSSObject = { width: 229 };

const labelStyle: CSSObject = { marginTop: 32 };

const inputsGridSection: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
};

const left: CSSObject = { width: 230, verticalAlign: 'top' };

const right: CSSObject = { width: 230, verticalAlign: 'top' };

const buttonPosition: CSSObject = {
  marginTop: 33,
  display: 'flex',
  justifyContent: 'flex-end',
};
// #endregion

const MAX_NAME_TITLE = 250;
const MAX_EMAIL = 50;
const MAX_ADDITIONAL_EMAIL = 255;

type EditTitleCoProps = {
  contactInformation: ContactInformation;
  onSaveContact: () => void;
  onCancelContact: () => void;
};

export default class EditTitleCo extends Component<EditTitleCoProps, ContactState> {
  state = {
    contactInformation: {} as ContactInformation,
    nameError: false,
    addressError: false,
    phoneError: false,
    faxError: false,
    emailError: false,
    cityError: false,
    zipError: false,
    additionalEmailError: false,
    originalContactInformation: {} as ContactInformation,
  };

  componentDidMount() {
    const { contactInformation } = this.props;
    this.setState({
      contactInformation: {
        ...contactInformation,
        firstAddress: contactInformation.address,
      },
      originalContactInformation: {
        ...contactInformation,
        firstAddress: contactInformation.address,
      },
    });
  }

  onChange = (value, field, value2 = '', field2 = '') => {
    const { contactInformation } = this.state;
    this.setState({
      contactInformation: { ...contactInformation, [field]: value, [field2]: value2 },
    });
  };

  changeErrorValue = (value, field: keyof ContactState) => {
    this.setState({
      [field]: value,
    } as Pick<ContactState, keyof ContactState>);
  };

  onSaveClick = async (exceededName, exceededAddress, exceededEmail, exceededAdditionalEmail) => {
    await this.checkValidation(
      exceededName,
      exceededAddress,
      exceededEmail,
      exceededAdditionalEmail,
    );
    const {
      nameError,
      addressError,
      cityError,
      zipError,
      phoneError,
      faxError,
      emailError,
      additionalEmailError,
    } = this.state;
    if (
      nameError ||
      addressError ||
      cityError ||
      zipError ||
      phoneError ||
      faxError ||
      emailError ||
      additionalEmailError
    ) {
      return;
    }
    const { contactInformation } = this.state;
    const { firstAddress, secondAddress } = contactInformation;
    const { onSaveContact } = this.props;
    await this.setState({
      contactInformation: {
        ...contactInformation,
        address: (firstAddress || '') + (secondAddress ? ` ${secondAddress}` : ''),
      },
    });
    const { contactInformation: newContactInformation } = this.state;
    try {
      await apiPost('/api/titlecompanies/update', {
        ...newContactInformation,
        useExistingOutreachSettings: true,
      });
    } catch (e) {
      const errorMessage = errorText(e);
      alert(errorMessage);
    }
    onSaveContact();
  };

  checkValidation = (exceededName, exceededAddress, exceededEmail, exceededAdditionalEmail) => {
    const { contactInformation } = this.state;
    const {
      name,
      firstAddress,
      zip,
      phone,
      fax,
      email,
      sendToGlobal,
      additionalEmail,
    } = contactInformation;
    const { originalContactInformation } = this.state;
    this.changeErrorValue(!name || exceededName, 'nameError');
    this.changeErrorValue(!firstAddress || exceededAddress, 'addressError');
    this.changeErrorValue(zip && !(zip.length === 5 || zip.length === 10), 'zipError');
    this.changeErrorValue(
      originalContactInformation.phone !== phone && phone && phone.length !== 10,
      'phoneError',
    );
    this.changeErrorValue(
      ((originalContactInformation.email !== email || originalContactInformation.fax !== fax) &&
        !fax &&
        !email) ||
        (originalContactInformation.fax !== fax && fax && fax.length !== 10),
      'faxError',
    );
    const emailError = (() => {
      if (
        !email &&
        sendToGlobal &&
        (originalContactInformation.email !== email ||
          originalContactInformation.sendToGlobal !== sendToGlobal)
      )
        return true;
      if (
        !email &&
        !fax &&
        (originalContactInformation.email !== email || originalContactInformation.fax !== fax)
      )
        return true;
      if (originalContactInformation.email !== email) {
        if (email && !ValidateEmail(email)) return true;
        if (exceededEmail) return true;
      }
      return false;
    })();
    this.changeErrorValue(emailError, 'emailError');
    this.changeErrorValue(
      additionalEmail && (exceededAdditionalEmail || !ValidateEmail(additionalEmail)),
      'additionalEmailError',
    );
  };

  getRidOfError = (field: keyof StateErrors) => {
    this.setState({ [field]: false } as Pick<StateErrors, keyof StateErrors>);
  };

  beforeMaskedValueChange = (newState, oldState, userInput, mask, field) => {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    if (
      (value.endsWith('-') &&
        userInput !== '-' &&
        !this.state.contactInformation[field].endsWith('-')) ||
      (value.endsWith('(') && userInput !== '(' && !this.state.contactInformation[field])
    ) {
      if (cursorPosition === value.length) {
        cursorPosition -= 1;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  render() {
    const {
      contactInformation,
      nameError,
      addressError,
      phoneError,
      faxError,
      emailError,
      additionalEmailError,
    } = this.state;
    const {
      contact,
      name,
      firstAddress,
      phone,
      fax,
      email,
      sendToGlobal,
      additionalEmail,
    } = contactInformation;
    const maxName = MAX_NAME_TITLE;
    const maxAddress = Infinity;
    const address = firstAddress && firstAddress;
    const { onCancelContact } = this.props;
    const exceededName = name && name.length > maxName;
    const exceededAddress = address && address.length > maxAddress;
    const exceededEmail = email && email.length > MAX_EMAIL;
    const exceededAdditionalEmail =
      additionalEmail && additionalEmail.length > MAX_ADDITIONAL_EMAIL;
    return (
      <div css={screen}>
        <Panel styles={{ margin: '40px 0', overflowY: 'scroll' }}>
          <PanelHeader largeText text="Edit Title Company" />
          <TextInput
            labelOverrides={labelStyle}
            label="Title company name"
            value={name || ''}
            onChange={e =>
              this.onChange(
                e.target.value,
                'name',
                e.target.value?.toLowerCase()?.includes('dp client') ? true : sendToGlobal,
                'sendToGlobal',
              )
            }
            error={
              nameError && (exceededName ? `Name cannot exceed ${maxName} characters` : 'Required')
            }
            onFocus={() => this.getRidOfError('nameError')}
          />
          <TextInput
            labelOverrides={labelStyle}
            label="Address"
            value={firstAddress || ''}
            onChange={e => this.onChange(e.target.value, 'firstAddress')}
            error={
              addressError &&
              (exceededAddress ? `Address cannot exceed ${maxAddress} characters` : 'Required')
            }
            onFocus={() => this.getRidOfError('addressError')}
          />
          <TextInput
            labelOverrides={labelStyle}
            label="Contact"
            value={contact || ''}
            onChange={e => this.onChange(e.target.value, 'contact')}
          />
          <div css={inputsGridSection}>
            <div css={left}>
              <PhoneInput
                labelOverrides={labelStyle}
                styleOverrides={inputSmall}
                label="Phone"
                value={phone || ''}
                onChange={value => this.onChange(value, 'phone')}
                error={phoneError && 'Invalid phone'}
                onClick={() => this.getRidOfError('phoneError')}
                onKeyUp={e => (e.which === 9 ? this.getRidOfError('phoneError') : null)}
              />
            </div>
            <div css={right}>
              <PhoneInput
                labelOverrides={labelStyle}
                styleOverrides={inputSmall}
                label="Fax"
                value={fax || ''}
                onChange={value => this.onChange(value, 'fax')}
                error={faxError && ((!email && !fax && 'Contact required') || 'Invalid fax')}
                onClick={() => {
                  this.getRidOfError('faxError');
                  !fax && !email && this.getRidOfError('emailError');
                }}
                onKeyUp={e =>
                  e.which === 9 &&
                  (this.getRidOfError('faxError'),
                  !fax && !email && this.getRidOfError('emailError'))
                }
              />
            </div>
          </div>
          <TextInput
            labelOverrides={labelStyle}
            label="Email"
            value={email || ''}
            onChange={e => this.onChange(e.target.value, 'email')}
            type="email"
            error={
              emailError &&
              ((!email && !fax && 'Contact required') ||
                (!email && sendToGlobal && 'Email required if send to global is set to true') ||
                (exceededEmail ? `Email cannot exceed ${MAX_EMAIL} characters` : 'Invalid email'))
            }
            onFocus={() => {
              this.getRidOfError('emailError');
              !fax && !email && this.getRidOfError('faxError');
            }}
          />

          <div css={{ marginTop: 8 }}>
            <Checkbox
              checked={sendToGlobal}
              text="Send to global"
              labelOverrides={{ whiteSpace: 'nowrap' }}
              onClick={
                () =>
                  this.onChange(
                    !sendToGlobal,
                    'sendToGlobal',
                    sendToGlobal ? '' : this.state.originalContactInformation.additionalEmail,
                    'additionalEmail',
                  )
                // this is the value that hasn't been switched yet, so if it's true, it means it WILL BE set to false,
                // so we want to clear the value of additionalEmail
              }
              disabled={name?.toLowerCase()?.includes('dp client')}
            />
          </div>

          {sendToGlobal && (
            <TextInput
              labelOverrides={labelStyle}
              label="Additional Email"
              value={additionalEmail || ''}
              onChange={e => this.onChange(e.target.value, 'additionalEmail')}
              type="email"
              error={
                additionalEmailError &&
                (exceededAdditionalEmail
                  ? `Email cannot exceed ${MAX_ADDITIONAL_EMAIL} characters`
                  : 'Invalid email')
              }
              onFocus={() => {
                this.getRidOfError('additionalEmailError');
              }}
            />
          )}

          <div css={buttonPosition}>
            <Button onClick={onCancelContact} secondary>
              Cancel
            </Button>
            <Button
              onClick={() =>
                this.onSaveClick(
                  exceededName,
                  exceededAddress,
                  exceededEmail,
                  exceededAdditionalEmail,
                )
              }
              styleOverrides={{ marginLeft: 16 }}
            >
              Save
            </Button>
          </div>
        </Panel>
      </div>
    );
  }
}
