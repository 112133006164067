/** @jsx jsx */
import { jsx } from '@emotion/core';
import { OutreachDisposition } from '../../../globalTypes/objects';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import Button from '@mui/material/Button';
import { apiPost } from '../../../adalConfig';
import { Note, RowData, SidebarOptions } from '../types';
import CircularProgress from '@mui/material/CircularProgress';
import Dispositions from './Dispositions';
import add from 'date-fns/add';
import { useToaster } from '../../../Hooks/toasters';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles(() => ({
  noteContainer: {
    border: '1px solid black',
    borderRadius: '6px',
    padding: '4px',
  },
  textareaWrap: {
    width: '100%',
    resize: 'none',
  },
}));

type Props = {
  selectedRows: RowData[];
  updateDatatable: (callback: (datatableRow: RowData) => void) => void;
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
};

const defaultDeferDate = add(new Date(), { days: 10 });

const getNoteTemplate = (disposition: OutreachDisposition, rowData: RowData, deferDate: Date) => {
  if (disposition.noteTemplate === null) {
    return '';
  }

  const replacements = {
    eta: deferDate.toLocaleDateString(),
    titleCompanyName: rowData.titleCompany,
    phoneNumber: rowData.loanLevelContactInformation.phone || rowData.titleCompanyPhoneGlobal,
    contactEmail:
      rowData.loanLevelContactInformation.email?.toLowerCase() ||
      rowData.titleCompanyEmailGlobal?.toLowerCase(),
  };

  const placeholderRegex = /%([A-Za-z0-9]+?)%/g;
  const matches = [...disposition.noteTemplate.matchAll(placeholderRegex)] as unknown as string[];

  return matches.reduce((finalTemplate, [fullMatch, matchInGrouping]) => {
    return finalTemplate.replace(
      fullMatch,
      replacements[matchInGrouping] ||
        matchInGrouping.replace(/([a-z])([A-Z])/g, '$1_$2').toUpperCase(),
    );
  }, disposition.noteTemplate);
};

const DispositionSection = ({ selectedRows, updateDatatable, setSidebarOption }: Props) => {
  const [selectedDisposition, setSelectedDisposition] = useState<OutreachDisposition | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deferDate, setDeferDate] = useState<Date | null>(defaultDeferDate);
  const [noteTemplate, setNoteTemplate] = useState('');
  const [internalNote, setInternalNote] = useState('');
  const [isWarningConfirmed, setIsWarningConfirmed] = useState(false);
  const [textForWarning, setTextForWarning] = useState<string | undefined>();
  const [isDeferDialogOpen, setIsDeferDialogOpen] = useState(false);

  const { errorToaster } = useToaster();
  const classes = useStyles();

  const confirmDisposition = async (
    selectedDispositionId: number | undefined,
    loanIds: number[],
  ) => {
    const allCapsSnakeCaseRegex = /\b[A-Z]+?_[A-Z]+\b/;
    const match = noteTemplate.match(allCapsSnakeCaseRegex);
    if (match !== null && !isWarningConfirmed) {
      setTextForWarning(match[0]);
      return;
    }

    await submitDispositions(selectedDispositionId, loanIds);
  };

  const submitDispositions = async (
    selectedDispositionId: number | undefined,
    loanIds: number[],
  ) => {
    if (!selectedDispositionId || deferDate === null) {
      return;
    }

    setIsSubmitting(true);

    try {
      await apiPost('/api/call-tasks/add-outreach-disposition', {
        outreachDispositionId: selectedDispositionId,
        loanIds,
        deferUntil: deferDate?.toLocaleDateString('en-CA'),
        note: noteTemplate,
        internalNote: internalNote.length > 0 ? internalNote : null,
      });

      resolveLoan(noteTemplate, internalNote);
      setSidebarOption('email');
      setIsWarningConfirmed(false);
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.message);
        return;
      }

      errorToaster(
        <Fragment>
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const resolveLoan = (externalNote: string, internalNote: string) => {
    const notes = [externalNote];
    if (internalNote.length) {
      notes.unshift(internalNote);
    }

    const callback = (row: RowData) => {
      const noteObjects = notes.map(
        noteText =>
          ({
            dateEntered: new Date(),
            text: noteText,
          } as Note),
      );

      row.isResolved = true;
      row.pastNotes.unshift(...noteObjects);
    };

    updateDatatable(callback);
  };

  if (isSubmitting) {
    return (
      <div className="center-in-parent" style={{ height: '90px' }}>
        <CircularProgress size="20" disableShrink />
      </div>
    );
  }

  const onDeferUpdate = (date: Date | null) => {
    if (selectedDisposition === null) {
      return;
    }

    if (selectedDisposition.noteTemplate?.includes('%eta%')) {
      setIsDeferDialogOpen(true);
    }

    setDeferDate(date);
  };

  const dispositionChangeHandler = (disposition: OutreachDisposition | null) => {
    setDeferDate(defaultDeferDate);
    setSelectedDisposition(disposition);

    if (disposition === null) {
      return;
    }

    const note = getNoteTemplate(disposition, selectedRows[0], defaultDeferDate);
    setNoteTemplate(note);
  };

  return (
    <Fragment>
      <div className="df col jcfe" style={{ gap: '8px' }}>
        <Dispositions
          selectedDisposition={selectedDisposition}
          dispositionChangeHandler={dispositionChangeHandler}
          deferDate={deferDate}
          setDeferDate={onDeferUpdate}
        />

        {selectedDisposition !== null && (
          <div>
            <FormControl sx={{ width: 1 }}>
              <FormLabel>Disposition Note</FormLabel>
              <div className={classes.noteContainer}>
                <TextareaAutosize
                  minRows={3}
                  aria-label="maximum height"
                  placeholder="Type here"
                  value={noteTemplate}
                  onChange={e => setNoteTemplate(e.target.value)}
                  className={classes.textareaWrap}
                />
              </div>
            </FormControl>

            <hr />
            <FormControl sx={{ width: 1 }}>
              <FormLabel>Internal Note (Optional)</FormLabel>
              <div className={classes.noteContainer}>
                <TextareaAutosize
                  minRows={3}
                  aria-label="maximum height"
                  placeholder="Add internal note here"
                  value={internalNote}
                  onChange={e => setInternalNote(e.target.value)}
                  className={classes.textareaWrap}
                />
              </div>
            </FormControl>
          </div>
        )}

        <div style={{ alignSelf: 'end' }}>
          <Button
            disabled={selectedDisposition === null || deferDate === null}
            onClick={() =>
              confirmDisposition(
                selectedDisposition?.id,
                selectedRows.map(r => r.id),
              )
            }
          >
            Submit
          </Button>
        </div>
      </div>

      <Dialog
        open={!!textForWarning}
        onClose={() => setTextForWarning(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Template Placeholder Detected</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Did you mean to submit the note with <b>{textForWarning}</b> part of the text?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTextForWarning(undefined)} autoFocus>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await submitDispositions(
                selectedDisposition?.id,
                selectedRows.map(r => r.id),
              );
              setIsWarningConfirmed(true);
              setTextForWarning(undefined);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeferDialogOpen}
        aria-labelledby="defer-dialog-title"
        aria-describedby="defer-dialog-description"
      >
        <DialogTitle id="defer-dialog-title">ETA Updated</DialogTitle>
        <DialogContent>
          <DialogContentText id="defer-dialog-description">
            Please update the date in the note to match the new ETA
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setIsDeferDialogOpen(false)}>
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DispositionSection;
