/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';

import { format } from 'date-fns';
import SearchSelect from '../../ui/SearchSelect';
import Tab, { TabGroup } from '../../ui/Tabs/Tab';
import NoResults, { SearchType } from './NoResults';
import { apiFetch } from '../../../adalConfig';
import { Client, Loan } from '../../../globalTypes/objects';

// #region css
const searchLoanStyles: CSSObject = {
  maxWidth: 404.641,
  position: 'relative',
  marginTop: 24,
};

const disabledOverlay: CSSObject = {
  cursor: 'normal',
  position: 'relative',
  ':before': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    content: '""',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 20,
  },
};
// #endregion

const getClients = async () => {
  const { data: clients } = await apiFetch<Client[]>('/api/clients/getAll');
  return clients;
};

const searchLoansByClient = async (
  searchString: string,
  searchBy: SearchType,
  clientId: number,
) => {
  const { data } = await apiFetch<Loan[]>(
    `/api/loans/searchBy${camelCase(searchBy)}AndClient?searchString=${encodeURIComponent(
      searchString,
    )}&clientId=${clientId}`,
  );
  return data;
};

const searchLoans = async (searchString: string, searchBy: SearchType) => {
  const { data } = await apiFetch<Loan[]>(
    `/api/loans/searchBy${camelCase(searchBy)}?searchString=${searchString}`,
  );
  return data;
};

// eslint-disable-next-line consistent-return
const getLabel = (loan: Loan, searchBy: SearchType) => {
  if (searchBy === SearchType.LoanNumber) return loan.loanNumber;
  if (searchBy === SearchType.Address) return loan.propertyAddress;
  if (searchBy === SearchType.Borrower) return loan.borrower;
};

type LoanDropdownProps = {
  client: Client;
  loan: Loan;
  onChange: (selection: SelectOption<Loan>) => void;
  onInputDebounced: (input: string, searchBy: SearchType) => void;
  disabled?: boolean;
  includeDate?: boolean;
  includeBorrower?: boolean;
  includeLoanNumber?: boolean;
  includeAddress?: boolean;
  [key: string]: any;
};

interface LoanDropdownState {
  searchBy: SearchType;
  clients: Client[];
  clientMatches: number[];
}

export default class LoanDropdown extends React.Component<LoanDropdownProps, LoanDropdownState> {
  constructor(props) {
    super(props);
    this.state = {
      searchBy: SearchType.LoanNumber,
      clients: [] as Client[],
      clientMatches: [] as number[],
    };
  }

  async componentDidMount() {
    const clients = await getClients();
    this.setState({ clients });
  }

  getLoanOptions = async (inputText: string) => {
    const { searchBy } = this.state;
    const { client, includeDate, includeBorrower, includeLoanNumber, includeAddress } = this.props;
    if (this.props.onInputDebounced) this.props.onInputDebounced(inputText, searchBy);
    const loans = await searchLoansByClient(inputText, searchBy, client.id);

    if (loans.length === 0) {
      const clientMatches = await searchLoans(inputText, searchBy);
      this.setState({ clientMatches: clientMatches.map(loan => loan.clientID) });
      return [];
    }

    return loans.map(loan => ({
      label: getLabel(loan, searchBy),
      first: includeAddress && searchBy !== SearchType.Address ? loan.propertyAddress : null,
      second: includeAddress ? `${loan.city}, ${loan.state} ${loan.zip}` : null,
      third: includeDate ? format(Date.parse(loan.dateDocumentsDrawn), 'MM/dd/yyyy') : null,
      fourth: includeBorrower && searchBy !== SearchType.Borrower ? loan.borrower : null,
      fifth: includeLoanNumber && searchBy !== SearchType.LoanNumber ? loan.loanNumber : null,
      value: loan,
      searchBy,
      SearchType,
    }));
  };

  loadOptions = debounce((input, callback) => {
    this.getLoanOptions(input).then(callback);
  }, 800);

  render() {
    const { clients, searchBy, clientMatches } = this.state;
    const { loan, client, onChange, onInputDebounced = () => {}, ...props } = this.props;
    let { disabled } = this.props;
    if (!client) {
      disabled = true;
    }

    return (
      <Fragment>
        <div css={disabled && disabledOverlay}>
          <TabGroup>
            <Tab onClick={() => this.setState({ searchBy: SearchType.LoanNumber })}>
              {SearchType.LoanNumber}
            </Tab>
            <Tab onClick={() => this.setState({ searchBy: SearchType.Address })}>
              {SearchType.Address}
            </Tab>
            <Tab onClick={() => this.setState({ searchBy: SearchType.Borrower })}>
              {SearchType.Borrower}
            </Tab>
          </TabGroup>

          <div css={searchLoanStyles}>
            <SearchSelect
              placeholder={`Select ${searchBy.toLowerCase()}`}
              key={searchBy}
              onChange={selection => onChange(selection)}
              loadOptions={this.loadOptions}
              filterConfig={{ matchFrom: 'allIgnoreSpace' }}
              cacheOptions={false}
              isAsync
              isDisabled={disabled}
              noOptionsMessage={() => (
                <NoResults
                  handleClick={value =>
                    this.setState({
                      searchBy: value,
                    })
                  }
                  searchBy={searchBy}
                  clientMatches={clients.filter(c => clientMatches.includes(c.id))}
                />
              )}
              value={
                !isEmpty(loan) && {
                  label: getLabel(loan, searchBy),
                  value: loan,
                }
              }
              {...props}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
