/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useCallback, useEffect, useReducer, useState } from 'react';
import xor from 'lodash/xor';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import TextInput from '../../ui/Inputs/TextInput';
import BarcodeInput from '../../Utilities/BarcodeInput';
import colors from '../../../styles/colors';
import { tabsWrap } from './clientDocumentIndex';
import { DocumentStatus } from '../../../globalTypes/objects';
import { useGetData, usePrevious } from '../../../Hooks';
import { IndexDoc, useDocIndex } from './useDocIndex';

enum DocFormat {
  Digital,
  Physical,
}

enum FilterActionType {
  ChangeDocStatus,
  ChangeDocFormat,
  ChangeScannedView,
  ChangeDocumentId,
  ChangeSearchText,
  ChangeIndexQuery,
}

type FilterState = {
  docStatusView: DocumentStatus[];
  docFormatView: DocFormat[];
  scannedView: boolean[];
  documentId: string | undefined;
  searchText: string;
  indexSearchText: string;
};

const filterReducer = (
  state: FilterState,
  action: { type: FilterActionType; payload: any },
): FilterState => {
  let newState = {} as FilterState;
  switch (action.type) {
    case FilterActionType.ChangeDocStatus:
      newState = { ...state, docStatusView: xor(state.docStatusView, [action.payload]) };
      break;
    case FilterActionType.ChangeDocFormat:
      newState = { ...state, docFormatView: xor(state.docFormatView, [action.payload]) };
      break;
    case FilterActionType.ChangeScannedView:
      newState = { ...state, scannedView: xor(state.scannedView, [action.payload]) };
      break;
    case FilterActionType.ChangeDocumentId:
      newState = { ...state, documentId: action.payload };
      break;
    case FilterActionType.ChangeSearchText:
      newState = { ...state, searchText: action.payload };
      break;
    case FilterActionType.ChangeIndexQuery:
      newState = { ...state, indexSearchText: action.payload };
      break;
    default:
      throw new Error();
  }
  return isEqual(newState, state) ? state : newState;
};

const initialFilterStates: FilterState = {
  docStatusView: [
    DocumentStatus.Found,
    DocumentStatus.Unsorted,
    DocumentStatus.NotFound,
    DocumentStatus.FailedAudit,
  ],
  docFormatView: [DocFormat.Digital, DocFormat.Physical],
  scannedView: [true],
  documentId: undefined,
  searchText: '',
  indexSearchText: '',
};

type FilterProps = {
  docs: IndexDoc[];
};

export default function Filters({ docs }: FilterProps) {
  const prevDocs = usePrevious(docs);
  const [indexData, setIndexData] = useDocIndex();
  const [searchInputText, setSearchInputText] = useState<string>('');

  const [filterState, filterDispatch] = useReducer(filterReducer, initialFilterStates);
  const { data: searchedIds, doFetch: searchDocs, isLoading: loadingSearchedIds } = useGetData<
    number[]
  >('', []);

  const {
    data: indexSearchResult,
    doFetch: searchIndex,
    isLoading: loadingIndexSearch,
  } = useGetData<Number[]>('', []);

  const { currentClient, displayedDocs, selected } = indexData;
  const prevDisplayedDocs = usePrevious(displayedDocs);

  const debounceSearchString = useCallback(
    debounce(
      (text: string, id: number) =>
        !text
          ? searchDocs('')
          : searchDocs(
              `/api/documents/searchAddressAndLoanNumber?searchString=${text}&clientId=${id}`,
            ),
      1000,
      { leading: true },
    ),
    [],
  );

  useEffect(() => {
    debounceSearchString(filterState.searchText, currentClient.id);
  }, [currentClient.id, debounceSearchString, filterState.searchText]);

  useEffect(() => {
    !filterState.indexSearchText
      ? searchIndex('')
      : searchIndex(
          `/api/documents/searchDocumentIndex?term=${filterState.indexSearchText}&clientId=${currentClient.id}`,
        );
  }, [searchIndex, filterState.indexSearchText, currentClient.id]);

  const filterDocs = useCallback((): IndexDoc[] => {
    const include = (doc: IndexDoc) =>
      filterState.docStatusView.includes(doc.docStatus) &&
      filterState.docFormatView.includes(
        doc.document.hardCopy ? DocFormat.Physical : DocFormat.Digital,
      ) &&
      filterState.scannedView.includes(!!doc.document.fileSubpath) &&
      (filterState.searchText && !loadingSearchedIds && searchedIds.length > 0
        ? searchedIds.includes(doc.document.id)
        : true) &&
      (filterState.indexSearchText ? indexSearchResult.includes(doc.document.id) : true);

    console.log({ docs });

    return docs.filter(d => include(d));
  }, [
    docs,
    filterState.docFormatView,
    filterState.docStatusView,
    filterState.scannedView,
    filterState.searchText,
    filterState.indexSearchText,
    loadingSearchedIds,
    searchedIds,
    indexSearchResult,
  ]);

  const getDocByDocId = useCallback(
    () => docs.filter(d => d.document.id === parseInt(filterState.documentId!, 10)),
    [docs, filterState.documentId],
  );

  useEffect(() => {
    if (docs.length > 0 || !isEqual(displayedDocs, prevDisplayedDocs)) {
      setIndexData({ isBusy: true });
      const newDocs = filterState.documentId ? getDocByDocId() : filterDocs();
      const newSelected = newDocs.reduce((acc: Set<number>, data) => {
        selected.has(data.document.id) && acc.add(data.document.id);
        return acc;
      }, new Set<number>());
      setIndexData({ displayedDocs: newDocs, selected: newSelected });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterState.docStatusView,
    filterState.docFormatView,
    filterState.scannedView,
    filterState.documentId,
    indexSearchResult,
    searchedIds,
    filterDocs,
    getDocByDocId,
  ]);

  useEffect(() => {
    setIndexData({ isBusy: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDocs]);

  const disableFilters = !!filterState.documentId;
  const handleDocIdChange = useCallback(value => {
    filterDispatch({
      type: FilterActionType.ChangeDocumentId,
      payload: value,
    });
  }, []);

  return (
    <Fragment>
      <div css={{ borderBottom: `1px solid ${colors.grayLight}`, padding: '16px 16px 24px' }}>
        <TextInput
          value={searchInputText}
          onChange={e => setSearchInputText(e.target.value)}
          onKeyDown={e => {
            if (e.keyCode == 13) {
              filterDispatch({ type: FilterActionType.ChangeIndexQuery, payload: searchInputText });
            }
          }}
          placeholder="Search Document Text"
          disabled={disableFilters}
        />

        <TextInput
          value={filterState.searchText}
          onChange={e =>
            filterDispatch({ type: FilterActionType.ChangeSearchText, payload: e.target.value })
          }
          placeholder="Search address, loan #"
          disabled={disableFilters}
        />

        <BarcodeInput onChange={handleDocIdChange} />
      </div>
      <div className="df col full-width">
        <div css={[tabsWrap, { marginTop: 14 }]}>
          <FilterToggle
            text="Founds"
            active={filterState.docStatusView.includes(DocumentStatus.Found)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocStatus,
                payload: DocumentStatus.Found,
              })
            }
            disabled={disableFilters}
            styles={{
              span: { color: colors.green },
            }}
          />
          <FilterToggle
            text="Unsorted"
            active={filterState.docStatusView.includes(DocumentStatus.Unsorted)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocStatus,
                payload: DocumentStatus.Unsorted,
              })
            }
            disabled={disableFilters}
            styles={{ span: { color: colors.orange } }}
          />
          <FilterToggle
            text="Not Founds"
            active={filterState.docStatusView.includes(DocumentStatus.NotFound)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocStatus,
                payload: DocumentStatus.NotFound,
              })
            }
            disabled={disableFilters}
            styles={{
              span: { color: colors.red },
            }}
          />
          <FilterToggle
            text="Failed Audit"
            active={filterState.docStatusView.includes(DocumentStatus.FailedAudit)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocStatus,
                payload: DocumentStatus.FailedAudit,
              })
            }
            disabled={disableFilters}
            styles={{
              span: { color: colors.red },
            }}
          />
        </div>
        <div css={tabsWrap}>
          <FilterToggle
            text="Physical"
            active={filterState.docFormatView.includes(DocFormat.Physical)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocFormat,
                payload: DocFormat.Physical,
              })
            }
            disabled={disableFilters}
          />
          <FilterToggle
            text="Digital"
            active={filterState.docFormatView.includes(DocFormat.Digital)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeDocFormat,
                payload: DocFormat.Digital,
              })
            }
            disabled={disableFilters}
          />
        </div>
        <div css={tabsWrap}>
          <FilterToggle
            text="Scanned"
            active={filterState.scannedView.includes(true)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeScannedView,
                payload: true,
              })
            }
            disabled={disableFilters}
          />
          <FilterToggle
            text="Not Scanned"
            active={filterState.scannedView.includes(false)}
            handleToggle={() =>
              filterDispatch({
                type: FilterActionType.ChangeScannedView,
                payload: false,
              })
            }
            disabled={disableFilters}
          />
        </div>
      </div>
    </Fragment>
  );
}

// #region  css
const toggleWrap = {
  backgroundColor: colors.grayExLight,
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 16,
  cursor: 'pointer',
  height: 34,
  width: 58,
  '&:hover': { borderColor: colors.gray },
};

const toggleCircle = {
  backgroundColor: colors.gray,
  borderRadius: '50%',
  height: 24,
  left: 4,
  marginTop: 4,
  position: 'relative',
  transition: 'all 75ms ease',
  width: 24,
};

const disabledStyles = {
  '&&': {
    backgroundColor: colors.grayLight,
    border: `1px solid ${colors.grayLight}`,
    color: colors.white,
    pointerEvents: 'none',
    '&:hover': {
      backgroundColor: colors.grayLight,
      border: `1px solid ${colors.grayLight}`,
      color: colors.white,
    },
    div: { backgroundColor: colors.gray },
  },
};
// #endregion

type FilterToggleProps = {
  active: boolean;
  handleToggle: () => void;
  text: string;
  disabled: boolean;
  styles?: object;
};

function FilterToggle({ active, handleToggle, text, styles, disabled }: FilterToggleProps) {
  return (
    <div
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10,
        },
        styles,
      ]}
    >
      <span
        css={[
          { paddingRight: 8, fontWeight: 600, whiteSpace: 'nowrap' },
          disabled && { color: colors.grayLight },
        ]}
      >
        {text}
      </span>
      <div css={[toggleWrap, disabled && disabledStyles]} onClick={handleToggle}>
        <div css={[toggleCircle, active && { backgroundColor: colors.blue, left: 28 }]} />
      </div>
    </div>
  );
}
