/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextInput from '../ui/Inputs/TextInput';
import Tab, { TabGroup } from '../ui/Tabs/Tab';

interface DateRangeProps {
  handleFromChange: (from: Date) => void;
  handleToChange: (to: Date) => void;
}

enum Mode {
  singleDate,
  dateRange,
}

export default function DateRange({ handleFromChange, handleToChange }: DateRangeProps) {
  const currentDate = new Date();
  const [from, setFrom] = useState(currentDate);
  const [to, setTo] = useState(currentDate);
  const [mode, setMode] = useState(Mode.singleDate);

  React.useEffect(() => {
    handleToChange(to);
  }, [handleToChange, to]);

  React.useEffect(() => {
    handleFromChange(from);
  }, [from, handleFromChange]);

  const handleToDateChange = async (date: Date) => {
    setTo(date);
    handleToChange(to);
    if (date < from) {
      await setFrom(date);
    }
  };

  const handleFromDateChange = async (date: Date) => {
    setFrom(date);
    if (mode === Mode.singleDate || (mode === Mode.dateRange && date > to)) {
      setTo(date);
    }
  };

  const handleModeChange = async (m: Mode) => {
    setMode(m);
    if (m === Mode.singleDate) {
      setTo(from);
    }
  };

  return (
    <React.Fragment>
      <TabGroup>
        <Tab onClick={() => handleModeChange(Mode.singleDate)}>Date</Tab>
        <Tab onClick={() => handleModeChange(Mode.dateRange)}>Date Range</Tab>
      </TabGroup>
      <div
        css={{
          margin: '24px 0 32px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="w-quarter">
          <DatePicker
            customInput={
              <TextInput label={mode === Mode.singleDate ? 'Select Date' : 'Start Date'} />
            }
            selected={from}
            selectsStart={mode === Mode.dateRange}
            startDate={from}
            endDate={to}
            onChange={f => handleFromDateChange(f as Date)}
          />
        </div>
        {mode === Mode.dateRange && (
          <div className="w-quarter">
            <DatePicker
              customInput={<TextInput label="End Date" />}
              selected={to}
              selectsEnd
              startDate={from}
              endDate={to}
              onChange={d => handleToDateChange(d as Date)}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
