/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../components/ui/Header';
import SearchBoxSection from '../components/Imports/ImportErrors/LoanErrors/SearchBoxSection';
import DocumentsIndexTile from '../components/Documents/Index/DocumentsIndexTile';
import colors from '../styles/colors';
import { apiFetch } from '../adalConfig';
import queryString from 'query-string';
import ConditionalComponent from '../components/ConditionalComponent';
import CircularProgress from '@mui/material/CircularProgress';
import { useToaster } from '../Hooks/toasters';
import { useClients } from '../Hooks/useClients';

const griddy = {
  display: 'grid',
  gridColumnGap: '24px',
  gridRowGap: '24px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  marginTop: 32,
  maxWidth: 1320,
};

const listViewMode = {
  gridTemplateColumns: '1fr',
  gridRowGap: '8px',
  marginTop: 0,
};

const listViewHeader = {
  position: 'sticky',
  padding: '16px 0',
  top: 64,
  backgroundColor: '#f7f7f9',
  display: 'block',
  '> div': {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: `solid 1px ${colors.grayLight}`,
    borderRadius: 4,
    display: 'grid',
    fontWeight: 600,
    gridColumnGap: '24px',
    gridTemplateColumns: '340px 430px 240px',
    height: 48,
    padding: '0 16px',
  },
};

const disabledButton = {
  '&&': {
    border: `solid 1px ${colors.gray}`,
    color: colors.gray,
    cursor: 'pointer',
    '&:hover': {
      border: `solid 1px ${colors.blue}`,
      color: colors.blue,
    },
  },
};

const viewSwitcher = {
  height: 40,
  width: 180,
  button: {
    alignItems: 'center',
    border: `solid 1px ${colors.blue}`,
    borderRadius: '0 5px 5px 0',
    color: colors.blue,
    cursor: 'auto',
    display: 'inline-flex',
    height: 40,
    justifyContent: 'center',
    width: '50%',
    ':first-of-type': { borderRadius: '5px 0 0 5px' },
  },
};

export default function DocumentsIndex() {
  const [selectedClientIds, setSelectedClientIds] = useState([]);
  const [isList, setList] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedClientIds.length) {
      getDocIndexData();
    }
  }, [selectedClientIds]);

  const clients = useClients();
  const { errorToaster } = useToaster();

  const clientsMap = clients.reduce((final, current) => {
    final.set(current.id, current);

    return final;
  }, new Map());

  const getDocIndexData = async () => {
    setIsLoading(true);

    try {
      const queryParams = queryString.stringify(
        { clientIds: selectedClientIds },
        { arrayFormat: 'index' },
      );
      const { data } = await apiFetch(`/api/documents/getDocIndexData?${queryParams}`);
      setClientData(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Header headerText="Documents" fixed />
      <div css={{ padding: 40, paddingTop: 32, marginTop: 64, maxWidth: 1400 }}>
        <div className="df jcsb" css>
          <SearchBoxSection
            selectedClientIds={selectedClientIds}
            setSelectedClient={id => setSelectedClientIds([...selectedClientIds, id])}
            removeSelectedClient={id =>
              setSelectedClientIds(selectedClientIds.filter(clientId => clientId !== id))
            }
            clients={clients.map(c => ({
              name: c.company,
              id: c.id,
            }))}
          />
          <ConditionalComponent display={clientData.length}>
            <div css={viewSwitcher}>
              <button onClick={() => setList(false)} css={isList && disabledButton}>
                Grid View
              </button>
              <button onClick={() => setList(true)} css={!isList && disabledButton}>
                List View
              </button>
            </div>
          </ConditionalComponent>
        </div>
        {isLoading ? (
          <div className="center-in-parent">
            <CircularProgress size="75" disableShrink />
          </div>
        ) : (
          <>
            <div css={[{ display: 'none' }, isList && listViewHeader]}>
              <div>
                <div>Client</div>
                <div className="df jcsb">
                  <span>Not Founds</span>
                  <span>Founds</span>
                  <span>Sorted</span>
                  <span>Suggestions</span>
                </div>
                <div className="tac pl4">Date</div>
              </div>
            </div>
            <div css={[griddy, isList && listViewMode]}>
              {!!selectedClientIds.length &&
                clientData.map(clientData => (
                  <Link key={clientData.clientId} to={`?clientId=${clientData.clientId}`}>
                    <DocumentsIndexTile
                      isList={isList}
                      clientName={clientsMap.get(clientData.clientId).company}
                      {...clientData}
                    />
                  </Link>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
