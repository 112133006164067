/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import colors from '../../../styles/colors';

export const containerStyles = (isFocused: boolean): CSSObject => ({
  position: 'relative',
  verticalAlign: 'top',
  zIndex: isFocused ? 30 : 10,
});

export const inputStyles = (isError: boolean): CSSObject => ({
  border: `solid 1px ${isError ? colors.red : colors.grayLight}`,
  borderRadius: 4,
  display: 'flex',
  fontSize: 14,
  height: 48,
  justifyContent: 'space-between',
  padding: '0px 16px',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  transition: 'color .2s',
  verticalAlign: 'top',
  whiteSpace: 'nowrap',
  width: '100%',
  ' > div': { padding: 0 },
});

export const openMenuStyles: CSSObject = {
  background: colors.white,
  border: 'solid 1px #e5e5ea',
  borderRadius: 4,
  cursor: 'pointer',
  marginTop: 10,
  maxHeight: 450,
  overflow: 'auto',
  position: 'absolute',
  padding: '8px',
  textAlign: 'left',
  width: 230,
  zIndex: 5000,
};

export const itemStyles = (isFocus: boolean, isSelected: boolean): CSSObject => ({
  backgroundColor: isFocus ? colors.grayExLight : 'white',
  borderRadius: 4,
  color: isSelected ? colors.blue : colors.black,
  margin: 0,
  padding: '9px 16px',
  transition: 'background-color .3s ease-out, color .3s ease-out',
  ':hover': { backgroundColor: colors.grayExLight },
});
