/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Client, LoanImportMapping } from '../../../globalTypes/objects';
import EditIcon from '@mui/icons-material/Edit';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SetMappingModal from './SetMappingModal';
import { apiPost } from '../../../adalConfig';
import { useToaster } from '../../../Hooks/toasters';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { useGetData } from '../../../Hooks';
import { AuthContext } from '../../AuthContext';
import { isDataAssociation } from '../../../Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noMappingsSection: {
      fontSize: '20px',
      marginTop: '24px',
      display: 'flex',
      justifyContent: 'center',
    },
    mappingsSection: {
      width: '500px',
      display: 'grid',
      gap: '8px',
    },
    adminMappingColumns: { gridTemplateColumns: '25px 25px 1fr 25px 1fr' },
    nonAdminMappingColumns: { gridTemplateColumns: '1fr 25px 1fr' },
  }),
);

type Props = {
  client: Client;
  systemMappingFields: string[];
};

const ClientMapping = ({ client, systemMappingFields }: Props) => {
  const [canSetMappings, setCanSetMappings] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<LoanImportMapping | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: mappings, refetch, doFetch: getClientMappings } = useGetData<LoanImportMapping[]>(
    '',
    [],
  );
  const { successToaster } = useToaster();
  const { roles } = useContext(AuthContext);

  useEffect(() => {
    getClientMappings(`/api/loans/client-import-mappings?clientId=${client.id}`);
  }, [client.id]);

  const classes = useStyles();

  const updateMapping = (mapping: LoanImportMapping) => {
    setSelectedMapping(mapping);
    setCanSetMappings(true);
  };

  const confirmDelete = (mapping: LoanImportMapping) => {
    setSelectedMapping(mapping);
    setIsDialogOpen(true);
  };

  const deleteMapping = async (mappingId: number) => {
    await apiPost(`/api/loans/delete-import-mapping/${mappingId}`, {});
    refetch();

    setSelectedMapping(null);
    setIsDialogOpen(false);

    successToaster('Mapping deleted');
  };

  return (
    <Fragment>
      <div className="my3">
        {isDataAssociation(roles) && (
          <Button variant="contained" onClick={() => setCanSetMappings(true)}>
            Add Mapping
          </Button>
        )}
      </div>

      {mappings.length === 0 ? (
        <div className={classes.noMappingsSection}>No Mappings found</div>
      ) : (
        <div
          className={`${classes.mappingsSection} ${
            isDataAssociation(roles) ? classes.adminMappingColumns : classes.nonAdminMappingColumns
          }`}
        >
          {isDataAssociation(roles) && (
            <Fragment>
              <div></div>
              <div></div>
            </Fragment>
          )}
          <div>
            <b>Client Column Name</b>
          </div>
          <div></div>
          <div>
            <b>System Column Name</b>
          </div>
          {mappings.map(mapping => {
            return (
              <Fragment key={mapping.externalSourcePropertyName}>
                {isDataAssociation(roles) && (
                  <Fragment>
                    <div>
                      <DeleteIcon color="error" onClick={() => confirmDelete(mapping)} />
                    </div>
                    <div>
                      <EditIcon color="warning" onClick={() => updateMapping(mapping)} />
                    </div>
                  </Fragment>
                )}
                <div>{mapping.externalSourcePropertyName}</div>
                <div>
                  <TrendingFlatIcon />
                </div>
                <div>{mapping.systemPropertyName.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</div>
              </Fragment>
            );
          })}
        </div>
      )}

      <SetMappingModal
        client={client}
        open={canSetMappings}
        setOpen={setCanSetMappings}
        selectedMapping={selectedMapping}
        setSelectedMapping={setSelectedMapping}
        systemMappingFields={systemMappingFields}
        onMappingsSaved={refetch}
      />

      <Dialog
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedMapping(null);
        }}
        open={isDialogOpen}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <Fragment>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this mapping (
              {selectedMapping?.externalSourcePropertyName})?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="inherit" variant="contained">
              Cancel
            </Button>
            <Button
              onClick={() => deleteMapping(selectedMapping!.id!)}
              color="error"
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Fragment>
      </Dialog>
    </Fragment>
  );
};

export default ClientMapping;
