import React, { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type EventData = {
  event: string;
  totalForMonth: number;
  totalForWeek: number;
  totalForToday: number;
};

const columns = [
  {
    field: 'event',
    title: 'Event',
    options: {
      filter: false,
    },
  },
  {
    field: 'totalForToday',
    title: 'Total For Today',
    options: {
      filter: false,
    },
  },
  {
    field: 'totalForWeek',
    title: 'Total For Week',
    options: {
      filter: false,
    },
  },
  {
    field: 'totalForMonth',
    title: 'Total For Month',
    options: {
      filter: false,
    },
  },
];

const UserDashboardEventLogs = () => (
  <Fragment>
    <DataTable<EventData>
      title="User Dashboard"
      columns={columns}
      url="/api/user-dashboard/logged-events"
      options={{ search: false }}
    />
  </Fragment>
);

export default UserDashboardEventLogs;
