/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { User } from '@microsoft/microsoft-graph-types';
import { graphApiFetch } from '../adalConfig';
import { useAccount } from '@azure/msal-react';

export const AuthContext = React.createContext({} as AuthContext);

type AuthContext = {
  user?: User;
  roles: string[];
};

function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();

  const account = useAccount();

  useEffect(() => {
    setLoading(true);
    graphApiFetch()
      .then(({ data }) => {
        setUser(data);
        setLoading(false);
      })
      .catch(() => {
        const payload = account?.idTokenClaims;
        setUser({
          displayName: payload?.name,
          givenName: payload?.given_name,
          mail: payload?.email,
          surname: payload?.family_name,
          userPrincipalName: payload?.upn,
        } as User);
      });
  }, [account]);

  return (
    <AuthContext.Provider
      value={{
        user,
        roles: account?.idTokenClaims?.roles || [],
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer };
