/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import DataTable from '../../components/ui/DataTable/DataTable';
import {
  AuditedAutomaticallyRowData as RowData,
  DocumentsAuditedAutomaticallyDrillDown,
} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsAuditedAutomatically: RowData[];
};

const automatedAuditsColumns = [
  {
    field: 'id',
    title: 'Document Id',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'createdAt',
    title: 'Date',
    render: (rowData: DocumentsAuditedAutomaticallyDrillDown) =>
      rowData.createdAt && new Date(rowData.createdAt).toLocaleDateString(),
  },
  {
    field: 'lender',
    title: 'Lender',
  },
  {
    field: 'passed',
    title: 'Passed / Failed',
    render: (rowData: DocumentsAuditedAutomaticallyDrillDown) =>
      rowData.passed ? 'Passed' : 'Failed',
  },
];

const AutomatedAudits = ({ documentsAuditedAutomatically }: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [dateDrillDownData, setDateDrillDownData] = useState<Date | null>(null);

  const classes = useStyles();

  const columns = [
    {
      field: 'count',
      title: 'Count',
      render: (rowData: RowData) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() => setDateDrillDownData(new Date(rowData.date))}
        >
          {rowData.count}
        </Link>
      ),
      cellStyle: {
        paddingTop: 6,
        paddingBottom: 6,
      },
    },
    {
      field: 'date',
      title: 'Date',
      cellStyle: {
        paddingTop: 6,
        paddingBottom: 6,
      },
      render: (rowData: RowData) => new Date(rowData.date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: 30,
            zIndex: 1,
            right: 90,
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          All Selected Dates
        </Button>
      </div>
      <DataTable<RowData>
        title="Documents Audited Automatically"
        columns={columns}
        data={documentsAuditedAutomatically}
        options={{
          maxBodyHeight: 500,
          search: false,
        }}
      />

      <Modal open={!!dateDrillDownData} onClose={() => setDateDrillDownData(null)}>
        <div className={classes.paper}>
          <DataTable<DocumentsAuditedAutomaticallyDrillDown>
            title={`Documents Audited Automatically Report - ${dateDrillDownData?.toLocaleDateString()}`}
            columns={automatedAuditsColumns}
            url={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: dateDrillDownData?.toISOString().split('T')[0],
              andOnward: false,
            })}`}
            options={{
              search: false,
            }}
          />
        </div>
      </Modal>

      <Modal open={isAllDrillDownModalOpen} onClose={() => setIsAllDrillDownModalOpen(false)}>
        <div className={classes.paper}>
          <DataTable<DocumentsAuditedAutomaticallyDrillDown>
            title={`Documents Audited Automatically Report - ${new Date(
              documentsAuditedAutomatically[0].date,
            ).toLocaleDateString()} and on`}
            columns={automatedAuditsColumns}
            url={`/api/reports/automatically-audited-report-by-date?${queryString.stringify({
              date: documentsAuditedAutomatically[0].date,
              andOnward: true,
            })}`}
            options={{
              search: false,
            }}
            orderBy={{ field: 'CreatedAt' }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default AutomatedAudits;
