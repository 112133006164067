/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
import { ActionButton, InfoRow, InnerCard, InnerCardHeader, ItemLabel, linkS } from './cardStyles';
import { EditTwoIcon } from '../../components/ui/icons';
import { ClientData } from './types';
import ConditionalComponent from '../../components/ConditionalComponent';
import { AuthContext } from '../../components/AuthContext';
import { isClientAdmin } from '../../Utils';

type Props = {
  isNewClient: boolean;
  clientData: ClientData;
  downloadFile: (fileType: string) => void;
  setEditMode: () => void;
};

const ClientFilesDisplay = ({ isNewClient, clientData, downloadFile, setEditMode }: Props) => {
  const { roles } = useContext(AuthContext);

  return (
    <Fragment>
      <div className="df aic jcfe mb3">
        <ConditionalComponent display={isClientAdmin(roles)}>
          <ActionButton onClick={setEditMode}>
            <EditTwoIcon />
          </ActionButton>
        </ConditionalComponent>
      </div>
      <InnerCardHeader className="mt2">Files</InnerCardHeader>
      <InnerCard className="mt1">
        <InfoRow>
          {!isNewClient && (
            <Fragment>
              <div>
                <ItemLabel>Closing Instructions</ItemLabel>
                <ConditionalComponent display={clientData.closingInstructions}>
                  <button css={linkS} onClick={() => downloadFile('ClosingInstructions')}>
                    View
                  </button>
                </ConditionalComponent>
              </div>
              <div>
                <ItemLabel>Signed Agreement</ItemLabel>
                <ConditionalComponent display={clientData.signedAgreement && isClientAdmin(roles)}>
                  <button css={linkS} onClick={() => downloadFile('SignedAgreement')}>
                    View
                  </button>
                </ConditionalComponent>
              </div>
              <div>
                <ItemLabel>Authorization Letter</ItemLabel>
                <ConditionalComponent display={clientData.authorizationLetter}>
                  <button css={linkS} onClick={() => downloadFile('AuthorizationLetter')}>
                    View
                  </button>
                </ConditionalComponent>
              </div>
            </Fragment>
          )}
        </InfoRow>
      </InnerCard>
    </Fragment>
  );
};

export default ClientFilesDisplay;
