/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, SetStateAction, useState } from 'react';

import { Link, TableCell, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { apiPost } from '../../adalConfig';
import { Client } from '../../globalTypes/objects';
import { ClientAlias, LenderAlias } from './LenderAliasObjects';
import { useToaster } from '../../Hooks/toasters';

export default function ClientRow(props: {
  clientAlias: ClientAlias;
  alias: LenderAlias;
  clients: Client[];
  rejected: boolean;
  setPendingClientAliases: Dispatch<SetStateAction<number[]>>;
}) {
  const { clientAlias, alias, clients, rejected, setPendingClientAliases } = props;

  const [aliasType, setAliasType] = useState<
    'Alias' | 'Correspondent' | 'Rejected' | null | undefined
  >(clientAlias.aliasType);
  const [loading, setLoading] = useState<boolean>(false);

  const { successToaster, errorToaster } = useToaster();

  const onAliasTypeChanged = async (
    event: React.MouseEvent<HTMLElement>,
    selectedType: 'Alias' | 'Correspondent' | 'Rejected' | null,
  ) => {
    try {
      setLoading(true);
      await apiPost(`/api/LenderAlias/Update-Alias-Type`, {
        clientId: clientAlias.clientId,
        lenderNameId: alias.lenderNameId,
        aliasType: selectedType,
      });
      setAliasType(selectedType);
      if (selectedType === null) {
        setPendingClientAliases(pending => [...pending, clientAlias.clientId]);
      } else {
        setPendingClientAliases(pendingClients =>
          pendingClients.filter(p => p !== clientAlias.clientId),
        );
      }
      successToaster('Successfully updated the alias type');
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <TableRow key={clientAlias.clientId}>
      <TableCell>{clients.find(c => c.id === clientAlias.clientId)?.company}</TableCell>
      <TableCell>
        {!rejected && (
          <ToggleButtonGroup
            size="small"
            exclusive
            value={aliasType}
            onChange={onAliasTypeChanged}
            color="primary"
            disabled={loading}
          >
            <ToggleButton value="Alias">Alias</ToggleButton>
            <ToggleButton value="Correspondent">Correspondent</ToggleButton>
            <ToggleButton value="Rejected">Not a Match</ToggleButton>
          </ToggleButtonGroup>
        )}
      </TableCell>
      <TableCell>
        {clientAlias.documentLenders.slice(0, 3).map(doc => (
          <div key={doc.documentId} css={{ display: 'flex', paddingBottom: 4 }}>
            <div>
              <Link target="_blank" href={`/documents/${doc.documentId}`}>
                {doc.documentId}
              </Link>
            </div>
            <div css={{ paddingLeft: 8 }}>{doc.locations.join(', ')}</div>
          </div>
        ))}
      </TableCell>
      <TableCell>{clientAlias.documentLenders.length}</TableCell>
    </TableRow>
  );
}
