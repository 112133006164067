/* eslint-disable react/no-danger */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import ShortId from 'shortid';
import { Alert } from '@mui/material';
import UIfx from 'uifx';
import { ShippingDoc } from './useShipping';
import BarcodeInput from '../Utilities/BarcodeInput';
import { apiFetch } from '../../adalConfig';
import { ShippingClient } from './ClientModal';
import { Investor } from '../../globalTypes/objects';
import { docBox } from './StepTwo';
import { isSuccess, mapSuccess } from '../../globalTypes/RemoteData';
import { getDocTypeName } from '../../Utils';
import useCheckIfDocTypeUpdated from '../../Hooks/useCheckIfDocTypeUpdated';
import { XIcon } from '../ui/icons';
import colors from '../../styles/colors';
// @ts-ignore
import ErrorEffect from '../Documents/ErrorEffect.wav';

const errorFx = new UIfx({
  asset: ErrorEffect,
});

type PhysicalProps = {
  docs: ShippingDoc[];
  client: ShippingClient;
  setDoc: (doc: ShippingDoc) => void;
  investor: Investor;
  removeDoc: (documentId: number) => void;
};

const Physical = ({ docs, client, setDoc, investor, removeDoc }: PhysicalProps) => {
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [docId, setDocId] = useState<number>();
  const docTypeUpdated = useCheckIfDocTypeUpdated(docId);

  const docTypeUpdatedMessage = mapSuccess(docTypeUpdatedResult => {
    if (docTypeUpdatedResult.updated) {
      return `This document has had its DocType changed to ${getDocTypeName(
        docTypeUpdatedResult.documentType,
      )}. <br>
      Please ensure the coversheet is correct.`;
    }
    return '';
  }, docTypeUpdated);

  const barcodeRef = useRef<HTMLInputElement>();
  const docBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (docBoxRef.current) {
      docBoxRef.current.scrollTop = docBoxRef.current.scrollHeight;
    }
  }, [docs]);

  const getScannedDocAmount = () => {
    return docs.filter(d => d.clientId === client.id).length;
  };

  const addDoc = useCallback(
    async (code: string) => {
      if (!code) {
        return;
      }
      setError('');
      setWarning('');
      const id = +code.toLowerCase().replace('docprobe-', '');
      setDocId(id);
      try {
        const { data: validationWarning } = await apiFetch<string>(
          `/api/shipping/validate?docId=${id}&investorId=${investor.id}&clientId=${client.id}`,
        );
        if (docs.find(d => d.docId === id)) {
          setError('Duplicate scan');
          return;
        }
        if (validationWarning) {
          setWarning(validationWarning);
        }
        setDoc({ docId: id, clientId: client.id });
      } catch (e) {
        setError(e.response.data);
        errorFx.play();
      }
    },
    [client.id, docs, investor.id, setDoc],
  );

  return (
    <Fragment>
      <BarcodeInput
        inputRef={barcodeRef}
        onChange={addDoc}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        hidden
      />
      <div className="mb1 mt2">
        <span className="bold">
          {getScannedDocAmount()}/{client.docAmount}&nbsp;
        </span>
        Scanned documents
      </div>
      <div
        ref={docBoxRef}
        css={[docBox, focused && { border: '1px solid blue' }]}
        onClick={() => client.id && barcodeRef.current!.focus({ preventScroll: true })}
      >
        {docs.map(
          (d, index) =>
            d.clientId === client.id && (
              <div key={ShortId.generate()} css={{ padding: '6px 0' }} className="df aic jcsb">
                {d.docId}
                {index === docs.length - 1 && (
                  <p
                    onClick={() => {
                      removeDoc(d.docId);
                      setWarning('');
                      setError('');
                    }}
                    css={{
                      cursor: 'pointer',
                      padding: 2,
                      border: '1px solid white',
                      borderRadius: '50%',
                      transition: 'all .15s',
                      ':hover': { border: '1px solid blue', svg: { stroke: colors.blue } },
                    }}
                  >
                    <XIcon />
                  </p>
                )}
              </div>
            ),
        )}
      </div>
      <div css={{ maxWidth: 320, marginTop: 20 }}>
        {error && <Alert severity="error">{error}</Alert>}
        {warning && <Alert severity="warning">{warning}</Alert>}
      </div>
      {isSuccess(docTypeUpdatedMessage) && (
        <p
          css={{ color: 'blue' }}
          dangerouslySetInnerHTML={{ __html: docTypeUpdatedMessage.data }}
        />
      )}
    </Fragment>
  );
};

export default Physical;
