/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import { apiFetch } from '../../../adalConfig';
import { RowData } from '../../../pages/ClientDocumentIndex';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    container: {
      maxHeight: 'calc(100vh - 120px)',
    },
    tableHeader: {
      '& th': {
        backgroundColor: '#fff',
      },
    },
    warning: {
      color: '#856404',
      borderColor: '#ffeeba',
    },
    error: {
      color: '#721c24',
      borderColor: '#f5c6cb',
    },
  }),
);

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedDocument: RowData | null;
};

type SuggestedMatchedLoansForDocument = {
  loanId: number;
  loanNumber: string;
  propertyAddress: string;
  borrower: string;
  amountOfTimesRejected: number;
};

const DocumentSuggestedMatchesModal = ({ isOpen, setIsOpen, selectedDocument }: Props) => {
  const [suggestedMatchedLoansForDocument, setSuggestedMatchedLoansForDocument] = useState<
    SuggestedMatchedLoansForDocument[]
  >([]);

  useEffect(() => {
    const getSuggestedMatchedLoansForDocument = async () => {
      const { data } = await apiFetch<SuggestedMatchedLoansForDocument[]>(
        `/api/documentmatch/suggested-matched-loans-for-document?documentId=${selectedDocument?.documentId}`,
      );

      setSuggestedMatchedLoansForDocument(data);
    };

    if (isOpen) {
      getSuggestedMatchedLoansForDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const classes = useStyles();

  const warningIcon = (amountOfTimesRejected: number) => {
    if (!amountOfTimesRejected) {
      return;
    }

    let icon;
    if (amountOfTimesRejected === 1) {
      icon = <WarningIcon className={classes.warning} />;
    } else {
      icon = <ErrorIcon className={classes.error} />;
    }

    return (
      <Tooltip
        title={`This suggested match has been ${
          amountOfTimesRejected > 1 ? 'confirmed ' : ''
        }rejected`}
      >
        {icon}
      </Tooltip>
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className={classes.paper}>
        <TableContainer component={Paper} className={classes.container}>
          <Table size="small" stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell>Loan Data</TableCell>
                <TableCell>View Suggested Match</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suggestedMatchedLoansForDocument.map(loanData => (
                <TableRow key={loanData.loanId}>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div
                        style={{
                          width: '10%',
                          display: 'inline-flex',
                        }}
                      >
                        <div className="center-in-parent">
                          {warningIcon(loanData.amountOfTimesRejected)}
                        </div>
                      </div>
                      <div className="dib" style={{ width: '90%' }}>
                        <div className="dib w-quarter">
                          <b>Loan Number: </b>
                        </div>
                        <div className="dib w-three-quarters">
                          <div>{loanData.loanNumber}</div>
                        </div>
                        <div className="dib w-quarter">
                          <b>Property Address: </b>
                        </div>
                        <div className="dib w-three-quarters">
                          <div>{loanData.propertyAddress}</div>
                        </div>
                        <div className="dib w-quarter">
                          <b>Borrower: </b>
                        </div>
                        <div className="dib w-three-quarters">
                          <div>{loanData.borrower}</div>
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <OpenInNewIcon
                      style={{ color: 'rgb(76, 175, 80)' }}
                      onClick={() =>
                        open(
                          `${location.origin}/documents/match?documentId=${selectedDocument?.documentId}&loanId=${loanData.loanId}`,
                        )
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Modal>
  );
};

export default DocumentSuggestedMatchesModal;
