/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { RowData, SidebarOptions } from '../components/CallCenter/types';
import { formatNotes } from '../components/CallCenter/Datatable';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { apiFetch } from '../adalConfig';
import { DispositionsProvider } from '../components/CallCenter/DispositionsContext';
import ResolveSidebarOptions from '../components/CallCenter/ResolveSidebarOptions';
import DataTable from '../components/CallCenter/OutreachEscalation/DataTable';
import Filters from '../components/CallCenter/OutreachEscalation/Filters';
import { useEscalationFilters } from '../components/CallCenter/OutreachEscalation/useEscalationFilters';

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 11,
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
}));

const getDataTableRows = async () => {
  const { data } = await apiFetch<RowData[]>('/api/call-tasks/escalated');
  return data;
};

const OutreachEscalations = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataTableData, setDataTableData] = useState<RowData[]>([]);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [rowOfSelectedNote, setRowOfSelectedNote] = useState<RowData | null>(null);
  const [sidebarOption, setSidebarOption] = useState<SidebarOptions>(undefined);

  useEffect(() => {
    setIsLoadingData(true);

    getDataTableRows().then(result => {
      setDataTableData(result);
      setIsLoadingData(false);
    });
  }, []);

  const { filters, filteredData, onFilterSelected } = useEscalationFilters(dataTableData);
  const classes = useStyles();

  if (isLoadingData) {
    return <CircularProgress className={classes.loader} size="75" disableShrink />;
  }

  return (
    <DispositionsProvider>
      <div className="my3">
        <Filters filters={filters} onFilterSelected={onFilterSelected} />
      </div>
      <div className="df" style={{ marginLeft: '-24px' }}>
        <div style={{ flexGrow: 1, overflow: 'auto' }}>
          <DataTable
            dataTableData={filteredData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            setSidebarOption={setSidebarOption}
            setRowOfSelectedNote={setRowOfSelectedNote}
          />
        </div>
        <div style={{ marginLeft: '-24px', marginTop: '8px' }}>
          <ResolveSidebarOptions
            setDatatableData={setDataTableData}
            datatableData={dataTableData}
            setSidebarOption={setSidebarOption}
            sidebarOption={sidebarOption}
            selectedRows={selectedRows}
          />
        </div>
      </div>

      <Modal open={!!rowOfSelectedNote} onClose={() => setRowOfSelectedNote(null)}>
        <div className={classes.paper} style={{ width: '800px' }}>
          {rowOfSelectedNote && formatNotes(rowOfSelectedNote.pastNotes)}
        </div>
      </Modal>
    </DispositionsProvider>
  );
};

export default OutreachEscalations;
