/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { AuthConsumer } from '../components/AuthContext';

import {
  backgroundD,
  DeeForty,
  infoBoxWrap,
  pageWrap,
  stepStyle,
  stepsWrap,
  titleWrap,
} from './styles/homeStyles';

export default function Home() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <AuthConsumer>
      {({ user }) => (
        <Fragment>
          <div css={pageWrap}>
            <div css={backgroundD}>
              <DeeForty />
            </div>
            <div css={titleWrap}>
              Welcome <span>{user.givenName}</span>
            </div>
            <div
              css={{
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            >
              <div css={stepsWrap}>
                <div css={[stepStyle, loaded && { left: 56 }]}>
                  Data Import
                  <InfoBox info={stepOne} />
                </div>
                <div
                  css={[stepStyle, { top: 120, transitionDelay: '.7s' }, loaded && { left: 56 }]}
                >
                  Documents Retrieval
                  <InfoBox info={stepTwo} styles={{ top: -64 }} />
                </div>
                <div
                  css={[stepStyle, { top: 184, transitionDelay: '.9s' }, loaded && { left: 56 }]}
                >
                  Sorting
                  <InfoBox info={stepThree} styles={{ top: -128 }} />
                </div>
                <div
                  css={[stepStyle, { top: 248, transitionDelay: '1.1s' }, loaded && { left: 56 }]}
                >
                  Auditing & Correcting
                  <InfoBox info={stepFour} styles={{ top: -192 }} />
                </div>
                <div
                  css={[stepStyle, { top: 312, transitionDelay: '1.3s' }, loaded && { left: 56 }]}
                >
                  Processing & Indexing
                  <InfoBox info={stepFive} styles={{ top: -256 }} />
                </div>
                <div
                  css={[stepStyle, { top: 376, transitionDelay: '1.5s' }, loaded && { left: 56 }]}
                >
                  Shipping
                  <InfoBox info={stepSix} styles={{ top: -320 }} />
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </AuthConsumer>
  );
}

function InfoBox({ styles, info }) {
  return (
    <div css={[infoBoxWrap, styles]} className="info-box__main-wrap">
      <div
        css={{
          position: 'relative',
          img: {
            position: 'absolute',
            width: 'auto',
            maxHeight: 300,
            zIndex: '25',
            bottom: -400,
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        {info.images.map(i => (
          <img key={i} src={i} alt="" />
        ))}
        <div>{info.text}</div>
      </div>
    </div>
  );
}

const stepOne = {
  text: 'Post-closing process begins with scheduled imports of closed loan data into our system, the start of our headache free and streamlined trailing docs process.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/data-import-1_m7wz2o.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/data-import-2_stqlco.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/data-import-3_obwrzq.png',
  ],
};
const stepTwo = {
  text: 'DocProbe is the new address for all trailing docs. Using a combination of automated follow ups and calls from our dedicated account managers to title companies, EVERY document is accounted for and retrieved.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/document-retrieval-1_ougx8a.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/document-retrieval-2_c7x5jb.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/document-retrieval-3_rps3ef.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/document-retrieval-4_jas1yr.png',
  ],
};
const stepThree = {
  text: 'Our DocProbe mailroom team opens and sorts through every incoming item.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/sorting-1_rtxrq1.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/sorting-2_adhbja.png',
  ],
};
const stepFour = {
  text: 'Every document is audited for accuracy through our rigorous audit checklist. We manage and take responsibility for all necessary corrections and investor exceptions.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/audit-correct-1_rnzdzu.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106524/home/steps/audit-correct-2_i2kdn7.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/audit-correct-3_h0tizj.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/audit-correct-4_jdzjjk.png',
  ],
};
const stepFive = {
  text: 'Each document is barcoded and entered into our proprietary processing platform. Easily search and track any document as it moves towards fulfillment. Through our integration with your LOS, a digital copy is readily available at all times.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/processing-indexing-1_utdarm.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106522/home/steps/processing-indexing-2_zulyp7.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106522/home/steps/processing-indexing-3_tqd7ih.png',
  ],
};
const stepSix = {
  text: 'We ship all documents to our clients. Every document arrives on time, every time so they can build better secondary market relationships.',
  images: [
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/shipping-1_hdj2xf.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/shipping-2_cyhxnl.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/shipping-3_keafaz.png',
    'https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106523/home/steps/shipping-4_apgqst.png',
  ],
};
