/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Chip from '@mui/material/Chip';
import { Filter, FilterOption, FiltersForFilterType, FilterTypes } from './types';

type Props = {
  filterTypes: FilterTypes;
};

const getActiveFilterOptionLabels = (filter: Filter): string =>
  filter.filterOptions
    .filter((filterOption: FilterOption) => filterOption.active)
    .map((filterOption: FilterOption) => filterOption.label)
    .join(', ');

const renderChip = (filter: Filter): JSX.Element => {
  const activeFilterLabels = getActiveFilterOptionLabels(filter);

  return (
    <Chip
      key={filter.label}
      label={`${filter.label.replace(/([A-Z])/g, ' $1')}: ${activeFilterLabels}`}
      color="primary"
      style={{ textTransform: 'capitalize', margin: '6px' }}
    />
  );
};

const isActiveFilter = (filter: Filter): boolean =>
  filter.filterOptions.find((filterOption: FilterOption) => filterOption.active) !== undefined;

const renderChipsForFilterType = (filtersForFilterType: FiltersForFilterType): JSX.Element[] =>
  filtersForFilterType.filters.filter(isActiveFilter).map(renderChip);

const renderChips = (filterTypes: FilterTypes): JSX.Element[][] =>
  filterTypes.map((filtersForFilterType: FiltersForFilterType) =>
    renderChipsForFilterType(filtersForFilterType),
  );

const Chips = ({ filterTypes }: Props) => (
  <div css={{ margin: '0px 0px 16px' }}>{renderChips(filterTypes)}</div>
);

export default Chips;
