/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { TabName } from './types';
import { filesArray, listWrap } from './contentList';

interface Props {
  tab: TabName;
}

export default function FileSamplesPage({ tab }: Props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isAdmin = false;
  const pdfList = filesArray
    .filter(i => i.type === 'pdf' && i.tabs.includes(tab))
    .sort((a, b) => (a.title > b.title ? 1 : -1));
  const spreadSheets = filesArray
    .filter(i => i.type === 'xls' && i.tabs.includes(tab))
    .sort((a, b) => (a.title > b.title ? 1 : -1));
  const videos = filesArray
    .filter(i => i.type === 'video' && i.tabs.includes(tab))
    .sort((a, b) => (a.title > b.title ? 1 : -1));

  return (
    <div css={{ minHeight: 'calc(100vh - 100px)' }}>
      {isAdmin && (
        <div>
          <Fab color="primary" aria-label="add" size="small" onClick={handleClickOpen}>
            <AddIcon />
          </Fab>
        </div>
      )}
      {/* ---- LIST OF DOCS ----- */}
      {(pdfList.length > 0 || spreadSheets.length > 0) && (
        <div css={listWrap}>
          <h3>Docs</h3>
          <List component="nav" aria-label="main mailbox folders">
            {pdfList.map(i => (
              <PdfFile key={i.src} i={i} tab={tab} />
            ))}
            {spreadSheets.map(i => (
              <File key={i.src} i={i} />
            ))}
          </List>
        </div>
      )}
      {/* ---- LIST OF VIDEOS ----- */}
      {videos.length > 0 && (
        <div css={listWrap}>
          <h3>Videos</h3>
          <List component="nav" aria-label="main mailbox folders">
            {videos.map(i => (
              <Video key={i.src} i={i} isAdmin={isAdmin} />
            ))}
          </List>
        </div>
      )}

      {/* ---- ADMIN MODAL TO ADD CONTENT ---- */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Video</DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            label="Video Url"
            variant="outlined"
            css={{ width: '100%' }}
          />
          <TextField
            css={{ '&&': { width: '100%', marginTop: 24 } }}
            id="outlined-basic"
            label="Video Label"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function File({ i }) {
  return (
    <a
      className="db"
      target="_blank"
      rel="noopener noreferrer"
      href={`https://res.cloudinary.com/dyuuf6gor/raw/upload/docprobe-videos/${i.src}`}
    >
      <ListItem button>
        <ListItemText primary={i.title} />
      </ListItem>
      <Divider />
    </a>
  );
}

function PdfFile({ i, tab }) {
  const [openPdf, setPdfOpen] = useState(false);
  const handlePdfClose = () => {
    setPdfOpen(false);
  };
  return (
    <Fragment>
      <div
        className="db"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setPdfOpen(true)}
      >
        <ListItem button>
          <ListItemText primary={i.title} />
        </ListItem>
        <Divider />
      </div>
      {/* ---- MODAL TO VIEW PDF ---- */}
      <Dialog
        open={openPdf}
        onClose={handlePdfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        css={{ '.MuiDialog-paperWidthLg': { width: '100%', height: '100%' } }}
      >
        <DialogContent>
          <iframe
            width="100%"
            height="100%"
            src={`https://res.cloudinary.com/dyuuf6gor/image/upload/docprobe-videos/${
              i.src
            }#${tab !== TabName.Letters && !i.downloadable && 'toolbar=0&'}navpanes=0&scrollbar=1`}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function Video({ i, isAdmin }) {
  const [openVideo, setVideoOpen] = useState(false);
  const handleVideoClose = () => {
    setVideoOpen(false);
  };
  return (
    <Fragment>
      <div
        className="db"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setVideoOpen(true)}
      >
        <ListItem button>
          <ListItemText primary={i.title} />
        </ListItem>
        <Divider />
      </div>
      {/* ---- MODAL TO PLAY VIDEO ---- */}
      <Dialog
        open={openVideo}
        onClose={handleVideoClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        css={{ '.MuiDialog-paperWidthLg': { width: '100%' } }}
      >
        <DialogContent>
          <div className="p1 mt1 bold allcaps fs18">{i.title}</div>
          <video
            width="100%"
            controls
            autoPlay
            css={{
              maxHeight: '760px',
              marginTop: 24,
            }}
          >
            <source
              src={`https://res.cloudinary.com/dyuuf6gor/video/upload/f_auto/docprobe-videos/${i.src}`}
              type="video/mp4"
            />
          </video>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVideoClose} color="primary" autoFocus>
            Close
          </Button>
          <div className="df aic">
            {isAdmin && (
              <div
                css={{
                  svg: {
                    marginLeft: 8,
                    cursor: 'pointer',
                    '&:hover': { color: 'red' },
                  },
                }}
              >
                {/* <EditIcon color="primary" />
                <DeleteIcon color="primary" /> */}
              </div>
            )}
          </div>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
