/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import { match as routeMatch, RouteComponentProps } from 'react-router';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Chip, Tooltip } from '@mui/material';
import { apiFetch, apiPost } from '../adalConfig';
import { Investor } from '../globalTypes/objects';
import { roleTypes } from '../constants';
import { AuthContext } from '../components/AuthContext';
import DataTable from '../components/ui/DataTable/DataTable';
import useDataTableFilters from '../components/ui/DataTable/UseDataTableFilters';
import InvestorSearchSelect from '../components/Utilities/InvestorSearchSelect';
import useFileExport from '../components/ui/DataTable/FileExport';
import { useToaster } from '../Hooks/toasters';
import { useClient } from '../Hooks/useClients';
import RequiredDocumentSettings, { Setting } from '../components/Loans/RequiredDocumentSettings';
import Modal from '@mui/material/Modal';

export enum LoanDocumentRequiredAndReceivedStatus {
  NotRequiredAndNotReceived = 0,
  RequiredButNotReceived = 1,
  RequiredAndReceived = 2,
  NotRequiredAndReceived = 3,
}

export type RequiredDocument = {
  documentType: string;
  status: LoanDocumentRequiredAndReceivedStatus;
};

type RowData = {
  id: number;
  appNumber: string;
  dateFunded: string;
  dateUploaded: string;
  borrower: string;
  investor: string;
  requiredDocuments: RequiredDocument[];
};

type ExportData = {
  ID: number;
  'App Number': string;
  'Date Funded': string;
  'Date Uploaded': string;
  Borrower: string;
  Investor: string;
  Mortgage: string;
  Policy: string;
  POA: string;
  CEMA: string;
  UCC: string;
  HECM: string;
  Assignment: string;
  'Second Mortgage': string;
};

interface Props extends RouteComponentProps<any> {
  match: routeMatch<{ clientId: string }>;
  history: any;
}

export const iconRender = (value: LoanDocumentRequiredAndReceivedStatus) => {
  switch (value) {
    case LoanDocumentRequiredAndReceivedStatus.RequiredButNotReceived:
      return (
        <Tooltip title="Required and not Received">
          <RadioButtonUncheckedIcon color="primary" />
        </Tooltip>
      );
    case LoanDocumentRequiredAndReceivedStatus.RequiredAndReceived:
      return (
        <Tooltip title="Required and Received">
          <CheckCircleOutlineIcon color="primary" />
        </Tooltip>
      );
    case LoanDocumentRequiredAndReceivedStatus.NotRequiredAndReceived:
      return (
        <Tooltip title="Not Required but Received">
          <CheckCircleOutlineIcon color="action" />
        </Tooltip>
      );
    default:
      return null;
  }
};

const ClientLoans = ({ match, history }: Props) => {
  const [clientName, setClientName] = useState('');
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [isRequireDocModalOpen, setIsRequireDocModalOpen] = useState(false);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});
  const [isSavingRequiredStatus, setIsSavingRequiredStatus] = useState(false);

  const authContext = useContext(AuthContext);
  const client = useClient(parseInt(match.params.clientId));
  const { successToaster, errorToaster } = useToaster();

  const {
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    onCustomOptionSelected,
    clearCustomFilter,
  } = useDataTableFilters('/api/loans/filters');

  const investorFilter = customFilters.find(filter => filter.label === 'Investors');
  const activeInvestorFilterOption = investorFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const investor: Investor | undefined = activeInvestorFilterOption
    ? ({ id: activeInvestorFilterOption.id, name: activeInvestorFilterOption.label } as Investor)
    : undefined;

  useEffect(() => {
    const getClientName = async () => {
      try {
        const { data } = await apiFetch<string>('/api/clients/GetClientName', {
          params: { id: match.params.clientId },
        });

        setClientName(data);
      } catch (e) {
        errorToaster(e?.response?.data.split('\n')[0] ?? e.message);
      }
    };

    if (client !== undefined) {
      setClientName(client.company);
      return;
    }

    getClientName();
  }, [match.params.clientId]);

  const columns = [
    {
      field: 'id',
      title: 'ID',
      hidden: !authContext.roles.includes(roleTypes.Dev),
    },
    {
      field: 'appNumber',
      title: 'App Number',
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: (rowData: RowData) => rowData && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'dateUploaded',
      title: 'Date Uploaded',
      render: (rowData: RowData) => rowData && new Date(rowData.dateUploaded).toLocaleDateString(),
    },
    {
      field: 'borrower',
      title: 'Borrower',
    },
    {
      field: 'investor',
      title: 'Investor',
      sorting: false,
    },
    {
      field: 'mortgage',
      title: 'Mortgage',
      sorting: false,
      render: ({ requiredDocuments }: RowData) => {
        const mortgage = requiredDocuments.find(
          requiredDocument => requiredDocument.documentType === 'Mortgage',
        );

        if (!mortgage) {
          return null;
        }

        return iconRender(mortgage.status);
      },
    },
    {
      field: 'policy',
      title: 'Policy',
      sorting: false,
      render: ({ requiredDocuments }: RowData) => {
        const policy = requiredDocuments.find(
          requiredDocument => requiredDocument.documentType === 'Policy',
        );

        if (!policy) {
          return null;
        }

        return iconRender(policy.status);
      },
    },
    {
      field: 'other',
      title: 'Other',
      sorting: false,
      render: ({ requiredDocuments }: RowData) => {
        const otherDocumentTypes = requiredDocuments
          .filter(({ documentType }) => documentType !== 'Mortgage' && documentType !== 'Policy')
          .filter(
            ({ status }) =>
              status !== LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
          );

        return (
          <Fragment>
            {otherDocumentTypes.map(({ documentType, status }) => (
              <Fragment key={documentType}>
                <span css={{ fontWeight: 'bold', padding: 4 }}>{documentType}</span>
                {iconRender(status)}
              </Fragment>
            ))}
          </Fragment>
        );
      },
    },
  ];

  const saveDocumentStatusUpdate = async (settings: Setting[], noteText: string) => {
    setIsSavingRequiredStatus(true);

    try {
      const { data: response } = await apiPost('/api/loans/update-required-status', {
        loanIds: selectedRows.map(row => row.id),
        settings: settings.map(setting => ({
          documentType: setting.documentType!.value,
          isRequired: setting.isRequired,
        })),
        noteText,
      });

      if (response === 1) {
        refreshDatatable();
        successToaster('Successfully updated the document status');
      } else {
        throw new Error('Failed to update the document status');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsSavingRequiredStatus(false);
      setIsRequireDocModalOpen(false);
    }
  };

  const documentReceivedStatus = (status: LoanDocumentRequiredAndReceivedStatus) => {
    switch (status) {
      case LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived: {
        return 'Not Required';
      }
      case LoanDocumentRequiredAndReceivedStatus.RequiredButNotReceived: {
        return 'Not Received';
      }
      default: {
        return 'Received';
      }
    }
  };

  const fileExport = useFileExport<ExportData>();

  const exportDatatable = (dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map(row => {
      let mortgage: RequiredDocument | undefined;
      let policy: RequiredDocument | undefined;
      let poa: RequiredDocument | undefined;
      let cema: RequiredDocument | undefined;
      let ucc: RequiredDocument | undefined;
      let hecm: RequiredDocument | undefined;
      let assignment: RequiredDocument | undefined;
      let secondMortgage: RequiredDocument | undefined;
      for (let requiredDocument of row.requiredDocuments) {
        switch (requiredDocument.documentType) {
          case 'Mortgage':
            mortgage = requiredDocument;
            break;
          case 'Policy':
            policy = requiredDocument;
            break;
          case 'POA':
            poa = requiredDocument;
            break;
          case 'CEMA':
            cema = requiredDocument;
            break;
          case 'UCC':
            ucc = requiredDocument;
            break;
          case 'HECM':
            hecm = requiredDocument;
            break;
          case 'Assignment':
            assignment = requiredDocument;
            break;
          case 'Second Mortgage':
            secondMortgage = requiredDocument;
            break;
        }
      }

      const exportRow: ExportData = {
        ID: row.id,
        'App Number': row.appNumber,
        'Date Funded': row.dateFunded,
        'Date Uploaded': row.dateUploaded,
        Borrower: row.borrower,
        Investor: row.investor,
        Mortgage: documentReceivedStatus(
          mortgage?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        Policy: documentReceivedStatus(
          policy?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        POA: documentReceivedStatus(
          poa?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        CEMA: documentReceivedStatus(
          cema?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        UCC: documentReceivedStatus(
          ucc?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        HECM: documentReceivedStatus(
          hecm?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        Assignment: documentReceivedStatus(
          assignment?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
        'Second Mortgage': documentReceivedStatus(
          secondMortgage?.status || LoanDocumentRequiredAndReceivedStatus.NotRequiredAndNotReceived,
        ),
      };

      return exportRow;
    });

    fileExport(exportData, 'Client Loans');
  };

  return (
    <Fragment>
      {investorFilter?.filterOptions && investorFilter.filterOptions.length > 0 && investor?.id && (
        <div css={{ marginTop: 36, marginLeft: 52 }}>
          <Chip
            variant="outlined"
            onClick={() => clearCustomFilter(investorFilter)}
            label="Clear Investor Filter"
          />
        </div>
      )}
      <div className="mt1 pl2">
        <DataTable<RowData>
          title={
            <span>
              Clients
              <span css={{ verticalAlign: 'middle' }}>
                <KeyboardArrowRightIcon color="action" />
              </span>
              {clientName}
            </span>
          }
          columns={columns}
          url={`/api/loans/${match.params.clientId}`}
          dataTableFilterTypes={dataTableFilterTypes}
          setDataTableFilterTypes={setDataTableFilterTypes}
          renderCustomFilters={
            <div css={{ margin: '24px 4px' }}>
              {investorFilter && (
                <InvestorSearchSelect
                  selected={investor}
                  onChange={({ id, name }) =>
                    onCustomOptionSelected(investorFilter, { id, label: name, active: true })
                  }
                />
              )}
            </div>
          }
          orderBy={{ field: 'dateFunded' }}
          orderDirection="desc"
          onRowClick={(event, rowData) => history.push(`/loans/${rowData.id}`)}
          onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
          allowMultiSearch
          options={{
            selection: true,
          }}
          actions={() => [
            {
              key: 'require-doc',
              tooltip: 'Toggle if a document is required',
              icon: () => <PostAddIcon color="primary" />,
              onClick: () => setIsRequireDocModalOpen(x => !x),
            },
          ]}
          onSelectionChange={rows => setSelectedRows(rows)}
          customExport={exportDatatable}
        />
      </div>

      <Modal open={isRequireDocModalOpen} onClose={() => setIsRequireDocModalOpen(false)}>
        <RequiredDocumentSettings
          setIsModalOpen={setIsRequireDocModalOpen}
          onSubmit={saveDocumentStatusUpdate}
          isSaving={isSavingRequiredStatus}
        />
      </Modal>
    </Fragment>
  );
};

export default ClientLoans;
