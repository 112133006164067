/** @jsx jsx */
import { jsx } from '@emotion/core';

import { useState, Fragment } from 'react';
import { CheckIcon, XIcon } from '../ui/icons';
import colors from '../../styles/colors';
import { VerificationStatus } from './VerificationPanel';

// #region css
const checkIconWrap = {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  backgroundColor: colors.white,
  border: '1px solid #e5e5ea',
  color: colors.grayLight,
  borderRadius: '50%',
  height: 32,
  width: 32,
  marginRight: 8,
  ':hover': {
    color: colors.blue,
  },
};

const xIconWrap = {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  backgroundColor: colors.white,
  borderRadius: '50%',
  border: '1px solid #e5e5ea',
  color: colors.grayLight,
  height: 32,
  width: 32,
  marginLeft: 10,
  ':hover': {
    color: colors.red,
  },
};

const isFalse = {
  ' svg': { color: colors.red },
  backgroundColor: colors.grayLight,
  ' p': {
    color: colors.red,
  },
};

const isTrue = {
  ' svg': { color: colors.blue },
  backgroundColor: colors.grayLight,
};
// #endregion

type InfoProps = {
  info: Info;
  onClick: (verified: boolean) => void;
  isError: VerificationStatus;
};

type Info = {
  name: string;
  data: string;
};

export type EditProps = AllOrNone<{
  editable: boolean;
  setValue: (e: React.FocusEvent<HTMLInputElement>) => void;
}>;

type Props = InfoProps & EditProps;

export default function VerifyItemError({ info, onClick, isError, setValue, editable }: Props) {
  const [newData, setNewData] = useState();
  return (
    <div
      css={{
        borderBottom: '4px solid #e5e5e5',
        paddingTop: 2,
        display: 'flex',
        flexDirection: 'row',
        fontSize: 14,
        justifyContent: 'space-between',
      }}
    >
      <div>
        <span css={{ color: colors.grayDark }}>{info.name}</span>
        <p
          css={{
            color: isError === true ? colors.red : colors.black,
            // marginLeft: '82px',
          }}
        >
          {editable ? (
            <input
              value={newData || info.data}
              onChange={e => setNewData(e.target.value)}
              onBlur={e => setValue!(e)}
            />
          ) : (
            <Fragment>{info.data}</Fragment>
          )}
        </p>
      </div>
      <div>
        <button
          css={[xIconWrap, isError === true && isFalse]}
          onClick={() => onClick(isError === true)}
        >
          <XIcon />
        </button>
      </div>
    </div>
  );
}
