/** @jsx jsx */
import { jsx } from '@emotion/core';
import DataTable from '../../components/ui/DataTable/DataTable';
import { useContext } from 'react';
import { AuthContext } from '../../components/AuthContext';
import { roleTypes } from '../../constants';
import useDataTableFilters from '../../components/ui/DataTable/UseDataTableFilters';
import AnalyticsFilters from './AnalyticsFilters';
import useFileExport from '../../components/ui/DataTable/FileExport';

type RowData = {
  logId: number;
  client: string;
  email: string;
  role: string;
  pageRoute: string;
  createdAt: string;
};

type ExportData = {
  'Log Id': number;
  Client: string;
  Email: string;
  Role: string;
  Page: string;
  'Timestamp (EST)': string;
};

const Analytics = () => {
  const { roles } = useContext(AuthContext);
  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    clearCustomFilter,
  } = useDataTableFilters('/api/client-portal-user-management/analytics-filters');
  const fileExport = useFileExport<ExportData>();

  const columns = [
    {
      field: 'logId',
      title: 'Log Id',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'client',
      title: 'Client',
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'role',
      title: 'Role',
    },
    {
      field: 'pageRoute',
      title: 'Page',
    },
    {
      field: 'createdAt',
      title: 'Timestamp (EST)',
      render: ({ createdAt }: RowData) => <span>{new Date(createdAt).toLocaleString()}</span>,
    },
  ];

  const exportDatatable = (dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map(
      row =>
        ({
          'Log Id': row.logId,
          Client: row.client,
          Email: row.email,
          Role: row.role,
          Page: row.pageRoute,
          'Timestamp (EST)': new Date(row.createdAt).toLocaleString(),
        } as ExportData),
    );

    fileExport(exportData, 'Client portal analytics');
  };

  return (
    <DataTable<RowData>
      title="Client portal analytics"
      columns={columns}
      url="/api/client-portal-user-management/analytics"
      dataTableFilterTypes={dataTableFilterTypes}
      setDataTableFilterTypes={setDataTableFilterTypes}
      renderCustomFilters={
        <AnalyticsFilters
          onCustomOptionSelected={onCustomOptionSelected}
          customFilters={customFilters}
          clearCustomFilter={clearCustomFilter}
        />
      }
      customExport={exportDatatable}
      orderBy={{ field: 'createdAt' }}
      orderDirection="desc"
    />
  );
};

export default Analytics;
