/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { AuditPoint } from '../../../globalTypes/objects';
import AuditModalBody from './AuditModalBody';
import AuditModalHeader, { AuditModalHeaderProps } from './AuditModalHeader';
import { Root } from './auditStyles';
import { useAuditFailContext } from './useAuditFailContext';

type AuditModalProps = AuditModalHeaderProps & {
  className?: string; // comes in from styled component
  alreadySelectedFailures?: AuditPoint[];
};
export default function AuditModal({
  className,
  closeModal,
  saveFailedAuditData,
  continueBtnTxt,
  alreadySelectedFailures,
}: AuditModalProps) {
  const [_, updateFailedReasons] = useAuditFailContext();
  useEffect(() => {
    alreadySelectedFailures?.length && updateFailedReasons(alreadySelectedFailures);
  }, [alreadySelectedFailures, updateFailedReasons]);
  return (
    <Root className={className}>
      <Global
        styles={{
          body: {
            height: '100vh',
            overflow: 'hidden',
          },
        }}
      />
      <AuditModalHeader
        continueBtnTxt={continueBtnTxt}
        closeModal={closeModal}
        saveFailedAuditData={saveFailedAuditData}
      />
      <AuditModalBody />
    </Root>
  );
}
