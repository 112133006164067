/** @jsx jsx */
import { jsx } from '@emotion/core';
import InputMask from './InputMask';
import { TextInputProps } from './TextInput';

const beforeMaskedValueChange = (newState, oldState, userInput, mask, propsValue) => {
  let { value, selection } = newState;
  let cursorPosition = selection ? selection.start : null;

  if (
    (value.endsWith('-') && userInput !== '-' && !propsValue.endsWith('-')) ||
    (value.endsWith('(') && userInput !== '(' && !propsValue)
  ) {
    if (cursorPosition === value.length) {
      cursorPosition -= 1;
      selection = { start: cursorPosition, end: cursorPosition };
    }
    value = value.slice(0, -1);
  }

  return {
    value,
    selection,
  };
};

export default function ZipInput({ value, ...props }: TextInputProps) {
  return (
    <InputMask
      beforeMaskedValueChange={(...args) => beforeMaskedValueChange(...args, value)}
      mask="99999-9999"
      maskChar=""
      value={value}
      {...props}
    />
  );
}
