/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, Component, FunctionComponent, Fragment } from 'react';

import ClientSearch from '../Utilities/ClientSearch';
import DateRange from './DateRange';
import { Client } from '../../globalTypes/objects';
import BackButton from './BackButton';
import Panel, { PanelHeader } from '../ui/Panel';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import { usePostData } from '../../Hooks';

export default function CreateMatches() {
  const [client, setClient] = useState<Client>();
  const currentDate = new Date();
  const [to, setTo] = useState<Date>(currentDate);
  const [from, setFrom] = useState<Date>(currentDate);

  const { isLoading: loading, error, isSuccess: success, doPost } = usePostData();

  const onSubmit = () => {
    doPost('/api/documents/createVerificationMatches', { clientId: client!.id, from, to });
  };

  React.useEffect(() => {
    if (success) {
      setTimeout(() => {
        window.location = window.location;
      }, 1000);
    }
  }, [success]);

  const getStatus = () => {
    return (
      (loading && StatusTypes.loading) ||
      (error && StatusTypes.error) ||
      (success && StatusTypes.success) ||
      StatusTypes.initial
    );
  };

  return (
    <Fragment>
      <div css={{ marginBottom: 24 }}>
        <BackButton to="/admin">Admin Page</BackButton>
      </div>
      <Panel styles={{ margin: '40px', marginTop: 24 }}>
        <PanelHeader text="Create Document Matches" />
        <br />
        <ClientSearch onChange={selection => setClient(selection)} />
        <br />
        <div css={{ marginBottom: 10 }}>
          <h3>Loan Upload Date</h3>
        </div>
        <DateRange handleFromChange={f => setFrom(f)} handleToChange={t => setTo(t)} />
        <StatusButton
          disabled={!client}
          onClick={() => onSubmit()}
          text="Submit"
          status={getStatus()}
        />
      </Panel>
    </Fragment>
  );
}
