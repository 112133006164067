/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import { format } from 'date-fns';
import {
  CompanyDrillDownData,
  CorrectionsTotalByMonthAndClient,
  CorrectionsTotalByMonthAndUserDrillDown,
} from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import useFileExportToExcel, {
  addImageToExcel,
} from '../../components/ui/DataTable/FileExportToExcel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  correctionsTotalByMonthAndClient: CorrectionsTotalByMonthAndClient[];
  fromDate: Date;
  oldVersion: boolean;
};

const drillDownColumns = [
  {
    field: 'loanNumber',
    title: 'Loan Number',
    options: {
      filter: false,
    },
    render: (rowData: CorrectionsTotalByMonthAndUserDrillDown) => (
      <Link target="_blank" href={`/loans/${rowData.loanId}`}>
        {rowData.loanNumber}
      </Link>
    ),
  },
  {
    field: 'documentType',
    title: 'Document Type',
    options: {
      filter: false,
    },
  },
  {
    field: 'dateFunded',
    title: 'Date Funded',
    render: (rowData: CorrectionsTotalByMonthAndUserDrillDown) =>
      new Date(rowData.dateFunded).toLocaleDateString(),
    options: {
      filter: false,
    },
  },
  {
    field: 'dateProcessed',
    title: 'Date Processed',
    render: (rowData: CorrectionsTotalByMonthAndUserDrillDown) =>
      new Date(rowData.dateProcessed).toLocaleDateString(),
    options: {
      filter: false,
    },
  },
  {
    field: 'firstDateFailed',
    title: 'Date Audited',
    sorting: false,
    render: (rowData: CorrectionsTotalByMonthAndUserDrillDown) =>
      new Date(rowData.firstFailedDate).toLocaleDateString(),
    options: {
      filter: false,
    },
  },
  {
    field: 'failedAuditReason',
    title: 'Failed Audit Reason',
    options: {
      filter: false,
    },
  },
  {
    field: 'failedAuditNotes',
    title: 'Failed Audit Notes',
    options: {
      filter: false,
    },
  },
  {
    field: 'failedVerificationNotes',
    title: 'Failed Verification Notes',
    options: {
      filter: false,
    },
  },
  {
    field: 'deletedDate',
    title: '',
    render: (rowData: CorrectionsTotalByMonthAndUserDrillDown) =>
      rowData.deletedDate ? `deleted on ${new Date(rowData.deletedDate).toLocaleDateString()}` : '',
    options: {
      filter: false,
    },
  },
];

const drilldownUrl = (oldVersion: boolean) =>
  `/api/reports/document-corrections-by-client-drill-down-report${oldVersion ? '-old' : ''}`;

const CorrectionsTotalByMonthAndClientReport = ({
  correctionsTotalByMonthAndClient,
  fromDate,
  oldVersion,
}: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [companyDrillDownData, setCompanyDrillDownData] = useState<CompanyDrillDownData | null>(
    null,
  );

  const classes = useStyles();

  const columns = [
    {
      field: 'client',
      title: 'Client',
      options: {
        filter: false,
      },
      render: (rowData: CorrectionsTotalByMonthAndClient) => (
        <Link
          css={{ width: '225px', textAlign: 'left' }}
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setCompanyDrillDownData({
              month: new Date(rowData.month),
              client: rowData.client,
              clientId: rowData.clientId,
            })
          }
        >
          {rowData.client}
        </Link>
      ),
    },
    {
      field: 'totalLoans',
      title: 'Total Loans',
      options: {
        filter: false,
      },
    },
    {
      field: 'corrections',
      title: 'Corrections',
      options: {
        filter: false,
      },
    },
    {
      field: 'percentDocsFailed',
      title: '% failed',
      options: {
        filter: false,
      },
    },
    {
      field: 'tolerancePercent',
      title: 'Tolerance %',
      options: {
        filter: false,
      },
      render: (rowData: CorrectionsTotalByMonthAndClient) => rowData.tolerancePercent,
    },
    {
      field: 'numAbovePercent',
      title: 'Number Above %',
      options: {
        filter: false,
      },
      render: (rowData: CorrectionsTotalByMonthAndClient) =>
        rowData.tolerancePercent === 0 ? 'n/a' : rowData.numAbovePercent,
    },
    {
      field: 'chargeAmount',
      title: 'Charge Amount',
      options: {
        filter: false,
      },
      render: (rowData: CorrectionsTotalByMonthAndClient) =>
        rowData.chargeAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) ??
        'n/a',
    },
  ];

  type ExportHeaderData = {
    Client: string;
    'Total Loans': number;
    Corrections: number;
    'Percent Docs Failed': number;
    'Tolerance Percent': number;
    'Number Above Percent': number;
    'Charge Amount': string;
  };

  type ExportData = {
    'Loan Number': string;
    'Document Type': string;
    'Date Funded': string;
    'Date Processed': string;
    'Date Failed': string;
    'Fail Audit Reason'?: string;
    'Fail Audit Note'?: string;
    'Fail Verification Note'?: string;
  };

  const headerFileExport = useFileExportToExcel<ExportHeaderData>();

  const exportHeaderDatatable = (
    columnsParam: ExportHeaderData[],
    dataTableRows: CorrectionsTotalByMonthAndClient[],
  ) => {
    let firstRow = {} as CorrectionsTotalByMonthAndClient;

    const exportData = dataTableRows.map<ExportHeaderData>((row) => {
      if (!firstRow.client) {
        firstRow = row;
      }

      return {
        Client: row.client,
        'Total Loans': row.totalLoans,
        Corrections: row.corrections,
        'Percent Docs Failed': row.percentDocsFailed,
        'Tolerance Percent': row.tolerancePercent === 0 ? 'n/a' : row.tolerancePercent,
        'Number Above Percent': row.tolerancePercent === 0 ? 'n/a' : row.numAbovePercent,
        'Charge Amount':
          row.chargeAmount?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          }) ?? 'n/a',
      } as ExportHeaderData;
    });

    const fileName = `Corrections_For_${format(new Date(fromDate), 'MMMM_yyyy')}`;

    const rowHeader = [
      'Client',
      'Total Docs Processed',
      'Corrections',
      'Percent Docs Failed',
      'Tolerance Percentage',
      'Number Above Percentage',
      'Charge Amount',
    ];

    headerFileExport(rowHeader, exportData, fileName, addImageToExcel);
  };

  const fileExport = useFileExportToExcel<ExportData>();

  const exportDatatable = (dataTableRows: CorrectionsTotalByMonthAndUserDrillDown[]) => {
    let firstRow = {} as CorrectionsTotalByMonthAndUserDrillDown;

    const exportData = dataTableRows.map((row) => {
      if (!firstRow.loanNumber) {
        firstRow = row;
      }

      const exportRow = {
        'Loan Number': row.loanNumber,
        'Document Type': row.documentType,
        'Date Funded': row.dateFunded && new Date(row.dateFunded).toLocaleDateString(),
        'Date Processed': row.dateProcessed && new Date(row.dateProcessed).toLocaleDateString(),
        'Date Failed': row.firstFailedDate && new Date(row.firstFailedDate).toLocaleDateString(),
        'Fail Audit Reason': row.failedAuditReason,
        'Fail Audit Note': row.failedAuditNotes,
        'Fail Verification Note': row.failedVerificationNotes,
        ' ': row.deletedDate && `deleted on ${new Date(row.deletedDate).toLocaleDateString()}`,
      };

      return exportRow;
    });

    const fileName = `${companyDrillDownData?.client ?? 'All_Clients'}_Corrections_${format(
      new Date(fromDate),
      'MMMM_yyyy',
    )}`;

    const rowHeader = [
      'Loan Number',
      'Document Type',
      'Date Funded',
      'Date Processed',
      'Date Failed',
      'Fail Audit Reason',
      'Fail Audit Note',
      'Fail Verification Note',
      ' ',
    ];

    fileExport(rowHeader, exportData, fileName, addImageToExcel);
  };

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: 25,
            zIndex: 1,
            right: '130px',
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          Drilldown
        </Button>
      </div>
      <DataTable<CorrectionsTotalByMonthAndClient>
        title={`Corrections By Month And Client for  ${format(new Date(fromDate), 'MMMM   yyyy')}`}
        columns={columns}
        data={correctionsTotalByMonthAndClient}
        options={{
          maxBodyHeight: '500px',
          search: false,
          exportCsv: exportHeaderDatatable,
        }}
      />
      <Modal open={!!companyDrillDownData} onClose={() => setCompanyDrillDownData(null)}>
        <div className={classes.paper}>
          <DataTable<CorrectionsTotalByMonthAndUserDrillDown>
            title={`Document Corrections Details Report - For ${
              companyDrillDownData?.client
            } - ${format(new Date(fromDate), 'MMMM   yyyy')}`}
            columns={drillDownColumns}
            url={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: companyDrillDownData?.clientId,
              fromDate: companyDrillDownData?.month.toISOString().split('T')[0],
            })}`}
            customExport={exportDatatable}
            options={{
              selection: false,
              search: false,
            }}
          />
        </div>
      </Modal>

      <Modal open={isAllDrillDownModalOpen} onClose={() => setIsAllDrillDownModalOpen(false)}>
        <div className={classes.paper}>
          <DataTable<CorrectionsTotalByMonthAndUserDrillDown>
            title={`Document Corrections Details Report for ${format(
              new Date(fromDate),
              'MMMM   yyyy',
            )}`}
            columns={drillDownColumns}
            url={`${drilldownUrl(oldVersion)}?${queryString.stringify({
              clientId: null,
              fromDate: fromDate.toISOString().split('T')[0],
            })}`}
            customExport={exportDatatable}
            options={{
              selection: false,
              search: false,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default CorrectionsTotalByMonthAndClientReport;
