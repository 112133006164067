/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { BooleanParam, useQueryParam } from 'use-query-params';
import Panel, { PanelHeader } from '../ui/Panel';
import LoanDropdown from '../Utilities/Dropdowns/LoanDropdown';
import colors from '../../styles/colors';
import { apiPost } from '../../adalConfig';
import { useGetData } from '../../Hooks';
import {
  Client,
  Document,
  DocumentType,
  DocumentVerificationMatch,
  Loan,
  TrueNotFounds,
} from '../../globalTypes/objects';
import Button from '../ui/Button';
import Icon, { IconType } from '../ui/Icon';
import MatchVerification from './MatchVerification';
import DocumentImportInformation from './DocumentImportInformation';
import { useToaster } from '../../Hooks/toasters';
import { getToken } from '../../auth-config';

// #region css
const panelWrap = {
  marginBottom: 24,
  maxWidth: 720,
};
const boxStyles = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  marginTop: 32,
  maxWidth: 656,
  padding: '32px 32px',
};

const disabledOverlay = {
  cursor: 'normal',
  position: 'relative',
  ':before': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    content: '""',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: '20',
  },
};

// #endregion

enum Mode {
  default,
  matchVerification,
}

type CreateMatchProps = {
  docId: number;
  client: Client;
  handleClose: () => void;
};

function authHeader(token) {
  return { Authorization: `Bearer ${token}` };
}

async function checkForTrueNotFound(docId): Promise<TrueNotFounds[]> {
  const token = await getToken();
  const res = await fetch(`/api/true-not-founds/GetByDocId/${docId}`, {
    headers: authHeader(token),
  });
  return res.json();
}

export default function CreateMatch({ docId, client, handleClose }: CreateMatchProps) {
  const { data: document } = useGetData<Document>(
    `/api/documents/getDocument?docId=${docId}`,
    {} as Document,
  );
  const [loan, setLoan] = useState({} as Loan);
  const [currentMode, setCurrentMode] = useState(Mode.default);
  const [match, setMatch] = useState<DocumentVerificationMatch>();
  const [isTrueNotFound, setIsTrueNotFound] = useState<boolean>(false);
  const [inManualExtractionMode] = useQueryParam('performManualExtraction', BooleanParam);

  const { successToaster, errorToaster } = useToaster();

  function createManualMatch() {
    return apiPost<DocumentVerificationMatch>('/api/documentMatch/createManualMatchSuggestion', {
      documentId: docId,
      loanId: loan.id,
    })
      .then(({ data }) => {
        setMatch(data);
        setCurrentMode(Mode.matchVerification);
      })
      .catch(console.error);
  }

  useEffect(() => {
    checkForTrueNotFound(docId).then(data => {
      setIsTrueNotFound(data.length !== 0);
    });
  }, [docId]);

  function flagTrueNotFound() {
    return apiPost<TrueNotFounds[]>('/api/true-not-founds/Add', { int: docId })
      .then(({ data }) => {
        successToaster('Successfully flagged as True Not Found');
        setIsTrueNotFound(data.length !== 0);
      })
      .catch(e => {
        errorToaster(`Error flagging as Not Found. ${e}`);
      });
  }

  function matchSuggestionGuard(
    maybeMatch: DocumentVerificationMatch | undefined,
  ): maybeMatch is DocumentVerificationMatch {
    return !!maybeMatch?.id;
  }

  return (
    <Fragment>
      {currentMode === Mode.default && (
        <div css={{ display: 'flex' }}>
          <Panel styles={panelWrap}>
            <PanelHeader text="Associate document with existing loan" />
            <div>
              <div css={{ margin: '16px 0' }}>
                <div css={boxStyles}>
                  <div css={{ display: 'flex' }}>
                    <div css={{ marginRight: 48 }}>
                      <LoanDropdown
                        autoFocus
                        loan={loan}
                        client={client}
                        onChange={selection => setLoan(selection.value || {})}
                        includeBorrower
                        includeDate
                        includeLoanNumber
                        includeAddress
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {inManualExtractionMode && (
              <Button
                disabled={!isEmpty(loan) || isTrueNotFound}
                onClick={flagTrueNotFound}
                css={{ marginLeft: 32, marginRight: 8 }}
              >
                True Not Found
              </Button>
            )}
            <Button disabled={isEmpty(loan)} onClick={createManualMatch}>
              Next
              <Icon icon={IconType.RightArrow} />
            </Button>
          </Panel>
          <div
            css={{
              marginLeft: 16,
              marginBottom: 24,
            }}
          >
            <DocumentImportInformation docId={document.id} />
          </div>
        </div>
      )}
      {currentMode === Mode.matchVerification && matchSuggestionGuard(match) && (
        <MatchVerification
          client={client}
          match={{
            id: match.id,
            documentId: document.id,
            document,
            loan,
            isMortgage: document.documentType === DocumentType.Mortgage,
            isPolicy: document.documentType === DocumentType.Policy,
            match,
          }}
          onMatchComplete={handleClose}
          handleDismissClick={() => setCurrentMode(Mode.default)}
        />
      )}
    </Fragment>
  );
}

// #region warnings
{
  /* {!isEmpty(loan) &&
                    !loan.investorID && (
                      <h4 css={{ color: colors.red }}>
                        We are still locating the investor for this document
                      </h4>
                    )}
                  {allowMultipleDocs && (
                    <TextInput
                      label={allowMultipleDocs}
                      labelOverrides={{ marginTop: 16 }}
                      placeholder="Enter last 5 digits"
                      maxLength={5}
                      value={identifyingNumber}
                      onChange={e => this.setState({ identifyingNumber: e.target.value })}
                    />
                  )}
                </div>
                {CorrectionDocs(digital, duplicate).includes(docType.value) && (
                  <div css={{ marginTop: 75 }}>
                    <Checkbox
                      checked={isCorrection}
                      onClick={() => this.setState({ isCorrection: !isCorrection })}
                      text="Correction"
                    />
                  </div>
                )}
              </div>
              {digital &&
                loan.investorID &&
                investor.requiresOriginalDoc && (
                  <h4 css={{ color: colors.red }}>
                    Investor {investor.name} requires original document
                  </h4>
                )}
              {duplicate && (
                <h4 css={{ color: colors.red }}>
                  {docType.label} already processed for this loan.
                  {CorrectionDocs(digital, duplicate).includes(docType.value) &&
                    !isCorrection &&
                    ' Please note if this is a correction.'}
                </h4>
              )}
              */
}
// #endregion
