/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useMemo, useState } from 'react';
import { CompartmentData } from './SortClientDocuments';
import { useGetData } from '../../../../Hooks';
import { useClients } from '../../../../Hooks/useClients';
import DataTable from '../../../ui/DataTable/DataTable';
import { roleTypes } from '../../../../constants';
import { AuthContext } from '../../../AuthContext';
import useFileExport from '../../../ui/DataTable/FileExport';
import Modal from '@mui/material/Modal';
import { groupBy } from 'lodash';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from '@mui/material/CircularProgress';
import isAfter from 'date-fns/isAfter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      minHeight: '200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    },
  }),
);

type ClientCompartments = {
  clientId: number;
  weeks: string;
  exceptions: string;
};

const groupClientData = (dashboardData: CompartmentData[]): ClientCompartments[] => {
  const groupedByClient = groupBy(dashboardData, d => d.clientId);

  return Object.entries(groupedByClient).map(([clientId, clientData]) => ({
    clientId: parseInt(clientId),
    weeks: clientData
      .filter(d => d.readyToSort)
      .map(d => d.label)
      .join(', '),
    exceptions: clientData
      .filter(d => d.documentsWithExceptions.length > 0)
      .map(d => d.label)
      .join(', '),
  }));
};

const Dashboard = () => {
  const [selectedClientId, setSelectedClientId] = useState(0);

  const { data: dashboardData, isLoading } = useGetData<CompartmentData[]>(
    '/api/documents/sort-dashboard',
    [],
  );

  const clients = useClients();
  const { roles } = useContext(AuthContext);
  const fileExport = useFileExport();
  const classes = useStyles();

  const clientsMapping = useMemo(
    () =>
      clients.reduce(
        (prev, current) => prev.set(current.id, current.company),
        new Map<number, string>(),
      ),
    [clients],
  );

  const aggregateData = useMemo(() => groupClientData(dashboardData), [dashboardData]);

  const exportDatatable = (columnsParam: any[], dataTableRows: ClientCompartments[]) => {
    const exportData = dataTableRows.map(rowData => {
      const devColumns = roles.includes(roleTypes.Dev) ? { 'Client Id': rowData.clientId } : {};

      const otherColumns = {
        Client: clientsMapping.get(rowData.clientId),
        Shelf: rowData.weeks,
        Exceptions: rowData.exceptions,
      };

      return { ...devColumns, ...otherColumns };
    });

    const fileName = 'Sort Dashboard';

    fileExport(exportData, fileName);
  };

  const exportDrilldownDatatable = (columnsParam: any[], dataTableRows: CompartmentData[]) => {
    const exportData = dataTableRows.map(rowData => {
      const devColumns = roles.includes(roleTypes.Dev)
        ? { 'Compartment Id': rowData.compartmentId }
        : {};

      const weekOrMonthStart = new Date(rowData.weekOrMonthStart);

      const otherColumns = {
        Client: clientsMapping.get(rowData.clientId),
        Shelf: rowData.label,
        'Week Or Month Start': isAfter(weekOrMonthStart, new Date(2000, 1, 1))
          ? weekOrMonthStart.toLocaleDateString()
          : null,
        'Documents On Shelf': `${rowData.documentIdsReadyForSort.length} of ${rowData.documentIds.length} docs ready for sort`,
        'Exceptions On Shelf': `${rowData.documentsWithExceptions.length} of ${rowData.documentIds.length} docs with exceptions`,
      };

      return { ...devColumns, ...otherColumns };
    });

    const fileName = 'Sort Dashboard Drilldown';

    fileExport(exportData, fileName);
  };

  const columns = [
    {
      field: 'clientId',
      title: 'Client Id',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'client',
      title: 'Client',
      render: (rowData: ClientCompartments) => clientsMapping.get(rowData.clientId),
    },
    {
      field: 'weeks',
      title: 'Shelf',
    },
    {
      field: 'exceptions',
      title: 'Exceptions',
    },
  ];

  const modalColumns = [
    {
      field: 'compartmentId',
      title: 'Compartment Id',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'clientId',
      title: 'Client',
      render: () => clientsMapping.get(selectedClientId),
    },
    {
      field: 'label',
      title: 'Shelf',
    },
    {
      field: 'weekOrMonthStart',
      title: 'Week Or Month Start',
      render: ({ weekOrMonthStart }: CompartmentData) => {
        const startDate = new Date(weekOrMonthStart);
        return isAfter(startDate, new Date(2000, 1, 1)) ? startDate.toLocaleDateString() : null;
      },
    },
    {
      field: 'documentIds',
      title: 'Documents On Shelf (ready/total)',
      render: ({ documentIds, documentIdsReadyForSort }: CompartmentData) =>
        `${documentIdsReadyForSort.length}/${documentIds.length}`,
    },
    {
      field: 'exceptions',
      title: 'Exceptions On Shelf (exceptions/total)',
      render: ({ documentIds, documentsWithExceptions }: CompartmentData) =>
        `${documentsWithExceptions.length}/${documentIds.length}`,
    },
  ];

  return (
    <Fragment>
      {!isLoading ? (
        <DataTable<ClientCompartments>
          title="Sort Dashboard"
          columns={columns}
          data={aggregateData}
          onRowClick={(event, rowData) => setSelectedClientId(rowData.clientId)}
          options={{
            exportFileName: 'Sort Dashboard',
            exportCsv: exportDatatable,
          }}
        />
      ) : (
        <div className="center-in-parent">
          <CircularProgress size="45" disableShrink />
        </div>
      )}

      <Modal open={!!selectedClientId} onClose={() => setSelectedClientId(0)}>
        <div className={classes.paper}>
          <DataTable<CompartmentData>
            title="Sort Dashboard Drilldown"
            columns={modalColumns}
            data={dashboardData.filter(d => d.clientId === selectedClientId)}
            options={{
              exportFileName: 'Sort Dashboard Drilldown',
              exportCsv: exportDrilldownDatatable,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Dashboard;
