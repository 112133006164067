/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React, { Fragment } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { LeaveConsolidationModeWarning } from './TableToolbar';
import { useStyles } from './TitleCompanyStyles';
import { useTitleCompanies } from './useTitleCompanies';

// this component handles all scenarios where user tries to leave while in middle of consolidation process
export default function LeavePageWarning() {
  const [{ selectedIds }] = useTitleCompanies();

  onbeforeunload = () => {
    if (selectedIds.size > 0) {
      return 'Are you sure you want to leave this screen?\nYour consolidated list won\'t be saved.';
    }
    return undefined;
  };

  return (
    <Fragment>
      <NavigationPrompt when={selectedIds.size > 0}>
        {({ onConfirm, onCancel }) => (
          <LeavePageWarningModal open onConfirmPageLeave={onConfirm} onCancelPageLeave={onCancel} />
        )}
      </NavigationPrompt>
      <LeaveConsolidationModeWarning />
    </Fragment>
  );
}

type LeavePageWarningModalProps = {
  open: boolean;
  onConfirmPageLeave: () => void;
  onCancelPageLeave: () => void;
};

export function LeavePageWarningModal({
  open,
  onConfirmPageLeave,
  onCancelPageLeave,
}: LeavePageWarningModalProps) {
  const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogContent>
        <DialogContentText css={{ textAlign: 'center' }}>
          Are you sure you want to leave this screen?
          <br />
          Your consolidated list won&apos;t be saved.
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          classes={{ label: classes.buttonLabelGrey }}
          variant="outlined"
          onClick={onConfirmPageLeave}
        >
          Leave
        </Button>
        <Button
          classes={{ label: classes.buttonLabelBold }}
          variant="contained"
          color="primary"
          onClick={onCancelPageLeave}
        >
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  );
}
