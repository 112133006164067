/* eslint-disable react/destructuring-assignment */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { MenuItem, Checkbox, ListItemText } from '@mui/material';
import React, { Fragment } from 'react';
import { AuditPoint, AuditPointOption } from '../../../globalTypes/objects';
import { RowWrap } from './auditStyles';
import { useAuditFailContext } from './useAuditFailContext';

export type AuditPointRowProps = {
  auditPoint: AuditPoint;
};
export function AuditPointRow({ auditPoint }: AuditPointRowProps) {
  const [{ failedReasons }, updateFailedReasons] = useAuditFailContext();
  const selected = failedReasons.find(reason => reason.id === auditPoint.id);
  const onAuditPointClick = () => {
    updateFailedReasons(draft => {
      const index = draft.findIndex(reason => reason.id === auditPoint.id);
      if (index > -1) {
        draft.splice(index, 1);
      } else {
        draft.push({ ...auditPoint, options: [] });
      }
    });
  };

  return (
    <RowWrap
      // @ts-ignore
      open={selected && (auditPoint.options.length > 0 || auditPoint.notesRequired)}
    >
      <div className="df aic jcsb pr2">
        <MenuItem key={auditPoint.id} value={auditPoint.id} onClick={onAuditPointClick}>
          <Checkbox checked={!!selected} />
          <ListItemText primary={auditPoint.description} />
        </MenuItem>
      </div>
      <div className="notes_dropdown">
        {auditPoint.options.length > 0 && !!selected && <OptionsDropdown auditPoint={auditPoint} />}
        {auditPoint.notesRequired && selected && <NotesInput auditPointOrOption={auditPoint} />}
      </div>
    </RowWrap>
  );
}

type OptionsDropdownProps = {
  auditPoint: AuditPoint;
};
function OptionsDropdown({ auditPoint }: OptionsDropdownProps) {
  const [{ failedReasons }, updateFailedReasons] = useAuditFailContext();

  const optionIsSelected = (auditPointOption: AuditPointOption) => {
    return failedReasons
      .find(reason => reason.id === auditPoint.id)
      ?.options.some(option => option.id === auditPointOption.id);
  };

  const onOptionClick = (auditPointOption: AuditPointOption) => {
    updateFailedReasons(draft => {
      const auditPointIndex = draft.findIndex(reason => reason.id === auditPoint.id);
      if (auditPointIndex < 0) return;

      const isSelected = draft[auditPointIndex].options.some(
        option => option.id === auditPointOption.id,
      );
      if (isSelected) {
        const auditPointOptionIndex = draft[auditPointIndex].options.findIndex(
          option => option.id === auditPointOption.id,
        );
        if (auditPointOptionIndex < 0) return;
        draft[auditPointIndex].options.splice(auditPointOptionIndex, 1);
      } else {
        draft[auditPointIndex].options.push(auditPointOption);
      }
    });
  };

  return (
    <Fragment>
      {auditPoint.options.map(option => (
        <div key={option.id}>
          <MenuItem
            value={option.id}
            onClick={() => {
              onOptionClick(option);
            }}
          >
            <Checkbox checked={optionIsSelected(option)} />
            <ListItemText primary={option.optionDescription} />
          </MenuItem>
          {option.notesRequired && optionIsSelected(option) && (
            <div className="notes_dropdown">
              <NotesInput auditPointOrOption={option} />
            </div>
          )}
        </div>
      ))}
    </Fragment>
  );
}

type AuditPointOrOption = AuditPoint | AuditPointOption;
type NotesInputProps = { auditPointOrOption: AuditPointOrOption };
function NotesInput({ auditPointOrOption }: NotesInputProps) {
  const [{ failedReasons }, updateFailedReasons] = useAuditFailContext();
  const isAuditPoint = (input: AuditPointOrOption): input is AuditPoint =>
    input.kind === 'AuditPoint';
  const isAuditPointOption = (input: AuditPointOrOption): input is AuditPointOption =>
    input.kind === 'AuditPointOption';

  const auditPointId = isAuditPoint(auditPointOrOption)
    ? auditPointOrOption.id
    : auditPointOrOption.auditPointId;

  const noteValue = isAuditPoint(auditPointOrOption)
    ? failedReasons.find(reason => reason.id === auditPointId)?.notes
    : failedReasons
        .find(reason => reason.id === auditPointId)
        ?.options.find(option => option.id === auditPointOrOption.id)?.notes;

  const onNoteInput = (noteText: string) => {
    const auditPointIndex = failedReasons.findIndex(reason => reason.id === auditPointId);
    updateFailedReasons(draft => {
      if (isAuditPoint(auditPointOrOption)) {
        draft[auditPointIndex].notes = noteText;
      }
      if (isAuditPointOption(auditPointOrOption)) {
        const optionIndex = draft[auditPointIndex].options.findIndex(
          option => option.id === auditPointOrOption.id,
        );
        draft[auditPointIndex].options[optionIndex].notes = noteText;
      }
    });
  };

  return (
    <div css={{ paddingBottom: 10 }}>
      <input
        autoFocus
        onChange={e => onNoteInput(e.target.value)}
        value={noteValue ?? ''}
        className="note_field"
        type="text"
        placeholder="add note"
      />
    </div>
  );
}
