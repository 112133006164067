/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Button, Card } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import MailIcon from '@mui/icons-material/Mail';
import SpinChildren from '../../ui/SpinChildren';
import {
  EmailStatus,
  ManualFollowUpsProps,
  SendFollowupsMessage,
  useManualFollowups,
} from './useManualFollowups';
import ErrorCard from './ErrorCard';
import useEmailStyles from './ManualFollowupStyles';
import { ConfirmGlobalDialog, errorText } from '../../CallCenter/UpdateContactInfoPanel';
import { apiPost, useFetchWithAuth } from '../../../adalConfig';
import { sentTitlePortalEmails } from './TitlePortalEmailPanel';
import { useToaster } from '../../../Hooks/toasters';

export default function ManualFollowupsPanel({
  loans,
  closeEmails,
  titleCompanyId,
  updateContactInfoData,
  titleCompanyEmail,
  updateNotesForSpecificRows,
  updateTitlePortalContacts,
  children,
}: ManualFollowUpsProps & { children: ReactNode }) {
  const classes = useEmailStyles();
  const [
    {
      isOverride,
      isUpdate,
      updateLocal,
      updateGlobal,
      validatedOverrideEmail,
      sendToInvalidOverride,
      sendToInvalid,
      sendToGlobal,
      emails,
      emailsSent,
      hardInvalidsDownloaded,
      isAdditionalRecipient,
      validatedAdditionalRecipients,
      titlePortalMode,
      selectedTitlePortalEmails,
    },
    setManualFollowups,
  ] = useManualFollowups();

  const [globalUpdateConfirmation, setGlobalUpdateConfirmation] = useState(false);
  const [prepDataError, setPrepDataError] = useState();

  const { errorToaster } = useToaster();

  const getLoanEmailStatus = loanEmail => emails.find(e => e.email === loanEmail)?.status;
  const getEmailToUse = (email: string) => {
    const emailStatus = getLoanEmailStatus(email);
    if (hardInvalidsDownloaded && emailStatus === EmailStatus.HardInvalid) return null;

    if (isOverride) return validatedOverrideEmail;

    if (emailStatus === EmailStatus.SoftInvalid) {
      if (sendToGlobal) return titleCompanyEmail;

      if (sendToInvalid) return email;

      return null;
    }

    if (emailStatus === EmailStatus.HardInvalid) {
      if (sendToGlobal) return titleCompanyEmail;

      return null;
    }

    return email; // emailStatus === Valid
  };

  const loansWithEmails = loans
    .map(l => ({ ...l, emailToUse: getEmailToUse(l.email) }))
    .filter(l => l.emailToUse !== null);

  const emailsToMakeValid = (() => {
    let emailsToValidate = [] as string[];
    if (isAdditionalRecipient) {
      emailsToValidate.push(...validatedAdditionalRecipients);
    }

    if (isOverride && sendToInvalidOverride) {
      emailsToValidate.push(validatedOverrideEmail);
    }

    if (!isOverride && sendToInvalid) {
      emailsToValidate = emailsToValidate.concat(
        emails.filter(e => e.status === EmailStatus.SoftInvalid).map(e => e.email),
      );
    }

    return emailsToValidate;
  })();

  const prepData = async () => {
    try {
      if (isOverride) {
        if (updateLocal) {
          await apiPost('/api/loans/updateLoanContact', {
            loanIds: loansWithEmails.map(l => l.loanId),
            email: validatedOverrideEmail,
            phone: null,
          });
        }

        if (updateGlobal) {
          try {
            await apiPost('/api/titleCompanies/update', {
              id: titleCompanyId,
              email: validatedOverrideEmail,
              phone: null,
              useExistingOutreachSettings: true,
            });
          } catch (e) {
            const errorMessage = errorText(e);
            errorToaster(errorMessage);
          }
        }
      }

      emailsToMakeValid.length > 0 &&
        (await apiPost('/api/emails/keepUsingBouncedEmails', emailsToMakeValid));
    } catch (err) {
      setPrepDataError(err);
      throw new Error(err);
    }
  };

  const getGroupedLoanMessages = () => {
    const messages = loansWithEmails.reduce((messagesList, l) => {
      if (l.emailToUse === null) return messagesList;

      const existingIndex = messagesList.findIndex(
        message => message.email === l.emailToUse && message.clientId === l.clientId,
      );

      if (existingIndex === -1) {
        messagesList.push({
          email: l.emailToUse,
          clientId: l.clientId,
          additionalEmails: validatedAdditionalRecipients,
          loansWithDocs: { [l.loanId]: l.missingDocuments },
        });
      } else {
        const existingMessage = messagesList[existingIndex];
        messagesList[existingIndex] = {
          ...existingMessage,
          loansWithDocs: { ...existingMessage.loansWithDocs, [l.loanId]: l.missingDocuments },
        };
      }

      return messagesList;
    }, [] as SendFollowupsMessage[]);

    return messages;
  };

  const { run: sendEmails, isFulfilled, error, isLoading: loading, setError } = useFetchWithAuth(
    '/api/followups/publishManualFollowupsToQueue',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ followups: getGroupedLoanMessages() }),
    },
    { json: false },
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsSuccess(isFulfilled);
  }, [isFulfilled]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const sendEmailsFunction = async () => {
    if (titlePortalMode) {
      try {
        setIsLoading(true);

        await sentTitlePortalEmails(
          selectedTitlePortalEmails,
          loans,
          updateNotesForSpecificRows,
          updateTitlePortalContacts,
        );

        setIsLoading(false);
        setIsSuccess(true);
      } catch (err) {
        setIsLoading(false);
        setError(err);
      }
    } else {
      await prepData();
      sendEmails();
    }
  };

  useEffect(() => {
    (isSuccess || !!error) && setManualFollowups({ emailsSent: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isSuccess]);

  useEffect(() => {
    isSuccess &&
      apiPost('/api/call-tasks/email-queued', { loanIds: loansWithEmails.map(l => l.loanId) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const updateContactData = () => {
    updateContactInfoData({
      loanIds: updateLocal ? loansWithEmails.map(l => l.loanId) : undefined,
      titleCompanyId: updateGlobal ? titleCompanyId : undefined,
      email: validatedOverrideEmail,
      phone: '',
    });
  };

  return (
    <Fragment>
      <h2>Emails</h2>
      {children}
      <div className="df jcsb mt2">
        {!emailsSent && !prepDataError && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            endIcon={
              isLoading ? (
                <SpinChildren>
                  <LoopIcon />
                </SpinChildren>
              ) : (
                <MailIcon />
              )
            }
            disabled={
              (isOverride && !validatedOverrideEmail) ||
              (isAdditionalRecipient && !validatedAdditionalRecipients.length) ||
              (titlePortalMode && selectedTitlePortalEmails.length === 0)
            }
            onClick={() =>
              updateGlobal ? setGlobalUpdateConfirmation(true) : sendEmailsFunction()
            }
          >
            Send Emails
          </Button>
        )}
        {(error || prepDataError) && <ErrorCard error={(error || prepDataError).toString()} />}
        {isSuccess && (
          <Card raised className={classes.successCard}>
            Emails being sent successfully :)
          </Card>
        )}
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            isUpdate && updateContactData();
            closeEmails();
          }}
        >
          {emailsSent ? 'close' : 'cancel'}
        </Button>
      </div>
      <ConfirmGlobalDialog
        open={globalUpdateConfirmation}
        handleClose={() => setGlobalUpdateConfirmation(false)}
        handleUpdate={sendEmailsFunction}
      />
    </Fragment>
  );
}
