/** @jsx jsx */
import { jsx } from '@emotion/core';
import DataTable from '../../components/ui/DataTable/DataTable';
import { CorrectionsWithPotentialCure as RowData } from './types';
import { Link } from '@mui/material';

type Props = {
  reportData: RowData[];
};

const columns = [
  {
    field: 'client',
    title: 'Lender',
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
  },
  {
    field: 'documentId',
    title: 'Document Id',
    render: ({ documentId }: RowData) => (
      <Link target="_blank" href={`/documents/${documentId}`}>
        {documentId}
      </Link>
    ),
  },
  {
    field: 'potentialCureDocumentId',
    title: 'Potential Cure Document Id',
    render: ({ potentialCureDocumentId }: RowData) => (
      <Link target="_blank" href={`/documents/${potentialCureDocumentId}`}>
        {potentialCureDocumentId}
      </Link>
    ),
  },
  {
    field: 'dateFunded',
    title: 'Date Funded',
    render: ({ dateFunded }: RowData) =>
      dateFunded ? new Date(dateFunded).toLocaleDateString() : '',
  },
  {
    field: 'borrower',
    title: 'Borrower',
  },
  {
    field: 'dateFailed',
    title: 'Date Failed',
    render: ({ dateFailed }: RowData) =>
      dateFailed ? new Date(dateFailed).toLocaleDateString() : '',
  },
];

const PotentialCureDocuments = ({ reportData }: Props) => {
  return (
    <DataTable<RowData>
      title="Potential Cure Documents"
      columns={columns}
      data={reportData}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Potential Cure Documents',
      }}
    />
  );
};

export default PotentialCureDocuments;
