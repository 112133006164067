/** @jsx jsx */
import { jsx, Global, CSSObject } from '@emotion/core';
import React, { useState, useEffect, Fragment, useMemo, Component } from 'react';
import queryString from 'query-string';

import { Link } from 'react-router-dom';
import { useGetData, usePrevious } from '../Hooks';
import { DocIndexProvider, useDocIndex, IndexDoc } from '../components/Documents/Index/useDocIndex';
import RenderDocs, { NoDataType } from '../components/Documents/Index/RenderDocs';
import DocumentHeader from '../components/Documents/Index/DocumentHeader';
import Filters from '../components/Documents/Index/Filters';
import FlaggedDocument from './FlagDocuments/FlaggedDocument';
import ActionsButtons from '../components/Documents/Index/ActionsButtons';
import {
  sidebarWrap,
  listHeader,
  contentWrap,
} from '../components/Documents/Index/clientDocumentIndex';
import colors from '../styles/colors';
import Button from '../components/ui/Button';
import Icon, { IconType } from '../components/ui/Icon';
import { DocumentStatus, Client } from '../globalTypes/objects';
import { DocTileSkeleton } from '../components/Documents/Index/DocumentTile';
import EscapeIcon from '../components/ui/EscapeIcon';

const btn: CSSObject = {
  position: 'fixed',
  right: 32,
  top: 14,
  zIndex: 250,
  backgroundColor: '#2196f3',
  fontWeight: 600,
  borderRadius: '4px',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  color: '#FFF',
  display: 'inline-block',
  padding: '.375rem .75rem',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: 'rgb(17, 82, 147)',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

export default function ClientDocumentIndex({ location: { search, pathname }, history }) {
  const { clientId } = queryString.parse(search);
  return (
    <DocIndexProvider>
      <ClientDocumentIndexFunc clientId={parseInt(clientId as string, 10)} />
    </DocIndexProvider>
  );
}

function ClientDocumentIndexFunc({ clientId }: { clientId: number }) {
  const [docIndexData, setDocIndexData] = useState<IndexDoc[]>([]);
  const [flagMode, setFlagMode] = useState(false);

  const [indexData, setIndexData] = useDocIndex();
  const { data: client } = useGetData<string>(`/api/clients/getClientName?id=${clientId}`, '');
  const {
    data: initialData,
    isLoading: loadingDocIndexData,
    isSuccess: docIndexDataSuccess,
    refetch: refetchDocIndexData,
  } = useGetData<IndexDoc[]>(`/api/documents/getDocTilesData?clientId=${clientId}`, []);

  const prevIsBusy = usePrevious(indexData.isBusy);
  const [initialFiltered, setInitialFiltered] = useState(false);

  const [sortNewest, setSortNewest] = useState(true);

  useEffect(() => {
    ((!indexData.isBusy && prevIsBusy) || (initialData.length === 0 && docIndexDataSuccess)) &&
      !initialFiltered &&
      setInitialFiltered(true);
  }, [docIndexDataSuccess, indexData.isBusy, initialData.length, initialFiltered, prevIsBusy]);

  useEffect(() => {
    setIndexData({ currentClient: { id: clientId, company: client } as Client });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, clientId]);

  useEffect(() => {
    if (docIndexDataSuccess) {
      const data: IndexDoc[] = initialData.map(doc => {
        if (doc.audit && !doc.audit.passed && !doc.audit.resolvedAt)
          return { ...doc, docStatus: DocumentStatus.FailedAudit };
        if (!doc.document.loanId) return { ...doc, docStatus: DocumentStatus.NotFound };
        if (doc.document.isSorted === false && doc.loan!.investorID)
          return { ...doc, docStatus: DocumentStatus.Unsorted };
        return { ...doc, docStatus: DocumentStatus.Found };
      });
      setDocIndexData(data);
    }
  }, [initialData, docIndexDataSuccess]);

  useEffect(() => {
    !loadingDocIndexData && setIndexData({ isBusy: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDocIndexData]);

  const getNoDataType = () => {
    if (docIndexData.length === 0) return NoDataType.NoDocs;
    if (indexData.displayedDocs.length === 0) return NoDataType.NoMatches;
    return undefined;
  };

  const getSortIcon = () => (
    <div
      css={[
        {
          marginLeft: 8,
          transition: 'all 1s ease-in',
          // transform: 'rotate(0deg)',
          cursor: 'pointer',
        },
        !sortNewest && { transform: 'rotate(-180deg)', transition: 'all 1s ease-in' },
      ]}
    >
      <Icon
        icon={IconType.DownArrowIcon}
        stroke={colors.blue}
        onClick={() => setSortNewest(s => !s)}
      />
    </div>
  );

  return (
    <Fragment>
      <Global styles={{ ' body': { overflow: 'hidden' } }} />
      <ActionsButtons refreshData={refetchDocIndexData} />
      <DocumentHeader client={client} />
      <Link to={`/documents?clientId=${clientId}`} target="_blank" css={btn}>
        New Version
      </Link>
      <div css={contentWrap}>
        <div>
          <IndexHeader IconComponent={getSortIcon} />
          {initialFiltered ? (
            <RenderDocs
              noDataType={getNoDataType()}
              sortNewest={sortNewest}
              refreshData={refetchDocIndexData}
            />
          ) : (
            <DocTileSkeleton />
          )}
        </div>
        <Sidebar
          clickFlag={() => setFlagMode(true)}
          docIndexData={docIndexData}
          indexData={indexData}
          initialFiltered={initialFiltered}
          refetchDocIndexData={refetchDocIndexData}
        />
      </div>
      {flagMode && (
        <div css={{ position: 'relative', zIndex: 600000, backgroundColor: '#fff' }}>
          <EscapeIcon onClick={() => setFlagMode(false)} />
          <FlaggedDocument defaultClientId={clientId} />
        </div>
      )}
    </Fragment>
  );
}

function IndexHeader({ IconComponent }: { IconComponent: () => JSX.Element }) {
  return (
    <div css={listHeader}>
      <div />
      <div />
      <div>Type</div>
      <div>Barcode</div>
      <div>Loan Number</div>
      <div>Format</div>
      <div className="df aic">
        Date Uploaded
        <IconComponent />
      </div>
    </div>
  );
}

function Sidebar({ initialFiltered, docIndexData, refetchDocIndexData, indexData, clickFlag }) {
  return (
    <div
      css={[
        sidebarWrap,
        !initialFiltered && {
          '&:after': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            bottom: 0,
            content: '""',
            height: '100%',
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            width: '100%',
            zIndex: 80,
          },
        },
      ]}
    >
      <Filters docs={docIndexData} />
      {/* <div
        css={{
          borderTop: `1px solid ${colors.grayLight}`,
          width: '100%',
          padding: '20px 0',
          textAlign: 'center',
        }}
      >
        <Button
          disabled={indexData.isBusy}
          secondary
          onClick={clickFlag}
          styleOverrides={{
            display: 'flex',
            alignItems: 'center',
            margin: 'auto',
            svg: { marginRight: 8 },
          }}
        >
          <Icon icon={IconType.Flag} />
          Flag a document
        </Button>
      </div> */}
    </div>
  );
}
