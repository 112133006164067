/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { mapValues } from 'lodash';

import { Loan } from '../../globalTypes/objects';
import VerifyItemError, { EditProps } from './VerifyItemError';
import { MatchData } from './MatchVerification';
import { ExtractedDocumentData } from '../../pages/DocumentCatchAll/types/document';

const headerStyle = {
  fontWeight: 800,
  textAlign: 'center',
};

const dataStyle = {
  marginBottom: 4,
  paddingLeft: 5,
  fontSize: 14,
  position: 'relative',
};

const extractedDataStyle = {
  ...dataStyle,
  backgroundColor: '#e5e5ea',
};

const loanDataStyle = {
  paddingLeft: 5,
  position: 'relative',
  borderBottom: '4px solid #e5e5ea',
};

const listItemsWrap = {
  overflowY: 'auto',
  width: '47%',
  display: 'grid',
  gridTemplateRows: '19% 22% 22% 23%',
};

type VerificationProps = EditProps & {
  loan: Loan;
  isMortgage: boolean;
  isPolicy: boolean;
  setVerificationFailures: (verificationFailures: number[]) => void;
  matchData: MatchData | undefined;
  importingDoc: boolean;
  alreadyFailed?: number[];
  extractedDocumentData: ExtractedDocumentData | undefined;
};

export type VerificationStatus = 'UNKNOWN' | boolean;

type VerificationList = {
  [key: string]: VerificationStatus;
};

const initialVerificationList = (
  matchData: MatchData | undefined,
  isMortgage: boolean,
  isPolicy: boolean,
): VerificationList => ({
    1: true,
    2: !(matchData && Math.min(...matchData.dateDaysFromDateFunded) > 7),
    3: !(matchData && matchData.fuzzyBorrower < 90),
    ...(!isMortgage
        ? {
            4: !(matchData && matchData.fuzzyPropertyAddress < 90),
            5: true,
        }
        : {}),
     6: true,
     7: true,
});

export default function VerificationPanel({
  loan,
  isMortgage,
  isPolicy,
  setVerificationFailures,
  editable,
  setValue,
  matchData,
  importingDoc = false,
  alreadyFailed,
  extractedDocumentData,
}: VerificationProps) {
  const [verificationList, setVerificationList] = useState<VerificationList>(
    mapValues(initialVerificationList(matchData, isMortgage, isPolicy), (val, key) => {
      if (alreadyFailed?.includes(parseInt(key))) return false;
      return importingDoc ? true : val;
    }),
  );

  useEffect(() => {
    const verificationFailures = Object.keys(verificationList)
      .filter(k => !verificationList[k])
      .map(Number);
    setVerificationFailures(verificationFailures);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationList]);

  const dataToValidate = [
    {
      name: 'Date funded',
      data: format(new Date(Date.parse(loan.dateDocumentsDrawn)), 'MM/dd/yyyy'),
        id: 2
    },
    { name: 'Borrower name', data: loan.borrower, id: 3},
    {
      name: 'Property address',
      data: loan.propertyAddress,
        id: 4
    },
  ];

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        css={{
          height: '568',
          width: '47%',
          display: 'grid',
          gridTemplateRows: '19% 22% 22% 23%',
        }}
      >
        <div
          css={{
            backgroundColor: '#e5e5ea',
            marginBottom: 4,
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div css={headerStyle}>Extracted Information</div>
        </div>
        <div css={extractedDataStyle}>
          <div>Date Funded</div>{' '}
          {extractedDocumentData?.dateFunded != null
            ? format(new Date(Date.parse(extractedDocumentData?.dateFunded)), 'MM/dd/yyyy')
            : ''}
        </div>
        <div css={extractedDataStyle}>
          <div>Borrower</div> {extractedDocumentData?.borrower}
        </div>
        <div css={extractedDataStyle}>
          <div>Property</div> {extractedDocumentData?.propertyAddress}
        </div>
      </div>
      <div css={listItemsWrap}>
        <div
          css={{
            borderBottom: '4px solid #e5e5e5',
            // marginBottom: 4,
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div css={headerStyle}>Loan Information</div>
        </div>
        {dataToValidate.map(
          (info) =>
            info.name && (
              <VerifyItemError
                key={info.name}
                info={info}
                onClick={value => setVerificationList({ ...verificationList, [info.id]: value })}
                isError={
                  typeof verificationList[info.id] === 'boolean'
                    ? !verificationList[info.id]
                    : verificationList[info.id]
                }
                editable={editable}
                setValue={setValue}
              />
            ),
        )}
      </div>
    </div>);
}
