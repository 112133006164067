/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import useDataTableFilters from '../../components/ui/DataTable/UseDataTableFilters';
import { Chip } from '@mui/material';
import ClientSearch from '../../components/Utilities/ClientSearch';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CreateIcon from '@mui/icons-material/Create';
import { hasAccessToRoute } from '../../Utils';
import Modal from '@mui/material/Modal';
import AlertDialogSlide from '../../components/Documents/DocumentDeleteAlert';
import AddClosingPackage from './AddClosingPackage';
import EditClosingPackage from './EditClosingPackage';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 750,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export type RowData = {
  id: number;
  client: string;
  loanNumber: string;
  propertyAddress: string;
  borrowerName: string;
  titleCompanyName: string;
  inboundTrackingNumber: string;
  outboundTrackingNumber: string;
  shippingCost: number;
  createdAt: string;
  createdBy: string;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

type Props = {
  setClosingPackage: Dispatch<SetStateAction<RowData>>;
  setRefreshDatatable: Dispatch<SetStateAction<() => void>>;
};

export const ClosingPackagesList = ({ setClosingPackage, setRefreshDatatable }: Props) => {
  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    clearCustomFilter,
  } = useDataTableFilters('/api/closingpackages/filters');

  const clientFilter = customFilters.find(filter => filter.label === 'Clients');
  const datePickerFilter = customFilters.find(filter => filter.label === 'DateAdded');

  const activeClientFilterOption = clientFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const activeDatePickerFilterOption = datePickerFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const selectedClient: { name: string; id: number | string } | null = activeClientFilterOption
    ? { id: activeClientFilterOption.id, name: activeClientFilterOption.label }
    : null;

  const columns = [
    {
      field: 'client',
      title: 'Client',
    },
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'propertyAddress',
      title: 'Address',
    },
    {
      field: 'borrowerName',
      title: 'Borrower',
    },
    {
      field: 'titleCompanyName',
      title: 'Title Company',
    },
    {
      field: 'inboundTrackingNumber',
      title: 'Inbound Tracking',
    },
    {
      field: 'outboundTrackingNumber',
      title: 'Outbound Tracking',
    },
    {
      field: 'shippingCost',
      title: 'Shipping Cost',
      render: ({ shippingCost }: RowData) => currencyFormat.format(shippingCost),
    },
    {
      field: 'createdAt',
      title: 'Date Added',
      render: ({ createdAt }: RowData) => new Date(createdAt).toLocaleDateString(),
    },
    {
      field: 'createdBy',
      title: 'User',
    },
  ];

  function selectClient() {
    return selection => {
      clientFilter &&
        onCustomOptionSelected(clientFilter, {
          id: selection.id,
          label: selection.company,
          active: true,
        });
    };
  }

  return (
    <Fragment>
      <DataTable<RowData>
        title="Closing Packages"
        columns={columns}
        url="/api/closingPackages/getClosingPackages"
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        renderCustomFilters={
          <div>
            <div className="m2">
              Client
              {clientFilter && (
                <ClientSearch
                  selectedClient={selectedClient?.id as number}
                  onChange={selectClient()}
                  styleOverrides={{ marginTop: 12 }}
                />
              )}
            </div>

            {datePickerFilter && (
              <div className="m2">
                <div>Date Added</div>
                <div style={{ marginTop: 12 }}>
                  <DatePicker
                    minDate={new Date(2000, 0, 1)}
                    maxDate={new Date()}
                    inline
                    selected={
                      activeDatePickerFilterOption?.id
                        ? new Date(activeDatePickerFilterOption.id)
                        : null
                    }
                    onChange={dateTime =>
                      dateTime &&
                      onCustomOptionSelected(datePickerFilter, {
                        id: dateTime.toISOString(),
                        label: format(dateTime, 'MM/yy'),
                        active: true,
                      })
                    }
                    placeholderText="Click to select"
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                  />
                </div>
              </div>
            )}
          </div>
        }
        actions={() => [
          {
            icon: () => <CreateIcon color="primary" />,
            tooltip: 'Edit Closing Package',
            onClick: (event, rowData) => setClosingPackage(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </Fragment>
  );
};

export default ClosingPackagesList;
