/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { format } from 'date-fns';
import DataTable from '../../components/ui/DataTable/DataTable';
import { MonthlyPayments as RowData } from './types';
import useFileExportToExcel, {
  addImageToExcel,
} from '../../components/ui/DataTable/FileExportToExcel';

type Props = {
  monthlyPayments: RowData[];
  fromDate: Date;
};

type ExportData = {
  Client: string;
  'Payment Amount': string;
  'Date Paid': string;
  'Check Number': string;
};

const MonthlyPayments = ({ monthlyPayments, fromDate }: Props) => {
  const columns = [
    {
      field: 'company',
      title: 'Client',
    },
    {
      field: 'paymentAmt',
      title: 'Payment Amount',
      render: (rowData: RowData) =>
        rowData.paymentAmt.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    },
    {
      field: 'datePaid',
      title: `Date Paid`,
      render: (rowData: RowData) => new Date(rowData.datePaid).toLocaleDateString(),
    },
    {
      field: 'checkNo',
      title: `Check Number`,
    },
  ];
  const fileExport = useFileExportToExcel<ExportData>();
  const exportDatatable = (columns: any[], dataTableRows: RowData[]) => {
    let firstRow = {} as RowData;

    const exportData = dataTableRows.map(row => {
      if (!firstRow.company) {
        firstRow = row;
      }
      return {
        Client: row.company,
        'Payment Amount': `${row.paymentAmt.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}`,
        'Date Paid': row.datePaid && new Date(row.datePaid).toLocaleDateString(),
        'Check Number': row.checkNo,
      };
    });
    const fileName = `Monthly_Payments_${format(new Date(fromDate), 'MMMM')}_${format(
      new Date(fromDate),
      'yyyy',
    )}`;

    const rowHeader = ['Client', 'Payment Amount', 'Date Paid', 'Check Number'];
    fileExport(rowHeader, exportData, fileName, addImageToExcel);
  };

  return (
    <Fragment>
      {monthlyPayments && (
        <DataTable<RowData>
          title={`Monthly Payments - For - ${format(new Date(fromDate), 'MMMM')}   ${format(
            new Date(fromDate),
            'yyyy',
          )}`}
          columns={columns}
          data={monthlyPayments}
          options={{
            selection: false,
            search: false,
            exportCsv: exportDatatable,
          }}
        />
      )}
    </Fragment>
  );
};

export default MonthlyPayments;
