/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactsIcon from '@mui/icons-material/Contacts';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InputMask from 'react-input-mask';
import { StripPhone, ValidateEmail } from '../../Utils';
import { beforeMaskedValueChange, phoneMask } from '../ui/Inputs/PhoneInput';
import SpinChildren from '../ui/SpinChildren';
import { useFetchWithAuth } from '../../adalConfig';
import { EmailStatus } from '../FollowUps/ManualFollowups/useManualFollowups';
import { useDebounce } from '../../Hooks';
import { DebounceRate } from '../Imports/ImportErrors/ImportErrorsUtils';
import colors from '../../styles/colors';
import { useToaster } from '../../Hooks/toasters';
import { Note, RowData } from './types';

type UpdateContactInfoProps = {
  closePanel: () => void;
  titleCompanyId: number;
  loanIds: number[];
  UpdateContactInfoData: (updatedInfo: UpdateContactInfoData) => void;
  updateDatatable: (callback: (datatableRow: RowData) => void) => void;
};

export type UpdateContactInfoData = {
  loanIds?: number[];
  email: string;
  deleteEmail?: boolean;
  phone: string;
  titleCompanyId?: number;
};

const useClasses = makeStyles(() =>
  createStyles({
    checkboxLabel: {
      '& > *:last-of-type': {
        width: '100%',
      },
    },
  }),
);

export const errorText = e => {
  if (!e.response) {
    return e.message;
  }

  if (e.response.data?.errors) {
    return e.response.data?.errors
      .map((error: { fieldName: string; message: string }) => error.message)
      .join('\n');
  }

  return e.response.data.split('\n')[0] || e.message;
};

export default function UpdateContactInfoPanel({
  titleCompanyId,
  loanIds,
  UpdateContactInfoData,
  updateDatatable,
  closePanel,
}: UpdateContactInfoProps) {
  const classes = useClasses();

  const [deleteEmail, setDeleteEmail] = useState(false);
  const [email, setUpdatedEmail] = useState('');
  const debouncedEmail = useDebounce(email, DebounceRate);
  const emailStatusState = useFetchWithAuth<EmailStatus>(
    `/api/emails/getEmailStatus?email=${debouncedEmail}`,
    {},
    { defer: !ValidateEmail(debouncedEmail) },
  );
  const isValidatedEmail = ValidateEmail(debouncedEmail) && emailStatusState.isSettled;
  const getEmailStatusLabel = () => {
    if (!ValidateEmail(debouncedEmail)) return '';
    if (emailStatusState.isPending) return 'Validating email address...';
    if (emailStatusState.isRejected) return 'Something went wrong validating the email address';
    if (emailStatusState.data === EmailStatus.HardInvalid)
      return 'Email address seems not to be working at all. If you choose to continue with this email address, future emails to this address will have to be downloaded and sent manually.';
    if (emailStatusState.data === EmailStatus.SoftInvalid)
      return 'Email address is invalid. You can choose to continue with this email address anyway.';
    if (emailStatusState.data === EmailStatus.Valid) return 'Email address is valid';
    return '';
  };

  const [phone, setUpdatedPhone] = useState('');
  const isValidPhone = phone.length === 10;

  const [updateGlobal, setUpdateGlobal] = useState(false);
  const [updateLoanLevel, setUpdateLoanLevel] = useState(false);

  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false);

  const { errorToaster } = useToaster();

  const loanUpdateState = useFetchWithAuth<string>(
    '/api/loans/updateLoanContact',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        loanIds,
        email: deleteEmail ? '' : isValidatedEmail ? email : null,
        phone: isValidPhone ? phone : null,
      }),
    },
    {
      onResolve: d => {
        UpdateContactInfoData({ loanIds, email, phone, deleteEmail });

        const callback = (row: RowData) => {
          row.pastNotes.unshift({
            dateEntered: new Date(),
            text: d,
          } as Note);
        };

        updateDatatable(callback);
      },
    },
  );
  const globalUpdateState = useFetchWithAuth(
    '/api/titleCompanies/update',
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: titleCompanyId,
        email: deleteEmail ? '' : isValidatedEmail ? email : null,
        phone: isValidPhone ? phone : null,
        useExistingOutreachSettings: true,
      }),
    },
    {
      json: false,
      onResolve: () => UpdateContactInfoData({ email, phone, titleCompanyId }),
    },
  );
  const handleUpdate = async () => {
    // open modal - only call when they confirm
    updateLoanLevel && (await loanUpdateState.run());
    try {
      updateGlobal && (await globalUpdateState.run());
    } catch (e) {
      const errorMessage = errorText(e);
      errorToaster(errorMessage);
    }
  };

  return (
    <Fragment>
      <h2>Update Contact Information</h2>
      <Grid container>
        <Grid item xs={10}>
          <TextField
            disabled={deleteEmail}
            margin="normal"
            size="medium"
            fullWidth
            variant="outlined"
            label="Updated Email"
            value={email}
            onChange={e => setUpdatedEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactMailIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <span css={{ fontSize: '1rem', lineHeight: '1.2em', color: colors.blue }}>
                {getEmailStatusLabel()}
              </span>
            }
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                checked={deleteEmail}
                onChange={() => {
                  setUpdatedEmail('');
                  setDeleteEmail(!deleteEmail);
                  setUpdateGlobal(false);
                }}
                color="error"
              />
            }
            label="Delete Email"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={10}>
          <InputMask
            // @ts-ignore
            beforeMaskedValueChange={(...args) => beforeMaskedValueChange(...args, phone)}
            mask={phoneMask}
            maskChar=""
            value={phone}
            onChange={e => setUpdatedPhone(StripPhone(e.target.value))}
          >
            {inputProps => (
              <TextField
                {...inputProps}
                margin="normal"
                size="medium"
                fullWidth
                variant="outlined"
                label="Updated Phone"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </InputMask>
        </Grid>
      </Grid>

      <FormControl disabled={!globalUpdateState.isInitial || !loanUpdateState.isInitial}>
        <FormGroup>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                color="primary"
                checked={updateLoanLevel}
                onClick={() => setUpdateLoanLevel(!updateLoanLevel)}
              />
            }
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span>
                  Update{' '}
                  <Typography variant="inherit" color="primary">
                    <b>loan level</b>
                  </Typography>{' '}
                  contact information
                </span>
                {loanUpdateState.isPending && (
                  <SpinChildren>
                    <RotateRightIcon color="primary" />
                  </SpinChildren>
                )}
                {loanUpdateState.isFulfilled && <CheckCircleIcon style={{ color: colors.green }} />}
                {loanUpdateState.isRejected && <ErrorIcon color="error" />}
              </div>
            }
          />
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Checkbox
                color="primary"
                checked={updateGlobal}
                disabled={deleteEmail}
                onClick={() => setUpdateGlobal(!updateGlobal)}
              />
            }
            label={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <span>
                  Update{' '}
                  <Typography variant="inherit" color="primary">
                    <b>global title company</b>
                  </Typography>{' '}
                  contact information
                </span>
                {globalUpdateState.isPending && (
                  <SpinChildren>
                    <RotateRightIcon color="primary" />
                  </SpinChildren>
                )}
                {globalUpdateState.isFulfilled && (
                  <CheckCircleIcon style={{ color: colors.green }} />
                )}
                {globalUpdateState.isRejected && <ErrorIcon color="error" />}
              </div>
            }
          />
        </FormGroup>
      </FormControl>

      <div className="df jcsb mt2">
        <Button
          variant="contained"
          color="primary"
          disabled={
            (phone === '' && email === '' && !deleteEmail) ||
            (email !== '' && !isValidatedEmail) ||
            (phone !== '' && !isValidPhone) ||
            (!updateLoanLevel && !updateGlobal) ||
            !globalUpdateState.isInitial ||
            !loanUpdateState.isInitial
          }
          endIcon={<ContactsIcon />}
          onClick={() => (updateGlobal ? setConfirmUpdateModal(true) : handleUpdate())}
        >
          Update
        </Button>
        <Button
          variant="contained"
          disabled={globalUpdateState.isPending || loanUpdateState.isPending}
          onClick={closePanel}
        >
          {globalUpdateState.isSettled || loanUpdateState.isSettled ? 'Close' : 'Cancel'}
        </Button>
      </div>
      <ConfirmGlobalDialog
        open={confirmUpdateModal}
        handleClose={() => setConfirmUpdateModal(false)}
        handleUpdate={handleUpdate}
      />
    </Fragment>
  );
}

type ConfirmGlobalDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleUpdate: () => void;
};

export function ConfirmGlobalDialog({ open, handleClose, handleUpdate }: ConfirmGlobalDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Are you sure you want to update the global title company?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Updating the global title company contact information affects all loans that belong to
          this title company regardless of client.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            handleUpdate();
          }}
          color="primary"
        >
          Update
        </Button>
        <Button variant="contained" onClick={handleClose} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
