/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Column } from 'material-table';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  id: number;
  client: string;
  email: string;
  permission: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: string;
};

const columns: Column<object>[] = [
  {
    field: 'email',
    title: 'Email',
  },
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'permission',
    title: 'Permission',
  },
  {
    field: 'firstName',
    title: 'First Name',
  },
  {
    field: 'lastName',
    title: 'Last Name',
  },
  {
    field: 'role',
    title: 'Role',
  },
  {
    field: 'createdAt',
    title: 'Date Created',
    render: ({ createdAt }: RowData) => new Date(createdAt).toLocaleDateString(),
  },
];

function ViewUsers() {
  return (
    <div style={{ marginTop: 16, marginLeft: '-12px' }}>
      <DataTable<RowData>
        title="Users"
        columns={columns}
        url="/api/client-portal-user-management/get-users"
        orderBy={{ field: 'createdAt' }}
        orderDirection="desc"
      />
    </div>
  );
}

export default ViewUsers;
