/** @jsx jsx */
import { jsx, CSSObject, ClassNames } from '@emotion/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

import colors from '../../styles/colors';
import { SidebarConsumer } from '../Layout/Sidebar/SidebarContext';

const wrapperStyles: CSSObject = {
  fontSize: 12,
  fontWeight: 600,
  left: 238,
  letterSpacing: '1px',
  position: 'absolute',
  textTransform: 'uppercase',
  top: 25,
  '&:before': {
    borderLeft: `1px solid ${colors.gray}`,
    content: '""',
    height: 32,
    left: -32,
    position: 'absolute',
    top: -8,
  },
};

const linkStyles: CSSObject = {
  color: colors.grayDark,
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 1,
  textTransform: 'uppercase',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all .2s',
  paddingBottom: 19,
  ':hover': {
    borderBottom: `2px solid ${colors.blueLight}`,
    color: colors.black,
  },
};

const activeStyle: CSSObject = {
  '&&': {
    transition: 'all .2s',
    borderBottom: `2px solid ${colors.blueLight}`,
    color: colors.black,
    ':hover': { borderBottom: `2px solid ${colors.blueLight}` },
  },
};

const secondLink: CSSObject = {
  marginLeft: 24,
};

export default function ImportDocsHeader() {
  return (
    <ClassNames>
      {({ css }) => (
        <div css={wrapperStyles}>
          <NavLink
            exact
            to="/documents/import/physical"
            css={linkStyles}
            activeClassName={css`
              ${activeStyle}
            `}
          >
            Physical
          </NavLink>
          <NavLink
            exact
            to="/documents/import/digital"
            css={[linkStyles, secondLink]}
            activeClassName={css`
              ${activeStyle}
            `}
          >
            Digital
          </NavLink>
        </div>
      )}
    </ClassNames>
  );
}
