/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { RowData as BasicRowData } from './types';
import Checkbox from '@mui/material/Checkbox';

type Props = {
  documentsShippedByUser: RowData[];
};

export type RowData = BasicRowData & { hardCopy: boolean };

const columns = [
  {
    field: 'count',
    title: 'Count',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'hardCopy',
    title: 'Hard Copy',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => <Checkbox color="primary" checked={rowData.hardCopy} />,
  },
  {
    field: 'user',
    title: 'User',
    options: {
      filter: false,
    },
    render: (rowData: RowData) => rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'date',
    title: 'Date',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => new Date(rowData.date).toLocaleDateString(),
  },
];

export const ShippingByUser = ({ documentsShippedByUser }: Props) => (
  <DataTable<RowData>
    title="Documents Shipped - By User"
    columns={columns}
    data={documentsShippedByUser}
    options={{
      maxBodyHeight: '500px',
      search: false,
    }}
  />
);
