/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import colors from '../../../../styles/colors';
import LoanErrorNoteInnerModal from './LoanErrorNoteInnerModal';

// #region css
const screen: CSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalWrap: CSSObject = {
  backgroundColor: colors.white,
  border: '1px solid #c7c7cc',
  borderRadius: 4,
  height: 416,
  padding: 32,
  width: 540,
};

const addNoteWrap: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  borderRadius: 4,
  display: 'flex',
  fontSize: 20,
  fontWeight: 'bold',
  height: 80,
  paddingLeft: 32,
  width: '100%',
};
// #endregion

type LoanErrorNoteModalProps = {
  handleClose: (noteIds: number[]) => any;
  selected: number[];
};
export default function LoanErrorNoteModal({ handleClose, selected }: LoanErrorNoteModalProps) {
  return (
    <div css={screen}>
      <div css={modalWrap}>
        <div css={addNoteWrap}>Add note</div>
        <LoanErrorNoteInnerModal handleClose={handleClose} ids={selected} />
      </div>
    </div>
  );
}
