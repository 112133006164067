/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';

import { LeftArrowIcon, RightArrowIcon } from '../../ui/icons';
import colors from '../../../styles/colors';

const styles: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.white,
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  boxSizing: 'border-box',
  display: 'flex',
  height: 80,
  justifyContent: 'center',
  marginBottom: 24,
  marginTop: 8,
  maxWidth: 1280,
};

const pageNum: CSSObject = {
  fontSize: 16,
  margin: 15,
  minWidth: 62,
  textAlign: 'center',
};

type PaginatorProps = {
  totalNum: number;
  currentNum: number;
  onPreviousClick: (e) => any;
  onNextClick: (e) => any;
  isDisabled?: boolean;
  isDisabledForPrevious?: boolean;
  isDisabledForNext?: boolean;
  loadingSkeleton?: boolean;
};

export default function Paginator({
  totalNum,
  currentNum,
  onPreviousClick,
  onNextClick,
  isDisabled,
  isDisabledForPrevious,
  isDisabledForNext,
  loadingSkeleton,
}: PaginatorProps) {
  const circleStyles: CSSObject = {
    alignItems: 'center',
    backgroundColor: colors.blue,
    borderRadius: '50%',
    color: colors.white,
    cursor: 'pointer',
    display: 'flex',
    height: 48,
    justifyContent: 'center',
    transition: 'background-color .2s ease',
    width: 48,
    ':hover': {
      backgroundColor: colors.blueDark,
    },
    ':disabled': {
      backgroundColor: colors.grayLight,
    },
    ':active': {
      boxShadow: `0 0 0 1.4px ${colors.blueLight}`,
    },
  };

  return (
    <div css={styles}>
      {!loadingSkeleton ? (
        <React.Fragment>
          <button
            data-test="Paginate Left"
            onClick={onPreviousClick}
            disabled={totalNum <= 1 || isDisabled || isDisabledForPrevious}
            css={circleStyles}
          >
            <LeftArrowIcon />
          </button>
          <div css={pageNum}>
            {currentNum}/{totalNum}
          </div>
          <button
            data-test="Paginate Right"
            onClick={onNextClick}
            disabled={totalNum <= 1 || isDisabled || isDisabledForNext}
            css={circleStyles}
          >
            <RightArrowIcon />
          </button>
        </React.Fragment>
      ) : null}
    </div>
  );
}
