import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { BarcodeIcon } from '../ui/icons';
import Button from '../ui/Button';
import colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  barcodeIcon: {
    width: '100%',
  },
  barcodeText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  barcodeContainer: {
    position: 'relative',
    textAlign: 'center',
    border: `1px solid ${colors.grayDark}`,
    borderRadius: '4px',
    padding: '23px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '32px',
    justifyContent: 'center',
  },
  buttonPadding: {
    margin: '3px',
  },
}));
export default function BatchScanning({ ...props }) {
  const classes = useStyles({});
  const { cancelScan, finishScan, showScanning } = props;
  return (
    <Fragment>
      <div>
        {showScanning ? (
          <div className={classes.barcodeContainer}>
            <BarcodeIcon className={classes.barcodeIcon} />
          </div>
        ) : (
          <div className={classes.barcodeContainer}>
            <BarcodeIcon style={{ opacity: '0.04' }} className={classes.barcodeIcon} />
            <div className={classes.barcodeText}>Scan each document</div>
          </div>
        )}
        <div className={classes.buttonContainer}>
          <Button className={classes.buttonPadding} onClick={cancelScan}>
            CANCEL
          </Button>
          <Button className={classes.buttonPadding} secondary onClick={finishScan}>
            FINISH
          </Button>
        </div>
      </div>
    </Fragment>
  );
}
