// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* I can't figure out why we're getting a "Uncaught runtime error -CanceledError: canceled". I suspect it has something to do with material table and how they render. I'm disabling by hiding the overlay */
iframe#webpack-dev-server-client-overlay {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./ClientApp/components/ui/DataTable/styles.css"],"names":[],"mappings":"AAAA,4MAA4M;AAC5M;IACI,aAAa;AACjB","sourcesContent":["/* I can't figure out why we're getting a \"Uncaught runtime error -CanceledError: canceled\". I suspect it has something to do with material table and how they render. I'm disabling by hiding the overlay */\niframe#webpack-dev-server-client-overlay {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
