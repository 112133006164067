import colors from '../../../styles/colors';

export const gridDimensions = {
  gridTemplateColumns: '46px 88px 261px 1fr 1fr 120px 200px',
  display: 'grid',
  gridColumnGap: 8,
  alignItems: 'center',
};

export const cardWrap = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  height: 80,
  marginBottom: 8,
  padding: '0 16px',
  '&:hover': { border: `1px solid ${colors.blue}` },
};

export const listViewMode = {
  border: '1px solid red',
};

export const statusDot = {
  borderRadius: '50%',
  display: 'inline-block',
  height: '12px',
  // marginRight: 16,
  placeSelf: 'center',
  width: '12px',
};

export const docTypeStyle = {
  fontSize: 20,
  fontWeight: 700,
  display: 'inline-block',
  paddingLeft: 24,
};

export const trashWrap = {
  alignItems: 'center',
  backgroundColor: colors.blue,
  borderRadius: '50%',
  color: colors.white,
  cursor: 'pointer',
  display: 'flex',
  height: 41,
  justifyContent: 'center',
  margin: 'auto',
  position: 'relative',
  width: 41,
  '&:hover': { backgroundColor: colors.blueDark },
};

export const deleteConfirmation = {
  alignItems: 'center',
  backgroundColor: '#333',
  borderRadius: 4,
  bottom: -66,
  display: 'flex',
  height: 56,
  justifyContent: 'space-between',
  padding: '8px 16px',
  position: 'absolute',
  width: 230,
  zIndex: 50,
  fontWeight: 600,
  cursor: 'auto',
  '&:before': {
    borderColor: 'transparent transparent #333 transparent',
    borderStyle: 'solid',
    borderWidth: '0 6px 6px 6px',
    content: "''",
    height: 0,
    left: 111,
    position: 'absolute',
    top: -6,
    width: 0,
  },
};

export const iconsWrap = {
  display: 'inline-flex',
  alignItems: 'center',
  color: colors.grayLight,
  svg: {
    cursor: 'pointer',
    ':last-child': { transform: 'scale(1.4)', marginLeft: 16 },
    '&:hover': { color: colors.white },
  },
};

export const moreInfoS = {
  height: 0,
  transition: 'all .2s',
  overflow: 'hidden',
  span: { fontWeight: 600, display: 'block' },
  padding: '0 8px',
  '> div': {
    border: `1px solid ${colors.gray}`,
    display: 'grid',
    gridColumnGap: 8,
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    marginTop: 16,
    padding: 8,
    borderRadius: 4,
  },
};
