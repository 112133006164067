enum State {
  NotAsked = 'NOT_ASKED',
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
}

interface NotAsked {
  state: State.NotAsked;
}

interface Loading {
  state: State.Loading;
}

export interface Success<T> {
  state: State.Success;
  data: T;
}

interface Failure<E> {
  state: State.Failure;
  error: E;
}

export type RemoteData<T, E> = NotAsked | Loading | Success<T> | Failure<E>;

export const NotAsked: NotAsked = { state: State.NotAsked };

export const Loading: Loading = { state: State.Loading };

export const Success = <T>(data: T): Success<T> => ({
  state: State.Success,
  data,
});

export const Failure = <E>(error: E): Failure<E> => ({
  state: State.Failure,
  error,
});

export function isNotAsked<T, E>(remoteData: RemoteData<T, E>): remoteData is NotAsked {
  return remoteData.state === State.NotAsked;
}

export function isLoading<T, E>(remoteData: RemoteData<T, E>): remoteData is Loading {
  return remoteData.state === State.Loading;
}

export function isInitial<T, E>(remoteData: RemoteData<T, E>): remoteData is Loading {
  return remoteData.state === State.Loading || remoteData.state === State.NotAsked;
}

export function isSuccess<T, E>(remoteData: RemoteData<T, E>): remoteData is Success<T> {
  return remoteData.state === State.Success;
}

export function isFailure<T, E>(remoteData: RemoteData<T, E>): remoteData is Failure<E> {
  return remoteData.state === State.Failure;
}

export function mapSuccess<T, U, E>(
  fn: (data: T) => U,
  remoteData: RemoteData<T, E>,
): RemoteData<U, E> {
  return !isSuccess(remoteData) ? remoteData : Success(fn(remoteData.data));
}

export function mapSuccess2<T1, T2, U, E>(
  fn: (data1: T1, data2: T2) => U,
  rd1: RemoteData<T1, E>,
  rd2: RemoteData<T2, E>,
): RemoteData<U, E> {
  if (!isSuccess(rd1)) return rd1;
  if (!isSuccess(rd2)) return rd2;
  return Success(fn(rd1.data, rd2.data));
}

export function filterSuccess<T, E>(
  fn: (data: T) => boolean,
  remoteData: RemoteData<T[], E>,
): RemoteData<T[], E> {
  if (!isSuccess(remoteData)) return remoteData;
  return Success(remoteData.data.filter(fn));
}

export function mapFailure<T, E, U>(
  fn: (error: E) => U,
  remoteData: RemoteData<T, E>,
): RemoteData<T, U> {
  if (!isFailure(remoteData)) return remoteData;
  return Failure(fn(remoteData.error));
}
