/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetData } from '../../../Hooks';
import { DocumentIntake as DocumentIntakeType } from '../../../components/ReportsDashboard/types';
import GenericCard from '../../../components/ReportsDashboard/DocumentIntakeDisplayCard';
import { greenWords } from '../../../components/ReportsDashboard/styles';
import { useToaster } from '../../../Hooks/toasters';

export const backgroundD = {
  position: 'absolute',
  display: 'inline-block',
  height: '100vh',
  zIndex: 1,
  width: '100%',
  overflow: 'hidden',
  svg: {
    width: 1120,
    display: 'block',
    marginLeft: 100,
    position: 'relative',
    top: -150,
    zIndex: 2,
  },
};

export const dpTitle = {
  fontSize: 80,
  lineHeight: '80px',
  fontWeight: 'bold',
  color: '#f9f9f9',
  position: 'absolute',
  transform: 'rotate(-90deg)',
  left: -202,
  top: 255,
  textTransform: 'uppercase',
  letterSpacing: '0.10em',
  zIndex: 10,
  '-webkit-text-fill-color': 'white' /* Will override color (regardless of order) */,
  '-webkit-text-stroke-width': '1px',
  '-webkit-text-stroke-color': '#64ae3e',
};

export const fullScreenStyles = {
  position: 'absolute',
  bottom: 24,
  right: 24,
  backgroundColor: '#fff',
  border: '1px solid #64ae3e',
  height: 40,
  padding: '0 16px',
  borderRadius: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    color: '#64ae3e',
  },
};

const DocumentIntake = () => {
  const { data, refetch, isSuccess } = useGetData<DocumentIntakeType>(
    '/api/reports/document-intake',
    {
      totalCoverSheetsCreatedToday: 0,
      percentOfNotFoundDocuments: 0,
      percentOfTotalWeeklyDocumentCreationGoal: 0,
      totalDocumentsCreatedMonthToDate: 0,
      totalDocumentsCreatedYearToDate: 0,
      inQueueToBeScanned: 0,
      inQueueToBeScannedAsOfToday: 0,
      percentOfGoalStapled: 0,
      totalDocumentsScannedToday: 0,
      totalDocumentsStapledToday: 0,
      totalForMonthScanned: 0,
      totalForMonthStapled: 0,
      totalForYearScanned: 0,
      totalForYearStapled: 0,
      percentOfGoalScanned: 0,
      allDocumentsInQueueToBeStapled: 0,
      documentsInQueueToBeStapledAsOfToday: 0,
    },
  );
  const [showLoader, setShowLoader] = useState(true);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [showFSButton, setShowFSButton] = useState(true);

  const { successToaster } = useToaster();

  useEffect(() => {
    const TWO_MINUTES = 120000;
    const id = setInterval(refetch, TWO_MINUTES);
    setIntervalId(id);

    return clearInterval(intervalId as NodeJS.Timeout);
  }, [intervalId, refetch]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (showLoader) {
      setShowLoader(false);
    } else {
      successToaster('The reports refreshed.');
    }
  }, [isSuccess, showLoader]);

  const inputEl = useRef(null);

  const makeFullScreen = () => {
    setShowFSButton(false);
    inputEl.current.requestFullscreen();
  };

  const pageWrap = {
    backgroundColor: '#f2f2f2',
    height: '100vh',
    width: '100%',
    position: 'relative',
  };

  return (
    <div css={pageWrap} ref={inputEl}>
      <div css={backgroundD}>
        <DeeForty />
      </div>
      {showLoader ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress size="75" disableShrink />
        </div>
      ) : (
        <div
          css={{
            padding: '24px 20px',
            height: '100%',
            position: 'relative',
            zIndex: 5,
            paddingLeft: 132,
          }}
        >
          <div css={dpTitle}>Docprobe</div>
          {showFSButton && (
            <div css={fullScreenStyles} onClick={makeFullScreen}>
              Full Screen
            </div>
          )}
          <div
            css={{
              display: 'flex',
              alignItems: 'flex-start',
              '> div': {
                padding: '44px 50px',
                backgroundColor: '#fefefe',
                borderRadius: '19px',
                boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
              },
            }}
          >
            <GenericCard
              kind="without-child"
              title="Scan"
              today={data.totalDocumentsScannedToday}
              queue={data.inQueueToBeScannedAsOfToday}
              new={data.inQueueToBeScannedAsOfToday}
              percentOfGoal={data.percentOfGoalScanned}
              month={data.totalForMonthScanned}
              year={data.totalForYearScanned}
            />
            <GenericCard
              kind="without-child"
              title="Staple"
              today={data.totalDocumentsStapledToday}
              queue={data.allDocumentsInQueueToBeStapled}
              new={data.documentsInQueueToBeStapledAsOfToday}
              percentOfGoal={data.percentOfGoalStapled}
              month={data.totalForMonthStapled}
              year={data.totalForYearStapled}
            />
            <GenericCard
              kind="with-child"
              title="Audit"
              today={data.totalCoverSheetsCreatedToday}
              percentOfGoal={data.percentOfTotalWeeklyDocumentCreationGoal}
              month={data.totalDocumentsCreatedMonthToDate}
              year={data.totalDocumentsCreatedYearToDate}
            >
              <div css={[greenWords, { marginTop: 20 }]}>
                {data.percentOfNotFoundDocuments}% NOT PURCHASED
              </div>
            </GenericCard>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentIntake;

export function DeeForty() {
  return (
    <svg
      enableBackground="new 0 0 1807 1824"
      viewBox="0 0 1807 1824"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m790.1 522.4v130.2h-135.1v1171.4h-130.4v-1171.5h-130.5v1171.5h-130.3v-1171.5h-130.4v1171.5h-130.4v-1171.5-130.2zm144.9-266.1c332 0 599.5 304.7 599.5 640.7 0 341.6-267.5 651.8-599.5 651.8h-152.1v-130.7h107.3c283.2 0 514.9-233.6 514.9-520.6 0-281.8-231.7-510.2-514.9-510.2h-890.2v-131.1h2.2zm872 648.3c0 481.9-377.6 919.4-846.5 919.4h-176v-130.2h113.5c429.8 0 781.4-354.2 781.4-789.2 0-427.1-351.5-773.6-781.4-773.6l-895 .1v-130.3h957.5c468.9 0 846.5 429.8 846.5 903.8z"
        fill="rgba(195,195,195, 0.15)"
        fillRule="evenodd"
      />
    </svg>
  );
}
