import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './components/AuthContext';
import { SidebarProvider } from './components/Layout/Sidebar/SidebarContext';
import { ClientsProvider } from './ClientsContext';
import { DocumentTypesProvider } from './DocumentTypesContext';
import { AlertProvider } from './components/AlertContext';
import { InvestorsProvider } from './InvestorsContext';

const queryClient = new QueryClient();

export default function Providers({ children }) {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ClientsProvider>
          <InvestorsProvider>
            <DocumentTypesProvider>
              <AlertProvider>
                <SidebarProvider>{children}</SidebarProvider>
              </AlertProvider>
            </DocumentTypesProvider>
          </InvestorsProvider>
        </ClientsProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
