import styled from '@emotion/styled';

export const linkS = {
  cursor: 'pointer',
  color: 'blue',
  textDecoration: 'underline',
  transition: 'all .2s',
  '&:hover': {
    color: '#0018ab',
    transition: 'all .2s',
  },
};

export const nameS = {
  color: '#47517B',
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: '1.1',
};
export const ActionButton = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: 5,
  backgroundColor: '#1F2B5E',
  cursor: 'pointer',
  svg: { color: '#fff' },
});

export const InfoRow = styled.div(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr ',
    gridColumnGap: '24px',
    gridRowGap: '24px',
    span: { wordBreak: 'break-word' },
    'span:first-of-type': {
      fontWeight: 'bold',
      display: 'block',
      marginRight: 5,
      color: '#47517B',
    },
  },
  props => ({ css: props.css }),
);

export const InnerCard = styled.div({
  backgroundColor: '#f5f6f8',
  borderRadius: 5,
  color: '#000',
  padding: 8,
});

export const InnerCardHeader = styled.div`
  color: #00b8d4;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 16px;
`;
export const ItemLabel = styled.div({
  fontWeight: 'bold',
  display: 'flex',
  marginRight: 5,
  color: '#47517B',
});
