/* eslint-disable default-case */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import fromPairs from 'lodash/fromPairs';
import { fromNullable, none, Option, some } from 'fp-ts/es6/Option';
import { useQuery } from '@tanstack/react-query';
import Tab, { TabGroup } from '../components/ui/Tabs/Tab';
import SearchSelect from '../components/ui/SearchSelect';
import ClientSearch from '../components/Utilities/ClientSearch';
import Dropdown from '../components/ui/Dropdown';
import Button, { StatusTypes } from '../components/ui/StatusButton';
import InputNumber from '../components/ui/Inputs/InputNumber';
import Panel, { PanelHeader } from '../components/ui/Panel';
import { apiFetch, apiPost, fetchWithAuth } from '../adalConfig';
import { Client, DocumentType, Loan } from '../globalTypes/objects';
import { AuthContext } from '../components/AuthContext';
import { useToaster } from '../Hooks/toasters';
import { getDocTypeName } from '../Utils';
import { DocumentTypesContext } from '../DocumentTypesContext';
import { useClient } from '../Hooks/useClients';

const inlineInput = {
  display: 'inline-block',
  marginTop: 24,
  verticalAlign: 'top',
  width: 230,
};

interface NotSet {
  kind: 'NOT_SET';
}
interface NotFound {
  kind: 'NOT_FOUND';
  loanNumber: string;
}

interface Found extends Loan {
  kind: 'FOUND';
}

interface SelectOption<T> {
  label: string;
  subLabel: string;
  value: T;
}

type SelectOptions<T> = SelectOption<T>[];

type LoanResult = Found | NotFound | NotSet;

type SearchBy = 'LOAN_NUMBER' | 'ADDRESS';

type ApplyTo = 'applyToNext' | `applyToExisting-${number}`;

interface FormData {
  costIncurred: Option<number>;
  documentType: Option<DocumentType>;
  loanNumber: Option<string>;
  clientId: Option<number>;
  applyTo: Option<ApplyTo>;
}

interface FoundForm extends FormData {
  loanId: Option<number>;
}

interface NotFoundForm extends FormData {
  clientId: Option<number>;
}

type Form<T> = T extends Found ? FoundForm : T extends NotFound ? NotFoundForm : never;

export default function SearcherRetrieval() {
  const [searchingBy, setSearchBy] = useState<SearchBy>('LOAN_NUMBER');

  const [loan, setLoan] = useState<LoanResult>({ kind: 'NOT_SET' });
  const [client, setClient] = useState<Client>();
  const [docType, setDocType] = useState<DocumentType>();
  const [cost, setCost] = useState<Option<number>>(none);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applyTo, setApplyTo] = useState<ApplyTo>('applyToNext');

  const { user } = useContext(AuthContext);
  const { successToaster, warningToaster } = useToaster();

  const loanId = loan.kind === 'FOUND' ? loan.id : undefined;

  type LoanDocumentBilledStatus = 'BILLED' | 'NOT_BILLED';
  interface LoanDocument {
    documentId: number;
    billedStatus: LoanDocumentBilledStatus;
  }

  const loanDocumentsQuery = useQuery<LoanDocument[]>(
    ['existingDocuments', loanId, docType],
    () =>
      fetchWithAuth(`/Api/AdditionalFees/ExistingDocuments?loanId=${loanId}&docType=${docType}`)
        .then((res) => res.json())
        .catch((_) => warningToaster('There was a problem. Please reload the page.')),
    {
      enabled: loanId !== undefined && docType !== undefined && docType !== DocumentType.Unknown,
    },
  );

  const loanDocuments = fromNullable(loanDocumentsQuery.data);

  const billedLoanDocuments = loanDocuments.map((lds) =>
    lds.filter((ld) => ld.billedStatus === 'BILLED'),
  );
  const notBilledLoanDocuments = loanDocuments.map((lds) =>
    lds.filter((ld) => ld.billedStatus === 'NOT_BILLED'),
  );

  const canApplyToExisting: Option<boolean> =
    loanDocuments.isNone() || loanDocuments.exists((lds) => lds.length < 1)
      ? none
      : some(
          notBilledLoanDocuments.exists((lds) => lds.length > 0)
            ? true
            : !billedLoanDocuments.exists((lds) => lds.length > 0),
        );

  const getFoundForm: FoundForm = {
    costIncurred: cost,
    documentType: docType ? some(docType) : none,
    loanNumber: loan.kind === 'FOUND' ? some(loan.loanNumber) : none,
    loanId: loan.kind === 'FOUND' ? some(loan.id) : none,
    clientId: client ? some(client.id) : none,
    applyTo: some(applyTo),
  };

  const getNotFoundForm: NotFoundForm = {
    costIncurred: cost,
    documentType: docType ? some(docType) : none,
    loanNumber: loan.kind === 'NOT_FOUND' ? some(loan.loanNumber) : none,
    clientId: client ? some(client.id) : none,
    applyTo: some('applyToNext'),
  };

  const form: Form<typeof loan> = loan.kind === 'FOUND' ? getFoundForm : getNotFoundForm;

  const formRaw = fromPairs(Object.entries(form).map(([key, val]) => [key, val.toNullable()]));
  const { docTypes } = useContext(DocumentTypesContext);

  const apiFetchDebounced = debounce(
    // eslint-disable-next-line consistent-return
    (url: string, input: string, callback: (options: SelectOptions<LoanResult>) => unknown) => {
      const notFound: NotFound = {
        kind: 'NOT_FOUND',
        loanNumber: input,
      };
      const notFoundOption = { label: `Not Found ${input}`, subLabel: '', value: notFound };

      if (input.length < 3) return callback([notFoundOption]);

      apiFetch<Loan[]>(url).then((result) => {
        const options: SelectOptions<Found> = result.data.map((l) => ({
          label: l.loanNumber,
          subLabel: l.propertyAddress,
          value: { kind: 'FOUND', ...l },
        }));

        callback([notFoundOption, ...options]);
      });
    },
    2000,
  );
  const loadOptions = (input: string, clientId: number, callback) => {
    const routePart = upperFirst(camelCase(searchingBy));
    apiFetchDebounced(
      `api/Loans/SearchBy${routePart}AndClient?searchString=${input}&clientId=${clientId}&includePartial=false`,
      input,
      callback,
    );
  };

  function isReadyForSubmit(): boolean {
    return Object.values(form).every((x) => x.isSome());
  }

  async function handleSubmit() {
    setIsSubmitting(true);
    const response = await apiPost('/api/AdditionalFees/InsertAdditionalFee', formRaw).catch(
      (e) => e.response,
    );
    const wasInserted = response.status === 200;

    const docTypeName = fromNullable(docTypes.find(({ value }) => value === docType)).map(
      (x) => x.label,
    );

    if (loan.kind === 'FOUND' && wasInserted) {
      const newNote = {
        orderID: loan.id,
        noteText: `Cost entered by searcher for ${docTypeName.getOrElse('')}`,
        userName: user?.displayName,
      };

      await apiPost('/api/Notes/InsertNote', newNote);
      successToaster('Fee Added.');
    }
    if (loan.kind === 'NOT_FOUND' && wasInserted)
      successToaster('Fee will be added when document is processed');
    if (!wasInserted) warningToaster('There was a error. Fee was not added.');

    setLoan({ kind: 'NOT_SET' });
    setCost(none);
    setDocType(undefined);
    setClient(undefined);
    setIsSubmitting(false);
    setApplyTo('applyToNext');
  }

  return (
    <Panel styles={{ margin: '40px' }}>
      <PanelHeader text="Searcher Retrieval Fee" />
      <div css={{ margin: '24px 0' }}>
        <span css={{ marginRight: 8 }}>Search by</span>
        <TabGroup>
          <Tab onClick={() => setSearchBy('LOAN_NUMBER')}>Loan Number</Tab>
          <Tab onClick={() => setSearchBy('ADDRESS')}>Address</Tab>
        </TabGroup>
      </div>
      <div css={{ width: 500 }}>
        <ClientSearch
          styleOverrides={{ marginBottom: '18px' }}
          selectedClient={client?.id}
          onChange={setClient}
        />
        <div
          // @ts-ignore
          css={!client && inactiveInputCss}
        >
          <SearchSelect
            placeholder="Search loans"
            cacheOptions={false}
            loadOptions={(input, onSuccess) => loadOptions(input, client!.id, onSuccess)}
            onChange={({ value }: { value: LoanResult }) => setLoan(value)}
            filterOption={() => true}
            value={loan.kind === 'NOT_SET' ? '' : { label: loan.loanNumber }}
            isAsync
          />
        </div>
        <div css={inlineInput}>
          <p css={{ marginBottom: 8 }}>Select document type</p>
          <div css={[{ marginBottom: 24 }]}>
            <Dropdown
              options={docTypes}
              placeholder="Document Type"
              onChange={({ value }: { value: DocumentType }) => setDocType(value)}
              value={docType ? docTypes.find(({ value }) => value === docType) : ''}
            />
          </div>
        </div>
        <div css={[inlineInput, { marginLeft: 16, width: 166 }]}>
          <InputNumber
            thousandSeparator
            prefix="$"
            label="Cost"
            decimalScale={2}
            isNumericString
            onValueChange={({ floatValue }) => setCost(floatValue ? some(floatValue) : none)}
            value={cost.isSome() ? cost.value : ''}
          />
        </div>
        {docType && canApplyToExisting.isSome() && (
          <div className="p1">
            <div>
              A {getDocTypeName(docType).toLowerCase()} has already been imported for this loan.
            </div>
            {canApplyToExisting.value && (
              <div
                className="mt2"
                css={{
                  padding: 16,
                  borderRadius: '5px',
                  backgroundColor: '#f2f2f2',
                  textAlign: 'center',
                }}
              >
                If the searched document has been ordered but not yet imported to DP3 choose the
                first option.
                <span
                  // @ts-ignore
                  css={{ fontWeight: '500', fontStyle: 'italic', fontSize: 16, display: 'block' }}
                >
                  or
                </span>{' '}
                If the searched document has already been imported to DP3, choose the second option.
              </div>
            )}
            <div className="mt2">
              <div className="df aic">
                <input
                  style={{ margin: 0 }}
                  onChange={(_) => setApplyTo('applyToNext')}
                  value="applyToNext"
                  name="apply-fee-to"
                  type="radio"
                  id="apply-to-next"
                  checked={applyTo === 'applyToNext'}
                />
                <label css={{ marginLeft: 8 }} htmlFor="apply-to-next">
                  Apply to the next {getDocTypeName(docType).toLowerCase()} imported
                </label>
              </div>
              {!canApplyToExisting.value && (
                <div css={{ marginTop: 10 }}>
                  If you want to apply to the existing {getDocTypeName(docType).toLowerCase()},
                  please reach out to an admin who can enter the charge manually.
                </div>
              )}
              {canApplyToExisting.value &&
                notBilledLoanDocuments.getOrElse([]).map((document) => (
                  <div key={document.documentId} css={{ marginTop: 12 }}>
                    <input
                      checked={applyTo === `applyToExisting-${document.documentId}`}
                      style={{ margin: 0 }}
                      onChange={(_) => setApplyTo(`applyToExisting-${document.documentId}`)}
                      value={`applyToExisting-${document.documentId}`}
                      name="apply-fee-to"
                      type="radio"
                      id={`apply-to-existing-${document.documentId}`}
                    />
                    <label
                      css={{ marginLeft: 8 }}
                      htmlFor={`apply-to-existing-${document.documentId}`}
                    >
                      Apply to the {getDocTypeName(docType).toLowerCase()} previously imported.
                      Barcode:{' '}
                      <a
                        css={{ textDecoration: 'underline' }}
                        rel="noreferrer"
                        target="_blank"
                        href={`/documents/${document.documentId}`}
                      >
                        {document.documentId}
                      </a>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        )}
        <Button
          disabled={!isReadyForSubmit()}
          status={isSubmitting ? StatusTypes.loading : StatusTypes.initial}
          text="submit"
          onClick={() => handleSubmit()}
          data-test="submit"
        />
      </div>
    </Panel>
  );
}

const inactiveInputCss = {
  position: 'relative',
  ':before': {
    cursor: 'not-allowed',
    zIndex: '50',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    content: `""`,
    backgroundColor: 'rgba(0, 0, 0, .05)',
    borderRadius: '4px',
  },
};
