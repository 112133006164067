/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import Select, { components, createFilter } from 'react-select';
import Async from 'react-select/async';
import escapeRegExp from 'lodash/escapeRegExp';

import {
  containerStyles,
  inputStyles,
  itemStyles,
  matchedStyles,
  openMenuStyles,
  searchglassStyles,
} from './css';
import Icon, { IconType } from '../Icon';

const customStyles = {
  control: (styles, { selectProps: { isError } }) => ({
    ...styles,
    ...inputStyles(isError),
  }),
  container: (styles, { isFocused }) => ({
    ...styles,
    ...containerStyles(isFocused),
  }),
  menu: () => ({
    // This removes original library style
  }),
  menuList: () => openMenuStyles,
  option: (styles, { isFocused }) => ({ ...styles, ...itemStyles(isFocused) }),
};

const Option = ({ children, ...props }) =>
  children.subLabel ||
  children.first ||
  children.second ||
  children.third ||
  children.fourth ||
  children.fifth ? (
    <components.Option {...props}>
      <span css={{ fontWeight: 700 }}>{children.label}</span>
      {children.subLabel && <div>{children.subLabel}</div>}
      {children.first && (
        <div>
          <i>{children.first}</i>
        </div>
      )}
      {children.second && (
        <div>
          <i>{children.second}</i>
        </div>
      )}
      {children.third && (
        <div>
          <i>{children.third}</i>
        </div>
      )}
      {children.fourth && (
        <div>
          <i>{children.fourth}</i>
        </div>
      )}
      {children.fifth && (
        <div>
          <i>{children.fifth}</i>
        </div>
      )}
    </components.Option>
  ) : (
    <components.Option {...props}>{children.label}</components.Option>
  );

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>{data.label}</components.SingleValue>
);

const DropdownIndicator = ({ selectProps, isFocused, ...props }) => {
  const { inputValue } = selectProps;
  return !inputValue ? (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon={IconType.Search} css={searchglassStyles(isFocused)} />
      </components.DropdownIndicator>
    )
  ) : (
    <components.ClearIndicator {...props}>
      <Icon icon={IconType.X} css={searchglassStyles(inputValue)} />
    </components.ClearIndicator>
  );
};

const formatOptionLabel = (option, { inputValue }, filter, match) => {
  const { label, ...optionRest } = option;
  const { matchFrom } = filter;
  // bolds letters that match search filter.
  // possible options are start of word, all matches, or custom filter
  const splitString = text => {
    if (matchFrom === 'start') {
      return text.split(new RegExp(`(^${escapeRegExp(inputValue)})`, 'i'));
    }
    if (matchFrom === 'all') {
      return text.split(new RegExp(`(${escapeRegExp(inputValue)})`, 'i'));
    }
    if (matchFrom === 'allIgnoreSpace') {
      return text.split(new RegExp(`(${escapeRegExp(inputValue).replace(' ', '\\s*')})`, 'i'));
    }
    return [text];
  };
  const toBold = text =>
    (match && match(text, inputValue)) ||
    ((matchFrom === 'start' || matchFrom === 'all') &&
      text.toLowerCase() === inputValue.toLowerCase()) ||
    (matchFrom === 'allIgnoreSpace' &&
      text.replace(/ /g, '').toLowerCase() === inputValue.replace(/ /g, '').toLowerCase());
  const parts = splitString(label).map((p, i) =>
    toBold(p) ? (
      <span key={`${p + i}`} css={matchedStyles}>
        {p}
      </span>
    ) : (
      p
    ),
  );
  return {
    label: <span>{parts}</span>,
    ...{ ...optionRest },
  };
};

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  trim: false,
  matchFrom: 'start',
};

const allIgnoreSpaceFilterOption = (option, searchString) => {
  return option.label
    .replace(/ /g, '')
    .toLowerCase()
    .includes(searchString.replace(/ /g, '').toLowerCase());
};

type SearchSelectProps = {
  isAsync?: boolean;
  [key: string]: any;
};
type SearchSelectState = { inputText: string };
export default class SearchSelect extends Component<SearchSelectProps, SearchSelectState> {
  state = {
    inputText: '',
  };

  selectProps = (inputText, props) => ({
    components: {
      DropdownIndicator,
      IndicatorSeparator: null,
      Option: props.Option || Option,
      SingleValue: props.SingleValue || SingleValue,
    },
    formatOptionLabel: (...args) =>
      // @ts-ignore
      formatOptionLabel(...args, props.filterConfig || filterConfig, props.match),
    styles: customStyles,
    onInputChange: inputValue => {
      this.setState({
        inputText: inputValue,
      });
    },
    menuIsOpen: inputText !== '',
    inputValue: inputText,
    onSelectResetsInput: false,
    backspaceRemoves: false,
    noOptionsMessage: () => (
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        No Results
      </div>
    ),
    filterOption:
      props.filterOption ||
      (props.filterConfig?.matchFrom === 'allIgnoreSpace' && allIgnoreSpaceFilterOption) ||
      createFilter(props.filterConfig || filterConfig),
    isClearable: false,
    ...props,
  });

  render() {
    const { inputText } = this.state;
    const { isAsync, ...props } = this.props;
    return isAsync ? (
      <Async
        cacheOptions
        isClearable
        loadOptions={props.loadOptions}
        {...this.selectProps(inputText, props)}
      />
    ) : (
      <Select isClearable {...this.selectProps(inputText, props)} />
    );
  }
}
