import React from 'react';

import ErrorPage, { ErrorType } from '../components/ui/ErrorPage';

const error: ErrorType = {
  errorTitle: 'Error ',
  errorName: 404,
  firstNumber: 4,
  lastNumber: 4,
  errorMessage:
    'The page you were looking for does not exist. You may have mistyped the address or the page may have been moved.',
};

export default function Page404() {
  return <ErrorPage errorType={error} />;
}
