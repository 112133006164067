/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useState } from 'react';
import produce from 'immer';
import DatePicker from 'react-datepicker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from '../../components/AuthContext';
import { roleTypes } from '../../constants';
import TextInput from '../../components/ui/Inputs/TextInput';
import ZipInput from '../../components/ui/Inputs/ZipInput';
import PhoneInput from '../../components/ui/Inputs/PhoneInput';
import Checkbox from '../../components/ui/Checkbox';
import { ActionButton } from './cardStyles';
import { ClientData, ClientSystemAccess, User } from './types';
import Dropdown from '../../components/ui/Dropdown';
import ClientDataDisplayCard from './ClientDataDisplayCard';
import { EditTwoIcon } from '../../components/ui/icons';
import { useGetData } from '../../Hooks';
import { clientReturnAddressDescriptions, isClientAdmin } from '../../Utils';
import { apiFetch } from '../../adalConfig';
import { ClientServiceLevel } from '../../globalTypes/objects';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      '& div.react-datepicker-wrapper, div.react-datepicker__input-container, div.react-datepicker__input-container input':
        {
          width: '100%',
        },
    },
  }),
);

type DropdownFields = {
  accountReps: User[];
  allDocProbeUsers: User[];
};

type Props = {
  clientData: ClientData;
  setClientData: (clientData: ClientData) => void;
  isNewClient: boolean;
  systemAccess: ClientSystemAccess;
  setSystemAccess: (clientSystemAccess: ClientSystemAccess) => void;
  accountRep: User;
  setAccountRep: (accountRep: User) => void;
  auditor: User;
  setAuditor: (auditor: User) => void;
  clientServiceCoordinator: User;
  setClientServiceCoordinator: (clientServiceCoordinator: User) => void;
};

enum PODNumbers {
  RemovePOD = 0,
  MyTeam = 1,
  OurTeam = 2,
  HelloTeam = 3,
}
const PODs = Object.entries(PODNumbers)
  .filter(([key, val]) => typeof val === 'number')
  .map(([key, val]) => ({ label: key, value: val }));

const coops = [
  'Capital Markets Cooperative (CMC)',
  'Lenders One',
  'The Mortgage Collaborative (TMC)',
];

const reportSources: Array<ClientData['reportSource']> = [
  'LOS',
  'Client emails',
  'FTP',
  'Encompass API',
];

const reportFrequencies: Array<ClientData['reportFrequency']> = [
  'Daily',
  'Weekly',
  'Bi Monthly',
  'Monthly',
];

const getClientServiceLevels = async (): Promise<ClientServiceLevel[]> => {
  const { data } = await apiFetch('/api/clients/service-levels');
  return data;
};

const ClientDataFields = ({
  clientData,
  setClientData,
  isNewClient,
  systemAccess,
  setSystemAccess,
  accountRep,
  setAccountRep,
  auditor,
  setAuditor,
  clientServiceCoordinator,
  setClientServiceCoordinator,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const { data: dropdownFields, isSuccess } = useGetData<DropdownFields>(
    '/api/clients/dropdown-fields',
    {} as DropdownFields,
  );

  const { data: serviceLevels } = useQuery({
    queryKey: ['client-service-levels'],
    queryFn: getClientServiceLevels,
    refetchOnWindowFocus: false,
  });

  const { roles } = useContext(AuthContext);

  const classes = useStyles();

  const updateInputField = <T, K extends keyof T>(
    updatedField: K,
    newValue: T[K],
    originalData: T,
  ) => {
    return produce(originalData, (draft: T) => {
      draft[updatedField] = newValue;
    });
  };

  const updateClientDataInputField = <K extends keyof ClientData>(
    updatedField: K,
    newValue: ClientData[K],
  ) => {
    const newClientData = updateInputField(updatedField, newValue, clientData);

    setClientData(newClientData);
  };

  const updateClientSystemAccessField = <K extends keyof ClientSystemAccess>(
    updatedField: K,
    newValue: ClientSystemAccess[K],
  ) => {
    const newClientSystemAccess = updateInputField(updatedField, newValue, systemAccess);

    setSystemAccess(newClientSystemAccess);
  };

  return (
    <Fragment>
      {editMode || isNewClient ? (
        <div css={{ 'label span': { fontWeight: 'bold' } }}>
          <ActionButton
            onClick={() => setEditMode(!editMode)}
            css={{ position: 'absolute', right: 32, top: 32 }}
          >
            <EditTwoIcon />
          </ActionButton>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: 24,
              gridRowGap: 24,
            }}
          >
            <TextInput
              type="text"
              label="Company"
              value={clientData.company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('company', e.target.value)
              }
            />
            <div />
            <TextInput
              type="text"
              label="First Name"
              value={clientData.firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('firstName', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="Last Name"
              value={clientData.lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('lastName', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="Address"
              value={clientData.address}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('address', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="City"
              value={clientData.city}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('city', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="State"
              value={clientData.state}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('state', e.target.value)
              }
            />
            <ZipInput
              label="Zip"
              value={clientData.zip}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('zip', e.target.value)
              }
            />
            <PhoneInput
              label="Phone"
              value={clientData.phone}
              onChange={(value: string) => updateClientDataInputField('phone', value)}
            />
            <PhoneInput
              label="Fax"
              value={clientData.fax}
              onChange={(value: string) => updateClientDataInputField('fax', value)}
            />
            <TextInput
              type="email"
              label="Email"
              value={clientData.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('email', e.target.value)
              }
            />
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>Account Manager</b>
              </div>
              <Autocomplete
                options={isSuccess ? dropdownFields.accountReps : []}
                getOptionLabel={option => (option?.username ? option.username : '')}
                value={accountRep}
                noOptionsText={isSuccess ? 'No options' : 'Loading...'}
                onChange={(event: any, newValue: User | null) =>
                  newValue && setAccountRep(newValue)
                }
                style={{ height: '48px' }}
                renderInput={params => <TextField {...params} variant="outlined" />}
              />
            </div>
            <TextInput
              type="email"
              label="Client Inbox"
              value={clientData.clientInbox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('clientInbox', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="AR Support"
              value={clientData.arSupport}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('arSupport', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="Loan Data Contact First Name"
              value={clientData.loanDataContactFirstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('loanDataContactFirstName', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="Loan Data Contact Last Name"
              value={clientData.loanDataContactLastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('loanDataContactLastName', e.target.value)
              }
            />
            <TextInput
              type="email"
              label="Loan Data Contact Email"
              value={clientData.loanDataContactEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('loanDataContactEmail', e.target.value)
              }
            />
            <TextInput
              type="number"
              label="Followups Range"
              value={clientData.followupsRange}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('followupsRange', parseInt(e.target.value))
              }
            />
            <TextInput
              type="text"
              label="Record & Return PO Box"
              value={clientData.poBox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('poBox', e.target.value)
              }
            />
            <div>
              <span>
                <b>Return Address</b>
              </span>
              <Dropdown
                options={Array.from(clientReturnAddressDescriptions, ([value, description], _) => ({
                  label: description,
                  value,
                }))}
                label="Return Address"
                placeholder="Select a return address"
                value={
                  clientData.returnAddress
                    ? {
                        label: clientReturnAddressDescriptions.get(clientData.returnAddress),
                        value: clientData.returnAddress,
                      }
                    : null
                }
                onChange={selected => updateClientDataInputField('returnAddress', selected.value)}
              />
            </div>
            <TextInput
              type="email"
              label="Invoice Email"
              value={clientData.invoiceEmail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('invoiceEmail', e.target.value)
              }
            />
            <TextInput
              type="email"
              label="Invoice Email CC"
              value={clientData.invoiceEmailCC}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('invoiceEmailCC', e.target.value)
              }
            />

            <TextInput
              type="text"
              label="Contact Name"
              value={clientData.contactName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('contactName', e.target.value)
              }
            />

            <TextInput
              type="text"
              label="Dropbox Folder"
              value={clientData.dropboxFolder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('dropboxFolder', e.target.value)
              }
            />
            <div className={classes.datePicker}>
              <DatePicker
                customInput={<TextInput label="Start Date" />}
                selected={
                  clientData.startDate
                    ? new Date(new Date(clientData.startDate).setUTCHours(12))
                    : null
                }
                onChange={value =>
                  updateClientDataInputField(
                    'startDate',
                    value ? value.toLocaleDateString('en-CA') : null,
                  )
                }
              />
            </div>
            <TextInput
              type="text"
              label="LOS"
              value={clientData.los}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('los', e.target.value)
              }
            />
            <TextInput
              type="text"
              label="Document Transfer Method"
              value={clientData.documentTransferMethod}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('documentTransferMethod', e.target.value)
              }
            />
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>Auditor</b>
              </div>
              <Autocomplete
                options={isSuccess ? dropdownFields.allDocProbeUsers : []}
                getOptionLabel={option => (option?.username ? option.username : '')}
                value={auditor}
                noOptionsText={isSuccess ? 'No options' : 'Loading...'}
                onChange={(event: any, newValue: User | null) => newValue && setAuditor(newValue)}
                style={{ height: '48px' }}
                renderInput={params => <TextField {...params} variant="outlined" />}
              />
            </div>
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>Client Success Manager</b>
              </div>
              <Autocomplete
                options={isSuccess ? dropdownFields.accountReps : []}
                getOptionLabel={option => option?.displayName ?? option?.username ?? ''}
                value={clientServiceCoordinator}
                noOptionsText={isSuccess ? 'No options' : 'Loading...'}
                onChange={(event: any, newValue: User | null) =>
                  newValue && setClientServiceCoordinator(newValue)
                }
                style={{ height: '48px' }}
                renderInput={params => <TextField {...params} variant="outlined" />}
              />
            </div>
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>Purchased loans Report Frequency</b>
              </div>
              <Dropdown
                options={reportFrequencies.map(frequency => ({
                  label: frequency,
                  value: frequency,
                }))}
                label="Frequency"
                placeholder="Select a frequency"
                value={
                  clientData.reportFrequency
                    ? { label: clientData.reportFrequency, value: clientData.reportFrequency }
                    : null
                }
                onChange={(selected: { value: ClientData['reportFrequency'] }) =>
                  updateClientDataInputField('reportFrequency', selected.value)
                }
              />
            </div>
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>Loan Data Source</b>
              </div>
              <Dropdown
                options={reportSources.map(source => ({
                  label: source,
                  value: source,
                }))}
                label="Loan Data Source"
                placeholder="Select a loan data source"
                value={
                  clientData.reportSource
                    ? { label: clientData.reportSource, value: clientData.reportSource }
                    : null
                }
                onChange={(selected: { value: ClientData['reportSource'] }) =>
                  updateClientDataInputField('reportSource', selected.value)
                }
              />
            </div>
            <TextInput
              type="number"
              label="Baseline Loans Per Month"
              value={clientData.baselineLoansPerMonth === 0 ? '' : clientData.baselineLoansPerMonth}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateClientDataInputField('baselineLoansPerMonth', parseInt(e.target.value))
              }
            />
            <div>
              <div style={{ paddingBottom: '7px' }}>
                <b>POD</b>
              </div>
              <Dropdown
                options={PODs}
                label="Pod"
                placeholder="Select a Pod"
                value={
                  clientData.podNumber
                    ? PODs.find(({ value }) => value === clientData.podNumber)
                    : null
                }
                onChange={selected =>
                  updateClientDataInputField('podNumber', parseInt(selected.value))
                }
              />
            </div>
            <div className="df aife">
              <Autocomplete
                options={serviceLevels || ([] as ClientServiceLevel[])}
                getOptionLabel={(option: ClientServiceLevel) => option.label}
                onChange={(e: any, newValue: ClientServiceLevel | null) =>
                  newValue?.id && updateClientDataInputField('serviceLevelId', newValue.id)
                }
                value={serviceLevels?.find(x => x.id === clientData.serviceLevelId) || null}
                style={{ width: '100%' }}
                autoHighlight
                renderInput={params => (
                  <TextField {...params} label="Choose a service level" variant="outlined" />
                )}
              />
            </div>
            {roles.includes(roleTypes.Dev) ? (
              <TextInput
                type="text"
                label="Logo"
                value={clientData.logo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateClientDataInputField('logo', e.target.value)
                }
              />
            ) : (
              <div />
            )}
            <Checkbox
              labelOverrides={{ width: 200 }}
              text="Require Upload To Encompass"
              checked={clientData.requireUploadToEncompass}
              onClick={() =>
                updateClientDataInputField(
                  'requireUploadToEncompass',
                  !clientData.requireUploadToEncompass,
                )
              }
            />
            <Checkbox
              labelOverrides={{ width: 200 }}
              text="Inactive"
              checked={clientData.inactive}
              onClick={() => updateClientDataInputField('inactive', !clientData.inactive)}
            />
            <div>
              <Checkbox
                text="Co-op"
                labelOverrides={{ width: 200 }}
                checked={clientData.isCoop}
                onClick={() =>
                  isNewClient ? updateClientDataInputField('isCoop', !clientData.isCoop) : null
                }
              />
              {clientData.isCoop && (
                <Dropdown
                  options={coops.map(coop => ({
                    label: coop,
                    value: coop,
                  }))}
                  placeholder="Select Co-op"
                  value={
                    clientData.coop ? { label: clientData.coop, value: clientData.coop } : null
                  }
                  onChange={selected => updateClientDataInputField('coop', selected.value)}
                  isDisabled={!isNewClient}
                />
              )}
            </div>
            {isClientAdmin(roles) && (
              <Checkbox
                text="Aged Pipeline"
                labelOverrides={{ width: 200 }}
                checked={clientData.agedPipeline}
                onClick={() => updateClientDataInputField('agedPipeline', !clientData.agedPipeline)}
              />
            )}
            <Checkbox
              labelOverrides={{ width: 200 }}
              text="No Follow Up"
              checked={clientData.noFollowupPackage}
              onClick={() =>
                updateClientDataInputField('noFollowupPackage', !clientData.noFollowupPackage)
              }
            />
            <Checkbox
              labelOverrides={{ width: 200 }}
              text="Cancelled"
              checked={clientData.isCancelled}
              onClick={() => updateClientDataInputField('isCancelled', !clientData.isCancelled)}
            />
          </div>
          {isClientAdmin(roles) && (
            <Fragment>
              <hr />
              <h2 className="bold tac">Client System Access</h2>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridColumnGap: 24,
                  gridRowGap: 24,
                }}
              >
                <TextInput
                  type="text"
                  label="Link"
                  value={systemAccess.link}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateClientSystemAccessField('link', e.target.value)
                  }
                />
                <TextInput
                  type="text"
                  label="Username"
                  value={systemAccess.username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateClientSystemAccessField('username', e.target.value)
                  }
                />
                <TextInput
                  type="text"
                  label="Password"
                  value={systemAccess.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateClientSystemAccessField('password', e.target.value)
                  }
                />
              </div>
            </Fragment>
          )}
        </div>
      ) : (
        <ClientDataDisplayCard
          clientData={clientData}
          editOnclick={() => setEditMode(!editMode)}
          systemAccess={systemAccess}
          accountRep={accountRep}
          auditor={auditor}
          clientServiceCoordinator={clientServiceCoordinator}
          serviceLevels={serviceLevels || ([] as ClientServiceLevel[])}
        />
      )}
    </Fragment>
  );
};

export default ClientDataFields;
