/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { FunctionComponent } from 'react';
import colors from '../../styles/colors';

const rowWrap: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.white,
  boxShadow: `0 0 0 1px ${colors.grayLight}`,
  borderRadius: 4,
  display: 'flex',
  height: 80,
  marginBottom: 8,
  padding: '0 32px',
  transition: 'all .2s',
  width: '100%',
  '&:hover': {
    boxShadow: `0 0 0 1.4px ${colors.blue}`,
    cursor: 'pointer',
  },
};

const errorHeaderWrap: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 32px',
  margin: '24px 0 10px',
  ' div': {
    fontWeight: 700,
    color: colors.grayDark,
  },
};

const LargeRowHeader: FunctionComponent<{ styles?: CSSObject }> = ({
  children,
  styles,
  ...props
}) => {
  return (
    <div css={{ ...errorHeaderWrap, ...styles }} {...props}>
      {children}
    </div>
  );
};

const LargeRow: FunctionComponent<{ styles?: CSSObject | CSSObject[]; [key: string]: any }> = ({
  children,
  styles,
  ...props
}) => {
  return (
    <div css={[rowWrap, styles]} {...props}>
      {children}
    </div>
  );
};

export { LargeRow, LargeRowHeader };
