import React, { Fragment, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { NoteData } from '../Loans/types';
import ConditionalComponent from '../ConditionalComponent';
import EditAndDeleteNote from './EditAndDeleteNote';

type Props = {
  notes: NoteData[];
  canEditAndDelete: boolean;
};

const PastNotesDisplay = ({ notes, canEditAndDelete }: Props) => {
  const sortedNotes = useMemo(
    () => [...notes].sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)),
    [notes],
  );

  return (
    <Fragment>
      {!notes.length ? (
        <Fragment>
          <Divider light />
          <div className="fs16 mt1 tac bold">No Past Notes</div>
        </Fragment>
      ) : (
        <div>
          {sortedNotes.map((note, i) => (
            <Fragment key={note.id}>
              <Divider light />
              <div className="mt3">
                <Grid container spacing={1}>
                  <Grid item xs={canEditAndDelete ? 11 : 12}>
                    <div className="df jcsb">
                      <div className="bold fs16 cap">{note.modifiedBy}</div>
                      {note.isInternal && (
                        <div style={{ backgroundColor: 'yellow' }}>Internal Note</div>
                      )}
                      <div>
                        {note.createdAt === '1900-01-01T00:00:00'
                          ? null
                          : new Date(note.createdAt).toLocaleDateString()}
                      </div>
                    </div>
                  </Grid>
                  <ConditionalComponent display={canEditAndDelete}>
                    <Grid item xs={1}>
                      <EditAndDeleteNote noteData={note} />
                    </Grid>
                  </ConditionalComponent>
                </Grid>
              </div>
              <p className="mt2 mb3" style={{ whiteSpace: 'pre-line' }}>
                {note.note}
              </p>
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default PastNotesDisplay;
