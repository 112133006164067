/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { useShipping } from './useShipping';
import { StepButton, StepComponentPanel } from '../Utilities/StepUI/StepPanel';
import InvestorSearchSelect from '../Utilities/InvestorSearchSelect';
import TextInput from '../ui/Inputs/TextInput';
import { StatusTypes } from '../ui/StatusButton';
import { ShippingClient } from './ClientModal';
import { RightArrowIcon } from '../ui/icons';
import ShippingClientsContext from './ShippingClientsContext';
import CircularProgress from '@mui/material/CircularProgress';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderSection: {
      position: 'absolute',
      right: '-300px',
      top: '0',
    },
  }),
);

export default function StepOne() {
  const [shipping, setShipping] = useShipping();
  const [clientData, setClientData] = useState<ShippingClient[]>([]);
  const [showQueue, dontShowQueue] = useState(true);

  const classes = useStyles();

  const { getShippingClients, isLoadingShippingClients } = useContext(ShippingClientsContext);

  const button = () => {
    return (
      <StepButton
        text="Next"
        status={StatusTypes.initial}
        disabled={!shipping.trackingNum || isEmpty(shipping.investor)}
        onClick={() => setShipping({ currentStep: 2 })}
      />
    );
  };

  return (
    <div className="relative">
      <StepComponentPanel
        headerText="Shipping Information"
        number={1}
        button={button}
        overrideStyles={{ maxWidth: 700, marginLeft: 18 }}
      >
        <div className="w-three-quarters">
          <span className="db mt4 mb1">Select investor</span>
          <InvestorSearchSelect
            onChange={investor => {
              setClientData([]);
              setShipping({ investor });
              getShippingClients(investor.id).then(d => setClientData(d));
            }}
            selected={shipping.investor.name && shipping.investor}
          />
          <TextInput
            label="Tracking number"
            value={shipping.trackingNum}
            labelOverrides={{ marginTop: 32 }}
            onChange={e => setShipping({ trackingNum: e.target.value.trim() })}
            required
          />
        </div>
        <div css={[investorClientWrap, clientData.length > 0 && showQueue && { right: 16 }]}>
          <div
            css={{
              padding: 8,
              fontSize: 18,
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>Shipping Queue</span>
            <span
              onClick={() => dontShowQueue(!showQueue)}
              title="Close list"
              css={[
                {
                  cursor: 'pointer',
                  color: '#663cff',
                  transition: 'all .3s',
                  fontSize: 12,
                  display: 'flex',
                  alignItems: 'center',
                  svg: { transition: 'all .3s', margin: '0 4px', transform: 'rotate(0deg)' },
                  '&:hover': { color: 'blue' },
                },
                !showQueue && {
                  position: 'absolute',
                  left: -184,
                  top: 20,
                  svg: { transform: 'rotate(180deg)', transition: 'all .3s' },
                },
              ]}
            >
              {showQueue && 'Hide Queue'}
              <RightArrowIcon />
              {!showQueue && 'Show Queue'}
            </span>
          </div>
          <div css={tableWrap}>
            <table css={{ backgroundColor: '#fff', borderRadius: 8 }}>
              <thead>
                <tr css={headerWrap}>
                  <th>Client</th>
                  <th>Physical</th>
                  <th>Digital</th>
                </tr>
              </thead>
              <tbody>
                {clientData.map(c => (
                  <tr key={c.id} css={rowWrap}>
                    <td>{c.client}</td>
                    <td>{c.physicalAmount}</td>
                    <td>{c.digitalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </StepComponentPanel>
      {isLoadingShippingClients && (
        <div className={`center-in-parent ${classes.loaderSection}`}>
          <CircularProgress size="35" disableShrink />
        </div>
      )}
    </div>
  );
}

const investorClientWrap = {
  position: 'fixed',
  right: -560,
  top: 100,
  transition: 'all .3s',
};

const tableWrap = {
  borderRadius: 8,
  border: '1px solid #e5e5ea',
  backgroundColor: '#fff',
  padding: 8,
  maxHeight: 'calc(100vh - 170px)',
  overflow: 'auto',
};

const headerWrap = {
  'th:first-of-type': { paddingLeft: 16, borderRadius: '4px 0 0 4px', textAlign: 'left' },
  'th:nth-of-type(2n)': { color: 'green', padding: 8 },
  'th:nth-of-type(3n)': { color: 'blue', padding: 8, borderRadius: '0 4px 4px 0' },
  th: {
    backgroundColor: '#e5e5ea',
    borderradius: 6,
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
  },
};

const rowWrap = {
  ':last-of-type': { td: { borderBottom: 'none' } },
  ':nth-of-type(even)': { td: { backgroundColor: '#f7f7f7' } },
  td: { padding: 8 },
  'td:first-of-type': { paddingLeft: 16, borderRadius: '6px 0 0 6px' },
  'td:nth-of-type(2n)': { color: 'green', textAlign: 'center', fontWeight: 'bold' },
  'td:nth-of-type(3n)': {
    color: 'blue',
    borderRadius: '0 6px 6px 0',
    textAlign: 'center',
    fontWeight: 'bold',
  },
};
