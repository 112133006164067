/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useMemo, useState } from 'react';
import { Snackbar } from '@mui/material';
import Button from '@mui/material/Button';
import axios, { AxiosError } from 'axios';
import { apiFetch, apiPost } from '../adalConfig';
import Dashboard from '../components/CallCenter/Dashboard';
import {
  Client,
  ClientInformation,
  DatatableResponse,
  DropdownListItem,
  ErrorInformation,
  IdType,
  RowData,
  SidebarOptions,
} from '../components/CallCenter/types';
import ConfirmationModal from '../components/CallCenter/ConfirmationModal';
import Datatable, { sortDataByPhoneAndClient } from '../components/CallCenter/Datatable';
import ResolveSidebarOptions from '../components/CallCenter/ResolveSidebarOptions';
import { localStorageKeys } from '../constants';
import { AuthContext } from '../components/AuthContext';
import { useToaster } from '../Hooks/toasters';
import { DispositionsProvider } from '../components/CallCenter/DispositionsContext';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

export const poBoxDisplay = (client: Client | ClientInformation) => (
  <Fragment>
    <div>{client.address}</div>
    {client.poBox && (
      <div>
        {client.address.includes('1133') ? 'MAIL CODE ' : 'PO BOX '} {client.poBox}
      </div>
    )}
    <div>
      {client.city}, {client.state.trim()} {client.zip}
    </div>
  </Fragment>
);

const getEscalationCount = async ({
  queryKey,
}: QueryFunctionContext<(string | number | undefined)[]>) => {
  const { data } = await apiFetch('/api/call-tasks/escalation-count', {
    params: { titleCompanyId: queryKey[1] },
  });

  return data as number;
};

const CallCenter = ({ location }) => {
  const [isClientMode, setIsClientMode] = useState(false);
  const initialClientState = {
    id: 0,
    label: '',
    clientInbox: '',
    poBox: null,
    address: '',
    city: '',
    state: '',
    zip: '',
  };
  const [selectedClient, setSelectedClient] = useState<Client>(initialClientState);
  const [selectedTitleCompany, setSelectedTitleCompany] = useState<DropdownListItem>({
    id: 0,
    label: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [datatableData, setDatatableData] = useState<RowData[]>([]);
  const [datatableErrors, setDatatableErrors] = useState<ErrorInformation[]>([]);
  const [localTime, setLocalTime] = useState('');
  const [timeInterval, setTimeInterval] = useState<any>(0);
  const [selectedRows, setSelectedRows] = useState<RowData[]>([]);
  const [sidebarOption, setSidebarOption] = useState<SidebarOptions>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateRequired, setIsUpdateRequired] = useState(false);

  const { data: escalationCount } = useQuery(
    ['title-escalation-count', selectedTitleCompany.id],
    getEscalationCount,
    {
      enabled: selectedTitleCompany.id > 0,
    },
  );

  const loansRemaining = useMemo(
    () => datatableData.filter(row => !row.isResolved).length,
    [datatableData],
  );

  const { user } = useContext(AuthContext);

  const { errorToaster, infoToaster } = useToaster();
  const getDatatableData = async (id?: number) => {
    setIsLoading(true);

    let apiData = {};
    try {
      const timeout = setTimeout(() => {
        const currentTime = new Date();
        const localTimeBusinessHoursStart = new Date();
        localTimeBusinessHoursStart.setHours(8, 30, 0);
        const latestBusinessHoursStart = new Date();
        latestBusinessHoursStart.setHours(11, 30, 0);

        if (currentTime >= localTimeBusinessHoursStart && currentTime <= latestBusinessHoursStart) {
          infoToaster('Longer wait time may be due to title company timezone');
        }
      }, 7000);

      const { data } = await apiFetch<DatatableResponse>('/api/call-tasks/generate-task', {
        params: { id, idType: isClientMode ? IdType.client : IdType.titleCompany },
      });

      clearTimeout(timeout);

      apiData = data;
      const { report, errorsInformation } = data;

      setDatatableData(report.sort(sortDataByPhoneAndClient));

      // when selecting a random title company, we need to set the title company when we receive the results
      if (!id && report.length) {
        const firstRow = report[0];
        setSelectedTitleCompany({ id: firstRow.titleCompanyId, label: firstRow.titleCompany });
      }

      if (errorsInformation !== null) {
        setDatatableErrors(errorsInformation);
      }

      if (timeInterval) {
        clearInterval(timeInterval);
      }

      if (report.length) {
        const interval = setInterval(
          () =>
            setLocalTime(new Date().toLocaleTimeString('en-US', { timeZone: report[0].timeZone })),
          1000,
        );

        setTimeInterval(interval);
      }

      setIsUpdateRequired(localStorage.getItem(localStorageKeys.IS_REFRESH_REQUIRED) === 'true');
    } catch (e) {
      if (e.message === 'Token renewal operation failed due to timeout') {
        errorToaster('Authorization Error! Please refresh your browser.');
        return;
      }

      // Slack Webhook to DM Moshe Ritterman with the error information to figure out what's causing an error
      if (ENVIRONMENT === 'Production' || user?.mail === 'MRitterman@docprobe.net') {
        await axios.post(
          'https://hooks.slack.com/services/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',
          JSON.stringify({
            text: `*${location.pathname}${location.search}*
        Call Center api request params were: id=${id}&idType=${
              isClientMode ? IdType.client : IdType.titleCompany
            }
        The api response was: ${JSON.stringify(apiData)}
        The user that experienced this error was: *${user?.mail}*
        Error Message: *${e.message}*`,
          }),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const clearDatatable = () => {
    setDatatableData([]);
    setDatatableErrors([]);

    setSelectedClient(initialClientState);
    setSelectedTitleCompany({ id: 0, label: '' });

    setSidebarOption(undefined);
  };

  const getErrorForToaster = (error: AxiosError) => {
    if (!error.response) {
      return <Fragment>{error.message}</Fragment>;
    }

    if (error.response.data?.errors) {
      return (
        <Fragment>
          {error.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>
      );
    }

    const errorMessage = error.response.data.split('\n')[0];
    return <Fragment>{errorMessage || errorMessage.message}</Fragment>;
  };

  const logLoanEvent = async (
    urlPath: string,
    note: string | null = null,
    loanIds = selectedRows.map(row => row.id),
  ): Promise<number> => {
    setIsLoading(true);

    try {
      const { data: response } = await apiPost<number>(`/api/call-tasks/${urlPath}`, {
        loanIds,
        note,
      });

      setIsUpdateRequired(localStorage.getItem(localStorageKeys.IS_REFRESH_REQUIRED) === 'true');

      return response;
    } catch (e) {
      const errorMessage = getErrorForToaster(e);
      errorToaster(errorMessage);

      return 0;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DispositionsProvider>
      <Dashboard
        datatableData={datatableData}
        getDatatableData={getDatatableData}
        clearDatatable={clearDatatable}
        setIsClientMode={setIsClientMode}
        isClientMode={isClientMode}
        setSelectedClient={setSelectedClient}
        selectedClient={selectedClient}
        setSelectedTitleCompany={setSelectedTitleCompany}
        selectedTitleCompany={selectedTitleCompany}
        localTime={localTime}
        loansRemaining={loansRemaining}
        setIsModalOpen={setIsModalOpen}
        escalationCount={escalationCount || 0}
      />
      {Boolean(selectedTitleCompany.id || selectedClient.id || isLoading) && (
        <div className="df">
          <div style={{ flexGrow: 1, overflow: 'auto' }}>
            <Datatable
              datatableData={datatableData}
              isClientMode={isClientMode}
              isLoading={isLoading}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setSidebarOption={setSidebarOption}
            />
          </div>
          <div style={{ marginLeft: '-24px', marginTop: '8px' }}>
            <ResolveSidebarOptions
              setDatatableData={setDatatableData}
              datatableData={datatableData}
              setSidebarOption={setSidebarOption}
              sidebarOption={sidebarOption}
              selectedRows={selectedRows}
            />
          </div>
        </div>
      )}

      <ConfirmationModal
        logLoanEvent={logLoanEvent}
        getDatatableData={getDatatableData}
        datatableData={datatableData}
        selectedClient={selectedClient}
        isClientMode={isClientMode}
        setDatatableErrors={setDatatableErrors}
        datatableErrors={datatableErrors}
        loansRemaining={loansRemaining}
        clearDatatable={clearDatatable}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />

      <Snackbar
        open={isUpdateRequired}
        message="A new version of Docprobe has been detected. Click here to refresh"
        action={
          <Button color="error" size="small" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        }
      />
    </DispositionsProvider>
  );
};

export default CallCenter;
