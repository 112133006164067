import { CSSObject } from '@emotion/core';
import colors from '../../../styles/colors';

interface SidebarStylesMap {
  [prop: string]: CSSObject;
}

export default <SidebarStylesMap>{
  outerContainer: {
    backgroundColor: colors.white,
    borderRight: `1px solid ${colors.grayLight}`,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    margin: 0,
    paddingBottom: 10,
    position: 'fixed',
    justifyContent: 'space-between',
    top: 0,
    transition: 'all .15s',
    zIndex: 500,
    width: 72,
    '@media print': { display: 'none' },
  },
  containerExpanded: {
    borderRight: `1px solid ${colors.grayLight}`,
    width: '256px',
  },
  innerContainer: {
    padding: '0 12px',
    marginTop: 23,
    overflow: 'auto',
    maxHeight: 'calc(100vh - 159px)',
    height: '100%',
  },
  headCont: {
    alignItems: 'center',
    display: 'flex',
    height: 50,
    marginBottom: 23,
    marginLeft: 11,
    marginTop: 24,
    position: 'relative',
    fontWeight: 500,
  },
  siteInitials: {
    fontSize: 24,
    marginLeft: '11px',
  },
  dpLogo: {
    display: 'block',
    fontSize: 24,
    marginLeft: 11,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  taskHead: {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0 24px 16px 16px',
    height: 38,
    whiteSpace: 'nowrap',
  },
  taskCount: {
    alignItems: 'center',
    backgroundColor: colors.red,
    borderRadius: '50%',
    display: 'flex',
    fontSize: 9,
    fontWeight: 'bold',
    height: 24,
    justifyContent: 'center',
    position: 'absolute',
    right: 20,
    testAlign: 'center',
    top: 12,
    width: 24,
  },

  // nav item
  taskStyle: {
    borderRadius: 24,
    color: colors.black,
    display: 'block',
    fontSize: 14,
    fontWeight: 'bold',
    height: 48,
    width: 48,
    position: 'relative',
    transition: 'all .15s',
    whiteSpace: 'nowrap',
    border: '1px solid transparent',
    ' span': { paddingBottom: 2 },
    ':hover': {
      border: `1px solid ${colors.blueLight}`,
      borderRadius: 24,
    },
  },

  activeTask: {
    backgroundColor: colors.blueLight,
    color: colors.white,
    '&:before': { display: 'none' },
    ' span': { color: colors.white },
    ' div': { color: colors.white },
    ' svg': { color: colors.white },
  },
  sectionStyle: {
    color: colors.black,
    display: 'block',
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: 16,
    padding: '15px 0',
    ':hover': { color: colors.blue },
  },
  iconWrapper: {
    height: 48,
    width: 48,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  taskImg: { margin: 'auto' },

  textWrap: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'space-between',
    marginTop: 12,
    paddingRight: 16,
    verticalAlign: 'top',
    width: 'calc(100% - 48px)',
  },

  countCircle: {
    backgroundColor: colors.red,
    borderRadius: 4,
    color: colors.white,
    fontWeight: 700,
    padding: '1px 4px',
    fontSize: 11,
  },
  taskLinkStyles: {
    height: 'auto',
    position: 'relative',
  },

  firstLinks: {},
  secondLinks: {
    marginTop: 8,
    '& .more-link-list': {
      height: 0,
      overflow: 'hidden',
      borderBottom: '1px solid #c7c7cc',
    },
  },
  moreLinksListOpen: {
    '&&': {
      height: 'auto',
    },
  },
};
