/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import Dispositions from '../CallCenter/SidebarSections/Dispositions';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { LatestDisposition as Disposition } from './types';
import { OutreachDisposition } from '../../globalTypes/objects';
import { apiPost } from '../../adalConfig';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useToaster } from '../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: 'gray',
    paddingBottom: '6px',
  },
  paper: {
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  noteContainer: {
    border: '1px solid black',
    borderRadius: '6px',
    padding: '4px',
  },
  textareaWrap: {
    width: '100%',
    resize: 'none',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
  },
}));

type Props = {
  loanId: number;
  latestDisposition?: Disposition;
  updateDisposition: (disposition: OutreachDisposition) => void;
};

const LatestDisposition = ({ loanId, latestDisposition, updateDisposition }: Props) => {
  const [isSettingDisposition, setIsSettingDisposition] = useState(false);
  const [selectedDisposition, setSelectedDisposition] = useState<OutreachDisposition | null>(null);
  const [deferDate, setDeferDate] = useState<Date | null>(null);
  const [note, setNote] = useState('');
  const [internalNote, setInternalNote] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { errorToaster } = useToaster();
  const classes = useStyles();

  const dispositionChangeHandler = (disposition: OutreachDisposition | null) => {
    setDeferDate(null);
    setSelectedDisposition(disposition);
  };

  const submitDisposition = async (
    selectedDisposition: OutreachDisposition | null,
    loanIds: number[],
  ) => {
    if (!selectedDisposition) {
      return;
    }

    setIsSubmitting(true);

    try {
      await apiPost('/api/call-tasks/add-outreach-disposition', {
        outreachDispositionId: selectedDisposition.id,
        loanIds,
        deferUntil: deferDate?.toLocaleDateString('en-CA'),
        note,
        internalNote,
      });

      updateDisposition(selectedDisposition);
      setIsSettingDisposition(false);
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.message);
        return;
      }

      errorToaster(
        <Fragment>
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isSubmitting) {
    return (
      <div className="center-in-parent" style={{ height: '90px' }}>
        <CircularProgress size="20" disableShrink />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.label}>
        <span>Latest Disposition</span>
        <EditIcon
          color="primary"
          style={{ paddingLeft: '5px' }}
          onClick={() => setIsSettingDisposition(x => !x)}
        />
      </div>
      {!!latestDisposition && (
        <Fragment>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'rgb(0, 0, 0, .05)',
            }}
          >
            Stage {latestDisposition.stage}
          </div>
          <p>{latestDisposition.dispositionReason}</p>
        </Fragment>
      )}

      <Modal open={isSettingDisposition} onClose={() => setIsSettingDisposition(false)}>
        <div className={classes.paper}>
          <div className="df col jcfe" style={{ gap: '8px' }}>
            <Dispositions
              selectedDisposition={selectedDisposition}
              dispositionChangeHandler={dispositionChangeHandler}
              deferDate={deferDate}
              setDeferDate={setDeferDate}
            />

            {selectedDisposition !== null && (
              <div>
                <FormControl sx={{ width: 1 }}>
                  <FormLabel>Disposition Note</FormLabel>
                  <div className={classes.noteContainer}>
                    <TextareaAutosize
                      minRows={3}
                      aria-label="maximum height"
                      placeholder="Type here"
                      value={note}
                      onChange={e => setNote(e.target.value)}
                      className={classes.textareaWrap}
                    />
                  </div>
                </FormControl>

                <hr />
                <FormControl sx={{ width: 1 }}>
                  <FormLabel>Internal Note (Optional)</FormLabel>
                  <div className={classes.noteContainer}>
                    <TextareaAutosize
                      minRows={3}
                      aria-label="maximum height"
                      placeholder="Add internal note here"
                      value={internalNote}
                      onChange={e => setInternalNote(e.target.value)}
                      className={classes.textareaWrap}
                    />
                  </div>
                </FormControl>
              </div>
            )}

            <div style={{ alignSelf: 'end' }}>
              <Button
                disabled={selectedDisposition === null}
                onClick={() => submitDisposition(selectedDisposition, [loanId])}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default LatestDisposition;
