import axios, { CancelTokenSource } from 'axios';
import { stringifyUrl } from 'query-string';
import { SortingRule } from 'react-table';
import { useQuery } from '@tanstack/react-query';
import { DocumentDetails } from '../types/document';
import { getToken } from '../../../auth-config';

type XhrState = {
  xhr: CancelTokenSource | undefined;
  setXhr: (value) => void;
};

export interface Results {
  data: DocumentDetails[];
  totalCount: number;
}

function fetchDocuments(
  page: number = 1,
  pageSize: number = 25,
  filter: {},
  searchString: string,
  isExport: boolean = false,
  sortBy: SortingRule<string>[],
  xhrState: () => XhrState,
) {
  const url = stringifyUrl({
    url: '/Api/DocumentCatchAll',
    query: {
      page: page.toString(),
      pageSize: pageSize.toString(),
      ...filter,
      searchString,
      isExport: isExport.toString(),
      sortBy: JSON.stringify(sortBy),
    },
  });

  return async () => {
    const { xhr, setXhr } = xhrState();
    if (xhr) {
      xhr.cancel();
    }

    const ajaxRequest = axios.CancelToken.source();
    setXhr(ajaxRequest);

    const token = await getToken();
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        cancelToken: ajaxRequest.token,
      })
      .then(res => {
        setXhr(undefined);
        return res.data;
      });
  };
}

export default function (
  page: number,
  pageSize: number,
  filter: {},
  searchString: string,
  isExport: boolean,
  sortBy: SortingRule<string>[],
  xhrState: () => XhrState,
) {
  return useQuery<Results, Error>(
    ['document-catch-all', { page, pageSize, filter, searchString, isExport, sortBy, xhrState }],
    fetchDocuments(page, pageSize, filter, searchString, isExport, sortBy, xhrState),
    { keepPreviousData: true, refetchOnWindowFocus: false },
  );
}
