/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';
import colors from '../../../../../styles/colors';

// #region CSS

const circleColumn: CSSObject = {
  position: 'absolute',
  top: 0,
  right: 0,
};

const p: CSSObject = {
  margin: 0,
  padding: 0,
  display: 'block',
  marginBottom: 4,
};

const title: CSSObject = {
  fontWeight: 'bold',
  position: 'relative',
  boxSizing: 'border-box',
  marginBottom: 16,
  height: 42,
};

const addressCon: CSSObject = {
  marginBottom: 22,
  height: 42,
};

const contact: CSSObject = {
  position: 'relative',
  width: '100%',
  height: 42,
};

const emailField: CSSObject = {
  position: 'absolute',
  right: 0,
  bottom: 0,
};

const circleStyles: CSSObject = {
  borderRadius: '50%',
  display: 'inline-block',
  height: '48px',
  width: '48px',
  backgroundColor: 'white',
  border: `1px solid ${colors.grayLight}`,
  margin: 0,
  transition: 'border .2s, background-color .2s',
};

const tileStyles: CSSObject = {
  fontSize: 14,
  width: '100%',
  height: 224,
  borderRadius: 4,
  display: 'inline-block',
  padding: '33px 33px',
  marginBottom: 8,
  border: `1px solid ${colors.grayLight}`,
  backgroundColor: colors.grayExLight,
};

const skeleton = (width: number): CSSObject => ({
  width,
  height: 16,
  borderRadius: 15.5,
  backgroundColor: colors.white,
  marginBottom: 4,
});

// #endregion

export default function SuggestionTileSkeleton() {
  return (
    <div css={tileStyles} data-test="match unidentified">
      <div css={title}>
        <p css={{ ...skeleton(315), ...p }} />
        <p css={{ ...skeleton(315), ...p }} />
        <div css={circleColumn}>
          <p css={circleStyles} />
        </div>
      </div>
      <div css={addressCon}>
        <p css={{ ...skeleton(315), ...p }} />
        <p css={{ ...skeleton(315), ...p }} />
      </div>
      <div css={contact}>
        <p css={{ ...skeleton(105), ...p }} />
        <p css={{ ...skeleton(105), ...p }} />
        <div css={{ ...skeleton(252), ...emailField }} />
      </div>
    </div>
  );
}
