/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import { format } from 'date-fns';
import ActionCircle from '../../../../ui/ActionCircle';
import Button from '../../../../ui/Button';
import UpdateNoteModal from './UpdateNoteModal';
import ToolTip from '../../../../ui/ToolTip';
import colors from '../../../../../styles/colors';
import { apiPost } from '../../../../../adalConfig';
import { IconType } from '../../../../ui/Icon';
import { Note } from '../../../../../globalTypes/objects';

// #region css
const noteBarWrap: CSSObject = {
  alignItems: 'center',
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  display: 'flex',
  minHeight: 80,
  justifyContent: 'space-between',
  marginTop: 16,
  padding: '16px 16px 16px 24px',
  position: 'relative',
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': { border: `1px solid ${colors.blue}` },
};

const overlay: CSSObject = {
  position: 'relative',
  transition: 'all .2s',
  '&:hover': { border: `1px solid ${colors.grayLight}` },
  ' :before': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    content: '""',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 10,
  },
};

const deleteButtonsWrap: CSSObject = {
  zIndex: 20,
  position: 'absolute',
  textAlign: 'center',
  width: 'calc(100% - 40px)',
  pointerEvents: 'all',
};

const systemGeneratedStyles: CSSObject = {
  display: 'block',
  '&:hover': { border: `1px solid ${colors.grayLight}`, cursor: 'auto' },
};
// #endregion

const deleteNote = async (id: number) => {
  await apiPost(`/api/loanErrors/deleteNote/${id}`, {});
};

type NoteBarProps = {
  setDisabled: (action: boolean) => any;
  refresh: () => any;
  note: Note;
};
type NoteBarState = { isDelete: boolean; isUpdateNote: boolean };
export default class NoteBar extends Component<NoteBarProps, NoteBarState> {
  state = {
    isDelete: false,
    isUpdateNote: false,
  };

  onDeleteClick = async () => {
    await deleteNote(this.props.note.id);
    this.setState({ isDelete: false });
    this.props.setDisabled(false);
    this.props.refresh();
  };

  render() {
    const { isDelete, isUpdateNote } = this.state;
    const {
      note: { note, createdAt, user, systemGenerated },
      setDisabled,
      refresh,
    } = this.props;

    if (systemGenerated) {
      return (
        <div css={[noteBarWrap, systemGeneratedStyles]}>
          <div>{note}</div>
          <p css={{ color: colors.grayDark, marginTop: 8 }}>
            Sent {user && ` by${user}`} on{' '}
            {format(new Date(Date.parse(createdAt)), 'MMMM d, yyyy h:mma')}
          </p>
        </div>
      );
    }
    return (
      <Fragment>
        {!isUpdateNote ? (
          <div
            css={[noteBarWrap, isDelete && overlay]}
            onClick={() => {
              if (!systemGenerated) {
                this.setState({ isUpdateNote: true });
                setDisabled(true);
              }
            }}
          >
            <div css={{ width: 336 }}>
              {note}
              <p css={{ color: colors.grayDark, marginTop: 8 }}>
                {systemGenerated ? 'Sent' : 'Added'}
                {user && ` by${user}`} on{' '}
                {format(new Date(Date.parse(createdAt)), 'MMMM d, yyyy h:mma')}
              </p>
            </div>

            {!systemGenerated && !isDelete && (
              <ToolTip toolTipText="Delete note">
                <div>
                  <ActionCircle
                    onClick={e => {
                      e.stopPropagation();
                      this.setState({ isDelete: true });
                      setDisabled(true);
                    }}
                    icon={IconType.TrashCan}
                  />
                </div>
              </ToolTip>
            )}

            {isDelete && (
              <div css={deleteButtonsWrap}>
                <Button
                  secondary
                  onClick={e => {
                    e.stopPropagation();
                    this.setState({ isDelete: false });
                    setDisabled(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  styleOverrides={{ marginLeft: 24 }}
                  onClick={e => {
                    e.stopPropagation();
                    this.onDeleteClick();
                  }}
                >
                  Delete
                </Button>
              </div>
            )}
          </div>
        ) : (
          <UpdateNoteModal
            note={this.props.note}
            handleClose={() => {
              this.setState({ isUpdateNote: false });
              refresh();
              setDisabled(false);
            }}
          />
        )}
      </Fragment>
    );
  }
}
