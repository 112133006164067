/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { DownArrowIcon } from '../ui/icons';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Barcode from 'react-barcode';
import * as QRCode from 'qrcode.react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      backgroundColor: '#fff !important',
      display: 'flex',
      flexDirection: 'column',
      height: '1056px', // 11 inches
      width: '816px', // 8.5 inches
      margin: 0,
      padding: 0,
      position: 'relative',
    },
    upsideDownSection: {
      transform: 'rotate(-180deg)',
      textAlign: 'center',
      fontSize: 60,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      top: 24,
      width: '100%',
    },
    arrow: {
      width: 48,
      height: 48,
    },
    companyName: {
      fontSize: 24,
      lineHeight: 1.2,
      fontWeight: 600,
      margin: '32px 0px',
    },
    documentDetails: {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gap: 8,
      lineHeight: '36px',
      fontSize: 22,
      '& > *:nth-child(2n+1)': { textAlign: 'right' },
      '& > *:nth-child(2n+2)': { fontWeight: 700 },
    },
    labelPlaceholder: {
      position: 'relative',
      width: '384px', // 4 inches
      height: '192px', // 2 inches
      marginTop: '40px',
      marginLeft: '96px',
      '&::before, &::after, & > :first-child::before, & > :first-child::after': {
        position: 'absolute',
        width: '35px',
        height: '35px',
        borderColor: 'black',
        borderStyle: 'solid',
        content: `''`,
      },
      '&::before': {
        top: 0,
        left: 0,
        borderWidth: '1px 0 0 1px',
      },
      '&::after': {
        top: 0,
        right: 0,
        borderWidth: '1px 1px 0 0',
      },
      '& > :first-child::before': {
        bottom: 0,
        right: 0,
        borderWidth: '0 1px 1px 0',
      },
      '& > :first-child::after': {
        bottom: 0,
        left: 0,
        borderWidth: '0 0 1px 1px',
      },
    },
    codeSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  }),
);

type Props = {
  documentId: number;
  clientName: string;
  documentType: string;
};

const PrePrintedCoversheet = ({ documentId, clientName, documentType }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.upsideDownSection}>
        <DownArrowIcon className={classes.arrow} />
        <div style={{ lineHeight: '45px', marginBottom: 16 }}>
          <div style={{ fontSize: 32 }}>{new Date().toLocaleDateString()}</div>
          <div>{documentId}</div>
        </div>
        <DownArrowIcon className={classes.arrow} />
      </div>

      <div className={classes.companyName}>DOCPROBE</div>
      <div>
        <div className={classes.documentDetails}>
          <p>Client:</p>
          <p>{clientName}</p>
          <p>Document Type:</p>
          <p>{documentType}</p>
        </div>
        <div className={classes.labelPlaceholder}>
          <p></p>
        </div>
      </div>
      <div className={classes.codeSection}>
        <div className="df jcc mt4">
          <Barcode value={`docprobe-${documentId.toString()}`} />
        </div>
        <div className="df jcfe">
          <QRCode
            value={`docprobe-${documentId.toString()}`}
            size={128}
            includeMargin
            className="mr4"
          />
        </div>
      </div>
    </div>
  );
};

export default PrePrintedCoversheet;
