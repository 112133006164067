import React, { createContext, useContext, useMemo, useState } from 'react';
import { UpdateContactInfoData } from '../../CallCenter/UpdateContactInfoPanel';
import { DocumentType } from '../../../globalTypes/objects';
import { Note } from '../../CallCenter/types';

export type LoanEmailData = {
  loanId: number;
  loanNumber: string;
  email: string;
  missingDocuments: DocumentType[];
  clientId: number;
  emailToUse?: string;
  titlePortalEmails?: string[];
};

export type SendFollowupsMessage = {
  clientId: number;
  email: string;
  additionalEmails: string[];
  loansWithDocs: { [loanNumber: number]: DocumentType[] };
};

export type TitlePortalMessage = {
  email: string;
  loansWithDocs: { [loanNumber: number]: DocumentType[] };
};

export type ManualFollowUpsProps = {
  loans: LoanEmailData[];
  closeEmails: () => void;
  titleCompanyEmail: string;
  titleCompanyId: number;
  updateContactInfoData: (updatedInfo: UpdateContactInfoData) => void;
  allEmails: string[];
  updateNotesForSpecificRows: (notes: Note[]) => void;
  updateTitlePortalContacts: (contacts: LoanContact[]) => void;
};

export type ManualFollowupsContext = {
  isOverride: boolean;
  isUpdate: boolean;
  updateLocal: boolean;
  updateGlobal: boolean;
  validatedOverrideEmail: string;
  sendToInvalidOverride: boolean;
  sendToInvalid: boolean;
  sendToGlobal: boolean;
  emails: EmailWithStatus[];
  emailsSent: boolean;
  hardInvalidsDownloaded: boolean;
  isAdditionalRecipient: boolean;
  validatedAdditionalRecipients: string[];
  titlePortalMode: boolean;
  selectedTitlePortalEmails: EmailWithStatusAndContact[];
};

export enum EmailStatus {
  Valid,
  SoftInvalid,
  HardInvalid,
}

export type EmailWithStatus = {
  email: string;
  status: EmailStatus;
};

export type EmailWithStatusAndContact = EmailWithStatus & {
  contactId?: number;
};

export type LoanContact = {
  loanId: number;
  contactId?: number;
  email: string;
};

type ManualFollowupsContextValue = [
  ManualFollowupsContext,
  (updateManualFollowups: Partial<ManualFollowupsContext>) => void,
];

export const initialState: ManualFollowupsContext = {
  isOverride: false,
  isUpdate: false,
  updateLocal: false,
  updateGlobal: false,
  validatedOverrideEmail: '',
  sendToInvalidOverride: false,
  sendToInvalid: false,
  sendToGlobal: false,
  emails: [],
  emailsSent: false,
  hardInvalidsDownloaded: false,
  isAdditionalRecipient: false,
  validatedAdditionalRecipients: [],
  titlePortalMode: false,
  selectedTitlePortalEmails: [],
};

const ManualFollowupsContext = createContext({} as ManualFollowupsContextValue);

function ManualFollowupsProvider(props) {
  const [manualFollowups, setManualFollowups] = useState<ManualFollowupsContext>(initialState);

  const value = useMemo(() => {
    const setManualFollowupsFunc = (obj: Partial<ManualFollowupsContext>) => {
      setManualFollowups(originalValue => ({ ...originalValue, ...obj }));
    };

    return [manualFollowups, setManualFollowupsFunc];
  }, [manualFollowups]);

  return <ManualFollowupsContext.Provider value={value} {...props} />;
}

function useManualFollowups() {
  const context = useContext(ManualFollowupsContext);

  if (!context) {
    throw new Error('useManualFollowups must be used within a ManualFollowupsProvider');
  }

  return context;
}

export { ManualFollowupsProvider, useManualFollowups };
