import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  barcode: number;
  documentType: string;
  client: string;
};

const columns = [
  {
    field: 'barcode',
    title: 'Barcode',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'client',
    title: 'Client',
  },
];

const WaitingToBeBroadMatchedDrillDownReport = () => (
  <DataTable<RowData>
    title="Waiting To Be Broad Matched Drill Down Report"
    columns={columns}
    url="/api/reports/broad-matching-drill-down-report"
  />
);

export default WaitingToBeBroadMatchedDrillDownReport;
