/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
  DocumentShipFormat,
  ShipFormat,
  ShipmentDTO,
  ShippingContext,
  ShippingDoc,
  useShipping,
} from './useShipping';
import { StepButton, StepComponentPanel } from '../Utilities/StepUI/StepPanel';
import Button from '../ui/Button';
import { apiPost } from '../../adalConfig';
import { usePrevious } from '../../Hooks';
import { ClientModal, ShippingClient } from './ClientModal';
import { StatusTypes } from '../ui/StatusButton';
import { SuccessTimeout } from '../Imports/ImportErrors/ImportErrorsUtils';
import Tab, { TabGroup } from '../ui/Tabs/Tab';
import PhysicalDocs from './StepTwoPhysical';
import DigitalDocs from './StepTwoDigital';
import PrintingOverlay from './PrintingOverlay';
import PrinterDropdown from '../Utilities/Dropdowns/PrinterDropdown';
import { openOrDownloadFile } from '../../Utils';
import ShippingClientsContext, {
  SHIPPING_CLIENT_LS_KEY,
  ShippingClientsByInvestor,
} from './ShippingClientsContext';
import { useToaster } from '../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocalStorage } from '@rehooks/local-storage';

enum Mode {
  Physical,
  Digital,
}

export const printCoverletterFiles = async (shipping: ShippingContext, docIds: number[]) => {
  const { investor, trackingNum } = shipping;
  const printerId = shipping.printer!.id;
  const shipmentObj: ShipmentDTO<number> = {
    docs: docIds,
    trackingNum,
    investorId: investor.id,
    printerId,
  };
  const { data } = await apiPost('/api/shipping/coverletter', shipmentObj);
  downloadPdf(data);
};

const downloadPdf = async (data: any) => {
  if (ENVIRONMENT === 'Development') {
    const url = await base64ToObjectURL(data.contentType, data.fileContents);
    openOrDownloadFile(url, data.fileDownloadName);
  }
};

const base64ToObjectURL = async (contentType: string, fileContents: string): Promise<string> => {
  const url = `data:${contentType};base64,${fileContents}`;
  const blobPromise = await fetch(url);
  const blob = await blobPromise.blob();

  return window.URL.createObjectURL(blob);
};

export async function printTransmittal<T>(transmittalDTO: ShipmentDTO<T>) {
  const { data } = await apiPost('/api/shipping/transmittal', transmittalDTO);
  downloadPdf(data);
}

export async function printDigitals<T>(digitalDTO: ShipmentDTO<T>) {
  const { data } = await apiPost('/api/shipping/digitals', digitalDTO);
  downloadPdf(data);
}

export const docBox = {
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  height: 80,
  overflow: 'auto',
  width: 320,
  padding: '8px 16px',
  minHeight: 190,
  maxHeight: 250,
};

export default function StepTwo() {
  const [shipping, setShipping] = useShipping();
  const [client, setClient] = useState<ShippingClient>({} as ShippingClient);
  const [mode, setMode] = useState(Mode.Physical);
  const [clientModal, setClientModal] = useState<ClientModal>({
    clients: [],
    show: false,
    selected: {},
  });
  // keep track of loans for transmittals
  const [coverletterCount, setCoverletterCount] = useState(0);
  const [printing, setPrinting] = useState(false);
  const [buttonStatus, setButtonStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { getShippingClients } = useContext(ShippingClientsContext);

  const { errorToaster } = useToaster();
  const [investorData, setInvestorData] = useLocalStorage<ShippingClientsByInvestor>(
    SHIPPING_CLIENT_LS_KEY,
    [],
  );

  const prevShipping = usePrevious<ShippingContext>(shipping);
  const prevCount = usePrevious<number>(coverletterCount);

  const printCoverletter = useCallback(async () => {
    const { investor, physicalDocs: documents } = shipping;
    const docIds = investor.manifestMaximum
      ? // if printing an investor transmittal only submit number of docs that have been created
        // since last coverletter
        documents.map(d => d.docId).slice(-coverletterCount)
      : // if printing individual coverletters the docIds are reversed so that
        // the coverletters are printed in reverse order to allow for easier collating
        documents.map(d => d.docId).reverse();
    await printCoverletterFiles(shipping, docIds);
  }, [coverletterCount, shipping]);

  // increment coverletterCount for investor transmittals
  useEffect(() => {
    if (prevShipping && prevShipping.physicalDocs.length < shipping.physicalDocs.length) {
      if (shipping.investor.manifestMaximum) {
        setCoverletterCount(c => c + 1);
      }
    }
  }, [
    prevShipping,
    shipping.physicalDocs.length,
    shipping.investor.coverletterBasePath,
    shipping.investor.manifestMaximum,
  ]);

  const print = useCallback(async () => {
    setPrinting(true);
    setCoverletterCount(0);
    await printCoverletter();
    setPrinting(false);
  }, [printCoverletter]);

  // print investor transmittals when reaching transmittal maximum
  useEffect(() => {
    if (
      prevCount !== coverletterCount &&
      shipping.investor.manifestMaximum &&
      shipping.investor.manifestMaximum === coverletterCount
    ) {
      print();
    }
  }, [coverletterCount, printCoverletter, prevCount, shipping, print]);

  const [loadingClients, setLoadingClients] = useState(false);
  const setupClientModal = async () => {
    setLoadingClients(true);
    // investor manifests should not have multiple clients on it
    // so before adding a client print the investor manifest
    // for any docs that haven't been printed on a manifest yet
    if (shipping.investor.manifestMaximum && coverletterCount > 0) {
      print();
    }
    const shippingClients = await getShippingClients(shipping.investor.id);
    setClientModal({ ...clientModal, clients: shippingClients, show: true });
    setLoadingClients(false);
  };

  const handleModalSave = () => {
    setClient(clientModal.selected as ShippingClient);
    setClientModal({ ...clientModal, show: false, selected: {} });
  };

  const createShipment = async () => {
    setButtonStatus(StatusTypes.loading);
    const { physicalDocs, digitalDocs, trackingNum, investor } = shipping;
    try {
      const createShipmentDTO: ShipmentDTO<number> = {
        docs: [...physicalDocs.map(d => d.docId), ...digitalDocs.map(d => d.docId)],
        trackingNum,
        investorId: investor.id,
        printerId: shipping.printer!.id,
      };

      // print physical individual coverletters
      if (
        (physicalDocs.length > 0 && investor.coverletterBasePath && !investor.manifestMaximum) ||
        (investor.manifestMaximum && coverletterCount > 0)
      ) {
        await printCoverletter();
      }

      // print digitals
      if (digitalDocs.length > 0) {
        const digitalDTO: ShipmentDTO<ShippingDoc> = { ...createShipmentDTO, docs: digitalDocs };
        await printDigitals(digitalDTO);
      }

      // print DP transmittal
      const transmittalDTO: ShipmentDTO<ShippingDoc> = {
        ...createShipmentDTO,
        docs: [...physicalDocs, ...digitalDocs],
      };

      await printTransmittal(transmittalDTO);

      // update db
      const createShipmentData: ShipmentDTO<DocumentShipFormat> = {
        docs: [
          ...physicalDocs.map<DocumentShipFormat>(d => ({
            documentId: d.docId,
            shipFormat: ShipFormat.Physical,
          })),
          ...digitalDocs.map<DocumentShipFormat>(d => ({
            documentId: d.docId,
            shipFormat: ShipFormat.Digital,
          })),
        ],
        trackingNum,
        investorId: investor.id,
        printerId: shipping.printer!.id,
      };
      await apiPost('/api/shipping/createshipment', createShipmentData);

      updateLocalStorage(investor.id, physicalDocs, digitalDocs);

      setButtonStatus(StatusTypes.success);
      setTimeout(() => {
        setShipping({ currentStep: 3 });
      }, SuccessTimeout);
    } catch (e) {
      setButtonStatus(StatusTypes.error);

      if (!e.response) {
        errorToaster(e.message);
        return;
      }

      if (e.response.data?.errors) {
        errorToaster(
          <Fragment>
            {e.response.data.errors.map(error => {
              return <p key={error.fieldName + error.message}>{error.message}</p>;
            })}
          </Fragment>,
        );
        return;
      }

      const errorMessage = e.response.data.split('\n')[0];
      errorToaster(errorMessage || e.message);
    }
  };

  const updateLocalStorage = (
    investorId: number,
    physicalDocs: ShippingDoc[],
    digitalDocs: ShippingDoc[],
  ) => {
    const physicalShipCount = getDocsShipCountPerClient(physicalDocs);
    const digitalShipCount = getDocsShipCountPerClient(digitalDocs);

    const newInvestorData = investorData[investorId].shippingClients.map(shippingClient => {
      const clientPhysicalShipCount = physicalShipCount.get(shippingClient.id);
      const clientDigitalShipCount = digitalShipCount.get(shippingClient.id);
      if (clientPhysicalShipCount === undefined && clientDigitalShipCount === undefined) {
        return shippingClient;
      }

      shippingClient.physicalAmount -= clientPhysicalShipCount ?? 0;
      shippingClient.digitalAmount -= clientDigitalShipCount ?? 0;

      return shippingClient;
    });

    setInvestorData({
      ...investorData,
      [investorId]: {
        timeOfFetch: investorData[investorId].timeOfFetch,
        shippingClients: newInvestorData,
      },
    });
  };

  const getDocsShipCountPerClient = (docs: ShippingDoc[]) =>
    docs.reduce((map: Map<number, number>, shippingDoc: ShippingDoc) => {
      const clientDocCount = map.get(shippingDoc.clientId);
      if (clientDocCount === undefined) {
        map.set(shippingDoc.clientId, 1);
        return map;
      }

      map.set(shippingDoc.clientId, clientDocCount + 1);
      return map;
    }, new Map<number, number>());

  const button = () => {
    return (
      <StepButton
        text="Close Shipment"
        status={buttonStatus}
        disabled={shipping.physicalDocs.length === 0 && shipping.digitalDocs.length === 0}
        onClick={createShipment}
      />
    );
  };

  return (
    <Fragment>
      <StepComponentPanel
        headerText="Documents"
        number={2}
        button={button}
        overrideStyles={{ position: 'relative' }}
      >
        <div className="half">
          <span className="db mt4 mb1">Choose printer</span>
          <PrinterDropdown onChange={p => setShipping({ printer: p })} />
        </div>
        {client.client && (
          <div>
            <h3 className="mt3 bold">{client.client}</h3>
            <br />
            <TabGroup>
              <Tab
                onClick={() => {
                  setMode(Mode.Physical);
                }}
                number={shipping.physicalDocs.filter(d => d.clientId === client.id).length}
              >
                Physical
              </Tab>
              <Tab
                number={shipping.digitalDocs.filter(d => d.clientId === client.id).length}
                onClick={() => {
                  setMode(Mode.Digital);
                }}
              >
                Digital
              </Tab>
            </TabGroup>
            {mode === Mode.Physical ? (
              <PhysicalDocs
                docs={shipping.physicalDocs}
                client={client}
                investor={shipping.investor}
                setDoc={doc => setShipping({ physicalDocs: [...shipping.physicalDocs, doc] })}
                removeDoc={documentId =>
                  setShipping({
                    physicalDocs: shipping.physicalDocs.filter(doc => doc.docId !== documentId),
                  })
                }
              />
            ) : (
              <DigitalDocs client={client} investor={shipping.investor} />
            )}
          </div>
        )}
        {loadingClients ? (
          <div className="center-in-parent" style={{ height: '40px', width: '80px' }}>
            <CircularProgress size="20" disableShrink />
          </div>
        ) : (
          <Button
            secondary
            onClick={() => setupClientModal()}
            styleOverrides={{ marginTop: 32 }}
            disabled={!shipping.printer}
          >
            Add Client
          </Button>
        )}
        {printing && <PrintingOverlay />}
      </StepComponentPanel>
      {clientModal.show && (
        <ClientModal
          setClientModal={setClientModal}
          handleModalSave={handleModalSave}
          clientModal={clientModal}
        />
      )}
    </Fragment>
  );
}
