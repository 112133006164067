/** @jsx jsx */
import { jsx } from '@emotion/core';
import DataTable from '../../ui/DataTable/DataTable';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Fragment, useState } from 'react';
import { apiPost } from '../../../adalConfig';
import { parseRequestError } from '../../../Utils';
import { useToaster } from '../../../Hooks/toasters';

type RowData = {
  id: number;
  client: string;
  datePaid: string;
  paymentAmount: number;
  checkNumber: string;
  canDelete: boolean;
};

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const columns = [
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'datePaid',
    title: 'Date Paid',
    render: ({ datePaid }: RowData) => new Date(datePaid).toLocaleDateString(),
  },
  {
    field: 'paymentAmount',
    title: 'Payment Amount',
    render: ({ paymentAmount }: RowData) => currencyFormat.format(paymentAmount),
  },
  {
    field: 'checkNumber',
    title: 'Check Number',
  },
];

const ViewPayments = () => {
  const [confirmDeleteRow, setConfirmDeleteRow] = useState<RowData | null>(null);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});

  const { successToaster, errorToaster } = useToaster();

  const deletePayment = async () => {
    try {
      await apiPost('/api/charge/delete-payment', { id: confirmDeleteRow?.id });
      successToaster('Successfully deleted payment');
      refreshDatatable();
      setConfirmDeleteRow(null);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    }
  };

  return (
    <Fragment>
      <div style={{ marginLeft: '-32px' }}>
        <DataTable<RowData>
          title="Payments"
          columns={columns}
          url="/api/charge/payments"
          orderBy={{ field: 'datePaid' }}
          orderDirection="desc"
          onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
          actions={() => [
            (rowData: RowData) => ({
              icon: () => <DeleteIcon style={{ color: 'red' }} />,
              tooltip: 'Delete payment',
              onClick: (event, rowData) => setConfirmDeleteRow(rowData),
              hidden: !rowData.canDelete,
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
          }}
        />
      </div>

      <Dialog open={confirmDeleteRow !== null} onClose={() => setConfirmDeleteRow(null)}>
        <DialogTitle>Delete Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm you want to delete a payment of{' '}
            {currencyFormat.format(confirmDeleteRow?.paymentAmount || 0)} from{' '}
            {confirmDeleteRow?.client} made on{' '}
            {new Date(confirmDeleteRow?.datePaid || Date.now()).toLocaleDateString()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteRow(null)} autoFocus>
            Cancel
          </Button>
          <Button onClick={deletePayment}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ViewPayments;
