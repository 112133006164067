import React from 'react';
import { css, Global } from '@emotion/core';

const styles = css`
    /* =Reset default browser CSS. Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html ----------------------------------------------------- */

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    font,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
        background: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
        font-weight: inherit;
    }

    .react-contextmenu-item,
    .react-contextmenu--visible {
        position: absolute;
        bottom: 56px;
        width: 100px;
        left: 20px;
    }
    body {
        line-height: 1;
    }

    ol,
    ul {
        list-style: none;
    }
    /* tables still need 'cellspacing="0"' in the markup */
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    input,
    select {
        margin-right: 0;
        margin-left: 0;
    }

    button {
        vertical-align: middle;
        outline: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    /* base styles */

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    html,
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        color: #000000;
        font-size: 14px;
        line-height: 1.5;
        max-width: 100%;
        min-width: 320px;
        width: 100%;
        -webkit-overflow-scrolling: touch;
        -webkit-text-size-adjust: none;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        background-color: #f7f7f9;
        font-family: aktiv-grotesk, sans-serif;
    }

    a {
        color: #000000;
        cursor: pointer;
        text-decoration: none;
    }

    b {
        font-weight: 600;
    }

    li {
        list-style: none;
    }
    img {
        display: block;
        max-width: 100%;
    }

    form {
        position: relative;
    }

    input,
    textarea {
        border: none;
        font-size: 15px;
        padding: 10px;
        border-radius: 0;
        outline: none;
    }

    input:focus,
    textarea:focus {
        outline: none;
    }

    button,
    select {
        text-transform: none;
    }

    button {
        -webkit-appearance: button;
    }

    button[disabled],
    input[disabled] {
        cursor: default;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input,
    button,
    select,
    textarea {
        // color: inherit;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
    }

    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #eee;
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: 0;
        min-width: 0;
    }

    input[type='text']:focus {
        outline: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px #0828cc inset;
        -webkit-text-fill-color: white;
    }
    .roboto {
        font-family: 'Roboto', sans-serif;
    }

    .tac {
        text-align: center;
    }
    .tar {
        text-align: right;
    }
    .db {
        display: block;
    }
    .dib {
        display: inline-block;
    }
    .df {
        display: flex;
    }
    .dif {
        display: inline-flex;
    }
    .f-wrap {
        flex-wrap: wrap;
    }
    .jcsb {
        justify-content: space-between;
    }
    .jcsa {
        justify-content: space-around;
    }
    .jcc {
        justify-content: center;
    }
    .jcfe {
        justify-content: flex-end;
    }
    .aic {
        align-items: center;
    }
    .aife {
        align-items: flex-end;
    }
    .aifs {
        align-items: flex-start;
    }
    .bold {
        font-weight: bold;
    }
    .col {
        flex-direction: column;
    }
    .row {
        flex-direction: row;
    }
    .pointer {
        cursor: pointer;
    }
    .red {
        border: 1px solid red;
    }
    .mt1 {
        margin-top: 8px;
    }
    .mt2 {
        margin-top: 16px;
    }
    .mt3 {
        margin-top: 24px;
    }
    .mt4 {
        margin-top: 32px;
    }
    .mt5 {
        margin-top: 40px;
    }
    .mt6 {
        margin-top: 48px;
    }
    .mt7 {
        margin-top: 56px;
    }
    .mt8 {
        margin-top: 64px;
    }
    .mt9 {
        margin-top: 72px;
    }
    .mt10 {
        margin-top: 80px;
    }

    .ml1 {
        margin-left: 8px;
    }
    .ml2 {
        margin-left: 16px;
    }
    .ml3 {
        margin-left: 24px;
    }
    .ml4 {
        margin-left: 32px;
    }
    .ml5 {
        margin-left: 40px;
    }
    .ml6 {
        margin-left: 48px;
    }
    .ml7 {
        margin-left: 56px;
    }
    .ml8 {
        margin-left: 64px;
    }
    .ml9 {
        margin-left: 72px;
    }
    .ml10 {
        margin-left: 80px;
    }

    .mr1 {
        margin-right: 8px;
    }
    .mr2 {
        margin-right: 16px;
    }
    .mr3 {
        margin-right: 24px;
    }
    .mr4 {
        margin-right: 32px;
    }
    .mr5 {
        margin-right: 40px;
    }
    .mr6 {
        margin-right: 48px;
    }
    .mr7 {
        margin-right: 56px;
    }
    .mr8 {
        margin-right: 64px;
    }
    .mr9 {
        margin-right: 72px;
    }
    .mr10 {
        margin-right: 80px;
    }

    .m1 {
        margin: 8px;
    }
    .m2 {
        margin: 16px;
    }
    .m3 {
        margin: 24px;
    }
    .m4 {
        margin: 32px;
    }
    .m5 {
        margin: 40px;
    }
    .m6 {
        margin: 48px;
    }
    .m7 {
        margin: 56px;
    }
    .m8 {
        margin: 64px;
    }
    .m9 {
        margin: 72px;
    }
    .m10 {
        margin: 80px;
    }

    .mb1 {
        margin-bottom: 8px;
    }
    .mb2 {
        margin-bottom: 16px;
    }
    .mb3 {
        margin-bottom: 24px;
    }
    .mb4 {
        margin-bottom: 32px;
    }
    .mb5 {
        margin-bottom: 40px;
    }
    .mb6 {
        margin-bottom: 48px;
    }
    .mx0 {
        margin-left: 0px;
        margin-right: 0px;
    }
    .mx1 {
        margin-left: 8px;
        margin-right: 8px;
    }
    .mx2 {
        margin-left: 16px;
        margin-right: 16px;
    }
    .mx3 {
        margin-left: 24px;
        margin-right: 24px;
    }
    .mx4 {
        margin-left: 32px;
        margin-right: 32px;
    }
    .mx5 {
        margin-left: 40px;
        margin-right: 40px;
    }
    .mx6 {
        margin-left: 48px;
        margin-right: 48px;
    }
    .my0 {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .my1 {
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .my2 {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .my3 {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .my4 {
        margin-top: 32px;
        margin-bottom: 32px;
    }
    .my5 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .my6 {
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .p1 {
        padding: 8px;
    }
    .p2 {
        padding: 16px;
    }
    .p3 {
        padding: 24px;
    }
    .p4 {
        padding: 32px;
    }
    .p5 {
        padding: 40px;
    }
    .p6 {
        padding: 48px;
    }

    .pl1 {
        padding-left: 8px;
    }
    .pl2 {
        padding-left: 16px;
    }
    .pl3 {
        padding-left: 24px;
    }
    .pl4 {
        padding-left: 32px;
    }
    .pl5 {
        padding-left: 40px;
    }
    .pl6 {
        padding-left: 48px;
    }

    .pr1 {
        padding-right: 8px;
    }
    .pr2 {
        padding-right: 16px;
    }
    .pr3 {
        padding-right: 24px;
    }
    .pr4 {
        padding-right: 32px;
    }
    .pr5 {
        padding-right: 40px;
    }
    .pr6 {
        padding-right: 48px;
    }
    .px0 {
        padding-left: 0px;
        padding-right: 0px;
    }
    .px1 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .px2 {
        padding-left: 16px;
        padding-right: 16px;
    }
    .px3 {
        padding-left: 24px;
        padding-right: 24px;
    }
    .px4 {
        padding-left: 32px;
        padding-right: 32px;
    }
    .px5 {
        padding-left: 40px;
        padding-right: 40px;
    }
    .px6 {
        padding-left: 48px;
        padding-right: 48px;
    }
    .py0.py0 {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .py1 {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .py2 {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .py3 {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .py4 {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .py5 {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .py6 {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .pt0 {
        padding-top: 0px;
    }

    .mat {
        margin-left: auto;
        margin-right: auto;
    }

    .underline {
        text-decoration: underline;
    }
    .allcaps {
        text-transform: uppercase;
    }
    .cap {
        text-transform: capitalize;
    }
    .radius {
        border-radius: 4px;
    }
    .circle {
        border-radius: 50%;
    }
    .border {
        border: 1px solid #e5e5ea;
    }
    .bg-white {
        background-color: #fff;
    }
    .shadow {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .relative {
        position: relative;
    }
    .absolute {
        position: absolute;
    }
    .top {
        top: 0px;
    }
    .bottom {
        bottom: 0px;
    }
    .right {
        right: 0px;
    }
    .left {
        left: 0px;
    }
    .index-15 {
        z-index: 15;
    }
    .index-30 {
        z-index: 30;
    }
    .index-150 {
        z-index: 150;
    }
    .max-quater {
        max-width: 25%;
    }
    .w-quarter {
        width: 25%;
    }
    .half {
        width: 50%;
    }
    .max-half {
        max-width: 50%;
    }
    .w-three-quarters {
        width: 75%;
    }
    .max-three-quarters {
        max-width: 75%;
    }
    .full-width {
        width: 100%;
    }
    .hidden {
        overflow: hidden;
    }
    .fs15 {
        font-size: 15px;
    }
    .fs16 {
        font-size: 16px;
    }
    .fs18 {
        font-size: 18px;
    }
    .test {
        border: 1px solid red;
    }
    .center-in-parent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
    }
    *::-webkit-scrollbar {
        background-color: rgba(250,250,250);
        height: 4px;
        width: 4px;
    }
    *::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 8px rgba(0,0,0,0.00);
    }

    *::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.5);
        border-radius: 4px;
        outline: 1px solid slategrey;
    }
    @media(min-width: 768px): {
        .md-row {
            flex-direction: row;
        }
    .react-datepicker-popper.react-datepicker-popper {
        z-index: 130;
    }
`;

export default function GlobalStyles() {
  return <Global styles={styles} />;
}
