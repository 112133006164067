/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../../styles/colors';

const resultWrap = {
  alignItems: 'center',
  backgroundColor: colors.grayExLight,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 24,
  minHeight: 72,
  padding: 16,
  textAlign: 'center',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
};

const header = {
  fontSize: 20,
  color: colors.blue,
  fontWeight: 600,
  marginBottom: 8,
};

type ScanResultProps = {
  headerMessage: string;
  message?: string;
  subMessage?: string;
  customStyles?: object;
  replacedByDocumentId?: number;
};

export default function ScanResult({
  headerMessage,
  message,
  subMessage,
  customStyles = {},
}: ScanResultProps) {
  return (
    <div css={{ ...resultWrap, ...customStyles }}>
      <div css={header}>{headerMessage}</div>
      <div css={{ fontSize: 18 }}>
        {message?.split('\n').map((messagePart, i) => (
          <div key={i}>{messagePart}</div>
        ))}
        {subMessage && <div css={{ fontSize: 20 }}>{subMessage}</div>}
      </div>
    </div>
  );
}

export function StillNotFound() {
  return <ScanResult headerMessage="Still not found :(" />;
}

export function Found({ ...props }: Partial<ScanResultProps>) {
  return <ScanResult headerMessage="Found!" {...props} />;
}

export function Deleted() {
  return <ScanResult headerMessage="Deleted" message="This document has been deleted" />;
}

export function Cbc2ndMtgMsg() {
  return (
    <div css={[resultWrap, errorStyles]}>
      <div css={[header, { color: colors.red, marginBottom: 0 }]}>File away in CBC- 2nd Liens</div>
    </div>
  );
  // return <ScanResult headerMessage="File away in CBC- 2nd Liens" message="" />;
}

const errorStyles = {
  boxShadow: '0 1px 3px rgba(255,59,48,0.12), 0 1px 2px rgba(255,59,48, 0.24)',
};

export function AnyWithWrongClient() {
  return (
    <div css={[resultWrap, errorStyles]}>
      <div css={[header, { color: colors.red, marginBottom: 0 }]}>
        Warning: Document may be stored in the wrong client
      </div>
    </div>
  );
}

export function DocNotUploaded() {
  return (
    <div css={[resultWrap, errorStyles]}>
      <div css={[header, { color: colors.red, marginBottom: 0 }]}>
        Warning: Document was not yet scanned!
      </div>
    </div>
  );
}

export function Error() {
  return (
    <div css={[resultWrap, errorStyles]}>
      <div css={[header, { color: colors.red }]}>Error</div>
      <div>Something went wrong sorting this document</div>
    </div>
  );
}
