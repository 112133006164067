/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { Alert as AlertType } from '../globalTypes/objects';
import Alert, { defaultAlertData } from './ui/Alert';

const AlertContext = React.createContext({} as AlertContext);

type AlertContext = {
  setAlert: Dispatch<SetStateAction<AlertType>>;
};

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState<AlertType>(defaultAlertData);

  return (
    <Fragment>
      <AlertContext.Provider
        value={{
          setAlert,
        }}
      >
        {children}
      </AlertContext.Provider>

      <Alert alertData={alert} />
    </Fragment>
  );
};

export default AlertContext;
