/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../styles/colors';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.white,
  boxShadow: `inset 0 0 0 1.4px ${colors.blue}`,
  borderRadius: '100%',
  color: colors.blue,
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 'bold',
  height: 48,
  justifyContent: 'center',
  position: 'relative',
  transition: 'all .2s',
  width: 48,
};

const activeStyles = {
  backgroundColor: colors.blueLight,
  color: colors.white,
  boxShadow: `inset 0 0 0 1.4px ${colors.blueLight}`,
};

const completedStyles = {
  backgroundColor: colors.blueStatus,
  color: colors.white,
  boxShadow: `inset 0 0 0 1.4px ${colors.blueStatus}`,
};

export enum StepStatusTypes {
  active = 'ACTIVE',
  completed = 'COMPLETED',
  disabled = 'DISABLED',
}

export type StepCircleProps = {
  text: number;
  status: StepStatusTypes;
};

export default function StepCircle({ text, status }: StepCircleProps) {
  const mergedStyles = [
    baseStyles,
    status === StepStatusTypes.active && activeStyles,
    status === StepStatusTypes.completed && completedStyles,
  ];

  return <div css={mergedStyles}>{text}</div>;
}
