/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import { AssociateModalData as AssociateInvestor, InvestorData } from '../pages/Investor';
import {
  AssociateModalData as AssociateTitleCompany,
  TitleCompanyData,
} from '../pages/TitleCompany';

const useStyles = makeStyles(() =>
  createStyles({
    buttonWrap: {
      paddingRight: 16,
      paddingBottom: 16,
      '& button:last-child': { marginLeft: 24 },
    },
  }),
);

type Props = {
  type: 'Investor' | 'Title';
  associateModalData: AssociateInvestor | AssociateTitleCompany;
  data: InvestorData | TitleCompanyData;
  onClick: Function;
  onClose: Function;
};

export function UnlinkAliasDialog(props: Props) {
  const classes = useStyles();
  return (
    <Dialog
      open={props.associateModalData.isOpen}
      onClose={() => props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="bold">
        Unlink this alias
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to Unlink this alias?
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.buttonWrap}>
        <Button onClick={() => props.onClose()} color="primary">
          Cancel
        </Button>
        <Tooltip
          title={`Unlinks ${props.associateModalData.association.name} from ${
            props.data.name
          } and unsets ${props.data.name} as ${
            props.type === 'Investor' ? 'investor' : 'title company'
          } for past loans`}
        >
          <Button
            onClick={() => props.onClick(`/api/associations/Undo${props.type}Association`)}
            color="primary"
            variant="contained"
          >
            Undo
          </Button>
        </Tooltip>
        <Tooltip
          title={`Deletes ${
            props.associateModalData.association.name
          } from being used in the future but won't unset ${props.data.name} as the ${
            props.type === 'Investor' ? 'investor' : 'title company'
          } for past loans`}
        >
          <Button
            onClick={() => props.onClick(`/api/associations/Delete${props.type}Association`)}
            color="primary"
            variant="contained"
          >
            Delete
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
