import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  textField: {
    width: '100%',
    border: 'none',
    padding: '9px 0 11px 19px ',
    marginRight: 18,
  },
  input: {
    lineHeight: 1.6,
    color: 'rgba(104, 104, 104, 0.8)',
  },
  closeIcon: {
    zIndex: 105,
    position: 'absolute',
    color: '#bcbcbc',
    cursor: 'pointer',
    right: 197,
    top: 77,
  },
  closeIcon2: {
    zIndex: 105,
    position: 'absolute',
    color: '#bcbcbc',
    cursor: 'pointer',
    right: 20,
    top: 20,
    '& svg': {
      fontSize: 30,
    },
  },
  closeIcon3: {
    zIndex: 105,
    position: 'absolute',
    cursor: 'pointer',
    color: '#c0c0c0',
    right: 178,
    top: 19,
    backgroundColor: '#fff',
    borderRadius: 50,
  },
});

export const container = {
  position: 'absolute',
  boxShadow: '-10px 9px 21px 0 rgba(88, 88, 88, 0.32)',
  backgroundColor: '#ffffff',
  right: 0,
  top: -10,
  display: 'flex',
  borderRadius: 4,
  padding: '40px 64px 50px',
  zIndex: 100,
  color: '#686868',
  fontSize: 14,
};
export const button = {
  backgroundColor: '#0627ca',
  color: '#fff',
  width: 108,
  height: 41,
  borderRadius: 4,
  cursor: 'pointer',
  fontWeight: 700,
};

export const searchButton = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 35,
  '& svg': {
    marginLeft: 8,
    marginTop: 2,
  },
  ':disabled': {
    backgroundColor: '#c7c7c7',
    color: '#8e8e93',
  },
};
export const text = {
  fontSize: 20,
  fontWeight: 700,
};

export const textfield = {
  height: 149,
  width: 300,
  marginTop: 5,
  border: 'solid 1px #d0d6e6',
  backgroundColor: '#fff',
};

export const resultsChip = {
  borderRadius: 20,
  padding: '10px 50px 10px 20px',
  backgroundColor: '#c0c0c0',
  color: 'fff',
};
