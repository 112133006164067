/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import startCase from 'lodash/startCase';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import DatePicker from 'react-datepicker';
import { subWeeks } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { apiFetch } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import ConditionalComponent from '../../components/ConditionalComponent';
import { ReportCategories, ReportSelection } from './types';
import { reportsSelection } from './ReportsSelection';
import { Client } from '../../globalTypes/objects';
import { useClients } from '../../Hooks/useClients';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
    },
  }),
);

const reportsPageUrl = '/reports-dashboard/general-reporting';

const reportNameMap = new Map([
  ['pdfImageSavedEventsByUser', 'Scanned Events By User'],
  ['digitalUploadedEventsByUser', 'Digital Imports By User'],
  ['DeterminationPerformedEventsByUser', 'Audit Performed Events By User'],
  ['DocumentsReadyToBeDetermined', 'Documents Ready To Be Audited'],
  ['DocumentsReadyToBeDeterminedAsOfToday', 'Documents Added To Audit Queue Today'],
    ['TitleUploadsNotPreAssigned', 'Title Portal Uploads Not On Title Portal'],
]);

const LandingPage = () => {
  const clients = useClients();
  const [reports, setReports] = useState<ReportSelection[]>([]);
  const [checkedFields, setCheckedFields] = useState<string[]>([]);
  const [fromDate, setFromDate] = useState<Date>(subWeeks(new Date(), 3));
  const [selectedClient, setSelectedClient] = useState<Client>({} as Client);
  const [showLoader, setShowLoader] = useState(true);
  const { errorToaster } = useToaster();

  useEffect(() => {
    const getReportsList = async () => {
      try {
        const { data } = await apiFetch<Array<string>>('/api/reports/reports-list');
        const reportsListInitialValue = reportsSelection.filter((report) =>
          data.includes(report.name),
        );

        setReports(reportsListInitialValue);

        setShowLoader(false);
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      }
    };

    getReportsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categories: ReportCategories[] = [
    'Work Queue',
    'User Activity',
    'System Activity',
    'Other',
  ];

  const showClientFilter = useMemo(() => {
    return (
      reports.some((report) => report.isSelected) &&
      checkedFields.every((val) =>
        reports
          .filter((c) => c.isClientFilterReport)
          .map((c) => c.name)
          .includes(val),
      )
    );
  }, [checkedFields, reports]);

  const showDateFilter = useMemo(() => {
    return (
      reports.some((report) => report.isSelected) &&
      checkedFields.some((val) =>
        reports
          .filter((c) => c.isDateFilterReport)
          .map((c) => c.name)
          .includes(val),
      )
    );
  }, [checkedFields, reports]);

  const history = useHistory();

  const classes = useStyles();

  return (
    <Fragment>
      {showLoader ? (
        <div className="center-in-parent">
          <CircularProgress size="75" disableShrink />
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel style={{ paddingBottom: '20px' }} component="legend">
              Select reports to view
            </FormLabel>
            <div style={{ display: 'flex', gap: '10px' }}>
              {categories.map((category) => (
                <div
                  key={category}
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                  }}
                >
                  <FormLabel
                    style={{ paddingBottom: '15px', fontWeight: 'bold' }}
                    component="legend"
                  >
                    {category} Reports
                  </FormLabel>
                  <FormGroup style={{ height: '600px', width: '380px' }}>
                    {reports
                      .filter((report) => report.reportCategory === category)
                      .map((report) => (
                        <FormControlLabel
                          key={report.name}
                          control={
                            <Checkbox
                              color="error"
                              checked={report.isSelected}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const updatedList = reports.map((item) => {
                                  if (report.name === item.name) {
                                    return { ...item, isSelected: !item.isSelected };
                                  }
                                  return item;
                                });
                                setReports(updatedList);
                                setCheckedFields(
                                  updatedList.filter((r) => r.isSelected).map((r) => r.name),
                                );
                              }}
                            />
                          }
                          label={reportNameMap.get(report.name) ?? startCase(report.name)}
                        />
                      ))}
                  </FormGroup>
                </div>
              ))}
            </div>
          </FormControl>
          <div
            style={{ display: 'flex', gap: 15, justifyContent: 'flex-end', marginRight: '100px' }}
          >
            <ConditionalComponent display={showClientFilter}>
              <Autocomplete
                options={clients}
                getOptionLabel={(client: Client) => client.company ?? ''}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select a client" />
                )}
                value={selectedClient}
                size="small"
                style={{ width: '250px' }}
                onChange={(e, newValue) => newValue && setSelectedClient(newValue)}
              />
            </ConditionalComponent>
            <div>
              <DatePicker
                disabled={!showDateFilter}
                openToDate={fromDate}
                onChange={(dateTime) => setFromDate(dateTime ?? new Date())}
                placeholderText={`From ${fromDate.toLocaleDateString()}`}
                maxDate={new Date()}
                css={{
                  display: `${showDateFilter ? 'block' : 'none'}`,
                  border: '1px solid #c5c4c6',
                  borderRadius: '4px',
                  backgroundColor: 'transparent',
                  height: '37px !important',
                }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              css={{ height: '37px' }}
              onClick={() =>
                history.push(reportsPageUrl, {
                  reports: checkedFields,
                  fromDate,
                  client: selectedClient,
                })
              }
              disabled={!reports.some((report) => report.isSelected)}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default LandingPage;
