/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext } from 'react';
import { Card, CardContent, CircularProgress } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useQuery } from '@tanstack/react-query';
import { match as matchType } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { DocumentPageDto } from '../globalTypes/objects';
import DocumentsPage from './DocumentsPage';
import { documentPageData } from '../Utils';

type Props = { match: matchType<{ documentId: string }> };

export default function DocumentsPageWrapper({ match }: Props) {
  const auth = useContext(AuthContext);

  const documentId = parseInt(match.params.documentId, 10);

  const pageData = useQuery<DocumentPageDto | null, Error>(
    ['documents-page', { documentId }],
    () => documentPageData(documentId),
    { refetchOnWindowFocus: false, keepPreviousData: true },
  );

  return (
    <Fragment>
      {pageData.isLoading && (
        <div
          css={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <CircularProgress color="primary" />
        </div>
      )}
      {pageData.isError && (
        <Card css={{ display: 'inline-block', marginLeft: 24, marginTop: 24 }}>
          <CardContent css={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon className="mr1" />
            Whoops, there was an error: {pageData.error.message}
          </CardContent>
        </Card>
      )}
      {pageData.isSuccess && !pageData.data && (
        <Card css={{ display: 'inline-block', marginLeft: 24, marginTop: 24 }}>
          <CardContent css={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon className="mr1" />
            Sorry! No document {documentId} was found. It may have been deleted.{' '}
          </CardContent>
        </Card>
      )}
      {pageData.isSuccess && pageData.data && (
        <DocumentsPage pageDataQuery={pageData} pageData={pageData.data} />
      )}
    </Fragment>
  );
}
