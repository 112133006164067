/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { Fragment, FunctionComponent, ReactElement } from 'react';
import colors from '../../styles/colors';
import Button from './Button';
import StatusButton from "./StatusButton";

const screen: CSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalWrap: CSSObject = {
  backgroundColor: colors.white,
  border: '1px solid #c7c7cc',
  borderRadius: 4,
  padding: 32,
  width: 540,
  boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
};

const headerWrap: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  borderRadius: 4,
  display: 'flex',
  fontSize: 20,
  fontWeight: 'bold',
  height: 80,
  paddingLeft: 32,
  width: '100%',
};

const buttonText: CSSObject = {
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: 16,
};

export type ModalProps = {
  handleCancel?: (...args: any[]) => any;
  handleSave?: (...args: any[]) => any;
  saveDisabled?: boolean;
  headerText?: string;
  styles?: CSSObject;
  saveButtonText?: string;
  status?: string;
  customFooter?: ReactElement;
};

const Modal: FunctionComponent<ModalProps> = ({
  handleCancel,
  handleSave,
  headerText,
  children,
  saveDisabled,
  styles,
  saveButtonText,
  status,
  customFooter
}) => {
  const saveButton = status ?
   (<StatusButton
      text={saveButtonText ? saveButtonText : 'Save'}
      disabled={saveDisabled}
      onClick={handleSave}
      status={status}
    />
  ) : (
      <Button onClick={handleSave} disabled={saveDisabled}>
        {saveButtonText ? saveButtonText : 'Save'}
      </Button>
    );

  return (
    <div css={screen}>
      <div css={[modalWrap, styles]}>
        {headerText && <div css={headerWrap}>{headerText}</div>}
        {children}
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {customFooter ? customFooter : 
              <Fragment>
                <Button secondary onClick={handleCancel} styleOverrides={buttonText}>
                  Cancel
              </Button>
                {saveButton}
              </Fragment>}
        </div>
      </div>
    </div>
  );
};

export default Modal;
