/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ChangeEvent, Fragment, useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { OutreachDisposition } from '../../../globalTypes/objects';
import TextField from '@mui/material/TextField';
import DatePicker from 'react-datepicker';
import add from 'date-fns/add';
import DispositionsContext from '../DispositionsContext';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  stageNumber: {
    fontSize: '10px',
    fontStyle: 'italic',
    opacity: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  deferContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  deferLabels: {
    color: '#0828CC',
    fontSize: '12px',
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
  deferDatePicker: {
    border: '1px solid #aeaeae',
    borderRadius: '4px',
    boxShadow: 'inset 0 2px 2px #e9e9e9',
    fontSize: '13px',
    lineHeight: '16px',
    marginTop: 8,
    width: '100%',
    padding: '3px',
  },
}));

type Props = {
  selectedDisposition: OutreachDisposition | null;
  dispositionChangeHandler: (disposition: OutreachDisposition | null) => void;
  deferDate: Date | null;
  setDeferDate: (date: Date | null) => void;
};

const Dispositions = ({
  selectedDisposition,
  dispositionChangeHandler,
  deferDate,
  setDeferDate,
}: Props) => {
  const dispositions = useContext(DispositionsContext);
  const classes = useStyles();

  const optionLabel = (disposition: OutreachDisposition, props) => (
    <div key={disposition.id} {...props}>
      <div className="df jcsb full-width">
        <div style={{ maxWidth: '85%' }}>{disposition.reason}</div>
        <div className={classes.stageNumber}>Stage {disposition.stage}</div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div>
        <Autocomplete
          options={dispositions.sort((a, b) =>
            a.stage - b.stage !== 0 ? a.stage - b.stage : a.position - b.position,
          )}
          getOptionLabel={(option: OutreachDisposition) => option.reason}
          renderOption={(props, option: OutreachDisposition) => optionLabel(option, props)}
          groupBy={(option: OutreachDisposition) => `Stage ${option.stage}`}
          onChange={(e: ChangeEvent<{}>, newValue: OutreachDisposition | null) =>
            dispositionChangeHandler(newValue)
          }
          value={selectedDisposition}
          style={{ width: 300 }}
          autoHighlight
          renderInput={params => (
            <TextField {...params} label="Choose a stage and disposition" variant="outlined" />
          )}
        />
      </div>
      {selectedDisposition?.stage === 2 && (
        <div className={classes.deferContainer}>
          <div className={classes.deferLabels}>ETA</div>
          <div className={classes.deferDatePicker}>
            <DatePicker
              minDate={add(new Date(), { days: 1 })}
              selected={deferDate}
              onChange={dateTime => setDeferDate(dateTime)}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Dispositions;
