import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  barcode: number;
  dateCreated: Date;
  documentType: string;
  client: string;
  suggestedLoanNumber: string;
  dateAddedToQueue: Date;
};

const columns = [
  {
    field: 'barcode',
    title: 'Barcode',
  },
  {
    field: 'dateCreated',
    title: 'Date Created',
    render: (rowData: RowData) =>
      rowData.dateCreated && new Date(rowData.dateCreated).toLocaleDateString(),
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'suggestedLoanNumber',
    title: 'Suggested Loan Number',
  },
  {
    field: 'dateAddedToQueue',
    title: 'Date Added To Queue',
    render: (rowData: RowData) =>
      rowData.dateAddedToQueue && new Date(rowData.dateAddedToQueue).toLocaleDateString(),
  },
];

const WaitingToBeMatchedDrillDownReport = () => (
  <DataTable<RowData>
    title="Waiting To Be Matched Drill Down Report"
    columns={columns}
    url="/api/reports/matching-drill-down-report"
  />
);

export default WaitingToBeMatchedDrillDownReport;
