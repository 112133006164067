import styled from '@emotion/styled';

export const AuditSection = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px 24px;
  margin-top: 24px;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  input {
    margin-top: 0;
    margin-right: 4px;
    cursor: pointer;
  }
`;

export const Select = styled.select`
  border: 1px solid #e5e5ea;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  margin-top: 16px;
`;
