import React from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import groupBy from 'lodash/groupBy';
import Typography from '@mui/material/Typography';

export const Root = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  .passedAuditHeader {
    padding: 8px 16px;
    font-weight: 700;
    font-style: italic;
    text-align: center;
  }
  .customAuditHeader {
    padding: 8px 16px;
    font-weight: 500;
    background-color: rgba(244, 246, 248, 0.6);
    border-bottom: 1px solid #eee;
  }
  .dotWrap {
    border-bottom: none;
    padding-right: 5px;
    font-size: 20px;
    font-weight: 900;
  }
`;

export default function CustomAuditPanel({ rules, passedStandardAudit }) {
  return (
    <Root>
      <div className="customAuditHeader">This document requires the following:</div>
      <div>
        <Table size="small" aria-label="a dense table">
          {Object.entries(groupBy(rules, 'conditionType')).map(([ruleType, auditRules]) => (
            <TableBody key={ruleType}>
              {auditRules.map(rule => (
                <TableRow key={rule.id}>
                  <TableCell align="left" className="dotWrap">
                    •
                  </TableCell>
                  <TableCell align="left" style={{ borderBottom: 'none', paddingLeft: 5 }}>
                    {rule.policyEndorsement} ({rule.conditionValue ?? 'client'} requirement)
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ))}
        </Table>
      </div>
      {passedStandardAudit && (
        <Typography className="passedAuditHeader" color="primary">
          This document passed standard audit
        </Typography>
      )}
    </Root>
  );
}
