/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Printer } from '../../../Utilities/Dropdowns/PrinterDropdown';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(3, 4),
    },
  }),
);

type Props = {
  printer: Printer | null;
  setPrinter: Dispatch<SetStateAction<Printer | null>>;
  printers: Printer[];
  isModalOpen: boolean;
  selectPrinter: (isPrintEnabled: boolean) => Promise<void>;
  isPrintPaused: boolean;
};

const PrinterModal = ({
  printer,
  setPrinter,
  printers,
  isModalOpen,
  selectPrinter,
  isPrintPaused,
}: Props) => {
  const [tempIsPrintPaused, setTempIsPrintPaused] = useState(isPrintPaused);

  const classes = useStyles();

  return (
    <Modal open={isModalOpen}>
      <div className={classes.paper}>
        <div className="mb1">Choose Printer</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={tempIsPrintPaused}
                color="warning"
                onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
                  setTempIsPrintPaused(checked)
                }
              />
            }
            label="Pause printing"
          />
        </FormGroup>
        <div className="df jcsb aic mt1">
          <Autocomplete
            options={printers}
            getOptionLabel={(option: Printer) => option.name}
            renderOption={(props, option: Printer) => (
              <div key={option.id} {...props}>
                {option.name}
              </div>
            )}
            onChange={(e: ChangeEvent<{}>, newValue: Printer | null) => setPrinter(newValue)}
            value={printer}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Please select a printer" variant="outlined" />
            )}
          />
          <div>
            <Button
              variant="contained"
              disabled={!printer && !tempIsPrintPaused}
              onClick={() => selectPrinter(tempIsPrintPaused)}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PrinterModal;
