/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useImmer } from 'use-immer';
import { useCallback, useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton from '../../components/ui/StatusButton';
import { useClients } from '../../Hooks/useClients';
import { useToaster } from '../../Hooks/toasters';
// eslint-disable-next-line prettier/prettier
// prettier-ignore
import {
    createAllViewClientPermission,
    createExternalUserType,
    createInternalUserType, createPodViewClientPermission,
    createSpecificViewClientPermission,
    CreateUserForm,
    externalKind, ExternalUserClientPermission, ExternalUserPermissionKind,
    internalKind,
    isExternalUser,
    isInternalUser, isPodViewClientPermission,
    isSpecificViewClientPermission, PermissionKind,
    viewAllPermissionKind, ViewClientPermission, viewMultiplePermissionKind,
    viewPodPermissionKind, viewSinglePermissionKind,
    viewSpecificPermissionKind
} from './types';
import { apiPost, fetchWithAuth } from '../../adalConfig';
import { Pod } from '../../globalTypes/objects';

// eslint-disable-next-line consistent-return
function formIsValid(userForm: CreateUserForm): boolean {
  if (!userForm.userEmail.length || !userForm.firstName || !userForm.lastName) return false;

  switch (userForm.userType.kind) {
    case 'Internal':
      switch (userForm.userType.viewClientPermission.kind) {
        case 'ALL':
          return true;
        case 'SOME':
          return Boolean(userForm.userType.viewClientPermission.clientIdWhitelist.length);
        case 'POD':
          return Boolean(userForm.userType.viewClientPermission.podIdWhitelist.length);
      }
    // eslint-disable-next-line no-fallthrough
    case 'External':
      switch (userForm.userType.viewClientPermission.kind) {
        case 'MULTIPLE':
          return Boolean(userForm.userType.viewClientPermission.clientIdWhitelist.length);
        case 'SINGLE':
          return Boolean(userForm.userType.viewClientPermission.clientId);
      }
  }
}

async function getPods(): Promise<Pod[]> {
  const res = await fetchWithAuth(`/Api/Clients/Pods`);
  return res.json();
}

const initState: CreateUserForm = {
  firstName: '',
  lastName: '',
  userEmail: '',
  userType: createExternalUserType(),
  sendInvite: false,
};

function CreateUser() {
  const clients = useClients();
  const [createUserForm, setCreateUserForm] = useImmer<CreateUserForm>(initState);
  const toast = useToaster();
  const podsQuery = useQuery({
    select: data => data.map(pod => ({ id: pod.id, label: pod.name })),
    queryKey: ['pods'],
    queryFn: getPods,
  });

  const createUserMutation = useMutation({
    mutationFn: (form: CreateUserForm) =>
      apiPost(`/api/client-portal-user-management/create-user`, form),
    onSuccess: () => {
      setCreateUserForm(draft => {
        draft.userType = initState.userType;
        draft.userEmail = initState.userEmail;
        draft.sendInvite = initState.sendInvite;
        draft.firstName = initState.firstName;
        draft.lastName = initState.lastName;
      });
      toast.successToaster('User Created');
    },
    onError: () => {
      toast.errorToaster('There was a problem creating the user');
    },
  });

  const clientsForAutocomplete = clients.map(client => ({
    id: client.id,
    label: client.company,
  }));

  const handleUserTypeToggle = useCallback(() => {
    const newUserType = isInternalUser(createUserForm.userType)
      ? createExternalUserType()
      : createInternalUserType(createAllViewClientPermission());

    setCreateUserForm(draft => {
      draft.userType = newUserType;
    });
  }, [createUserForm.userType, setCreateUserForm]);

  const handleViewClientPermissionUpdate = useCallback(
    event => {
      if (isExternalUser(createUserForm.userType)) return;
      const selectedPermissionOption: PermissionKind = event.target.value;

      // eslint-disable-next-line consistent-return
      const newUserViewClientPermission: ViewClientPermission = (() => {
        switch (selectedPermissionOption) {
          case 'ALL':
            return createAllViewClientPermission();
          case 'SOME':
            return createSpecificViewClientPermission([]);
          case 'POD':
            return createPodViewClientPermission([]);
        }
      })();

      setCreateUserForm(draft => {
        if (isExternalUser(draft.userType)) return;
        draft.userType.viewClientPermission = newUserViewClientPermission;
      });
    },
    [createUserForm.userType, setCreateUserForm],
  );

  const handleExteranlUserPermissionKindUpdate = useCallback(
    event => {
      if (!isExternalUser(createUserForm.userType)) return;
      const selectedPermissionOption: ExternalUserPermissionKind = event.target.value;

      // eslint-disable-next-line consistent-return
      const externalUserClientPermission: ExternalUserClientPermission = (() => {
        switch (selectedPermissionOption) {
          case 'SINGLE':
            return { kind: 'SINGLE', clientId: null };
          case 'MULTIPLE':
            return { kind: 'MULTIPLE', clientIdWhitelist: [] };
        }
      })();

      setCreateUserForm(draft => {
        if (!isExternalUser(draft.userType)) return;
        draft.userType.viewClientPermission = externalUserClientPermission;
      });
    },
    [createUserForm.userType, setCreateUserForm],
  );

  const handlePodSelectChange = useCallback(
    (value: { id: number; label: string } | { id: number; label: string }[] | null) => {
      if (value === null) return;
      setCreateUserForm(draft => {
        if (value === null) return;
        if (!Array.isArray(value)) return;
        if (!isInternalUser(draft.userType)) return;

        if (!isPodViewClientPermission(draft.userType.viewClientPermission)) return;
        draft.userType.viewClientPermission.podIdWhitelist = value.flat().map(({ id }) => id);
      });
    },
    [setCreateUserForm],
  );

  const handleClientSelectChange = useCallback(
    (value: { id: number; label: string } | { id: number; label: string }[] | null) => {
      if (value === null) return;

      setCreateUserForm(draft => {
        if (isExternalUser(draft.userType)) {
          if (draft.userType.viewClientPermission.kind === 'SINGLE') {
            if (Array.isArray(value)) return;
            draft.userType.viewClientPermission.clientId = value.id;
          }
          if (draft.userType.viewClientPermission.kind === 'MULTIPLE') {
            if (!Array.isArray(value)) return;
            draft.userType.viewClientPermission.clientIdWhitelist = value
              .flat()
              .map(({ id }) => id);
          }
          return;
        }

        if (
          !isSpecificViewClientPermission(draft.userType.viewClientPermission) ||
          !Array.isArray(value)
        )
          return;
        draft.userType.viewClientPermission.clientIdWhitelist = value.flat().map(({ id }) => id);
      });
    },
    [setCreateUserForm],
  );

  const clientSelectValue = useMemo(() => {
    if (isExternalUser(createUserForm.userType)) {
      if (createUserForm.userType.viewClientPermission.kind === 'SINGLE') {
        const selectedClientId = createUserForm.userType.viewClientPermission.clientId;
        return clientsForAutocomplete.find(client => client.id === selectedClientId) ?? null;
      }
      if (createUserForm.userType.viewClientPermission.kind === 'MULTIPLE') {
        const { clientIdWhitelist } = createUserForm.userType.viewClientPermission;
        return clients
          .filter(client => clientIdWhitelist.includes(client.id))
          .map(client => ({ id: client.id, label: client.company }));
      }
    }

    if (isInternalUser(createUserForm.userType)) {
      if (!isSpecificViewClientPermission(createUserForm.userType.viewClientPermission)) return [];
      const { clientIdWhitelist } = createUserForm.userType.viewClientPermission;
      return clients
        .filter(client => clientIdWhitelist.includes(client.id))
        .map(client => ({ id: client.id, label: client.company }));
    }

    return null;
  }, [clients, clientsForAutocomplete, createUserForm.userType]);

  const podSelectValue = useMemo(() => {
    if (!podsQuery.isSuccess) return undefined;
    if (!isInternalUser(createUserForm.userType)) return undefined;
    if (!isPodViewClientPermission(createUserForm.userType.viewClientPermission)) return undefined;
    const { podIdWhitelist } = createUserForm.userType.viewClientPermission;
    return podsQuery.data.filter(pod => podIdWhitelist.includes(pod.id));
  }, [createUserForm.userType, podsQuery.data, podsQuery.isSuccess]);

  return (
    <div>
      <div css={{ marginLeft: '24px', marginTop: '24px' }}>
        <Panel>
          <PanelHeader text="Create Client Portal User" />
          <div css={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
            <div
              css={{
                marginTop: '32px',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '40px',
              }}
            >
              <div css={{ width: '50%' }}>
                <TextInput
                  value={createUserForm.firstName}
                  onChange={e =>
                    setCreateUserForm(draft => {
                      draft.firstName = e.target.value.trim();
                    })
                  }
                  placeholder="Enter First Name"
                />
              </div>
              <div css={{ width: '50%' }}>
                <TextInput
                  value={createUserForm.lastName}
                  onChange={e =>
                    setCreateUserForm(draft => {
                      draft.lastName = e.target.value.trim();
                    })
                  }
                  placeholder="Enter Last Name"
                />
              </div>
            </div>

            <TextInput
              value={createUserForm.userEmail}
              onChange={e =>
                setCreateUserForm(draft => {
                  draft.userEmail = e.target.value.toLowerCase().trim();
                })
              }
              placeholder="Enter Email"
            />

            <div className="df jcsb" css={{ marginTop: '10px' }}>
              <FormControl>
                <FormLabel>User Type</FormLabel>
                <RadioGroup
                  row
                  value={createUserForm.userType.kind}
                  onChange={handleUserTypeToggle}
                >
                  <FormControlLabel value={externalKind} control={<Radio />} label="External" />
                  <FormControlLabel value={internalKind} control={<Radio />} label="Internal" />
                </RadioGroup>
              </FormControl>
            </div>

            {isInternalUser(createUserForm.userType) && (
              <div css={{ marginTop: '10px' }}>
                <FormControl>
                  <FormLabel>Clients</FormLabel>
                  <RadioGroup
                    row
                    value={createUserForm.userType.viewClientPermission.kind}
                    onChange={handleViewClientPermissionUpdate}
                  >
                    <FormControlLabel
                      value={viewAllPermissionKind}
                      control={<Radio />}
                      label="All"
                    />
                    <FormControlLabel
                      value={viewSpecificPermissionKind}
                      control={<Radio />}
                      label="Specific"
                    />
                    <FormControlLabel
                      value={viewPodPermissionKind}
                      control={<Radio />}
                      label="Pod"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}

            {isExternalUser(createUserForm.userType) && (
              <div css={{ marginTop: '10px' }}>
                <FormControl>
                  <FormLabel>Clients</FormLabel>
                  <RadioGroup
                    row
                    value={createUserForm.userType.viewClientPermission.kind}
                    onChange={handleExteranlUserPermissionKindUpdate}
                  >
                    <FormControlLabel
                      value={viewSinglePermissionKind}
                      control={<Radio />}
                      label="Single"
                    />
                    <FormControlLabel
                      value={viewMultiplePermissionKind}
                      control={<Radio />}
                      label="Multiple"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            )}

            {(isExternalUser(createUserForm.userType) ||
              isSpecificViewClientPermission(createUserForm.userType.viewClientPermission)) && (
              <div css={{ marginTop: '20px' }}>
                <Autocomplete
                  options={clientsForAutocomplete}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label="Choose a client" />
                  )}
                  onChange={(_, value) => {
                    handleClientSelectChange(value);
                  }}
                  multiple={
                    isInternalUser(createUserForm.userType) ||
                    createUserForm.userType.viewClientPermission.kind === 'MULTIPLE'
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={clientSelectValue}
                />
              </div>
            )}

            {isInternalUser(createUserForm.userType) &&
              isPodViewClientPermission(createUserForm.userType.viewClientPermission) &&
              podsQuery.isSuccess && (
                <div css={{ marginTop: '20px' }}>
                  <Autocomplete
                    options={podsQuery.data}
                    renderInput={params => (
                      <TextField {...params} variant="outlined" label="Choose one or more pods" />
                    )}
                    onChange={(_, value) => {
                      handlePodSelectChange(value);
                    }}
                    multiple
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={podSelectValue}
                  />
                </div>
              )}

            <div css={{ marginTop: '10px', display: 'flex', justifyContent: 'end' }}>
              <StatusButton
                onClick={() => createUserMutation.mutate(createUserForm)}
                disabled={!formIsValid(createUserForm)}
                text="Create User"
              />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
}

export default CreateUser;
