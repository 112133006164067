/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { Component } from 'react';

import NoNotes from './NoNotes';
import NoteBar from './NoteBar';
import Panel from '../../../../ui/Panel';
import ToolTip from '../../../../ui/ToolTip';
import { apiFetch } from '../../../../../adalConfig';
import LoanErrorNoteInnerModal from '../LoanErrorNoteInnerModal';
import ActionCircle from '../../../../ui/ActionCircle';
import { IconType } from '../../../../ui/Icon';
import { Note } from '../../../../../globalTypes/objects';

// #region css
const headerWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
};

const notesTitle: CSSObject = {
  fontSize: 12,
  fontWeight: 700,
  textTransform: 'uppercase',
};

// #endregion

const getNotes = async id => {
  const { data } = await apiFetch(`/api/loanErrors/getNotesForError?loanErrorId=${id}`);
  return data;
};

type NotesPanelProps = {
  id: number;
};
type NotesPanelState = {
  notes: Note[];
  isNewNote: boolean;
  loading: boolean;
  disabled: boolean;
};
export default class NotesPanel extends Component<NotesPanelProps, NotesPanelState> {
  state = { notes: [] as Note[], isNewNote: false, loading: true, disabled: false };

  async componentDidMount() {
    const { id } = this.props;
    const notes = await getNotes(id);
    this.setState({ notes, loading: false });
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      await this.refresh(nextProps.id);
    }
  }

  refresh = async (id?: number) => {
    const notes = await getNotes(id || this.props.id);

    this.setState({ notes });
  };

  setDisabled = bool => {
    this.setState({ disabled: bool });
  };

  render() {
    const { notes, isNewNote, loading, disabled } = this.state;
    const { id } = this.props;
    return (
      <Panel styles={{ position: 'relative' }}>
        <div css={[headerWrap, disabled && { pointerEvents: 'none' }]}>
          <span css={notesTitle}>Notes</span>
          <ToolTip toolTipText="Add note">
            <ActionCircle
              onClick={() => this.setState({ isNewNote: true, disabled: true })}
              icon={IconType.Plus}
            />
          </ToolTip>
        </div>

        {isNewNote && (
          <LoanErrorNoteInnerModal
            ids={[id]}
            handleClose={() => {
              this.setState({ isNewNote: false, disabled: false });
              this.refresh();
            }}
          />
        )}
        {!notes.length && !loading && !isNewNote ? (
          <NoNotes />
        ) : (
          <div css={[{ backgroundColor: 'white' }, disabled && { pointerEvents: 'none' }]}>
            {notes.map(note => (
              <NoteBar
                key={note.id}
                note={note}
                refresh={this.refresh}
                setDisabled={this.setDisabled}
              />
            ))}
          </div>
        )}
      </Panel>
    );
  }
}
