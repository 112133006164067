import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import {Client, Loan, DocumentStatus, Document, DocumentAudit} from '../../../globalTypes/objects';

export type IndexDoc = {
  document: Document;
  loan: undefined | Loan;
  docStatus: DocumentStatus;
  audit: DocumentAudit | null;
};

export type DocIndexContext = {
  selected: Set<number>;
  lastSelected: number | undefined;
  currentClient: Client;
  displayedDocs: IndexDoc[];
  isBusy: boolean;
};

type DocIndexContextValue = [DocIndexContext, (updateIndex: Partial<DocIndexContext>) => void];

const initialState: DocIndexContext = {
  selected: new Set(),
  lastSelected: undefined,
  currentClient: {} as Client,
  displayedDocs: [],
  isBusy: false,
};

const DocIndexContext = createContext({} as DocIndexContextValue);

function DocIndexProvider(props) {
  const [docIndexData, setDocIndexData] = useState<DocIndexContext>(initialState);
  const setDataFunc = useCallback((obj: Partial<DocIndexContext>) => {
    return setDocIndexData(d => ({ ...d, ...(obj as DocIndexContext) }));
  }, []);
  const value = useMemo(() => {
    return [docIndexData, setDataFunc];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docIndexData]);

  return <DocIndexContext.Provider value={value} {...props} />;
}

function useDocIndex() {
  const context = useContext(DocIndexContext);

  if (!context) {
    throw new Error('useDocIndex must be used within a DocIndexProvider');
  }
  return context;
}

export { DocIndexProvider, useDocIndex };
