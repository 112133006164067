import React, { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import BatchDocument from './BatchDocument';

const useStyles = makeStyles(() => ({
  textBold: {
    fontWeight: 'bold',
  },
}));
export default function BatchContent({ ...props }) {
  const classes = useStyles({});
  const { batchDocuments } = props;
  return (
    <Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '30px' }}>
        <div>
          <p className={classes.textBold}>Batch content</p>
        </div>
        <div>
          <p className={classes.textBold}>
            {batchDocuments.filter(d => d.isVerified === true).length}/{batchDocuments.length}{' '}
            Scanned
          </p>
        </div>
      </div>

      <div>
        {batchDocuments.map(document => (
          <BatchDocument document={document} handleScan={props.handleScan} key={document.id} />
        ))}
      </div>
    </Fragment>
  );
}
