import React from 'react';
import debounce from 'lodash/debounce';
import { apiFetch } from '../../adalConfig';
import SearchSelect from '../ui/SearchSelect';
import { Investor } from '../../globalTypes/objects';

const stringsOrEmpty = (string: string) => string || '';

const getInvestorOption = (investor: Investor): SelectOption<Investor> => {
  {
    const investorName = investor.name;
    const investorAddress = `${stringsOrEmpty(investor.address)} ${stringsOrEmpty(
      investor.city,
    )} ${stringsOrEmpty(investor.state)} ${stringsOrEmpty(investor.zip)}`;
    return {
      label: investorName,
      subLabel: investorAddress,
      value: investor,
    };
  }
};

const andSign = '%26';
const searchInvestors = async (partialString: string): Promise<SelectOption<Investor>[]> => {
  const { data }: { data: Investor[] } = await apiFetch(
    `/api/investors/searchInvestors?searchString=${partialString.replace('&', andSign)}`,
  );
  return data.map(investor => getInvestorOption(investor));
};

const loadInvestorOptions = debounce((input: string, callback) => {
  searchInvestors(input).then(callback);
}, 800);

type InvestorProps = { onChange: (investor: Investor) => void; selected?: Investor | '' };

export default function InvestorSearchSelect({ onChange, selected, ...props }: InvestorProps) {
  const defaultVal = selected && getInvestorOption(selected);
  return (
    <SearchSelect
      placeholder="Search Investors"
      loadOptions={loadInvestorOptions}
      onChange={(selection: SelectOption<Investor>) => onChange(selection.value)}
      {...(selected && { defaultValue: defaultVal })}
      isAsync
      {...props}
    />
  );
}
