/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { PrinterIcon } from '../ui/icons';
import colors from '../../styles/colors';

const printingOverlay = {
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 200,
  p: {
    alignItems: 'center',
    backgroundColor: colors.grayExLight,
    borderRadius: '50%',
    display: 'flex',
    height: 168,
    justifyContent: 'center',
    width: 168,
  },
  div: {
    fontSize: 24,
    fontWeight: 600,
    marginTop: 24,
  },
};
export default function PrintingOverlay({ showIcon = true }: { showIcon?: boolean }) {
  return (
    <div css={printingOverlay}>
      {showIcon && (
        <Fragment>
          <p>
            <PrinterIcon />
          </p>
          <div>Printing Coversheet</div>
        </Fragment>
      )}
    </div>
  );
}
