import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/ui/Tabs/MaterialTabPanel';
import AddPayment from '../components/Admin/Payments/AddPayment';
import ViewPayments from '../components/Admin/Payments/ViewPayments';

const Payments = () => {
  const [tab, setTab] = useState(0);

  return (
    <div className="m5">
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={(event, newValue: number) => setTab(newValue)}
      >
        {['Add payment', 'View payments'].map((tabLabel, i) => (
          <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
        ))}
      </Tabs>
      <TabPanel value={tab} index={0}>
        <AddPayment />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ViewPayments />
      </TabPanel>
    </div>
  );
};

export default Payments;
