/** @jsx jsx */
import { jsx } from '@emotion/core';

import { Fragment, useState } from 'react';
import Panel from '../../ui/Panel';
import StepThreeFound from './StepThreeFound';
import StepThreeNotFound from './StepThreeNotFound';
import StepHeader from '../../Utilities/StepUI/StepHeader';
import colors from '../../../styles/colors';

// #region css

const panelWrap = {
  marginBottom: 24,
  maxWidth: 720,
};
// #endregion

export default function StepThree({
  loan,
  docType,
  notFound,
  handleChange,
  disabled,
  digital,
  flaggedDoc,
  investor,
  verificationFailures,
  note,
}) {
  const [isMoreLoanDetailsOpen, setMoreLoanDetailsOpen] = useState(false);
  return (
    <Panel styles={[panelWrap, disabled && { pointerEvents: 'none', opacity: 0.4 }]}>
      <StepHeader text="Verify Information" number="3" />
      {flaggedDoc && <FlaggedDocMessage flaggedDoc={flaggedDoc} />}
      {!notFound ? (
        <Fragment>
          <a css={{ color: 'blue' }} onClick={() => setMoreLoanDetailsOpen(!isMoreLoanDetailsOpen)}>
            More Loan Data
          </a>
          {isMoreLoanDetailsOpen && (
            <div>
              <div>
                <b>Borrower:</b> {loan.borrower}
              </div>
              <div>
                <b>Property:</b> {loan.propertyAddress}
              </div>
              <div>
                <b>City State Zip:</b>
                {loan.city} {loan.state} {loan.zip}
              </div>
              <div>
                <b>County:</b> {loan.county}
              </div>
              <div>
                <b>Loan Amount:</b> {loan.loanAmount}
              </div>
              {loan.mTANum && (
                <div>
                  <b>Title Number:</b> {loan.mTANum}
                </div>
              )}
              <div>
                <b>System ID:</b> {loan.id}
              </div>
              {investor?.name && (
                <div>
                  <b>Investor:</b> {investor.name}
                </div>
              )}
            </div>
          )}
          <StepThreeFound
            loan={loan}
            handleChange={handleChange}
            docType={docType}
            verificationFailures={verificationFailures}
            note={note}
          />
        </Fragment>
      ) : (
        <StepThreeNotFound digital={digital} handleChange={handleChange} docType={docType} />
      )}
    </Panel>
  );
}

function FlaggedDocMessage({ flaggedDoc }) {
  return (
    <div
      css={{
        alignItems: 'center',
        border: `1px solid ${colors.red}`,
        borderRadius: 4,
        color: colors.red,
        display: 'flex',
        flexDirection: 'column',
        fontSize: '25px',
        justifyContent: 'space-between',
        marginTop: 24,
        padding: 16,
      }}
    >
      <span>This doc has been flagged! </span>
      <span>Please submit it to the account manager.</span>
      {flaggedDoc.reason && (
        <span
          css={{
            backgroundColor: colors.grayLight,
            borderRadius: 4,
            color: colors.black,
            marginTop: 16,
            padding: 16,
            fontSize: 16,
          }}
        >
          Reason: {flaggedDoc.reason}
        </span>
      )}
    </div>
  );
}
