/** @jsx jsx */
import { jsx, CSSObject, ClassNames } from '@emotion/core';
import React, { Children } from 'react';
import { NavLink, withRouter, matchPath, NavLinkProps } from 'react-router-dom';

import { borderStyles, tabStyles, selectedTabStyles, numStyles, selectedNumStyles } from './css';

export function TabLinkGroup({ children }) {
  return (
    <div css={borderStyles}>{Children.map(children, child => React.cloneElement(child, {}))}</div>
  );
}

interface TabLinkProps extends NavLinkProps {
  text: string;
  number?: number;
  url: string;
  staticContext?: any;
}

function TabLink({ text, number, url, staticContext, ...props }: TabLinkProps) {
  const { location } = props;
  const isActive = location && matchPath(location.pathname, url);
  return (
    <ClassNames>
      {({ css }) => (
        <NavLink
          to={url}
          css={tabStyles}
          activeClassName={css`
            ${selectedTabStyles}
          `}
          {...props}
        >
          {text}
          {(number || number === 0) && (
            <span css={[numStyles, isActive && selectedNumStyles]}>{number}</span>
          )}
        </NavLink>
      )}
    </ClassNames>
  );
}

export default withRouter(TabLink);
