/** @jsx jsx */
import { ClassNames, jsx } from '@emotion/core';
import { Fragment, ReactElement, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import MaterialIcon from '@mdi/react';
import {
  mdiChartBar,
  mdiChartLine,
  mdiFileChart,
  mdiFileDocumentCheckOutline,
  mdiFileDocumentMultiple,
  mdiForwardburger,
  mdiOfficeBuilding,
  mdiSitemap,
  mdiWrench,
} from '@mdi/js';

import { Tooltip } from '@mui/material';
import s from './indexStyles';
import { SidebarConsumer } from './SidebarContext';
import Avatar from './Avatar';
import Icon, { IconType } from '../../ui/Icon';
import Circle from '../../ui/Circle';
import { AuthContext } from '../../AuthContext';
import { hasAccessToRoute } from '../../../Utils';

type LinkProps<T = boolean> = T extends true
  ? {
      text: string;
      count?: number;
      icon: ReactElement<typeof MaterialIcon>;
      to: string;
      isMaterialUIIcon: true;
    }
  : {
      text: string;
      count?: number;
      icon: IconType;
      to: string;
      isMaterialUIIcon?: false;
    };

type TaskLinkProps = LinkProps & {
  count?: number;
};

export default function Sidebar({ handleOpen, isOpen }) {
  const { roles } = useContext(AuthContext);

  const SectionLink = ({ text, to, icon, isMaterialUIIcon, styles }: LinkProps) => {
    return hasAccessToRoute(roles, to) ? (
      <ClassNames>
        {({ css }) => (
          <NavLink
            to={to}
            activeClassName={css`
              ${s.activeTask}
            `}
            exact
            css={[s.taskStyle, styles, isOpen && { width: '100%' }]}
          >
            {isOpen ? (
              <Fragment>
                <div css={s.iconWrapper}>{isMaterialUIIcon ? icon : <Icon icon={icon} />}</div>
                <div css={s.textWrap}>
                  <span>{text}</span>
                </div>
              </Fragment>
            ) : (
              <Tooltip title={text} placement="right" arrow>
                <div css={s.iconWrapper}>{isMaterialUIIcon ? icon : <Icon icon={icon} />}</div>
              </Tooltip>
            )}
          </NavLink>
        )}
      </ClassNames>
    ) : (
      <Fragment />
    );
  };

  const TaskLink = ({ text, count, icon, to, isMaterialUIIcon }: TaskLinkProps) => {
    return hasAccessToRoute(roles, to) ? (
      <ClassNames>
        {({ css }) => (
          <NavLink
            to={to}
            activeClassName={css`
              ${s.activeTask}
            `}
            exact
            css={[s.taskStyle, isOpen && { width: '100%' }]}
          >
            {isOpen ? (
              <Fragment>
                <div css={s.iconWrapper}>{isMaterialUIIcon ? icon : <Icon icon={icon} />}</div>
                <div css={s.textWrap}>
                  <span>{text}</span>
                  {count && count > 0 && <div css={s.countCircle}>{count}</div>}
                </div>
              </Fragment>
            ) : (
              <Tooltip title={text} placement="right" arrow>
                <div css={s.iconWrapper}>{isMaterialUIIcon ? icon : <Icon icon={icon} />}</div>
              </Tooltip>
            )}
          </NavLink>
        )}
      </ClassNames>
    ) : (
      <Fragment />
    );
  };

  const burgerSpices = {
    backgroundColor: '#f7f7f9',
    color: '#8e8e93',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#e5e5ea' },
  };

  return (
    <Fragment>
      <SidebarConsumer>
        {({ associationErrorCount, loanErrorCount }) => (
          <div css={[s.outerContainer, isOpen && s.containerExpanded]} style={{ zIndex: 0 }}>
            <div>
              <div css={s.headCont}>
                <div onClick={handleOpen}>
                  <Circle small icon={IconType.BurgerMenu} styleoverrides={burgerSpices} />
                </div>
                {isOpen && (
                  <Link css={s.dpLogo} to="/">
                    Docprobe
                  </Link>
                )}
              </div>
              <div css={s.innerContainer}>
                <div css={s.firstLinks}>
                  <div>
                    <TaskLink text="Loan Imports" to="/loan-imports" icon={IconType.Note} />
                    <TaskLink
                      text="Import Errors"
                      to="/errors/associations"
                      count={(associationErrorCount || 0) + (loanErrorCount || 0)}
                      icon={IconType.Errors}
                    />
                  </div>
                  <div css={s.taskLinkStyles}>
                    <TaskLink
                      text="Import Docs"
                      to="/documents/import/physical"
                      icon={IconType.ImportDocs}
                    />
                  </div>
                  <TaskLink text="Staple" to="/staple" icon={IconType.Stapler} />
                  <TaskLink
                    text="Sort"
                    to="/sort"
                    isMaterialUIIcon
                    icon={<MaterialIcon path={mdiSitemap} size={1} />}
                  />
                  <div css={s.taskLinkStyles}>
                    <TaskLink text="Match" to="/documents/match" icon={IconType.Unassociated} />
                    <TaskLink text="Workflow" to="/workflow" icon={IconType.Dashboard} />
                    <TaskLink text="Documents Index" to="/documents" icon={IconType.Docs} />
                    <TaskLink text="Shipping" to="/shipping" icon={IconType.Shipping} />
                    <TaskLink text="Outreach" to="/outreach" icon={IconType.Phone} />
                    <TaskLink
                      text="Outreach Escalations"
                      to="/outreach-escalations"
                      isMaterialUIIcon
                      icon={
                        <MaterialIcon path={mdiForwardburger} title="mdi-forward-burger" size={1} />
                      }
                    />
                    <TaskLink text="Clients" to="/clients" icon={IconType.Client} />
                    <TaskLink
                      text="Status Tool"
                      to="/investor-reports"
                      isMaterialUIIcon
                      icon={<MaterialIcon path={mdiFileChart} size={1} />}
                    />
                    <TaskLink
                      text="Corrections Pipeline"
                      to="/account-reps"
                      isMaterialUIIcon
                      icon={<MaterialIcon path={mdiWrench} title="mdi-wrench" size={1} />}
                    />
                    <TaskLink
                      text="Correction Fulfillment"
                      to="/correction-fulfillment"
                      isMaterialUIIcon
                      icon={<MaterialIcon path={mdiFileDocumentCheckOutline} size={1} />}
                    />
                    <SectionLink text="Audit Docs" icon={IconType.Docs} to="/determination" />
                    <SectionLink
                      to="/sales-reps-landing-page"
                      text="Sales Rep Dashboard"
                      icon={IconType.Dashboard}
                    />
                    <TaskLink
                      text="Ship a Copy"
                      to="/ship-a-copy"
                      isMaterialUIIcon
                      icon={
                        <MaterialIcon path={mdiFileDocumentMultiple} title="mdi-wrench" size={1} />
                      }
                    />
                  </div>
                </div>
                <div css={s.secondLinks}>
                  <div className="more-link-list" css={s.moreLinksListOpen}>
                    <SectionLink to="/search" text="Loans" icon={IconType.Loans} />
                    <SectionLink
                      to="/knowledge-base"
                      text="Knowledge Base"
                      icon={IconType.InfoIcon}
                    />
                    <SectionLink
                      to="/documents/download"
                      text="Download Docs - LOS Upload"
                      icon={IconType.Download}
                      styles={{ fontSize: 12 }}
                    />
                    <SectionLink to="/reports" text="Reports" icon={IconType.Docs} />
                    <SectionLink
                      to="/reports-dashboard/landing-page"
                      text="Reports Dashboard"
                      isMaterialUIIcon
                      icon={<MaterialIcon path={mdiChartBar} size={1} />}
                    />
                    <SectionLink
                      to="/powerbi"
                      text="PowerBI"
                      isMaterialUIIcon
                      icon={<MaterialIcon path={mdiChartLine} size={1} />}
                    />
                    <SectionLink
                      to="/admin/flagdocument"
                      text="Flag Document"
                      icon={IconType.Flag}
                    />
                    <SectionLink
                      to="/searcher-retrieval"
                      text="Searcher Retrieval Fee"
                      icon={IconType.Search}
                    />
                    <SectionLink
                      to="/user-dashboard/log-event"
                      text="Dashboard"
                      icon={IconType.Dashboard}
                    />
                    <TaskLink
                      text="Title Companies"
                      to="/title-companies"
                      isMaterialUIIcon
                      icon={
                        <MaterialIcon
                          path={mdiOfficeBuilding}
                          title="mdi-office-building"
                          size={1}
                        />
                      }
                    />
                    <SectionLink to="/investors" text="Investors" icon={IconType.InvestorSmall} />
                    <SectionLink
                      to="/title-company-portal-links"
                      text="Title Company Uploads"
                      icon={IconType.Docs}
                    />
                    <SectionLink
                      to="/title-portal-links"
                      text="Title Portal Links"
                      icon={IconType.EmailIcon}
                    />
                    <SectionLink
                      to="/print-coversheets"
                      text="Print Coversheets"
                      icon={IconType.PrinterSmall}
                    />
                    <SectionLink
                      to="/closing-packages"
                      text="Closing Packages"
                      icon={IconType.Docs}
                    />
                    <SectionLink
                      to="/document-catch-all"
                      text="Document Catchall"
                      icon={IconType.BlackHole}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div css={{ '> div': { display: 'block' } }}>
              <Avatar isOpen={isOpen} />
            </div>
          </div>
        )}
      </SidebarConsumer>
    </Fragment>
  );
}
