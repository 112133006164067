/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoopIcon from '@mui/icons-material/Loop';
import useEmailStyles from './ManualFollowupStyles';
import {
  EmailStatus,
  EmailWithStatus,
  ManualFollowUpsProps,
  useManualFollowups,
} from './useManualFollowups';
import { useGetData } from '../../../Hooks';
import { buildArrayFetchString, convertToFileDownload } from '../../../Utils';
import { DownloadIcon } from '../../ui/icons';
import ErrorCard from './ErrorCard';
import SpinChildren from '../../ui/SpinChildren';
import { apiPost } from '../../../adalConfig';

export default function EmailValidationPanel({
  loans,
  titleCompanyEmail,
  allEmails,
}: ManualFollowUpsProps) {
  const classes = useEmailStyles();
  const [
    { sendToInvalid, sendToGlobal, emails, isOverride, emailsSent, titlePortalMode },
    setManualFollowups,
  ] = useManualFollowups();
  const [expanded, setExpanded] = useState(true);

  const { data: emailsWithStatuses, isLoading, isSuccess, error } = useGetData<EmailWithStatus[]>(
    `/api/emails/getMultipleEmailStatuses?${buildArrayFetchString(allEmails, 'emails')}`,
    [],
  );
  useEffect(() => {
    isSuccess && setManualFollowups({ emails: emailsWithStatuses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsWithStatuses, isSuccess]);

  const {
    doFetch: downloadEmails,
    isSuccess: downloadSuccess,
    isLoading: loadingDownload,
    error: downloadError,
    data: downloadData,
  } = useGetData<{ base64: string; fileName: string }>('', { base64: '', fileName: '' });
  useEffect(() => {
    (downloadSuccess || downloadError) && setManualFollowups({ hardInvalidsDownloaded: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadError, downloadSuccess]);

  // #region variables
  const alreadyDownloaded = downloadSuccess || downloadError;

  const emailsOnLoans = new Set([...loans.map(l => l.email), titleCompanyEmail].filter(Boolean));

  const validEmails = emails.filter(e => e.status === EmailStatus.Valid).map(e => e.email);
  const softInvalidEmails = emails
    .filter(e => e.status === EmailStatus.SoftInvalid && emailsOnLoans.has(e.email))
    .map(e => e.email);
  const hardInvalidEmails = emails
    .filter(e => e.status === EmailStatus.HardInvalid && emailsOnLoans.has(e.email))
    .map(e => e.email);

  const validLoans = loans.filter(l => validEmails.includes(l.email));
  const softInvalidLoans = loans.filter(l => softInvalidEmails.includes(l.email));
  const hardInvalidLoans = loans.filter(l => hardInvalidEmails.includes(l.email));
  const missingLoans = loans.filter(l => !l.email);

  // these don't include global email
  const validsExist = validEmails.length > 0;
  const softInvalidsExist = softInvalidLoans.length > 0;
  const hardInvalidsExist = hardInvalidLoans.length > 0;
  const missingsExist = missingLoans.length > 0;

  const titleEmailStatus = emails.find(e => e.email === titleCompanyEmail)?.status;

  const notGlobal = () => {
    sendToGlobal && setManualFollowups({ sendToGlobal: false });
    return false;
  };
  const displaySendToGlobalOption = () => {
    if (titleEmailStatus === undefined) return notGlobal();
    if (!titleCompanyEmail) return notGlobal();
    if (titleEmailStatus === EmailStatus.HardInvalid) return notGlobal();
    if (titleEmailStatus === EmailStatus.Valid) {
      // only show if "send anyway" is not checked (cuz if it is and there are no hardInvalids then nothing will be sent to global)
      if (softInvalidsExist && !hardInvalidsExist) return !sendToInvalid || notGlobal();
      if (hardInvalidsExist && !softInvalidsExist) return !alreadyDownloaded || notGlobal();
      // once get here - know that there are both softInvalids AND hardInvalids (cuz if there are none - panel will not be expanded)
      return !sendToInvalid || !alreadyDownloaded || notGlobal();
    }
    if (titleEmailStatus === EmailStatus.SoftInvalid) {
      if (!hardInvalidsExist) return notGlobal();
      // once get here, for sure hardInvalids so always show if "send anyway" is checked (cuz then email will be valid)
      // and if hardInvalids are not already downloaded
      return (sendToInvalid && !alreadyDownloaded) || notGlobal();
    }
    throw Error("There was an option you didn't catch....");
  };
  const displaySoftInvalidSection =
    softInvalidsExist ||
    (titleEmailStatus === EmailStatus.SoftInvalid && hardInvalidsExist && !alreadyDownloaded);
  const displayHardInvalidSection = hardInvalidsExist || missingsExist;

  const softInvalidsToDisplay = softInvalidEmails.map(e => ({
    email: e,
    loans: loans
      .filter(l => l.email === e)
      .map(l => l.loanNumber)
      .concat(titleCompanyEmail === e ? ['global'] : [])
      .join(', '),
  }));
  const hardInvalidsToDisplay = hardInvalidEmails
    .map(e => ({
      email: e,
      loans: loans
        .filter(l => l.email === e)
        .map(l => l.loanNumber)
        .join(', '),
    }))
    .concat(
      missingsExist
        ? [{ email: 'Missing', loans: missingLoans.map(l => l.loanNumber).join(', ') }]
        : [],
    );

  const downloadLoanIds = hardInvalidEmails
    .flatMap(e => loans.filter(l => l.email === e).map(l => l.loanId))
    .concat(missingLoans.map(l => l.loanId));

  const collapse = isSuccess && !displaySoftInvalidSection && !displayHardInvalidSection && !error;

  // #endregion

  useEffect(() => {
    if (downloadSuccess && downloadData) {
      convertToFileDownload(downloadData.base64, downloadData.fileName);
      apiPost('/api/call-tasks/email-sent', {
        loanIds: downloadLoanIds,
        note: 'Generated email message downloaded',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData, downloadSuccess]);

  useEffect(() => {
    // if hardInvalids were downloaded (and marked as sent)
    // if there are no loans in the valid or softInvalid list
    // then there is nothing to be sent, so emails are finished
    if ((downloadSuccess || downloadError) && !validsExist && !softInvalidsExist)
      setManualFollowups({ emailsSent: true });
  });

  return (
    <Fragment>
      <Accordion
        className={isOverride || emailsSent ? classes.disableChildren : ''}
        disabled={collapse || isOverride || emailsSent || titlePortalMode}
        expanded={!collapse && expanded && !titlePortalMode}
      >
        <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />}>
          <b>Invalid Emails</b>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          {displaySendToGlobalOption() && (
            <Fragment>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Checkbox
                    checked={sendToGlobal}
                    onClick={() => setManualFollowups({ sendToGlobal: !sendToGlobal })}
                    color="primary"
                  />
                }
                label="send invalid emails to global email"
              />
              <Divider className={classes.divider} />
            </Fragment>
          )}
          {isLoading && <LinearProgress />}
          {error && <ErrorCard error={error.toString()} />}
          {displaySoftInvalidSection && (
            <Fragment>
              <span css={{ color: 'red' }}>
                <b>{softInvalidEmails.length}</b> invalid emails
              </span>
              <List>
                {softInvalidsToDisplay.map(e => (
                  <ListItem key={e.email}>
                    <ListItemText primary={e.email} secondary={e.loans} />
                  </ListItem>
                ))}
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={sendToInvalid}
                      onClick={() => setManualFollowups({ sendToInvalid: !sendToInvalid })}
                      color="primary"
                      style={{ float: 'right' }}
                    />
                  }
                  label="send to these emails anyway"
                />
              </List>
            </Fragment>
          )}
          {displaySoftInvalidSection && displayHardInvalidSection && (
            <Divider className={classes.divider} />
          )}
          {downloadError && <ErrorCard error={downloadError.toString()} />}
          {displayHardInvalidSection && (
            <Fragment>
              <span css={{ color: 'red' }}>
                <b>{hardInvalidsToDisplay.length}</b> emails that are not working at all
              </span>
              <List>
                {hardInvalidsToDisplay.map(e => (
                  <ListItem key={e.email}>
                    <ListItemText primary={e.email} secondary={e.loans} />
                  </ListItem>
                ))}
              </List>
              <Button
                variant="contained"
                color="primary"
                endIcon={
                  loadingDownload ? (
                    <SpinChildren>
                      <LoopIcon />
                    </SpinChildren>
                  ) : (
                    <DownloadIcon />
                  )
                }
                disabled={!!downloadSuccess || !!downloadError}
                onClick={() =>
                  downloadEmails(
                    `/api/followups/downloadManualFollowupEmailsByLoanIds?${buildArrayFetchString(
                      downloadLoanIds,
                      'loanIds',
                    )}`,
                  )
                }
              >
                Download Manual Followups
              </Button>
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
