export const roleTypes = {
  AccountRep: 'AccountRep',
  Admin: 'Admin',
  BasicUser: 'BasicUser',
  CallCenterAdmin: 'CallCenterAdmin',
  CallCenterUser: 'CallCenterUser',
  CallCenterUserWithShipping: 'CallCenterUserWithShipping',
  ClientAccess: 'ClientAccess',
  ClientAdmin: 'ClientAdmin',
  CorrectionAdmin: 'CorrectionAdmin',
  ExecutiveManagement: 'ExecutiveManagement',
  Data: 'Data',
  DataAssociation: 'DataAssociation',
  Dev: 'Dev',
  Digitals: 'Digitals',
  DigitalsWithImportErrors: 'DigitalsWithImportErrors',
  DigitalTeamLead: 'DigitalTeamLead',
  MatchAndSort: 'MatchAndSort',
  Online: 'Online',
  ProcessingAdmin: 'ProcessingAdmin',
  QCTeamLead: 'QCTeamLead',
  Scanner: 'Scanner',
  SalesManager: 'SalesManager',
  SalesRep: 'SalesRep',
  ScannerWithNotFound: 'ScannerWithNotFound',
  ScanningTeamLead: 'ScanningTeamLead',
  ShippingTeamLead: 'ShippingTeamLead',
  TitlePortalSupport: 'TitlePortalSupport',
};

export const localStorageKeys = {
  IS_REFRESH_REQUIRED: 'is.refresh.required',
};
