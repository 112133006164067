/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import Dropdown from '../../ui/Dropdown';
import { apiFetch } from '../../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';

export type Printer = {
  id: number;
  name: string;
};

export default function PrinterDropdown({ onChange }: { onChange: (printer: Printer) => void }) {
  const [printers, setPrinters] = useState<Printer[]>();
  const [selected, setSelected] = useState<Printer>();
  const [loading, setLoading] = useState(true);
  const [recentPrinters, setRecentPrinters] = useLocalStorage<number[]>('recentPrinters', []);

  useEffect(() => {
    const getPrinters = async () => {
      const { data: printerList } = await apiFetch<Printer[]>('/api/printers/getAll');
      const printersSorted = recentPrinters
        .map(
          recentPrinterId => printerList.find(printer => printer.id === recentPrinterId) as Printer,
        )
        .filter(printer => printer)
        .concat(printerList.filter(printer => !recentPrinters.includes(printer.id)));
      setSelected(recentPrinters.length ? printersSorted[0] : undefined);
      setPrinters(printersSorted);
      setLoading(false);
    };
    getPrinters();
  }, []);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <Fragment>
      {loading ? (
        <div className="center-in-parent" style={{ height: '40px', width: '80px' }}>
          <CircularProgress size="20" disableShrink />
        </div>
      ) : (
        <Fragment>
          <Dropdown
            options={printers!.map(p => ({ label: p.name, value: p }))}
            onChange={(p: SelectOption<Printer>) => {
              setRecentPrinters(Array.from(new Set([p.value.id, ...recentPrinters])));
              setSelected(p.value);
            }}
            {...(selected && {
              value: { label: selected.name, value: selected },
            })}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
