import React, { Fragment, useEffect, useMemo, useState } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textareaWrap: {
      width: '100%',
      resize: 'none',
    },
  }),
);

type Props = {
  addNote: (note: string, isInternal: boolean) => void;
  initialText?: string;
  noteType?: string;
  placeholder?: string;
  isInternal?: boolean;
};

const AddNoteInput = ({ addNote, initialText, noteType, placeholder, isInternal }: Props) => {
  const [newNote, setNewNote] = useState('');

  useEffect(() => setNewNote(initialText ?? ''), [initialText]);

  const canAddNote = useMemo(() => !!newNote.trim().length, [newNote]);

  const classes = useStyles();

  const createNote = async () => {
    if (!canAddNote) {
      return;
    }

    addNote(newNote, isInternal ?? false);
    setNewNote('');
  };

  return (
    <Fragment>
      {noteType && <h2>{noteType} Notes</h2>}
      <p className="mt2 bold">New Note</p>
      <div>
        <TextareaAutosize
          minRows={3}
          aria-label="maximum height"
          placeholder={placeholder || 'Type here'}
          value={newNote}
          onChange={e => setNewNote(e.target.value)}
          className={classes.textareaWrap}
        />
      </div>
      <div className="df jcfe mb2">
        <Button color="primary" onClick={() => setNewNote('')}>
          Clear
        </Button>
        <Button color="primary" disabled={!canAddNote} onClick={createNote}>
          Add Note
        </Button>
      </div>
    </Fragment>
  );
};

export default AddNoteInput;
