/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';
import Chips from './Chips';
import { FilterTypes } from './types';
import { SearchIcon } from '../icons';
import { TextField } from '@mui/material';
import icons from './Icons';
import OnOutsideClick from '../../Utilities/OnOutsideClick';
import {
  button,
  container,
  resultsChip,
  searchButton,
  text,
  textfield,
  useStyles,
} from './HeaderFeaturesCss';

type Props = {
  dataTableFilterTypes?: FilterTypes;
  tableRef?: any;
  allowMultiSearch: boolean;
  isBulkSearchMode: boolean;
  setIsBulkSearchMode: Dispatch<SetStateAction<boolean>>;
};

const HeaderFeatures = ({
  dataTableFilterTypes,
  tableRef,
  allowMultiSearch,
  isBulkSearchMode,
  setIsBulkSearchMode,
}: Props) => {
  const classes = useStyles();

  const [isSearchByListAreaOpen, setIsSearchByListAreaOpen] = useState(false);
  const [textAreaText, setTextAreaText] = useState('');

  const isTextAreaEmpty = useMemo(() => !textAreaText.replace(/\s+/g, '').length, [textAreaText]);

  const exitBulkSearchMode = () => {
    setIsBulkSearchMode(false);
    tableRef.current.onQueryChange({
      ...tableRef.current.state.query,
      search: '',
    });
  };

  const search = () => {
    if (isTextAreaEmpty || !tableRef?.current) {
      return;
    }

    const newLineWhitespaceCommaIfFollowedByText = /[\n\s,]+(\w)/g;
    const ReplacedSearchText = textAreaText.replace(newLineWhitespaceCommaIfFollowedByText, ', $1');
    setIsBulkSearchMode(true);
    tableRef.current.onQueryChange({
      ...tableRef.current.state.query,
      search: ReplacedSearchText,
    });
    setIsSearchByListAreaOpen(false);
  };

  return (
    <div className="df jcsb aifs">
      {dataTableFilterTypes && <Chips filterTypes={dataTableFilterTypes} />}
      {allowMultiSearch && (
        <Fragment>
          {isSearchByListAreaOpen && (
            <OnOutsideClick onOutsideClick={() => setIsSearchByListAreaOpen(false)}>
              <div css={container}>
                <span
                  className={classes.closeIcon2}
                  onClick={() => setIsSearchByListAreaOpen(false)}
                >
                  <icons.Close />
                </span>
                <div>
                  <span css={text}>Bulk Search</span>
                  <div css={textfield}>
                    {!isTextAreaEmpty && (
                      <span className={classes.closeIcon} onClick={() => setTextAreaText('')}>
                        <icons.Close />
                      </span>
                    )}
                    <TextField
                      className={classes.textField}
                      placeholder="Paste a list of Loan IDs or App Numbers seperated by commas, spaces, or line breaks"
                      name="bulk-search"
                      variant="standard"
                      onChange={e => setTextAreaText(e.target.value)}
                      value={textAreaText}
                      InputProps={{ disableUnderline: true, className: classes.input }}
                      multiline
                      rows={5}
                    />
                  </div>
                </div>
                <div className="df ml3">
                  <button disabled={isTextAreaEmpty} onClick={search} css={[button, searchButton]}>
                    <Fragment>
                      Search
                      <SearchIcon />
                    </Fragment>
                  </button>
                </div>
              </div>
            </OnOutsideClick>
          )}
          <div className="df aic">
            {isBulkSearchMode && (
              <div css={resultsChip}>
                Bulk Search Results
                {!isSearchByListAreaOpen && (
                  <span className={classes.closeIcon3} onClick={exitBulkSearchMode}>
                    <icons.Close />
                  </span>
                )}
              </div>
            )}
            <button
              onClick={() => setIsSearchByListAreaOpen(true)}
              css={[button, { marginLeft: 24 }]}
            >
              Bulk Search
            </button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default HeaderFeatures;
