/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo, useState } from 'react';
import produce, { enableMapSet } from 'immer';
import Grid from '@mui/material/Grid';
import { apiFetch, apiPost } from '../../adalConfig';
import { TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import TextInput from '../ui/Inputs/TextInput';
import Button from '@mui/material/Button';
import BarcodeTextArea from '../Utilities/BarcodeTextArea';
import { useToaster } from '../../Hooks/toasters';

const BulkShipDocuments = () => {
  const [documentIds, setDocumentIds] = useState<Set<number>>(new Set());
  const [trackingNumber, setTrackingNumber] = useState('');
  const [dateSent, setDateSent] = useState<Date | null>(new Date());
  const [errorMessage, setErrorMessage] = useState('');

  const { successToaster, errorToaster } = useToaster();

  const documentIdsArray = useMemo(() => Array.from(documentIds), [documentIds.size]);

  enableMapSet();

  const wasDocumentUploaded = async (documentId: number): Promise<boolean> => {
    const { data: isUploaded } = await apiFetch<boolean>(
      '/api/documents/check-document-uploaded-status',
      {
        params: { documentId },
      },
    );

    return isUploaded;
  };

  const addDocumentId = async (barcode: string) => {
    if (!barcode) {
      return;
    }

    const documentId = +barcode.toLowerCase().replace('docprobe-', '');
    const includingAddedDocumentId = produce(documentIds, (draft: Set<number>) => {
      draft.add(documentId);
    });
    try {
      if (await wasDocumentUploaded(documentId)) {
        setDocumentIds(includingAddedDocumentId);
        setErrorMessage('');
      } else {
        setErrorMessage("This document wasn't uploaded into our system");
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const save = async () => {
    if (documentIds.size === 0 || !trackingNumber.length || !dateSent) {
      errorToaster('Please scan a barcode, add a Tracking number and set the Date Sent Fields');
      return;
    }

    try {
      const { data: response } = await apiPost('/api/shipping/create-manual-bulk-shipments', {
        docs: documentIdsArray,
        trackingNum: trackingNumber,
        dateSent: dateSent,
      });
      if (response) {
        successToaster('Successfully set as shipped');
        setDocumentIds(new Set());
        setTrackingNumber('');
        setDateSent(new Date());
      } else {
        errorToaster('Failed to set as shipped');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return (
    <div style={{ margin: '15px' }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <BarcodeTextArea
            addBarcode={addDocumentId}
            barcodes={documentIdsArray}
            errorMessage={errorMessage}
          />
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={3} style={{ height: '100%' }}>
            <Grid item xs={3}>
              <TextField
                label="Tracking Number"
                variant="outlined"
                value={trackingNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTrackingNumber(e.target.value)
                }
                style={{ marginTop: '30px' }}
              />
            </Grid>
            <Grid item xs={9}>
              <DatePicker
                customInput={<TextInput label="Select the date sent" />}
                selected={dateSent}
                onChange={date => setDateSent(date)}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={documentIds.size === 0 || !trackingNumber.length || !dateSent}
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkShipDocuments;
