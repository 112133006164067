import { TextField } from '@mui/material';
import React from 'react';
import MaskedInput, { InputState, MaskOptions } from 'react-input-mask';
import TextInput, { TextInputProps } from './TextInput';

interface MaskProps extends React.InputHTMLAttributes<HTMLInputElement> {
  mask: string;
  maskChar: string;
  formatChars?: { [key: string]: string };
  alwaysShowMask?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  beforeMaskedValueChange?(
    newState: InputState,
    oldState: InputState,
    userInput: string,
    maskOptions: MaskOptions,
  ): InputState;
}

interface InputMaskProps extends MaskProps, TextInputProps {
  useMaterialUI?: boolean;
}
export default function InputMask({
  mask,
  value,
  onChange,
  maskChar,
  beforeMaskedValueChange,
  useMaterialUI,
  onFocus,
  ...props
}: InputMaskProps) {
  return (
    <MaskedInput
      mask={mask}
      value={value}
      onChange={onChange}
      maskChar={maskChar}
      beforeMaskedValueChange={beforeMaskedValueChange}
      onFocus={onFocus}
    >
      {inputProps =>
        useMaterialUI ? (
          <TextField variant="standard" {...inputProps} {...props} type="tel" />
        ) : (
          <TextInput {...inputProps} {...props} type="tel" />
        )
      }
    </MaskedInput>
  );
}
