import { StepStatusTypes } from '../../ui/StepCircle';
import { StepData } from '../../Utilities/StepUI/Stepper';

export default (formData: string[][]): StepData[] => [
  {
    stepNumber: 1,
    name: 'Identify document',
    status: (formData[0] && StepStatusTypes.completed) || StepStatusTypes.disabled,
    completeData: formData[0],
  },
  {
    stepNumber: 2,
    name: 'Associate document with existing loan',
    status: (formData[1] && StepStatusTypes.completed) || StepStatusTypes.disabled,
    completeData: formData[1],
  },
  {
    stepNumber: 3,
    name: 'Verify information',
    status: (formData[2] && StepStatusTypes.completed) || StepStatusTypes.disabled,
    completeData: formData[2],
  },
  {
    stepNumber: 4,
    name: 'Audit',
    status: (formData[3] && StepStatusTypes.completed) || StepStatusTypes.disabled,
    completeData: formData[3],
  },
];
