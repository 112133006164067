import React from 'react';
import Stepper, { StepData } from '../Utilities/StepUI/Stepper';
import { StepStatusTypes } from '../ui/StepCircle';
import { useShipping, ShippingContext } from './useShipping';
import { shippingValidation } from '../../pages/Shipping';

const stepData = (shippingData: ShippingContext): StepData[] => [
  {
    stepNumber: 1,
    name: 'Shipping Information',
    status: shippingValidation(shippingData, 1)
      ? StepStatusTypes.completed
      : StepStatusTypes.disabled,
    completeData: [shippingData.investor.name, shippingData.trackingNum!],
  },
  {
    stepNumber: 2,
    name: 'Documents',
    status: shippingData.currentStep === 3 ? StepStatusTypes.completed : StepStatusTypes.disabled,
  },
  { stepNumber: 3, name: 'Validate', status: StepStatusTypes.disabled },
];

export default function ShippingSteps() {
  const [shipping, setShipping] = useShipping();
  return (
    <Stepper
      currentStep={shipping.currentStep}
      submitted={false}
      stepData={stepData(shipping)}
      onEditStep={stepNum => setShipping({ currentStep: stepNum as 1 | 2 })}
    />
  );
}
