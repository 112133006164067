/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useEffect, useState } from 'react';
import { FilterOption } from '../../../components/ui/DataTable/types';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Client, Investor } from '../../../globalTypes/objects';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../../Utils';
import AlertContext from '../../../components/AlertContext';
import { errorAlert } from '../../../components/ui/Alert';
import { stringifyUrl } from 'query-string';
import CircularProgress from '@mui/material/CircularProgress';
import { useFilters } from './useFilters';
import DatePicker from "react-datepicker";
import {subWeeks} from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '90%',
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4, 3),
    },
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& > div': {
        marginBottom: '8px',
      },
    },
    button: {
      marginTop: theme.spacing(1),
    },
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '110px',
      height: '39px',
    },
  }),
);

export type TitleCompany = {
  id: number;
  label: string;
};

const defaultFilterOptions = createFilterOptions();
const GenerateReport = () => {
  const [clientGroup, setClientGroup] = useState<FilterOption | null>(null);
  const [outstandingDocuments, setOutstandingDocuments] = useState<FilterOption | null>(null);
  const [mayHaveCure, setMayHaveCure] = useState<FilterOption | null>(null);
  const [documentStatus, setDocumentStatus] = useState<FilterOption[]>([]);
  const [failureSensitivity, setFailureSensitivity] = useState<FilterOption | null>(null);
  const [fromDate, setFromDate] = useState<Date>(subWeeks(new Date(), 3));;
  const [client, setClient] = useState<Client | null>(null);
  const [investor, setInvestor] = useState<Investor | null>(null);
  const [titleCompany, setTitleCompany] = useState<TitleCompany | null>(null);
  const [isExporting, setIsExporting] = useState(false);

  const {
    clientsFilter,
    outstandingDocumentsFilter,
    mayHaveCureFilter,
    documentStatusFilter,
    failureSensitivityFilter,
    clients,
    investors,
    titleCompanies,
  } = useFilters();

  useEffect(() => {
    if (clientsFilter.length && clientGroup === null) {
      setClientGroup(clientsFilter.find(f => f.active) ?? null);
    }

    if (outstandingDocumentsFilter.length && outstandingDocuments === null) {
      setOutstandingDocuments(outstandingDocumentsFilter.find(f => f.active) ?? null);
    }

    if (mayHaveCureFilter.length && mayHaveCure === null) {
      setMayHaveCure(mayHaveCureFilter.find(f => f.active) ?? null);
    }

    if (documentStatusFilter.length && documentStatus.length === 0) {
      setDocumentStatus(documentStatusFilter.filter(f => f.active));
    }

      if (failureSensitivityFilter.length && failureSensitivity === null) {
          setFailureSensitivity(failureSensitivityFilter.find(f => f.active) ?? null);
      }
  }, [clientsFilter, outstandingDocumentsFilter, mayHaveCureFilter, documentStatusFilter, failureSensitivityFilter]);

  const { setAlert } = useContext(AlertContext);
  const classes = useStyles();

  const exportReport = async () => {
    if (isExporting) {
      return;
    }

    try {
      setIsExporting(true);
      const url = stringifyUrl({
        url: `/api/reports/corrections-pipeline`,
        query: {
          documentStatus: documentStatus.map(s => s.id),
          failureSensitivity: failureSensitivity?.id,
          titleCompanies: titleCompany ? [titleCompany.id] : [],
          clientSearch: client ? [client.id] : [],
          investorSearch: investor ? [investor.id] : [],
          clients: clientGroup?.id,
          outstandingDocuments: outstandingDocuments?.id,
          mayHaveCure: mayHaveCure?.id,
          fromDate: fromDate?.toLocaleDateString('en-CA'),
        },
      });
      await downloadFile(url, 'Corrections Pipeline.csv');
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        setAlert(errorAlert(errorMessage || e.message));
      } else {
        setAlert(errorAlert(e.message));
      }
    } finally {
      setIsExporting(false);
    }
  };

  const showDateFilter = documentStatus.length > 0 && documentStatus.every(s => s.label == 'Corrected');

  return (
    <div className="m2 df jcc">
      <div className={classes.paper}>
        <div className={classes.filters}>
          <div>
            <Autocomplete
              options={clientsFilter}
              getOptionLabel={(option: FilterOption) => option.label || ''}
              value={clientGroup}
              onChange={(event: any, newValue: FilterOption | null) => setClientGroup(newValue)}
              style={{ width: '300px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Client group" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={outstandingDocumentsFilter}
              getOptionLabel={(option: FilterOption) => option.label || ''}
              value={outstandingDocuments}
              onChange={(event: any, newValue: FilterOption | null) =>
                setOutstandingDocuments(newValue)
              }
              style={{ width: '300px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Outstanding Documents" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={mayHaveCureFilter}
              getOptionLabel={(option: FilterOption) => option.label || ''}
              value={mayHaveCure}
              onChange={(event: any, newValue: FilterOption | null) => setMayHaveCure(newValue)}
              style={{ width: '300px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="May Have Cure" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={documentStatusFilter}
              getOptionLabel={(option: FilterOption) => option.label || ''}
              value={documentStatus}
              onChange={(event: any, newValue: FilterOption[]) => setDocumentStatus(newValue)}
              style={{ width: '300px' }}
              autoHighlight
              multiple
              renderInput={params => (
                <TextField {...params} label="Document Status" variant="outlined" />
              )}
            />
          </div>
            <div>
                <Autocomplete
                    options={failureSensitivityFilter}
                    getOptionLabel={(option: FilterOption) => option.label || ''}
                    value={failureSensitivity}
                    onChange={(event: any, newValue: FilterOption | null) => setFailureSensitivity(newValue)}
                    style={{ width: '300px' }}
                    autoHighlight
                    renderInput={params => (
                        <TextField {...params} label="Failure Sensitivity" variant="outlined" />
                    )}
                />
            </div>
          <div>
            <Autocomplete
              options={clients}
              getOptionLabel={(option: Client) => option?.company || ''}
              value={client}
              onChange={(event: any, newValue: Client | null) => setClient(newValue)}
              style={{ width: '400px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Choose a client" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={investors}
              getOptionLabel={(option: Investor) => option.name || ''}
              value={investor}
              onChange={(event: any, newValue: Investor | null) => setInvestor(newValue)}
              style={{ width: '400px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Choose an investor" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={titleCompanies}
              filterOptions={(options, state) =>
                defaultFilterOptions(options, state).slice(0, 50) as TitleCompany[]
              }
              getOptionLabel={(option: TitleCompany) => option.label || ''}
              value={titleCompany}
              onChange={(event: any, newValue: TitleCompany | null) => setTitleCompany(newValue)}
              style={{ width: '400px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Choose a title company" variant="outlined" />
              )}
            />
          </div>
        </div>
          <div>
            <DatePicker
                disabled={!showDateFilter}
                openToDate={fromDate}
                onChange={(dateTime) => setFromDate(dateTime ?? new Date())}
                placeholderText={`From ${fromDate.toLocaleDateString()}`}
                maxDate={new Date()}
                css={{
                  display: `${showDateFilter ? 'block' : 'none'}`,
                  border: '1px solid #c5c4c6',
                  borderRadius: '4px',
                  backgroundColor: 'transparent',
                  height: '37px !important',
                }}
            />
          </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className="mt1">
            {isExporting ? (
              <div className={`${classes.loaderButton} center-in-parent`}>
                <CircularProgress size="20" disableShrink />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<GetAppIcon />}
                onClick={exportReport}
              >
                Export
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateReport;
