/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Container,
  LinearProgress,
  Paper,
  StyledEngineProvider,
  Table,
  TableContainer,
  Theme,
  ThemeProvider,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import dotObject from 'dot-object';
import axios, { CancelTokenSource } from 'axios';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { apiFetch } from '../../adalConfig';
import { TitleCompany } from '../../globalTypes/objects';
import TableData from './TableData';
import TableHeader from './TableHeader';
import { TitleCompaniesContextProvider, useTitleCompanies } from './useTitleCompanies';
import TableBottom from './TableBottom';
import TableToolbar from './TableToolbar';
import useFileExport from '../ui/DataTable/FileExport';
import theme from './TitleCompanyStyles';
import ConsolidationModal from './ConsolidationModal';
import EditTitleCompanyModal from './EditTitleCompanyModal';
import LeavePageWarning from './LeavePageWarning';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


const disabledPaperStyles = {
  backgroundColor: 'lightgray !important',
  pointerEvents: 'none',
};

type FetchDataParams = {
  searchText: string;
  orderBy: { column: string | number; directionAscending: boolean };
  offset: number;
  fetch: number;
  getRowCount: boolean;
  isExporting: boolean;
};

export default function TitleCompanies({ history }) {
  return (
    <TitleCompaniesContextProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <TitleCompaniesTable history={history} />
        </ThemeProvider>
      </StyledEngineProvider>
    </TitleCompaniesContextProvider>
  );
}

function TitleCompaniesTable({ history }) {
  const [
    {
      paginationOptions: { page, rowsPerPage },
      sortOptions,
      searchText,
      isExporting,
      processingConsolidation,
    },
    setTitleCompanies,
  ] = useTitleCompanies();
  const [xhr, setXhr] = useState<CancelTokenSource | undefined>(undefined);
  const [prevParams, setPrevParams] = useState<FetchDataParams>({} as FetchDataParams);
  const [prevProcessingConsolidation, setPrevProcessingConsolidation] = useState<boolean>(false);

  const getParams = useCallback(
    (): FetchDataParams => ({
      searchText,
      orderBy: sortOptions,
      offset: page * rowsPerPage,
      fetch: rowsPerPage,
      getRowCount: true,
      isExporting,
    }),
    [searchText, sortOptions, page, rowsPerPage, isExporting],
  );

  const fileExport = useFileExport<TitleCompany>();
  const exportDatatable = useCallback(
    (data: TitleCompany[]) => {
      fileExport(data, 'Title Companies');
    },
    [fileExport],
  );

  const fetchData = useCallback(
    async (params: FetchDataParams) => {
      /*
    We want to make sure that the latest filter is the one that's being displayed
    rather than the the latest request that returned
    (for example when a user selects two filters one after the other but the first request takes longer to run,
      we want to cancel the first request so that the datatable displays the the results of the latest filters selected)
    */
      if (xhr) {
        xhr.cancel();
      }
      const fetchRequest = axios.CancelToken.source();
      setXhr(fetchRequest);

      const {
        data: { dataTableRows: data, count },
      } = await apiFetch<{ dataTableRows: TitleCompany[]; count: number }>(
        '/api/titleCompanies/all',
        {
          params: dotObject.dot(params),
          cancelToken: fetchRequest.token,
        },
      );

      if (isExporting) {
        exportDatatable(data);
        setTitleCompanies({ isExporting: false });
      } else {
        setTitleCompanies({ data, paginationOptions: { page, rowsPerPage, totalCount: count } });
      }

      window.scrollTo(0, 0);
      setXhr(undefined);
      setPrevParams(params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [exportDatatable, isExporting, page, rowsPerPage, xhr, processingConsolidation],
  );

  useEffect(() => {
    const params = getParams();
    setPrevProcessingConsolidation(processingConsolidation);

    if (isExporting) {
      fetchData(params);
      return;
    }

    // when finished consolidating (processingConsolidation **changed** to false)
    if (prevProcessingConsolidation === true && processingConsolidation === false) {
      fetchData(params);
      return;
    }

    // we want to check if params are the same as the previous params before fetching
    if (isEqual(omit(params, 'isExporting'), omit(prevParams, 'isExporting'))) {
      console.log('not fetching because params are the same');
      return;
    }
    fetchData(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortOptions, searchText, isExporting, processingConsolidation]);

  return (
    <Container
      css={{
        marginLeft: 0,
        marginRight: 0,
        padding: 32,
        maxWidth: '100% !important',
        position: 'relative',
      }}
    >
      <Paper
        css={[
          xhr ? disabledPaperStyles : {},
          { maxHeight: 'calc(100vh - 64px)', overflow: 'auto' },
        ]}
      >
        <TableToolbar />
        <TableContainer css={{ position: 'relative', maxHeight: 'calc(100% - 128px)' }}>
          <Table stickyHeader>
            <TableHeader />
            <TableData history={history} />
            <TableBottom />
            {xhr && (
              <LinearProgress css={{ position: 'absolute !important', width: '100%', bottom: 0 }} />
            )}
          </Table>
        </TableContainer>
      </Paper>
      <ConsolidationModal />
      <EditTitleCompanyModal />
      <LeavePageWarning />
    </Container>
  );
}
