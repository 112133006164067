/** @jsx jsx */
/* eslint-disable  react/jsx-boolean-value */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { apiFetch } from '../adalConfig';
import Header from '../components/ui/Header';
import { useClients } from '../Hooks/useClients';
import { useToaster } from '../Hooks/toasters';
import { Client } from '../globalTypes/objects';
import { LenderAlias } from '../components/LenderAliases/LenderAliasObjects';
import AliasRow from '../components/LenderAliases/AliasRow';

export default function LenderAliases() {
  const clients = useClients();
  const { errorToaster } = useToaster();

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedClient, setSelectedClient] = useState<Client | undefined>();
  const [onlyShowPending, setOnlyShowPending] = useState<boolean>(false);
  const [lenderAliases, setLenderAliases] = useState<LenderAlias[]>([]);
  const [rejectedAliases, setRejectedAliases] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    getLenderAliases()
      .then(({ data }) => {
        setLenderAliases(data);
      })
      .catch(({ e }) => {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getLenderAliases() {
    return apiFetch<LenderAlias[]>('/api/LenderAlias/Lender-Aliases');
  }

  const filteredAliases = useMemo(() => {
    const clientAliases =
      selectedClient === undefined || selectedClient === null
        ? lenderAliases
        : lenderAliases.filter(la =>
            la.clientAliases.map(ca => ca.clientId).includes(selectedClient?.id),
          );
    const pendingAliases = !onlyShowPending
      ? lenderAliases
      : lenderAliases.filter(la => la.clientAliases.map(ca => ca.aliasType).includes(null));

    setPage(0);
    return clientAliases.filter(a => pendingAliases.includes(a));
  }, [selectedClient, lenderAliases, onlyShowPending]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Fragment>
      <Header headerText="Lender Aliases" />
      {loading ? (
        <Box css={{ width: '30%', position: 'fixed', top: '50%', left: '35%' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Fragment>
          <div
            css={{ paddingTop: 32, paddingRight: 32, display: 'flex', justifyContent: 'flex-end' }}
          >
            <ToggleButtonGroup
              size="small"
              exclusive
              value={onlyShowPending}
              onChange={(e, value: boolean) => setOnlyShowPending(value)}
              color="primary"
              sx={{ paddingRight: '32px' }}
            >
              <ToggleButton value={false}>All</ToggleButton>
              <ToggleButton value={true}>Pending</ToggleButton>
            </ToggleButtonGroup>
            <Autocomplete
              sx={{ width: 500 }}
              options={clients}
              getOptionLabel={option => option?.company ?? ''}
              onChange={(event: any, value: Client | undefined) => setSelectedClient(value)}
              renderInput={params => (
                <TextField {...params} label="Client Filter" placeholder="Choose a client..." />
              )}
            />
          </div>
          <div css={{ margin: 32, marginBottom: 64 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {filteredAliases
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(alias => (
                      <AliasRow
                        key={alias.lenderName}
                        alias={alias}
                        rejectedAliases={rejectedAliases}
                        setRejectedAliases={setRejectedAliases}
                        clients={clients}
                      />
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={filteredAliases.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
