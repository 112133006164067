/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ChangeEvent, Fragment, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EmailStatus, EmailWithStatus, useManualFollowups } from './useManualFollowups';
import useEmailStyles from './ManualFollowupStyles';
import EmailValidationInput from './EmailValidationInput';
import produce from 'immer';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';

type EmailInput = EmailWithStatus & {
  id: ReturnType<typeof crypto.randomUUID>;
  sendToInvalidAdditional: boolean;
};

const emptyEmailInputValue = () =>
  ({
    email: '',
    status: EmailStatus.Valid,
    id: crypto.randomUUID(),
    sendToInvalidAdditional: false,
  } as EmailInput);

function getStyles(status: EmailStatus, sendToInvalidAdditional: boolean) {
  if (status === EmailStatus.Valid) {
    return {};
  }

  if (status === EmailStatus.HardInvalid || !sendToInvalidAdditional) {
    return { textDecoration: 'line-through' };
  }

  return {};
}

export default function AdditionalRecipientPanel() {
  const classes = useEmailStyles();
  const [
    { emailsSent, isAdditionalRecipient, titlePortalMode },
    setManualFollowups,
  ] = useManualFollowups();

  const [expanded, setExpanded] = useState(false);
  const [emailInputs, setEmailInputs] = useState<EmailInput[]>([emptyEmailInputValue()]);

  const addEmail = () => {
    const emptyInput = emailInputs.find(input => input.email === '');

    if (emptyInput === undefined) {
      setEmailInputs(current => [
        ...current.filter(({ email }) => email.length),
        emptyEmailInputValue(),
      ]);
    }
  };

  const setValidatedAdditionalRecipients = (
    { id, email, status, sendToInvalidAdditional }: EmailInput,
    index: number,
  ) => {
    const newEmailInputs = produce(emailInputs, (draft: EmailInput[]) => {
      draft[index] = { id, email, status, sendToInvalidAdditional };
    });
    setEmailInputs(newEmailInputs);

    const validatedAdditionalRecipients = newEmailInputs
      .filter(({ email, status, sendToInvalidAdditional }) => {
        if (!email.length) {
          return false;
        }

        if (status === EmailStatus.Valid) {
          return true;
        }

        return status === EmailStatus.SoftInvalid && sendToInvalidAdditional;
      })
      .map(({ email }) => email);

    setManualFollowups({
      validatedAdditionalRecipients: [...new Set(validatedAdditionalRecipients)], // removing duplicate email addresses
    });
  };

  return (
    <Accordion
      disabled={emailsSent || titlePortalMode}
      expanded={expanded && !titlePortalMode}
      className={emailsSent ? classes.disableChildren : ''}
    >
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />}>
        <b>Additional Recipient</b>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              checked={isAdditionalRecipient}
              onChange={() => setManualFollowups({ isAdditionalRecipient: !isAdditionalRecipient })}
              color="primary"
            />
          }
          label="Add additional recipient"
        />
        {isAdditionalRecipient &&
          emailInputs.map(({ id, email, status, sendToInvalidAdditional }, index) => (
            <Fragment key={id}>
              <div className="df jcsb aic">
                <EmailValidationInput
                  initialValue={email}
                  setValidatedEmail={data => {
                    setValidatedAdditionalRecipients(
                      { ...data, id, sendToInvalidAdditional },
                      index,
                    );
                  }}
                  styles={getStyles(status, sendToInvalidAdditional)}
                />
                {index === emailInputs.length - 1 && (
                  <Tooltip title="Add another email">
                    <AddCircleIcon color="primary" onClick={addEmail} />
                  </Tooltip>
                )}
              </div>
              {status === EmailStatus.HardInvalid && (
                <span css={{ color: 'red' }}>Email is not working at all</span>
              )}
              {status === EmailStatus.SoftInvalid && (
                <div className="df jcsb aic">
                  <span css={{ color: 'red' }}>Email is invalid</span>
                  <FormControlLabel
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        checked={sendToInvalidAdditional}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setValidatedAdditionalRecipients(
                            { id, email, status, sendToInvalidAdditional: e.target.checked },
                            index,
                          )
                        }
                        color="primary"
                        style={{ float: 'right' }}
                      />
                    }
                    label="send to this email anyway"
                  />
                </div>
              )}
            </Fragment>
          ))}
      </AccordionDetails>
    </Accordion>
  );
}
