/** @jsx jsx */
import { jsx } from '@emotion/core';
import ManualFollowUps from '../../FollowUps/ManualFollowups';
import { DocumentType } from '../../../globalTypes/objects';
import { UpdateContactInfoData } from '../UpdateContactInfoPanel';
import { Note, RowData, SidebarOptions } from '../types';
import { LoanContact } from '../../FollowUps/ManualFollowups/useManualFollowups';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  setDatatableData: Dispatch<SetStateAction<RowData[]>>;
  datatableData: RowData[];
  selectedRows: RowData[];
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
  updateContactInfoData: (updatedInfo: UpdateContactInfoData) => void;
};

const EmailSection = ({
  setDatatableData,
  datatableData,
  selectedRows,
  setSidebarOption,
  updateContactInfoData,
}: Props) => {
  const updateNotesForSpecificRows = (notes: Note[]) => {
    setDatatableData(d =>
      d.map(row => {
        row.pastNotes = notes.filter(n => n.loanId === row.id).concat(row.pastNotes);
        return row;
      }),
    );
  };

  const updateTitlePortalContacts = (contacts: LoanContact[]) => {
    setDatatableData(d =>
      d.map(row => {
        row.titlePortalContactEmails = row.titlePortalContactEmails.concat(
          contacts.filter(c => c.loanId === row.id).map(c => c.email),
        );
        return row;
      }),
    );
  };

  return (
    <ManualFollowUps
      loans={[...new Set(selectedRows)].map(d => ({
        loanId: d.id,
        loanNumber: d.appNumber,
        email:
          d.loanLevelContactInformation.email?.toLowerCase() ??
          datatableData[0]?.titleCompanyEmailGlobal?.toLowerCase(),
        titlePortalEmails: d.titlePortalContactEmails,
        missingDocuments: d.missingDocuments
          .split(',')
          .map(doc => DocumentType[doc.replace(/\s/g, '')]),
        clientId: d.clientInfo.id,
      }))}
      titleCompanyEmail={datatableData[0]?.titleCompanyEmailGlobal?.toLowerCase()}
      closeEmails={() => setSidebarOption(undefined)}
      titleCompanyId={selectedRows[0]?.titleCompanyId}
      updateContactInfoData={updateContactInfoData}
      allEmails={[
        ...new Set(
          datatableData
            .flatMap(data => [
              data.loanLevelContactInformation.email?.toLowerCase(),
              ...data.titlePortalContactEmails?.map(e => e?.toLowerCase()),
            ])
            .concat(datatableData[0]?.titleCompanyEmailGlobal?.toLowerCase())
            .filter(Boolean),
        ),
      ]}
      updateNotesForSpecificRows={updateNotesForSpecificRows}
      updateTitlePortalContacts={updateTitlePortalContacts}
    />
  );
};

export default EmailSection;
