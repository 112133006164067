/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Checkbox,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { format } from 'date-fns';
import React, { Fragment, useMemo, useState } from 'react';
import { apiPost } from '../../../adalConfig';
import { useToaster } from '../../../Hooks/toasters';
import { ShippingIcon } from '../../ui/icons';
import SpinChildren from '../../ui/SpinChildren';
import ShipRequestsFilters from './Filters';
import Footer from './Footer';

export type ShipRequestsState = {
  loading: boolean;
  filterSelected: boolean;
  filteredDocuments: Document[];
  documents: Document[];
  paginatedDocuments: Document[];
  selectedIds: Set<number>;
};

export type Document = {
  id: number;
  clientId: number;
  client: string;
  investor: string;
  loanNumber: string;
  docType: string;
  format: string;
  dateShipped: Date;
  trackingNumber: string;
  requiresOriginal: boolean;
};

export const initialState: ShipRequestsState = {
  loading: false,
  filterSelected: false,
  documents: [],
  filteredDocuments: [],
  paginatedDocuments: [],
  selectedIds: new Set<number>(),
};

export default function ShipRequests() {
  const [state, setState] = useState(initialState);
  const setStateFunc = (obj: Partial<ShipRequestsState>) =>
    setState(sr => ({ ...sr, ...(obj as ShipRequestsState) }));

  return (
    <Container
      css={{
        marginLeft: 0,
        marginRight: 0,
        padding: 32,
        maxWidth: '100% !important',
        position: 'relative',
      }}
    >
      <ShipRequestsFilters state={[state, setStateFunc]} />
      <ShipRequestsTable state={[state, setStateFunc]} />
    </Container>
  );
}

function ShipRequestsTable({ state }) {
  const [
    { paginatedDocuments, loading, filterSelected, filteredDocuments, selectedIds },
    setState,
  ] = state;

  const handleCheckboxClick = (documentId: number) => {
    const newSelectedIds = new Set(selectedIds);
    selectedIds.has(documentId)
      ? newSelectedIds.delete(documentId)
      : newSelectedIds.add(documentId);
    setState({ selectedIds: newSelectedIds });
  };
  const allSelected = useMemo(
    () =>
      selectedIds.size >= paginatedDocuments.length &&
      paginatedDocuments.every(d => selectedIds.has(d.id)),
    [paginatedDocuments, selectedIds],
  );
  const handleSelectAllCheckboxClick = () => {
    const newSelectedIds = new Set(selectedIds);
    paginatedDocuments.forEach(d =>
      allSelected ? newSelectedIds.delete(d.id) : newSelectedIds.add(d.id),
    );
    setState({ selectedIds: newSelectedIds });
  };

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { successToaster, errorToaster } = useToaster();
  const stageForShipping = async (documentIds: number[]) => {
    setLoadingSubmit(true);
    try {
      await apiPost('/api/shipRequests/stageForShipping', documentIds);
      successToaster(`${documentIds.length} documents staged for shipping`);
    } catch (error) {
      errorToaster('Documents not successfully staged for shipping');
    }
    setLoadingSubmit(false);
  };

  return (
    <Fragment>
      <Paper css={{ maxHeight: 'calc(100vh - 64px)', overflow: 'auto' }}>
        <TableContainer css={{ position: 'relative', maxHeight: 'calc(100% - 300px)' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={paginatedDocuments.length > 0 && allSelected}
                    onClick={handleSelectAllCheckboxClick}
                  />
                </TableCell>
                <TableCell align="left">Document Id</TableCell>
                <TableCell align="left">Client</TableCell>
                <TableCell align="left">Investor</TableCell>
                <TableCell align="left">Loan Number</TableCell>
                <TableCell align="left">Document Type</TableCell>
                <TableCell align="left">Format</TableCell>
                <TableCell align="left">Date Last Shipped</TableCell>
                <TableCell align="left">Latest Tracking Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedDocuments.map(document => (
                <TableRow key={document.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={selectedIds.has(document.id)}
                      onClick={() => handleCheckboxClick(document.id)}
                    />
                  </TableCell>
                  <TableCell align="left">{document.id}</TableCell>
                  <TableCell align="left">{document.client}</TableCell>
                  <TableCell align="left">{document.investor}</TableCell>
                  <TableCell align="left">{document.loanNumber}</TableCell>
                  <TableCell align="left">{document.docType}</TableCell>
                  <TableCell align="left">{document.format}</TableCell>
                  <TableCell align="left">
                    {document.dateShipped
                      ? format(new Date(document.dateShipped), 'MM/dd/yyyy')
                      : ''}
                  </TableCell>
                  <TableCell align="left">{document.trackingNumber}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {!filterSelected && (
              <caption
                // @ts-ignore
                css={{
                  textAlign: 'center !important',
                  fontSize: '24px !important',
                  fontWeight: 'bold !important',
                }}
              >
                Please select a filter to view documents
              </caption>
            )}
            {filteredDocuments.length === 0 && !loading && filterSelected && (
              <caption
                // @ts-ignore
                css={{
                  textAlign: 'center !important',
                  fontSize: '24px !important',
                  fontWeight: 'bold !important',
                }}
              >
                There are no documents that match your search
              </caption>
            )}

            <Footer state={state} />
          </Table>
        </TableContainer>
      </Paper>
      {selectedIds.size > 0 && (
        <Button
          variant="contained"
          color="primary"
          css={{
            ...(loadingSubmit && { pointerEvents: 'none' }),
            ...{ float: 'right', margin: '16px !important', marginRight: '50 !important' },
          }}
          onClick={() => stageForShipping([...selectedIds])}
          endIcon={
            loadingSubmit ? (
              <SpinChildren>
                <LoopIcon />
              </SpinChildren>
            ) : (
              <ShippingIcon />
            )
          }
        >
          Ship a copy
        </Button>
      )}
    </Fragment>
  );
}
