/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';

import isEmpty from 'lodash/isEmpty';
import { loanErrorIssues, FormData } from '../ImportErrorsUtils';
import states from '../../../../states';
import Dropdown from '../../../ui/Dropdown';
import SearchSelect from '../../../ui/SearchSelect';
import TextInput from '../../../ui/Inputs/TextInput';
import ToolTip from '../../../ui/ToolTip';
import AddNewContactWrapper from '../../../Utilities/Forms/AddNewContactWrapper';
import colors from '../../../../styles/colors';
import ZipInput from '../../../ui/Inputs/ZipInput';
import AddNewContactPlus from './AddNewContactPlus';
import { apiFetch } from '../../../../adalConfig';
import {
  Investor,
  TitleCompany,
  ContactType,
  ContactInformation,
} from '../../../../globalTypes/objects';

// #region css

const fieldWithAddStyles: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 32,
};

const addToolTip: CSSObject = {
  backgroundColor: colors.grayLight,
  cursor: 'pointer',
  borderRadius: '50%',
};

const toolTipStyles: CSSObject = {
  left: '-48px',
  '&:after': { left: 17 },
};
// #endregion

// #region pure functions
const searchInvestors = async (partialString: string) => {
  const { data } = await apiFetch<Investor[]>(
    `/api/investors/searchInvestors?searchString=${partialString}`,
  );
  return data.map(investor => {
    const investorName = investor.name;
    const investorAddress = `${stringsOrEmpty(investor.address)} ${stringsOrEmpty(
      investor.city,
    )} ${stringsOrEmpty(investor.state)} ${stringsOrEmpty(investor.zip)}`;
    return {
      label: investorName,
      subLabel: investorAddress,
      value: investor.id,
    };
  });
};

const searchTitleCompanies = async (partialString: string) => {
  const { data } = await apiFetch<TitleCompany[]>(
    `/api/titleCompanies/searchTitleCompanies?searchString=${partialString}`,
  );
  return data.map(titleCompany => {
    const titleCompanyName = titleCompany.name;
    const titleCompanyAddress = titleCompany.address;
    return {
      label: titleCompanyName,
      subLabel: titleCompanyAddress,
      value: titleCompany.id,
    };
  });
};

const stringsOrEmpty = (string: string) => string || '';

const getContactInformation = (type: 'investor' | 'titleCompany') => (
  info: FormData,
): ContactInformation => ({
  name: info[`${type}Name`],
  address: info[`${type}Address`],
  city: info[`${type}City`],
  state: info[`${type}State`],
  zip: info[`${type}Zip`],
  phone: info[`${type}Phone`],
  fax: info[`${type}Fax`],
  email: info[`${type}Email`],
  type: `${type.split(/(?=[A-Z])/)[0]}Error` as ContactType,
});
// #endregion

const defaultState = {
  borrowerFirstName: '',
  borrowerLastName: '',
  propertyAddress: '',
  propertyCity: '',
  propertyState: '',
  propertyZip: '',
  propertyCounty: '',
  investorName: '',
  investorAddress: '',
  titleCompanyName: '',
  titleCompanyAddress: '',
};

const DEBOUNCE_RATE = 800;

const storeData = (id: number, state) => {
  window.localStorage.setItem(`state${id}`, JSON.stringify(state));
};

const getData = (id: number) => JSON.parse(window.localStorage.getItem(`state${id}`)!);

type ErrorFormProps = {
  errorId: number;
  onChange: (data: FormData) => any;
  issues: string[];
  contactInfo: FormData;
};

export default class ErrorForm extends Component<ErrorFormProps, FormData> {
  state = defaultState;
  // #region functions

  componentDidMount() {
    const state = getData(this.props.errorId);
    this.setState(state, () => {
      const { ...formData } = this.state;
      this.props.onChange(formData);
    });
    window.onbeforeunload = () => {
      storeData(this.props.errorId, this.state);
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errorId !== nextProps.errorId) {
      this.setState(defaultState);
    }
  }

  componentWillUnmount() {
    const empty = Object.values(this.state).every(x => isEmpty(x));
    !empty && storeData(this.props.errorId, this.state);
  }

  // react-select expects a callback
  // eslint-disable-next-line react/sort-comp
  loadInvestorOptions = debounce((input, callback) => {
    searchInvestors(input).then(callback);
  }, DEBOUNCE_RATE);

  // react-select expects a callback
  loadTitleCompanyOptions = debounce((input, callback) => {
    searchTitleCompanies(input).then(callback);
  }, DEBOUNCE_RATE);

  makeOnChange = (name: keyof FormData) => (value: string) => {
    this.setState(({ [name]: value } as unknown) as Pick<FormData, keyof FormData>, () => {
      const { ...formData } = this.state;
      this.props.onChange(formData);
    });
  };

  makeOnMultiChange = (name: 'investor' | 'titleCompany') => (selection?: SelectOption<string>) => {
    const { value, label, subLabel } = selection || ({} as SelectOption<string>); // selection can be null
    this.setState(
      ({
        [`${name}Name`]: label,
        [`${name}Address`]: subLabel,
        [`${name}Id`]: value,
      } as unknown) as Pick<FormData, keyof FormData>,
      () => {
        const { ...formData } = this.state;
        this.props.onChange(formData);
      },
    );
  };

  // #endregion

  render() {
    const {
      borrowerFirstName,
      borrowerLastName,
      propertyAddress,
      propertyCity,
      propertyState,
      propertyZip,
      propertyCounty,
      investorName,
      investorAddress,
      titleCompanyName,
      titleCompanyAddress,
    } = this.state;
    const { issues, contactInfo, errorId } = this.props;
    return (
      <React.Fragment>
        {issues.includes(loanErrorIssues.BORROWER_NAME) && (
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextInput
              label="Borrower first name"
              value={borrowerFirstName}
              error={!borrowerFirstName}
              labelOverrides={{ width: 230 }}
              onChange={e => this.makeOnChange('borrowerFirstName')(e.target.value)}
            />
            <TextInput
              label="Borrower last name"
              value={borrowerLastName}
              error={!borrowerLastName}
              labelOverrides={{ width: 230 }}
              onChange={e => this.makeOnChange('borrowerLastName')(e.target.value)}
            />
          </div>
        )}
        {issues.includes(loanErrorIssues.PROPERTY_ADDRESS) && (
          <TextInput
            label="Property street"
            value={propertyAddress}
            error={!propertyAddress}
            onChange={e => this.makeOnChange('propertyAddress')(e.target.value)}
            labelOverrides={{ marginTop: 32 }}
          />
        )}
        {issues.includes(loanErrorIssues.PROPERTY_CITY) && (
          <TextInput
            label="Property city"
            value={propertyCity}
            error={!propertyCity}
            labelOverrides={{ marginTop: 32 }}
            onChange={e => this.makeOnChange('propertyCity')(e.target.value)}
          />
        )}
        {issues.includes(loanErrorIssues.PROPERTY_STATE) && (
          <div css={{ marginTop: 32, maxWidth: 230 }}>
            <Dropdown
              label="Property state"
              isError={!propertyState} // need error state
              options={Object.entries(states).map(([value, label]) => ({
                label,
                value,
              }))}
              {...(propertyState && {
                value: { label: states[propertyState], value: propertyState },
              })}
              placeholder="Select State"
              onChange={selection => this.makeOnChange('propertyState')(selection.value)}
            />
          </div>
        )}
        {issues.includes(loanErrorIssues.PROPERTY_ZIP) && (
          <div css={{ maxWidth: 230, marginTop: 32 }}>
            <ZipInput
              label="Property zip"
              value={propertyZip}
              onChange={e => this.makeOnChange('propertyZip')(e.target.value)}
              error={!propertyZip || !(propertyZip.length === 5 || propertyZip.length === 10)}
            />
          </div>
        )}
        {issues.includes(loanErrorIssues.PROPERTY_COUNTY) && (
          <TextInput
            label="Property county"
            value={propertyCounty}
            error={!propertyCounty}
            labelOverrides={{ marginTop: 32, maxWidth: 230 }}
            onChange={e => this.makeOnChange('propertyCounty')(e.target.value)}
          />
        )}
        {(issues.includes(loanErrorIssues.INVESTOR_NAME) ||
          issues.includes(loanErrorIssues.SERVICER)) && (
          <Fragment>
            <div css={fieldWithAddStyles}>
              <div css={{ width: 412 }}>
                <SearchSelect
                  key={errorId}
                  placeholder="Search Investors"
                  loadOptions={this.loadInvestorOptions}
                  label="Investor/Servicer name"
                  filterConfig={{ matchFrom: 'all' }}
                  onChange={selection => this.makeOnMultiChange('investor')(selection)}
                  isError={!investorName}
                  {...(investorName && {
                    value: {
                      label: investorName,
                    },
                  })}
                  isAsync
                />
              </div>
              <ToolTip
                toolTipText="Add new contact"
                wrapOverrides={addToolTip}
                toolTipOverrides={toolTipStyles}
              >
                <AddNewContactWrapper
                  contactInformation={getContactInformation('investor')(contactInfo)}
                  onSave={newContact => this.makeOnMultiChange('investor')(newContact)}
                >
                  <AddNewContactPlus />
                </AddNewContactWrapper>
              </ToolTip>
            </div>
            {investorName && (
              <TextInput
                disabled
                label="Investor/Servicer address"
                labelOverrides={{ marginTop: 32 }}
                value={investorAddress}
              />
            )}
          </Fragment>
        )}
        {(issues.includes(loanErrorIssues.TITLE_COMPANY_NAME) ||
          issues.includes(loanErrorIssues.TITLE_COMPANY_ADDRESS)) && (
          <Fragment>
            <div css={fieldWithAddStyles}>
              <div css={{ width: 412 }}>
                <SearchSelect
                  key={errorId}
                  placeholder="Search Title Companies"
                  loadOptions={this.loadTitleCompanyOptions}
                  label="Title company name"
                  filterConfig={{ matchFrom: 'all' }}
                  onChange={selection => this.makeOnMultiChange('titleCompany')(selection)}
                  isError={!titleCompanyName}
                  {...((titleCompanyName || contactInfo.titleCompanyName) && {
                    value: {
                      label: titleCompanyName || contactInfo.titleCompanyName,
                    },
                  })}
                  isAsync
                />
              </div>
              <ToolTip
                toolTipText="Add new contact"
                wrapOverrides={addToolTip}
                toolTipOverrides={toolTipStyles}
              >
                <AddNewContactWrapper
                  contactInformation={getContactInformation('titleCompany')(contactInfo)}
                  onSave={newContact => this.makeOnMultiChange('titleCompany')(newContact)}
                >
                  <AddNewContactPlus />
                </AddNewContactWrapper>
              </ToolTip>
            </div>
            <TextInput
              disabled
              label="Title company address"
              value={titleCompanyAddress || contactInfo.titleCompanyAddress || ''}
              labelOverrides={{ marginTop: 32 }}
            />
          </Fragment>
        )}
      </React.Fragment>
    );
  }
}
