/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ClientLoans as RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import Link from '@mui/material/Link';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { roleTypes } from '../../constants';
import useFileExport from '../../components/ui/DataTable/FileExport';

type Props = {
  reportData: RowData[];
  fromDate: Date;
};

const ClientLoans = ({ reportData, fromDate }: Props) => {
  const columns = [
    {
      field: 'loanNumber',
      title: 'Loan Number',
      render: ({ loanId, loanNumber }: RowData) => (
        <Link target="_blank" href={`/loans/${loanId}`}>
          {loanNumber}
        </Link>
      ),
    },
    {
      field: 'borrower',
      title: 'Borrower',
    },
    {
      field: 'propertyAddress',
      title: 'Property Address',
    },
    {
      field: 'city',
      title: 'City',
    },
    {
      field: 'state',
      title: 'State',
    },
    {
      field: 'zip',
      title: 'Zip',
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: (rowData: RowData) =>
        rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'loanAmount',
      title: 'Loan Amount',
      render: (rowData: RowData) =>
        rowData.loanAmount?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    },
    {
      field: 'investor',
      title: 'Investor',
    },
    {
      field: 'investorNum',
      title: 'Investor Number',
    },
    {
      field: 'titleCompany',
      title: 'Title Company',
    },
  ];

  return (
    <DataTable<RowData>
      title="Loans"
      columns={columns}
      data={reportData}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Loans',
      }}
    />
  );
};

export default ClientLoans;
