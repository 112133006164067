/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import colors from '../../../../styles/colors';
import { PlusIcon } from '../../../ui/icons';

const iconCircle = {
  height: 48,
  width: 48,
  backgroundColor: colors.grayExLight,
  borderRadius: '50%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.blue,
  cursor: 'pointer',
};

export default function AddNewContactPlus() {
  return (
    <span css={iconCircle}>
      <PlusIcon />
    </span>
  );
}
