/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';
import * as icons from './icons';
import Icon, { IconType } from './Icon';

const baseStyles = {
  alignItems: 'center',
  backgroundColor: colors.white,
  borderRadius: '50%',
  color: colors.black,
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 'bold',
  height: 56,
  justifyContent: 'center',
  width: 56,
};

const smallStyles = {
  height: 48,
  width: 48,
};

interface CircleProps {
  small?: boolean;
  text?: string | number;
  icon?: IconType;
  styleoverrides?: object;
}

export default function Circle({ small, text, icon, styleoverrides }: CircleProps) {
  const mergedStyles = [baseStyles, small && smallStyles, styleoverrides];
  return <div css={mergedStyles}>{icon ? <Icon icon={icon} /> : text}</div>;
}
