import styled from '@emotion/styled';

export const DocAndSidebarWrap = styled.div<{ makeBig: boolean }>`
  display: flex;
  padding: 16px;
  height: calc(100% - 50px);
  /* .aspect_ratio_keeper {
    padding-top: 75%;
    position: relative;
    display: block;
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
  .aspect_ratio_keeper_inner,
  object,
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  } */

  .newman-is-the-bomb {
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    gap: 16px;
    height: 100vh;
    justify-content: space-between;
    left: 0;
    position: fixed;
    top: 0;
    transition: all 0.15s ease-in-out;
    width: 100vw;
    z-index: 260;
    .aspect_ratio_keeper {
      padding: 8px;
      flex: 0 0 65%;
    }
  }
`;

export const NewmanWrap = styled.div`
  flex: 0 0 65%;
`;

export const AuditSectionWrap = styled.div`
  border-radius: 6px;
  border: 1px solid #eee;
  margin-top: 8px;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormLabel-root {
    font-size: 18px;
    color: #444 !important;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding: 12px 16px;
    font-weight: 600;
    background-color: rgba(244, 246, 248, 0.6);
  }
`;

export const TopActionBarWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  background-color: #fff;
`;

export const EventLogWrap = styled.div`
  display: flex;
  justify-content: center;
  background-color: white;
  position: relative;
  max-width: 1368px;
  margin: 32px;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  .inner_wrap {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    .gridder {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 16px;
    }
  }
`;

export const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-left: 16px;
  .tile_wrap {
    display: flex;
    justify-content: center;
    background-color: white;
    width: 100%;
    max-width: 540px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  }
  .inner-wrap_jhk {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
`;
