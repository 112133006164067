/** @jsx jsx */
import { jsx } from '@emotion/core';
import { IconButton, Input, Switch, Toolbar, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useContext } from 'react';
import { useTitleCompanies } from './useTitleCompanies';
import { DownloadIcon } from '../ui/icons';
import { LeavePageWarningModal } from './LeavePageWarning';
import { AuthContext } from '../AuthContext';
import { isDataAssociation } from '../../Utils';

export default function TableToolbar() {
  const [{ searchText, selectionMode, selectedIds }, setTitleCompanies] = useTitleCompanies();
  const { roles } = useContext(AuthContext);

  return (
    <Toolbar css={{ backgroundColor: '#fafafa' }}>
      <Typography variant="h6">Title Companies</Typography>
      <div css={{ marginLeft: 'auto', display: 'flex' }}>
        {isDataAssociation(roles) && (
          <div css={{ paddingTop: 20, paddingRight: 20 }}>
            <span css={{ fontSize: 14, color: '#686868' }}>Consolidation</span>
            <Switch
              checked={selectionMode}
              onChange={() => {
                if (selectedIds.size > 0) {
                  setTitleCompanies({ leavingConsolidationWarning: true });
                } else {
                  setTitleCompanies({
                    selectionMode: !selectionMode,
                    mainConsolidatedTitleCompany: undefined,
                    consolidationError: false,
                  });
                }
              }}
            />
          </div>
        )}
        <Input
          placeholder="Search"
          autoFocus
          value={searchText}
          onChange={e => setTitleCompanies({ searchText: e.target.value })}
          startAdornment={<SearchIcon />}
          endAdornment={
            <IconButton
              disabled={searchText === ''}
              onClick={() => setTitleCompanies({ searchText: '' })}
              size="large"
            >
              <ClearIcon />
            </IconButton>
          }
        />
      </div>
      <Tooltip title="export">
        <IconButton onClick={() => setTitleCompanies({ isExporting: true })} size="large">
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}
export function LeaveConsolidationModeWarning() {
  const [{ leavingConsolidationWarning }, setTitleCompanies] = useTitleCompanies();

  return (
    <LeavePageWarningModal
      open={leavingConsolidationWarning}
      onConfirmPageLeave={() =>
        setTitleCompanies({
          leavingConsolidationWarning: false,
          selectedIds: new Set(),
          selectionMode: false,
          mainConsolidatedTitleCompany: undefined,
          consolidationError: false,
        })
      }
      onCancelPageLeave={() => setTitleCompanies({ leavingConsolidationWarning: false })}
    />
  );
}
