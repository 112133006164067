import React, { useEffect, useState } from 'react';
import { apiFetch } from '../adalConfig';
import { DocumentTypePrediction } from '../globalTypes/objects';
import { Failure, Loading, NotAsked, RemoteData, Success } from '../globalTypes/RemoteData';

async function getSuggestedDocType(documentId: number) {
  return apiFetch<DocumentTypePrediction>(`/api/Documents/SuggestedDocType/${documentId}`).then(
    ({ data }) => {
      return data;
    },
  );
}

export default function useSuggestedDocType(docId?: number) {
  const [suggestedDocType, setSuggestedDocType] = useState<
    RemoteData<DocumentTypePrediction, string>
  >(NotAsked);

  useEffect(() => {
    if (!docId) return;
    setSuggestedDocType(Loading);
    getSuggestedDocType(docId)
      .then(res => {
        setSuggestedDocType(Success(res));
      })
      .catch(e => setSuggestedDocType(Failure(e.message)));
  }, [docId]);

  return suggestedDocType;
}
