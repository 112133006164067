import { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

export default (vals: [string, (v?: string | null) => void][]) => {
  useEffect(() => {
    vals.forEach(([val, updater]) => {
      if (isEmpty(val?.trim())) {
        updater(null);
      }
    });
  }, [vals]);
};
