/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import DataTable from '../components/ui/DataTable/DataTable';
import ClientSearch from '../components/Utilities/ClientSearch';
import useDataTableFilters from '../components/ui/DataTable/UseDataTableFilters';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { apiPost } from '../adalConfig';
import { useToaster } from '../Hooks/toasters';
import Link from '@mui/material/Link';
import { DocumentNote, NotesDisplay } from '../components/AccountRepsPage';
import useFileExport from '../components/ui/DataTable/FileExport';
import { Investor } from '../globalTypes/objects';
import { Chip } from '@mui/material';
import InvestorSearchSelect from '../components/Utilities/InvestorSearchSelect';

type RowData = {
  documentId: number;
  resolutionType: string;
  correctedAt: string;
  correctedDocumentEvent: string;
  correctedBy: string;
  replacementDocumentId?: number;
  replacementDocumentIsHardCopy?: boolean;
  resolutionNote?: string;
  fulfilledBy?: string;
  fulfilledAt?: string;
  shippedAt?: string;
  lastActionTaken?: string;
  client: string;
  failedDocumentFormat: string;
  investorName: string;
  dateFunded?: string;
  documentNotes: DocumentNote[];
};

type ExportData = {
  'Document Id': number;
  Investor: string;
  'Resolution Type': string;
  'Corrected At': string;
  'Corrected Document Event': string;
  'Corrected By': string;
  'Replacement Document Id'?: number;
  'Replacement Document Is Physical'?: boolean;
  'Resolution Note'?: string;
  'Fulfilled By'?: string;
  'Fulfilled At'?: string;
  'Shipped At'?: string;
  'Last Action Taken'?: string;
  Client: string;
  'Failed Document Format': string;
  'Date Funded'?: string;
  'Document Notes': string;
};

const CorrectionFulfillment = () => {
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | undefined>();

  const { successToaster, errorToaster } = useToaster();
  const fileExport = useFileExport<ExportData>();

  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    clearCustomFilter,
  } = useDataTableFilters('/api/accountReps/fulfillment-coordinator-filters');

  const clientFilter = customFilters.find(filter => filter.label === 'ClientSearch');

  const activeClientFilterOption = clientFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const investorFilter = customFilters.find(filter => filter.label === 'Investors');

  const activeInvestorFilterOption = investorFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const investor: Investor | undefined = activeInvestorFilterOption
    ? ({ id: activeInvestorFilterOption.id, name: activeInvestorFilterOption.label } as Investor)
    : undefined;

  const client = activeClientFilterOption
    ? { id: parseInt(activeClientFilterOption.id.toString()), name: activeClientFilterOption.label }
    : null;

  const columns = [
    {
      field: 'documentId',
      title: 'Document Id',
      render: (rowData: RowData) => (
        <Link target="_blank" href={`documents/${rowData.documentId}`}>
          {rowData.documentId}
        </Link>
      ),
    },
    {
      field: 'investorName',
      title: 'Investor',
    },
    {
      field: 'resolutionType',
      title: 'Resolution Type',
    },
    {
      field: 'correctedAt',
      title: 'Corrected At',
      render: (rowData: RowData) =>
        rowData.correctedAt && new Date(rowData.correctedAt).toLocaleDateString(),
    },
    {
      field: 'correctedDocumentEvent',
      title: 'Corrected Document Event',
      sorting: false,
    },
    {
      field: 'correctedBy',
      title: 'Corrected By',
      sorting: false,
    },
    {
      field: 'replacementDocumentId',
      title: 'Replacement Document Id',
      render: (rowData: RowData) => (
        <Link target="_blank" href={`documents/${rowData.replacementDocumentId}`}>
          {rowData.replacementDocumentId}
        </Link>
      ),
    },
    {
      field: 'replacementDocumentIsHardCopy',
      title: 'Replacement Document Format',
      render: (rowData: RowData) =>
        rowData.replacementDocumentIsHardCopy !== null && (
          <Fragment>{rowData.replacementDocumentIsHardCopy ? 'Physical' : 'Digital'}</Fragment>
        ),
    },
    {
      field: 'fulfilledAt',
      title: 'Fulfilled At',
      render: (rowData: RowData) =>
        rowData.fulfilledAt && new Date(rowData.fulfilledAt).toLocaleDateString(),
    },
    {
      field: 'shippedAt',
      title: 'Shipped At',
      sorting: false,
      render: (rowData: RowData) =>
        rowData.shippedAt && new Date(rowData.shippedAt).toLocaleDateString(),
    },
    {
      field: 'lastActionTaken',
      title: 'Last Action Taken',
      sorting: false,
      render: (rowData: RowData) =>
        rowData.lastActionTaken && new Date(rowData.lastActionTaken).toLocaleDateString(),
    },
  ];

  const fulfillDocument = async () => {
    try {
      await apiPost(`/api/accountReps/fulfill/${selectedDocumentId}`, {});

      setSelectedDocumentId(undefined);

      successToaster(`Document id ${selectedDocumentId} was marked fulfilled`);
    } catch (e) {
      errorToaster(`Failed to fulfill. ${e.message}`);
    }
  };

  const exportDatatable = (dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map(row => {
      const exportRow: ExportData = {
        'Document Id': row.documentId,
        Investor: row.investorName,
        'Resolution Type': row.resolutionType,
        'Corrected At': row.correctedAt && new Date(row.correctedAt).toLocaleDateString(),
        'Corrected Document Event': row.correctedDocumentEvent,
        'Corrected By': row.correctedBy,
        'Replacement Document Id': row.replacementDocumentId,
        'Replacement Document Is Physical': row.replacementDocumentIsHardCopy,
        'Resolution Note': row.resolutionNote,
        'Fulfilled By': row.fulfilledBy,
        'Fulfilled At': row.fulfilledAt && new Date(row.fulfilledAt).toLocaleDateString(),
        'Shipped At': row.shippedAt && new Date(row.shippedAt).toLocaleDateString(),
        'Last Action Taken':
          row.lastActionTaken && new Date(row.lastActionTaken).toLocaleDateString(),
        Client: row.client,
        'Failed Document Format': row.failedDocumentFormat,
        'Date Funded': row.dateFunded && new Date(row.dateFunded).toLocaleDateString(),
        'Document Notes': row.documentNotes
          .sort((a, b) => Date.parse(a.createdAt.toString()) - Date.parse(b.createdAt.toString()))
          .map(
            (documentNote: DocumentNote) =>
              `${new Date(documentNote.createdAt).toLocaleDateString()} - ${
                documentNote.createdBy
              } - ${documentNote.noteText}`,
          )
          .join('\n'),
      };

      return exportRow;
    });

    fileExport(exportData, 'Correction Fulfillment Documents');
  };

  return (
    <Fragment>
      {clientFilter?.filterOptions && clientFilter.filterOptions.length > 0 && client?.id && (
        <div css={{ marginTop: 36, marginLeft: 52 }}>
          <Chip
            variant="outlined"
            onClick={() => clearCustomFilter(clientFilter)}
            label="Clear Investor Filter"
          />
        </div>
      )}
      {investorFilter?.filterOptions && investorFilter.filterOptions.length > 0 && investor?.id && (
        <div css={{ marginTop: 36, marginLeft: 52 }}>
          <Chip
            variant="outlined"
            onClick={() => clearCustomFilter(investorFilter)}
            label="Clear Investor Filter"
          />
        </div>
      )}
      <div className="mt1 pl2">
        <DataTable<RowData>
          title="Correction Fulfillment Documents"
          columns={columns}
          url="/api/accountReps/correction-fulfillment-documents"
          headerStyle={{ whiteSpace: 'normal' }}
          dataTableFilterTypes={dataTableFilterTypes}
          setDataTableFilterTypes={setDataTableFilterTypes}
          condensed
          customExport={exportDatatable}
          detailPanel={(rowData: RowData) => (
            <div style={{ margin: '16px 48px' }}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(4, 1fr)',
                  marginBottom: '8px',
                }}
              >
                <div>
                  <b>Client: </b>
                  {rowData.client}
                </div>
                <div>
                  <b>Date Funded: </b>
                  {rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString()}
                </div>
                <div>
                  <b>Failed Document Format: </b>
                  {rowData.failedDocumentFormat}
                </div>
                <div>
                  <b>Fulfilled By: </b>
                  {rowData.fulfilledBy}
                </div>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <div>
                  <b>Resolution Note: </b>
                  {rowData.resolutionNote}
                </div>
                <div>
                  {rowData.documentNotes && (
                    <div>
                      <b>NOTES:</b>
                      <NotesDisplay notes={rowData.documentNotes} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          renderCustomFilters={
            <div
              style={{ margin: '10px 5px', display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              {clientFilter && (
                <div>
                  <ClientSearch
                    selectedClient={client?.id}
                    onChange={({ id, company }) =>
                      onCustomOptionSelected(clientFilter, { id, label: company, active: true })
                    }
                    styleOverrides={{ marginTop: 16 }}
                  />
                </div>
              )}

              {investorFilter && (
                <div>
                  <InvestorSearchSelect
                    selected={investor}
                    onChange={({ id, name }) =>
                      onCustomOptionSelected(investorFilter, { id, label: name, active: true })
                    }
                  />
                </div>
              )}
            </div>
          }
          options={{
            actionsColumnIndex: -1,
          }}
          actions={() => [
            {
              key: 'fulfill',
              tooltip: 'Mark as fulfilled',
              icon: () => <FactCheckIcon style={{ color: 'rgb(76, 175, 80)' }} />,
              onClick: (event, rowData: RowData) => {
                setSelectedDocumentId(rowData.documentId);
              },
            },
          ]}
        />
      </div>

      <Dialog open={!!selectedDocumentId} onClose={() => setSelectedDocumentId(undefined)}>
        <DialogTitle style={{ cursor: 'move' }}>Fulfill Correction</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you want to mark this as fulfilled.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectedDocumentId(undefined)} color="inherit">
            Cancel
          </Button>
          <Button onClick={fulfillDocument} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CorrectionFulfillment;
