/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { CallCenterGroupedByUser as RowData } from './types';
import { Column } from 'material-table';

type Props = {
  callCenterGroupedByUser: RowData[];
};

type TransposedRowData = {
  user: string;
  totalNumberOfLoans: number;
  totalNumberOfTitleGroups: number;
  [key: string]: number | string;
};

const sumOfValues = (values: number[]) => values.reduce((a, b) => a + b);

const addTotalsColumns = (transposedDataTable: TransposedRowData[]) => {
  return transposedDataTable.map(row => {
    row.totalNumberOfLoans = sumOfValues(
      Object.entries(row).map(([column, value]) =>
        column.startsWith('loans') ? (value as number) : 0,
      ),
    );

    row.totalNumberOfTitleGroups = sumOfValues(
      Object.entries(row).map(([column, value]) =>
        column.startsWith('titleGroup') ? (value as number) : 0,
      ),
    );

    return row;
  });
};

const loadDataTable = (
  callCenterGroupedByUser: RowData[],
  dataTableShell: Map<string, TransposedRowData>,
) => {
  const transposedDataTable = callCenterGroupedByUser.reduce((dataTable, userEntry: RowData) => {
    const originalData =
      dataTable.get(userEntry.user) ?? ({ user: userEntry.user } as TransposedRowData);
    const dateString = new Date(userEntry.date).toLocaleDateString();

    const loanCountHeader = `loans${dateString}`;
    const titleGroupCountHeader = `titleGroup${dateString}`;

    originalData[loanCountHeader] = userEntry.numberOfLoans;
    originalData[titleGroupCountHeader] = userEntry.numberOfTitleGroups;

    dataTable.set(userEntry.user, originalData);

    return dataTable;
  }, dataTableShell);

  return Array.from(transposedDataTable.values());
};

const setupUserLoanAndTitleGroupCountPerDate = (columnHeaders: TransposedRowData, date: string) => {
  const loanHeader = `loans${date}`;
  const titleGroupHeader = `titleGroup${date}`;

  columnHeaders[loanHeader] = 0;
  columnHeaders[titleGroupHeader] = 0;

  return columnHeaders;
};

const groupUserDataWithDate = (rangeOfDatesInReport: string[]) => {
  return rangeOfDatesInReport.reduce(
    (columnHeaders, date) => setupUserLoanAndTitleGroupCountPerDate(columnHeaders, date),
    {} as TransposedRowData,
  );
};

const setupUsersReportData = (usersInReport: string[], rangeOfDatesInReport: string[]) => {
  return usersInReport.reduce((users, user) => {
    const userReportData = groupUserDataWithDate(rangeOfDatesInReport);

    const columnHeaders = {
      user,
      ...userReportData,
      totalNumberOfLoans: 0,
      totalNumberOfTitleGroups: 0,
    };
    users.set(user, columnHeaders);

    return users;
  }, new Map<string, TransposedRowData>());
};

const dataTableColumns = (dates: string[]) => {
  const cellStyle = {
    paddingTop: '6px',
    paddingBottom: '6px',
  };

  const sortedDatesForColumnHeaders = dates
    .map(date => new Date(date))
    .sort((a, b) => a.getTime() - b.getTime());

  const columns = [
    {
      field: 'user',
      title: 'User',
      cellStyle,
    },
  ];

  sortedDatesForColumnHeaders.forEach(date => {
    const dateString = date.toLocaleDateString();

    columns.push({
      field: `loans${dateString}`,
      title: `Loans ${dateString}`,
      cellStyle,
    });

    columns.push({
      field: `titleGroup${dateString}`,
      title: `Title Group ${dateString}`,
      cellStyle,
    });
  });

  columns.push({
    field: 'totalNumberOfLoans',
    title: 'Total Number Of Loans',
    cellStyle,
  });

  columns.push({
    field: 'totalNumberOfTitleGroups',
    title: 'Total Number Of Title Groups',
    cellStyle,
  });

  return columns;
};

const usersAndDatesFromCallCenterReport = (callCenterGroupedByUser: RowData[]) => {
  const dates = new Set<string>();
  const users = new Set<string>();

  callCenterGroupedByUser.forEach(userEntry => {
    users.add(userEntry.user);
    dates.add(userEntry.date.toString());
  });

  return [Array.from(users), Array.from(dates)];
};

const CallCenterGroupedByUser = ({ callCenterGroupedByUser }: Props) => {
  const [columns, setColumns] = useState<Column<any>[]>([]);
  const [dataTableData, setDataTableData] = useState<TransposedRowData[]>([]);

  useEffect(() => {
    transposeData(callCenterGroupedByUser);
  }, [callCenterGroupedByUser]);

  const transposeData = (callCenterGroupedByUser: RowData[]) => {
    const [users, dates] = usersAndDatesFromCallCenterReport(callCenterGroupedByUser);

    setColumns(dataTableColumns(dates));

    const dataTableShell = setupUsersReportData(users, dates);
    const transposedDataTable = loadDataTable(callCenterGroupedByUser, dataTableShell);
    const dataTableWithTotalsColumns = addTotalsColumns(transposedDataTable);

    setDataTableData(dataTableWithTotalsColumns);
  };

  return (
    <Fragment>
      {!!dataTableData.length && (
        <DataTable<RowData>
          title="Outreach - By User"
          columns={columns}
          data={dataTableData}
          headerStyle={{ whiteSpace: 'normal' }}
          options={{
            maxBodyHeight: '500px',
            search: false,
            exportFileName: 'Outreach - By User',
          }}
        />
      )}
    </Fragment>
  );
};

export default CallCenterGroupedByUser;
