import { useContext } from 'react';
import AlertContext from '../components/AlertContext';
import {
  AlertMessage,
  errorAlert,
  infoAlert,
  successAlert,
  warningAlert,
} from '../components/ui/Alert';

export const useSuccessToaster = () => {
  const { setAlert } = useContext(AlertContext);

  return (message: AlertMessage = '') => setAlert(successAlert(message));
};

export const useErrorToaster = () => {
  const { setAlert } = useContext(AlertContext);

  return (message: AlertMessage = '') => setAlert(errorAlert(message));
};

export const useInfoToaster = () => {
  const { setAlert } = useContext(AlertContext);

  return (message: AlertMessage = '') => setAlert(infoAlert(message));
};

export const useWarningToaster = () => {
  const { setAlert } = useContext(AlertContext);

  return (message: AlertMessage = '') => setAlert(warningAlert(message));
};

export const useToaster = () => ({
  successToaster: useSuccessToaster(),
  errorToaster: useErrorToaster(),
  infoToaster: useInfoToaster(),
  warningToaster: useWarningToaster(),
});
