/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import React, { Fragment } from 'react';

import FileUploadIcon, { FaceType } from '../FileUploadIcon';
import Button from '../../ui/Button';
import Circle from '../../ui/Circle';
import colors from '../../../styles/colors';
import Header from '../../ui/Header';

const outerContainer = {
  width: '100%',
  textAlign: 'center',
};

const errorImage = {
  marginTop: '80px',
  marginBottom: 0,
  paddingBottom: 0,
  display: 'flex',
  justifyContent: 'center',
};

const h3 = {
  fontWeight: 'bold',
  paddingTop: 40,
  paddingBottom: 30,
  paddingLeft: 14,
  fontSize: 24,
  margin: 0,
  display: 'block',
};

const countOuter = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const midCont = {
  display: 'block',
};

const countInner = {
  paddingBottom: 8,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
};

const p = {
  paddingLeft: 8,
  fontSize: 16,
  textAlign: 'left',
};

const circleStyles1 = {
  backgroundColor: colors.gray,
  border: 'none',
  height: 55,
  width: 55,
};

const buttonMargin = {
  marginTop: 40,
  marginLeft: 14,
};

type ImportSuccessProps = {
  handleExit: () => void;
  importCount: number;
};

export default ({ handleExit, importCount }: ImportSuccessProps) => (
  <Fragment>
    <Global styles={{ ' body': { backgroundColor: '#fff' } }} />
    <Header />
    <div css={outerContainer}>
      <div css={errorImage}>
        <FileUploadIcon type={FaceType.Sucess} />
      </div>
      <h3 css={h3}>Import Success</h3>
      <div css={countOuter}>
        <div css={midCont}>
          <div css={countInner}>
            <Circle styleoverrides={circleStyles1} text={importCount} small />
            <p css={p}>Loans Uploaded</p>
          </div>
        </div>
      </div>
      <div css={buttonMargin}>
        <Button large onClick={handleExit}>
          Done
        </Button>
      </div>
    </div>
  </Fragment>
);
