import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthConsumer } from './AuthContext';
import { localStorageKeys } from '../constants';

const ProtectedRoute = ({ component: Component, title, ...rest }) => (
  <AuthConsumer>
    {({ roles }) => {
      if (!rest.authorizedRoles || roles.some(role => rest.authorizedRoles.includes(role))) {
        // in the api fetch wrapper, in adalConfig.ts, we check on every api GET/POST request to see if we need a reload, if we do, we reload here
        if (localStorage.getItem(localStorageKeys.IS_REFRESH_REQUIRED) === 'true') {
          window.location.reload();
        }

        document.title = title ? `${title} - Docprobe 3` : 'Docprobe 3';

        return <Route render={props => <Component {...props} />} {...rest} />;
      }

      return <Redirect to="/403" />;
    }}
  </AuthConsumer>
);

export default ProtectedRoute;
