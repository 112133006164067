/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import Panel, { PanelHeader } from '../ui/Panel';
import VerificationPanel from './VerificationPanel';
import MatchPanel from './MatchPanel';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import {
  Client,
  Document,
  DocumentType,
  DocumentVerificationMatch,
  Loan,
  TitleCompanyUpload,
} from '../../globalTypes/objects';
import colors from '../../styles/colors';
import { DisplayTimeout } from '../../Utils';
import { apiFetch, apiPost } from '../../adalConfig';
import { PageDisplay } from '../../pages/NotFounds';
import DocumentImportInformation from './DocumentImportInformation';
import { useToaster } from '../../Hooks/toasters';
import { ExtractedDocumentData } from '../../pages/DocumentCatchAll/types/document';

const textAreaWrap = {
  backgroundColor: colors.grayExLight,
  borderRadius: 4,
  height: 160,
  marginTop: 32,
  resize: 'none',
  width: 448,
  '::placeholder': { color: colors.black, fontWeight: 700 },
};

const countCircle = {
  backgroundColor: colors.red,
  borderRadius: 4,
  color: colors.white,
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: 1,
  marginLeft: 24,
  padding: '1px 4px',
};

export interface MatchData {
  containsLoanNumber: boolean;
  dateDaysFromDateFunded: number[];
  dates: string[];
  fuzzyBorrower: number;
  fuzzyPropertyAddress: number;
  containsLoanAmount: boolean | null;
}

export type MatchDisplay = {
  id: number;
  documentId: number;
  loan: Loan;
  isMortgage: boolean;
  isPolicy: boolean;
  matchData?: MatchData;
  match: DocumentVerificationMatch;
  document: Document;
  documentLink: string;
  // client?: Client;
};

export type MatchStatus = 'INITIAL' | 'MATCHED' | 'REJECTED';

export type LoadingStatuses = {
  pdfLoading: boolean;
  submitStatus: StatusTypes;
  matchStatus: MatchStatus;
  verificationStatus: 'INITIAL' | 'PASSED' | 'FAILED';
};

type MatchVerificationProps = {
  match: MatchDisplay;
  client: Client;
  onMatchComplete: (matchStatus?: Exclude<MatchStatus, 'INITIAL'>) => void;
  onLoadError?: () => void;
  handleDismissClick?: () => void;
  originalFilename?: string;
  matchAmount?: number;
  pdfLoading?: boolean;
  pageDisplay?: PageDisplay;
  setAllowEditingDocType?: (value: boolean) => void;
  renderDocumentTypeSelect: () => JSX.Element | null;
};

type MatchDTO = {
  id?: number;
  note: string;
  verificationFailures: number[] | undefined;
  isMatch: boolean;
  documentId: number;
  loanId: number;
};

interface ResolveMatchDto {
  id: number;
  match: DocumentVerificationMatch;
  isMatch?: boolean;
}

interface VerificationResultDto {
  documentId: number;
  verificationFailures: number[];
  note: string;
}

const initialLoadingState: LoadingStatuses = {
  submitStatus: StatusTypes.initial,
  matchStatus: 'INITIAL',
  pdfLoading: false,
  verificationStatus: 'INITIAL',
};

export default function MatchVerification({
  match,
  client,
  matchAmount,
  handleDismissClick,
  onMatchComplete,
  pdfLoading,
  pageDisplay,
  setAllowEditingDocType,
  renderDocumentTypeSelect,
}: MatchVerificationProps) {
  const [verificationFailures, setVerificationFailures] = useState<number[]>();
  const [note, setNote] = useState('');
  const [loadingStatuses, setLoadingStatuses] = useState<LoadingStatuses>(initialLoadingState);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [titlePortalUploadId, setTitlePortalUploadId] = useState<number>();
  const [extractedDocumentData, setExtractedDocumentData] = useState<ExtractedDocumentData>(
    {} as ExtractedDocumentData,
  );

  const { errorToaster } = useToaster();

  const getExtractedData = async () => {
    try {
      const { data } = await apiFetch<ExtractedDocumentData>(
        `/Api/Documents/GetExtractDocumentDataById/${match.documentId}`,
      );

      setExtractedDocumentData(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const getIsTitlePortalUpload = async () => {
    try {
      const { data } = await apiFetch<TitleCompanyUpload>(
        `/Api/Documents/GetTitleCompanyUpload/${match.documentId}`,
      );
      setTitlePortalUploadId(data?.id);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  useEffect(() => {
    getExtractedData();
    getIsTitlePortalUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolveMatch = async (isMatch: boolean) => {
    if (!isMatch && handleDismissClick) {
      handleDismissClick();
      return;
    }

    setLoadingStatuses(prev => ({
      ...prev,
      submitStatus: StatusTypes.loading,
    }));

    const data: ResolveMatchDto = {
      id: match.id,
      isMatch,
      match: match.match,
    };

    try {
      const { data: duplicate } = await apiPost(`/api/documentmatch/resolvematch`, data);
      setAllowEditingDocType?.(false);
      setIsDuplicate(duplicate);

      const matchStatus = isMatch ? 'MATCHED' : 'REJECTED';
      setLoadingStatuses(prev => ({
        ...prev,
        submitStatus: StatusTypes.initial, // reset for verification panel
        matchStatus,
      }));

      duplicate &&
        setTimeout(() => {
          onMatchComplete(pageDisplay === PageDisplay.specificMatch ? matchStatus : undefined);
        }, DisplayTimeout);
    } catch (e) {
      setLoadingStatuses(prev => ({
        ...prev,
        submitStatus: StatusTypes.error,
      }));
    }
  };

  const onMatchClick = async () => {
    await resolveMatch(true);
    if (!verificationRequired) await onPassedVerification();
  };

  const onNotMatchClick = async () => {
    await resolveMatch(false);
    setLoadingStatuses(prev => ({ ...prev, matchStatus: 'REJECTED' }));
    await onMatchComplete(pageDisplay === PageDisplay.specificMatch ? 'REJECTED' : undefined);
  };

  const getJsxForError = (errors: string[]) => (
    <Fragment>
      {errors.map((errorMessage: string, i) => {
        return <p key={i}>{errorMessage}</p>;
      })}
    </Fragment>
  );

  const displayErrors = (e, errors: string[]) => {
    let jsxForErrors;
    if (!e.response) {
      jsxForErrors = getJsxForError([e.message]);
    } else if (e.response?.data?.errors) {
      jsxForErrors = getJsxForError(errors);
    } else {
      const errorMessage = e.response.data.split('\n')[0];
      jsxForErrors = getJsxForError([errorMessage || e.message]);
    }

    errorToaster(jsxForErrors);
  };

  const onSkipMatchClick = async () => {
    setLoadingStatuses(prev => ({
      ...prev,
      submitStatus: StatusTypes.loading,
    }));

    const data: ResolveMatchDto = {
      id: match.id,
      match: match.match,
    };

    try {
      await apiPost(`/api/documentmatch/skip-resolve-match`, data);
      await onMatchComplete();
    } catch (e) {
      const errors = e.response?.data?.errors?.map(
        (error: { fieldName: string; message: string }) => error.message,
      );
      displayErrors(e, errors);
    }
  };

  const onRejectReasonConfirm = async (reason: string) => {
    await apiPost(`/api/titleCompanies/UpdateRejectReason`, {
      uploadId: titlePortalUploadId,
      reason,
    });
  };

  const onReturnToInboxClick = async () => {
    setLoadingStatuses(prev => ({
      ...prev,
      submitStatus: StatusTypes.loading,
    }));

    await apiPost(`/api/DocumentEmail/ReturnToInbox`, { int: match.document.id });
    await onMatchComplete();
  };

  const onIrrelevantDocClick = async (irrelevantType: string) => {
    setLoadingStatuses(prev => ({
      ...prev,
      submitStatus: StatusTypes.loading,
    }));

    await apiPost(`/api/DocumentEmail/AddIrrelevantDoc`, {
      documentId: match.document.id,
      irrelevantType,
    });
    await onMatchComplete();
  };

  const onFailedVerification = async () => {
    setLoadingStatuses(prev => ({ ...prev, verificationStatus: 'FAILED' }));
    const data: VerificationResultDto = {
      documentId: match.documentId,
      verificationFailures: verificationFailures ?? [],
      note,
    };
    try {
      setLoadingStatuses(prev => ({
        ...prev,
        submitStatus: StatusTypes.loading,
      }));

      await apiPost(`/api/documents/FailVerification`, data);

      setLoadingStatuses(prev => ({
        ...prev,
        submitStatus: StatusTypes.success,
      }));

      setTimeout(() => {
        onMatchComplete(pageDisplay === PageDisplay.specificMatch ? 'MATCHED' : undefined);
        setNote('');
        setVerificationFailures([]);
      }, DisplayTimeout);
    } catch (e) {
      setLoadingStatuses(prev => ({
        ...prev,
        submitStatus: StatusTypes.error,
      }));

      const errors = e.response?.data?.errors?.map(
        (error: { fieldName: string; message: string }) => error.message,
      );
      displayErrors(e, errors);
    }
  };

  const onPassedVerification = async () => {
    setLoadingStatuses(prev => ({ ...prev, verificationStatus: 'PASSED' }));
    await onMatchComplete(pageDisplay === PageDisplay.specificMatch ? 'MATCHED' : undefined);
  };

  const { loan, matchData } = match;

  const isMatched = loadingStatuses.matchStatus === 'MATCHED';

  const verificationRequired =
    match.document.documentType == DocumentType.Policy ||
    match.document.documentType == DocumentType.PolicyToSecondMortgage;

  return (
    <div
      css={[
        {
          display: 'inline-block',
          verticalAlign: 'top',
          maxWidth: 428,
          width: '100%',
        },
        isMatched && { maxWidth: 488 },
      ]}
    >
      <Panel styles={{ padding: 24 }}>
        <PanelHeader text={client.company}>
          {matchAmount && matchAmount > 0 ? <div css={countCircle}>{matchAmount}</div> : null}
        </PanelHeader>

        {loadingStatuses.matchStatus === 'INITIAL' && (
          <Fragment>
            <div css={{ marginTop: 10 }}>{renderDocumentTypeSelect?.()}</div>
            <MatchPanel
              canMatch={match.document?.documentType !== DocumentType.Unknown}
              loading={loadingStatuses.submitStatus === StatusTypes.loading}
              onMatch={onMatchClick}
              onReject={onNotMatchClick}
              pageDisplay={pageDisplay}
              onSkip={onSkipMatchClick}
              onReturnToInbox={onReturnToInboxClick}
              onIrrelevantDoc={onIrrelevantDocClick}
              onRejectReasonConfirm={onRejectReasonConfirm}
              titlePortalUploadId={titlePortalUploadId}
              document={match.document}
              loan={loan}
              matchData={matchData}
              extractedDocumentData={extractedDocumentData}
            />
          </Fragment>
        )}

        {isMatched && isDuplicate && (
          <h1 css={{ color: colors.blueDark }}>
            This document already exists, document will be deleted.
          </h1>
        )}

        {isMatched && !isDuplicate && verificationRequired && (
          <div>
            <VerificationPanel
              importingDoc={false}
              loan={match.loan}
              isMortgage={match.isMortgage}
              isPolicy={match.isPolicy}
              matchData={matchData}
              setVerificationFailures={setVerificationFailures}
              extractedDocumentData={extractedDocumentData}
            />
            <textarea
              type="text"
              // @ts-ignore
              css={textAreaWrap}
              placeholder="Note"
              value={note}
              onChange={e => setNote(e.target.value)}
            />
            <div className="df jcsb mt4">
              <StatusButton
                secondary
                disabled={
                  loadingStatuses.pdfLoading ||
                  !verificationFailures ||
                  verificationFailures.length === 0
                }
                text="Failed"
                status={
                  loadingStatuses.submitStatus ? loadingStatuses.submitStatus : StatusTypes.initial
                }
                styleOverrides={{ minWidth: 156, width: 156 }}
                onClick={onFailedVerification}
              />
              <StatusButton
                disabled={
                  loadingStatuses.pdfLoading ||
                  (verificationFailures && verificationFailures.length !== 0)
                }
                status={
                  loadingStatuses.submitStatus ? loadingStatuses.submitStatus : StatusTypes.initial
                }
                styleOverrides={{ minWidth: 156, width: 156 }}
                text="Looks Good"
                onClick={onPassedVerification}
              />
            </div>
          </div>
        )}
      </Panel>

      {!isMatched && (
        <div
          css={{
            marginTop: 16,
          }}
        >
          <DocumentImportInformation docId={match.documentId} />
        </div>
      )}
    </div>
  );
}
