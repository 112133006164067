import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import { FilterType } from 'powerbi-models';
import { EmbedReportInfo } from './EmbedContainer';
import { Client } from '../../globalTypes/objects';

type Props = {
  report: EmbedReportInfo;
  selectedClient: Client | null;
};

const getFilters = (client: Props['selectedClient']) => {
  if (client === null) {
    return;
  }

  return [
    {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: 'Clients',
        column: 'Company',
      },
      filterType: FilterType.Basic,
      operator: 'In',
      values: [client.company],
    },
  ];
};

const EmbedReport = ({ report, selectedClient }: Props) => (
  <PowerBIEmbed
    key={report.embedToken}
    embedConfig={{
      type: 'report',
      id: `${report.id}`,
      embedUrl: `${report.embedUrl}`,
      accessToken: `${report.embedToken}`,
      tokenType: models.TokenType.Embed,
      filters: getFilters(selectedClient),
      settings: {
        panes: {
          pageNavigation: {
            position: models.PageNavigationPosition.Left,
            visible: true,
          },
          bookmarks: {
            visible: false,
          },
          filters: {
            expanded: false,
            visible: true,
          },
        },
        background: models.BackgroundType.Default,
      },
    }}
    getEmbeddedComponent={embeddedReport => {
      window.report = embeddedReport as Report;
    }}
  />
);

export default EmbedReport;
