/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import DatePicker from 'react-datepicker';
import { add } from 'date-fns';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { apiPost } from '../../adalConfig';
import { LoanInformation } from './types';
import { useToaster } from '../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      borderRadius: '5px',
      boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
      padding: '24px 24px 16px',
      position: 'absolute',
      right: 0,
      top: '48px',
      width: 320,
      zIndex: 1,
    },
    deferLabels: {
      color: '#0828CC',
      fontSize: '12px',
      fontWeight: 'bold',
      letterSpacing: 1,
      textTransform: 'uppercase',
    },
    deferDatePicker: {
      border: '1px solid #aeaeae',
      borderRadius: '4px',
      boxShadow: 'inset 0 2px 2px #e9e9e9',
      fontSize: '13px',
      lineHeight: '16px',
      marginTop: 8,
      maxWidth: 164,
      padding: '3px',
    },
    textareaContainer: {
      border: '1px solid black',
      marginTop: 8,
    },
    textareaWrap: {
      resize: 'none',
      width: '100%',
    },
  }),
);

type Props = {
  loanId: number;
  refreshNotesList: () => Promise<void>;
  setLoanInformation: Dispatch<SetStateAction<LoanInformation>>;
};
const DeferLoan = ({ loanId, refreshNotesList, setLoanInformation }: Props) => {
  const [isSettingDefer, setIsSettingDefer] = useState(false);
  const [tempDeferDate, setTempDeferDate] = useState(add(new Date(), { days: 1 }));
  const [reasonForDeferral, setReasonForDeferral] = useState('');

  const { successToaster, errorToaster, warningToaster } = useToaster();

  const classes = useStyles();

  const save = async () => {
    const postBody = {
      loanId,
      deferUntil: tempDeferDate.toISOString().split('T')[0],
      reasonForDeferral,
    };

    try {
      await apiPost('/api/call-tasks/defer-loan', postBody);
      successToaster();
      resetState();
      await refreshNotesList();
      setLoanInformation(loanInformation => ({
        ...loanInformation,
        deferUntil: tempDeferDate.toISOString(),
      }));
    } catch (e) {
      if (!e.response) {
        errorToaster(e.message);
        return;
      }

      if (e.response.data?.errors) {
        errorToaster(
          <Fragment>
            {e.response.data.errors.map(error => {
              return <p key={error.fieldName + error.message}>{error.message}</p>;
            })}
          </Fragment>,
        );
        return;
      }

      const errorMessage = e.response.data.split('\n')[0];
      errorToaster(errorMessage || e.message);
    }
  };

  const resetState = () => {
    setIsSettingDefer(false);
    setTempDeferDate(add(new Date(), { days: 1 }));
    setReasonForDeferral('');
  };

  return (
    <Fragment>
      <div className="df jcfe">
        <Button
          color="primary"
          variant={isSettingDefer ? 'contained' : 'text'}
          onClick={() => setIsSettingDefer(true)}
        >
          Defer Loan
        </Button>
      </div>
      {isSettingDefer && (
        <div className={classes.container}>
          <div>
            <div className={classes.deferLabels}>Defer Until</div>
            <div className={classes.deferDatePicker}>
              <DatePicker
                minDate={add(new Date(), { days: 1 })}
                maxDate={add(new Date(), { weeks: 4 })}
                selected={tempDeferDate}
                onChange={dateTime => {
                  if (!dateTime) {
                    return;
                  }
                  setTempDeferDate(dateTime);
                }}
              />
            </div>
          </div>
          <div className="mt3">
            <div className={classes.deferLabels}>Reason For Deferral</div>
            <div className={classes.textareaContainer}>
              <TextareaAutosize
                minRows={3}
                value={reasonForDeferral}
                onChange={e => {
                  if (e.target.value.length >= 3000) {
                    warningToaster('The max length for the deferral reason is 3,000 characters');
                    return;
                  }
                  setReasonForDeferral(e.target.value);
                }}
                className={classes.textareaWrap}
              />
            </div>
          </div>
          <div className="df jcfe mt2">
            <Button color="primary" onClick={() => setIsSettingDefer(false)}>
              Cancel
            </Button>
            <Button color="primary" disabled={reasonForDeferral.length === 0} onClick={save}>
              Save
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DeferLoan;
