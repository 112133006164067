/** @jsx jsx */
/* eslint-disable react-hooks/exhaustive-deps */
import { jsx } from '@emotion/core';
import React, { useEffect, useMemo, useState } from 'react';
import {Button, TextField} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import isValid from 'date-fns/isValid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from './ui/Checkbox';
import { apiFetch, apiPost } from '../adalConfig';
import useConvertEmptyStringsToNulls from '../Hooks/useConvertEmptyStringsToNulls';
import { isBlank } from '../Utils';
import { useToaster } from '../Hooks/toasters';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

type Data = {
  loanNumber?: string;
  borrower?: string;
  propertyAddress?: string;
  city?: string;
  state?: string;
  zip?: string;
  dateFunded?: Date;
  loanAmount?: number;
  documentId?: number;
  extractionCompleted?: boolean;
};
type ExtractedData = {
  loanNumber: string;
  borrower: string;
  propertyAddress: string;
  city: string;
  state: string;
  zip: string;
  dateFunded: Date;
  loanAmount: number;
  extractionCompleted: boolean;
};

function isValidForSubmit(data: Data): boolean {
  return Object.entries(data).some(([key, val]) => key !== 'documentId' && !isBlank(val));
}

export default function ManualDataExtractionSection({ documentId }: { documentId: number }) {
  const [extractedData, setExtractedData] = useState<ExtractedData>();
  const [loanNumber, setLoanNumber] = useState();
  const [borrower, setBorrower] = useState();
  const [propertyAddress, setPropertyAddress] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [dateFunded, setDateFunded] = useState<Date>();
  const [loanAmount, setLoanAmount] = useState();
  const [extractionCompleted, setExtractionCompleted] = useState<boolean>(false);
  const [dataComplete, setDataComplete] = useState<boolean>(false);

  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const { successToaster, errorToaster } = useToaster();

  const mutation = useMutation(
    (data: Data) => apiPost(`/Api/Documents/ManuallyExtractDocumentData`, data),
    {
      onSuccess: () => {
        successToaster('Successfully saved document data.');
        setSaveSuccessful(true);
      },
      onError: () => errorToaster('Save unsuccessful'),
    },
  );

  const getExtractedData = async () => {
    try {
      const { data } = await apiFetch<ExtractedData>(
        `/Api/Documents/GetExtractDocumentDataById/${documentId}`,
      );

      setExtractedData(data);
      setExtractionCompleted(data.extractionCompleted);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  useEffect(() => {
    getExtractedData();
  }, []);

  useEffect(() => {
    if (
      (loanNumber != null || extractedData?.loanNumber != null) &&
      (borrower != null || extractedData?.borrower != null) &&
      (dateFunded != null || extractedData?.dateFunded != null) &&
      (loanAmount != null || extractedData?.loanAmount != null) &&
      (propertyAddress != null || extractedData?.propertyAddress != null) &&
      (city != null || extractedData?.city != null) &&
      (state != null || extractedData?.state != null) &&
      (zip != null || extractedData?.zip != null)
    ) {
      setDataComplete(true);
    } else {
      setDataComplete(false);
    }
  }, [
    loanNumber,
    borrower,
    dateFunded,
    loanAmount,
    propertyAddress,
    city,
    state,
    zip,
    extractedData,
  ]);

  useConvertEmptyStringsToNulls([
    [borrower, setBorrower],
    [loanNumber, setLoanNumber],
    [propertyAddress, setPropertyAddress],
    [city, setCity],
    [state, setState],
    [zip, setZip],
    [loanAmount, setLoanAmount],
  ]);

  const formData = useMemo(
    () => ({
      borrower,
      loanNumber,
      propertyAddress,
      city,
      state,
      zip,
      dateFunded,
      loanAmount,
      documentId,
      extractionCompleted,
    }),
    [
      borrower,
      city,
      dateFunded,
      documentId,
      loanAmount,
      loanNumber,
      propertyAddress,
      state,
      zip,
      extractionCompleted,
    ],
  );

  return (
    <div>
      {saveSuccessful ? null : (
        <div
          css={{
            marginTop: 20,
            backgroundColor: 'white',
            width: '25rem',
            borderRadius: 5,
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          }}
        >
          <div css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 16, padding: 16 }}>
            <TextField
              label="Loan #"
              value={loanNumber || extractedData?.loanNumber || ''}
              onChange={e => setLoanNumber(e.target.value)}
              variant="standard"
            />
            <TextField
              label="Borrower"
              value={borrower || extractedData?.borrower || ''}
              onChange={e => setBorrower(e.target.value)}
              variant="standard"
            />
            <div className="relative">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date Funded"
                  inputFormat="MM/dd/yyyy"
                  value={dateFunded?.setUTCHours(12) || extractedData?.dateFunded || null}
                  onChange={date => {
                    setDateFunded(isValid(date) ? date : undefined);
                  }}
                  renderInput={params => <TextField {...params} variant="standard" />}
                />
              </LocalizationProvider>
            </div>
            <TextField
              label="Loan Amount"
              variant="standard"
              value={loanAmount || extractedData?.loanAmount || ''}
              onChange={e => setLoanAmount(e.target.value)}
              type="number"
            />
            <TextField
              label="Property Address"
              variant="standard"
              value={propertyAddress || extractedData?.propertyAddress || ''}
              onChange={e => setPropertyAddress(e.target.value)}
            />
            <TextField
              label="City"
              variant="standard"
              value={city || extractedData?.city || ''}
              onChange={e => setCity(e.target.value)}
            />
            <TextField
              label="State"
              variant="standard"
              value={state || extractedData?.state || ''}
              onChange={e => setState(e.target.value)}
            />
            <TextField
              label="Zip"
              variant="standard"
              value={zip || extractedData?.zip || ''}
              onChange={e => setZip(e.target.value)}
            />
          </div>
          <div css={{ display: 'flex', justifyContent: 'space-between', padding: 16 }}>
            <Checkbox
              checked={!dataComplete && extractionCompleted}
              onClick={() => setExtractionCompleted(!extractionCompleted)}
              text="Extraction Completed"
              labelOverrides={{ width: '200px' }}
              disabled={dataComplete}
            />
            <Button
              onClick={() => mutation.mutate(formData)}
              variant="contained"
              color="primary"
              size="large"
              disabled={!isValidForSubmit(formData) || mutation.isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
