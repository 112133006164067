/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import SearchSelect from '../ui/SearchSelect';
import { Client } from '../../globalTypes/objects';
import { useClients } from '../../Hooks/useClients';

interface ClientSearchProps {
  onChange: (selection: Client) => any;
  selectedClient?: number;
  styleOverrides?: object;
}

export default function ClientSearch({
  onChange,
  selectedClient,
  styleOverrides,
}: ClientSearchProps) {
  const clients = useClients();

  return (
    <SearchSelect
      options={clients.map((c: Client) => ({
        label: c.company,
        value: c,
      }))}
      placeholder="Search Clients"
      {...(selectedClient &&
        clients.length !== 0 && {
          defaultValue: {
            label: clients.find((c: Client) => c.id === selectedClient)?.company,
            value: clients.find((c: Client) => c.id === selectedClient),
          },
        })}
      onChange={async (selection: { value: Client }) => {
        if (selection) {
          onChange(selection.value);
        }
      }}
      css={styleOverrides}
    />
  );
}
