/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import { AuthContext } from '../../components/AuthContext';
import { apiFetch } from '../../adalConfig';
import { roleTypes } from '../../constants';
import CircularProgress from '@mui/material/CircularProgress';
import { DrillDownRowData, OverviewRowData } from './types';
import USDashboard from './USDashboard';
import IsraelDashboard from './IsraelDashboard';
import { useToaster } from '../../Hooks/toasters';

const SalesRepsDashboard = () => {
  const [dataTableData, setDataTableData] = useState<OverviewRowData[]>([]);
  const [drillDownDataTableData, setDrillDownDataTableData] = useState<DrillDownRowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { roles } = useContext(AuthContext);

  const { errorToaster } = useToaster();

  const paymentType: string = useMemo(
    () => (dataTableData.length ? dataTableData[0].paymentType : ''),
    [dataTableData],
  );

  const [{ 'sales-rep-id': salesRepId }] = useQueryParams({ 'sales-rep-id': StringParam });

  const isManager =
    roles.findIndex(role => role === roleTypes.SalesManager || role === roleTypes.Dev) !== -1;

  useEffect(() => {
    const getDataTableData = async () => {
      try {
        const dataTableUrl =
          isManager && salesRepId?.length
            ? `/api/sales-reps/${salesRepId}/overview`
            : '/api/sales-reps/overview';

        const { data } = await apiFetch(dataTableUrl);
        setDataTableData(data);
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      }

      setIsLoading(false);
    };

    getDataTableData();
  }, [salesRepId, roles]);

  const loadDrillDownReport = async (lenderId: number) => {
    try {
      const dataTableUrl =
        isManager && salesRepId?.length
          ? `/api/sales-reps/${salesRepId}/drill-down/${lenderId}`
          : `/api/sales-reps/drill-down/${lenderId}`;

      const { data } = await apiFetch(dataTableUrl);
      setDrillDownDataTableData(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return isLoading ? (
    <div className="center-in-parent">
      <CircularProgress size="75" disableShrink />
    </div>
  ) : paymentType === 'Projected Year Imports' ? (
    <USDashboard
      dataTableData={dataTableData}
      isManager={isManager}
      drillDownDataTableData={drillDownDataTableData}
      loadDrillDownReport={loadDrillDownReport}
    />
  ) : (
    <IsraelDashboard
      dataTableData={dataTableData}
      isManager={isManager}
      drillDownDataTableData={drillDownDataTableData}
      loadDrillDownReport={loadDrillDownReport}
    />
  );
};

export default SalesRepsDashboard;
