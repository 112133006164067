/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import {
  ActionButton,
  CopyButton,
  CopyText,
  InfoRow,
  InnerCard,
  InnerCardHeader,
  ItemLabel,
  ItemStatus,
  nameS,
  PrimaryContact,
} from './cardStyles';
import { CheckIcon, CopyIcon, EditTwoIcon } from '../../components/ui/icons';
import ConditionalComponent from '../../components/ConditionalComponent';
import { clientReturnAddressDescriptions, isClientAdmin } from '../../Utils';
import { AuthContext } from '../../components/AuthContext';
import { ClientData, ClientSystemAccess, User } from './types';
import { PODNumbers } from '../Reports/ClientSearchReportTemplate';
import { ClientServiceLevel } from '../../globalTypes/objects';

type Props = {
  clientData: ClientData;
  editOnclick: (value: boolean) => void;
  systemAccess: ClientSystemAccess;
  accountRep: User;
  auditor: User;
  clientServiceCoordinator: User;
  serviceLevels: ClientServiceLevel[];
};

const PODs = Object.entries(PODNumbers)
  .filter(([key, val]) => typeof val === 'number')
  .map(([key, val]) => ({ label: key, value: val }));

export default function ClientDataDisplayCard({
  clientData,
  editOnclick,
  systemAccess,
  accountRep,
  auditor,
  clientServiceCoordinator,
  serviceLevels,
}: Props) {
  const [copySuccess, setCopySuccess] = useState('');

  const { roles } = useContext(AuthContext);

  const {
    requireUploadToEncompass,
    isCoop,
    inactive,
    agedPipeline,
    noFollowupPackage,
    isCancelled,
  } = clientData;

  function copyToClipboard(e) {
    navigator.clipboard.writeText(clientData.invoiceEmailCC);
    setCopySuccess('CC emails Copied');
    setTimeout(() => {
      setCopySuccess('');
    }, 2000);
  }

  return (
    <div>
      <div className="df aic jcsb mb3">
        <div className="df aic">
          <div css={nameS}>{clientData.contactName || 'No Contact Yet'}</div>
          {clientData.contactName && <PrimaryContact>Primary Contact</PrimaryContact>}
        </div>
        <ConditionalComponent display={isClientAdmin(roles)}>
          <ActionButton onClick={editOnclick}>
            <EditTwoIcon />
          </ActionButton>
        </ConditionalComponent>
      </div>
      <InfoRow>
        <div>
          <ItemLabel>Address</ItemLabel>
          <div>{clientData.address}</div>
          <div>
            {clientData.city} {clientData.state}, {clientData.zip}
          </div>
        </div>
        <div>
          <ItemLabel>Phone</ItemLabel>
          <span>{clientData.phone}</span>
        </div>
        <div>
          <ItemLabel>Fax</ItemLabel>
          <span>{clientData.fax}</span>
        </div>
      </InfoRow>
      <InnerCardHeader className="mt2">Emails</InnerCardHeader>
      <InnerCard className="mt1">
        <InfoRow css={{ a: { color: 'blue' } }}>
          <div>
            <ItemLabel dark>Email</ItemLabel>
            <a href={`mailto:${clientData.email}`}>{clientData.email}</a>
          </div>
          <div>
            <ItemLabel dark>Invoice Email</ItemLabel>
            <a href={`mailto:${clientData.invoiceEmail}`}>{clientData.invoiceEmail}</a>
          </div>
          <div css={{ position: 'relative' }}>
            {document.queryCommandSupported('copy') && (
              <CopyButton>
                <CopyIcon onClick={copyToClipboard} />
                <CopyText copied={copySuccess}>{copySuccess}</CopyText>
              </CopyButton>
            )}
            <ItemLabel>Invoice Email CC</ItemLabel>
            <a href={`mailto:${clientData.invoiceEmailCC}`} css={{ wordBreak: 'break-all' }}>
              {clientData.invoiceEmailCC}
            </a>
          </div>
          <div>
            <ItemLabel>Fax Folder Email</ItemLabel>
            <a href={`mailto:${clientData.clientInbox}`}>{clientData.clientInbox}</a>
          </div>
          <div>
            <ItemLabel>Followups Range</ItemLabel>
            <span>{clientData.followupsRange}</span>
          </div>
          <div>
            <ItemLabel>Loan Data Contact</ItemLabel>
            <span>{`${clientData.loanDataContactFirstName} ${clientData.loanDataContactLastName}`}</span>
          </div>
          <div>
            <ItemLabel>Loan Data Contact Email</ItemLabel>
            <span>{clientData.loanDataContactEmail}</span>
          </div>
        </InfoRow>
      </InnerCard>
      <InnerCardHeader className="mt2">Followup Details</InnerCardHeader>
      <InnerCard className="mt1">
        <InfoRow css={{ a: { color: 'blue' } }}>
          <div>
            <ItemLabel>Client Inbox</ItemLabel>
            <a href={`mailto:${clientData.clientInbox}`}>{clientData.clientInbox}</a>
          </div>
          <div>
            <ItemLabel>Followups Range</ItemLabel>
            <span>{clientData.followupsRange}</span>
          </div>
          <div>
            <ItemLabel>Record & Return PO Box</ItemLabel>
            <span>{clientData.poBox ? clientData.poBox : ''}</span>
          </div>
          <div>
            <ItemLabel>Return Address</ItemLabel>
            <span>{clientReturnAddressDescriptions.get(clientData.returnAddress)}</span>
          </div>
        </InfoRow>
      </InnerCard>
      <InnerCardHeader className="mt2">Client Details</InnerCardHeader>
      <InnerCard className="mt1">
        <InfoRow>
          <div>
            <ItemLabel>Account Manager</ItemLabel>
            <span>{accountRep.username || 'No Account Manager'}</span>
          </div>
          <div>
            <ItemLabel>AR Support</ItemLabel>
            <span>{clientData.arSupport}</span>
          </div>
          <div>
            <ItemLabel>Auditor</ItemLabel>
            <span>{auditor.username}</span>
          </div>
          <div>
            <ItemLabel>Baseline Loans Per Month</ItemLabel>
            <span>{clientData.baselineLoansPerMonth}</span>
          </div>
        </InfoRow>
        <InfoRow css={{ borderTop: '1px solid #757575', paddingTop: 16, marginTop: 24 }}>
          <div>
            <ItemLabel>Client Success Manager</ItemLabel>
            <span>{clientServiceCoordinator.displayName}</span>
          </div>
          <div>
            <ItemLabel>Document Transfer Method</ItemLabel>
            <span>{clientData.documentTransferMethod}</span>
          </div>
          <div>
            <ItemLabel>Dropbox Folder</ItemLabel>
            <span>{clientData.dropboxFolder}</span>
          </div>
          <div>
            <ItemLabel>LOS</ItemLabel>
            <span>{clientData.los}</span>
          </div>
        </InfoRow>
        <InfoRow css={{ borderTop: '1px solid #757575', paddingTop: 16, marginTop: 24 }}>
          <div>
            <ItemLabel>Purchased Loans Report Frequency</ItemLabel>
            <span>{clientData.reportFrequency}</span>
          </div>
          <div>
            <ItemLabel>Loan Data Source</ItemLabel>
            <span>{clientData.reportSource}</span>
          </div>
          <div>
            <ItemLabel>Start Date</ItemLabel>
            <span>
              {clientData.startDate
                ? new Date(clientData.startDate).toLocaleDateString()
                : clientData.startDate}
            </span>
          </div>

          <div>
            <ItemLabel>POD #</ItemLabel>
            <span>
              {clientData.podNumber
                ? PODs.filter(({ value }) => value === clientData.podNumber)[0].label
                : null}
            </span>
          </div>
        </InfoRow>

        <InfoRow css={{ borderTop: '1px solid #757575', paddingTop: 16, marginTop: 24 }}>
          <div>
            <ItemLabel>Service level</ItemLabel>
            <span>{serviceLevels.find(({ id }) => id === clientData.serviceLevelId)?.label}</span>
          </div>
        </InfoRow>
      </InnerCard>
      {isClientAdmin(roles) && (
        <Fragment>
          <InnerCardHeader className="mt2">Client System Access</InnerCardHeader>
          <InnerCard className="mt1">
            <InfoRow>
              <div>
                <ItemLabel>Link</ItemLabel>
                <span>{systemAccess.link}</span>
              </div>
              <div>
                <ItemLabel>Username</ItemLabel>
                <span>{systemAccess.username}</span>
              </div>
              <div>
                <ItemLabel>Password</ItemLabel>
                <span>{systemAccess.password}</span>
              </div>
            </InfoRow>
          </InnerCard>
        </Fragment>
      )}
      <div css={{ display: 'flex', marginTop: 24 }}>
        <ItemStatus active={requireUploadToEncompass}>
          <ItemLabel>Require Upload To Encompass</ItemLabel>
          {requireUploadToEncompass && <CheckIcon />}
        </ItemStatus>
        <ItemStatus active={inactive}>
          <ItemLabel>Inactive</ItemLabel>
          {inactive && <CheckIcon />}
        </ItemStatus>
        <ItemStatus active={isCoop}>
          <ItemLabel>Co-op</ItemLabel>
          {isCoop && <CheckIcon />}
        </ItemStatus>
        <ItemStatus active={agedPipeline}>
          <ItemLabel>Aged Pipeline</ItemLabel>
          {agedPipeline && <CheckIcon />}
        </ItemStatus>
        <ItemStatus active={noFollowupPackage}>
          <ItemLabel>No Follow Up</ItemLabel>
          {noFollowupPackage && <CheckIcon />}
        </ItemStatus>
        <ItemStatus active={isCancelled}>
          <ItemLabel>Cancelled</ItemLabel>
          {isCancelled && <CheckIcon />}
        </ItemStatus>
      </div>
    </div>
  );
}
