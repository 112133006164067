/** @jsx jsx */
import { jsx } from '@emotion/core';
import colors from '../../styles/colors';
import { IconType } from './Icon';
import Circle from './Circle';

const actionCirclestyles = {
  transition: 'all .2s',
  backgroundColor: colors.grayExLight,
  color: colors.blue,
  cursor: 'pointer',
  '&:hover': { backgroundColor: colors.grayLight },
};

export default function ActionCircle({
  icon,
  onClick,
}: {
  icon: IconType;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
  return (
    <div onClick={onClick}>
      <Circle icon={icon} small styleoverrides={actionCirclestyles} />
    </div>
  );
}
