/** @jsx jsx */
import { jsx } from '@emotion/core';
import { apiPost } from '../../../../adalConfig';
import NoteInnerModal from '../../../ui/NoteInnerModal';
import { Note } from '../../../../globalTypes/objects';

const addNote = async (note: string, ids: number[]) => {
  const { data: noteIds } = await apiPost('/api/loanErrors/addBulkNotes', { note, ids });
  return noteIds;
};

type LoanErrorNoteInnerModalProps = {
  handleClose: (noteIds: number[]) => any;
  ids: number[];
};
export default function LoanErrorNoteInnerModal({
  handleClose,
  ids,
}: LoanErrorNoteInnerModalProps) {
  const saveNote = async (noteText: string) => {
    const noteIds = await addNote(noteText, ids);
    handleClose(noteIds);
  };

  return <NoteInnerModal handleClose={handleClose} handleSave={noteText => saveNote(noteText)} />;
}
