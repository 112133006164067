/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import { useHistory } from 'react-router';
import { apiFetch } from '../../adalConfig';
import { GeneralReporting as GeneralReportingType } from './types';
import ConditionalComponent from '../../components/ConditionalComponent';
import { ShippingByUser } from './Shipping';
import { StapleByUser } from './Stapling';
import { MatchByUser } from './Matching';
import Coversheet from './Coversheet';
import { ScannedIntoAzureByUser } from './Scanning';
import { SortedByUser } from './Sorting';
import { DeterminationByUser } from './Determination';
import DigitalImportsByUser from './DigitalImports';
import QueueDisplay from './QueueDisplay';
import AggregateProcessingStats from './AggregateProcessingStats';
import AssociationsGroupedByUser from './AssociationsGroupedByUser';
import CallCenterGroupedByUser from './CallCenterGroupedByUser';
import ClientData from './ClientData';
import WaitingToBeScannedIntoAzureDrillDown from './WaitingToBeScannedIntoAzureDrillDown';
import WaitingToBeStapledDrillDown from './WaitingToBeStapledDrillDown';
import AssociationData from './InvestorAssociations';
import ClientInvestors from './InvestorsPerClient';
import { DocumentsCorrectedByUser } from './DocumentsCorrectedCount';
import CallCenterProgressHeader from './CallCenterProgressByUser';
import CallCenterDocumentsFromCallsHeader from './CallCenterDocumentsFromCalls';
import DocumentsAgingForShipping from './DocumentsAgingForShipping';
import CorrectionsTotalByMonthAndClientReport from './CorrectionsByMonthAndClient';
import { useToaster } from '../../Hooks/toasters';
import DigitalDeliveryLog from './DigitalDeliveryLog';
import AutomatedAudits from './AutomatedAudits';
import MonthlyPayments from './MonthlyPayments';
import { Client } from '../../globalTypes/objects';
import AutoDeletedDocuments from './AutoDeletedDocuments';
import { CoversheetPreprintedByUser } from './CoversheetPreprintedByUser';
import CompletedLoans from './CompletedLoans';
import TitleUploadsNotPreAssigned from './TitleUploadsNotPreAssigned';
import PotentialCureDocuments from './PotentialCureDocuments';
import ClientUpdatedOnDocument from './ClientUpdatedOnDocument';
import GuildRetainedDocuments from './GuildRetainedDocuments';
import { FulfilledByUser } from './FulfilledByUser';
import ClientLoans from './ClientLoans';

const GeneralReporting = ({ location }) => {
  const selectedReports: Array<string> = location.state?.reports ?? [];
  const selectedClient: Client = location.state?.client;
  const fromDate: Date = location.state?.fromDate ?? new Date();

  const [reports, setReports] = useState<Partial<GeneralReportingType>>({});
  const [showLoader, setShowLoader] = useState(true);

  const { errorToaster, warningToaster } = useToaster();

  const history = useHistory();

  useEffect(() => {
    const getReports = async () => {
      const queryParams = queryString.stringify(
        {
          reports: selectedReports,
          fromDate: fromDate.toLocaleDateString(),
          clientId: selectedClient?.id ?? null,
        },
        { arrayFormat: 'index' },
      );

      const { data } = await apiFetch<typeof reports>(
        `/api/reports/general-reporting?${queryParams}`,
      );
      setReports(data);
    };

    if (!selectedReports.length) {
      warningToaster('No reports selected');
      history.push('/reports-dashboard/landing-page');
      return;
    }

    getReports()
      .catch(e => {
        if (e.response?.status === 403) {
          errorToaster("You don't have access to view this report");
          history.push('/reports-dashboard/landing-page');
        } else if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      })
      .finally(() => setShowLoader(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showLoader) {
    return (
      <div className="center-in-parent">
        <CircularProgress size="75" disableShrink />
      </div>
    );
  }

  return (
    <Grid container spacing={0}>
      {!!reports.documentsShippedByUser && (
        <Grid item xs={4}>
          <ShippingByUser documentsShippedByUser={reports.documentsShippedByUser} />
        </Grid>
      )}
      {!!reports.documentsStapledByUser && (
        <Grid item xs={4}>
          <StapleByUser documentsStapledByUser={reports.documentsStapledByUser} />
        </Grid>
      )}
      {!!reports.investorAssociations && (
        <Grid item xs={12}>
          <AssociationData investorAssociations={reports.investorAssociations} />
        </Grid>
      )}
      {!!reports.investorsPerClient && (
        <Grid item xs={6}>
          <ClientInvestors investorsPerClient={reports.investorsPerClient} />
        </Grid>
      )}
      {!!reports.documentsCorrectedByUser && (
        <Grid item xs={4}>
          <DocumentsCorrectedByUser documentsCorrectedByUser={reports.documentsCorrectedByUser} />
        </Grid>
      )}
      {!!reports.coversheetPreprintedByUser && (
        <Grid item xs={4}>
          <CoversheetPreprintedByUser
            coversheetPreprintedByUser={reports.coversheetPreprintedByUser}
          />
        </Grid>
      )}
      {!!reports.documentsMatchedOrRejectedByUser && (
        <Grid item xs={4}>
          <MatchByUser
            documentsMatchedOrRejectedByUser={reports.documentsMatchedOrRejectedByUser}
          />
        </Grid>
      )}
      {!!reports.coversheetCreatedEventsByUser && (
        <Grid item xs={4}>
          <Coversheet coversheetCreatedEventsByUser={reports.coversheetCreatedEventsByUser} />
        </Grid>
      )}
      {!!reports.pdfImageSavedEventsByUser && (
        <Grid item xs={4}>
          <ScannedIntoAzureByUser pdfImageSavedEventsByUser={reports.pdfImageSavedEventsByUser} />
        </Grid>
      )}
      {!!reports.sortedEventsByUser && (
        <Grid item xs={4}>
          <SortedByUser sortedEventsByUser={reports.sortedEventsByUser} />
        </Grid>
      )}
      {!!reports.determinationPerformedEventsByUser && (
        <Grid item xs={4}>
          <DeterminationByUser
            determinationPerformedEventsByUser={reports.determinationPerformedEventsByUser}
          />
        </Grid>
      )}
      {!!reports.documentsAuditedAutomatically && (
        <Grid item xs={4}>
          <AutomatedAudits documentsAuditedAutomatically={reports.documentsAuditedAutomatically} />
        </Grid>
      )}
      {!!reports.digitalUploadedEventsByUser && (
        <Grid item xs={4}>
          <DigitalImportsByUser digitalUploadedEventsByUser={reports.digitalUploadedEventsByUser} />
        </Grid>
      )}
      {!!reports.associationsGroupedByUser && (
        <Grid item xs={4}>
          <AssociationsGroupedByUser
            associationsGroupedByUser={reports.associationsGroupedByUser}
          />
        </Grid>
      )}
      {!!reports.callCenterGroupedByUser && (
        <Grid item xs={8}>
          <CallCenterGroupedByUser callCenterGroupedByUser={reports.callCenterGroupedByUser} />
        </Grid>
      )}
      {!!reports.callCenterProgressByUser && (
        <Grid item xs={8}>
          <CallCenterProgressHeader callCenterProgressHeader={reports.callCenterProgressByUser} />
        </Grid>
      )}
      {!!reports.callCenterDocumentsFromCallsMonthly && (
        <Grid item xs={8}>
          <CallCenterDocumentsFromCallsHeader
            callCenterDocumentsFromCallsHeader={reports.callCenterDocumentsFromCallsMonthly}
          />
        </Grid>
      )}

      {!!reports.callCenterDocumentsFromCallsWeekly && (
        <Grid item xs={8}>
          <CallCenterDocumentsFromCallsHeader
            callCenterDocumentsFromCallsHeader={reports.callCenterDocumentsFromCallsWeekly}
          />
        </Grid>
      )}
      {!!reports.clientData && (
        <Grid item xs={8}>
          <ClientData clientData={reports.clientData} />
        </Grid>
      )}

      {!!reports.documentsAgingForShipping14 && (
        <Grid item xs={12}>
          <DocumentsAgingForShipping
            documentsAgingForShipping={reports.documentsAgingForShipping14}
          />
        </Grid>
      )}
      {!!reports.documentsAgingForShipping30Sorted && (
        <Grid item xs={12}>
          <DocumentsAgingForShipping
            documentsAgingForShipping={reports.documentsAgingForShipping30Sorted}
          />
        </Grid>
      )}
      {!!reports.documentsAgingForShipping90 && (
        <Grid item xs={12}>
          <DocumentsAgingForShipping
            documentsAgingForShipping={reports.documentsAgingForShipping90}
          />
        </Grid>
      )}
      {!!reports.correctionsTotalByMonthAndClientOld && (
        <Grid item xs={10}>
          <CorrectionsTotalByMonthAndClientReport
            correctionsTotalByMonthAndClient={reports.correctionsTotalByMonthAndClientOld}
            fromDate={fromDate}
            oldVersion={true}
          />
        </Grid>
      )}
      {!!reports.correctionsTotalByMonthAndClient && (
        <Grid item xs={10}>
          <CorrectionsTotalByMonthAndClientReport
            correctionsTotalByMonthAndClient={reports.correctionsTotalByMonthAndClient}
            fromDate={fromDate}
            oldVersion={false}
          />
        </Grid>
      )}
      {!!reports.completedLoans && (
        <Grid item xs={10}>
          <CompletedLoans completedLoans={reports.completedLoans} client={selectedClient} />
        </Grid>
      )}
      {!!reports.monthlyPayments && (
        <Grid item xs={6}>
          <MonthlyPayments monthlyPayments={reports.monthlyPayments} fromDate={fromDate} />
        </Grid>
      )}
      {!!reports.aggregateProcessingStats && (
        <Grid item xs={8}>
          <AggregateProcessingStats
            aggregateProcessingStats={reports.aggregateProcessingStats}
            client={selectedClient.company}
          />
        </Grid>
      )}
      {!!reports.autoDeletedDocs && (
        <Grid item xs={8}>
          <AutoDeletedDocuments autoDeletedDocs={reports.autoDeletedDocs} fromDate={fromDate} />
        </Grid>
      )}
      {!!reports.titleUploadsNotPreAssigned && (
        <Grid item xs={8}>
          <TitleUploadsNotPreAssigned
            titleUploadsNotPreAssigned={reports.titleUploadsNotPreAssigned}
            fromDate={fromDate}
          />
        </Grid>
      )}
      {!!reports.digitalDeliveryLog && (
        <Grid item xs={12}>
          <DigitalDeliveryLog reportData={reports.digitalDeliveryLog} />
        </Grid>
      )}
      {!!reports.correctionsWithPotentialCure && (
        <Grid item xs={8}>
          <PotentialCureDocuments reportData={reports.correctionsWithPotentialCure} />
        </Grid>
      )}
      {!!reports.clientUpdatedOnDocument && (
        <Grid item xs={12}>
          <ClientUpdatedOnDocument reportData={reports.clientUpdatedOnDocument} />
        </Grid>
      )}
      {!!reports.guildRetainedDocuments && (
        <Grid item xs={12}>
          <GuildRetainedDocuments reportData={reports.guildRetainedDocuments} fromDate={fromDate} />
        </Grid>
      )}
      {!!reports.fulfilledByUser && (
        <Grid item xs={12}>
          <FulfilledByUser reportData={reports.fulfilledByUser} fromDate={fromDate} />
        </Grid>
      )}
      {!!reports.clientLoans && (
        <Grid item xs={12}>
          <ClientLoans reportData={reports.clientLoans} fromDate={fromDate} />
        </Grid>
      )}
      <ConditionalComponent
        display={
          reports.readyToBeScannedIntoAzureCount ||
          reports.readyToBeBatchedCount ||
          reports.readyToBeMatched ||
          reports.readyToBeMatchedAsOfTodayCount ||
          reports.readyToBeDeterminedCount ||
          reports.readyToBeDeterminedAsOfTodayCount ||
          reports.readyToBeShippedCount ||
          reports.digitalsNotBeingShippedCount ||
          reports.readyToBeSortedCount ||
          reports.pendingCorrectionCount ||
          reports.pendingTitleCompanyAssociationCount ||
          reports.pendingInvestorAssociationCount
        }
      >
        <Grid item xs={4}>
          <Card style={{ marginTop: 16, marginLeft: 32 }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Queue
              </Typography>
              {!!reports.readyToBeScannedIntoAzureCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Scanned"
                    value={reports.readyToBeScannedIntoAzureCount}
                  >
                    <WaitingToBeScannedIntoAzureDrillDown />
                  </QueueDisplay>
                </Typography>
              )}
              {!!reports.readyToBeMatched && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td>Unique Documents Ready To Be Matched:</td>
                          <td className="pl1">{reports.readyToBeMatched.uniqueDocumentsCount}</td>
                        </tr>
                        <tr>
                          <td className="tar pt1">Total Matches:</td>
                          <td className="pl1">
                            {reports.readyToBeMatched.matchCount}
                            <Link
                              href={`${window.location.origin}/reports-dashboard/general-reporting/matching`}
                              target="_blank"
                              className="ml2"
                            >
                              more...
                            </Link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Typography>
              )}
              {!!reports.readyToBeMatchedAsOfTodayCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Added to Match Queue Today"
                    value={reports.readyToBeMatchedAsOfTodayCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/matchingAsOfToday`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeBroadMatchedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Broad Matched"
                    value={reports.readyToBeBroadMatchedCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/broadMatching`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeBatchedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Batched" value={reports.readyToBeBatchedCount}>
                    <WaitingToBeStapledDrillDown />
                  </QueueDisplay>
                </Typography>
              )}
              {!!reports.readyToBeDeterminedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Ready To Be Audited"
                    value={reports.readyToBeDeterminedCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/determination`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {reports.readyToBeDeterminedAsOfTodayCount != undefined && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Added To Audit Queue Today"
                    value={reports.readyToBeDeterminedAsOfTodayCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/determinationAsOfToday`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.readyToBeShippedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Shipped" value={reports.readyToBeShippedCount} />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/shipping`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.digitalsNotBeingShippedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Digitals Not Being Shipped"
                    value={reports.digitalsNotBeingShippedCount}
                  />
                </Typography>
              )}
              {!!reports.readyToBeSortedCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay label="Ready To Be Sorted" value={reports.readyToBeSortedCount} />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/sorting`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingCorrectionCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Documents Pending Correction"
                    value={reports.pendingCorrectionCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/pending-correction`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingTitleCompanyAssociationCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Title Companies Pending Association"
                    value={reports.pendingTitleCompanyAssociationCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/title-companies-pending-association`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
              {!!reports.pendingInvestorAssociationCount && (
                <Typography variant="body1" color="textSecondary" component="p">
                  <QueueDisplay
                    label="Investors Pending Association"
                    value={reports.pendingInvestorAssociationCount}
                  />
                  <Link
                    href={`${window.location.origin}/reports-dashboard/general-reporting/investors-pending-association`}
                    target="_blank"
                    style={{ marginLeft: 16 }}
                  >
                    more...
                  </Link>
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </ConditionalComponent>
    </Grid>
  );
};

export default GeneralReporting;
