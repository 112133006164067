import React from 'react';
import { useFetchWithAuth } from './adalConfig';
import { Client } from './globalTypes/objects';

export const ClientsContext = React.createContext<Client[]>([]);

function ClientsProvider({ children }) {
  const clientReq = useFetchWithAuth<Client[]>(`/Api/Clients/GetAll`);
  return clientReq.isFulfilled ? (
    <ClientsContext.Provider value={clientReq.data}>{children}</ClientsContext.Provider>
  ) : null;
}
const ClientsConsumer = ClientsContext.Consumer;

export { ClientsProvider, ClientsConsumer };
