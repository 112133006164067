/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Fragment } from 'react';

import { NoErrorsIcon } from '../../../ui/icons';
import colors from '../../../../styles/colors';

const noErrorsWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: 160,
  color: colors.grayDark,
};

const iconCircle: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.white,
  borderRadius: '50%',
  display: 'flex',
  height: 224,
  justifyContent: 'center',
  width: 224,
};

export default function NoErrors() {
  return (
    <Fragment>
      <div css={noErrorsWrap}>
        <div css={iconCircle}>
          <NoErrorsIcon />
        </div>
        <div
          css={{
            fontSize: 24,
            margin: '40px 0 8px',
            fontWeight: 700,
          }}
        >
          No loan errors
        </div>
        <p css={{ fontSize: 16 }}>Check back when the next spreadsheet is imported</p>
      </div>
    </Fragment>
  );
}
