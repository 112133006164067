/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, SyntheticEvent, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NewImport from './NewImport';
import ImportInformation from '../components/Loans/ImportInformation';
import TabPanel from '../components/ui/Tabs/MaterialTabPanel';
import LoanImportMapping from './LoanImportMapping';

const LoanImports = () => {
  const [tab, setTab] = useState(0);

  return (
    <Fragment>
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={(event: SyntheticEvent, newValue: number) => setTab(newValue)}
      >
        {['New Import', 'Import Status', 'Field Mappings'].map((tabLabel, i) => (
          <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
        ))}
      </Tabs>
      <TabPanel value={tab} index={0}>
        <NewImport handleExit={() => setTab(1)} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ImportInformation />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <LoanImportMapping />
      </TabPanel>
    </Fragment>
  );
};

export default LoanImports;
