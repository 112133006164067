/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { FunctionComponent, Fragment } from 'react';
import { topStyles, panelWrap } from './styles';
import Panel from '../../ui/Panel';
import StepHeader from './StepHeader';
import StatusButton, { StatusTypes } from '../../ui/StatusButton';
import { IconType } from '../../ui/Icon';

const panelsWrap = {
  display: 'inline-block',
  marginLeft: 24,
  maxWidth: 720,
  verticalAlign: 'top',
  width: '100%',
};

type StepPanelProps = {
  stepper: React.ElementType;
  component: React.ElementType;
  button?: React.ElementType;
  showStepper?: boolean;
};

export default function StepPanel({
  stepper,
  component,
  button,
  showStepper = true,
}: StepPanelProps) {
  const Stepper = stepper;
  const Component = component;
  const Button = button;
  return (
    <div css={[topStyles, { borderTop: 'none' }]}>
      {showStepper && <Stepper />}
      <div css={panelsWrap}>
        <Component />
        {button && (
          <div className="df jcfe mt4">
            <Button />
          </div>
        )}
      </div>
    </div>
  );
}

type StepComponentPanelProps = {
  headerText: string;
  number: number;
  button?: React.ElementType;
  overrideStyles?: any;
};

export const StepComponentPanel: FunctionComponent<StepComponentPanelProps> = ({
  headerText,
  number,
  children,
  button,
  overrideStyles,
}) => {
  const Button = button;
  return (
    <Fragment>
      <Panel styles={[panelWrap, { display: 'inline-block' }, overrideStyles]}>
        <StepHeader text={headerText} number={number} />
        {children}
      </Panel>
      {button && (
        <div className="df jcfe mt3">
          <Button />
        </div>
      )}
    </Fragment>
  );
};

type StepButtonProps = {
  text: string;
  disabled: boolean;
  status?: StatusTypes;
  onClick: () => void;
};

export const StepButton: FunctionComponent<StepButtonProps> = ({
  text,
  disabled,
  status,
  onClick,
}) => {
  return (
    <StatusButton
      text={text}
      disabled={disabled}
      initialIcon={IconType.RightArrow}
      {...(status && { status })}
      onClick={onClick}
    />
  );
};
