/** @jsx jsx */
import { jsx } from '@emotion/core';
import makeStyles from '@mui/styles/makeStyles';
import { SortResponse as SortResponseType } from '../SortDocumentPanel';
import SortResponse from './SortResponse';
import ClientInvestorData from './ClientInvestorData';
import colors from '../../../../styles/colors';
import { isWrongClient } from './ResultPanel';

const useStyles = makeStyles({
  container: {
    border: 'solid 1px #e6e4e8',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
    overflow: 'hidden',
  },
  warning: {
    height: '75px',
    padding: '24px',
    marginBottom: 0,
    fontFamily: 'Roboto, Helvetica, sansSerif',
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    borderRadius: '0',
    borderTop: 'solid 1px #e6e4e8',
    color: colors.red,
    backgroundColor: '#fffeff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

type FullDisplayProps = {
  clientName: string;
  sortResponse: SortResponseType;
};

const FullDisplay = ({ clientName, sortResponse }: FullDisplayProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SortResponse
        alertType={sortResponse.alertType}
        primaryMessage={sortResponse.message}
        secondaryMessage={sortResponse.additionalInformation}
        isWrongClient={isWrongClient(clientName, sortResponse?.sortDocumentLocation?.client)}
      />
      <ClientInvestorData
        clientName={sortResponse.sortDocumentLocation?.client || ''}
        investorName={sortResponse.sortDocumentLocation?.investor || ''}
      />
      {!sortResponse.sortDocumentLocation?.docUploaded && (
        <div className={classes.warning}>Warning: Document was not yet scanned!</div>
      )}
    </div>
  );
};

export default FullDisplay;
