/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useEmailStyles from './ManualFollowupStyles';
import { EmailStatus, useManualFollowups } from './useManualFollowups';
import colors from '../../../styles/colors';
import EmailValidationInput from './EmailValidationInput';

export default function OverrideEmailPanel() {
  const classes = useEmailStyles();
  const [
    {
      isOverride,
      isUpdate,
      updateGlobal,
      updateLocal,
      emailsSent,
      validatedOverrideEmail,
      sendToInvalidOverride,
      titlePortalMode,
    },
    setManualFollowups,
  ] = useManualFollowups();
  const [expanded, setExpanded] = useState(true);
  const [overrideEmail, setOverrideEmail] = useState('');
  const [overrideEmailStatus, setOverrideEmailStatus] = useState<EmailStatus>();

  useEffect(() => {
    if (isUpdate) setManualFollowups({ updateGlobal: false, updateLocal: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    const useEmail =
      overrideEmailStatus === EmailStatus.Valid ||
      (sendToInvalidOverride && overrideEmailStatus === EmailStatus.SoftInvalid);
    setManualFollowups({ validatedOverrideEmail: useEmail ? overrideEmail : '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideEmailStatus, overrideEmail, sendToInvalidOverride]);

  return (
    <Accordion
      disabled={emailsSent || titlePortalMode}
      expanded={expanded && !titlePortalMode}
      className={emailsSent ? classes.disableChildren : ''}
    >
      <AccordionSummary onClick={() => setExpanded(!expanded)} expandIcon={<ExpandMoreIcon />}>
        <b>Override Email</b>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              checked={isOverride}
              onChange={() => setManualFollowups({ isOverride: !isOverride })}
              color="primary"
            />
          }
          label="Add override email address"
        />
        {isOverride && (
          <Fragment>
            <EmailValidationInput
              setValidatedEmail={emailWithStatus => {
                setOverrideEmail(emailWithStatus?.email ?? '');
                setOverrideEmailStatus(emailWithStatus?.status);
              }}
            />
            {overrideEmailStatus === EmailStatus.HardInvalid && (
              <span css={{ color: 'red' }}>Email is not working at all</span>
            )}
            {overrideEmailStatus === EmailStatus.SoftInvalid && (
              <Fragment>
                <span css={{ color: 'red' }}>Email is invalid</span>
                <FormControlLabel
                  control={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Checkbox
                      checked={sendToInvalidOverride}
                      onChange={() =>
                        setManualFollowups({ sendToInvalidOverride: !sendToInvalidOverride })
                      }
                      color="primary"
                      style={{ float: 'right' }}
                    />
                  }
                  label="send to this email anyway"
                />
              </Fragment>
            )}
            {validatedOverrideEmail && (
              <Fragment>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span css={{ color: !isUpdate ? colors.blue : 'default' }}>Use once</span>
                  </Grid>
                  <Grid item>
                    <Switch
                      color="primary"
                      checked={isUpdate}
                      onChange={() => setManualFollowups({ isUpdate: !isUpdate })}
                    />
                  </Grid>
                  <Grid item>
                    <span css={{ color: isUpdate ? colors.blue : 'default' }}>
                      Update contact information
                    </span>
                  </Grid>
                </Grid>
                {isUpdate && (
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        className={classes.checkboxLabel}
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Checkbox
                            color="primary"
                            checked={updateLocal}
                            onClick={() => setManualFollowups({ updateLocal: !updateLocal })}
                          />
                        }
                        label={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <span>
                              Update{' '}
                              <Typography variant="inherit" color="primary">
                                <b>loan level</b>
                              </Typography>{' '}
                              contact information
                            </span>
                          </div>
                        }
                      />
                      <FormControlLabel
                        className={classes.checkboxLabel}
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Checkbox
                            color="primary"
                            checked={updateGlobal}
                            onClick={() => setManualFollowups({ updateGlobal: !updateGlobal })}
                          />
                        }
                        label={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <div
                            css={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <span>
                              Update{' '}
                              <Typography variant="inherit" color="primary">
                                <b>global title company</b>
                              </Typography>{' '}
                              contact information
                            </span>
                          </div>
                        }
                      />
                    </FormGroup>
                  </FormControl>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
