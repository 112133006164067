/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction } from 'react';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RowData, SidebarOptions } from './types';
import DispositionSection from './SidebarSections/DispositionSection';
import EmailSection from './SidebarSections/EmailSection';
import UpdateContactInfoPanel, { UpdateContactInfoData } from './UpdateContactInfoPanel';
import { formatPhoneWithParenthesis } from '../../Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '350px',
      padding: theme.spacing(3),
      border: '1px solid #EEEEEE',
      '& h2': { fontSize: 24, fontWeight: 600 },
    },
  }),
);

type Props = {
  setDatatableData: Dispatch<SetStateAction<RowData[]>>;
  datatableData: RowData[];
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
  sidebarOption: SidebarOptions;
  selectedRows: RowData[];
};

const resolvedDatatableData = (
  prevData: RowData[],
  selectedRows: RowData[],
  callback: (row: RowData) => void,
) => {
  return prevData.map(row => {
    const selectedRow = selectedRows.find(r => r.id === row.id);
    if (selectedRow !== undefined) {
      callback(row);
    }

    return row;
  });
};

const ResolveSidebarOptions = ({
  setDatatableData,
  datatableData,
  setSidebarOption,
  sidebarOption,
  selectedRows,
}: Props) => {
  const classes = useStyles();

  const updateDatatable = (callback: (datatableRow: RowData) => void) => {
    setDatatableData(prevState => {
      return resolvedDatatableData(prevState, selectedRows, callback);
    });
  };

  const updateContactInfoData = (updatedInfo: UpdateContactInfoData) => {
    setDatatableData(d =>
      d.map(row => {
        if (updatedInfo.loanIds?.includes(row.id)) {
          if (updatedInfo.email) row.loanLevelContactInformation.email = updatedInfo.email;
          if (updatedInfo.deleteEmail) row.loanLevelContactInformation.email = '';
          if (updatedInfo.phone)
            row.loanLevelContactInformation.phone = formatPhoneWithParenthesis(updatedInfo.phone);
        }

        if (updatedInfo.titleCompanyId === row.titleCompanyId) {
          if (updatedInfo.email) row.titleCompanyEmailGlobal = updatedInfo.email;
          if (updatedInfo.phone)
            row.titleCompanyPhoneGlobal = formatPhoneWithParenthesis(updatedInfo.phone);
        }

        return row;
      }),
    );
  };

  const sidebar = () => {
    switch (sidebarOption) {
      case 'disposition':
        return (
          <DispositionSection
            selectedRows={selectedRows}
            updateDatatable={updateDatatable}
            setSidebarOption={setSidebarOption}
          />
        );
      case 'email':
        return (
          <EmailSection
            setDatatableData={setDatatableData}
            datatableData={datatableData}
            selectedRows={selectedRows}
            setSidebarOption={setSidebarOption}
            updateContactInfoData={updateContactInfoData}
          />
        );
      case 'other':
        return (
          <UpdateContactInfoPanel
            closePanel={() => setSidebarOption(undefined)}
            titleCompanyId={selectedRows[0]?.titleCompanyId}
            loanIds={selectedRows.map(r => r.id)}
            UpdateContactInfoData={updateContactInfoData}
            updateDatatable={updateDatatable}
          />
        );
    }
  };

  if (sidebarOption === undefined) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Paper className={classes.paper} elevation={0}>
        {sidebar()}
      </Paper>
    </Fragment>
  );
};

export default ResolveSidebarOptions;
