import { useCallback, useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { apiFetch } from '../../../adalConfig';
import { Filter, FilterOption, FiltersForFilterType, FilterType, FilterTypes } from './types';
import { useToaster } from '../../../Hooks/toasters';
import { ObjectParam, useQueryParams } from 'use-query-params';
import dotObject from 'dot-object';

type FilterParams = {
  [key: string]: string | string[];
};

export default function useDataTableFilters(filterUrl?: string | undefined) {
  const [dataTableFilterTypes, setDataTableFilterTypes] = useState<FilterTypes>([]);

  const [query] = useQueryParams({
    filters: ObjectParam,
  });
  const { errorToaster } = useToaster();

  const filterParams = useCallback(
    (filters: typeof query.filters) => {
      if (!filters) {
        return {} as FilterParams;
      }

      return Object.entries(filters).reduce((final, [key, value]) => {
        if (!value) {
          return final;
        }

        const splitFilterValue = value.split(',');
        final[key] = splitFilterValue.length > 1 ? splitFilterValue : value;

        return final;
      }, {} as FilterParams);
    },
    [query.filters],
  );

  useEffect(() => {
    const activateFilters = (filterTypes: FilterTypes): FilterTypes => {
      return produce(filterTypes, (draft: FilterTypes) => {
        const radioFilters = draft.find(
          (filtersForFilterTypes: FiltersForFilterType) =>
            filtersForFilterTypes.filterType === FilterType.Radio,
        );
        if (radioFilters !== undefined) {
          radioFilters.filters.map(setDefaultValueForInactiveFilter);
        }
      });
    };

    const getFilters = async () => {
      if (!filterUrl) {
        return;
      }

      try {
        const { data } = await apiFetch<{ filterTypes: FilterTypes }>(filterUrl, {
          params: dotObject.dot({ filters: filterParams(query.filters) }),
        });

        const activatedFilterTypes = activateFilters(data.filterTypes);

        setDataTableFilterTypes(activatedFilterTypes);
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      }
    };

    getFilters();
  }, [filterUrl]);

  const setDefaultValueForInactiveFilter = (filter: Filter): Filter => {
    const activeFilter = filter.filterOptions.find(
      (filterOption: FilterOption) => filterOption.active,
    );
    if (activeFilter === undefined) {
      filter.filterOptions[0].active = true;
    }

    return filter;
  };

  const onCustomOptionSelected = useCallback(
    (filter: Filter, filterOption: FilterOption) => {
      const updated = produce(dataTableFilterTypes, dataTableFilterTypesDraft => {
        dataTableFilterTypesDraft
          .find(filtertype => filtertype.filterType === FilterType.Custom)
          ?.filters.forEach(({ label, filterOptions }) => {
            if (label === filter.label) {
              const exists = filterOptions.some(option => option.id === filterOption.id);
              filterOptions.forEach(option => {
                option.active = option.id === filterOption.id;
              });
              if (!exists) filterOptions.push({ ...filterOption, active: true });
            }
          });
      });
      setDataTableFilterTypes(updated);
    },
    [dataTableFilterTypes],
  );

  const clearCustomFilter = useCallback(
    (filter: Filter) => {
      const updated = produce(dataTableFilterTypes, dataTableFilterTypesDraft => {
        dataTableFilterTypesDraft
          .find(filtertype => filtertype.filterType === FilterType.Custom)
          ?.filters.forEach(({ label, filterOptions }) => {
            if (label === filter.label) {
              filterOptions.forEach(option => {
                option.active = false;
              });
            }
          });
      });
      setDataTableFilterTypes(updated);
    },
    [dataTableFilterTypes],
  );

  const clearCheckBoxFilter = useCallback(
    (filter: Filter) => {
      const updated = produce(dataTableFilterTypes, dataTableFilterTypesDraft => {
        dataTableFilterTypesDraft
          .find(filtertype => filtertype.filterType === FilterType.Checkbox)
          ?.filters.forEach(({ label, filterOptions }) => {
            if (label === filter.label) {
              filterOptions.forEach(option => {
                option.active = option.label === 'Failed';
              });
            }
          });
      });
      setDataTableFilterTypes(updated);
    },
    [dataTableFilterTypes],
  );

  const getFilterByType = useCallback(
    (filterTypes: FilterTypes, filterType: FilterType): Filter[] => {
      return (
        filterTypes.find(
          (filtersForFilterType: FiltersForFilterType) =>
            filtersForFilterType.filterType === filterType,
        )?.filters ?? []
      );
    },
    [],
  );

  const customFilters = useMemo(() => {
    return getFilterByType(dataTableFilterTypes, FilterType.Custom);
  }, [dataTableFilterTypes, getFilterByType]);

  const checkboxFilters = useMemo(() => {
    return getFilterByType(dataTableFilterTypes, FilterType.Checkbox);
  }, [dataTableFilterTypes, getFilterByType]);

  return {
    dataTableFilterTypes,
    setDataTableFilterTypes,
    onCustomOptionSelected,
    customFilters,
    checkboxFilters,
    clearCustomFilter,
    clearCheckBoxFilter,
  };
}
