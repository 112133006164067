import React, { Fragment } from 'react';
import { AffectedField, ChangeLogs, TableChanges } from './types';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';

type ChangeLogsModalProps = {
  changeLogsList: ChangeLogs[] | null;
  clearChangeLogs: () => void;
};

type ChangesContainerProps = {
  changeLogs: ChangeLogs;
};

type ChangesDisplayProps = {
  tableChangesList: TableChanges[];
};

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ChangeLogsModal = ({ changeLogsList, clearChangeLogs }: ChangeLogsModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={changeLogsList !== null} onClose={clearChangeLogs}>
      <div className={classes.paper}>
        {changeLogsList?.map((changeLogs) => (
          <ChangesContainer key={changeLogs.id} changeLogs={changeLogs} />
        ))}
      </div>
    </Modal>
  );
};

export default ChangeLogsModal;

const ChangesContainer = ({ changeLogs }: ChangesContainerProps) => (
  <Box sx={{ width: '100%' }}>
    <Paper sx={{ width: '100%', mb: 2 }}>
      <Typography className="tac py1" variant="h6" component="div">
        Changes made by {changeLogs.user} at {new Date(changeLogs.timestamp).toLocaleString()}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Original Value</TableCell>
              <TableCell>New Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ChangesDisplay tableChangesList={changeLogs.tableChangesList} />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  </Box>
);

const ChangesDisplay = ({ tableChangesList }: ChangesDisplayProps) => {
  const formatted = tableChangesList.flatMap((tableChanges) =>
    tableChanges.TableName === 'Clients'
      ? clientChanges(tableChanges.AffectedFields)
      : chargeChanges(tableChanges.AffectedFields),
  );

  return (
    <Fragment>
      {formatted.map((affectedField) => (
        <TableRow
          key={affectedField.Field}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell>{affectedField.Field}</TableCell>
          <TableCell>{affectedField.OldValue}</TableCell>
          <TableCell>{affectedField.NewValue}</TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

const clientChanges = (affectedFields: AffectedField[]) =>
  affectedFields.filter((affectedField) => {
    const lowerCasedField = affectedField.Field.toLowerCase();
    // These fields are always modified and not by the user
    if (lowerCasedField === 'modifiedat' || lowerCasedField === 'faxfolderemail') {
      return false;
    }

    // The data type of 'state' in the database is CHAR(15) which means that the DB will pad the value with spaces until 15 characters in length which is why there will always be an entry of a change for this field
    if (
      lowerCasedField === 'state' &&
      affectedField.OldValue.trim() === affectedField.NewValue.trim()
    ) {
      return false;
    }

    return true;
  });

const chargeChanges = (affectedFields: AffectedField[]) =>
  affectedFields.map(
    (affectedField) =>
      ({
        // 'Field' is set to 'Amount' in the DB (since that's the field that was changed). The user only cares about the charge type that was changed. The charge type is stored as 'Metadata' which is why 'Field' is being set by 'Metadata'
        Field: affectedField.Metadata,
        OldValue: affectedField.OldValue,
        NewValue: affectedField.NewValue,
      } as AffectedField),
  );
