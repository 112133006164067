/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { Filter } from './types';
import produce from 'immer';
import TitleCompanySearchSelect from '../../Utilities/TitleCompanySearchSelect';

type Props = {
  filters: Filter[];
  updateSelectFilters: (filtersForFilterType: Filter[]) => void;
};

const SelectFilters = ({ filters, updateSelectFilters }: Props) => {
  function filterUpdated(updatedFilterOption: Filter, filterOptionId) {
    const updated = produce(filters, filtersDraft => {
      filtersDraft.forEach(filter => {
        if (filter.label === updatedFilterOption.label) {
          filter.filterOptions.forEach(filterOption => {
            filterOption.active = filterOption.id === filterOptionId;
          });
        }
      });
    });
    updateSelectFilters(updated);
  }

  function hasOptionSelected(filter: Filter) {
    return filter.filterOptions.some(option => option.active);
  }

  const [newOptionInput, setNewOptionInput] = useState('');

  function addOption(currentFilter: Filter) {
    const updated = produce(filters, filtersDraft => {
      filtersDraft.forEach(filter => {
        if (filter.label === currentFilter.label) {
          const maxId =
            filter.filterOptions
              .map(filterOption => filterOption.id)
              .sort()
              .reverse()?.[0] ?? 0;
          filter.filterOptions.push({ label: newOptionInput, active: false, id: maxId + 1 });
        }
      });
    });
    updateSelectFilters(updated);
    setNewOptionInput('');
  }

  return (
    <Fragment>
      {filters.map(selectFilter => (
        <Fragment key={selectFilter.label}>
          {hasOptionSelected(selectFilter) ? <div>{selectFilter.label}</div> : null}
          <select onChange={e => filterUpdated(selectFilter, parseInt(e.target.value))}>
            <option value={-1}>Select {selectFilter.label}</option>
            {selectFilter.filterOptions.map(option => (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            ))}
          </select>
          <input
            type="text"
            onChange={e => setNewOptionInput(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                addOption(selectFilter);
              }
            }}
            value={newOptionInput}
          />
          <div>{newOptionInput}</div>

        </Fragment>
      ))}
    </Fragment>
  );
};

export default SelectFilters;
