/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import queryString from 'query-string';

import ImportErrorTabs from '../components/Imports/ImportErrors/ImportErrorTabs';
import ClientHeader from '../components/Imports/ImportErrors/LoanErrors/ClientHeader';
import Paginator from '../components/Imports/ImportErrors/Paginator';
import MissingDataPanel from '../components/Imports/ImportErrors/LoanErrors/MissingDataPanel';
import StatusButton, { StatusTypes } from '../components/ui/StatusButton';
import {
  loanErrorIssues,
  SuccessTimeout,
  isEasyToResolve,
  LoanError,
} from '../components/Imports/ImportErrors/ImportErrorsUtils';
import NotesPanel from '../components/Imports/ImportErrors/LoanErrors/NotesPanel/NotesPanel';
import Header from '../components/ui/Header';
import ImportHeader from '../components/Imports/ImportHeader';
import AllErrorsResolved from '../components/Imports/ImportErrors/LoanErrors/AllErrorsResolved';

import { SidebarConsumer } from '../components/Layout/Sidebar/SidebarContext';
import { panelWrap } from '../components/ui/Panel';
import { apiFetch, apiPost } from '../adalConfig';
import { Client, Loan } from '../globalTypes/objects';

// #region pure functions
const getClient = async (clientId: number) => {
  const { data: client } = await apiFetch<Client>(`/api/clients/getClientById?id=${clientId}`);
  return client;
};

const checkIfAssociate = (issues: string[]) =>
  issues.includes(loanErrorIssues.INVESTOR_NAME) ||
  issues.includes(loanErrorIssues.SERVICER) ||
  issues.includes(loanErrorIssues.TITLE_COMPANY_NAME) ||
  issues.includes(loanErrorIssues.TITLE_COMPANY_ADDRESS);

const getResolveButtonText = (status, issues: string[]) => {
  const associate = checkIfAssociate(issues);
  if (status === 'resolving') {
    `resolving${associate ? ' and associating' : ''}`;
  }
  if (status === 'resolved') {
    `resolved${associate ? ' and associated' : ''}`;
  }
  return `resolve${associate ? ' and associate' : ''}`;
};

const sortLoanErrors = (errors: LoanError[]) =>
  errors.reduce(
    (acc, loanError) =>
      isEasyToResolve(loanError.loanErrorIssues) ? [loanError, ...acc] : [...acc, loanError],
    [],
  );

const getIndex = props => +(queryString.parse(props.location.search).index || 0);
// #endregion

const defaultState = {
  client: (undefined as unknown) as Client,
  errors: [] as LoanError[],
  resolvedError: undefined,
  resolveStatus: StatusTypes.initial,
};

const getLoanErrors = async (clientId: number) => {
  const { data: loanErrors } = await apiFetch<LoanError[]>(
    `/api/loanErrors/getLoanErrorsByClient?clientId=${clientId}`,
  );
  return sortLoanErrors(loanErrors);
};

type LoanErrorResolverState = {
  client: Client | undefined;
  errors: LoanError[];
  resolvedError: LoanError | undefined;
  resolveStatus: StatusTypes;
};

type LoanErrorResolverProps = {
  history: any;
  location: any;
  match: any;
};
export default class LoanErrorResolver extends Component<
  LoanErrorResolverProps,
  LoanErrorResolverState
> {
  state = defaultState;

  async componentDidMount() {
    const { clientId } = this.props.match.params;
    const client = await getClient(+clientId);
    const errors = await getLoanErrors(clientId);
    this.setState({ client, errors });
  }

  onResolveClick = async (updateLoanErrorCount: Function) => {
    const { resolvedError, client } = this.state;
    const { history, location } = this.props;
    const currentIndex = getIndex(this.props);
    try {
      await this.setState({ resolveStatus: StatusTypes.loading });
      await apiPost('/api/loanErrors/resolveLoanError', resolvedError!);
      await updateLoanErrorCount();
      await this.setState({ resolveStatus: StatusTypes.success });
      window.localStorage.removeItem(`state${(resolvedError! as LoanError).id}`);
      setTimeout(async () => {
        const errors = await getLoanErrors((client! as Client).id);
        if (errors.length - 1 < currentIndex) {
          history.push(`${location.pathname}?index=${currentIndex - 1}`);
        }
        this.setState({
          errors,
          resolvedError: undefined,
          resolveStatus: StatusTypes.initial,
        });
      }, SuccessTimeout);
    } catch (err) {
      await this.setState({ resolveStatus: StatusTypes.error });
    }
  };

  onNextClick = () => {
    const { history, location } = this.props;
    const { errors } = this.state;
    const currentIndex = getIndex(this.props);
    this.setState(defaultState);
    if (currentIndex === errors!.length - 1) {
      history.push(`${location.pathname}?index=${0}`);
      return;
    }
    history.push(`${location.pathname}?index=${currentIndex + 1}`);
  };

  onPreviousClick = () => {
    const { history, location } = this.props;
    const { errors } = this.state;
    const currentIndex = getIndex(this.props);
    this.setState(defaultState);
    if (currentIndex === 0) {
      history.push(`${location.pathname}?index=${errors!.length - 1}`);
      return;
    }
    history.push(`${location.pathname}?index=${currentIndex - 1}`);
  };

  render() {
    const { client, errors, resolvedError, resolveStatus } = this.state;
    if (!client || !errors) {
      return null;
    }
    const currentIndex = getIndex(this.props);
    const error = errors[currentIndex];
    return (
      <Fragment>
        <Header headerText="Import Errors" fixed />
        <div css={{ position: 'fixed', width: 800, top: 0, zIndex: 250 }}>
          <ImportHeader />
        </div>
        <div css={{ marginLeft: 40, maxWidth: 1280, padding: '32px 0 40px', marginTop: 64 }}>
          <ImportErrorTabs />
          {!errors!.length ? (
            <AllErrorsResolved clientName={(client! as Client).company} />
          ) : (
              <SidebarConsumer>
                {({ updateLoanErrorCount }) => (
                  <Fragment>
                    <div css={{ marginBottom: 24 }}>
                      <ClientHeader client={client!} />
                    </div>
                    <Paginator
                      totalNum={errors.length}
                      currentNum={currentIndex + 1}
                      onNextClick={this.onNextClick}
                      onPreviousClick={this.onPreviousClick}
                    />
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div css={panelWrap}>
                        <MissingDataPanel
                          error={error}
                          client={client}
                          onChange={(data: LoanError) => this.setState({ resolvedError: data })}
                        />
                        <div css={{ marginTop: 32, textAlign: 'center' }}>
                          <StatusButton
                            text={getResolveButtonText(
                              resolveStatus,
                              (error as LoanError).loanErrorIssues,
                            )}
                            disabled={!resolvedError}
                            onClick={() => this.onResolveClick(updateLoanErrorCount)}
                            status={resolveStatus}
                          />
                        </div>
                      </div>
                      <NotesPanel id={(error as LoanError).id} />
                    </div>
                  </Fragment>
                )}
              </SidebarConsumer>
            )}
        </div>
      </Fragment>
    );
  }
}
