import React, { Component, ErrorInfo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import axios from 'axios';
import { msalInstance } from './auth-config';
import { RawIndexMap, RawSourceMap, SourceMapConsumer } from 'source-map';
import Page500 from './pages/500';
import { apiFetch } from './adalConfig';

/*
 * Matches both of these bundle files (with or without 'bundle' in the filename)
 * /dist/main.e82625b44fbef4192a15.bundle.js
 * /dist/main.e82625b44fbef4192a15.js
 */
const regexForMatchingBundleFile = /\/dist\/main\.[A-Za-z0-9]+?\.(?:bundle\.)?js/;

class ErrorBoundary extends Component<RouteComponentProps> {
  state = {
    hasError: false,
  };

  getErrorFromSourceMap = async error => {
    const filePaths = [...document.querySelectorAll('script')]
      .filter(x => x.src.match(regexForMatchingBundleFile))
      .map(x => `${x.src.replace(/.*?(\/dist\/.+)/, '$1')}.map`);

    const stackTraceForMainFile = error.stack
      .split('at')
      .filter(x => x.includes('/dist/main'))
      .join();

    const stackTrace = stackTraceForMainFile
      .match(/\d+:\d+/g)
      .map(x => x.split(':').filter(Boolean));

    const { data: rawSourceMap } = await apiFetch<RawSourceMap | RawIndexMap | string>(
      filePaths[0],
    );

    SourceMapConsumer.initialize({
      'lib/mappings.wasm': 'https://unpkg.com/source-map@0.7.3/lib/mappings.wasm',
    });

    return SourceMapConsumer.with(rawSourceMap, null, consumer =>
      stackTrace
        .map(([lineNumber, columnNumber]) => {
          const line = parseInt(lineNumber);
          const column = parseInt(columnNumber);

          return consumer.originalPositionFor({
            line,
            column,
          });
        })
        .filter(d => !!d.source),
    );
  };

  logToSlack = async (error: Error, info: ErrorInfo) => {
    const { location } = this.props;
    const account = msalInstance.getActiveAccount();
    const stackTraces = await this.getErrorFromSourceMap(error);

    const stackTraceToString = stackTraces
      .map(stackTrace => {
        const sourceFilePathParts = stackTrace.source
          ?.split('/')
          .filter(part => part.length && !part.startsWith('webpack'));

        const fileName = sourceFilePathParts.pop();
        sourceFilePathParts.push(`*${fileName}*`);
        const sourceFilePath = sourceFilePathParts.join('/');

        return `at ${sourceFilePath} line: ${stackTrace.line}, column: ${stackTrace.column}`;
      })
      .join('\n');

    await axios.post(
      'https://hooks.slack.com/services/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',
      JSON.stringify({
        text: `*${location.pathname}${location.search}*
        *User: ${account?.idTokenClaims?.email}*
        ${error.message}
        ${stackTraceToString}
        ${error.stack}`,
      }),
    );
  };

  async componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    if (ENVIRONMENT === 'Production') {
      console.log({ ENVIRONMENT });
      await this.logToSlack(error, info);
    }
  }

  render() {
    if (this.state.hasError) {
      return <Page500 />;
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
