/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import produce from 'immer';
import { Filter } from './types';
import CheckboxFilter from './CheckboxFilter';

type Props = {
  filters: Filter[];
  updateCheckboxFilters: (filtersForFilterType: Filter[]) => void;
};

const CheckboxFilters = ({ filters, updateCheckboxFilters }: Props) => {
  const setFilter = (updatedFilter: Filter) => {
    const newCheckboxFilters = produce(filters, (draft: Filter[]) => {
      const updatedIndex = filters.findIndex(
        (filter: Filter) => filter.label === updatedFilter.label,
      );
      if (updatedIndex !== -1) {
        draft[updatedIndex] = updatedFilter;
      }
    });

    updateCheckboxFilters(newCheckboxFilters);
  };

  return (
    <Fragment>
      {filters.map((filter: Filter) => (
        <CheckboxFilter key={filter.label} filter={filter} setFilter={setFilter} />
      ))}
    </Fragment>
  );
};

export default CheckboxFilters;
