/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { roleTypes } from '../../constants';
import colors from '../../styles/colors';
import { AuthContext } from '../AuthContext';
import { useTitleCompanies } from './useTitleCompanies';

export default function TableData({
  history,
  consolidationModal = false,
}: {
  history?: any;
  consolidationModal?: boolean;
}) {
  const [
    { data, selectedIds, selectedRows, selectionMode, mainConsolidatedTitleCompany },
    setTitleCompanies,
  ] = useTitleCompanies();
  const authContext = useContext(AuthContext);

  const handleCheckboxClick = (titleCompanyId: number) => {
    const newSelectedIds = new Set(selectedIds);
    selectedIds.has(titleCompanyId)
      ? newSelectedIds.delete(titleCompanyId)
      : newSelectedIds.add(titleCompanyId);
    setTitleCompanies({
      selectedIds: newSelectedIds,
    });
  };

  const dataToRender = consolidationModal ? selectedRows : data;
  const showSelection = selectionMode && !consolidationModal;

  return (
    <TableBody>
      {dataToRender.length === 0 && (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography align="center">No records to display</Typography>
          </TableCell>
        </TableRow>
      )}
      {dataToRender.map(row => {
        const tableCellStyle = row.verifiedAt ? { color: colors.blue, fontWeight: 'bold' } : {};
        return (
          <TableRow
            key={row.id}
            hover
            onClick={() => {
              if (consolidationModal) setTitleCompanies({ mainConsolidatedTitleCompany: row });
              else if (!selectionMode) history.push(`/title-companies/${row.id}`);
            }}
            selected={
              (showSelection && selectedIds.has(row.id)) ||
              (consolidationModal && mainConsolidatedTitleCompany?.id === row.id)
            }
          >
            {showSelection && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="error"
                  checked={selectedIds.has(row.id)}
                  onClick={e => {
                    e.stopPropagation();
                    handleCheckboxClick(row.id);
                  }}
                />
              </TableCell>
            )}
            {authContext.roles.includes(roleTypes.Dev) && (
              <TableCell>
                <span css={tableCellStyle}>{row.id}</span>
              </TableCell>
            )}
            <TableCell>
              <span css={tableCellStyle}>{row.name}</span>
            </TableCell>
            <TableCell>
              <span css={tableCellStyle}>{row.phone}</span>
            </TableCell>
            <TableCell>
              <span css={tableCellStyle}>{row.email}</span>
            </TableCell>
            <TableCell>
              <span css={tableCellStyle}>{row.address}</span>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
