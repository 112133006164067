/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import {
  DocumentEventDrillDownRowData,
  DocumentsMatchedOrRejectedDrillDown,
  DrillDownData,
  RowData,
} from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import Link from '@mui/material/Link';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsMatchedOrRejectedByUser: RowData[];
};

const docEventDrillDownColumns = [
  {
    field: 'barcode',
    title: 'Barcode',
    options: {
      filter: false,
    },
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
    options: {
      filter: false,
    },
  },
  {
    field: 'lender',
    title: 'Lender',
    options: {
      filter: false,
    },
  },
  {
    field: 'user',
    title: 'User',
    render: (rowData: DocumentEventDrillDownRowData) =>
      rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    options: {
      filter: false,
    },
  },
  {
    field: 'date',
    title: 'Date',
    render: (rowData: DocumentEventDrillDownRowData) => new Date(rowData.date).toLocaleDateString(),
    options: {
      filter: false,
    },
  },
  {
    field: 'matchStatus',
    title: 'Match Status',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
];

const documentsMatchColumns = [
  {
    field: 'id',
    title: 'Document Id',
  },
  {
    field: 'user',
    title: 'User',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'createdAt',
    title: 'Date',
    render: (rowData: DocumentsMatchedOrRejectedDrillDown) =>
      rowData.createdAt && new Date(rowData.createdAt).toLocaleDateString(),
  },
  {
    field: 'hardCopy',
    title: 'Hard Copy',
    render: (rowData: DocumentsMatchedOrRejectedDrillDown) => (rowData.hardCopy ? 'Yes' : 'No'),
  },
  {
    field: 'lender',
    title: 'Lender',
  },
  {
    field: 'matchStatus',
    title: 'Match Status',
  },
];

export const MatchByUser = ({ documentsMatchedOrRejectedByUser }: Props) => {
  const [isAllDrillDownModalOpen, setIsAllDrillDownModalOpen] = useState(false);
  const [userDrillDownData, setUserDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns = [
    {
      field: 'count',
      title: 'Count',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setUserDrillDownData({
              date: new Date(rowData.date),
              user: rowData.user,
            })
          }
        >
          {rowData.count}
        </Link>
      ),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'user',
      title: 'User',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'date',
      title: 'Date',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      render: (rowData: RowData) => new Date(rowData.date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Button
          style={{
            position: 'absolute',
            top: 50,
            zIndex: 1,
            right: '80px',
          }}
          onClick={() => setIsAllDrillDownModalOpen(true)}
          color="primary"
          size="small"
          variant="outlined"
        >
          Drilldown
        </Button>
      </div>
      <DataTable<RowData>
        title="Documents Matched Or Rejected - By User"
        columns={columns}
        data={documentsMatchedOrRejectedByUser}
        options={{
          maxBodyHeight: '500px',
          search: false,
        }}
      />

      <Modal open={!!userDrillDownData} onClose={() => setUserDrillDownData(null)}>
        <div className={classes.paper}>
          <DataTable<DocumentEventDrillDownRowData>
            title={`Document Match Drill Down Report - For ${userDrillDownData?.user}`}
            columns={docEventDrillDownColumns}
            url={`/api/reports/document-match-by-user-drill-down-report?${queryString.stringify({
              user: userDrillDownData?.user,
              date: userDrillDownData?.date.toISOString().split('T')[0],
            })}`}
            options={{
              search: false,
            }}
          />
        </div>
      </Modal>

      <Modal open={isAllDrillDownModalOpen} onClose={() => setIsAllDrillDownModalOpen(false)}>
        <div className={classes.paper}>
          <DataTable<DocumentsMatchedOrRejectedDrillDown>
            title="Document Match Drill Down Report"
            columns={documentsMatchColumns}
            url={'/api/reports/document-match-drill-down-report'}
            options={{
              search: false,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
