/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useCallback, useEffect, useState } from 'react';
import UIfx from 'uifx';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import BarcodeInput from '../components/Utilities/BarcodeInput';
import Header from '../components/ui/Header';
import { CheckIcon, XIcon } from '../components/ui/icons';
import colors from '../styles/colors';
import Panel, { PanelHeader } from '../components/ui/Panel';
// @ts-ignore
import ErrorEffect from '../components/Documents/ErrorEffect.wav';
import {
  Failure,
  isFailure,
  isLoading,
  isSuccess,
  Loading,
  mapSuccess,
  mapSuccess2,
  NotAsked,
  RemoteData,
  Success,
} from '../globalTypes/RemoteData';

import { AuthHeader } from '../adalConfig';
import useCheckIfDocTypeUpdated from '../Hooks/useCheckIfDocTypeUpdated';
import { getDocTypeName } from '../Utils';
import { getToken } from '../auth-config';

const useStyles = makeStyles({
  bigRedMessage: {
    width: 'max-content',
    whiteSpace: 'pre-wrap',
    fontSize: 24,
    '& .MuiAlert-icon': {
      fontSize: 36,
    },
  },
});

const error = new UIfx({
  asset: ErrorEffect,
});

export type UserAlertType = 'Check' | 'X' | 'AlertBanner';

export type DocumentStatus = {
  userAlertType: UserAlertType;
  message: string;
  additionalInformation: string;
  clientId: number;
  pageCount: number;
  requiresPrinterId: boolean;
};

function getDocumentStatus(authHeader: AuthHeader, docId: number): Promise<DocumentStatus> {
  return fetch(`/api/documents/checkIfDocUploaded?docId=${docId}`, {
    headers: { ...authHeader },
  }).then(response => {
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    if (!response.ok) {
      throw response;
    }
    return response.json();
  });
}

function getNumberOfPages(authHeader: AuthHeader, docId: number): Promise<number> {
  return fetch(`/Api/Documents/GetNumberOfPages?docId=${docId}`, {
    headers: { ...authHeader },
  }).then(response => {
    if (response.status === 401) {
      throw new Error('Unauthorized');
    }
    if (!response.ok) {
      throw response;
    }
    return response.text().then(pages => {
      const parsedToInt = parseInt(pages);
      return Number.isNaN(parsedToInt) ? 0 : parsedToInt;
    });
  });
}

function Message({ status }: { status: DocumentStatus }) {
  switch (status.userAlertType) {
    case 'Check':
      return <UploadSuccess />;
    case 'X':
      return <NotUploadedIcon />;
    case 'AlertBanner':
      return <BigRedMessage>{status.message}</BigRedMessage>;
    default:
      throw new Error(`There was an Error! No status ${status} known`);
  }
}

export default function CheckUploadedDoc() {
  const [docId, setDocId] = useState<number>();
  const [docStatus, setDocStatus] = useState<RemoteData<DocumentStatus, Error>>(NotAsked);
  const [numberOfPages, setNumberOfPages] = useState<RemoteData<number, Error>>(NotAsked);
  const docTypeUpdated = useCheckIfDocTypeUpdated(docId);

  const docTypeUpdatedMessage = mapSuccess(docTypeUpdatedResult => {
    if (docTypeUpdatedResult.updated) {
      return `This document has had its DocType changed to ${getDocTypeName(
        docTypeUpdatedResult.documentType,
      )}. <br>
      Please ensure the coversheet is correct.`;
    }
    return '';
  }, docTypeUpdated);

  const playErrorSound = mapSuccess2(
    // eslint-disable-next-line no-shadow
    (status, docId) => docId && ['AlertBanner', 'X'].includes(status.userAlertType),
    docStatus,
    Success(docId),
  );

  useEffect(() => {
    setDocStatus(NotAsked);
    setNumberOfPages(NotAsked);

    getToken().then(token => {
      const authHeader: AuthHeader = { Authorization: `Bearer ${token}` };
      if (!docId) return;

      setDocStatus(Loading);
      getDocumentStatus(authHeader, docId)
        .then(docStatusResult => {
          setDocStatus(Success(docStatusResult));
        })
        .catch(e => {
          setDocStatus(Failure(e));
        });

      setNumberOfPages(Loading);
      getNumberOfPages(authHeader, docId)
        .then(numberOfPagesResult => {
          setNumberOfPages(Success(numberOfPagesResult));
        })
        .catch(e => setNumberOfPages(Failure(e)));
    });
  }, [docId]);

  useEffect(() => {
    if (isSuccess(playErrorSound) && playErrorSound.data) {
      error.play();
    }
  }, [playErrorSound, docStatus]);

  const onDocIdChange = useCallback(value => {
    setDocId(parseInt(value));
  }, []);

  return (
    <div css={{ textAlign: 'center', alignContent: 'center' }}>
      <Header headerText="Documents" />
      <Alert severity="warning">This is the old staple page</Alert>
      <div css={{ marginTop: 16 }}>
        <Panel css={{ display: 'inline-block' }}>
          <PanelHeader text="Check uploaded document" />
          <BarcodeInput keepFocus onChange={onDocIdChange} />
        </Panel>
      </div>
      <div>{isLoading(docStatus) && <div>Loading...</div>}</div>
      <div>{isFailure(docStatus) && <div>There was an error!</div>}</div>
      <div>{isSuccess(docStatus) && <Message status={docStatus.data} />}</div>
      <div>
        {isSuccess(numberOfPages) && numberOfPages.data > 0 && (
          <div css={{ fontSize: 20 }}>
            Document contains {numberOfPages.data} page{numberOfPages.data > 1 ? 's' : ''}.
          </div>
        )}
      </div>
      {isSuccess(docTypeUpdatedMessage) && (
        <div
          css={{ color: 'blue' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: docTypeUpdatedMessage.data }}
        />
      )}
    </div>
  );
}

function BigRedMessage({ children }) {
  const classes = useStyles();
  return (
    <div css={{ display: 'flex', justifyContent: 'center', margin: 16 }}>
      <Alert className={classes.bigRedMessage} severity="error">
        {children}
      </Alert>
    </div>
  );
}

function UploadedIndicatorMessage({ children }) {
  return <div css={{ svg: { height: 200, width: 200 } }}>{children}</div>;
}

function UploadSuccess() {
  return (
    <UploadedIndicatorMessage>
      <CheckIcon stroke={colors.green} />
    </UploadedIndicatorMessage>
  );
}

function NotUploadedIcon() {
  return (
    <UploadedIndicatorMessage>
      <XIcon stroke={colors.red} />
    </UploadedIndicatorMessage>
  );
}
