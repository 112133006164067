/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { DocumentEmailAttachment } from '../../globalTypes/objects';

export default function EmailAttachments({
  attachments,
}: {
  attachments: DocumentEmailAttachment[];
}) {
  return (
    <div>
      <p css={{ fontWeight: 500 }}>Attachments Imported From This Email</p>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attachment File Name</TableCell>
              <TableCell align="right">Barcode Id</TableCell>
              <TableCell align="right">Document Type</TableCell>
              <TableCell align="right">Deleted Document</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments
              .filter(a => !a.isLogo && !a.ignoreAttachment)
              .map(attachment => (
                <TableRow key={attachment.id}>
                  <TableCell component="th" scope="row">
                    {attachment.filePath.split('/').pop()}
                  </TableCell>
                  <TableCell align="right">
                    <Link target="_blank" href={`/documents/${attachment.documentId}`}>
                      {attachment.documentId ?? ''}
                    </Link>
                  </TableCell>
                  <TableCell align="right">{attachment.documentType}</TableCell>
                  <TableCell align="right">
                    {attachment.active != null && (attachment.active ? 'No' : 'Yes')}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <p css={{ marginTop: 40, fontWeight: 500 }}>Attachments Ignored From This Email</p>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attachment File Name</TableCell>
              <TableCell align="right">Logo</TableCell>
              <TableCell align="right">Ignored Document Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attachments
              .filter(a => a.isLogo || a.ignoreAttachment)
              .map(attachment => (
                <TableRow key={attachment.id}>
                  <TableCell component="th" scope="row">
                    {attachment.filePath.split('/').pop()}
                  </TableCell>
                  <TableCell align="right">
                    {attachment.isLogo && <CheckBox color="error" />}
                  </TableCell>
                  <TableCell align="right">
                    {attachment.ignoreAttachment && <CheckBox color="error" />}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
