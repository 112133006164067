/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Shortid from 'shortid';
import { apiFetch } from '../../adalConfig';
import TextInput from '../ui/Inputs/TextInput';
import { ShippingDoc, useShipping } from './useShipping';
import { ShippingClient } from './ClientModal';
import { Document, Investor, Loan } from '../../globalTypes/objects';
import { usePrevious } from '../../Hooks';
import { buildArrayFetchString, elementScrolledToBottom, getDocTypeName } from '../../Utils';
import { LargeRow, LargeRowHeader } from '../ui/LargeRowComponents';
import Checkbox from '../ui/Checkbox';
import Toggle from '../ui/Toggle';
import CircularProgress from '@mui/material/CircularProgress';
import { useToaster } from '../../Hooks/toasters';
import { AxiosError } from 'axios';

type DigitalProps = {
  client: ShippingClient;
  investor: Investor;
};

enum DigitalMode {
  Input,
  Individual,
}

const Digital = ({ client, investor }: DigitalProps) => {
  const [availableDocs, setAvailableDocs] = useState<number[]>();
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(DigitalMode.Input);

  const { errorToaster } = useToaster();

  // load available docs for client
  useEffect(() => {
    const getAvailableDocs = async () => {
      try {
        const { data: available } = await apiFetch<number[]>(
          `/api/shipping/availableDigitals?clientId=${client.id}&investorId=${investor.id}`,
        );
        setAvailableDocs(available);
        setLoading(false);
      } catch (e) {
        errorToaster(
          'Failed to load digital documents. ' + (e as AxiosError).response?.data.split('\n')[0],
        );
      }
    };

    getAvailableDocs();
  }, [client.id, investor.id]);

  if (loading) {
    return (
      <div className="center-in-parent" style={{ height: '100px' }}>
        <CircularProgress size="25" disableShrink />
      </div>
    );
  }

  return (
    <Fragment>
      {availableDocs && availableDocs.length > 0 ? (
        <Fragment>
          <div css={{ marginTop: 10, marginBottom: 10 }}>
            <Toggle
              active={mode === DigitalMode.Individual}
              text="Choose documents"
              handleToggle={() =>
                setMode(
                  mode === DigitalMode.Individual ? DigitalMode.Input : DigitalMode.Individual,
                )
              }
            />
          </div>
          {mode === DigitalMode.Input && <Input docIds={availableDocs} client={client} />}
          {mode === DigitalMode.Individual && <Individual docIds={availableDocs} client={client} />}
        </Fragment>
      ) : (
        <p>No documents available for {client.client}</p>
      )}
    </Fragment>
  );
};

export default Digital;

type ModeProps = {
  docIds: number[];
  client: ShippingClient;
};

const Input = ({ docIds, client }: ModeProps) => {
  const [amount, setAmount] = useState<number>();
  const [shipping, setShipping] = useShipping();

  const prevClient = usePrevious<ShippingClient | undefined>(client);

  // set input to amount selected per client when amount or client changes
  useEffect(() => {
    const currentClientDocs = shipping.digitalDocs.filter(d => d.clientId === client.id);
    if (currentClientDocs.length > 0 || (prevClient && prevClient.id !== client.id)) {
      setAmount(currentClientDocs.length);
    }
  }, [client.id, prevClient, shipping.digitalDocs]);

  const prevAmount = usePrevious<number | undefined>(amount);
  const setShippingCallback = useCallback(setShipping, []);

  // update selected docs when user changes amount
  useEffect(() => {
    const setDocsEffect = () => {
      const filteredDocs = shipping.digitalDocs.filter(d => d.clientId !== client.id);
      const currentClientDigitals: ShippingDoc[] | undefined =
        docIds &&
        docIds
          .sort()
          .slice(0, amount)
          .map(d => ({ clientId: client.id, docId: d }));
      if (currentClientDigitals) {
        setShippingCallback({ digitalDocs: [...filteredDocs, ...currentClientDigitals] });
      }
    };
    if (
      prevAmount !== amount &&
      (amount || amount === 0) &&
      amount !== shipping.digitalDocs.length
    ) {
      setDocsEffect();
    }
  }, [amount, client.id, docIds, prevAmount, setShippingCallback, shipping.digitalDocs]);

  const handleAmountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericVal = +e.target.value.replace(/\D/g, '');
    if (numericVal <= docIds.length) {
      setAmount(numericVal);
    }
  };

  return (
    <Fragment>
      <p>
        <b>{docIds.length}</b> available documents
      </p>
      <br />
      <p>Number of documents</p>
      <TextInput
        placeholder="Amount"
        value={amount && amount > 0 ? amount : ''}
        onChange={handleAmountInput}
      />
    </Fragment>
  );
};

const Individual = ({ docIds, client }: ModeProps) => {
  const [loading, setLoading] = useState(true);
  const [digitals, setDigitals] = useState<{ document: Document; loan: Loan }[]>();

  // load doc details so user can select them manually
  useEffect(() => {
    const getSubmitted = async () => {
      const { data: submitted } = await apiFetch<{ document: Document; loan: Loan }[]>(
        `/api/shipping/digitalDocInfo?${buildArrayFetchString(docIds.slice(0, 11), 'docIds')}`,
      );
      setDigitals(submitted);
      setLoading(false);
    };
    getSubmitted();
  }, [docIds]);

  const handleScrollToBottom = async () => {
    // @ts-ignore
    const docsToFetch = docIds.slice(digitals.length || 0, (digitals.length || 0) + 11);
    if (docsToFetch.length === 0) return;
    const { data: submitted } = await apiFetch<{ document: Document; loan: Loan }[]>(
      `/api/shipping/digitalDocInfo?${buildArrayFetchString(docsToFetch, 'docIds')}`,
    );
    setDigitals(d => [...d, ...submitted]);
  };

  return (
    <div>
      <LargeRowHeader styles={grid}>
        <div>&nbsp;</div>
        <div>Borrower Name</div>
        <div>Loan Number</div>
        <div>Document Type</div>
      </LargeRowHeader>
      {!loading && (
        <div
          css={{ overflow: 'auto', maxHeight: 300 }}
          onScroll={e => {
            if (elementScrolledToBottom(e)) handleScrollToBottom();
          }}
        >
          <br />
          {digitals!.map(d => (
            <DigitalRow
              key={Shortid.generate()}
              document={d.document}
              loan={d.loan}
              client={client}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const rowStyles: CSSObject = {
  marginLeft: 5,
  width: '98%',
  height: 50,
};

const grid: CSSObject = {
  display: 'grid',
  gridColumnGap: '8px',
  gridTemplateColumns: '1fr 2fr 2fr 2fr',
};

const DigitalRow = ({
  document,
  loan,
  client,
}: {
  document: Document;
  loan: Loan;
  client: ShippingClient;
}) => {
  const [shipping, setShipping] = useShipping();

  const updateDigitalDocs = () => {
    // remove from digitals if unchecking
    const existingDoc = shipping.digitalDocs.find(d => d.docId === document.id);
    if (existingDoc) {
      setShipping({ digitalDocs: shipping.digitalDocs.filter(d => d.docId !== document.id) });
    }
    // if checking add to digitals
    else {
      setShipping({
        digitalDocs: [...shipping.digitalDocs, { docId: document.id, clientId: client.id }],
      });
    }
  };

  return (
    <LargeRow styles={[rowStyles, grid]} onClick={updateDigitalDocs}>
      <div>
        <Checkbox checked={!!shipping.digitalDocs.find(d => d.docId === document.id)} />
      </div>
      <div>{loan.borrower}</div>
      <div>{loan.loanNumber}</div>
      <div>{getDocTypeName(document.documentType)}</div>
    </LargeRow>
  );
};
