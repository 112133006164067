/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Client, LoanImportMapping } from '../../../globalTypes/objects';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { apiPost } from '../../../adalConfig';
import { useToaster } from '../../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContent: {
      marginTop: '24px',
      display: 'grid',
      gridTemplateColumns: '1fr 25px 1fr',
      gap: '8px',
    },
    buttonSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '8px',
      marginTop: '16px',
    },
  }),
);

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type Props = {
  client: Client;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedMapping: LoanImportMapping | null;
  setSelectedMapping: Dispatch<SetStateAction<LoanImportMapping | null>>;
  systemMappingFields: string[];
  onMappingsSaved: () => void;
};

const SetMappingModal = ({
  client,
  open,
  setOpen,
  selectedMapping,
  setSelectedMapping,
  systemMappingFields,
  onMappingsSaved,
}: Props) => {
  const [clientFieldName, setClientFieldName] = useState('');
  const [systemFieldName, setSystemFieldName] = useState('');

  useEffect(() => {
    setClientFieldName(selectedMapping?.externalSourcePropertyName ?? '');
    setSystemFieldName(selectedMapping?.systemPropertyName ?? '');
  }, [selectedMapping]);

  const { successToaster, errorToaster, warningToaster } = useToaster();
  const classes = useStyles();

  const closeModal = () => {
    setClientFieldName('');
    setSystemFieldName('');
    setOpen(false);
    setSelectedMapping(null);
  };

  const saveSetting = async () => {
    if (!canSave()) {
      warningToaster('All fields are required');
      return;
    }

    const postData: LoanImportMapping = {
      id: selectedMapping?.id,
      clientId: client.id,
      externalSourcePropertyName: clientFieldName.trim(),
      systemPropertyName: systemFieldName,
    };

    try {
      await apiPost('/api/loans/save-import-mappings-field', postData);
      successToaster('Setting saved');
      closeModal();
      onMappingsSaved();
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.message);
        return;
      }

      errorToaster(
        <Fragment>
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    }
  };

  const canSave = () => Boolean(clientFieldName.trim().length && systemFieldName.length);

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {!selectedMapping ? 'Create' : 'Update'} mappings for {client.company}
        </Typography>
        <div className={classes.mainContent}>
          <TextField
            label="Client column name"
            variant="outlined"
            value={clientFieldName}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setClientFieldName(e.target.value)}
          />
          <div className="df col jcc">
            <TrendingFlatIcon />
          </div>
          <div>
            <Autocomplete
              options={systemMappingFields}
              getOptionLabel={(option: string) => option.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}
              onChange={(e: SyntheticEvent, newValue: string | null) =>
                setSystemFieldName(newValue || '')
              }
              value={systemFieldName}
              disabled={!!selectedMapping}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="System column name" variant="outlined" />
              )}
            />
          </div>
        </div>
        <div className={classes.buttonSection}>
          <Button variant="contained" color="inherit" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="contained" disabled={!canSave()} onClick={saveSetting}>
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SetMappingModal;
