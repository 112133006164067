/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import snakeCase from 'lodash/snakeCase';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import SearchSelect from '../../components/ui/SearchSelect';
import StatusButton, { RadioSelections, StatusTypes } from '../../components/ui/StatusButton';
import { downloadFile } from '../../Utils';
import ConditionalComponent from '../../components/ConditionalComponent';
import { useClients } from '../../Hooks/useClients';
import Dropdown from '../../components/ui/Dropdown';

export enum PODNumbers {
  All = 0,
  MyTeam = 1,
  OurTeam = 2,
  HelloTeam = 3,
}
const ClientSearchReportTemplate = ({ url, report, setAlert }) => {
  const searchSelectStyles = {
    width: '230px',
    marginRight: '24px',
  };
  const clients = useClients();

  const PODs = Object.entries(PODNumbers)
    .filter(([key, val]) => typeof val === 'number')
    .map(([key, val]) => ({ label: key, value: val }));
  const [selectedClientId, setSelectedClientId] = useState<number>();
  const [selectedPOD, setSelectedPOD] = useState<PODNumbers | null>(null);
  const [loadingStatus, setLoadingStatus] = useState<StatusTypes>(StatusTypes.initial);
  const [selectedSearchOption, setSelectedSearchOption] = useState<RadioSelections>(
    RadioSelections.Client,
  );

  const getReport = selectedOption => async () => {
    let filename = '';

    if (selectedOption === RadioSelections.ALL) {
      url += '?isAMR=false&clientId=0&podNumber=-1';
      filename = `${selectedOption}_clients_outstandingloans.csv`;
    } else if (selectedOption === RadioSelections.AMR) {
      url += '?isAMR=true&clientId=0&podNumber=-1';
      filename = `${selectedOption}_${snakeCase(report)}.csv`;
    } else if (selectedOption === RadioSelections.Client) {
      url += `?isAMR=false&clientId=${selectedClientId}&podNumber=-1`;
      const client = clients.find(({ id }) => id === selectedClientId);
      filename = `${client?.company}_${snakeCase(report)}.csv`;
    } else if (selectedOption === RadioSelections.POD) {
      url += `?isAMR=false&clientId=0&podNumber=${selectedPOD}`;
      filename = `${selectedOption}_${selectedPOD}_${snakeCase(report)}.csv`;
    }

    try {
      setLoadingStatus(StatusTypes.loading);
      await downloadFile(url, filename);

      setLoadingStatus(StatusTypes.success);
    } catch (e) {
      setLoadingStatus(StatusTypes.error);
      throw new Error(e);
    }
  };
  return (
    <Panel styles={{ margin: 40 }}>
      <PanelHeader largeText text={report} />
      <div css={{ marginTop: 24 }}>
        <RadioGroup
          value={selectedSearchOption}
          onChange={event => {
            setSelectedSearchOption(event.target.value as RadioSelections);
            setLoadingStatus(StatusTypes.initial);
          }}
        >
          <FormControlLabel value={RadioSelections.ALL} control={<Radio />} label="All Clients" />
          <FormControlLabel value={RadioSelections.AMR} control={<Radio />} label="Search AM-R" />
          <FormControlLabel
            value={RadioSelections.Client}
            control={<Radio />}
            label="Search by Client"
          />
          <FormControlLabel value={RadioSelections.POD} control={<Radio />} label="Search by POD" />
        </RadioGroup>
        <ConditionalComponent display={selectedSearchOption === RadioSelections.Client}>
          <SearchSelect
            styleOverrides={searchSelectStyles}
            options={clients.map(client => ({
              label: client.company,
              value: client.id,
            }))}
            placeholder="Search clients"
            {...(selectedClientId &&
              clients.length !== 0 && {
                defaultValue: {
                  label: clients.find(c => c.id === selectedClientId)?.company,
                  value: selectedClientId,
                },
              })}
            onChange={selection => {
              if (selection) setSelectedClientId(selection.value);
              setLoadingStatus(StatusTypes.initial);
            }}
          />
        </ConditionalComponent>

        <ConditionalComponent display={selectedSearchOption === RadioSelections.POD}>
          <Dropdown
            options={PODs}
            placeholder="POD #"
            css={{ width: 200 }}
            onChange={({ value }: { value: PODNumbers }) => {
              if (value !== null) {
                setSelectedPOD(value);
                setLoadingStatus(StatusTypes.initial);
              }
            }}
            value={selectedPOD !== null ? PODs.find(({ value }) => value === selectedPOD) : ''}
          />
        </ConditionalComponent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 32,
          }}
        >
          <StatusButton
            text="Download"
            disabled={
              (!selectedClientId && selectedSearchOption === RadioSelections.Client) ||
              (selectedPOD == null && selectedSearchOption === RadioSelections.POD)
            }
            status={loadingStatus}
            onClick={getReport(selectedSearchOption)}
          />
        </div>
      </div>
    </Panel>
  );
};
export default ClientSearchReportTemplate;
