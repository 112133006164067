/** @jsx jsx */
import { jsx } from '@emotion/core';
import { LinearProgress, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import colors from '../../../styles/colors';
import { Document } from './index';

export const getPaginatedDocuments = (rowsPerPage: number, page: number, documents: Document[]) => {
  return rowsPerPage > 0
    ? documents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : documents;
};

export default function Footer({ state }) {
  const [{ filteredDocuments, loading, selectedIds }, setShipRequests] = state;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const paginatedDocuments = getPaginatedDocuments(rowsPerPage, page, filteredDocuments);
    setShipRequests({ paginatedDocuments });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDocuments, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableFooter
      css={{ position: 'sticky !important', bottom: 0, width: '100%', backgroundColor: '#fafafa' }}
    >
      {filteredDocuments.length > 0 && (
        <TableRow>
          <TableCell colSpan={3}>
            <span css={{ color: colors.blue, fontSize: 14, paddingRight: 16 }}>
              {selectedIds.size} selected{' '}
            </span>
          </TableCell>
          <TablePagination
            rowsPerPageOptions={[50, 100, 150, 200, 250]}
            count={filteredDocuments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      )}
      {loading && (
        <LinearProgress
          // @ts-ignore
          css={{ position: 'absolute !important', width: '100%', bottom: 0 }}
        />
      )}
    </TableFooter>
  );
}
