/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import Panel, { PanelHeader, panelWrap } from '../ui/Panel';
import Tab, { TabGroup } from '../ui/Tabs/Tab';
import TextInput from '../ui/Inputs/TextInput';
import SearchSelect from '../ui/SearchSelect';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import colors from '../../styles/colors';
import Button from '../ui/Button';
import BackButton from './BackButton';
import { apiFetch, apiPost } from '../../adalConfig';
import { AssocationType } from './DeleteAssociation';
import { Client, Loan } from '../../globalTypes/objects';
import UndoAssociationResultPanel from '../UndoAssociationResultPanel';
import { DocumentOldWithShipment } from './types';

const getAssociationId = async (loanNumber: string, clientId: number, type: AssocationType) => {
  const { data: id } = await apiFetch<number>(
    `/api/associations/get${type}AssociationId?loanNumber=${loanNumber}&&clientId=${clientId}`,
  );
  return id;
};

const undoAssociation = async (associationId: number, type: AssocationType) => {
  const { data: results } = await apiPost<Result[]>(`/api/associations/undo${type}Association`, {
    int: associationId,
  });
  return results;
};

const panelsWrap = {
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: 1280,
  padding: 40,
  paddingTop: 24,
};

const tabsWrap = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: '100px',
  padding: '8px',
  display: 'inline-block',
  margin: '24px 0',
};

type UndoAssociationState = {
  loanNumber: string;
  clientId?: number;
  clients: Client[];
  type: AssocationType;
  status: StatusTypes;
  errorMessage: boolean | string;
  results: Result[];
};
type Result = {
  loan: Loan;
  documents: DocumentOldWithShipment[];
  notes: boolean;
};

export default class UndoAssociation extends Component<any, UndoAssociationState> {
  state = {
    loanNumber: '',
    clientId: undefined,
    clients: [] as Client[],
    type: 'Investor' as AssocationType,
    status: StatusTypes.initial,
    errorMessage: false,
    results: [] as Result[],
  };

  async componentDidMount() {
    const { data: clients } = await apiFetch<Client[]>('/api/clients/getAll');
    await this.setState({ clients });
  }

  onUndoClick = async () => {
    this.setState({ status: StatusTypes.loading });
    try {
      const { loanNumber, clientId, type } = this.state;
      const id = await getAssociationId(loanNumber, clientId!, type);
      if (id === 0) {
        this.setState({ errorMessage: true, status: StatusTypes.initial });
        return;
      }
      const results = await undoAssociation(id, type);
      this.setState({ status: StatusTypes.success, results });
    } catch (error) {
      this.setState({ status: StatusTypes.error });
      console.log(error);
    }
  };

  render() {
    const { loanNumber, clients, status, clientId, errorMessage, results } = this.state;
    return (
      <Fragment>
        <BackButton to="/admin">Admin Page</BackButton>
        <div css={panelsWrap}>
          <div css={panelWrap}>
            <Panel>
              <PanelHeader text="Undo Association" />
              <div css={tabsWrap}>
                <TabGroup>
                  <Tab
                    onClick={() =>
                      this.setState({
                        type: 'Investor',
                        status: StatusTypes.initial,
                        errorMessage: false,
                      })
                    }
                  >
                    Investor
                  </Tab>
                  <Tab
                    onClick={() =>
                      this.setState({
                        type: 'Title',
                        status: StatusTypes.initial,
                        errorMessage: false,
                      })
                    }
                  >
                    Title Company
                  </Tab>
                </TabGroup>
              </div>
              <TextInput
                value={loanNumber}
                onChange={e => this.setState({ loanNumber: e.target.value })}
                placeholder="Loan number"
                onClick={() => this.setState({ status: StatusTypes.initial, errorMessage: false })}
              />
              <div
                onClick={() => this.setState({ status: StatusTypes.initial, errorMessage: false })}
                css={{ margin: '24px 0' }}
              >
                <SearchSelect
                  options={clients.map(client => ({
                    label: client.company,
                    value: client.id,
                  }))}
                  placeholder="Search clients"
                  onChange={selection => {
                    this.setState({ clientId: selection?.value, status: StatusTypes.initial });
                  }}
                />
              </div>

              <StatusButton
                status={status}
                text="Undo Association"
                disabled={!clientId || isEmpty(loanNumber) || results.length !== 0}
                onClick={this.onUndoClick}
                styleOverrides={{ width: 230 }}
              />

              {errorMessage && (
                <span css={{ color: colors.red }}>Loan number does not exist for this client</span>
              )}
            </Panel>
            {results.length !== 0 && (
              <Link to={this.props.location.pathname}>
                <Button styleOverrides={{ marginTop: 32 }}>Clear</Button>
              </Link>
            )}
          </div>
          {results.length !== 0 && (
            <Panel>
              <PanelHeader text="Association undone for the following:" />
              {results.map(r => (
                <UndoAssociationResultPanel
                  key={r.loan.loanNumber}
                  loanNumber={r.loan.loanNumber}
                  documents={r.documents}
                  notes={r.notes}
                />
              ))}
            </Panel>
          )}
        </div>
      </Fragment>
    );
  }
}
