/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState, Fragment } from 'react';
import { usePrevious } from '../../../Hooks';
import Panel, { PanelHeader } from '../../ui/Panel';
import NoData from '../../ui/NoData';
import Circle from '../../ui/Circle';
import colors from '../../../styles/colors';
import { apiFetch } from '../../../adalConfig';
import { Client } from '../../../globalTypes/objects';
import { IconType } from '../../ui/Icon';

const gridWrap = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  display: 'grid',
  gridTemplateColumns: '1fr',
  marginTop: 16,
  overflow: 'hidden',
  padding: 8,
  '> div': {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    padding: 16,
    ':first-child': { borderRadius: '4px 4px 0 0' },
    ':last-child': { borderRadius: '0 0 4px 4px' },
    ':nth-child(odd)': {
      backgroundColor: colors.grayExLight,
    },
  },
};
const messageWrap = {
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  borderRadius: 4,
  fontSize: 18,
  marginTop: 16,
  padding: '24px 16px',
  textAlign: 'center',
  color: colors.blue,
  span: { fontWeight: 600 },
};

type Count = {
  month: number;
  year: number;
  unsortedCount: number;
  toSortCount: number;
};

const getMonthDiff = (prevCounts?: Count[], monthlyCounts?: Count[]) => {
  if (!prevCounts || !monthlyCounts) return [];
  return prevCounts.filter(
    prev =>
      !monthlyCounts.some(current => prev.month === current.month && prev.year === current.year),
  );
};

type MonthlyUnsortedCountPanelProps = {
  client?: Client;
  docId?: number;
};
export default function MonthlyUnsortedCountPanel({
  client,
  docId,
}: MonthlyUnsortedCountPanelProps) {
  const [monthlyCounts, setMonthlyCounts] = useState<Count[]>();
  const prevCounts = usePrevious(monthlyCounts);

  useEffect(() => {
    const fetchCounts = async () => {
      const { data: counts } = await apiFetch(
        `/api/documents/getMonthlyUnsortedCounts?clientId=${client?.id}`,
      );
      setMonthlyCounts(counts);
    };
    client && fetchCounts();
  }, [client, docId]);

  const lastSortable = getMonthDiff(prevCounts, monthlyCounts);
  return (
    <Fragment>
      <Panel>
        <PanelHeader
          text={client?.company}
          iconComponent={<Circle icon={IconType.Client} styleoverrides={{ color: colors.blue }} />}
        />
        {monthlyCounts && monthlyCounts.length === 0 && (
          <NoData message={`All documents for ${client?.company} have been sorted`} />
        )}
        {lastSortable.length > 0 && (
          <div
            // @ts-ignore
            css={messageWrap}
          >
            NOTE: All the documents for{' '}
            <span>
              {lastSortable[0].month} {lastSortable[0].year}
            </span>{' '}
            have been sorted
          </div>
        )}
        {monthlyCounts && monthlyCounts.length > 0 && (
          <Fragment>
            <div css={gridWrap}>
              <div css={{ fontSize: 14, fontWeight: 600 }}>
                <div>Total</div>
                <div>To Sort</div>
                <div>Month</div>
                <div>Year</div>
              </div>
              {monthlyCounts.map(c => (
                <div key={c.month + c.year}>
                  <div>{c.unsortedCount}</div>
                  <div>{c.toSortCount}</div>
                  <div>{c.month}</div>
                  <div>{c.year}</div>
                </div>
              ))}
            </div>
          </Fragment>
        )}
      </Panel>
    </Fragment>
  );
}
