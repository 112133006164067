/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { AuditPoint } from '../../../globalTypes/objects';
import { AuditPointsWrap } from './auditStyles';

type FailedAuditDisplayProps = {
  failedReasons: AuditPoint[];
};
export default function FailedAuditDisplay({ failedReasons }: FailedAuditDisplayProps) {
  return (
    <AuditPointsWrap>
      <ul>
        {failedReasons.map(auditPoint => (
          <li key={auditPoint.id}>
            <div>{auditPoint.description}</div>
            {auditPoint.notesRequired && auditPoint.notes && (
              <div>
                <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- </b>
                <span>{auditPoint.notes}</span>
              </div>
            )}
            {auditPoint.options.map(option => (
              <div key={option.id}>
                <b>&nbsp;&nbsp;~ </b>
                <span>{option.optionDescription}</span>
                {option.notesRequired && option.notes && (
                  <div>
                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- </b>
                    <span>{option.notes}</span>
                  </div>
                )}
              </div>
            ))}
          </li>
        ))}
      </ul>
    </AuditPointsWrap>
  );
}
