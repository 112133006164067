/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useClients } from '../../Hooks/useClients';
import { useGetData } from '../../Hooks';
import { Client } from '../../globalTypes/objects';
import { DocumentTypesContext } from '../../DocumentTypesContext';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material/Warning';
import { orange } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import DocumentTypeOverrideSettings from './DocumentTypeOverrideSettings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topSection: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      maxWidth: '750px',
    },
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '84px',
      height: '39px',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: '610px',
    },
    warning: {
      color: orange[500],
      borderColor: '#ffeeba',
    },
  }),
);

const initialSettings = {
  client: null,
  investor: null,
  allClients: false,
  shouldShipDigitally: true,
  allowGinnieMaeOverride: true,
  documentTypeOverride: false,
  documentTypeSettings: [],
  investorSettings: [],
  isSubmitting: false,
  isImportSettingsDialogOpen: false,
  isSettingsOverwriteDialogOpen: false,
};

const DigitalDeliverySettings = () => {
  const { data: investors } = useGetData<Investor[]>('/api/investors/dropdown-data', []);
  const clients = useClients();
  const { docTypes: documentTypes } = useContext(DocumentTypesContext);

  const [investor, setInvestor] = useState<Investor | null>(initialSettings.investor);
  const [client, setClient] = useState<Client | null>(initialSettings.client);
  const [allClients, setAllClients] = useState(initialSettings.allClients);
  const [shouldShipDigitally, setShouldShipDigitally] = useState(
    initialSettings.shouldShipDigitally,
  );
  const [allowGinnieMaeOverride, setAllowGinnieMaeOverride] = useState(
    initialSettings.allowGinnieMaeOverride,
  );
  const [documentTypeOverride, setDocumentTypeOverride] = useState(
    initialSettings.documentTypeOverride,
  );
  const [documentTypeSettings, setDocumentTypeSettings] = useState<DocumentTypeSetting[]>(
    initialSettings.documentTypeSettings,
  );
  const [investorSettings, setInvestorSettings] = useState<FullSettings[]>(
    initialSettings.investorSettings,
  );
  const [isSubmitting, setIsSubmitting] = useState(initialSettings.isSubmitting);
  const [isImportSettingsDialogOpen, setIsImportSettingsDialogOpen] = useState(
    initialSettings.isImportSettingsDialogOpen,
  );
  const [isSettingsOverwriteDialogOpen, setIsSettingsOverwriteDialogOpen] = useState(
    initialSettings.isSettingsOverwriteDialogOpen,
  );

  const findCurrentSettings = (investorSettings: FullSettings[]) =>
    investorSettings.find(d =>
      client?.id
        ? d.digitalDeliverySetting.clientId === client?.id
        : d.digitalDeliverySetting.clientId === null,
    );

  const originalSettings = useMemo(() => findCurrentSettings(investorSettings), [investorSettings]);

  const classes = useStyles();

  const { successToaster, errorToaster, warningToaster } = useToaster();

  useEffect(() => {
    if (!investor) {
      return;
    }

    if (!client && !allClients) {
      return;
    }

    checkIfSettingsExist(investor.id).then(data => {
      setInvestorSettings(data);
      const settings = findCurrentSettings(data);

      if (settings !== undefined) {
        setIsImportSettingsDialogOpen(true);
      }
    });
  }, [client, investor, allClients]);

  const checkIfSettingsExist = async (investorId: number): Promise<FullSettings[]> => {
    try {
      const { data } = await apiFetch<FullSettings[]>(
        '/api/digital-delivery-settings/existing-settings',
        {
          params: { investorId },
        },
      );

      return data;
    } catch (e) {
      let errorMessage = e.message;
      if (e.response) {
        errorMessage = e.response.data.split('\n')[0] || e.message;
      }

      errorToaster(errorMessage);

      return [];
    }
  };

  useEffect(() => {
    if (!documentTypes.length || documentTypeSettings.length > 0) {
      return;
    }

    const initialDocumentTypeSettingsState = documentTypes.map<DocumentTypeSetting>(
      documentType => ({
        documentTypeId: documentType.value,
        documentTypeLabel: documentType.label,
        shouldShipDigitally: false,
        onlyDigital: false,
      }),
    );

    setDocumentTypeSettings(initialDocumentTypeSettingsState);
  }, [documentTypes, setDocumentTypeSettings, documentTypeSettings]);

  useEffect(() => {
    if (!shouldShipDigitally) {
      setAllowGinnieMaeOverride(false);
    }
  }, [shouldShipDigitally]);

  const updateDocumentTypeSetting = (newState: DocumentTypeSetting) => {
    const newSettings = documentTypeSettings.map<DocumentTypeSetting>(setting =>
      setting.documentTypeId === newState.documentTypeId ? newState : setting,
    );

    setDocumentTypeSettings(newSettings);
  };

  const trySubmit = async () => {
    if (!client && !allClients) {
      warningToaster('Please select a client');
      return;
    }

    if (!investor) {
      warningToaster('Please select an investor');
      return;
    }

    const potentialLossOfSettings =
      allClients && investorSettings.some(s => s.digitalDeliverySetting.clientId !== null);
    if (potentialLossOfSettings) {
      setIsSettingsOverwriteDialogOpen(true);
      return;
    }

    await submit(false);
  };

  const submit = async (isRemovingClientSettings: boolean) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    const postData: DigitalDeliverySettingsPostData = {
      digitalDeliverySetting: {
        clientId: client?.id,
        investorId: investor!.id,
        shouldShipDigitally,
        allowGinnieMaeOverride,
        disposalMethodId: 1,
      },
      documentTypeSettings: documentTypeOverride
        ? documentTypeSettings.map(setting => ({
            documentTypeId: setting.documentTypeId,
            shouldShipDigitally: setting.shouldShipDigitally,
            onlyDigital: setting.onlyDigital,
          }))
        : [],
      isRemovingClientSettings,
    };

    try {
      await apiPost('/api/digital-delivery-settings/create-or-update', postData);
      resetPageSettings();

      successToaster('Settings created');
    } catch (e) {
      const response = e.response?.data ?? e.message;
      const errorMessage =
        typeof response === 'string' ? response.split('\n')[0] : response.errors[0]?.message;
      errorToaster(errorMessage);

      setIsSubmitting(false);
    }
  };

  const resetPageSettings = () => {
    setClient(initialSettings.client);
    setInvestor(initialSettings.investor);
    setAllClients(initialSettings.allClients);
    setShouldShipDigitally(initialSettings.shouldShipDigitally);
    setAllowGinnieMaeOverride(initialSettings.allowGinnieMaeOverride);
    setDocumentTypeOverride(initialSettings.documentTypeOverride);
    setDocumentTypeSettings(initialSettings.documentTypeSettings);
    setInvestorSettings(initialSettings.investorSettings);
    setIsSubmitting(initialSettings.isSubmitting);
    setIsImportSettingsDialogOpen(initialSettings.isImportSettingsDialogOpen);
    setIsSettingsOverwriteDialogOpen(initialSettings.isSettingsOverwriteDialogOpen);
  };

  const UpdateSettingsInUI = (settings: FullSettings) => {
    setShouldShipDigitally(settings.digitalDeliverySetting.shouldShipDigitally);
    setAllowGinnieMaeOverride(settings.digitalDeliverySetting.allowGinnieMaeOverride);

    if (!settings.documentTypeSettings) {
      setDocumentTypeOverride(false);
      return;
    }

    setDocumentTypeOverride(true);

    const documentTypeSettingsMap = settings.documentTypeSettings.reduce((map, current) => {
      map.set(current.documentTypeId, current);

      return map;
    }, new Map<number, DocumentTypeSetting>());

    const newSettings = documentTypeSettings.map<DocumentTypeSetting>(setting => {
      const currentMappedSetting = documentTypeSettingsMap.get(setting.documentTypeId);

      return {
        documentTypeId: setting.documentTypeId,
        documentTypeLabel: setting.documentTypeLabel,
        shouldShipDigitally:
          currentMappedSetting?.shouldShipDigitally ?? setting.shouldShipDigitally,
        onlyDigital: currentMappedSetting?.onlyDigital ?? setting.onlyDigital,
      };
    });

    setDocumentTypeSettings(newSettings);
  };

  return (
    <Fragment>
      <div className="m2">
        <div className={classes.topSection}>
          <div>
            <Autocomplete
              options={investors}
              getOptionLabel={(option: Investor) => option?.label || ''}
              value={investor}
              onChange={(event: any, newValue: Investor | null) => setInvestor(newValue)}
              style={{ width: 300 }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Choose an investor" variant="outlined" />
              )}
            />
          </div>
          <div>
            <Autocomplete
              options={clients}
              getOptionLabel={(option: Client) => option?.company || ''}
              value={client}
              onChange={(event: any, newValue: Client | null) => setClient(newValue)}
              style={{ width: 300 }}
              autoHighlight
              disabled={allClients}
              renderInput={params => (
                <TextField {...params} label="Choose a client" variant="outlined" />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allClients}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setClient(null);
                    setAllClients(e.target.checked);
                  }}
                  color="primary"
                />
              }
              label="All Clients"
            />
          </div>
        </div>

        <div style={{ display: 'flex', gap: '10px' }}>
          <div style={{ width: '300px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldShipDigitally}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setShouldShipDigitally(e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Ship Digitally (Master Setting)"
            />
          </div>
          <div style={{ width: '300px' }}>
            {shouldShipDigitally && (
              <Tooltip title="Overrides the standard rule to physically deliver documents for Ginnie Mae loans">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowGinnieMaeOverride}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setAllowGinnieMaeOverride(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label="Ginnie Mae Override"
                />
              </Tooltip>
            )}
          </div>

          <div>
            {isSubmitting ? (
              <div className={`${classes.loaderButton} center-in-parent`}>
                <CircularProgress size="20" disableShrink />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || (!client && !allClients) || !investor}
                onClick={trySubmit}
              >
                Submit
              </Button>
            )}
          </div>
        </div>

        <Divider className={classes.divider} />

        <div style={{ display: 'flex' }}>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={documentTypeOverride}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDocumentTypeOverride(e.target.checked)
                  }
                  color="primary"
                />
              }
              label="Document Type Override"
            />
          </div>

          {documentTypeOverride && (
            <DocumentTypeOverrideSettings
              documentTypeSettings={documentTypeSettings}
              updateDocumentTypeSetting={updateDocumentTypeSetting}
            />
          )}
        </div>
      </div>

      <Dialog
        onClose={() => setIsImportSettingsDialogOpen(false)}
        aria-labelledby="import-settings-title"
        open={isImportSettingsDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="import-settings-title">
          <div>Lender: {client?.company}</div>
          <div>Investor: {investor?.label}</div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="import-settings-dialog-description">
            <span className="db">Settings were already assigned for this client and investor</span>
            <span className="db">Would you like to auto-populate the current settings?</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsImportSettingsDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              UpdateSettingsInUI(originalSettings!);

              setIsImportSettingsDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setIsSettingsOverwriteDialogOpen(false)}
        aria-labelledby="settings-overwrite-title"
        open={isSettingsOverwriteDialogOpen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="settings-overwrite-title">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <WarningIcon className={classes.warning} />
            <div className="px1">Warning</div>
            <WarningIcon className={classes.warning} />
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="settings-overwrite-dialog-description">
            <span className="db">Settings already exist for this investor on the client level</span>
            <span className="db">
              Click discard to override all client specific settings with the master investor
              setting
            </span>
            <span className="db">Click keep to maintain an exception for this/these client(s)</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={async () => {
              await submit(true);
              setIsImportSettingsDialogOpen(false);
            }}
          >
            Discard
          </Button>
          <Button
            onClick={async () => {
              await submit(false);
              setIsImportSettingsDialogOpen(false);
            }}
            color="primary"
          >
            Keep
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DigitalDeliverySettings;
