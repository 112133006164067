/** @jsx jsx */
import { jsx } from '@emotion/core';
import Autocomplete from '@mui/material/Autocomplete';
import { Client, Payment } from '../../../globalTypes/objects';
import TextField from '@mui/material/TextField';
import { useClients } from '../../../Hooks/useClients';
import { Fragment, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { apiPost } from '../../../adalConfig';
import { useToaster } from '../../../Hooks/toasters';
import { parseRequestError } from '../../../Utils';
import subYears from 'date-fns/subYears';
import addMonths from 'date-fns/addMonths';
import isWithinInterval from 'date-fns/isWithinInterval';

const minDate = subYears(new Date(), 1);
const maxDate = addMonths(new Date(), 1);
const initialPaymentData = {
  checkNo: '',
  clientId: 0,
  datePaid: new Date(),
  id: 0,
  paymentAmt: 0,
} as Payment;

const AddPayment = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [payment, setPayment] = useState<Payment>(initialPaymentData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { successToaster, errorToaster } = useToaster();

  const canSubmit = useMemo(() => {
    if (!selectedClient) {
      return false;
    }

    const { paymentAmt, datePaid, checkNo } = payment;
    return (
      paymentAmt > 0 &&
      isWithinInterval(datePaid, {
        start: minDate,
        end: maxDate,
      }) &&
      checkNo.trim().length > 0
    );
  }, [selectedClient, payment]);

  const submit = async () => {
    if (!canSubmit) {
      return false;
    }

    setIsSubmitting(true);
    try {
      await apiPost('/api/charge/add-payment', {
        checkNo: payment.checkNo,
        clientId: selectedClient?.id,
        datePaid: payment.datePaid.toLocaleDateString(),
        paymentAmt: payment.paymentAmt,
      });

      successToaster('Payment submitted successfully');

      setSelectedClient(null);
      setPayment(initialPaymentData);
    } catch (e) {
      const firstError = parseRequestError(e)[0];
      errorToaster(firstError);
    } finally {
      setIsSubmitting(false);
    }
  };

  const clients = useClients();

  return (
    <Fragment>
      <div className="my4">
        <Autocomplete
          options={clients}
          getOptionLabel={(option: Client) => option?.company || ''}
          onChange={(event: any, newValue: Client | null) => setSelectedClient(newValue)}
          value={selectedClient}
          style={{ width: 300 }}
          autoHighlight
          renderInput={params => (
            <TextField {...params} label="Choose a client" variant="outlined" />
          )}
        />
      </div>
      <div className="w-three-quarters">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '32px',
          }}
        >
          <div>
            <TextField
              label="Amount"
              variant="outlined"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: '100%' }}
              value={payment.paymentAmt > 0 ? payment.paymentAmt : ''}
              onChange={e => {
                const numericValue = e.target.value.replace(/[^\d.]/g, '');
                if (numericValue === '') {
                  setPayment(x => ({ ...x, paymentAmt: 0 }));
                  return;
                }

                const limitDecimal = Math.round(parseFloat(numericValue) * 100) / 100;
                setPayment(x => ({ ...x, paymentAmt: limitDecimal }));
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <Typography variant="subtitle2" gutterBottom width="75px">
              Date paid
            </Typography>
            <DatePicker
              wrapperClassName="full-width"
              customInput={<TextField style={{ width: '100%' }} />}
              selected={payment.datePaid}
              onChange={value => value && setPayment(x => ({ ...x, datePaid: value }))}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <div>
            <TextField
              placeholder="Check number"
              variant="outlined"
              style={{ width: '100%' }}
              onChange={e => setPayment(x => ({ ...x, checkNo: e.target.value ?? '' }))}
              value={payment.checkNo}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '32px' }}>
          <LoadingButton
            color="primary"
            loading={isSubmitting}
            variant="contained"
            disabled={!canSubmit}
            onClick={submit}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </Fragment>
  );
};

export default AddPayment;
