/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { NoteData } from './Loans/types';
import AddNoteInput from './Notes/AddNoteInput';
import PastNotesDisplay from './Notes/PastNotesDisplay';

type Props = {
  addNote?: (note: string) => void;
  notes: NoteData[];
  noteType?: string;
  canEditAndDelete?: boolean;
};

const Notes = ({ addNote, notes, noteType, canEditAndDelete = false }: Props) => {
  return (
    <Fragment>
      <h2>{noteType && `${noteType} `}Notes</h2>
      {addNote && <AddNoteInput addNote={addNote} />}
      <PastNotesDisplay notes={notes} canEditAndDelete={canEditAndDelete} />
    </Fragment>
  );
};

export default Notes;
