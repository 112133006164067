import { CSSObject } from '@emotion/core';

export const cardWrap: CSSObject = {
  backgroundColor: '#fff',
  borderRadius: '13.6px',
  boxShadow: '42px 42px 66px -35px rgba(0, 0, 0, 0.13)',
  // boxShadow: '104px 103px 204px -60px rgba(0, 0, 0, 0.08)',
  display: 'inline-block',
  marginRight: 40,
  maxWidth: 320,
  padding: '47px 52px',
  verticalAlign: 'top',
  width: '100%',
};

export const topPart: CSSObject = {
  color: '#e5e5e3',
  display: 'inline-block',
  fontSize: 49,
  fontWeight: 'bold',
  letterSpacing: -1.01,
  lineHeight: 0.85,
  verticalAlign: 'top',
  width: '100%',
};

export const todayWrap = {
  display: 'inline-block',
  width: '100%',
  verticalAlign: 'top',
};

export const today: CSSObject = {
  fontWeight: 'bold',
  letterSpacing: -0.08,
  color: '#6dd400',
  fontSize: 40,
  marginTop: 40,
  lineHeight: 1,
};

export const number: CSSObject = {
  fontSize: 55.2,
  fontWeight: 'bold',
  letterSpacing: -1.71,
};

export const greenWords: CSSObject = {
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: 0.8,
  color: '#6dd400',
  paddingTop: 8,
  marginRight: 8,
};

export const blueWords: CSSObject = {
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: 0.8,
  color: '#0091ff',
  paddingTop: 8,
  marginRight: 8,
};

export const thirdPart: CSSObject = {
  borderTop: '0.8px solid #e5e5e3',
  display: 'flex',
  marginTop: 20,
  verticalAlign: 'top',
  width: '100%',
  paddingTop: 28,
  '> div': {
    width: '50%',
  },
};

export const week: CSSObject = {
  fontSize: 28,
  fontWeight: 'bold',
  letterSpacing: -0.08,
  color: '#f7b500',
  paddingBottom: 24,
  marginTop: -8,
};

export const month: CSSObject = {
  fontSize: 16,
  fontWeight: 'bold',
  letterSpacing: -0.12,
  color: '#f7b500',
};

export const circleStyles: CSSObject = {
  position: 'relative',
  '&:before': {
    border: '6px solid #e5e5e3',
    borderRadius: '50%',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: '-1',
  },
};

export const circleWrap: CSSObject = {
  position: 'relative',
  display: 'inline-block',
  marginRight: 32,
  '.MuiCircularProgress-circleStatic': {
    strokeLinecap: 'round',
  },
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    border: '6px solid #e5e5e3',
    borderRadius: '50%',
  },
};

export const titleStyle: CSSObject = {
  color: '#555',
  fontSize: 70,
  fontWeight: 'bold',
  lineHeight: '0.8em',
};

export const subTitleStyle: CSSObject = {
  fontSize: 16,
  fontWeight: 'bold',
  color: '#6dd400',
  marginRight: 8,
};

export const dataPointStyle: CSSObject = {
  fontSize: 20,
  fontWeight: 'bold',
};
