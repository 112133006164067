import { File, TabName } from './types';

export const listWrap = {
  maxWidth: 500,
  width: '100%',
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: 40,
  marginBottom: 40,
  border: '1px solid #b1b1b1',
  borderRadius: 6,
  '.MuiList-padding': {
    padding: 0,
  },
  '.MuiTypography-root': {
    fontWeight: 'bold',
  },
  h3: {
    padding: 16,
    fontSize: 18,
    fontWeight: 'bold',
    borderBottom: '1px solid #b1b1b1',
  },
};

export const filesArray: File[] = [
  // GENERAL //
  {
    type: 'pdf',
    title: 'Onboarding Presentation',
    src: 'onboarding_presentation_ggebkx.pdf',
    tabs: [TabName.General],
  },
  {
    type: 'pdf',
    title: 'Org Chart',
    src: 'Org_Chart_-_DP_Onboarding_zvui1f.pdf',
    tabs: [TabName.General],
  },
  {
    type: 'pdf',
    title: 'Process',
    src: 'The_Process_-_DP_Onboarding_im40cl.pdf',
    tabs: [TabName.General],
  },
  {
    type: 'video',
    title: 'DocProbe Tour 10.19.20',
    src: 'IMG_5336_oqxn32.mp4',
    tabs: [TabName.General],
  },
  {
    type: 'pdf',
    title: 'How to Access Lender Inboxes',
    src: 'access_lender_inboxes_final_ga5n7p',
    tabs: [TabName.General, TabName.Contact],
  },
  {
    type: 'pdf',
    title: 'Flagged Docs Protocol',
    src: 'Flagged_Docs_Protocol_zbpzeg',
    tabs: [TabName.General],
    downloadable: true,
  },
  {
    type: 'pdf',
    title: 'DocProbe Directory',
    src: 'DP_directory_leqlec',
    tabs: [TabName.General],
  },

  // CONTACT CENTER  //
  {
    type: 'pdf',
    src: 'CC_Quick_Reference_Deck_Knowledge_Base_vascfr',
    title: 'Quick Reference',
    tabs: [TabName.Contact],
  },
  {
    type: 'pdf',
    title: 'Script',
    src: 'script_revisions_Oct_30_2020_vckphh.pdf',
    tabs: [TabName.Contact],
  },
  {
    type: 'pdf',
    src: 'Terminology_Card_KB_rq8rvu',
    title: 'Terminology',
    tabs: [TabName.Contact],
  },
  {
    type: 'pdf',
    title: 'Sample Follow Up Email',
    src: 'Outstanding_Doc_Email_qbbehp.pdf',
    tabs: [TabName.Contact],
  },
  {
    type: 'pdf',
    title: 'Sample Authorization Letter',
    src: 'Sample_-_Authorization_Letter_gdydjw.pdf',
    tabs: [TabName.Contact],
  },
  {
    type: 'xls',
    title: 'Sample Distribution List',
    src: 'Sample_-_Distribution_list_tp70e8.xlsx',
    tabs: [TabName.Contact],
  },
  {
    type: 'video',
    title: 'Overview of Contact Center Page',
    src: 'CC_Page_overview_hnran4',
    tabs: [TabName.Contact],
  },
  {
    type: 'video',
    title: 'Demo 1 with Instruction',
    src: 'CC_Demo_with_Instruction_s1ou9f.mp4',
    tabs: [TabName.Contact],
  },
  {
    type: 'video',
    title: 'Demo 2 without Instruction',
    src: 'CC_video_demo_CTaus_xyfejx.mp4',
    tabs: [TabName.Contact],
  },
  {
    type: 'video',
    title: 'Invalid Emails',
    src: 'Invalid_Emails_epxv4o.mp4',
    tabs: [TabName.Contact],
  },
  {
    type: 'pdf',
    title: 'Email Template for High Volume of Loans',
    src: 'Spreadsheet_required_email_template_-_approved_002_vgnkjc',
    tabs: [TabName.Contact],
    downloadable: true,
  },
  {
    type: 'pdf',
    title: 'Bulk Note Upload Procedure',
    src: 'Bulk_Note_Upload_Procedure_venmum',
    tabs: [TabName.Contact, TabName.AccountManagers],
  },
  {
    type: 'xls',
    title: 'Sample Bulk Note Upload Report',
    src: 'Sample_Bulk_Note_Upload_Report_-_Required_Columns_nq4xkv.xlsx',
    tabs: [TabName.Contact, TabName.AccountManagers],
  },

  // DIGITALS/MATCHING //
  {
    src: 'DocumentType_Lookup_adltns.pdf',
    title: 'Document Type Lookup',
    type: 'pdf',
    tabs: [TabName.Digitals],
  },
  {
    src: 'DocProbe_Audit_Checklist_yorcm1.pdf',
    title: 'DocProbe Audit Checklist',
    type: 'pdf',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'Mortgage_determination_kc1hqq',
    title: 'Mortgage Audit',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'policy_match_Ver_my6lpl',
    title: 'Policy Match',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'Failed_Audit_fugkr2',
    title: 'Failed Audit',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'Long_form_policy_Det_junj73',
    title: 'Long Form Policy',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'Short_form_det_ammgvt',
    title: 'Short Form',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'mortgage_Match_ver_olhyme',
    title: 'Mortgage Match',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'deleting_standalone_1_h4npvk',
    title: 'Deleting Standalone',
    tabs: [TabName.Digitals],
  },
  {
    type: 'video',
    src: 'Policies_-_Doc_DET_sjewhm',
    title: 'Policies Document',
    tabs: [TabName.Digitals],
  },

  // ACCOUNT MANAGERS //
  {
    type: 'pdf',
    src: 'Client_Engagement_cgznis',
    title: 'Client Engagement',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Account_Representative_-_Role_and_Responsibilities_td02yl',
    title: 'Account Manager - Role and Responsibilities',
    tabs: [TabName.AccountManagers],
  },
  {
    src: 'Purchased_loans_Template_obsfjs.xls',
    title: 'Purchased Loans Template',
    type: 'xls',
    tabs: [TabName.AccountManagers],
  },
  {
    src: 'Investor_tool_Template_myp42o.xlsx',
    title: 'Investor Tool Template',
    type: 'xls',
    tabs: [TabName.AccountManagers],
  },
  {
    src: 'old_loans_template_nhhedl.xls',
    title: 'Old Loans template',
    type: 'xls',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'additional_email_xlnas1',
    title: 'Additional Email',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'loan_send_to_global_and_delete_loan_email_vecwp7',
    title: 'Loan send to global and delete loan',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'title_send_to_global_bs9d7m',
    title: 'Title send to global',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Client_Portal_htoujo',
    title: 'DocProbe Portal',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Encompass_qokedu',
    title: 'Encompass',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Preparing_a_Status_Report_-_Investor_Tool_Guide_1_qsquot',
    title: 'Preparing a Status Report - Investor Tool Guide',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Account_Representative_Training_lyaqao',
    title: 'Account Manager Training',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'zoom_0_qwseq1',
    title: 'How to Change a Doc Type',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'FlaggedEmails1pdf_ljhylc',
    title: 'Resolving Flagged Emails in Lender Inboxes',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Correction_Pipeline_Portal_il5ek7',
    title: 'Correction Pipeline Portal',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Document_Search_Procedures_lxpgfy',
    title: 'Document Search Procedures for Outstanding Documents',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Adding_Zeros_To_Loan_Numbers_gp5cer',
    title: 'Adding Zeros to Loan Numbers',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Curing_Terms_zzvqee',
    title: 'Curing Terms',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Curing_Docs_Remotely_procedure_1_j4wrmm',
    title: 'Curing Docs Remotely Procedure',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'Pivot_Clip_ihiwvu',
    title: 'How to Pivot a Report',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'final_binder_wj0vc9',
    title: 'CST Protocol',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'video',
    src: 'kensie_mae_tutorial_account_reps',
    title: 'KM Tool Demo',
    tabs: [TabName.AccountManagers],
  },
  {
    type: 'pdf',
    src: 'Sold_Loan_Report_Submission_Protocol_ehbmiw',
    title: 'Sold Loan Report Submission Protocol',
    tabs: [TabName.AccountManagers],
  },

  // /////// AUTHORIZATION LETTERS /////////////////////////
  {
    type: 'pdf',
    src: 'NRL_Auth_qzdk7q',
    title: 'NRL Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Compass_Letter_-2_tgebgw',
    title: 'Compass Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'AmCap_Ltter_hhp8nh',
    title: 'AmCap Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Axia_Auth_Letter_nnnbtz',
    title: 'Axia Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Cornerstone_Letter_xuk6bo',
    title: 'Cornerstone Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'CBM_Mortgage_p0ucte',
    title: 'CBM Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Apex_Letter_qyybv1',
    title: 'Apex Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'C_F_Letter_fhdhrh',
    title: 'C & F Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Atlantic_Home_Loans_Authorization_Form_kdnptd',
    title: 'Atlantic Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Congressional_Letter_tem2ba',
    title: 'Congressional Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Capitall_Bank_Authorization_o0sqyn',
    title: 'Capital Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Gershman_Letter_xh6zkg',
    title: 'Gershman Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Foothills_Authorization_Letter_kqqmof',
    title: 'Foothills Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'HomeVantage_Authorization_mfvdua',
    title: 'Home Vantage Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'FM_Home_Loans_Authorization_inax2e',
    title: 'FM Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Hamilton_Group_noicer',
    title: 'Hamilton Group Funding',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Homewise_Authorization_Letter_ziu6ga',
    title: 'Homewise',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Iserve_Letter_aqvp5n',
    title: 'IServe Residential Lending',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'ks_state_bank_Authorization_Letter_lnitis',
    title: 'KS State Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Homeowners_Authorization_Letter_tm7tcj',
    title: 'Homeowners Financial Group',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Lux_letter_eew2p9',
    title: 'Luxury Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'KWIK_letter_tccyfs',
    title: 'KWIK Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Peoples_United_izcq6d',
    title: "People's United Bank",
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'MLB_Residential_Lending_jnf2np',
    title: 'MLB Residential Lending',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'ntfn_vy3zwy',
    title: 'NTFN - Premier Nationwide Lending',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Victorian_Letter_hl2lay',
    title: 'Victorian Finance',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Nationwide_letter_tmpenv',
    title: 'Nationwide Equities',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'open_mortgage_authorization_letter_tmrcyz',
    title: 'Open Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'republic_state_letter_a5xajm',
    title: 'Lima One Capital',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Parkside_Letter_mulqjv',
    title: 'Parkside Lending',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'PFN_Authorization_ytas7g',
    title: 'Priority Financial Network',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Southwest_Letter_c9zkkj',
    title: 'Southwest Funding',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Watson_Letter_b6p3an',
    title: 'Watson Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Triumph_Mortgage_fv7yix',
    title: 'Triumph Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Premium_Letter_p1z3sh',
    title: 'Premium Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'RWM_m0fwlc',
    title: 'RWM Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Sente_Letter_x7imgl',
    title: 'Sente Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Trinity_hdr6p3',
    title: 'Trinity Oaks Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'FirstBank_s2nloy',
    title: 'First Bank Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Firstrust_Bank_f7higk',
    title: 'First Trust Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Ameris_Bank_Mortgage_o5wri9',
    title: 'Ameris Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Ivy_Mortgage_Inc_nkpb7a',
    title: 'Ivy Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Mason_McDuffie_htog11',
    title: 'Mason McDuffie Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Nutter_Home_Loans_r2hmi3',
    title: 'Nutter Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Holland_Mortgage_Advisors_encbvv',
    title: 'Holland Mortgage Advisors',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Home_Access_Financial_pzhghm',
    title: 'Home Access Financial',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'CapstarBank_jyyrke',
    title: 'Capstar Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'NP_Inc_rvv07h',
    title: 'NP, Inc.',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Panorama_Authorizartion_letter_wwc5za',
    title: 'Panorama Mortgage Group',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'DocProbe_Authorization_Letter_q5yjxc',
    title: 'Homespire Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'BOA_Authorization_Letter_xwjlod',
    title: 'Bank of England',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Eustis_Authorization_Letter_mgucse',
    title: 'Eustis Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'SunWest_Auth_g0hc3l',
    title: 'Sun West Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'united_mortgage_corp',
    title: 'United Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'AuthorizationLetter_bz7v4m',
    title: 'Key Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Cornerstone_Home_Lending_Letter_-_CHL_fckdxa',
    title: 'Cornerstone Home Lending - CHL',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'LoanPeople_Authorization_xrjem8',
    title: 'Loan People',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Republic_State_Mortgage_Co_Authorization_Lettered_n2buke',
    title: 'Republic State Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Pike_Creek_Authorization_ypedvm',
    title: 'Pike Creek Mortgage Services',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Directions_Authorization_Letter_ekayux',
    title: 'Directions Home Loan',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Mortgage300_Authorization_Letter_sfeahb',
    title: 'Mortgage 300',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'letter_of_authorization_from_revolution_mortgage',
    title: 'Revolution Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'DocProbe_Auth_Letter_vatgvi',
    title: 'Sun American Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Geneva_Authorization_Letter_viewam',
    title: 'Geneva Financial',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Letter_of_authoriation_wycuzy',
    title: 'First Home Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'APM_Authorization_Letter_ud28cw',
    title: 'American Pacific',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'DOC061721-06172021141118_nxfnng',
    title: 'Old Second',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Angel_Oak_Letter',
    title: 'Angel Oak',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'First_Bank_Home_Loans_hqdncb',
    title: 'First Bank Home Loans',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Essex_Mortgage_b6xpdz',
    title: 'Essex Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Family_First_Funding_qzwxpz',
    title: 'Family First Funding',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Synergy_1_d2pbwz',
    title: 'Synergy One Lending',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Mid_America_Mortgage_t1grwg',
    title: 'Mid America Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'SnapFi_auth_letter',
    title: 'SnapFi',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'McLean_Letter_yjkknf',
    title: 'McLean Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'One_American_Bank_New_menebt',
    title: 'One American Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Authorization_letter_Legacy_yhzur0',
    title: 'Legacy Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'BartonCreekAuthorizationLetter_eoop6u',
    title: 'Barton Creek Lending Group',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Armed_Forces_Authorization_Letter_cxypiv',
    title: 'Armed Forces Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: '1st_Security_Bank_-_signed_authorization_letter_e81rdd',
    title: '1st Security Bank',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Mutual_of_Omaha_fefvuv',
    title: 'Mutual of Omaha Mortgage',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'First_Equity_Auth_Letter_s3blwm',
    title: 'First Equity',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Provident_Authorization_Letter_-_PLG_kpu0hj',
    title: 'Provident',
    tabs: [TabName.Letters],
  },
  {
    type: 'pdf',
    src: 'Intercoastal_srt9er',
    title: 'Intercoastal Mortgage',
    tabs: [TabName.Letters],
  },

  // /////// PHYSICAL PROCESSING /////////////////////////
  {
    type: 'pdf',
    src: 'Pulling_Docs_from_Pipeline_Protocol_rzx3ou',
    title: 'Pulling Docs from the Pipeline Protocol',
    tabs: [TabName.Physicals],
  },
  {
    type: 'pdf',
    src: 'Processing_Flagged_Documents_Procedure_mcwont',
    title: 'Processing Flagged Documents',
    tabs: [TabName.Physicals],
  },

  // /////// PODS /////////////////////////
  {
    type: 'pdf',
    src: 'CSM_xvhsuc',
    title: 'CSM',
    tabs: [TabName.Pods],
  },
  {
    type: 'pdf',
    src: 'Investor_management_2_agoi2c',
    title: 'Investor Management',
    tabs: [TabName.Pods],
  },
  {
    type: 'pdf',
    src: 'Corrections_Management_v10wds',
    title: 'Corrections Management',
    tabs: [TabName.Pods],
  },
  {
    type: 'pdf',
    src: 'Outreach_Management2_srripn',
    title: 'Outreach Management',
    tabs: [TabName.Pods],
  },
];
