/** @jsx jsx */
import { jsx } from '@emotion/core';
import makeStyles from '@mui/styles/makeStyles';
import Icon, { IconType } from '../../../ui/Icon';
import { AlertType } from '../SortDocumentPanel';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    textAlign: 'left',
    padding: '24px',
    borderBottom: 'solid 1px #e6e4e8',
    backgroundColor: '#fffeff',
    justifyContent: 'space-between',
  },
  iconContainer: {
    width: '70px',
  },
  messageTextContainer: {
    minWidth: '140px',
    maxWidth: '550px',
    height: '37px',
    marginLeft: '18px',
    fontFamily: 'Roboto, Helvetica, sans-serif',
  },
  primaryMessageText: {
    fontSize: '27px',
    lineHeight: 'normal',
    textAlign: 'left',
    textTransform: 'uppercase',
  },
  secondaryMessageText: {
    margin: '5px 0px',
    opacity: '0.6',
    fontSize: '16px',
    lineHeight: 'normal',
  },
  missingClientContainer: {
    maxWidth: '421px',
    minHeight: '75px',
    padding: '5px 10px 5px 11px',
    borderRadius: '5px',
    backgroundColor: 'rgba(238, 77, 62, .1)',
  },
  missingClientText: {
    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontSize: '29px',
    fontWeight: 'bold',
    lineHeight: 'normal',
    textAlign: 'left',
    color: 'rgb(238, 77, 62)',
  },
});

type Props = {
  alertType: AlertType;
  primaryMessage: string;
  secondaryMessage: string;
  isWrongClient: boolean;
};

const getIconType = (alertType: AlertType) => {
  switch (alertType) {
    case 'PRIMARY':
      return IconType.StaplePrimaryIcon;
    case 'SUCCESS':
      return IconType.StapleSuccessIcon;
    case 'WARNING':
      return IconType.StapleWarningIcon;
    case 'ERROR':
      return IconType.StapleErrorIcon;
    default:
      throw new Error('alert type not recognized');
  }
};

const SortResponse = ({ alertType, primaryMessage, secondaryMessage, isWrongClient }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div
        style={{
          display: 'flex',
          marginBottom: '16px',
          alignItems: secondaryMessage ? 'flex-start' : 'center',
        }}
      >
        <div className={classes.iconContainer}>
          <Icon icon={getIconType(alertType)} />
        </div>
        <div className={classes.messageTextContainer}>
          <div className={classes.primaryMessageText}>{primaryMessage}</div>
          <div className={classes.secondaryMessageText}>{secondaryMessage}</div>
        </div>
      </div>
      {isWrongClient && (
        <div className={classes.missingClientContainer}>
          <div className={classes.missingClientText}>
            WARNING: Document may be stored in the wrong client
          </div>
        </div>
      )}
    </div>
  );
};

export default SortResponse;
