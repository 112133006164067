/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';

import { NoResultsIcon } from '../../../ui/icons';

const outerCon: CSSObject = {
  textAlign: 'center',
  fontSize: 16,
  margin: '65 auto 63 auto',
};

const circle: CSSObject = {
  borderRadius: '50%',
  height: '168px',
  width: '168px',
  backgroundColor: '#f7f7f9',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto 8 auto',
};

const title: CSSObject = {
  fontWeight: 'bold',
  fontSize: 24,
  marginBottom: 8,
};

const searchTerm: CSSObject = {
  fontWeight: 'bold',
};

export default function EmptySearch({ searchString }: { searchString: string }) {
  return (
    <div css={outerCon}>
      <div css={circle}>
        <NoResultsIcon />
      </div>
      <div css={title}>No search results</div>
      <div>
        No contact found matching <span css={searchTerm}>{searchString}</span>
      </div>
    </div>
  );
}
