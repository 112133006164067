/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { differenceInYears, format, parseISO } from 'date-fns';
import Button from '@mui/material/Button';
import { useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { MatchData } from './MatchVerification';
import { Document, DocumentType, Loan, LoanDocument } from '../../globalTypes/objects';
import ConditionalComponent from '../ConditionalComponent';
import { PageDisplay } from '../../pages/NotFounds';
import ReturnToInbox from './ReturnToInbox';
import { apiFetch } from '../../adalConfig';
import { ExtractedDocumentData } from '../../pages/DocumentCatchAll/types/document';
import AlertDialogSlide from './DocumentDeleteAlert';
import { useQuery } from '@tanstack/react-query';

const labelStyle = {
  fontWeight: 600,
};

const headerStyle = {
  fontWeight: 800,
  textAlign: 'center',
};

const dataStyle = {
  marginBottom: 4,
  paddingLeft: 5,
  position: 'relative',
};

const extractedDataStyle = {
  ...dataStyle,
  backgroundColor: '#e5e5ea',
};

const loanDataStyle = {
  paddingLeft: 5,
  position: 'relative',
  borderBottom: '4px solid #e5e5ea',
};

const containerStyle = {
  ...loanDataStyle,
  '&:before': {
    backgroundColor: 'white',
    borderRadius: '50%',
    content: '""',
    height: 8,
    left: -12,
    position: 'absolute',
    top: 8,
    width: 8,
  },
};

type FoundInDoc = 'YES' | 'NO' | 'UNKNOWN';

interface PropTypes {
  canMatch: boolean;
  loan: Loan;
  matchData?: MatchData;
  onReject: () => void;
  onMatch: () => void;
  onSkip: () => void;
  onReturnToInbox: () => void;
  onIrrelevantDoc: (string) => void;
  onRejectReasonConfirm: (string) => void;
  loading: boolean;
  titlePortalUploadId: number | undefined;
  pageDisplay?: PageDisplay;
  document: Document;
  extractedDocumentData: ExtractedDocumentData;
}

const textStyle = (s: FoundInDoc) => {
  switch (s) {
    case 'YES':
      return { '&:before': { backgroundColor: '#00c853' } };
    case 'NO':
      return { '&:before': { backgroundColor: '#ff3b30' } };
    case 'UNKNOWN':
      return { '&:before': { backgroundColor: '#ffcc00' } };
    default:
      throw new Error(`Unknown ${s}`);
  }
};

const getLoanDocuments = async (loanId: number) => {
  const { data } = await apiFetch(`/api/loans/loan-documents/${loanId}`);
  return data as LoanDocument[];
};

export default function MatchPanel({
  canMatch,
  loan,
  document,
  matchData,
  onReject,
  onMatch,
  onSkip,
  onReturnToInbox,
  onIrrelevantDoc,
  onRejectReasonConfirm,
  titlePortalUploadId,
  loading,
  pageDisplay,
  extractedDocumentData,
}: PropTypes) {
  const [confirmRejectedReason, setConfirmRejectedReason] = useState(false);
  const [rejectedReason, setRejectedReason] = useState('');

  const { data: loanDocuments } = useQuery({
    queryKey: ['loan-documents', loan.id],
    queryFn: () => getLoanDocuments(loan.id),
    refetchOnWindowFocus: false,
  });

  const isSecondMortgageRequired = useMemo(() => {
    if (!loanDocuments) {
      return false;
    }

    const secondMortgage = loanDocuments.find(
      x => x.documentTypeId === DocumentType.SecondMortgage,
    );

    return Boolean(secondMortgage?.isRequired);
  }, [loanDocuments]);

  const options = [
    { label: 'This document belongs to another loan' },
    { label: '1st lien received instead of a 2nd lien' },
    { label: '2nd lien received instead of a 1st lien' },
  ];

  return (
    <div>
      {differenceInYears(new Date(), parseISO(loan.dateDocumentsDrawn.toString())) >= 1 && (
        <div
          css={{
            fontWeight: 'bold',
            textAlign: 'center',
            border: '3px solid red',
            borderRadius: '3px',
            padding: '.5em',
          }}
        >
          <div>
            Loan is more than a year old. <br /> Be sure this is not a Refi!
          </div>
        </div>
      )}

      {isSecondMortgageRequired && (
        <div
          css={{
            fontWeight: 'bold',
            textAlign: 'center',
            border: '3px solid red',
            borderRadius: '3px',
            padding: '.5em',
            marginTop: '6px',
          }}
        >
          <div>Loan requires a second mortgage</div>
        </div>
      )}

      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            height: '600',
            width: '47%',
            display: 'grid',
            gridTemplateRows: '9% 12% 18% 12% 12% 13% 13% 11%',
          }}
        >
          <div
            css={{
              backgroundColor: '#e5e5ea',
              marginBottom: 4,
              marginTop: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div css={headerStyle}>Extracted Information</div>
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>Loan Number:</div> {extractedDocumentData.loanNumber}
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>Borrower:</div> {extractedDocumentData.borrower}
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>Loan Amount:</div>
            {extractedDocumentData.loanAmount > 0 ? `$${extractedDocumentData.loanAmount}` : ''}
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>Date Funded:</div>{' '}
            {extractedDocumentData.dateFunded != null
              ? format(new Date(Date.parse(extractedDocumentData.dateFunded)), 'MM/dd/yyyy')
              : ''}
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>Property:</div> {extractedDocumentData.propertyAddress}
          </div>
          <div css={extractedDataStyle}>
            <div css={labelStyle}>City:</div> {extractedDocumentData.city}{' '}
            {extractedDocumentData.state} {extractedDocumentData.zip}
          </div>
        </div>
        <div
          css={{
            height: '600',
            width: '47%',
            display: 'grid',
            gridTemplateRows: '9% 12% 18% 12% 12% 13% 13% 11%',
          }}
        >
          <div
            css={{
              borderBottom: '4px solid #e5e5e5',
              marginTop: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div css={headerStyle}>Loan Information</div>
          </div>
          <div
            css={[
              containerStyle,
              matchData && textStyle(matchData.containsLoanNumber ? 'YES' : 'NO'),
            ]}
          >
            <div css={labelStyle}>Loan Number:</div> {loan.loanNumber}
          </div>

          <div
            css={[
              containerStyle,
              matchData && textStyle(matchData.fuzzyBorrower >= 90 ? 'YES' : 'NO'),
            ]}
          >
            <div css={labelStyle}>Borrower:</div> {loan.borrower}
          </div>

          {loan.loanAmount > 0 ? (
            <div
              css={[
                containerStyle,
                matchData &&
                  matchData.containsLoanAmount != null &&
                  textStyle(matchData.containsLoanAmount ? 'YES' : 'NO'),
              ]}
            >
              <div css={labelStyle}>Loan Amount:</div> ${loan.loanAmount}
            </div>
          ) : (
            <div css={dataStyle}>
              <div css={labelStyle}>Loan Amount:</div>
            </div>
          )}

          <div
            css={[
              containerStyle,
              matchData &&
                textStyle(
                  Math.min(...matchData.dateDaysFromDateFunded) === 0
                    ? 'YES'
                    : Math.min(...matchData.dateDaysFromDateFunded) <= 7
                    ? 'UNKNOWN'
                    : 'NO',
                ),
            ]}
          >
            <div css={labelStyle}>Date Funded:</div>{' '}
            {format(new Date(Date.parse(loan.dateDocumentsDrawn)), 'MM/dd/yyyy')}
          </div>

          <div
            css={[
              containerStyle,
              matchData && textStyle(matchData.fuzzyPropertyAddress >= 90 ? 'YES' : 'NO'),
            ]}
          >
            <div css={labelStyle}>Property:</div> {loan.propertyAddress}
          </div>

          <div css={containerStyle}>
            <div css={labelStyle}>City:</div> {loan.city} {loan.state} {loan.zip}
          </div>

          {loan.county && (
            // @ts-ignore
            <div css={containerStyle}>
              <div css={labelStyle}>County:</div> {loan.county}
            </div>
          )}

          {loan.mTANum && (
            // @ts-ignore
            <div css={containerStyle}>
              <div css={labelStyle}>Title Number:</div> {loan.mTANum}
            </div>
          )}
        </div>
      </div>
      {document.clientId === loan.clientID && (
        <div>
          <div className="df jcsb mt2">
            <div css={{ width: 80 }}>
              {canMatch && (
                <Button variant="contained" color="primary" disabled={loading} onClick={onMatch}>
                  Match
                </Button>
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={titlePortalUploadId ? () => setConfirmRejectedReason(true) : onReject}
            >
              Reject
            </Button>
            <ConditionalComponent display={pageDisplay === PageDisplay.normal}>
              <Button variant="contained" color="primary" disabled={loading} onClick={onSkip}>
                Skip
              </Button>
            </ConditionalComponent>
          </div>
          <div className="df mt2" css={{ justifyContent: 'center', color: '#ff3b30' }}>
            {(pageDisplay === PageDisplay.normal || pageDisplay === PageDisplay.specificMatch) && (
              <ReturnToInbox
                docId={document.id}
                disabled={loading}
                color="inherit"
                onConfirm={onReturnToInbox}
                onIrrelevantDocConfirm={onIrrelevantDoc}
                showDelete
              />
            )}
          </div>
        </div>
      )}
      {document.clientId !== loan.clientID && (
        <div>
          <div css={{ display: 'flex', justifyContent: 'center', marginBottom: '2' }}>
            <Button
              css={{}}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={titlePortalUploadId ? () => setConfirmRejectedReason(true) : onReject}
            >
              Reject
            </Button>
          </div>
          <div css={{ textAlign: 'center', fontSize: 18, fontWeight: '600' }}>
            Cross Lender. Cannot Match.
          </div>
        </div>
      )}
      <AlertDialogSlide
        isOpen={confirmRejectedReason}
        handleClose={() => setConfirmRejectedReason(false)}
        onConfirm={() => {
          onRejectReasonConfirm(rejectedReason);
          setConfirmRejectedReason(false);
          onReject();
        }}
        disableConfirm={rejectedReason === ''}
        confirmationHeader="Reject Title Portal Match"
        confirmationText="Please select reason for match rejection and confirm rejection of title portal match."
      >
        <div>
          <Autocomplete
            disablePortal
            options={options}
            style={{ marginTop: 10, marginBottom: 10 }}
            onChange={(event, newValue) => {
              setRejectedReason(newValue ? newValue.label : '');
            }}
            getOptionLabel={option => option.label}
            renderInput={params => <TextField {...params} label="Rejected Reason" />}
          />
        </div>
      </AlertDialogSlide>
    </div>
  );
}
