/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';
import colors from '../../../../../styles/colors';

// #region CSS
const tileStyles: CSSObject = {
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  display: 'inline-block',
  fontSize: 14,
  height: 224,
  marginBottom: 8,
  marginTop: 32,
  padding: '33px 33px',
  transition: 'border .2s, background-color .2s',
  width: '100%',
  ':nth-of-type(n+0)': {
    animation: 'none',
    ':last-child': { marginBottom: 0 },
  },
};

const circleColumn: CSSObject = {
  position: 'absolute',
  top: 0,
  right: 0,
};

const p: CSSObject = {
  height: 16,
  display: 'block',
  marginBottom: 4,
};

const title: CSSObject = {
  fontWeight: 'bold',
  position: 'relative',
  marginBottom: 22,
};

const addressCon: CSSObject = {
  marginBottom: 22,
};

const contact: CSSObject = {
  position: 'relative',
  width: '100%',
};

const emailField: CSSObject = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  height: 16,
};

const circleStyles: CSSObject = {
  borderRadius: '50%',
  display: 'inline-block',
  height: '13px',
  width: '13px',
  backgroundColor: colors.red,
  transition: 'border .2s, background-color .2s',
};

const skeleton = (width: number): CSSObject => ({
  width,
  borderRadius: 8,
  backgroundColor: colors.grayExLight,
});

// #endregion

export default function UnidentifiedTileSkeleton() {
  return (
    <div css={tileStyles} data-test="match unidentified">
      <div css={title}>
        <p css={{ ...skeleton(315), ...p }} />
        <p css={{ ...skeleton(315), ...p }} />
        <div css={circleColumn}>
          <p css={circleStyles} />
        </div>
      </div>
      <div css={addressCon}>
        <p css={{ ...skeleton(315), ...p }} />
        <p css={{ ...skeleton(315), ...p }} />
      </div>
      <div css={contact}>
        <p css={{ ...skeleton(105), ...p }} />
        <p css={{ ...skeleton(105), ...p }} />
        <div css={{ ...skeleton(252), ...emailField }} />
      </div>
    </div>
  );
}
