/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import colors from '../../styles/colors';

const baseStyles = {
  backgroundColor: colors.blue,
  borderRadius: 48 / 2,
  color: colors.white,
  cursor: 'pointer',
  fontSize: 11,
  fontWeight: 'bold',
  height: 48,
  letterSpacing: 1,
  minWidth: 128,
  padding: '0 25px',
  position: 'relative',
  textTransform: 'uppercase',
  transition: 'all .3s',
  '&:hover': {
    backgroundColor: colors.blueDark,
  },
};

const darkStyles = {
  backgroundColor: colors.blueDark,
  color: colors.white,
  '&:hover': {
    backgroundColor: colors.grayExLight,
    border: `1px solid ${colors.blueDark}`,
    color: colors.blueDark,
  },
};

const secondaryStyles = {
  backgroundColor: colors.white,
  color: colors.blue,
  border: `1px solid ${colors.blue}`,
  '&:hover': {
    backgroundColor: colors.grayExLight,
  },
};

const largeStyles = {
  height: 56,
  borderRadius: 56 / 2,
  minWidth: 177,
  padding: '0 25px',
};

const defaultDisabled = {
  cursor: 'not-allowed',
  backgroundColor: colors.grayLight,
  color: colors.white,
  border: 'none',
  ':hover': {
    backgroundColor: colors.grayLight,
    color: colors.white,
    border: 'none', // to override dark hover border
  },
};

const secondaryDisabled = {
  cursor: 'not-allowed',
  backgroundColor: colors.white,
  color: colors.grayLight,
  border: `1px solid ${colors.grayLight}`,
  ':hover': {
    backgroundColor: colors.white,
  },
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | any;
  dark?: boolean;
  secondary?: boolean;
  large?: boolean;
  disabled?: boolean;
  styleOverrides?: CSSObject;
}

export default function Button({
  children,
  dark,
  secondary,
  large,
  disabled,
  styleOverrides,
  ...props
}: ButtonProps) {
  const disabledStyles = secondary ? secondaryDisabled : defaultDisabled;
  const mergedStyles = [
    baseStyles,
    large && largeStyles,
    dark && darkStyles,
    secondary && secondaryStyles,
    disabled && disabledStyles,
    styleOverrides,
  ];
  return (
    <button {...props} disabled={disabled} css={mergedStyles}>
      {children}
    </button>
  );
}
