/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { Chip, LinearProgress, Tooltip } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import GetAppIcon from '@mui/icons-material/GetApp';
import PauseIcon from '@mui/icons-material/Pause';
import CachedIcon from '@mui/icons-material/Cached';
import format from 'date-fns/format';
import { deepOrange, green, grey, red } from '@mui/material/colors';
import Link from '@mui/material/Link';
import DatePicker from 'react-datepicker';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useDataTableFilters from '../ui/DataTable/UseDataTableFilters';
import { apiFetch } from '../../adalConfig';
import DataTable from '../ui/DataTable/DataTable';
import { useToaster } from '../../Hooks/toasters';
import Modal from '@mui/material/Modal';
import ImportedLoansModal from './ImportedLoansModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      margin: '16px 3px 0px',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }),
);

enum LoanImportBatchStatus {
  Failed,
  Success,
  Processing,
  Preprocessing,
  NothingToProcess,
}

type RowData = {
  id: number;
  client: string;
  importedErrorsCount: number;
  rejectedCount: number;
  importStatus: LoanImportBatchStatus;
  filePath: string;
  note: string;
  loansInBatch: number;
  importedCount: number;
  createdAt: Date;
  importedBy: string;
  isUpdatingExistingLoans: boolean;
  hasRawDataPath: boolean;
};

const displayStatusIcon = (importStatus: LoanImportBatchStatus) => {
  if (importStatus === LoanImportBatchStatus.Success) {
    return (
      <Tooltip title="Import Processed Successfully">
        <DoneIcon style={{ color: green[500] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Failed) {
    return (
      <Tooltip title="Failed While Processing">
        <CloseIcon style={{ color: red[800] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Processing) {
    return (
      <Tooltip title="Processing">
        <LinearProgress />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.Preprocessing) {
    return (
      <Tooltip title="Waiting To Be Processed">
        <PauseIcon style={{ color: grey[500] }} />
      </Tooltip>
    );
  }

  if (importStatus === LoanImportBatchStatus.NothingToProcess) {
    return (
      <Tooltip title="Failed Before Processing">
        <BlockIcon style={{ color: deepOrange[500] }} />
      </Tooltip>
    );
  }
};

const ImportInformation = () => {
  const [selectedBatchId, setSelectedBatchId] = useState<number | undefined>(undefined);
  const [viewOnlyImportedLoans, setViewOnlyImportedLoans] = useState<boolean | undefined>();

  const { errorToaster } = useToaster();

  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    clearCustomFilter,
  } = useDataTableFilters('/api/loans/import-information-filters');

  const classes = useStyles();

  const datePickerFilter = customFilters.find(filter => filter.label === 'ImportMonthAndYear');

  const activeDatePickerFilterOption = datePickerFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const exportSpreadsheet = async (apiUrl: string, fileName: string) => {
    try {
      const { headers, data } = await apiFetch(apiUrl, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;

      let fileNameFromServer;
      const fileParts = headers['content-disposition'].match(
        /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      );
      if (fileParts.length >= 2) {
        fileNameFromServer = fileParts[1].replace(/"/g, '');
      }

      a.download = fileNameFromServer || fileName;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const columns = [
    {
      field: 'id',
      title: 'Batch Id',
      render: (rowData: RowData) => (
        <div style={{ display: 'flex', gap: '.4rem', alignItems: 'center' }}>
          <span>{rowData.id}</span>
          {rowData.isUpdatingExistingLoans && (
            <Tooltip title="Updated Loans">
              <CachedIcon color="action" />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      field: 'client',
      title: 'Client',
    },
    {
      field: 'importedErrorsCount',
      title: 'Loans Imported With Errors',
    },
    {
      field: 'loansInBatch',
      title: 'Loans In Batch',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.hasRawDataPath ? (
            <Link
              underline="hover"
              onClick={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                setSelectedBatchId(rowData.id);
                setViewOnlyImportedLoans(false);
              }}
            >
              {rowData.loansInBatch}
            </Link>
          ) : (
            rowData.loansInBatch
          )}
        </Fragment>
      ),
    },
    {
      field: 'importedCount',
      title: 'Total Imported',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.hasRawDataPath && rowData.importedCount > 0 ? (
            <Link
              underline="hover"
              onClick={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                setSelectedBatchId(rowData.id);
                setViewOnlyImportedLoans(true);
              }}
            >
              {rowData.importedCount}
            </Link>
          ) : (
            rowData.importedCount
          )}
        </Fragment>
      ),
    },
    {
      field: 'rejectedCount',
      title: 'Total Excluded From Import',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.rejectedCount ? (
            <Link
              underline="hover"
              onClick={async (e: React.SyntheticEvent) => {
                e.preventDefault();
                await exportSpreadsheet(
                  `/api/loans/download-rejected-imports?batchId=${rowData.id}`,
                  `Rejected Loans From Batch Id ${rowData.id}`,
                );
              }}
            >
              {rowData.rejectedCount}
            </Link>
          ) : (
            0
          )}
        </Fragment>
      ),
    },
    {
      field: 'importStatus',
      title: 'Import Status',
      render: (rowData: RowData) => displayStatusIcon(rowData.importStatus),
    },
    {
      field: 'filePath',
      title: 'Original Spreadsheet',
      render: (rowData: RowData) =>
        !!rowData.filePath && (
          <GetAppIcon
            style={{ cursor: 'pointer' }}
            onClick={() =>
              exportSpreadsheet(
                `/api/loans/download-import-spreadsheet?batchId=${
                  rowData.id
                }&isEncompass=${!rowData.importedBy}`,
                rowData.filePath,
              )
            }
          />
        ),
    },
    {
      field: 'note',
      title: 'Note',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.note?.split('\n').map((notePart, i) => (
            <div key={i}>{notePart}</div>
          ))}
        </Fragment>
      ),
    },
    {
      field: 'createdAt',
      title: 'Import Date',
      render: (rowData: RowData) =>
        rowData.createdAt && format(new Date(rowData.createdAt), 'MM/dd/yyyy h:mm:ss aaa'),
    },
    {
      field: 'importedBy',
      title: 'Imported By',
      render: (rowData: RowData) => rowData.importedBy ?? 'Encompass',
    },
  ];

  return (
    <Fragment>
      <div css={{ paddingLeft: 29 }}>
        {datePickerFilter?.filterOptions &&
          datePickerFilter.filterOptions.length > 0 &&
          activeDatePickerFilterOption?.id && (
            <div className={classes.root}>
              <Chip
                variant="outlined"
                onClick={() => clearCustomFilter(datePickerFilter)}
                label="Clear Date Filter"
              />
            </div>
          )}
      </div>

      <DataTable<RowData>
        title="Loan Imports"
        columns={columns}
        url="/api/loans/import-information"
        orderBy={{ field: 'id' }}
        orderDirection="desc"
        headerStyle={{ whiteSpace: 'normal' }}
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        renderCustomFilters={
          <div css={{ margin: '10px 5px' }}>
            {datePickerFilter && (
              <div style={{ paddingLeft: '32px' }}>
                <b>Select a date</b>
                <div>
                  <DatePicker
                    minDate={new Date(2000, 0, 1)}
                    maxDate={new Date()}
                    inline
                    selected={
                      activeDatePickerFilterOption?.id
                        ? new Date(activeDatePickerFilterOption.id)
                        : null
                    }
                    onChange={dateTime =>
                      dateTime &&
                      onCustomOptionSelected(datePickerFilter, {
                        id: dateTime.toISOString(),
                        label: format(dateTime, 'MM/yy'),
                        active: true,
                      })
                    }
                    placeholderText="Click to select"
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                  />
                </div>
              </div>
            )}
          </div>
        }
      />

      <Modal
        open={selectedBatchId !== undefined && viewOnlyImportedLoans !== undefined}
        onClose={() => {
          setSelectedBatchId(undefined);
          setViewOnlyImportedLoans(undefined);
        }}
      >
        <ImportedLoansModal
          batchId={selectedBatchId!}
          viewOnlyImportedLoans={viewOnlyImportedLoans!}
        />
      </Modal>
    </Fragment>
  );
};

export default ImportInformation;
