import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

const useInfiniteScroll = (fetchData: () => Promise<void>) => {
  const [isFetching, setIsFetching] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleScroll = useCallback(async () => {
    if (window.innerHeight + window.scrollY < document.body.offsetHeight || disabled) {
      return;
    }

    setIsFetching(true);
    await fetchData();
    setIsFetching(false);
  }, [disabled, fetchData]);

  const debouncedScroll = debounce(handleScroll, 500);

  useEffect(() => {
    window.addEventListener('scroll', debouncedScroll);
    return () => window.removeEventListener('scroll', debouncedScroll);
  }, [debouncedScroll]);

  return [isFetching, setDisabled];
};

export default useInfiniteScroll;
