import React from 'react';

import ErrorPage, { ErrorType } from '../components/ui/ErrorPage';

const error: ErrorType = {
  errorTitle: 'Error ',
  errorName: 500,
  firstNumber: 5,
  lastNumber: 0,
  errorMessage: (
    <div>
      Sorry, there was an <br /> internal server error on our end.
      <br /> Please try again or contact
      <br /> support for help.
    </div>
  ),
};

export default function Page500() {
  return <ErrorPage errorType={error} />;
}
