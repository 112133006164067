/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { forwardRef } from 'react';

import colors from '../../../styles/colors';

const labelStyle: CSSObject = {
  fontSize: 14,
  color: '#000000',
  display: 'block',
};

export const styles: CSSObject = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  caretColor: colors.blue,
  color: '#000000',
  cursor: 'pointer',
  display: 'block',
  fontSize: 14,
  height: 48,
  outline: 0,
  overflow: 'hidden',
  padding: '0px 16px',
  textOverflow: 'ellipsis',
  transition: '.2s border',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: '100%',
  '::placeholder': { color: '#c7c7cc' },
  ':focus-within': {
    '&&': { border: `1px solid ${colors.blue}` },
  },
};

const errorStyles: CSSObject = { border: `1px solid ${colors.red}` };

const labelsWrap: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 8,
};

const errorLabelStyle: CSSObject = {
  color: colors.red,
  fontSize: 12,
};

const disabledStyles: CSSObject = {
  border: '1px solid #e5e5ea',
  backgroundColor: '#f7f7f9',
  ':focus': {
    border: '1px solid #e5e5ea',
    backgroundColor: '#f7f7f9',
  },
  cursor: 'not-allowed',
};

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean;
  disabled?: boolean;
  label?: string;
  labelOverrides?: any;
  styleOverrides?: any;
  required?: boolean;
}

function TextInput(props: TextInputProps, ref) {
  const { error, disabled, required, label, labelOverrides, styleOverrides, ...rest } = props;
  return (
    <label css={[labelStyle, labelOverrides]}>
      <div css={labelsWrap}>
        <span>{label}</span>
        <span css={{ marginBottom: -4 }}>
          {required && <span css={[errorLabelStyle, { color: 'green' }]}>* required</span>}
          {error && <span css={errorLabelStyle}>{error}</span>}
        </span>
      </div>
      <input
        ref={ref}
        css={[styles, error && errorStyles, disabled && disabledStyles, styleOverrides]}
        disabled={disabled}
        {...rest}
      />
    </label>
  );
}

export default forwardRef(TextInput);
