import { adaptV4Theme, createTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: colors.blue,
      },
      secondary: {
        main: colors.blue,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '0px',
          textTransform: 'none',
        },
        label: {
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
    },
  }),
);

export default theme;

const useStyles = makeStyles({
  // edit title company modal
  dialogTitleRoot: {
    paddingBottom: 0,
  },
  dialogContentText: {
    fontSize: 14,
  },
  dialogContentRoot: {
    paddingTop: 0,
  },
  textFieldUnderline: {
    '&:before': { borderBottom: '1px solid #a1adcd' },
  },
  formFieldRoot: {
    marginTop: 30,
  },
  dialogSpacing: {
    border: '26px solid white',
  },
  dialogActionsSpacing: {
    borderTop: '16px solid white',
    borderRight: '16px solid white',
  },
  editButton: {
    borderRight: '20px solid white',
  },
  // consolidation modal
  containerBorder: {
    border: '1px solid #d0d6e6',
    boxShadow: '-7px 9px 15px 0 rgba(137, 165, 202, 0.12)',
  },
  dialogContentWithoutScroll: {
    overflow: 'hidden',
  },
  // leave page warning modal
  dialogActionsRoot: { justifyContent: 'center' },
  buttonLabelBold: { fontWeight: 'bold' },
  buttonLabelGrey: { color: 'grey' },
});

export { useStyles };
