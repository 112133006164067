/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { AutoDeletedDocs as RowData } from './types';

type Props = {
  autoDeletedDocs: RowData[];
  fromDate: Date;
};

const AutoDeletedDocuments = ({ autoDeletedDocs, fromDate }: Props) => {
  const columns = [
    {
      field: 'barcodeId',
      title: 'Barcode Id',
    },
    {
      field: 'docType',
      title: 'Document Type',
    },
    {
      field: 'deletedDate',
      title: `Deleted Date`,
      render: (rowData: RowData) => new Date(rowData.deletedDate).toLocaleDateString(),
    },
    {
      field: 'client',
      title: `Client`,
    },
    {
      field: 'loanNumber',
      title: `Loan Number`,
    },
    {
      field: 'reason',
      title: `Deleted Reason`,
    },
  ];

  return (
    <Fragment>
      {autoDeletedDocs && (
        <DataTable<RowData>
          title={`Deleted Docs From  ${fromDate.toLocaleDateString()}`}
          columns={columns}
          data={autoDeletedDocs}
          options={{
            selection: false,
            search: false,
            exportFileName: `Deleted Docs From  ${fromDate.toLocaleDateString()}`,
          }}
        />
      )}
    </Fragment>
  );
};

export default AutoDeletedDocuments;
