/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import FileDropper from '../../ui/FileDropper';
import Button from '../../ui/Button';
import StatusButton, { StatusTypes } from '../../ui/StatusButton';
import { apiFetch, apiPost } from '../../../adalConfig';
import { Client, LoanImportMapping } from '../../../globalTypes/objects';
import ClientSearch from '../../Utilities/ClientSearch';
import { convertToBase64, isDataAssociation } from '../../../Utils';
import Checkbox from '../../ui/Checkbox';
import { NewImportState } from '../../../pages/NewImport';
import { AuthConsumer } from '../../AuthContext';
import ImportReviewModal, { ImportData } from '../../Loans/ImportReviewModal';

const outerContainer = {
  width: 'calc(100% - 82px)',
  margin: 40,
};

const searchSelect = {
  width: '230px',
  marginRight: '24px',
};

const dropdownSection = {
  width: '100%',
  display: 'flex',
};

const buttonSec = {
  marginTop: 40,
  paddingBottom: 40,
  display: 'flex',
  justifyContent: 'center',
};

type ImportFormProps = {
  onSubmit: (submit: Partial<NewImportState>) => void;
  handleExit: () => void;
  updateErrorCount: () => Promise<void>;
  clients: Client[];
};

type ImportFormState = {
  file: File | undefined;
  importing: boolean;
  loading: boolean;
  isHomeEquity: boolean;
  isUpdatingExistingLoans: boolean;
  clientId: number | undefined;
  validationOverride: boolean;
  importData: ImportData;
  hasClientMappings: boolean;
  isImporting: boolean;
};

class ImportForm extends Component<ImportFormProps, ImportFormState> {
  state: ImportFormState = {
    file: undefined,
    importing: false,
    loading: false,
    isHomeEquity: false,
    isUpdatingExistingLoans: false,
    clientId: undefined,
    validationOverride: false,
    importData: {} as ImportData,
    hasClientMappings: true,
    isImporting: false,
  };

  onClientSelected = async (client: Client) => {
    const { data } = await apiFetch<LoanImportMapping[]>(
      `/api/loans/client-import-mappings?clientId=${client.id}`,
    );
    this.setState({ clientId: client.id, hasClientMappings: !!data.length });
  };

  reviewForImport = async () => {
    const { file, isHomeEquity, isUpdatingExistingLoans, clientId, validationOverride } = this
      .state as ImportFormState;
    const { updateErrorCount, onSubmit } = this.props;

    if (!file) {
      onSubmit({ errorMessage: 'Please add a file' });
      return;
    }

    if (file.name.match(/[^.\w\s()-]/)) {
      onSubmit({
        errorMessage:
          'Filename can only be an alphanumeric character, period, space, underscore, parenthesis, or hyphen.',
      });

      return;
    }

    try {
      this.setState({ importing: true });

      const { data: importData } = await apiPost<ImportData>(
        '/api/loans/review-spreadsheet-for-import',
        {
          spreadsheet: await convertToBase64(file),
          clientId,
          filename: file.name,
          isHomeEquity,
          isUpdatingExistingLoans,
          validationOverride,
        },
      );

      if (importData.rawLoansData.length === 0) {
        this.cancelSubmit();
        onSubmit({ errorMessage: 'No loans found in the spreadsheet' });

        return;
      }

      this.setState({ importData });
    } catch (error) {
      if (!error.response) {
        onSubmit({ errorMessage: error.message });
        await updateErrorCount();
        return;
      }

      let errors = [];
      if (error.response.data?.errors) {
        errors = error.response.data.errors.map(e => e.message);
      } else if (Array.isArray(error.response.data)) {
        errors = error.response.data;
      }

      if (errors.length) {
        const errorMessage = (
          <div>
            {errors.map((message: string, i: number) => (
              <p key={i} style={{ paddingBottom: '20px' }}>
                {message}
              </p>
            ))}
          </div>
        );
        onSubmit({ errorMessage });
      } else {
        onSubmit({ errorMessage: error.response.data.split('\n')[0] });
      }
    }
  };

  cancelSubmit = () => {
    this.setState({
      importing: false,
      loading: false,
      file: undefined,
      importData: {} as ImportData,
    });
  };

  handleSubmit = async () => {
    const { file, isUpdatingExistingLoans, clientId, importData } = this.state as ImportFormState;
    const { updateErrorCount, onSubmit } = this.props;

    if (importData.errors.length > 0) {
      this.cancelSubmit();
      onSubmit({ errorMessage: 'Cannot import loans with critical errors' });

      return;
    }

    try {
      this.setState({ isImporting: true });
      const { data: loansCount } = await apiPost<number>('/api/loans/importSpreadsheet', {
        rawLoansData: importData.rawLoansData,
        spreadsheet: await convertToBase64(file!),
        clientId,
        filename: file!.name,
        isUpdatingExistingLoans,
      });

      this.setState({ isImporting: false });
      onSubmit({ importCount: loansCount });
    } catch (error) {
      if (!error.response) {
        onSubmit({ errorMessage: error.message });
        await updateErrorCount();
        return;
      }

      let errors = [];
      if (error.response.data?.errors) {
        errors = error.response.data.errors.map(e => e.message);
      } else if (Array.isArray(error.response.data)) {
        errors = error.response.data;
      }

      if (errors.length) {
        const errorMessage = (
          <div>
            {errors.map((message: string, i: number) => (
              <p key={i} style={{ paddingBottom: '20px' }}>
                {message}
              </p>
            ))}
          </div>
        );
        onSubmit({ errorMessage });
      } else {
        onSubmit({ errorMessage: error.response.data.split('\n')[0] });
      }
    }

    await updateErrorCount();
  };

  handleFileSelection = async (file: File) => {
    if (!file) {
      this.setState({ file: undefined });
      return;
    }
    this.setState({ file });
  };

  checkClientMismatch = () => {
    const { file, clientId } = this.state;
    const { clients } = this.props;
    if (file && !!clientId) {
      const name = (file as any).name.replace(/ .*/, '').toLowerCase();
      const client = clients!
        .find(c => c.id === clientId)!
        .company.replace(/ /g, '')
        .toLowerCase();
      return !client.includes(name);
    }
    return false;
  };

  render() {
    const {
      file,
      clientId,
      importing,
      loading,
      isHomeEquity,
      isUpdatingExistingLoans,
      validationOverride,
    } = this.state;
    const { handleExit } = this.props;
    if (loading) {
      return <Fragment />;
    }
    const isFormValid = file && clientId;

    return (
      <div>
        <Global styles={{ ' body': { backgroundColor: '#fff' } }} />
        <div css={outerContainer}>
          <div css={dropdownSection}>
            <div css={{ width: 230, marginRight: 24 }}>
              <div>
                <ClientSearch
                  selectedClient={clientId}
                  onChange={this.onClientSelected}
                  styleOverrides={searchSelect}
                />
              </div>
              {!this.state.hasClientMappings && (
                <div style={{ color: 'red' }}>* Missing client mappings</div>
              )}
            </div>
            <div css={{ width: 230, margin: 'auto 0' }}>
              <Checkbox
                text="Is Home Equity"
                labelOverrides={{ width: 200 }}
                checked={isHomeEquity}
                onClick={() => this.setState({ isHomeEquity: !isHomeEquity })}
              />
            </div>
            <div css={{ width: 230, margin: 'auto 0' }}>
              <Checkbox
                text="Update Existing Loans"
                labelOverrides={{ width: 200 }}
                checked={isUpdatingExistingLoans}
                onClick={() => this.setState({ isUpdatingExistingLoans: !isUpdatingExistingLoans })}
              />
            </div>
            <AuthConsumer>
              {({ roles }) =>
                isDataAssociation(roles) && (
                  <div style={{ width: 230, margin: 'auto 0' }}>
                    <Checkbox
                      text="Validation Override"
                      labelOverrides={{ width: 200 }}
                      checked={validationOverride}
                      onClick={() => this.setState({ validationOverride: !validationOverride })}
                    />
                  </div>
                )
              }
            </AuthConsumer>
          </div>
          <FileDropper
            onChange={this.handleFileSelection}
            file={file}
            clientMismatch={this.checkClientMismatch()}
            acceptableFileTypes=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </div>
        <div css={buttonSec}>
          <Button
            styleOverrides={{ marginRight: '12px' }}
            secondary
            large
            onClick={handleExit}
            data-test="cancel"
          >
            cancel
          </Button>
          <StatusButton
            text={importing ? 'importing' : 'import'}
            disabled={!isFormValid}
            onClick={this.reviewForImport}
            {...(importing && { status: StatusTypes.loading })}
            data-test="import"
          />
        </div>

        <ImportReviewModal
          clientId={clientId ?? 0}
          fileName={file?.name ?? ''}
          importData={this.state.importData}
          confirmSubmit={this.handleSubmit}
          cancelSubmit={this.cancelSubmit}
          isImporting={this.state.isImporting}
        />
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(ImportForm);
