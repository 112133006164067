/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction, useContext } from 'react';
import { ActionButton, InfoRow, InnerCard, InnerCardHeader, nameS } from './feesStyles';
import { EditTwoIcon } from '../../components/ui/icons';
import ConditionalComponent from '../../components/ConditionalComponent';
import { AuthContext } from '../../components/AuthContext';
import { ChargeData } from './types';
import { isClientAdmin } from '../../Utils';

type Props = {
  charges: ChargeData[][];
  editOnclick: () => Dispatch<SetStateAction<boolean>>;
};

const chargeDisplay = (charge: ChargeData) => {
  const isPercent = charge.description.toLowerCase().includes('percent');
  const isThreshold = charge.description.toLowerCase().includes('threshold');
  const amount = charge.amount === 0 ? '' : charge.amount;

  if (isThreshold) {
    return amount;
  }

  return isPercent ? `${amount}%` : `$${amount}`;
};

export default function ClientFeesDisplayCard({ charges, editOnclick }: Props) {
  const { roles } = useContext(AuthContext);

  return (
    <div>
      <div className="df aic jcsb mb3">
        <div css={nameS}>Client Fees</div>
        <ConditionalComponent display={isClientAdmin(roles)}>
          <ActionButton onClick={editOnclick}>
            <EditTwoIcon />
          </ActionButton>
        </ConditionalComponent>
      </div>
      <div>
        {charges.map((chargeGroup, index) => (
          <div
            key={index}
            css={{
              padding: 16,
              label: { marginTop: 16 },
            }}
          >
            <InnerCardHeader>
              {chargeGroup.length && `${chargeGroup[0].description.slice(0, 8)}s`}
            </InnerCardHeader>
            <InfoRow>
              {chargeGroup.map(charge => (
                <InnerCard key={charge.description}>
                  {charge.description.slice(11)}
                  <br />
                  <span className="bold fs18">{chargeDisplay(charge)}</span>
                </InnerCard>
              ))}
            </InfoRow>
          </div>
        ))}
      </div>
    </div>
  );
}
