import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import Link from '@mui/material/Link';

type RowData = {
  barcode: number;
  coversheetCreated: Date;
  documentType: string;
  client: string;
  loanNumber: string;
  investor: string;
};

const columns = [
  {
    field: 'barcode',
    title: 'Barcode',
    render: ({ barcode }: RowData) => (
      <Link target="_blank" href={`/documents/${barcode}`}>
        {barcode}
      </Link>
    ),
  },
  {
    field: 'coversheetCreated',
    title: 'Coversheet Created',
    render: (rowData: RowData) =>
      rowData.coversheetCreated && new Date(rowData.coversheetCreated).toLocaleDateString(),
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
  },
  {
    field: 'investor',
    title: 'Investor',
  },
];

const WaitingToBeSortedDrillDownReport = () => (
  <DataTable<RowData>
    title="Sorting Drill Down Report"
    columns={columns}
    url="/api/reports/sorting-drill-down-report"
  />
);

export default WaitingToBeSortedDrillDownReport;
