import { RowData } from '../types';
import { useMemo } from 'react';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

export type DataTableFilters = {
  disposition: number | null;
  pod: number | null;
  client: string | null;
  titleCompany: string | null;
};

export const useEscalationFilters = (dataTableData: RowData[]) => {
  const [query, setQuery] = useQueryParams({
    disposition: withDefault(NumberParam, null),
    pod: withDefault(NumberParam, null),
    client: withDefault(StringParam, null),
    titleCompany: withDefault(StringParam, null),
  });

  const filteredData = useMemo(() => {
    return dataTableData.filter(rowData => {
      if (query.disposition !== null && rowData.latestDisposition?.id !== query.disposition) {
        return false;
      }

      if (query.client !== null && rowData.clientInfo.client !== query.client) {
        return false;
      }

      if (query.pod !== null && rowData.podNumber !== query.pod) {
        return false;
      }

      if (query.titleCompany !== null && rowData.titleCompany !== query.titleCompany) {
        return false;
      }

      return true;
    });
  }, [query, dataTableData]);

  const onFilterSelected = <T extends keyof DataTableFilters>(
    field: T,
    value: DataTableFilters[T],
  ) => {
    setQuery(prevState => ({ ...prevState, [field]: value }));
  };

  return { filters: query, filteredData, onFilterSelected };
};
