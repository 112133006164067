export const internalKind: 'Internal' = 'Internal';
export const externalKind: 'External' = 'External';

type InternalUserType = { kind: 'Internal'; viewClientPermission: ViewClientPermission };
type ExternalUserType = { kind: 'External'; viewClientPermission: ExternalUserClientPermission };

export type PermissionKind = 'ALL' | 'SOME' | 'POD';

export const viewAllPermissionKind: Extract<PermissionKind, 'ALL'> = 'ALL';
export const viewSpecificPermissionKind: Extract<PermissionKind, 'SOME'> = 'SOME';
export const viewPodPermissionKind: Extract<PermissionKind, 'POD'> = 'POD';

type AllViewClientPermission = { kind: 'ALL' };
type SpecificViewClientPermission = { kind: 'SOME'; clientIdWhitelist: number[] };
type PodViewClientPermission = { kind: 'POD'; podIdWhitelist: number[] };

type UserType = InternalUserType | ExternalUserType;

export type ViewClientPermission =
  | AllViewClientPermission
  | SpecificViewClientPermission
  | PodViewClientPermission;

export type ExternalUserPermissionKind = 'SINGLE' | 'MULTIPLE';
export const viewSinglePermissionKind: Extract<ExternalUserPermissionKind, 'SINGLE'> = 'SINGLE';
export const viewMultiplePermissionKind: Extract<ExternalUserPermissionKind, 'MULTIPLE'> =
  'MULTIPLE';

export type ExternalUserClientPermission =
  | { kind: 'SINGLE'; clientId: number | null }
  | { kind: 'MULTIPLE'; clientIdWhitelist: number[] };

export function isInternalUser(userType: UserType): userType is InternalUserType {
  return userType.kind === internalKind;
}

export function isExternalUser(userType: UserType): userType is ExternalUserType {
  return userType.kind === externalKind;
}

export function isAllViewClientPermission(
  viewClientPermission: ViewClientPermission,
): viewClientPermission is AllViewClientPermission {
  return viewClientPermission.kind === viewAllPermissionKind;
}

export function isSpecificViewClientPermission(
  viewClientPermission: ViewClientPermission,
): viewClientPermission is SpecificViewClientPermission {
  return viewClientPermission.kind === viewSpecificPermissionKind;
}

export function isPodViewClientPermission(
  viewClientPermission: ViewClientPermission,
): viewClientPermission is PodViewClientPermission {
  return viewClientPermission.kind === viewPodPermissionKind;
}

export function createInternalUserType(
  viewClientPermission: ViewClientPermission,
): InternalUserType {
  return { kind: internalKind, viewClientPermission };
}

export function createExternalUserType(): ExternalUserType {
  return { kind: externalKind, viewClientPermission: { kind: 'SINGLE', clientId: null } };
}

export function createAllViewClientPermission(): AllViewClientPermission {
  return { kind: viewAllPermissionKind };
}

export function createSpecificViewClientPermission(
  clientIdWhitelist: number[],
): SpecificViewClientPermission {
  return { kind: viewSpecificPermissionKind, clientIdWhitelist };
}

export function createPodViewClientPermission(podIdWhitelist: number[]): PodViewClientPermission {
  return { kind: viewPodPermissionKind, podIdWhitelist };
}

export type CreateUserForm = {
  firstName: string;
  lastName: string;
  userEmail: string;
  userType: UserType;
  sendInvite: boolean;
};
