/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { FunctionComponent } from 'react';
import ToolTip from '../../../ui/ToolTip';
import colors from '../../../../styles/colors';
import * as icons from '../../../ui/icons';
import { wrapStyles, tooltipStyles } from '../LoanErrors/css_index';
import Icon, { IconType } from '../../../ui/Icon';

const deleteMessage: CSSObject = {
  bottom: -20,
  right: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
};

const overrideWrapStyles: CSSObject = {
  cursor: 'pointer',
  backgroundColor: colors.blue,
  svg: { color: colors.white },
  '&:hover': { backgroundColor: colors.blueDark },
};

const disabledStyles: CSSObject = {
  backgroundColor: colors.grayExLight,
  pointerEvents: 'none',
  svg: { color: colors.white },
};

type ActionsBarProps = {
  errorMessage?: string;
  successMessage?: string;
  styles?: CSSObject;
  [key: string]: any;
};

const ActionsBar: FunctionComponent<ActionsBarProps> = ({
  children,
  errorMessage,
  successMessage,
  styles,
  ...props
}) => {
  return (
    <div css={{ position: 'relative' }}>
      <div {...props} css={styles}>
        {children}
      </div>
      {errorMessage && <span css={[deleteMessage, { color: colors.red }]}>{errorMessage}</span>}
      {successMessage && (
        <span css={[deleteMessage, { color: colors.blue }]}>{successMessage}</span>
      )}
    </div>
  );
};

export default ActionsBar;

type ActionProps = {
  text: string;
  icon: IconType;
  onClick: Function;
  styles?: CSSObject;
  disabled?: boolean;
  [key: string]: any;
};

export function Action({ text, icon, styles, disabled, ...props }: ActionProps) {
  return (
    <ToolTip
      toolTipText={text}
      wrapOverrides={[wrapStyles, overrideWrapStyles, styles, disabled && disabledStyles]}
      toolTipOverrides={tooltipStyles}
    >
      <Icon icon={icon} {...props} />
    </ToolTip>
  );
}
