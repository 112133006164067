/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Filter } from './types';
import RadioFilter from './RadioFilter';
import produce from 'immer';

type Props = {
  filters: Filter[];
  updateRadioFilters: (filtersForFilterType: Filter[]) => void;
};

const RadioFilters = ({ filters, updateRadioFilters }: Props) => {
  const setFilter = (updatedFilter: Filter) => {
    const newRadioFilters = produce(filters, (draft: Filter[]) => {
      const updatedIndex = filters.findIndex(
        (filter: Filter) => filter.label === updatedFilter.label,
      );
      if (updatedIndex !== -1) {
        draft[updatedIndex] = updatedFilter;
      }
    });

    updateRadioFilters(newRadioFilters);
  };

  return (
    <Fragment>
      {filters.map((filter: Filter) => (
        <RadioFilter key={filter.label} filter={filter} setFilter={setFilter} />
      ))}
    </Fragment>
  );
};

export default RadioFilters;
