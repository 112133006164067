/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { match } from 'react-router';
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CreateIcon from '@mui/icons-material/Create';

import { apiFetch, apiPost } from '../adalConfig';
import { AuthContext } from '../components/AuthContext';
import Header from '../components/ui/Header';
import TextInput from '../components/ui/Inputs/TextInput';
import { RightCaretLightIcon } from '../components/ui/icons';
import { formatPhoneWithParenthesis, isClientAdmin, isDataAssociation, StripPhone } from '../Utils';
import states from '../states';
import Dropdown from '../components/ui/Dropdown';
import { UnlinkAliasDialog } from '../components/UnlinkAliasDialog';
import Panel, { PanelHeader } from '../components/ui/Panel';
import UndoAssociationResultPanel from '../components/UndoAssociationResultPanel';
import { Loan } from '../globalTypes/objects';
import { DocumentOldWithShipment } from '../components/Admin/types';
import { useToaster } from '../Hooks/toasters';
import DataTable from '../components/ui/DataTable/DataTable';
import Modal from '@mui/material/Modal';
import { useQuery } from '@tanstack/react-query';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    grid: {
      maxWidth: 1200,
      margin: 32,
      marginTop: 84,
    },
    paper: {
      padding: theme.spacing(3),
      border: '1px solid #EEEEEE',
      '& h2': { fontSize: 24, fontWeight: 600 },
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const headerStyles = {
  display: 'flex',
  alignItems: 'center',
  div: { display: 'flex', alignItems: 'center', fontSize: 20 },
  '> div:first-of-type': {
    fontWeight: 400,
    paddingRight: 12,
  },
  svg: { marginRight: 12, marginTop: 2 },
};

type Props = {
  match: match<{ investorId: string }>;
};

type InvestorAssociation = {
  id: number;
  name: string;
  clientName: string;
  servicer?: string;
  createdAt?: string;
};

export type InvestorData = {
  id: number;
  name: string;
  contact: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  mailCode: string;
  requiresOriginalDoc: boolean;
  associations: InvestorAssociation[];
  lastDateSent?: string;
  daysAgedDeadline?: number;
  lateFee?: number;
};

export type AssociateModalData = {
  association: InvestorAssociation;
  isOpen: boolean;
};

type Result = {
  loan: Loan;
  documents: DocumentOldWithShipment[];
  notes: boolean;
};

type DigitalDeliverySettings = {
  client?: string;
  masterSetting: boolean;
  documentType?: string;
  onlyDigital?: boolean;
};

enum DigitalDeliverySettingsStatus {
  All,
  Some,
  None,
}

const initialState: InvestorData = {
  id: 0,
  name: '',
  contact: '',
  phone: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  mailCode: '',
  requiresOriginalDoc: false,
  associations: [],
  lastDateSent: undefined,
  daysAgedDeadline: undefined,
  lateFee: undefined,
};

const associationName = (associate: InvestorAssociation) => {
  let name = associate.name;
  if (associate.servicer) {
    name += ` ; ${associate.servicer}`;
  }

  if (associate.createdAt) {
    name += ` ; ${new Date(associate.createdAt).toLocaleDateString()}`;
  }

  return name;
};

const getDigitalDeliverySettings = async (
  investorId?: number,
): Promise<DigitalDeliverySettings[]> => {
  if (!investorId) {
    return [];
  }

  const { data } = await apiFetch<DigitalDeliverySettings[]>(
    `/api/digital-delivery-settings/investor-settings?investorId=${investorId}`,
  );

  return data;
};

const displayStatus = (
  digitalDeliveryStatus: DigitalDeliverySettingsStatus,
  isFetching: boolean,
) => {
  if (isFetching) {
    return <CircularProgress size={15} />;
  }

  if (digitalDeliveryStatus === DigitalDeliverySettingsStatus.All) {
    return <CheckIcon color="success" />;
  }

  if (digitalDeliveryStatus === DigitalDeliverySettingsStatus.None) {
    return <CloseIcon color="error" />;
  }

  if (digitalDeliveryStatus === DigitalDeliverySettingsStatus.Some) {
    return (
      <Tooltip title="Click to view settings">
        <InfoIcon />
      </Tooltip>
    );
  }
};
const Investor = ({ match }: Props) => {
  const authContext = useContext(AuthContext);
  const [investorData, setInvestorData] = useState<InvestorData>(initialState);
  const [originalInvestorData, setOriginalInvestorData] = useState<InvestorData>(initialState);
  const [canEditInvestorData, setCanEditInvestorData] = useState(false);
  const [associateModalData, setAssociateModalData] = useState<AssociateModalData>({
    association: {} as InvestorAssociation,
    isOpen: false,
  });
  const [undoAssociationResult, setUndoAssociationResult] = useState<Result[] | string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isDigitalDeliveryModalOpen, setIsDigitalDeliveryModalOpen] = useState(false);

  const { data: digitalDeliverySettings, isFetching: isFetchingDigitalDeliverySettings } = useQuery(
    {
      queryKey: ['digital-delivery-settings', match.params.investorId],
      queryFn: () => getDigitalDeliverySettings(+match.params.investorId),
      refetchOnWindowFocus: false,
    },
  );

  const digitalDeliveryStatus = useMemo(() => {
    if (!digitalDeliverySettings || digitalDeliverySettings.length === 0) {
      return DigitalDeliverySettingsStatus.None;
    }

    if (digitalDeliverySettings.length > 1) {
      return DigitalDeliverySettingsStatus.Some;
    }

    const { client, documentType, masterSetting, onlyDigital } = digitalDeliverySettings[0];

    if (!masterSetting) {
      return DigitalDeliverySettingsStatus.None;
    }

    if (client || documentType || onlyDigital) {
      return DigitalDeliverySettingsStatus.Some;
    }

    return DigitalDeliverySettingsStatus.All;
  }, [digitalDeliverySettings]);

  const { successToaster, errorToaster } = useToaster();

  const classes = useStyles();

  useEffect(() => {
    const getInvestorData = async () => {
      try {
        const { data } = await apiFetch<InvestorData>(
          `/api/investors/${match.params.investorId}/GetWithAssociations`,
        );

        setInvestorData({
          id: data.id,
          name: data.name,
          contact: data.contact ?? '',
          address: data.address ?? '',
          city: data.city ?? '',
          state: data.state ?? '',
          zip: data.zip ?? '',
          mailCode: data.mailCode ?? '',
          requiresOriginalDoc: data.requiresOriginalDoc,
          email: data.email ?? '',
          phone: data.phone ?? '',
          associations: data.associations || [],
          lastDateSent: data.lastDateSent,
          daysAgedDeadline: data.daysAgedDeadline,
          lateFee: data.lateFee,
        });
      } catch (e) {
        if (e.response) {
          const errorMessage = await new Response(e.response.data).text();
          errorToaster(errorMessage);
        } else {
          errorToaster(e.message);
        }
      }
    };
    getInvestorData();
  }, [match.params.investorId]);

  const unlinkAssociation = async (url: string) => {
    setIsLoading(true);
    setAssociateModalData({ ...associateModalData, ...{ isOpen: false } });

    try {
      const { data: result } = await apiPost(url, { int: associateModalData.association.id });

      const { data: investorAssociations } = await apiFetch(
        `/api/investors/${investorData.id}/Associations`,
      );

      setUndoAssociationResult(result);
      setInvestorData({ ...investorData, ...{ associations: investorAssociations } });
      successToaster('Successfully unlinked association');
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => setAssociateModalData({ ...associateModalData, ...{ isOpen: false } });

  const updateInvestor = async () => {
    try {
      const { data: response } = await apiPost('/api/investors/update', investorData);

      if (response === 1) {
        successToaster('Successfully updated investor info');
        setCanEditInvestorData(false);
        setOriginalInvestorData(investorData);
      } else {
        errorToaster('Failed to update investor info');
      }
    } catch (e) {
      errorToaster(e.message);
    }
  };

  return (
    <Fragment>
      <Header styles={headerStyles} headerText="Investors" fixed>
        <RightCaretLightIcon /> {investorData.name}
      </Header>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={8}>
          <Paper className={classes.paper} elevation={0}>
            <span css={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 css={{ paddingBottom: 24 }}>Information</h2>
              {isClientAdmin(authContext.roles) ? (
                <CreateIcon
                  color="action"
                  onClick={() => {
                    setOriginalInvestorData(investorData);
                    setCanEditInvestorData(true);
                  }}
                />
              ) : null}
            </span>
            <div className="mb3">
              <TextInput
                label="Phone Number"
                value={formatPhoneWithParenthesis(investorData.phone)}
                disabled={!canEditInvestorData}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestorData({ ...investorData, ...{ phone: StripPhone(e.target.value) } })
                }
                labelOverrides={{ marginTop: 16 }}
              />
              <TextInput
                label="Email"
                value={investorData.email}
                disabled={!canEditInvestorData}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestorData({ ...investorData, ...{ email: e.target.value } })
                }
                labelOverrides={{ marginTop: 16 }}
              />
              <TextInput
                label="Street"
                value={investorData.address}
                disabled={!canEditInvestorData}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestorData({ ...investorData, ...{ address: e.target.value } })
                }
                labelOverrides={{ marginTop: 16 }}
              />
              <TextInput
                label=""
                value={investorData.mailCode}
                disabled={!canEditInvestorData}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestorData({ ...investorData, ...{ mailCode: e.target.value } })
                }
                placeholder="Attention, suite, unit, building, floor, etc."
                labelOverrides={{ marginTop: 16 }}
              />
              <TextInput
                label="City"
                value={investorData.city}
                disabled={!canEditInvestorData}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setInvestorData({ ...investorData, ...{ city: e.target.value } })
                }
                labelOverrides={{ marginTop: 16 }}
              />
              <div
                className="mb2"
                css={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 56 }}
              >
                {!canEditInvestorData && (
                  <TextInput
                    label="State"
                    value={investorData.state}
                    disabled={true}
                    labelOverrides={{ marginTop: 16 }}
                  />
                )}
                {canEditInvestorData && (
                  <div>
                    <div css={{ marginTop: 16, marginBottom: 8 }}>State</div>
                    <Dropdown
                      options={Object.entries(states).map(([k, v]) => ({
                        label: v,
                        value: k,
                      }))}
                      {...(investorData.state && {
                        value: { label: states[investorData.state], value: investorData.state },
                      })}
                      placeholder="Select State"
                      onChange={selection =>
                        setInvestorData({ ...investorData, ...{ state: selection.value } })
                      }
                    />
                  </div>
                )}
                <TextInput
                  label="Zip"
                  value={investorData.zip}
                  disabled={!canEditInvestorData}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInvestorData({ ...investorData, ...{ zip: e.target.value } })
                  }
                  labelOverrides={{ marginTop: 16 }}
                />
              </div>
              <div className="df aic" style={{ gap: 56 }}>
                <FormControlLabel
                  className="full-width"
                  label="Requires original document"
                  control={
                    <Checkbox
                      color="primary"
                      checked={investorData.requiresOriginalDoc}
                      disabled={!canEditInvestorData}
                      onClick={() =>
                        setInvestorData({
                          ...investorData,
                          requiresOriginalDoc: !investorData.requiresOriginalDoc,
                        })
                      }
                    />
                  }
                />
                <div className="full-width">
                  <h3>Last Date Sent</h3>
                  <div>
                    {investorData.lastDateSent &&
                      new Date(investorData.lastDateSent).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: 26 }}>
                <div>
                  <TextInput
                    type="number"
                    label="Deadline in days from date funded"
                    disabled={!canEditInvestorData}
                    value={investorData?.daysAgedDeadline ?? ''}
                    onChange={e => {
                      const numericValue = parseInt(e.target.value);
                      setInvestorData(prev => ({
                        ...prev,
                        daysAgedDeadline:
                          isNaN(numericValue) || numericValue < 0 ? undefined : numericValue,
                      }));
                    }}
                  />
                </div>
                <div>
                  <TextInput
                    type="number"
                    label="Late Fee"
                    disabled={!canEditInvestorData}
                    value={investorData?.lateFee ?? ''}
                    onChange={e => {
                      const numericValue = parseInt(e.target.value);
                      setInvestorData(prev => ({
                        ...prev,
                        lateFee: isNaN(numericValue) || numericValue < 0 ? undefined : numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="df">
                  <div className="df aic" style={{ gap: 6 }}>
                    <div>Digital delivery</div>
                    <div
                      onClick={() =>
                        digitalDeliveryStatus === DigitalDeliverySettingsStatus.Some &&
                        setIsDigitalDeliveryModalOpen(true)
                      }
                    >
                      {displayStatus(digitalDeliveryStatus, isFetchingDigitalDeliverySettings)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {canEditInvestorData ? (
              <div className="df jcfe mb3">
                <Button
                  onClick={() => {
                    setCanEditInvestorData(false);
                    setInvestorData(originalInvestorData);
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={updateInvestor} color="primary" variant="contained">
                  Update
                </Button>
              </div>
            ) : null}
            {isDataAssociation(authContext.roles) && (
              <Fragment>
                <Divider />
                <h2 className="mb2 mt5">Aliases</h2>
                {!investorData.associations.length ? (
                  <Typography variant="body2" component="p" className="fs16 bold">
                    No Aliases
                  </Typography>
                ) : (
                  <div>
                    {investorData.associations.map(associate => (
                      <div
                        key={associate.id}
                        css={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr 46px',
                          gap: '16px',
                          marginTop: 20,
                        }}
                      >
                        <span>{associationName(associate)}</span>
                        <span>{associate.clientName}</span>
                        <div
                          css={{
                            fontWeight: 500,
                            fontSize: 16,
                            cursor: 'pointer',
                            color: '#F73378',
                          }}
                          onClick={() =>
                            setAssociateModalData({ association: associate, isOpen: true })
                          }
                        >
                          Unlink
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Fragment>
            )}
          </Paper>
        </Grid>
        {undoAssociationResult && undoAssociationResult.length !== 0 && (
          <Grid item xs={6}>
            <Paper className={classes.paper} elevation={0}>
              {typeof undoAssociationResult !== 'string' ? (
                <Panel>
                  <PanelHeader text="Association undone for the following:" />
                  {undoAssociationResult.map(r => (
                    <UndoAssociationResultPanel
                      key={r.loan.loanNumber}
                      loanNumber={r.loan.loanNumber}
                      documents={r.documents}
                      notes={r.notes}
                    />
                  ))}
                </Panel>
              ) : (
                <div>{undoAssociationResult}</div>
              )}
            </Paper>
          </Grid>
        )}
      </Grid>
      <UnlinkAliasDialog
        type="Investor"
        associateModalData={associateModalData}
        data={investorData}
        onClick={unlinkAssociation}
        onClose={closeModal}
      />
      {isLoading && (
        <CircularProgress css={{ position: 'fixed', left: '50%', top: '50%' }} size={50} />
      )}

      <Modal open={isDigitalDeliveryModalOpen} onClose={() => setIsDigitalDeliveryModalOpen(false)}>
        <div className={classes.modal}>
          <DataTable<DigitalDeliverySettings>
            title={`${investorData.name} digital delivery settings`}
            columns={[
              {
                field: 'client',
                title: 'Client',
                render: ({ client }: DigitalDeliverySettings) => client || 'All Clients',
              },
              {
                field: 'documentType',
                title: 'Document Type',
                render: ({ documentType }: DigitalDeliverySettings) => documentType || 'All',
              },
              {
                field: 'onlyDigital',
                title: 'Only digital',
                render: ({ onlyDigital }: DigitalDeliverySettings) =>
                  onlyDigital ? <CheckIcon color="success" /> : null,
              },
            ]}
            data={digitalDeliverySettings}
            options={{
              exportFileName: `${investorData.name} digital delivery settings`,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Investor;
