/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import colors from '../styles/colors';
import Circle from './ui/Circle';
import { IconType } from './ui/Icon';
import { format } from 'date-fns';
import { DocumentOldWithShipment } from './Admin/types';

const resultsWrap = {
  borderRadius: 4,
  marginTop: 24,
  border: `2px solid ${colors.grayLight}`,
};

const loanNumberWrap = {
  alignItems: 'center',
  display: 'flex',
  fontSize: 16,
  height: 64,
  paddingLeft: 16,
};

const loanDeletedWrap = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  color: colors.blue,
  display: 'flex',
  height: 64,
  paddingLeft: 16,
  textTransform: 'uppercase',
  svg: { color: colors.blue },
};

const rowWrap = {
  padding: 8,
  span: {
    width: '37%',
    display: 'inline-block',
    textTransform: 'capitalize',
    ':first-of-type': { width: '33%' },
    ':last-child': { width: '30%' },
  },
  ':nth-of-type(odd)': { backgroundColor: colors.grayExLight, borderRadius: 4 },
};

type ResultPanelProps = {
  loanNumber: string;
  documents: DocumentOldWithShipment[];
  notes: boolean;
};

export default function UndoAssociationResultPanel({
  loanNumber,
  documents,
  notes,
}: ResultPanelProps) {
  return (
    <div css={resultsWrap}>
      <div css={loanNumberWrap}>Loan Number: {loanNumber}</div>
      {documents.length !== 0 && (
        <Fragment>
          <div css={[resultsWrap, { margin: 16 }]}>
            <div css={loanDeletedWrap}>
              <Circle small icon={IconType.Docs} styleoverrides={{ marginRight: 12 }} /> Docs added
            </div>
            <div css={{ padding: 8, paddingTop: 16 }}>
              <div css={[rowWrap, { fontWeight: 700 }]}>
                <span>Doc Type</span>
                <span>Date Uploaded</span>
                <span>Date Sent</span>
              </div>
              {documents.map(d => (
                <div css={rowWrap} key={d.id}>
                  <span>{d.docType}</span>
                  <span>{!!d.dateUploaded && format(new Date(d.dateUploaded), 'MM/dd/yyyy')}</span>
                  <span>{!!d.dateSent && format(new Date(d.dateSent), 'MM/dd/yyyy')} </span>
                </div>
              ))}
              {notes && (
                <div css={loanDeletedWrap}>
                  <Circle small icon={IconType.Note} styleoverrides={{ marginRight: 12 }} /> Notes
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}
