import React from 'react';
import debounce from 'lodash/debounce';
import { apiFetch } from '../../adalConfig';
import SearchSelect from '../ui/SearchSelect';
import { TitleCompany } from '../../globalTypes/objects';

const getTitleOption = (titleCompany: TitleCompany): SelectOption<TitleCompany> => ({
  label: titleCompany.name,
  subLabel: titleCompany.address,
  value: titleCompany,
});

const searchTitleCompanies = async (partialString: string) => {
  const { data } = await apiFetch<TitleCompany[]>(
    `/api/titleCompanies/searchTitleCompanies?searchString=${partialString}`,
  );
  return data.map(titleCompany => getTitleOption(titleCompany));
};

const loadTitleOptions = debounce((input: string, callback) => {
  searchTitleCompanies(input).then(callback);
}, 800);

type TitleProps = { onChange: (titleCompany: TitleCompany) => void; selected?: TitleCompany | '' };

export default function TitleCompanySearchSelect({ onChange, selected, ...props }: TitleProps) {
  const defaultVal = selected && getTitleOption(selected);
  return (
    <SearchSelect
      placeholder="Search Title Companies"
      loadOptions={loadTitleOptions}
      onChange={(selection: SelectOption<TitleCompany>) => onChange(selection.value)}
      {...(selected && { defaultValue: defaultVal })}
      isAsync
      {...props}
    />
    //   filterConfig={{ matchFrom: 'all' }}
  );
}
