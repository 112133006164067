/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, TextField } from '@mui/material';
import AlertDialogSlide from './DocumentDeleteAlert';
import { getToken } from '../../auth-config';

interface PropTypes {
  docId: number;
  disabled: boolean;
  color: 'inherit' | 'primary' | 'error' | 'default' | undefined;
  onConfirm: () => void;
  onIrrelevantDocConfirm: (string) => void;
  showDelete: boolean;
}

function authHeader(token) {
  return { Authorization: `Bearer ${token}` };
}

async function getHasSourceEmail(docId): Promise<boolean> {
  const authToken = await getToken();
  const res = await fetch(`/Api/DocumentEmail/HasDocumentEmail/${docId}`, {
    headers: authHeader(authToken),
  });
  return res.json();
}

export default function ReturnToInbox({
  docId,
  disabled,
  color,
  onConfirm,
  onIrrelevantDocConfirm,
  showDelete,
}: PropTypes) {
  const [hasEmail, setHasEmail] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [confirmIrrelevantDoc, setConfirmIrrelevantDoc] = useState<boolean>(false);
  const [docType, setDocType] = React.useState('');
  const [otherDocType, setOtherDocType] = React.useState('');

  useEffect(() => {
    getHasSourceEmail(docId).then(email => {
      setHasEmail(email);
    });
  }, [docId]);

  const options = [
    { label: 'Plat Map' },
    { label: 'Cover Letter' },
    { label: 'Invoice' },
    { label: 'Closing Disclosure' },
    { label: 'Other' },
  ];

  const nonEmailOptions = [{ label: 'Owners Policy' }];

  return (
    <div>
      {hasEmail ? (
        <div
          style={{ display: 'flex', justifyContent: 'space-between', gap: 10, marginTop: '16px0' }}
        >
          <Button
            variant="outlined"
            size="small"
            color={color}
            disabled={disabled}
            onClick={() => setConfirm(true)}
          >
            Return To Client Inbox
          </Button>
          <Button
            variant="outlined"
            size="small"
            color={color}
            disabled={disabled}
            onClick={() => setConfirmIrrelevantDoc(true)}
          >
            Irrelevant Doc
          </Button>
          <AlertDialogSlide
            isOpen={confirm}
            handleClose={() => setConfirm(false)}
            onConfirm={() => {
              onConfirm();
              setConfirm(false);
            }}
            disableConfirm={false}
            confirmationHeader="Return Document To Inbox"
            confirmationText="Are you sure you want to return to the client inbox? This will delete the document"
          />
          <AlertDialogSlide
            isOpen={confirmIrrelevantDoc}
            handleClose={() => setConfirmIrrelevantDoc(false)}
            onConfirm={() => {
              onIrrelevantDocConfirm(docType !== 'Other' ? docType : otherDocType);
              setConfirmIrrelevantDoc(false);
            }}
            disableConfirm={docType === '' || (docType === 'Other' && otherDocType === '')}
            confirmationHeader="Delete Irrelevant Doc"
            confirmationText="Are you sure you want to mark this document as irrelevant? This will delete the document."
          >
            <div>
              <Autocomplete
                disablePortal
                options={options}
                style={{ marginTop: 10 }}
                onChange={(event, newValue) => {
                  setDocType(newValue ? newValue.label : '');
                }}
                getOptionLabel={option => option.label}
                renderInput={params => <TextField {...params} label="Document Type" />}
              />
              <div style={{ height: 96, marginTop: 10 }}>
                {docType === 'Other' && (
                  <TextField
                    variant="standard"
                    fullWidth
                    label="Input Document Type"
                    value={otherDocType}
                    onChange={e => {
                      setOtherDocType(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>
          </AlertDialogSlide>
        </div>
      ) : (
        showDelete && (
          <div>
            <Button
              variant="outlined"
              size="medium"
              color={color}
              disabled={disabled}
              onClick={() => setConfirmIrrelevantDoc(true)}
            >
              Delete Document
            </Button>
            <AlertDialogSlide
              isOpen={confirmIrrelevantDoc}
              handleClose={() => setConfirmIrrelevantDoc(false)}
              onConfirm={() => {
                onIrrelevantDocConfirm(docType !== 'Other' ? docType : otherDocType);
                setConfirmIrrelevantDoc(false);
              }}
              disableConfirm={docType === '' || (docType === 'Other' && otherDocType === '')}
              confirmationHeader="Delete Irrelevant Doc"
              confirmationText="Are you sure you want to delete this document and mark it as irrelevant? This will delete the document."
            >
              <div>
                <Autocomplete
                  disablePortal
                  options={[...nonEmailOptions, ...options]}
                  style={{ marginTop: 20 }}
                  onChange={(event, newValue) => {
                    setDocType(newValue ? newValue.label : '');
                  }}
                  getOptionLabel={option => option.label}
                  renderInput={params => <TextField {...params} label="Document Type" />}
                />
                <div style={{ height: 50, marginTop: 10 }}>
                  {docType === 'Other' && (
                    <TextField
                      variant="standard"
                      fullWidth
                      label="Input Document Type"
                      value={otherDocType}
                      onChange={e => {
                        setOtherDocType(e.target.value);
                      }}
                    />
                  )}
                </div>
              </div>
            </AlertDialogSlide>
          </div>
        )
      )}
    </div>
  );
}
