/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useMemo, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import UIfx from 'uifx';
import BarcodeInput from '../components/Utilities/BarcodeInput';
import Header from '../components/ui/Header';
import { PanelHeader } from '../components/ui/Panel';
import { apiFetch } from '../adalConfig';
import { DocumentStatus } from './CheckUploadedDoc';
// @ts-ignore
import ErrorEffect from '../components/Documents/ErrorEffect.wav';
import { useToaster } from '../Hooks/toasters';
import createStyles from '@mui/styles/createStyles';
import StatusDisplay from '../components/Documents/Staple/StatusDisplay';
import { useClients } from '../Hooks/useClients';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-block',
      width: '795px',
      borderRadius: '3px',
      border: 'solid 1px #e6e4e8',
      backgroundColor: '#fffeff',
    },
    barcodePanel: {
      height: '180px',
      padding: '19px 73px',
    },
    messageContainer: {
      height: '140px',
      padding: '35px 38px',
    },
  }),
);

const errorSound = new UIfx({
  asset: ErrorEffect,
});

const Staple = () => {
  const [documentStatus, setDocumentStatus] = useState<DocumentStatus>({} as DocumentStatus);

  const clients = useClients();
  const { errorToaster } = useToaster();
  const classes = useStyles();

  const clientName = useMemo(
    () => clients.find(client => client.id === documentStatus.clientId)?.company,
    [documentStatus],
  );

  const handleDocumentIdChange = async (documentId: string) => {
    setDocumentStatus({} as DocumentStatus);

    if (!documentId) {
      return;
    }

    try {
      const { data } = await apiFetch<DocumentStatus>(`/api/documents/staple-scan`, {
        params: { documentId },
      });
      setDocumentStatus(data);

      if (['AlertBanner', 'X'].includes(data.userAlertType)) {
        errorSound.play();
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return (
    <Fragment>
      <div style={{ textAlign: 'center', alignContent: 'center' }}>
        <Header headerText="Documents" />
        <div className="relative">
          <div>
            <div style={{ marginTop: 16 }}>
              <div className={`${classes.container} ${classes.barcodePanel}`}>
                <PanelHeader text="Check uploaded document" />
                <BarcodeInput onChange={handleDocumentIdChange} />
              </div>
            </div>

            <div className={`${classes.container} ${classes.messageContainer}`}>
              <StatusDisplay documentStatus={documentStatus} />
            </div>
            {!!documentStatus.additionalInformation && (
              <div className="fs18 mt2">{documentStatus.additionalInformation}</div>
            )}
            {!!clientName && <div className="fs18 mt2">Client: {clientName}</div>}
            {documentStatus.pageCount > 0 && (
              <div className="fs18 mt2">
                Document contains {documentStatus.pageCount} page
                {documentStatus.pageCount > 1 ? 's' : ''}.
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Staple;
