/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useCallback, useEffect, useState } from 'react';
import ShortId from 'shortid';
import { Link } from 'react-router-dom';
import { boxWrap } from './clientDocumentIndex';
import NoData from '../../ui/NoData';
import { IconType } from '../../ui/Icon';
import Button from '../../ui/Button';
import DocumentTile from './DocumentTile';
import { useDocIndex } from './useDocIndex';
import { getDocTypes } from '../../../Utils';
import CircularProgress from '@mui/material/CircularProgress';
import { DocumentTypeProperties } from '../../../DocumentTypesContext';

type RenderDocsProps = {
  noDataType?: NoDataType;
  sortNewest: boolean;
  refreshData: () => void;
};

export enum NoDataType {
  NoDocs = 1,
  NoMatches = 2,
}

export default function RenderDocs({ noDataType, sortNewest, refreshData }: RenderDocsProps) {
  const [indexData, setIndexData] = useDocIndex();
  const { displayedDocs, isBusy } = indexData;
  const [docTypes, setDocTypes] = useState<DocumentTypeProperties[]>([]);

  useEffect(() => {
    getDocTypes().then(setDocTypes);
  }, []);

  const sortFunc = useCallback(
    (a, b) => {
      const first = sortNewest ? b : a;
      const second = sortNewest ? a : b;
      return (
        new Date(first.document.createdAt).valueOf() - new Date(second.document.createdAt).valueOf()
      );
    },
    [sortNewest],
  );

  return (
    <div>
      {isBusy && (
        <div className="center-in-parent" style={{ height: '40px', width: '80px' }}>
          <CircularProgress size="20" disableShrink />
        </div>
      )}
      <div css={boxWrap}>
        {noDataType ? (
          <EmptyDisplay noDataType={noDataType} client={indexData.currentClient.company} />
        ) : (
          displayedDocs
            .sort(sortFunc)
            .map(d => (
              <DocumentTile
                key={ShortId.generate()}
                data={d}
                docTypes={docTypes}
                refreshData={refreshData}
              />
            ))
        )}
      </div>
    </div>
  );
}

function EmptyDisplay({ noDataType, client }: { noDataType: NoDataType; client: string }) {
  return noDataType === NoDataType.NoDocs ? (
    <NoData
      message={`There are no documents for ${client}`}
      subMessage="Please return when more documents are uploaded"
    >
      <Link to="/documents">
        <Button>go back</Button>
      </Link>
    </NoData>
  ) : (
    <NoData
      message="There are no documents matching your search"
      subMessage="Please edit your search filters"
      icon={IconType.NoResults}
    />
  );
}
