/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import FileDropper from '../components/ui/FileDropper';
import { apiFetch, apiPost } from '../adalConfig';
import Dropdown from '../components/ui/Dropdown';
import Modal from '../components/ui/Modal';
import { useGetData } from '../Hooks';
import TextInput from '../components/ui/Inputs/TextInput';
import Header from '../components/ui/Header';
import StatusButton, { StatusTypes } from '../components/ui/StatusButton';
import { AuthContext } from '../components/AuthContext';
import Checkbox from '../components/ui/Checkbox';
import Grid from '@mui/material/Grid';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { useToaster } from '../Hooks/toasters';
import { fileToBase64 } from '../Utils';

const btn: CSSObject = {
  backgroundColor: '#007BFF',
  borderRadius: '4px',
  boxShadow:
    '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  color: '#FFF',
  display: 'inline-block',
  padding: '.375rem .75rem',
  transition:
    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    backgroundColor: 'rgb(17, 82, 147)',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
};

type Client = {
  id: number;
  company: string;
};

type UploadData = {
  userName: string;
  date?: Date | null;
  file?: File;
};

type GenerateSpreadsheetData = {
  client?: Client;
  allDates?: boolean;
  fromDate?: Date | null;
  toDate?: Date | null;
};

const ImportNotesSpreadsheet = () => {
  const authContext = useContext(AuthContext);

  const [spreadsheetUploadData, setSpreadsheetUploadData] = useState<UploadData>({
    userName: authContext.user?.mail?.split('@')[0].toLowerCase() ?? '',
    date: undefined,
    file: undefined,
  });
  const [generateSpreadsheetData, setGenerateSpreadsheetData] = useState<GenerateSpreadsheetData>({
    client: undefined,
    allDates: false,
    fromDate: undefined,
    toDate: undefined,
  });
  const [loadingStatus, setLoadingStatus] = useState(StatusTypes.initial);
  const [modalLoadingStatus, setModalLoadingStatus] = useState(StatusTypes.initial);
  const [modalOpenStatus, setModalOpenStatus] = useState(false);

  const { successToaster, errorToaster, infoToaster } = useToaster();

  useEffect(() => {
    setLoadingStatus(StatusTypes.initial);
  }, [spreadsheetUploadData]);

  useEffect(() => {
    setModalLoadingStatus(StatusTypes.initial);
  }, [generateSpreadsheetData]);

  const { data: clients } = useGetData<Client[]>('/api/clients/getAll', []);

  const isInitialStateDefined = state => {
    if (state === generateSpreadsheetData) {
      const { client, allDates, fromDate, toDate } = generateSpreadsheetData;
      const hasDates = allDates || (fromDate && toDate);

      return client && hasDates;
    }

    return Object.keys(state).every(prop => state[prop]);
  };

  const formatDate = (dateTime: Date | null) =>
    dateTime && dateTime.getFullYear() + '-' + (dateTime.getMonth() + 1) + '-' + dateTime.getDate();

  const onSubmit = async () => {
    if (!isInitialStateDefined(spreadsheetUploadData)) {
      errorToaster('Please select a date and file');
      return;
    }

    if (spreadsheetUploadData.file === undefined) {
      setLoadingStatus(StatusTypes.error);
      errorToaster('File is not attached');
      return;
    }

    setLoadingStatus(StatusTypes.loading);

    const base64File = await fileToBase64(spreadsheetUploadData.file);

    const postData = {
      userName: spreadsheetUploadData.userName,
      date: spreadsheetUploadData.date,
      base64File,
    };

    try {
      const { data } = await apiPost('/api/Notes/Import', postData);
      if (data.success) {
        setLoadingStatus(StatusTypes.success);
        successToaster(data.message);
      } else {
        setLoadingStatus(StatusTypes.error);
        errorToaster(data.error);
      }
    } catch (e) {
      setLoadingStatus(StatusTypes.error);
      errorToaster(e.message);
    }
  };

  const generateSpreadsheet = async () => {
    if (!isInitialStateDefined(generateSpreadsheetData)) {
      errorToaster('Please select a client and date funded range');
      return;
    }

    setModalLoadingStatus(StatusTypes.loading);
    const { client, allDates, fromDate, toDate } = generateSpreadsheetData;
    const queryData: any = { clientId: client!.id };
    if (!allDates && fromDate && toDate) {
      queryData.fromDate = formatDate(fromDate || null);
      queryData.toDate = formatDate(toDate || null);
    }

    download(
      '/api/notes/generate-spreadsheet',
      queryData,
      `${client!.company}_${new Date().toISOString()}.xlsx`,
      () => {
        setModalLoadingStatus(StatusTypes.success);
        setTimeout(() => setModalLoadingStatus(StatusTypes.initial), 1500);
      },
      () => setModalLoadingStatus(StatusTypes.error),
    );
  };

  const downloadAllUnresolvedReport = async (excludeLoansInPipeline: boolean) => {
    const filename = `Unresolved Loans - ${
      excludeLoansInPipeline ? 'Excluding' : 'Including'
    } Loans In Pipeline.xlsx`;
    download('/api/notes/outstanding-loans', { excludeLoansInPipeline }, filename);
  };

  const downloadUnresolvedWithoutRecentNotesReport = async () => {
    const filename = `UnresolvedLoansWithoutRecentNotes.xlsx`;
    download('/api/notes/outstanding-loans-without-recent-notes', {}, filename);
  };

  const download = async (
    requestUrl: string,
    params: object,
    defaultFilename: string,
    onSuccess = () => {},
    onError = () => {},
  ) => {
    try {
      const { headers, data } = await apiFetch(requestUrl, { responseType: 'blob', params });

      if (data.type === 'application/json') {
        let textResponse = await data.text();
        infoToaster(textResponse.replace(/"/g, ''));
      } else {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;

        let fileName;
        const fileParts = headers['content-disposition'].match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        );
        if (fileParts.length >= 2) {
          fileName = fileParts[1].replace(/"/g, '');
        }

        a.download = fileName || defaultFilename;

        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      }

      onSuccess();
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }

      onError();
    }
  };

  return (
    <Fragment>
      <Header headerText="Outreach" fixed />
      <div css={{ padding: 40, marginTop: 64, maxWidth: 1200 }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setModalOpenStatus(!modalOpenStatus)}
            >
              Generate Report - By Client
            </Button>
          </Grid>
          <Grid item xs={6}>
            <ButtonGroup size="small" color="error" aria-label="outlined secondary button group">
              <Button onClick={() => downloadAllUnresolvedReport(true)}>
                All Unresolved Loans (Excluding loans in pipeline)
              </Button>
              <Button onClick={() => downloadAllUnresolvedReport(false)}>
                All Unresolved Loans (Including loans in pipeline)
              </Button>
              <Button onClick={downloadUnresolvedWithoutRecentNotesReport}>
                Unresolved Loans Without Recent Notes
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: '600px 180px 180px',
            alignItems: 'flex-end',
            marginTop: 16,
          }}
        >
          <div css={{ marginRight: 32 }}>
            <DatePicker
              customInput={<TextInput label="Date" />}
              selected={spreadsheetUploadData.date}
              onChange={value =>
                setSpreadsheetUploadData({ ...spreadsheetUploadData, ...{ date: value } })
              }
            />
          </div>
          <div>
            <TextInput
              type="text"
              label="User Name"
              value={spreadsheetUploadData.userName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSpreadsheetUploadData({
                  ...spreadsheetUploadData,
                  ...{ userName: e.target.value },
                })
              }
            />
          </div>
        </div>
        <FileDropper
          onChange={(file: File) =>
            setSpreadsheetUploadData({
              ...spreadsheetUploadData,
              ...{ file: file ? file : undefined },
            })
          }
          file={spreadsheetUploadData.file}
          acceptableFileTypes=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <div className="mt4 df jcfe">
          <StatusButton
            text="Submit"
            status={loadingStatus}
            disabled={!isInitialStateDefined(spreadsheetUploadData)}
            onClick={onSubmit}
          />
        </div>

        <div>
          {modalOpenStatus && (
            <Modal
              headerText="Generate Report For Loans With Missing Documents"
              handleSave={generateSpreadsheet}
              handleCancel={() => setModalOpenStatus(false)}
              saveDisabled={!isInitialStateDefined(generateSpreadsheetData)}
              saveButtonText="Export"
              status={modalLoadingStatus}
            >
              <div className="mt3 pointer">
                <Dropdown
                  options={clients.map((client: Client) => ({
                    label: client.company,
                    value: client.id,
                  }))}
                  isSearchable
                  placeholder="Select Client"
                  onChange={selected =>
                    setGenerateSpreadsheetData({
                      ...generateSpreadsheetData,
                      ...{ client: { id: selected.value, company: selected.label } },
                    })
                  }
                  css={{ backgroundColor: '#fff' }}
                />
              </div>
              <div className="border radius mt2 p2 mb3">
                <div className="mb1 bold allcaps fs16">Date Funded</div>
                <Checkbox
                  text="All Outstanding Documents"
                  labelOverrides={{ width: 200 }}
                  checked={generateSpreadsheetData.allDates}
                  onClick={() =>
                    setGenerateSpreadsheetData({
                      ...generateSpreadsheetData,
                      ...{ allDates: !generateSpreadsheetData.allDates },
                    })
                  }
                />
                {!generateSpreadsheetData.allDates && (
                  <div className="df jcsb mt1">
                    <DatePicker
                      customInput={<TextInput label="From: " />}
                      selected={generateSpreadsheetData.fromDate}
                      onChange={value =>
                        setGenerateSpreadsheetData({
                          ...generateSpreadsheetData,
                          ...{ fromDate: value },
                        })
                      }
                    />
                    <DatePicker
                      customInput={<TextInput label="Thru: " />}
                      selected={generateSpreadsheetData.toDate}
                      onChange={value =>
                        setGenerateSpreadsheetData({
                          ...generateSpreadsheetData,
                          ...{ toDate: value },
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ImportNotesSpreadsheet;
