import styled from '@emotion/styled';

export const linkS = {
  cursor: 'pointer',
  color: 'blue',
  textDecoration: 'underline',
  transition: 'all .2s',
  '&:hover': {
    color: '#0018ab',
    transition: 'all .2s',
  },
};

export const nameS = {
  color: '#47517B',
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: '1.1',
  marginRight: 16,
};

export const InfoRow = styled.div(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: '24px',
    gridRowGap: '24px',
    span: { wordBreak: 'break-word' },
  },
  props => ({ css: props.css }),
);

export const ItemLabel = styled.div(
  {
    color: '#47517B',
    display: 'flex',
    fontWeight: 'bold',
    marginBottom: 8,
    marginRight: 5,
  },
  ({ dark }) => ({
    color: dark && '#000',
  }),
);

export const InnerCard = styled.div({
  backgroundColor: '#f5f6f8',
  borderRadius: 5,
  color: '#000',
  padding: 24,
});

export const InnerCardHeader = styled.div`
  color: #00b8d4;
  font-size: 16px;
  font-weight: bold;
`;

export const ItemStatus = styled.div(
  { display: 'flex', alignItems: 'center', color: '#e5e5e5', marginRight: 24 },
  ({ active }) => ({
    '*': {
      color: active ? 'green' : '#e5e5e5',
    },
  }),
);

export const PrimaryContact = styled.div({
  background: '#E0F7FA',
  borderRadius: 5,
  height: 32,
  display: 'inline-flex',
  alignItems: 'center',
  padding: '0 16px',
  fontWeight: 600,
  color: '#00B8D4',
  fontSize: 12,
});

export const ActionButton = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  borderRadius: 5,
  backgroundColor: '#1F2B5E',
  cursor: 'pointer',
  svg: { color: '#fff' },
});

export const CopyText = styled.div(
  {
    backgroundColor: 'hsl(120, 100%, 93%)',
    border: '1px solid transparent',
    borderRadius: 5,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 8,
    opacity: 0,
    overflow: 'hidden',
    transition: 'all .13s',
    whiteSpace: 'nowrap',
    width: 0,
    position: 'absolute',
    left: 16,
    zIndex: 26,
    top: 0,
  },
  ({ copied }) =>
    copied && {
      border: '1px solid #00C642',
      padding: '4px 8px',
      opacity: 1,
      width: 130,
    },
);

export const CopyButton = styled.div`
  position: absolute;
  left: 121px;
  top: 1px;
  svg {
    max-width: 18px;
    height: 20px;
    cursor: pointer;
    :hover {
      g {
        fill: blue;
      }
    }
  }
`;
