/** @jsx jsx */
import { jsx } from '@emotion/core';
import { ChangeEvent, useMemo } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import { Filter, FilterOption } from '../../components/ui/DataTable/types';
import { apiFetch } from '../../adalConfig';
import DatePicker from 'react-datepicker';
import format from 'date-fns/format';
import debounce from 'lodash/debounce';
import { useQuery } from '@tanstack/react-query';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  onCustomOptionSelected: (filter: Filter, filterOption: FilterOption) => void;
  customFilters: Filter[];
  clearCustomFilter: (filter: Filter) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    margin: '10px 10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
}));

const getClientPortalEmails = async () => {
  const { data } = await apiFetch<string[]>('/api/client-portal-user-management/all-emails');
  return data;
};

const AnalyticsFilters = ({ onCustomOptionSelected, customFilters, clearCustomFilter }: Props) => {
  const { clientFilter, userFilter, datePickerFilter } = useMemo(
    () => ({
      clientFilter: customFilters.find(filter => filter.label === 'Client'),
      userFilter: customFilters.find(filter => filter.label === 'Email'),
      datePickerFilter: customFilters.find(filter => filter.label === 'Date'),
    }),
    [customFilters],
  );
  const { data: emails } = useQuery(['client-portal-emails'], getClientPortalEmails, {
    refetchOnWindowFocus: false,
  });
  const classes = useStyles();

  const activeClientFilterOption = clientFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const client = activeClientFilterOption
    ? { id: activeClientFilterOption.id as number, name: activeClientFilterOption.label }
    : null;

  const activeUserFilterOption = userFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const activeDatePickerFilterOption = datePickerFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const onInputChange = debounce(
    async (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, newValue: string) =>
      onCustomOptionSelected(userFilter!, {
        id: newValue,
        label: newValue,
        active: true,
      }),
    500,
  );

  return (
    <div className={classes.mainContainer}>
      {clientFilter && (
        <div className={classes.filterContainer}>
          <ClientSearch
            selectedClient={client?.id}
            onChange={({ id, company }) =>
              onCustomOptionSelected(clientFilter, { id, label: company, active: true })
            }
            styleOverrides={{ width: '100%' }}
          />
          <div>
            <Tooltip title="Clear client filter">
              <IconButton size="small" onClick={() => clearCustomFilter(clientFilter)}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      {userFilter && emails && (
        <div className={classes.filterContainer}>
          <div className="full-width">
            <Autocomplete
              options={emails}
              onChange={onInputChange}
              value={(activeUserFilterOption?.id as string) || null}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="User" fullWidth variant="outlined" />
              )}
            />
          </div>
          <div>
            <Tooltip title="Clear user filter">
              <IconButton size="small" onClick={() => clearCustomFilter(userFilter)}>
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}

      {datePickerFilter && (
        <div>
          <b>Select a date</b>
          <div>
            <DatePicker
              minDate={new Date(2000, 0, 1)}
              maxDate={new Date()}
              selected={
                activeDatePickerFilterOption?.id ? new Date(activeDatePickerFilterOption.id) : null
              }
              onChange={dateTime => {
                if (!dateTime) {
                  return onCustomOptionSelected(datePickerFilter, {} as FilterOption);
                }

                return onCustomOptionSelected(datePickerFilter, {
                  id: dateTime.toISOString(),
                  label: format(dateTime, 'M/d/yy'),
                  active: true,
                });
              }}
              isClearable
              placeholderText="Click to select"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyticsFilters;
