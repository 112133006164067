/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { Component } from 'react';
import colors from '../../../../styles/colors';

import { errorType } from '../ImportErrorsUtils';
import { CheckIcon } from '../../../ui/icons';
import { StripPhone, FormatPhoneWithSpaces } from '../../../../Utils';
import { ContactInformation } from '../../../../globalTypes/objects';

// #region CSS

const p: CSSObject = {
  margin: 0,
  padding: 0,
  display: 'block',
};

const title: CSSObject = {
  fontWeight: 'bold',
  position: 'relative',
  boxSizing: 'border-box',
  width: 315,
};

const addressCon: CSSObject = {
  marginTop: 16,
  height: 42,
  width: 315,
};

const contact: CSSObject = {
  position: 'relative',
  width: '100%',
  marginTop: 16,
};

const lineHeader: CSSObject = {
  color: colors.blue,
};

const emailField: CSSObject = {
  position: 'absolute',
  right: 0,
  bottom: 0,
};

function getCircleStyles({ isSelected, isHover }) {
  const circleStyles: CSSObject = {
    backgroundColor: 'white',
    border: isSelected ? 'none' : `1px solid ${colors.grayLight}`,
    borderRadius: '50%',
    display: 'inline-block',
    height: '48px',
    margin: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    transition: 'border .2s, background-color .2s',
    width: '48px',
    ' svg': { display: 'none' },
  };
  const selectedStyles = isSelected && {
    alignItems: 'center',
    backgroundColor: colors.blue,
    color: colors.white,
    display: 'inline-flex',
    justifyContent: 'center',
    ' svg': { display: 'block' },
  };
  const hoverStyles = !isSelected &&
    isHover && {
    border: `1px solid ${colors.blue}`,
  };
  return { ...circleStyles, ...selectedStyles, ...hoverStyles };
}

function getTileStyles({ isHover, isSelected, showCircle }) {
  const tileStyles: CSSObject = {
    backgroundColor: isSelected ? colors.white : colors.grayExLight,
    border: isSelected ? '1px solid #0828cc' : `1px solid${colors.grayLight}`,
    borderRadius: 4,
    marginBottom: 8,
    padding: '24px',
    width: '100%',
  };

  const hoverStyles: CSSObject = !isSelected &&
    (showCircle && isHover) && {
    border: `1px solid ${colors.blue}`,
    cursor: 'pointer',
  };
  return { ...tileStyles, ...hoverStyles };
}

// #endregion

type SuggestionTileProps = {
  contactInformation: ContactInformation;
  handleClick: () => any;
  isSelected?: boolean;
  isUnselectable?: boolean;
  showCircle?: boolean;
};
type SuggestionTileState = {
  isHover?: boolean;
};
export default class SuggestionTile extends Component<SuggestionTileProps, SuggestionTileState> {
  state = {
    isHover: false,
  };

  render() {
    const { isHover } = this.state;
    const { contactInformation, handleClick, isSelected, isUnselectable, showCircle } = this.props;
    const { name, address, city, state, zip, phone, fax, email } = contactInformation;
    const formattedPhone = phone && FormatPhoneWithSpaces(StripPhone(phone));
    const formattedFax = fax && FormatPhoneWithSpaces(StripPhone(fax));

    return (
      <div
        css={getTileStyles({ isSelected, isHover, showCircle })}
        onMouseOver={isUnselectable ? undefined : () => this.setState({ isHover: true })}
        onMouseOut={isUnselectable ? undefined : () => this.setState({ isHover: false })}
        onClick={isUnselectable ? undefined : handleClick}
        data-test="match match"
      >
        <div css={{ position: 'relative' }}>
          <div css={title}>
            {name && <p css={p}>{name}</p>}
            <p css={p}>
              {city && contactInformation.type === errorType.title ? `${city}, ` : null}
              {state}
            </p>
          </div>
          {showCircle && (
            <span>
              <p
                css={getCircleStyles({
                  isSelected,
                  isHover,
                })}
              >
                <CheckIcon />
              </p>
            </span>)}
        </div>
        {address ||
          city ||
          state ||
          (zip && (
            <div css={addressCon}>
              {address && <p css={p}>{address}</p>}
              <p css={p}>
                {city && `${city}, `}
                {state} {zip}
              </p>
            </div>
          ))}
        {(formattedPhone || formattedFax || email) && (
          <div css={contact}>
            {formattedPhone && (
              <p css={p}>
                <span css={lineHeader}>P&nbsp;&nbsp;</span>
                {formattedPhone}
              </p>
            )}
            {formattedFax && (
              <p css={p}>
                <span css={lineHeader}>F&nbsp;&nbsp;</span>
                {formattedFax}
              </p>
            )}
            {email && <div css={emailField}>{email}</div>}
          </div>
        )}
      </div>
    );
  }
}
