/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useContext } from 'react';
import { ClientData as RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import { AuthContext } from '../../components/AuthContext';
import { roleTypes } from '../../constants';

type Props = {
  clientData: RowData[];
};

const ClientData = ({ clientData }: Props) => {
  const { roles } = useContext(AuthContext);

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'company',
      title: 'Name',
    },
    {
      field: 'contactName',
      title: 'Contact Name',
    },
    {
      field: 'address',
      title: 'Address',
    },
    {
      field: 'city',
      title: 'City',
    },
    {
      field: 'state',
      title: 'State',
    },
    {
      field: 'zip',
      title: 'Zip',
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'phone',
      title: 'Phone',
    },
    {
      field: 'loanDataContactFirstName',
      title: 'Loan Data Contact',
      render: rowData => `${rowData.loanDataContactFirstName} ${rowData.loanDataContactLastName}`,
    },
    {
      field: 'loanDataContactEmail',
      title: 'Loan Data Contact Email',
    },
    {
      field: 'amrPodName',
      title: 'POD',
    },
    {
      field: 'accountRep',
      title: 'Account Manager',
    },
    {
      field: 'auditor',
      title: 'Auditor',
    },
    {
      field: 'startDate',
      title: 'Start Date',
      render: (rowData: RowData) =>
        rowData.startDate && new Date(rowData.startDate).toLocaleDateString(),
    },
    {
      field: 'baselineLoansPerMonth',
      title: 'Baseline Loans Per Month',
    },
    {
      field: 'los',
      title: 'LOS',
    },
    {
      field: 'poBox',
      title: 'Record & Return PO Box',
    },
    {
      field: 'clientInbox',
      title: 'Client Inbox',
    },
    {
      field: 'invoiceEmail',
      title: 'Invoice Email',
    },
    {
      field: 'reportSource',
      title: 'Report Source',
    },
    {
      field: 'reportFrequency',
      title: 'Purchased Loans Report Frequency',
    },
    {
      field: 'followupsRange',
      title: 'Followups Range',
    },
  ];

  return (
    <DataTable<RowData>
      title="Client Data"
      columns={columns}
      data={clientData}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Client Data',
      }}
    />
  );
};

export default ClientData;
