/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';

import colors from '../styles/colors';
import Header from '../components/ui/Header';
import SearchBoxSection from '../components/Imports/ImportErrors/LoanErrors/SearchBoxSection';
import NoErrors from '../components/Imports/ImportErrors/LoanErrors/NoErrors';
import ClientSection from '../components/Imports/ImportErrors/LoanErrors/ClientSection';
import ImportHeader from '../components/Imports/ImportHeader';
import { isEasyToResolve } from '../components/Imports/ImportErrors/ImportErrorsUtils';
import MessageTab from '../components/ui/MessageTab';
import { apiFetch, apiPost } from '../adalConfig';
import { Client, Note } from '../globalTypes/objects';

export type LoanErrorIndexLoan = {
  loanErrorId: number;
  issues: string[];
  loanNumber: string;
  spreadsheetDate: Date;
  lastNote: Note;
  clientId: number;
  emailSent: boolean;
  isPinned?: boolean;
};
export type LoanErrorClient = {
  client: Client;
  loanErrors: LoanErrorIndexLoan[];
};

const mainWrap = {
  backgroundColor: colors.grayExLight,
  width: '100%',
};

const setPinnedLoanErrors = (clients: LoanErrorClient[]) =>
  clients.map(client => ({
    ...client,
    loanErrors: client.loanErrors.map(loanError => ({
      ...loanError,
      isPinned: isEasyToResolve(loanError.issues),
    })),
  }));
type LoanErrorIndexState = {
  clients: LoanErrorClient[];
  noteUndoIds?: number[];
  loading?: boolean;
  error?: Error;
  clientWithSelection?: number;
  selectedClientIds: number[];
  deletedLoanErrorIds: number[];
};

export default class LoanErrorIndex extends Component<any, LoanErrorIndexState> {
  state = {
    clients: [] as LoanErrorClient[],
    noteUndoIds: (undefined as unknown) as number[],
    loading: true,
    error: undefined,
    clientWithSelection: undefined,
    selectedClientIds: [] as number[],
    deletedLoanErrorIds: [] as number[],
  };

  async componentDidMount() {
    try {
      const { data: clients } = await apiFetch<LoanErrorClient[]>(
        '/api/loanErrors/getLoanErrorsForIndex',
      );
      this.setState({
        loading: false,
        clients: setPinnedLoanErrors(clients),
      });
    } catch (error) {
      this.setState({ error }); // manually throw error in render, out of async function
    }
  }

  refresh = async () => {
    this.setState({ clientWithSelection: undefined });
    const { data: clients } = await apiFetch('/api/loanErrors/getLoanErrorsForIndex');
    this.setState({
      clients: setPinnedLoanErrors(clients),
    });
  };

  handleNoteUndo = async () => {
    await apiPost('/api/loanErrors/deleteNotes', this.state.noteUndoIds!);
    this.setState({ noteUndoIds: undefined });
    this.refresh();
  };

  handleDeletedLoanErrorUndo = async () => {
    await apiPost('/api/loanErrors/undelete', this.state.deletedLoanErrorIds);
    this.setState({ deletedLoanErrorIds: [] });
    this.refresh();
  };

  render() {
    const {
      clients,
      loading,
      error,
      clientWithSelection,
      selectedClientIds,
      noteUndoIds,
      deletedLoanErrorIds,
    } = this.state;
    if (error) throw error;
    const clientsToRender = selectedClientIds.length
      ? clients.filter(c => selectedClientIds.includes(c.client.id))
      : clients;
    return (
      <div css={{ paddingTop: 56 }}>
        <Header headerText="Import Errors" fixed />
        <div css={{ position: 'fixed', width: 800, top: 0, zIndex: 250 }}>
          <ImportHeader />
        </div>
        {loading ? null : (
          <div css={mainWrap}>
            {clients.length === 0 ? (
              <NoErrors />
            ) : (
              <div css={{ maxWidth: 1184, padding: '32px 40px' }}>
                <SearchBoxSection
                  selectedClientIds={selectedClientIds}
                  setSelectedClient={id =>
                    this.setState({
                      selectedClientIds: [...selectedClientIds, id],
                    })
                  }
                  removeSelectedClient={id =>
                    this.setState({
                      selectedClientIds: selectedClientIds.filter(clientId => clientId !== id),
                    })
                  }
                  clients={clients.map(c => ({
                    name: c.client.company,
                    id: c.client.id,
                  }))}
                />
                <div>
                  {clientsToRender.map(client => (
                    <ClientSection
                      key={client.client.id}
                      client={client}
                      refresh={this.refresh}
                      showNoteUndo={loanErrorIds => {
                        this.setState({ noteUndoIds: loanErrorIds });
                        setTimeout(() => {
                          this.setState({ noteUndoIds: undefined });
                        }, 10000);
                      }}
                      clientWithSelection={clientWithSelection === client.client.id}
                      onLoanErrorDeleted={id => {
                        this.setState(state => ({
                          ...state,
                          deletedLoanErrorIds: state.deletedLoanErrorIds.concat(id),
                        }));
                      }}
                      setClientWithSelection={selectedErrors =>
                        this.setState({
                          clientWithSelection: selectedErrors ? client.client.id : undefined,
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {noteUndoIds && (
              <MessageTab
                messageText={`Note added to ${noteUndoIds!.length} loans.`}
                handleUndo={this.handleNoteUndo}
                handleClose={() => this.setState({ noteUndoIds: undefined })}
              />
            )}
            {deletedLoanErrorIds.length && (
              <MessageTab
                messageText={`Deleted ${deletedLoanErrorIds.length} Loan Errors`}
                handleClose={() => {
                  this.setState({ deletedLoanErrorIds: [] });
                }}
                handleUndo={this.handleDeletedLoanErrorUndo}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
