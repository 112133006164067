/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';

import { ClientLargeIcon, NoteIcon, FollowUpsIcon, TrashCanIcon } from '../../../ui/icons';
import ToolTip from '../../../ui/ToolTip';
import colors from '../../../../styles/colors';
import { wrapStyles, headerWrap, clientName, tooltipStyles } from './css_index';
import { FormatPhoneWithSpaces, StripPhone } from '../../../../Utils';
import { Client } from '../../../../globalTypes/objects';

type ClientHeaderProps = {
  client: Client;
  errorMessage?: string;
  emailMessage?: string;
  showActions?: boolean;
  onAddNoteClick?: () => any;
  onEmailClick?: () => any;
  onDeleteErrorClick?: () => any;
};

export default function ClientHeader({
  client,
  errorMessage,
  emailMessage,
  showActions,
  onAddNoteClick,
  onEmailClick,
  onDeleteErrorClick,
}: ClientHeaderProps) {
  const formattedPhone = client.phone && FormatPhoneWithSpaces(StripPhone(client.phone));
  return (
    <div css={headerWrap}>
      <ClientLargeIcon />
      <span css={clientName}>{client.company}</span>
      {formattedPhone && (
        <p css={{ fontSize: 16 }}>
          <span css={{ color: colors.blue, fontSize: 16 }}>P&nbsp;&nbsp;</span>
          {formattedPhone}
        </p>
      )}
      <div
        css={{
          color: colors.blue,
          marginLeft: 24,
        }}
      />
      {showActions && (
        <Fragment>
          <div
            css={{
              color: colors.blue,
              marginLeft: 24,
            }}
          >
            <ToolTip
              toolTipText="Add note"
              wrapOverrides={wrapStyles}
              toolTipOverrides={tooltipStyles}
            >
              <div css={wrapStyles} onClick={onAddNoteClick}>
                <NoteIcon data-tip="Add note" />
              </div>
            </ToolTip>
            <ToolTip
              toolTipText="Email client"
              wrapOverrides={wrapStyles}
              toolTipOverrides={tooltipStyles}
            >
              <FollowUpsIcon data-tip="Email client" onClick={onEmailClick} />
            </ToolTip>
            <ToolTip
              toolTipText="Delete Error"
              wrapOverrides={wrapStyles}
              toolTipOverrides={tooltipStyles}
            >
              <TrashCanIcon data-tip="Delete Error" onClick={onDeleteErrorClick} />
            </ToolTip>
          </div>
        </Fragment>
      )}
      {errorMessage && <span css={{ marginLeft: '8', color: colors.red }}>{errorMessage}</span>}
      {emailMessage && (
        <span css={{ marginLeft: '8', color: colors.blue }}>Email sent successfully</span>
      )}
    </div>
  );
}
