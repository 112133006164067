/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ChangeEvent, Fragment, useMemo, useState } from 'react';
import { useGetData } from '../../../../Hooks';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { apiPost } from '../../../../adalConfig';
import { useToaster } from '../../../../Hooks/toasters';
import { useClients } from '../../../../Hooks/useClients';
import { Client } from '../../../../globalTypes/objects';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '24px',
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
    },
    badge: {
      display: 'inline',
      boxSizing: 'border-box',
      maxWidth: '100%',
      height: 'fit-content',
      padding: '2px 0px 3px',
      borderRadius: '3px',
      fontSize: '11px',
      fontWeight: 'bold',
      lineHeight: '1',
      textTransform: 'uppercase',
      verticalAlign: 'baseline',
    },
    chargeBadge: {
      backgroundColor: '#DFE1E6',
      color: '#42526E',
    },
    creditBadge: {
      backgroundColor: '#DEEBFF',
      color: '#0747A6',
    },
    button: {
      margin: theme.spacing(1),
    },
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '88px',
      height: '34px',
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    th: {
      padding: '12px 16px',
      textAlign: 'left',
    },
    td: {
      maxWidth: '340px',
      padding: '8px',
      color: 'rgba(0, 0, 0, .3)',
    },
  }),
);

type Charge = {
  id: number;
  clientID: number;
  dateUploaded: string;
  username: string;
  reason: string;
  amount: number;
  otherChargeTypeId: number;
};

type ChargeType = {
  id: number;
  label: string;
  isCredit: boolean;
};

type PostData = {
  clientId: number;
  reason: string;
  amount: number;
  otherChargeTypeId: number;
};

const AddChargeOrCredit = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedChargeType, setSelectedChargeType] = useState<ChargeType | null>(null);
  const [freeText, setFreeText] = useState('');
  const [amount, setAmount] = useState(0);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recentSubmissions, setRecentSubmissions] = useState<Charge[]>([]);

  const { data: chargeTypes } = useGetData<ChargeType[]>('/api/charge/other-charge-types', []);

  const clients = useClients();
  const { successToaster, errorToaster, warningToaster } = useToaster();

  const clientsMapping = useMemo(
    () =>
      clients.reduce(
        (prev, current) => prev.set(current.id, current.company),
        new Map<number, string>(),
      ),
    [clients],
  );
  const sortedChargeTypes = useMemo(() => chargeTypes.sort((a, b) => (a.isCredit ? 1 : -1)), [
    chargeTypes,
  ]);
  const canSubmit = useMemo(() => !!selectedClient && !!selectedChargeType && amount > 0, [
    selectedClient,
    selectedChargeType,
    amount,
  ]);

  const classes = useStyles();

  const optionLabel = (label, isCredit, props) => (
    <div key={label + isCredit} {...props}>
      <div className="df jcsb full-width">
        <div>{label}</div>
        <div className={`${classes.badge} ${isCredit ? classes.creditBadge : classes.chargeBadge}`}>
          {isCredit ? 'Credit' : 'Charge'}
        </div>
      </div>
    </div>
  );

  const submitButtonText = `Add ${selectedChargeType?.isCredit ? 'Credit' : 'Charge'}`;

  const submit = async () => {
    if (!canSubmit) {
      warningToaster('Please fill out all required fields');
    }

    setIsSubmitting(true);

    const postData: PostData = {
      clientId: selectedClient!.id,
      reason: `${selectedChargeType!.label} ${freeText}`,
      amount,
      otherChargeTypeId: selectedChargeType!.id,
    };

    try {
      const { data: newRecord } = await apiPost<Charge>('/api/charge/add-other-charge', postData);
      setRecentSubmissions(s => [newRecord, ...s]);
      resetForm();
      successToaster(`${selectedChargeType?.isCredit ? 'Credit' : 'Charge'} posted successfully`);
    } catch (e) {
      const response = e.response?.data ?? e.message;
      const errorMessage =
        typeof response === 'string' ? response.split('\n')[0] : response.errors[0]?.message;
      errorToaster(errorMessage);
    } finally {
      setIsSubmitting(false);
      setIsConfirmationModalOpen(false);
    }
  };

  const resetForm = () => {
    setSelectedClient(null);
    setSelectedChargeType(null);
    setFreeText('');
    setAmount(0);
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div>
          <Autocomplete
            options={clients}
            getOptionLabel={(option: Client) => option?.company || ''}
            onChange={(event: any, newValue: Client | null) => setSelectedClient(newValue)}
            value={selectedClient}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a client" variant="outlined" />
            )}
          />
        </div>
        <div style={{ display: 'flex', gap: '6px', alignItems: 'flex-end' }}>
          <Autocomplete
            options={sortedChargeTypes}
            getOptionLabel={(option: ChargeType) => option.label}
            renderOption={(props, option: ChargeType) =>
              optionLabel(option.label, option.isCredit, props)
            }
            groupBy={(option: ChargeType) => (option.isCredit ? 'Credits' : 'Charges')}
            onChange={(e: ChangeEvent<{}>, newValue: ChargeType | null) =>
              setSelectedChargeType(newValue)
            }
            value={selectedChargeType}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a charge adjustment type" variant="standard" />
            )}
          />
          <TextField
            placeholder="Append to message"
            variant="standard"
            style={{ width: '600px' }}
            onChange={e => setFreeText(e.target.value ?? '')}
            value={freeText}
          />
          <TextField
            label="Amount"
            variant="standard"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginLeft: '16px' }}
            value={amount > 0 ? amount : ''}
            onChange={e => {
              const numericValue = e.target.value.replace(/[^\d.]/g, '');
              if (numericValue === '') {
                setAmount(0);
                return;
              }

              const limitDecimal = Math.round(parseFloat(numericValue) * 100) / 100;
              setAmount(limitDecimal);
            }}
          />
        </div>
        <div>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setIsConfirmationModalOpen(true);
            }}
            disabled={!canSubmit}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th className={classes.th}>Client</th>
              <th className={classes.th}>Charge/Credit Type</th>
              <th className={classes.th}>Amount</th>
              <th className={classes.th}>Timestamp</th>
            </tr>
          </thead>
          <tbody>
            {recentSubmissions.map(charge => (
              <tr key={charge.id}>
                <td className={classes.td}>{clientsMapping.get(charge.clientID)}</td>
                <td className={classes.td}>{charge.reason}</td>
                <td className={classes.td}>
                  {charge.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td className={classes.td}>{new Date(charge.dateUploaded).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={isConfirmationModalOpen && canSubmit}>
        <div className={classes.paper}>
          <Fragment>
            <h3
              style={{
                backgroundColor: 'rgba(220,220,220,.5)',
                fontWeight: 'bold',
                margin: '6px 0',
              }}
            >
              Please confirm the reason and amount
            </h3>
            <div>
              The {selectedChargeType?.isCredit ? 'Credit' : 'Charge'} reason is:{' '}
              {`"${selectedChargeType?.label}${freeText ? ` ${freeText}` : ''}"`}
            </div>
            <p>
              For the amount of{' '}
              {amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
            </p>
          </Fragment>
          <div className="df jcfe aic mb2 mt2">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setIsConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            {isSubmitting ? (
              <div className={`${classes.loaderButton} center-in-parent`}>
                <CircularProgress size="20" disableShrink />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={submit}
              >
                {submitButtonText}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default AddChargeOrCredit;
