/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';
import { SidebarConsumer } from '../Layout/Sidebar/SidebarContext';
import { HeaderLinkGroup, HeaderLink } from '../ui/Tabs/HeaderLink';

const numberCon: CSSObject = {
  backgroundColor: colors.red,
  borderRadius: 4,
  color: colors.white,
  fontWeight: 700,
  padding: '1px 4px',
  display: 'inline-block',
  marginLeft: 9,
  fontSize: 11,
};

export default function ImportHeader() {
  return (
    <SidebarConsumer>
      {({ associationErrorCount, loanErrorCount }) => (
        <HeaderLinkGroup>
          <HeaderLink exact to="/errors/associations">
            <span>Association Errors</span>
            {!!(associationErrorCount || loanErrorCount) && (
              <div css={numberCon}>{associationErrorCount || 0}</div>
            )}
          </HeaderLink>
          <HeaderLink to="/errors/loans">
            <span>Loan Errors</span>
            {!!(associationErrorCount || loanErrorCount) && (
              <div css={numberCon}>{loanErrorCount || 0}</div>
            )}
          </HeaderLink>
        </HeaderLinkGroup>
      )}
    </SidebarConsumer>
  );
}
