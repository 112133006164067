import { useQuery } from '@tanstack/react-query';
import { AuditPointCategory, DocumentType } from '../globalTypes/objects';
import { apiFetch } from '../adalConfig';

export default function useAuditPoints(
  docType: DocumentType,
  includeInvestorOnlyPoints: boolean = false,
) {
  const result = useQuery(['auditPoints'], () =>
    apiFetch<AuditPointCategory[]>(
      `/Api/AuditPoints/GetAuditPointsByDocType?docTypeId=${docType}&includeInvestorOnly=${includeInvestorOnlyPoints}`,
    ).then(({ data: auditPointCategories }) => {
      auditPointCategories.forEach(category => {
        category.auditPoints.sort((a, b) => {
          return (b.priority ?? 0) - (a.priority ?? 0);
        });

        category.auditPoints.forEach(auditPoint => {
          auditPoint.options.sort((a, b) => {
            return (b.priority ?? 0) - (a.priority ?? 0);
          });
        });
      });

      auditPointCategories.sort((a, b) => {
        return (b.priority ?? 0) - (a.priority ?? 0);
      });
      return auditPointCategories;
    }),
  );
  return result.data;
}
