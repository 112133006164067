/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextField } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { apiFetch } from '../../../adalConfig';
import { useDebounce } from '../../../Hooks';
import { DebounceRate, ValidateEmail } from '../../../Utils';
import { EmailStatus, EmailWithStatus } from './useManualFollowups';

type Props = {
  initialValue?: string;
  setValidatedEmail: (emailWithStatus: EmailWithStatus) => void;
  styles?: object;
};

const fetchEmailStatus = async (email: string) => {
  const { data } = await apiFetch<EmailWithStatus[]>(
    `/api/emails/getMultipleEmailStatuses?emails[0]=${email}`,
  );

  return data[0].status;
};

export default function EmailValidationInput({
  initialValue = '',
  setValidatedEmail,
  styles = {},
}: Props) {
  const [email, setEmail] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  const debouncedEmail = useDebounce(email, DebounceRate);

  const fetch = async () => {
    setLoading(true);
    const status = await fetchEmailStatus(debouncedEmail);

    setLoading(false);
    setValidatedEmail({ email: debouncedEmail, status });
  };

  useEffect(() => {
    const isValid = ValidateEmail(debouncedEmail);
    if (isValid) {
      fetch();
    }
  }, [debouncedEmail]);

  const handleEmailChange = (newEmail: string) => {
    setEmail(newEmail);
    setLoading(false);
    if (newEmail === '') {
      setValidatedEmail({ email: '', status: EmailStatus.Valid });
    }
  };

  return (
    <Fragment>
      <TextField
        css={{
          width: '100%',
          marginBottom: 18,
          ...styles,
        }}
        variant="standard"
        label="Enter email address"
        value={email}
        onChange={e => handleEmailChange(e.target.value)}
      />
      {loading && <span css={{ color: 'red' }}>Validating Email...</span>}
    </Fragment>
  );
}
