export default {
  blue: '#0828cc',
  blueDark: '#00009a',
  blueLight: '#663cff',
  blueStatus: '#0018ab',
  black: '#000000',
  blackText: '#333',
  grayDark: '#8e8e93',
  gray: '#c7c7cc',
  grayLight: '#e5e5ea',
  grayExLight: '#f7f7f9',
  // green: '#00c853',
  green: '#1cbf8b',
  white: '#ffffff',
  red: '#ff3b30',
  orange: '#ffcc00',
};
