/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { format } from 'date-fns';
import colors from '../styles/colors';
import { WorkflowData } from '../pages/Workflow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

const gridWrap = {
  borderRadius: 4,
  paddingTop: 16,
  '> div': {
    overflow: 'visible',
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    minHeight: '66px',
    padding: '8px 16px',
    ':last-of-type': { borderRadius: '0 0 4px 4px' },
    ':nth-of-type(odd)': {
      backgroundColor: colors.grayExLight,
    },
  },
};

const headerWrap = {
  '&&': { minHeight: 40, fontWeight: 600 },
};

export default function ClientWorkFlow({
  data,
  dates,
  clientOpened,
  collapseClicked,
}: {
  data: WorkflowData;
  dates: Date[];
  clientOpened: boolean;
  collapseClicked: Function;
}) {
  return (
    <div key={data.clientId} className="radius mt3 p2 bg-white shadow">
      <Accordion
        expanded={clientOpened}
        onChange={() => collapseClicked(data.clientId, !clientOpened)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div css={{ fontSize: 18, fontWeight: 600 }}>{data.clientName}</div>
        </AccordionSummary>
        <AccordionDetails>
          <div css={gridWrap}>
            <WorkflowHeader />
            <WorkflowRow data={data} />
          </div>
          <WeeklyLoansCount data={data} dates={dates} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function WorkflowHeader() {
  return (
    <div css={headerWrap}>
      <div>Latest Date Funded</div>
      <div>3 Month Avg</div>
      <div>This Month</div>
      <div>Last Import Date</div>
      <div>Volume on Last Import Date</div>
      <div>Current Month Upload Count</div>
    </div>
  );
}

const ShortDate = (date: Date) => {
  const monthAndDateRegex = /\d{1,2}\/\d{1,2}/;
  const fullDate = new Date(date).toLocaleDateString();
  return fullDate.match(monthAndDateRegex);
};

function DateRange({ start, end }: { start: Date; end: Date }) {
  return (
    <div>
      {ShortDate(start)} - {ShortDate(end)}
    </div>
  );
}

function WorkflowRow({ data }: { data: WorkflowData }) {
  return (
    <div>
      <div>
        {data.latestDateFunded &&
          format(Date.parse(data.latestDateFunded.toLocaleString()), 'MMMM dd, yyyy')}
      </div>
      <div>{data.threeMonthAverage}</div>
      <div>{data.currentMonthCount}</div>
      <div>
        {data.lastImportDate &&
          format(Date.parse(data.lastImportDate.toLocaleString()), 'MMMM dd, yyyy')}
      </div>
      <div>{data.lastImportVolume}</div>
      <div>{data.currentMonthUploadCount}</div>
    </div>
  );
}

function WeeklyLoansCount({ data, dates }: { data: WorkflowData; dates: Date[] }) {
  return (
    <div css={[gridWrap, { '> div': { gridTemplateColumns: 'repeat(12, 1fr)' } }]}>
      <div css={headerWrap}>
        <DateRange start={dates[1]} end={dates[0]} />
        <DateRange start={dates[3]} end={dates[2]} />
        <DateRange start={dates[5]} end={dates[4]} />
        <DateRange start={dates[7]} end={dates[6]} />
        <DateRange start={dates[9]} end={dates[8]} />
        <DateRange start={dates[11]} end={dates[10]} />
        <DateRange start={dates[13]} end={dates[12]} />
        <DateRange start={dates[15]} end={dates[14]} />
        <DateRange start={dates[17]} end={dates[16]} />
        <DateRange start={dates[19]} end={dates[18]} />
        <DateRange start={dates[21]} end={dates[20]} />
        <DateRange start={dates[23]} end={dates[22]} />
      </div>

      <div>
        {data.weeklyLoansCount.map((count, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>{count}</div>
        ))}
      </div>
    </div>
  );
}
