/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { ReactElement } from 'react';
import colors from '../../styles/colors';

export const panelWrap: CSSObject = {
  width: '100%',
  maxWidth: 620,
};

type PanelProps = {
  active?: boolean;
  children: any;
  styles?: any;
};

const panelStyles = (active = false): CSSObject => ({
  borderRadius: 4,
  backgroundColor: 'white',
  ...(active && { boxShadow: `0 0 0 1.4px ${colors.blue}` }),
  border: `1px solid ${active ? colors.white : colors.grayLight}`,
  maxWidth: 620,
  padding: 32,
  width: '100%',
  display: 'inline-block',
  verticalAlign: 'top',
});

export default function Panel({ active, children, styles }: PanelProps) {
  return <div css={[{ ...panelStyles(active), ...styles }]}>{children}</div>;
}

const header = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  borderRadius: 4,
  display: 'flex',
  height: 80,
  padding: '16px 25px',
};

type PanelHeaderProps = {
  text?: string;
  largeText?: boolean;
  iconComponent?: ReactElement;
  headerStyles?: any;
  [propName: string]: any;
};

const textStyles = (largeText = false): CSSObject => ({
  fontSize: largeText ? 20 : 16,
  fontWeight: 'bold',
});

export function PanelHeader({
  text,
  largeText,
  iconComponent,
  headerStyles,
  children,
  ...props
}: PanelHeaderProps) {
  return (
    <div css={[header, headerStyles]}>
      {iconComponent && <div css={{ marginRight: 16 }}>{iconComponent}</div>}
      <div css={textStyles(largeText)} {...props}>
        {text}
      </div>
      {children}
    </div>
  );
}
