/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Client, OutreachDisposition, Pod } from '../../../globalTypes/objects';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useClients } from '../../../Hooks/useClients';
import { DataTableFilters } from './useEscalationFilters';
import { SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import { apiFetch } from '../../../adalConfig';
import debounce from 'lodash/debounce';
import { DropdownListItem } from '../types';
import DispositionsContext from '../DispositionsContext';
import { useQuery } from '@tanstack/react-query';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  filters: DataTableFilters;
  onFilterSelected: <T extends keyof DataTableFilters>(
    field: T,
    value: DataTableFilters[T],
  ) => void;
};

const titleCompanyOptionLabel = (props: object, { id, label }: DropdownListItem) => (
  <div {...props} key={id}>
    <div>{label}</div>
  </div>
);

const getPods = async () => {
  const { data } = await apiFetch<Pod[]>('/api/clients/pods');
  return data;
};

const Filters = ({ filters, onFilterSelected }: Props) => {
  const [titleCompanies, setTitleCompanies] = useState<DropdownListItem[]>([]);
  const [isLoadingTitleCompanies, setIsLoadingTitleCompanies] = useState(false);
  const { data: pods, isFetching: isFetchingPods } = useQuery(['pods'], getPods, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (filters.titleCompany) {
      getTitleCompanies(filters.titleCompany);
    }
  }, []);

  const xhr = useRef<CancelTokenSource | undefined>(undefined);
  const clients = useClients();
  const dispositions = useContext(DispositionsContext);

  const onInputChange = debounce(
    async (event: SyntheticEvent, inputText: string, reason: AutocompleteInputChangeReason) =>
      await getTitleCompanies(inputText),
    300,
  );

  const getTitleCompanies = useCallback(
    async (inputText: string) => {
      if (xhr.current) {
        xhr.current.cancel();
      }

      const ajaxRequest = axios.CancelToken.source();
      xhr.current = ajaxRequest;

      setIsLoadingTitleCompanies(true);
      const { data } = await apiFetch<DropdownListItem[]>('/api/titleCompanies/dropdown-data', {
        params: { inputText },
        cancelToken: ajaxRequest.token,
      });

      xhr.current = undefined;

      setIsLoadingTitleCompanies(false);
      setTitleCompanies(data.slice(0, 150));
    },
    [setIsLoadingTitleCompanies, setTitleCompanies],
  );

  return (
    <div className="m1">
      <div className="df jcsb">
        <div>
          <Autocomplete
            options={dispositions.filter(x => x.stage === 3)}
            getOptionLabel={(option: OutreachDisposition) => option.reason}
            onChange={(e: any, newValue: OutreachDisposition | null) =>
              onFilterSelected('disposition', newValue?.id || null)
            }
            value={dispositions.find(x => x.id === filters.disposition) || null}
            style={{ width: '350px' }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a disposition" variant="outlined" />
            )}
          />
        </div>
        <div>
          {isFetchingPods ? (
            <div className="center-in-parent">
              <CircularProgress disableShrink size={25} />
            </div>
          ) : (
            <Autocomplete
              options={pods}
              getOptionLabel={(option: Pod) => option.name}
              onChange={(e: any, newValue: Pod | null) =>
                onFilterSelected('pod', newValue?.id || null)
              }
              value={pods.find(x => x.id === filters.pod) || null}
              style={{ width: '350px' }}
              autoHighlight
              renderInput={params => (
                <TextField {...params} label="Choose a pod" variant="outlined" />
              )}
            />
          )}
        </div>
        <div>
          <Autocomplete
            options={clients}
            getOptionLabel={(option: Client) => option.company}
            onChange={(e: any, newValue: Client | null) =>
              onFilterSelected('client', newValue?.company || null)
            }
            value={clients.find(x => x.company === filters.client) || null}
            style={{ width: '350px' }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a client" variant="outlined" />
            )}
          />
        </div>
        <div>
          <Autocomplete
            options={titleCompanies}
            onInputChange={onInputChange}
            loading={isLoadingTitleCompanies}
            getOptionLabel={(option: DropdownListItem) => option.label}
            renderOption={(props, option: DropdownListItem) =>
              titleCompanyOptionLabel(props, option)
            }
            onChange={(e: any, newValue: DropdownListItem | null) =>
              onFilterSelected('titleCompany', newValue?.label || null)
            }
            value={titleCompanies.find(x => x.label === filters.titleCompany) || null}
            isOptionEqualToValue={(x: DropdownListItem | null, y: DropdownListItem | null) =>
              x?.id === y?.id
            }
            style={{ width: '350px' }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Search for a title company" variant="outlined" />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;
