import React, { createContext } from 'react';
import { OutreachDisposition } from '../../globalTypes/objects';
import { useGetData } from '../../Hooks';

const DispositionsContext = createContext<OutreachDisposition[]>([]);

const sortedDispositions = (dispositions: OutreachDisposition[]) =>
  dispositions.sort((a, b) => (a.stage + a.reason < b.stage + b.reason ? -1 : 1));

export const DispositionsProvider = ({ children }) => {
  const { data: dispositions } = useGetData<OutreachDisposition[]>(
    '/api/call-tasks/outreach-dispositions',
    [],
  );

  return (
    <DispositionsContext.Provider value={sortedDispositions(dispositions)}>
      {children}
    </DispositionsContext.Provider>
  );
};

export default DispositionsContext;
