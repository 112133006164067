/* eslint-disable no-nested-ternary */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import { produce } from 'immer';
import EditIcon from '@mui/icons-material/Edit';
import MaterialButton from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Client, DocumentType as DocType } from '../globalTypes/objects';
import {
  Failure,
  isFailure,
  isLoading,
  isSuccess,
  Loading,
  NotAsked,
  RemoteData,
  Success,
} from '../globalTypes/RemoteData';
import NoData from '../components/ui/NoData';
import Button from '../components/ui/Button';
import EscapeIcon from '../components/ui/EscapeIcon';
import { apiFetch, apiPost } from '../adalConfig';
import MatchVerification, {
  MatchDisplay,
  MatchStatus,
} from '../components/Documents/MatchVerification';
import { getDocTypeName, getDocTypes } from '../Utils';
import ConditionalComponent from '../components/ConditionalComponent';
import Dropdown from '../components/ui/Dropdown';
import { MatchOnlyOption, MatchSuggestionParams, PageDisplay } from './NotFounds';
import useIsFaultyDoc from '../Hooks/useIsFaultyDoc';
import { useClients } from '../Hooks/useClients';
import useSuggestedDocType from '../Hooks/useSuggestedDocType';
import NativePdfRenderer from '../components/NativePdfRenderer';
import { useToaster } from '../Hooks/toasters';

const pdfWrap = {
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  display: 'inline-block',
  height: 900,
  marginRight: 32,
  maxWidth: 1024,
  overflow: 'auto',
  width: '100%',
};

const tagStyles = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: 28,
  display: 'flex',
  justifyContent: 'center',
  marginRight: 24,
  padding: '8px 16px',
  verticalAlign: 'middle',
  span: { fontWeight: 'bold', marginRight: 5 },
};

interface UnassociatedDocumentsProps {
  client: Client;
  pageDisplay: PageDisplay;
  hideForm: () => void;
  matchOnly: MatchOnlyOption;
  queryParams?: MatchSuggestionParams;
}

type DocumentType = {
  label: string;
  value: number;
};

export default function UnassociatedDocuments({
  client,
  pageDisplay,
  hideForm,
  matchOnly,
  queryParams,
}: UnassociatedDocumentsProps) {
  const [match, setMatch] = useState<MatchDisplay>({} as MatchDisplay);
  const [matchAmount, setMatchAmount] = useState<number>();
  const [dataLoading, setDataLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentClient, setCurrentClient] = useState<Client>(client);
  const [allowEditingDocType, setAllowEditingDocType] = useState(true);
  const [isEditingDocType, setIsEditingDocType] = useState(false);
  const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
  const docTypePrediction = useSuggestedDocType(match?.documentId);
  const [selectedDocType, setSelectedDocType] = useState<DocumentType>({} as DocumentType);
  const isFaultyDoc = useIsFaultyDoc(match.document);
  const clients = useClients();
  const [initialSetDocumentTypeRequest, setInitialSetDocumentTypeRequest] =
    useState<RemoteData<unknown, Error>>(NotAsked);
  const [initialSetDocumentData, setInitialSetDocumentData] = useState<null | DocumentType>(null);

  const { successToaster, errorToaster } = useToaster();

  useEffect(() => {
    if (initialSetDocumentData && match.documentId) {
      setInitialSetDocumentTypeRequest(Loading);
      apiPost(`/api/documents/setDocumentType`, {
        documentId: match.documentId,
        documentType: initialSetDocumentData.value,
      })
        .then(res => {
          setInitialSetDocumentTypeRequest(Success(res.data));
          const newDocType = initialSetDocumentData.value;
          setInitialSetDocumentData(null);
          setMatch(
            produce(match, matchDraft => {
              matchDraft.document.documentType = newDocType;
            }),
          );
        })
        .catch(e => {
          setInitialSetDocumentData(null);
          setInitialSetDocumentTypeRequest(Failure(e));
        });
    }
  }, [initialSetDocumentData, match, match.documentId]);

  type FetchBy = 'Client' | 'Document' | 'Match';
  const fetchMatchByClient = () => fetchMatch('Client');
  const fetchMatchByDocument = () => fetchMatch('Document');
  const fetchMatchById = () => fetchMatch('Match');

  const fetchMatch = async (fetchBy: FetchBy) => {
    let apiEndPoint;
    const params: {
      clientId?: number;
      documentId?: number;
      matchId?: number;
      type?: MatchOnlyOption;
      uploadedByTitleCo?: boolean;
    } = {};

    if (pageDisplay === PageDisplay.skippedMatch) {
      apiEndPoint = 'get-skipped-verification-match';
    } else {
      apiEndPoint = 'getVerificationMatch';
      if (pageDisplay === PageDisplay.titleCompanyUploads) {
        params.type = matchOnly;
        params.uploadedByTitleCo = true;
      }
      if (fetchBy === 'Client') {
        params.clientId = client.id;
        params.type = matchOnly;
      }
      if (fetchBy === 'Document') {
        params.documentId = match.documentId;
      }
      if (fetchBy === 'Match') {
        params.matchId = match.id;
      }
    }
    const { data } = await apiFetch<MatchDisplay>(`/api/documents/${apiEndPoint}`, { params });
    return data;
  };

  const getVerificationMatchForDocumentAndLoan = async () => {
    const { data } = await apiFetch<MatchDisplay>(
      '/api/documents/get-verification-match-for-document-and-loan',
      { params: { documentId: queryParams?.documentId, loanId: queryParams?.loanId } },
    );

    return data;
  };

  const loadMatch = async () => {
    let data;
    if (queryParams) {
      data = await getVerificationMatchForDocumentAndLoan();
    } else {
      data = await fetchMatchByClient();
    }

    if (data) {
      if ((pageDisplay === PageDisplay.skippedMatch || queryParams) && data.loan.clientID) {
        setCurrentClient(clients.find(c => c.id === data.loan.clientID) || ({} as Client));
      }
      if (pageDisplay === PageDisplay.titleCompanyUploads) {
        setCurrentClient(clients.find(c => c.id === data.loan.clientID) || ({} as Client));
      }

      setMatch(data);
      await getMatchAmount(data.loan.clientID);

      // @ts-ignore
      setSelectedDocType({
        label: data.document.documentTypeName,
        value: data.document.documentType,
      });
    }

    setDataLoading(false);
  };

  const getMatchAmount = async (clientId: number) => {
    let url = '/api/documents/';
    url +=
      pageDisplay === PageDisplay.skippedMatch
        ? 'skipped-match-verification-count'
        : pageDisplay === PageDisplay.titleCompanyUploads
        ? 'titleUploadsMatchVerificationCount'
        : `matchVerificationCount?clientId=${clientId}`;

    const { data: matchNum } = await apiFetch<number>(url);
    setMatchAmount(matchNum);
  };

  const onMatchComplete = async (matchStatus?: Exclude<MatchStatus, 'INITIAL'>) => {
    setAllowEditingDocType(true);

    if (pageDisplay !== PageDisplay.specificMatch) {
      await next();
    } else if (matchStatus) {
      successToaster(
        <p>
          The match suggestion status was successfully updated to <b>{matchStatus.toLowerCase()}</b>
        </p>,
      );
      setTimeout(() => {
        window.close();
      }, 1000);
    }
  };

  const next = async () => {
    setDataLoading(true);
    setMatch({} as MatchDisplay);
    await loadMatch();
  };

  const resetMatchDisplay = async () =>
    apiPost('/api/documents/resetMatchDisplay', { int: match.id });

  useEffect(() => {
    loadMatch();
    getDocTypes().then(setDocTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateDocType = async (docType: DocumentType) => {
    if (!allowEditingDocType) {
      return;
    }

    setIsEditingDocType(false);

    try {
      const { data: response } = await apiPost<number>('/api/documents/update-document-type', {
        documentId: match.documentId,
        documentType: docType?.value,
      });

      if (response >= 1) {
        const data = await fetchMatchById();
        setMatch(data);

        successToaster('Successfully updated the document type');
      } else {
        errorToaster('Failed to update the document type');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const { documentId } = match;
  return dataLoading ? (
    <Fragment />
  ) : !documentId ? (
    <Fragment>
      <NoData
        message={`No unassociated documents${
          pageDisplay === PageDisplay.normal ? ` for ${currentClient.company}` : ''
        }`}
        subMessage="Check back when the next spreadsheet is imported"
      >
        <div css={{ marginTop: 20 }}>
          <Button onClick={hideForm}>go back</Button>
        </div>
      </NoData>
    </Fragment>
  ) : (
    <Fragment>
      <EscapeIcon
        onClick={() => {
          resetMatchDisplay();
          hideForm();
        }}
      />
      <div css={{ padding: 32 }}>
        <div className="fs20 mb3 df">
          <ConditionalComponent display={pageDisplay === PageDisplay.skippedMatch}>
            <div css={tagStyles}>
              <span>Client: </span>
              {currentClient.company}
            </div>
          </ConditionalComponent>
          <div css={tagStyles}>
            <span>Document:</span>
            {match.document.id}
          </div>
          {isEditingDocType ? (
            <div css={{ display: 'flex' }}>
              <Dropdown
                placeholder="Document Type"
                options={docTypes}
                value={selectedDocType}
                css={{ width: 280 }}
                onChange={docType => setSelectedDocType(docType)}
              />
              <div css={{ display: 'flex', justifyContent: 'flex-end', margin: '8px 0px' }}>
                <MaterialButton
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ margin: '0px 6px' }}
                  onClick={() => setIsEditingDocType(false)}
                >
                  Cancel
                </MaterialButton>
                <MaterialButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => updateDocType(selectedDocType)}
                >
                  Submit
                </MaterialButton>
                {isSuccess(docTypePrediction) && (
                  <div css={{ padding: 8 }}>
                    {docTypePrediction.data.prediction === DocType.Unknown ? (
                      <div>Document type suggestion unavailable.</div>
                    ) : (
                      <div>
                        Suggested DocType: {getDocTypeName(docTypePrediction.data.prediction)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            match?.document?.documentType &&
            match.document.documentType !== DocType.Unknown && (
              // @ts-ignore
              <div css={tagStyles}>
                <span>Doc Type: </span>
                {getDocTypeName(match.document.documentType)}
                <ConditionalComponent display={allowEditingDocType}>
                  <EditIcon
                    color="action"
                    style={{ paddingLeft: '5px' }}
                    onClick={() => setIsEditingDocType(true)}
                  />
                </ConditionalComponent>
              </div>
            )
          )}
          {isSuccess(isFaultyDoc) && isFaultyDoc.data && (
            // @ts-ignore
            <div css={{ ...tagStyles, color: 'red' }}>
              <span>Needs Correction</span>
            </div>
          )}
          {isSuccess(docTypePrediction) &&
            docTypePrediction.data.prediction !== DocType.Unknown &&
            match.document.documentType !== DocType.Unknown &&
            docTypePrediction.data.prediction !== match.document.documentType && (
              // @ts-ignore
              <div css={{ ...tagStyles, color: 'red' }}>
                <span>Doc type may be incorrect. Please double check before matching!</span>
              </div>
            )}
        </div>
        <div css={{ display: 'flex' }}>
          <div css={pdfWrap}>
            {documentId && <NativePdfRenderer link={match.documentLink} rerender={0} />}
          </div>
          <MatchVerification
            match={{
              ...match,
              isPolicy: match.document.documentType === DocType.Policy,
              isMortgage: match.document.documentType === DocType.Mortgage,
            }}
            onMatchComplete={onMatchComplete}
            matchAmount={matchAmount}
            client={currentClient}
            onLoadError={resetMatchDisplay}
            pdfLoading={pdfLoading}
            pageDisplay={pageDisplay}
            setAllowEditingDocType={setAllowEditingDocType}
            renderDocumentTypeSelect={() =>
              match.document.documentType && match.document.documentType === DocType.Unknown ? (
                <Fragment>
                  <FormControl css={{ width: '100%' }}>
                    <Autocomplete
                      options={docTypes}
                      renderOption={(props, docType) => (
                        // @ts-ignore
                        <div key={docType.value} {...props}>
                          {typeof docType !== 'string' ? docType.label : ''}
                        </div>
                      )}
                      getOptionLabel={docType =>
                        typeof docType !== 'string' ? docType?.label.toString() : ''
                      }
                      value={selectedDocType}
                      onChange={(event, docType) => {
                        setInitialSetDocumentData(docType);
                      }}
                      renderInput={params => (
                        <TextField {...params} variant="outlined" label="Set Document Type" />
                      )}
                    />
                    {isLoading(initialSetDocumentTypeRequest) && <div>Saving...</div>}
                    {isFailure(initialSetDocumentTypeRequest) && <div>There was an error.</div>}
                  </FormControl>
                  {isSuccess(docTypePrediction) && (
                    <div css={{ padding: 8 }}>
                      {docTypePrediction.data.prediction === DocType.Unknown ? (
                        <div>Document type suggestion unavailable.</div>
                      ) : (
                        <div>
                          Suggested DocType: {getDocTypeName(docTypePrediction.data.prediction)}
                        </div>
                      )}
                    </div>
                  )}
                </Fragment>
              ) : null
            }
          />
        </div>
      </div>
    </Fragment>
  );
}
