/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import SearchSelect from '../ui/SearchSelect';
import Button from '../ui/Button';
import Dropdown from '../ui/Dropdown';
import TextInput from '../ui/Inputs/TextInput';
import Panel, { PanelHeader } from '../ui/Panel';
import PhoneInput from '../ui/Inputs/PhoneInput';
import BackButton from './BackButton';
import { apiFetch, apiPost } from '../../adalConfig';
import { Client } from '../../globalTypes/objects';

const defaultState = {
  clientId: (undefined as unknown) as number,
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  fax: '',
  error: false,
  editClient: ('' as unknown) as number,
  contactList: (undefined as unknown) as ClientContact[],
  selectedContact: (undefined as unknown) as ClientContact,
  editError: false,
};

const panelsWrap = {
  margin: '40px',
  marginTop: 24,
  maxWidth: 1280,
  '@media(min-width: 1024px)': {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

type ClientContact = {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  fax: string;
  clientId: number;
};

type ClientContactsState = ClientContact & {
  error: boolean;
  editClient: number;
  contactList?: ClientContact[];
  selectedClient?: ClientContact;
  selectedContact?: ClientContact;
  editError: boolean;
  clients?: Client[];
  loading?: boolean;
};

enum ClientContactType {
  LoanErrorContact = 0,
}

export default class ClientContacts extends Component<any, ClientContactsState> {
  state = { ...defaultState, clients: [] as Client[], loading: false };

  async componentDidMount() {
    this.setState({ loading: true });
    const { data: clients } = await apiFetch<Client[]>('/api/clients/getall', {});
    await this.setState({ clients });
    this.setState({ loading: false });
  }

  onSubmit = async () => {
    const { clientId, title, firstName, lastName, email, phone, fax } = this.state;
    if (!clientId || !email || !firstName || !lastName) {
      this.setState({ error: true });
      return;
    }
    const contact: ClientContact = {
      title,
      clientId,
      firstName,
      lastName,
      email,
      phone,
      fax,
    };
    Object.keys(contact).forEach(key => {
      if (!contact[key]) contact[key] = null;
    });
    await apiPost('/api/clients/insertClientContactForType', {
      ...contact,
      type: ClientContactType.LoanErrorContact,
    });
    this.setState(defaultState);
  };

  onEditSubmit = async () => {
    await apiPost('/api/clients/updateClientContact', {
      ...this.state.selectedContact,
    });
    this.setState(defaultState);
  };

  getEditClientContact = async () => {
    const { data: contactList } = await apiFetch<ClientContact[]>(
      `/api/clients/getClientContacts?clientId=${this.state.editClient}`,
      {},
    );
    this.setState({ contactList });
  };

  render() {
    const {
      loading,
      clients,
      clientId,
      title,
      firstName,
      lastName,
      email,
      phone,
      fax,
      error,
      editClient,
      contactList,
      selectedContact,
      editError,
    } = this.state;
    return loading ? null : (
      <Fragment>
        <BackButton to="/admin">Admin Page</BackButton>
        <div css={panelsWrap}>
          <Panel>
            <PanelHeader text="Create Loan Error Client Contact" />
            <div css={{ maxWidth: 320, marginTop: 24 }}>
              <SearchSelect
                options={clients.map((c: Client) => ({
                  label: c.company,
                  value: c.id,
                }))}
                placeholder="Search clients *"
                {...(clientId &&
                  clients.length !== 0 && {
                    defaultValue: {
                      label: clients.find(c => c.id === clientId)?.company,
                      value: clientId,
                    },
                  })}
                onChange={selection => {
                  if (selection) {
                    this.setState({ clientId: selection.value });
                  }
                }}
              />
            </div>
            <TextInput
              type="text"
              placeholder="Title"
              value={title}
              onChange={e => this.setState({ title: e.target.value })}
              labelOverrides={{ marginTop: 16, width: 320 }}
            />
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
              }}
            >
              <TextInput
                type="text"
                placeholder="First Name *"
                value={firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
                labelOverrides={{ width: '48%' }}
              />
              <TextInput
                type="text"
                placeholder="Last Name *"
                value={lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
                labelOverrides={{ width: '48%' }}
              />
            </div>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 16,
              }}
            >
              <PhoneInput
                placeholder="Phone"
                value={phone}
                onChange={value => this.setState({ phone: value })}
                labelOverrides={{ width: '48%' }}
              />
              <PhoneInput
                placeholder="Fax"
                value={fax}
                onChange={value => this.setState({ fax: value })}
                labelOverrides={{ width: '48%' }}
              />
            </div>
            <TextInput
              type="text"
              placeholder="Email *"
              value={email}
              onChange={e => this.setState({ email: (e.target.value || '').trim() })}
              labelOverrides={{ margin: '16px 0 24px', maxWidth: 320 }}
            />
            <Button onClick={this.onSubmit}>Submit</Button>
            {error && (
              <div style={{ margin: '4px', color: 'red' }}>Please fill out all required fields</div>
            )}
          </Panel>
          <Panel>
            <PanelHeader text="Edit Client Contact" />
            <div css={{ maxWidth: 320, marginTop: 24 }}>
              <SearchSelect
                options={clients.map(c => ({
                  label: c.company,
                  value: c.id,
                }))}
                placeholder="Search clients *"
                {...(editClient &&
                  clients.length !== 0 && {
                    defaultValue: {
                      label: clients.find(c => c.id === editClient)?.company,
                      value: editClient,
                    },
                  })}
                onChange={async selection => {
                  if (selection) {
                    await this.setState(defaultState);
                    await this.setState({ editClient: selection.value });
                    this.getEditClientContact();
                  }
                }}
              />
            </div>
            {contactList && (
              <Fragment>
                <div css={{ maxWidth: 320, marginTop: 24 }}>
                  <Dropdown
                    options={contactList!.map((c: ClientContact) => ({
                      label: `${c.firstName} ${c.lastName}`,
                      value: c,
                    }))}
                    placeholder="Select contact"
                    onChange={(selection: SelectOption<ClientContact>) =>
                      this.setState({ selectedContact: selection.value })
                    }
                  />
                </div>
                {selectedContact && (
                  <Fragment>
                    <TextInput
                      type="text"
                      label="Title"
                      placeholder="Title"
                      value={selectedContact!.title}
                      labelOverrides={{ marginTop: 16, width: 320 }}
                      onChange={e =>
                        this.setState({
                          selectedContact: {
                            ...selectedContact,
                            title: e.target.value,
                          },
                        })
                      }
                    />
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 16,
                      }}
                    >
                      <TextInput
                        label="First Name"
                        type="text"
                        placeholder="First Name *"
                        value={selectedContact.firstName}
                        onChange={e =>
                          this.setState({
                            selectedContact: {
                              ...selectedContact,
                              firstName: e.target.value,
                            },
                          })
                        }
                      />
                      <TextInput
                        label="Last Name"
                        type="text"
                        placeholder="Last Name *"
                        value={selectedContact.lastName}
                        onChange={e =>
                          this.setState({
                            selectedContact: {
                              ...selectedContact,
                              lastName: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      css={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 16,
                      }}
                    >
                      <PhoneInput
                        label="Phone"
                        placeholder="Phone"
                        value={selectedContact.phone}
                        onChange={value =>
                          this.setState({
                            selectedContact: {
                              ...selectedContact,
                              phone: value,
                            },
                          })
                        }
                      />
                      <PhoneInput
                        label="Fax"
                        placeholder="Fax"
                        value={selectedContact.fax}
                        onChange={value =>
                          this.setState({
                            selectedContact: {
                              ...selectedContact,
                              fax: value,
                            },
                          })
                        }
                      />
                    </div>
                    <TextInput
                      label="Email"
                      type="text"
                      placeholder="Email *"
                      value={selectedContact.email}
                      labelOverrides={{ margin: '16px 0 24px', maxWidth: 320 }}
                      onChange={e =>
                        this.setState({
                          selectedContact: {
                            ...selectedContact,
                            email: (e.target.value || '').trim(),
                          },
                        })
                      }
                    />
                    <Button onClick={this.onEditSubmit}>Submit</Button>
                    {editError && (
                      <div style={{ margin: '4px', color: 'red' }}>
                        Please fill out all required fields
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </Panel>
        </div>
      </Fragment>
    );
  }
}
