/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DigitalDeliveryLog as RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';

type Props = {
  reportData: RowData[];
};

const columns = [
  {
    field: 'method',
    title: 'Shipment Method',
  },
  {
    field: 'investor',
    title: 'Investor',
  },
  {
    field: 'lender',
    title: 'Lender',
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
  },
  {
    field: 'investorsLoanNumber',
    title: 'Investor Loan Number',
  },
  {
    field: 'borrower',
    title: 'Borrower',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'dateFunded',
    title: 'Date Funded',
    render: (rowData: RowData) =>
      rowData.dateFunded ? new Date(rowData.dateFunded).toLocaleDateString() : '',
  },
  {
    field: 'dateProcessed',
    title: 'Date Processed',
    render: (rowData: RowData) =>
      rowData.dateProcessed ? new Date(rowData.dateProcessed).toLocaleDateString() : '',
  },
  {
    field: 'dateUploaded',
    title: 'Date Uploaded',
    render: (rowData: RowData) =>
      rowData.dateUploaded ? new Date(rowData.dateUploaded).toLocaleDateString() : '',
  },
];

const DigitalDeliveryLog = ({ reportData }: Props) => {
  return (
    <DataTable<RowData>
      title="Digital Delivery Log"
      columns={columns}
      data={reportData}
      options={{
        maxBodyHeight: '500px',
        search: false,
      }}
    />
  );
};

export default DigitalDeliveryLog;
