/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import debounce from 'lodash/debounce';

import { Client, DropdownListItem, RowData } from './types';
import Dropdown from '../ui/Dropdown';
import { roleTypes } from '../../constants';
import { AuthContext } from '../AuthContext';
import { useGetData } from '../../Hooks';
import { apiFetch } from '../../adalConfig';
import SearchSelect from '../ui/SearchSelect';
import { isBasicUser } from '../../Utils';
import { poBoxDisplay } from '../../pages/CallCenter';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';

type Props = {
  getDatatableData: (id?: number) => Promise<void>;
  datatableData: RowData[];
  clearDatatable: () => void;
  setIsClientMode: (value: boolean) => void;
  isClientMode: boolean;
  setSelectedClient: (client: Client) => void;
  selectedClient: Client;
  setSelectedTitleCompany: (titleCompany: DropdownListItem) => void;
  selectedTitleCompany: DropdownListItem;
  localTime: string;
  loansRemaining: number;
  setIsModalOpen: (value: boolean) => void;
  escalationCount: number;
};

const Dashboard = ({
  getDatatableData,
  datatableData,
  clearDatatable,
  setIsClientMode,
  isClientMode,
  setSelectedClient,
  selectedClient,
  setSelectedTitleCompany,
  selectedTitleCompany,
  localTime,
  loansRemaining,
  setIsModalOpen,
  escalationCount,
}: Props) => {
  const { data: clients } = useGetData<Client[]>('/api/call-tasks/basic-client-data', []);

  const allowResolve = useMemo(
    () => !!datatableData.length && datatableData.every(row => row.isResolved),
    [datatableData],
  );

  const authContext = useContext(AuthContext);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dropdownContainer: {
        width: '100%',
        margin: '10px',
      },
      links: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: 16,
        top: 16,
      },
      linkStyle: {
        color: 'blue',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      btn: {},
    }),
  );
  const classes = useStyles();

  const loadTitleCompanyOptions = debounce(async (inputText, callback) => {
    const { data: titleCompanies } = await apiFetch('/api/titleCompanies/dropdown-data', {
      params: { inputText },
    });

    callback(titleCompanies);
  }, 800);

  const isCallCenterAdmin = () =>
    authContext.roles.findIndex(role =>
      [roleTypes.Admin, roleTypes.Dev, roleTypes.CallCenterAdmin].includes(role),
    ) !== -1;

  const loadData = async (id?: number) => {
    await getDatatableData(id);
  };

  return (
    <div className="roboto roboto df aifs">
      <div
        css={{
          alignItems: 'flex-start',
          backgroundColor: '#fff',
          border: '1px solid #eee',
          borderRadius: 5,
          display: 'inline-flex',
          margin: '12px 32px 0px',
          padding: 16,
          position: 'relative',
        }}
      >
        <div>
          <div className="mb1">
            <FormControlLabel
              control={
                <Switch
                  checked={isClientMode}
                  onChange={() => {
                    setIsClientMode(!isClientMode);
                    clearDatatable();
                  }}
                  color="primary"
                />
              }
              label="Client Mode"
            />
          </div>
          <ButtonGroup color="primary" aria-label="outlined primary button group" size="small">
            {isCallCenterAdmin() && (
              <Button>
                <NavLink to="/admin/outreach">Admin</NavLink>
              </Button>
            )}
            {isBasicUser(authContext.roles) && (
              <Button>
                <NavLink to="/spreadsheets/notes">Outstanding Report</NavLink>
              </Button>
            )}
            <Button color="primary" disabled={!allowResolve} onClick={() => setIsModalOpen(true)}>
              Resolve
            </Button>
          </ButtonGroup>
        </div>

        <div css={{ marginLeft: 16, paddingLeft: 16, borderLeft: '1px solid #e5e5e5' }}>
          {isClientMode ? (
            clients.length !== 0 && (
              <div css={{ width: 260 }}>
                {!selectedClient.id && <span className="mb1 db">Please select a client</span>}
                <Dropdown
                  options={clients}
                  placeholder="Clients"
                  // value={selectedClient}
                  onChange={selected => {
                    setSelectedClient(selected);
                    loadData(selected.id);
                  }}
                  isSearchable
                />
              </div>
            )
          ) : (
            <div style={{ width: '315px' }}>
              {!selectedTitleCompany.id && (
                <span className="mb1 db">
                  Please select a title company or click
                  <Button
                    onClick={() => loadData()}
                    disabled={!!selectedTitleCompany.id}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Here
                  </Button>
                </span>
              )}
              {
                <div css={{ marginTop: 8 }}>
                  <SearchSelect
                    placeholder="Search Title Companies"
                    loadOptions={loadTitleCompanyOptions}
                    label="Title company name"
                    filterConfig={{ matchFrom: 'all' }}
                    onChange={selected => {
                      setSelectedTitleCompany(selected);
                      loadData(selected.id);
                    }}
                    isAsync
                  />
                </div>
              }
            </div>
          )}
        </div>
      </div>
      <div
        css={[
          {
            // alignItems: 'flex-start',
            backgroundColor: '#fff',
            borderRadius: 5,
            display: 'flex',
            justifyContent: 'space-between',
            margin: '12px 32px 0px',
            position: 'relative',
            width: 0,
            overflow: 'hidden',
            transition: 'all .3s',
          },
          !!datatableData.length && {
            padding: 16,
            border: '1px solid #eee',
            width: 'auto',
          },
        ]}
      >
        {!!datatableData.length && (
          <Fragment>
            {isClientMode && (
              <InfoBox css={{ paddingRight: 16 }} className="">
                <div css={{ marginBottom: 4, borderBottom: '1px solid #aaa', paddingBottom: 8 }}>
                  <div className="bold fs16">Client Inbox</div>
                  <span css={{ color: 'blue' }}>{selectedClient.clientInbox}</span>
                </div>
                <div className="mt1">
                  <b className="fs16">
                    {selectedClient.address.includes('1133') ? 'MAIL CODE' : 'PO Box'}
                  </b>
                  <div>
                    <div>{selectedClient.label} / CO DocProbe</div>
                    {poBoxDisplay(selectedClient)}
                  </div>
                </div>
              </InfoBox>
            )}
            <InfoBox css={{ paddingLeft: 16 }} className="">
              <div className="p1 pt0">
                <div className="bold fs16">Title Company</div>
                <div>{datatableData[0].titleCompany}</div>
                <div>
                  <a
                    className={classes.linkStyle}
                    href={`tel:${datatableData[0].titleCompanyPhoneGlobal?.replace(/\D/g, '')}`}
                  >
                    {datatableData[0].titleCompanyPhoneGlobal}
                  </a>
                </div>
                <div>{datatableData[0].titleCompanyEmailGlobal}</div>
                <div
                  css={{
                    padding: '4px 8px',
                    backgroundColor:
                      datatableData[0].timeZone.toLowerCase() !== 'america/new_york'
                        ? 'yellow'
                        : 'transparent',
                  }}
                >
                  Local Time: {localTime}
                </div>
              </div>
              <div
                css={{
                  marginTop: 8,
                  padding: '10px 8px 0',
                  borderTop: '1px solid #aaa',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                <div>
                  <span style={{ color: 'red' }}>{loansRemaining}</span> unresolved loans
                </div>
                {escalationCount > 0 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    View
                    <Box sx={{ color: 'primary.main', mx: 0.5, fontWeight: 'medium' }}>
                      {escalationCount}
                    </Box>
                    escalations
                    <OpenInNewIcon
                      style={{ marginLeft: '8px' }}
                      color="primary"
                      fontSize="small"
                      onClick={() =>
                        window.open(
                          `/outreach-escalations?titleCompany=${selectedTitleCompany.label}`,
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </InfoBox>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Dashboard;

const infoBoxStyles = {
  // padding: 12,
  // borderRadius: 5,
  // backgroundColor: '#F7F7F9',
  borderRight: '1px solid #aaa',
  minWidth: 210,
  ':last-of-type': {
    border: 'none',
  },
};

function InfoBox({ children, className, css }) {
  return (
    <div css={[infoBoxStyles, css]} className={className}>
      {children}
    </div>
  );
}
