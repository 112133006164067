/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DataTable from '../../components/ui/DataTable/DataTable';
import Grid from '@mui/material/Grid';
import { apiFetch } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';
import dotObject from 'dot-object';
import { useInvestors } from '../../Hooks/useInvestors';
import Autocomplete from '@mui/material/Autocomplete';
import { Investor } from '../../globalTypes/objects';
import DatePicker from 'react-datepicker';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  filters: {
    '& .react-datepicker-popper': { zIndex: '9999 !important' },
  },
});

type LoadState = 'initial' | 'loading' | 'loaded';

type RowData = {
  client: string;
  barcode: number;
  appNumber: string;
  borrower: string;
  investorNum: string;
  propertyAddress: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  dateFunded: Date;
  documentType: string;
  trackingNumber: string;
  dateSent: Date;
};

type ReportFilters = {
  trackingNumber: string | null;
  rangeStart: Date | null;
  rangeEnd: Date | null;
  investorId: number | null;
};

const canSubmit = (filters: ReportFilters) =>
  Boolean(filters.trackingNumber?.length || filters.rangeStart);

const loadReportData = async (filters: ReportFilters) => {
  const x = dotObject.dot({ filters });
  const { data } = await apiFetch<RowData[]>('/api/reports/shipment-tracking', {
    params: x,
  });
  return data;
};

const columns = [
  {
    field: 'client',
    title: 'Lender',
  },
  {
    field: 'appNumber',
    title: 'Loan Number',
  },
  {
    field: 'borrower',
    title: 'Name',
  },
  {
    field: 'investorNum',
    title: 'Investor Number',
  },
  {
    field: 'propertyAddress',
    title: 'Address',
  },
  {
    field: 'city',
    title: 'City',
  },
  {
    field: 'state',
    title: 'State',
  },
  {
    field: 'zip',
    title: 'Zip',
  },
  {
    field: 'county',
    title: 'County',
  },
  {
    field: 'dateFunded',
    title: 'Date Funded',
    render: (rowData: RowData) =>
      rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString(),
  },
  {
    field: 'barcode',
    title: 'Barcode',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'trackingNumber',
    title: 'Tracking Number',
  },
  {
    field: 'dateSent',
    title: 'Date Sent',
    render: (rowData: RowData) =>
      rowData.dateFunded && new Date(rowData.dateSent).toLocaleDateString(),
  },
];

const ShippingTrackingReport = () => {
  const [datatableData, setDatatableData] = useState<RowData[]>([]);
  const [reportFilters, setReportFilters] = useState<ReportFilters>({
    trackingNumber: null,
    rangeStart: null,
    rangeEnd: null,
    investorId: null,
  });
  const [loadState, setLoadState] = useState<LoadState>('initial');

  const investors = useInvestors();
  const { errorToaster } = useToaster();
  const classes = useStyles();

  return (
    <Fragment>
      <div className={`my3 mx4 df jcsb ${classes.filters}`}>
        <div>
          <TextField
            label="Tracking #"
            variant="outlined"
            style={{ width: 300 }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setReportFilters(x => ({ ...x, trackingNumber: e.target.value || null }))
            }
          />
        </div>
        <div>
          <DatePicker
            maxDate={new Date()}
            selected={reportFilters.rangeStart}
            selectsStart
            startDate={reportFilters.rangeStart}
            endDate={reportFilters.rangeEnd}
            placeholderText="Select a start date"
            customInput={<TextField style={{ width: 300 }} />}
            onChange={startDate => setReportFilters(x => ({ ...x, rangeStart: startDate }))}
          />
        </div>
        <div>
          <DatePicker
            maxDate={new Date()}
            selected={reportFilters.rangeEnd}
            selectsEnd
            startDate={reportFilters.rangeStart}
            endDate={reportFilters.rangeEnd}
            placeholderText="Select an end date"
            customInput={<TextField style={{ width: 300 }} />}
            onChange={endDate => setReportFilters(x => ({ ...x, rangeEnd: endDate }))}
          />
        </div>
        <div>
          <Autocomplete
            options={investors}
            getOptionLabel={(option: Investor) => option.name || ''}
            value={investors.find(x => x.id === reportFilters.investorId)}
            onChange={(event: any, newValue: Investor | null) =>
              setReportFilters(x => ({ ...x, investorId: newValue ? newValue.id : null }))
            }
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose an investor" variant="outlined" />
            )}
          />
        </div>
        <div>
          <Button
            style={{ marginLeft: '15px' }}
            variant="contained"
            color="primary"
            disabled={!canSubmit(reportFilters)}
            onClick={async () => {
              if (!canSubmit(reportFilters)) {
                return;
              }

              try {
                setLoadState('loading');
                const reportData = await loadReportData(reportFilters);
                setDatatableData(reportData);
              } catch (e) {
                errorToaster(e.response?.data || 'Failed to load report');
              } finally {
                setLoadState('loaded');
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>

      {loadState === 'initial' && <Fragment />}
      {loadState === 'loading' && (
        <div className="center-in-parent">
          <CircularProgress size="45" disableShrink />
        </div>
      )}
      {loadState === 'loaded' && (
        <Grid container>
          <Grid item xs={12}>
            <DataTable<RowData>
              title="Shipment Tracking Report"
              columns={columns}
              data={datatableData}
              options={{
                exportFileName: 'Shipment Tracking Report',
              }}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default ShippingTrackingReport;
