import React, { createContext, useState, useMemo, useContext } from 'react';
import { apiPost } from '../../adalConfig';
import { TitleCompany } from '../../globalTypes/objects';

export type TitleCompaniesContext = {
  data: TitleCompany[];
  selectedIds: Set<number>;
  selectedRows: TitleCompany[];
  sortOptions: { column: string | number; directionAscending: boolean };
  searchText: string;
  paginationOptions: { rowsPerPage: number; page: number; totalCount: number };
  isExporting: boolean;
  selectionMode: boolean;
  consolidationMode: ConsolidationMode;
  mainConsolidatedTitleCompany: TitleCompany | undefined;
  processingConsolidation: boolean;
  leavingConsolidationWarning: boolean;
  consolidationError: boolean;
};

export enum ConsolidationMode {
  False,
  True,
  Edit,
}

type TitleCompaniesContextValue = [
  TitleCompaniesContext,
  (updateTitleCompanies: Partial<TitleCompaniesContext>) => void,
];

export const initialState: TitleCompaniesContext = {
  data: [],
  selectedIds: new Set<number>(),
  selectedRows: [],
  sortOptions: { column: '1', directionAscending: true },
  searchText: '',
  paginationOptions: { rowsPerPage: 25, page: 0, totalCount: -1 },
  isExporting: false,
  selectionMode: false,
  consolidationMode: ConsolidationMode.False,
  mainConsolidatedTitleCompany: undefined,
  processingConsolidation: false,
  leavingConsolidationWarning: false,
  consolidationError: false,
};
const TitleCompaniesContext = createContext({} as TitleCompaniesContextValue);

function TitleCompaniesContextProvider(props) {
  const [titleCompanies, setTitleCompanies] = useState<TitleCompaniesContext>(initialState);
  const value = useMemo(() => {
    const setTitleCompaniesFunc = (obj: Partial<TitleCompaniesContext>) =>
      setTitleCompanies({ ...titleCompanies, ...(obj as TitleCompaniesContext) });
    return [titleCompanies, setTitleCompaniesFunc];
  }, [titleCompanies]);
  return <TitleCompaniesContext.Provider value={value} {...props} />;
}

function useTitleCompanies() {
  const context = useContext(TitleCompaniesContext);
  if (!context) {
    throw new Error('useTitleCompanies must be used within a TitleCompaniesProvider');
  }
  return context;
}

export { TitleCompaniesContextProvider, useTitleCompanies };

export const consolidateTitleCompanies = async (
  titleCompany: TitleCompany,
  titleCosToDelete: number[],
) => {
  await apiPost('/api/titleCompanies/consolidateTitleCompanies', {
    correctTitleCoId: titleCompany.id,
    titleCosToDelete,
  });
};

export const updateTitleCompany = async (titleCompany: TitleCompany) => {
  await apiPost('/api/titleCompanies/update', {
    ...titleCompany,
    useExistingOutreachSettings: true,
  });
};
