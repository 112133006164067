import React from 'react';
import * as icons from './icons';

export enum IconType {
  Billing = 'BillingIcon',
  Check = 'CheckIcon',
  ImportDocs = 'ImportDocsIcon',
  Cursor = 'CursorIcon',
  Flag = 'FlagIcon',
  TrashCan = 'TrashCanIcon',
  Dashboard = 'DashBoardIcon',
  DigitalDelivery = 'DigitalDeliveryIcon',
  Directory = 'DirectoryIcon',
  Docs = 'DocsIcon',
  DownArrowIcon = 'DownArrowIcon',
  Down = 'DownIcon',
  Download = 'DownloadIcon',
  Errors = 'ErrorsIcon',
  Filter = 'FilterIcon',
  FollowUps = 'FollowUpsIcon',
  Import = 'Import',
  Ledgers = 'LedgersIcon',
  LeftArrow = 'LeftArrowIcon',
  Loans = 'Loans',
  Note = 'NoteIcon',
  Plus = 'PlusIcon',
  Payment = 'PaymentIcon',
  Processing = 'Processing',
  Reports = 'ReportsIcon',
  Return = 'ReturnIcon',
  RightArrow = 'RightArrowIcon',
  RightCaret = 'RightCaretIcon',
  Settings = 'SettingsIcon',
  Shipping = 'ShippingIcon',
  Tasks = 'TasksIcon',
  Unassociated = 'UnassociatedIcon',
  Unknowns = 'UnknownsIcon',
  UpArrow = 'UpArrowIcon',
  X = 'XIcon',
  Calendar = 'CalendarIcon',
  BurgerMenu = 'BurgerMenuIcon',
  XLarge = 'XLargeIcon',
  XLargeBold = 'XLargeBoldIcon',
  XMedium = 'XMediumIcon',
  XMediumLarge = 'XMediumLargeIcon',
  Search = 'SearchIcon',
  More = 'MoreIcon',
  NoResults = 'NoResultsIcon',
  DragDrop = 'DragDropIcon',
  ErrorFace = 'ErrorFaceIcon',
  GotItFace = 'GotItFaceIcon',
  Lock = 'LockIcon',
  MailLarge = 'MailLargeIcon',
  NoErrors = 'NoErrorsIcon',
  NoResultsLarge = 'NoResultsLargeIcon',
  SuccessFace = 'SuccessFaceIcon',
  NoNotes = 'NoNotesIcon',
  Client = 'ClientIcon',
  Investor = 'InvestorIcon',
  InvestorSmall = 'InvestorSmallIcon',
  PlusLarge = 'PlusLargeIcon',
  TitleCompany = 'TitleCompanyIcon',
  ClientLarge = 'ClientLargeIcon',
  InvestorLarge = 'InvestorLargeIcon',
  TitleCompanyLarge = 'TitleCompanyLargeIcon',
  ResolvedFolder = 'ResolvedFolderIcon',
  Printer = 'PrinterIcon',
  PrinterSmall = 'PrinterSmallIcon',
  Pencil = 'PencilIcon',
  NoFileFace = 'NoFileFaceIcon',
  HoverFace = 'HoverFaceIcon',
  Active = 'ActiveIcon',
  Spreadsheet = 'SpreadsheetIcon',
  Account = 'AccountIcon',
  Phone = 'PhoneIcon',
  Stapler = 'StaplerIcon',
  EmailIcon = 'EmailIcon',
  InfoIcon = 'InfoIcon',
  StapleSuccessIcon = 'StapleSuccessIcon',
  StapleErrorIcon = 'StapleErrorIcon',
  StapleWarningIcon = 'StapleWarningIcon',
  StaplePrimaryIcon = 'StaplePrimaryIcon',
  BlackHole = 'BlackHoleIcon',
  Mapping = 'MappingIcon',
}

type IconProps = {
  icon: IconType;
  [key: string]: any;
};
export default function Icon({ icon, ...props }: IconProps) {
  const IconImage = icons[icon];
  return <IconImage {...props} />;
}
