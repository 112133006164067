/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { format } from 'date-fns';
import { apiFetch } from '../../adalConfig';
import { DocumentEmail, FileMetadata, TitleCompanyUpload } from '../../globalTypes/objects';

interface PropTypes {
  docId: number;
}

async function getSourceEmail(docId) {
  return (await apiFetch<DocumentEmail>(`/Api/DocumentEmail/GetDocumentEmail/${docId}`)).data;
}

async function getTitleCompanyUpload(docId) {
  return (await apiFetch<TitleCompanyUpload>(`/Api/Documents/GetTitleCompanyUpload/${docId}`)).data;
}

async function getOriginalFileName(docId) {
  return (
    await apiFetch<FileMetadata>('/Api/Documents/GetFileMetadata', {
      params: { documentId: docId },
    })
  ).data.originalFilename;
}

export default function DocumentImportInformation({ docId }: PropTypes) {
  const [documentEmail, setDocumentEmail] = useState<DocumentEmail>();
  const [titleCompanyUpload, setTitleCompanyUpload] = useState<TitleCompanyUpload>();
  const [originalFilename, setOriginalFilename] = useState('');
  const [emailContents, setEmailContents] = useState<string>();
  const [openContents, setOpenContents] = useState(false);

  useEffect(() => {
    if (docId) {
      getOriginalFileName(docId).then(fileName => setOriginalFilename(fileName));
      getSourceEmail(docId).then(email => {
        if (email) {
          setDocumentEmail(email);
        }
      });
      getTitleCompanyUpload(docId).then(upload => {
        if (upload) {
          setTitleCompanyUpload(upload);
        }
      });
    }
  }, [docId]);

  const onViewContents = async () => {
    if (emailContents == null) {
      const { data } = await apiFetch<string>(
        `/Api/DocumentEmail/GetDocumentEmailContents/${docId}`,
      );
      setEmailContents(data);
    }
    setOpenContents(true);
  };

  return (
    <div>
      {(originalFilename ||
        documentEmail ||
        titleCompanyUpload?.generalUpload ||
        titleCompanyUpload?.email) && (
        <div
          css={{
            backgroundColor: 'white',
            padding: 32,
            borderRadius: 4,
            border: '1px solid #e5e5ea',
            width: 328,
          }}
        >
          {originalFilename && (
            <div>
              <div css={{ fontWeight: 'bold' }}>Original Filename:</div>
              <div>{originalFilename}</div>
            </div>
          )}

          {titleCompanyUpload?.email && (
            <div>
              <div
                css={{
                  fontWeight: 'bold',
                  backgroundColor: '#D0ECE7',
                  padding: 4,
                  width: 136,
                  color: '#0E6655',
                }}
              >
                Title Portal Upload
              </div>
              <br />
              {titleCompanyUpload.email && (
                <div>
                  <div css={{ fontWeight: 'bold' }}>Uploaded By:</div>
                  <div>{titleCompanyUpload.email}</div>
                  <br />
                </div>
              )}
            </div>
          )}

          {titleCompanyUpload?.generalUpload && (
            <div>
              <div css={{ fontWeight: 'bold' }}>User Entered Loan Information</div>
              <br />
              {titleCompanyUpload.loanNumber && (
                <div>
                  <div css={{ fontWeight: 'bold' }}>Loan Number:</div>
                  <div>{titleCompanyUpload.loanNumber}</div>
                  <br />
                </div>
              )}
              {titleCompanyUpload.borrowerName && (
                <div>
                  <div css={{ fontWeight: 'bold' }}>Borrower Name:</div>
                  <div>{titleCompanyUpload.borrowerName}</div>
                  <br />
                </div>
              )}
              {titleCompanyUpload.dateFunded && (
                <div>
                  <div css={{ fontWeight: 'bold' }}>Date Funded:</div>
                  <div>
                    {format(Date.parse(titleCompanyUpload.dateFunded.toString()), 'MM/dd/yy')}
                  </div>
                </div>
              )}
            </div>
          )}

          {documentEmail && (
            <div>
              {originalFilename && <br />}
              <div css={{ fontWeight: 'bold' }}>Email Subject:</div>
              <div>{documentEmail.emailSubject}</div>
              <br />
              <div css={{ fontWeight: 'bold' }}>Email From:</div>
              <div>{documentEmail.emailFrom}</div>
              <br />
              <Button variant="outlined" onClick={onViewContents}>
                View Email Contents
              </Button>
            </div>
          )}
        </div>
      )}

      {emailContents && (
        <div>
          <Dialog open={openContents} keepMounted onClose={() => setOpenContents(false)}>
            <DialogTitle>Email Contents</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                {emailContents}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
}
