/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import colors from '../styles/colors';

const innerContentWrap = {
  padding: '16px 40px',
  backgroundColor: colors.grayExLight,
};

export default ({ children }) => <div css={innerContentWrap}>{children}</div>;
