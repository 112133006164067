/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Dropdown from '../../ui/Dropdown';
import { useGetData } from '../../../Hooks';
import { apiPost } from '../../../adalConfig';
import ConditionalComponent from '../../ConditionalComponent';
import { useToaster } from '../../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      maxWidth: 1100,
      width: '100%',
      margin: '10px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }),
);

type Item = {
  id: number;
  label: string;
};

const Filters = () => {
  const initialClientValue = { id: 0, label: '' };
  const { data: clients, isLoading } = useGetData<Item[]>('/api/call-tasks/basic-client-data', []);
  const [selectedClient, setSelectedClient] = useState<Item>(initialClientValue);
  const [filterAllClients, setFilterAllClients] = useState<boolean>(true);
  const [daysToBeIgnored, setDaysToBeIgnored] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const { successToaster, errorToaster } = useToaster();

  const classes = useStyles();

  const save = async () => {
    if (!filterAllClients && !selectedClient.id) {
      errorToaster('Please select a client or select to update all clients');
      return;
    }

    if (daysToBeIgnored === 0) {
      errorToaster('Please select an option from the dropdown');
      return;
    }

    setIsSaving(true);

    try {
      await apiPost('/api/call-tasks/filter-tasks', {
        clientId: selectedClient.id,
        days: daysToBeIgnored,
      });

      successToaster('Updated successfully');
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }

    setIsSaving(false);
  };

  return (
    <Grid container spacing={3} className={classes.dropdownContainer}>
      {isLoading ? (
        <div css={{ margin: '0 auto' }}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <Fragment>
          <div>
            <Fragment>
              <RadioGroup
                css={{ marginTop: '1rem' }}
                value={filterAllClients ? 'all' : 'single'}
                onChange={(e, val) => {
                  if (val === 'all') {
                    setSelectedClient(initialClientValue);
                  }
                  setFilterAllClients(val === 'all');
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="All Clients"
                />
                <FormControlLabel
                  value="single"
                  control={<Radio color="primary" />}
                  label="Select A Client"
                />
              </RadioGroup>
            </Fragment>

            <ConditionalComponent display={clients.length && !filterAllClients}>
              <div
                className="df aic mb1"
                css={{ '> div': { width: 228, backgroundColor: '#fff', cursor: 'pointer' } }}
              >
                {!selectedClient.id && <span className="mr2">Please select a client</span>}
                <Dropdown
                  options={clients}
                  placeholder="Clients"
                  value={selectedClient}
                  onChange={selected => setSelectedClient(selected)}
                  isSearchable
                />
              </div>
            </ConditionalComponent>
          </div>

          <ConditionalComponent display={filterAllClients || selectedClient.id}>
            <Grid item xs={4}>
              <Card>
                <CardContent>
                  <h2>Please do not display loans more recent than</h2>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="select-label">Days</InputLabel>
                    <Select
                      labelId="select-label"
                      value={daysToBeIgnored}
                      onChange={(e: React.ChangeEvent<{ value: number }>) =>
                        setDaysToBeIgnored(e.target.value)
                      }
                    >
                      {[15, 21, 30, 45, 60, 75, 90, 120, 180].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
                <div className="df jcfe mb2">
                  <CardActions>
                    {isSaving ? (
                      <div className="center-in-parent" style={{ height: '40px', width: '80px' }}>
                        <CircularProgress size="20" disableShrink />
                      </div>
                    ) : (
                      <Button color="primary" onClick={save}>
                        Submit
                      </Button>
                    )}
                  </CardActions>
                </div>
              </Card>
            </Grid>
          </ConditionalComponent>
        </Fragment>
      )}
    </Grid>
  );
};

export default Filters;
