/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import words from 'lodash/words';
import { apiPost } from '../../adalConfig';
import { FlaggedDoc } from '../../globalTypes/objects';
import { getDocTypeName } from '../../Utils';
import SplitButton from './SplitButton';
import TablePaginationActions from './TablePaginationActions';
import AlertDialogSlide from '../../components/Documents/DocumentDeleteAlert';

type Props = {
  flaggedDocs: FlaggedDocumentWithDetail[];
  setFlaggedDocs: Dispatch<SetStateAction<FlaggedDocumentWithDetail[]>>;
};

type RowProps = {
  row: FlaggedDocumentWithDetail;
  setFlaggedDocs: Dispatch<SetStateAction<FlaggedDocumentWithDetail[]>>;
};

export interface FlaggedDocumentDetails {
  documentId: number;
  flaggedId: number;
  receivedDate: Date;
  auditDate: Date | null;
  passed: boolean;
}
export type FlaggedDocumentWithDetail = FlaggedDoc & {
  documentDetails: FlaggedDocumentDetails[];
};

function Row({ row, setFlaggedDocs }: RowProps) {
  const [open, setOpen] = React.useState(false);
  const removeCurrentRow = () => setFlaggedDocs(rows => rows.filter(fdRow => fdRow.id !== row.id));
  const [confirmUnflag, setConfirmUnflag] = useState<boolean>(false);
  const [flaggedDocument, setFlaggedDocument] = useState<FlaggedDocumentWithDetail>(
    {} as FlaggedDocumentWithDetail,
  );
  const actionClickHandler = (option: string, fd: FlaggedDocumentWithDetail) => {
    if (option.toLowerCase() === 'unflag') {
      setFlaggedDocument(fd);
      setConfirmUnflag(true);
    }
    if (option.toLowerCase() === 'audit') {
      if (fd.documentDetails?.length === 1) {
        const url = `/documents/${fd.documentDetails[0].documentId}`;
        window.open(url, '_blank');
      }
    }
  };
  function unFlag() {
    apiPost(`/api/documents/unflagdocument`, flaggedDocument);
    removeCurrentRow();
    setConfirmUnflag(false);
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.client?.company}
        </TableCell>
        {row.loanId && (
          <TableCell align="left">
            {row.loanId && (
              <Link target="_blank" href={`/loans/${row.loanId}`}>
                {row?.loanNumber}
              </Link>
            )}
          </TableCell>
        )}
        {row.loanId == null && <TableCell align="left">{row.loanNumber}</TableCell>}
        <TableCell align="left">
          <Link underline="hover" onClick={() => setOpen(!open)}>
            {row.documentDetails?.length}
          </Link>
        </TableCell>
        <TableCell align="left">{words(getDocTypeName(row.documentType)).join(' ')}</TableCell>
        <TableCell align="left">{new Date(row?.createdAt).toLocaleDateString()}</TableCell>
        <TableCell align="left">{row.createdBy?.split('@')[0]}</TableCell>
        <TableCell align="left">{row.borrower}</TableCell>
        <TableCell align="left">{row.address}</TableCell>
        <TableCell align="left">
          <AlertDialogSlide
            isOpen={confirmUnflag}
            handleClose={() => setConfirmUnflag(false)}
            onConfirm={() => unFlag()}
            disableConfirm={false}
            confirmationHeader="Unflag Document"
            confirmationText="Are you sure you want to unflag this document?"
          />

          <SplitButton
            onOptionSelected={option => actionClickHandler(option, row)}
            options={['Action...', 'Unflag', 'Audit']}
            disabledItemIndex={
              row.documentDetails?.length !== 1 ||
              (row.documentDetails && row.documentDetails[0].auditDate != null)
                ? [2]
                : [0]
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div css={{ padding: 10 }}>
                <b>Reason</b>
              </div>
              <div css={{ paddingLeft: 10, paddingBottom: 10 }}>{row.reason}</div>
              {row.documentDetails && (
                <div>
                  <b css={{ paddingLeft: 10, paddingTop: 10 }}>
                    {row.documentDetails.length > 0 ? 'Documents' : ''}
                  </b>
                  <div css={{ padding: 10 }}>
                    {row.documentDetails.sort().map((documentDetails: FlaggedDocumentDetails) => (
                      <div key={documentDetails.documentId}>
                        <div
                          css={{
                            display: 'grid',
                            gridTemplateColumns: '7% 15% 15%',
                          }}
                        >
                          <div style={{ whiteSpace: 'pre-wrap' }}>
                            <Link target="_blank" href={`/documents/${documentDetails.documentId}`}>
                              {documentDetails.documentId}
                            </Link>
                          </div>
                          <div css={{ paddingRight: 10 }}>
                            <b>Received:</b>{' '}
                            {new Date(documentDetails.receivedDate).toLocaleDateString()}
                          </div>
                          {documentDetails.auditDate && (
                            <div>
                              {!documentDetails.passed && <b>Failed: </b>}
                              {documentDetails.passed && <b>Passed: </b>}
                              {new Date(documentDetails.auditDate).toLocaleDateString()}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function FlaggedDocsList({ flaggedDocs, setFlaggedDocs }: Props) {
  const rows = flaggedDocs;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - flaggedDocs.length) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Client</TableCell>
            <TableCell align="left">Loan Number</TableCell>
            <TableCell align="left">Total Docs</TableCell>
            <TableCell align="left">Doc Type</TableCell>
            <TableCell align="left">Date Flagged</TableCell>
            <TableCell align="left">User</TableCell>
            <TableCell align="left">Borrower</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left"> </TableCell>
            <TableCell align="left"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map(row => (
            <Row key={row.id} row={row} setFlaggedDocs={setFlaggedDocs} />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={11}
              count={flaggedDocs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
