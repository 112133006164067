/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useCallback, useEffect, useState } from 'react';
import ShortId from 'shortid';
import debounce from 'lodash/debounce';
import format from 'date-fns/format';

import { Link } from 'react-router-dom';
import { Client, Document, Loan } from '../../globalTypes/objects';
import { apiFetch } from '../../adalConfig';
import useInfiniteScroll from '../../Hooks/useInfiniteScroll';
import { DebounceRate } from '../../Utils';
import { usePrevious } from '../../Hooks';
import SearchFilter from '../ui/SearchFilter';
import Icon, { IconType } from '../ui/Icon';
import colors from '../../styles/colors';
import Circle from '../ui/Circle';
import { LargeRow, LargeRowHeader } from '../ui/LargeRowComponents';
import { ShippingHeader } from '../../pages/Shipping';
import { Radio, RadioGroup } from '../ui/Radio';
import CircularProgress from '@mui/material/CircularProgress';

const radioStyles = [
  {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    marginLeft: '5rem',
  },
  { label: { width: 'max-content' } },
];

export type Shipment = {
  id: number;
  dateSent: Date;
  trackingNumber: string;
  documents: { document: Document; loan: Loan }[];
  createdAt: Date;
  docCount: number;
  clients: Client[];
  investor: string;
};

type SearchBy = 'TRACKING' | 'BARCODE' | 'INVESTOR';

const LOAD_AMOUNT = 50;
export default function Shipments() {
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchBy, setSearchBy] = useState<SearchBy>('TRACKING');

  const getShipments = useCallback(async () => {
    const sortedIds = shipments.map(s => s.id).sort();
    const lastId = sortedIds.length > 0 ? sortedIds[0] : 0;
    const { data: fetchedShipments } = await apiFetch<Shipment[]>(
      `/api/shipping/shipmentsWithDocCount?lastId=${lastId}&amount=${LOAD_AMOUNT}`,
    );
    setShipments(s => [...s, ...fetchedShipments]);
  }, [shipments]);

  const [isFetching, setScrollingDisabled] = useInfiniteScroll(getShipments);

  // load shipments on initial render
  useEffect(() => {
    if (shipments.length === 0) {
      const fetchShipments = async () => {
        await getShipments();
        setInitialLoading(false);
      };
      fetchShipments();
    }
  }, [getShipments, shipments.length]);

  const search = async (text: string, searchingBy: SearchBy) => {
    const { data: fetchedShipments } = await apiFetch<Shipment[]>(
      `/api/shipping/searchShipments?search=${text}&searchBy=${searchingBy}`,
    );
    setShipments(fetchedShipments);
  };
  const debouncedSearch = useCallback(debounce(search, DebounceRate), []);

  const prevSearch = usePrevious(searchText);

  useEffect(() => {
    if (searchText && searchText !== prevSearch) {
      // @ts-ignore
      setScrollingDisabled(true);
      debouncedSearch(searchText, searchBy);
    }
    if (!searchText && prevSearch) {
      // @ts-ignore
      setScrollingDisabled(false);
      setShipments([]);
    }
  }, [debouncedSearch, prevSearch, searchText, searchBy, setScrollingDisabled]);

  const placeholderText = () => {
    switch (searchBy) {
      case 'BARCODE':
        return `Enter Barcode`;
      case 'INVESTOR':
        return 'Enter Investor';
      case 'TRACKING':
        return 'Enter Tracking Number';
      default:
        throw Error(`Unknown search by ${searchBy}`);
    }
  };

  return (
    <Fragment>
      <div css={{ paddingTop: 56 }}>
        <ShippingHeader fixed />
        <div css={{ maxWidth: 1260, padding: '32px 40px' }}>
          <div css={{ display: 'flex' }}>
            <SearchFilter
              type="text"
              excludeHelperText
              clearSearch={() => setSearchText('')}
              placeholder={placeholderText()}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
            <RadioGroup styles={radioStyles} handleChange={val => setSearchBy(val)}>
              <Radio text="Tracking #" value="TRACKING" />
              <Radio text="Document Barcode" value="BARCODE" />
              <Radio text="Investor" value="INVESTOR" />
            </RadioGroup>
          </div>
          <LargeRowHeader styles={goodGrid}>
            <div>Investor</div>
            <div>Tracking Number</div>
            <div>Date Sent</div>
            <div>Client(s)</div>
            <div>Doc Amount</div>
          </LargeRowHeader>
          {shipments.map(s => (
            <ShippingRow key={ShortId.generate()} shipment={s} />
          ))}
          {isFetching && (
            <div className="center-in-parent">
              <CircularProgress size="45" disableShrink />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

const goodGrid = {
  display: 'grid',
  gridTemplateColumns: '5fr 4fr 3fr 5fr 1fr',
  alignItems: 'center',
  gridColumnGap: '8px',
  div: { display: 'inline-flex', alignItems: 'center' },
};

const ShippingRow = ({ shipment }: { shipment: Shipment }) => {
  return (
    <Link to={`/shipping/shipments/${shipment.id}`}>
      <LargeRow styles={goodGrid}>
        <div>{shipment.investor}</div>
        <div>
          <Icon icon={IconType.Shipping} color={colors.blue} css={{ marginRight: 8 }} />
          {shipment.trackingNumber}
        </div>
        <div>
          <Icon icon={IconType.Calendar} stroke={colors.blue} css={{ marginRight: 8 }} />
          {format(new Date(Date.parse(shipment.dateSent)), 'MM/dd/yyyy')}
        </div>
        <div>{shipment.clients.map(c => c.company).join(', ')}</div>
        <div>
          <Circle
            small
            text={shipment.docCount}
            styleoverrides={{ backgroundColor: colors.grayLight }}
          />
        </div>
      </LargeRow>
    </Link>
  );
};
