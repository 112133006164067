/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import ShortId from 'shortid';

import StepCircle, { StepStatusTypes } from '../../ui/StepCircle';
import colors from '../../../styles/colors';

// #region css
const sideBarWrap = {
  display: 'inline-flex',
  flexDirection: 'column',
  margin: '0 auto 40px',
  maxWidth: 620,
  overflow: 'hidden',
  width: 312,
};

const stepWrap = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const stepNameStyles = {
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 700,
  marginLeft: 16,
  maxWidth: 195,
};

const editButton = {
  color: colors.blue,
  fontWeight: 700,
};
// #endregion

export type StepData = {
  stepNumber: number;
  name: string;
  status: StepStatusTypes;
  completeData?: string[];
  editDisabled?: boolean;
};

type StepperProps = {
  currentStep: number;
  onEditStep: (step: number) => void;
  stepData: StepData[];
  submitted: boolean;
};

export default function Stepper({ currentStep, onEditStep, submitted, stepData }: StepperProps) {
  return (
    <div css={sideBarWrap}>
      {stepData.map(step => (
        <Step
          key={step.stepNumber}
          stepNumber={step.stepNumber}
          stepName={step.name}
          status={(step.stepNumber === currentStep && StepStatusTypes.active) || step.status}
          onEditStep={onEditStep}
          completeData={step.completeData}
          submitted={submitted}
        />
      ))}
    </div>
  );
}

type StepCircleProps = {
  stepNumber: number;
  stepName: string;
  status: StepStatusTypes;
  onEditStep?: (step: number) => void;
  completeData?: string[];
  submitted: boolean;
};

function Step({
  stepNumber,
  stepName,
  status,
  onEditStep,
  completeData,
  submitted,
}: StepCircleProps) {
  return (
    <div css={{ marginBottom: 32 }}>
      <div css={stepWrap}>
        <div css={{ display: 'flex', alignItems: 'center' }}>
          <StepCircle text={stepNumber} status={status} />
          <p css={stepNameStyles}>{stepName}</p>
        </div>

        {status === StepStatusTypes.completed && !submitted && onEditStep && (
          <button css={editButton} onClick={() => onEditStep(stepNumber)}>
            Edit
          </button>
        )}
      </div>

      {status === StepStatusTypes.completed && (
        <div css={{ marginLeft: 66, marginTop: 4 }}>
          {completeData?.map(data => (
            <Fragment key={ShortId.generate()}>
              <p>{data}</p>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
