/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DigitalDeliverySettings from './DigitalDelivery/DigitalDeliverySettings';
import DigitalDeliverySettingsListing from './DigitalDelivery/DigitalDeliverySettingsListing';
import TabPanel from '../components/ui/Tabs/MaterialTabPanel';

const DigitalDelivery = () => {
  const [tab, setTab] = useState(0);

  return (
    <div className="p2">
      <Tabs
        indicatorColor="primary"
        value={tab}
        textColor="primary"
        onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTab(newValue)}
      >
        {['Create/Update Settings', 'View Settings'].map((tabLabel, i) => (
          <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
        ))}
      </Tabs>
      <div css={{ paddingTop: '15px' }}>
        <TabPanel value={tab} index={0}>
          <DigitalDeliverySettings />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <DigitalDeliverySettingsListing />
        </TabPanel>
      </div>
    </div>
  );
};

export default DigitalDelivery;
