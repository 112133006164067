/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Edit';
import debounce from 'lodash/debounce';
import { isFuture } from 'date-fns';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import { green, orange, red } from '@mui/material/colors';
import { LoanContext } from '../../pages/ClientSingleLoan';
import {
  convertToFileDownload,
  formatPhoneWithParenthesis,
  StripPhone,
  ValidateEmail,
} from '../../Utils';
import { useGetData } from '../../Hooks';
import { DownloadIcon } from '../ui/icons';
import SearchSelect from '../ui/SearchSelect';
import { apiFetch, apiPost } from '../../adalConfig';
import { LoanInformation as LoanInformationType, NoteData } from './types';
import { AuthContext } from '../AuthContext';
import Notes from '../Notes';
import { DropdownListItem } from '../CallCenter/types';
import InvestorSearchSelect from '../Utilities/InvestorSearchSelect';
import { Investor, OutreachDisposition } from '../../globalTypes/objects';
import DeferLoan from './DeferLoan';
import { useToaster } from '../../Hooks/toasters';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import LatestDisposition from './LatestDisposition';

type Props = {
  originalLoanInformation: LoanInformationType;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalPaper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paper: {
      padding: theme.spacing(3),
      border: '1px solid #EEEEEE',
      '& h2': { fontSize: 24, fontWeight: 600 },
    },
    grid: {
      paddingTop: theme.spacing(2),
    },
    label: {
      color: 'gray',
      paddingBottom: '6px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    button: {
      verticalAlign: 'bottom',
      marginBottom: '8px',
      marginLeft: '6px',
    },
    modalButton: {
      margin: theme.spacing(1),
    },
    deferDate: {
      fontWeight: 'bold',
      letterSpacing: 1,
      textTransform: 'uppercase',
      color: orange[500],
      fontSize: '12px',
      position: 'absolute',
      right: 8,
      top: 8,
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

const LoanInformation = ({ originalLoanInformation }: Props) => {
  const [isInternal, setIsInternal] = useState(false);
  const [isInvestorNumberEditable, setIsInvestorNumberEditable] = useState(false);
  const [temporaryInvestorNumber, setTemporaryInvestorNumber] = useState('');
  const [isContactNumberEditable, setIsContactNumberEditable] = useState(false);
  const [isContactEmailEditable, setIsContactEmailEditable] = useState(false);
  const [temporaryContactNumber, setTemporaryContactNumber] = useState('');
  const [temporaryContactEmail, setTemporaryContactEmail] = useState('');
  const [temporarySendToGlobal, setTemporarySendToGlobal] = useState<boolean>(false);
  const [isTitleCompanyEditable, setIsTitleCompanyEditable] = useState(false);
  const [selectedTitleCompany, setSelectedTitleCompany] = useState({} as DropdownListItem);
  const [isInvestorEditable, setIsInvestorEditable] = useState(false);
  const [selectedInvestor, setSelectedInvestor] = useState<Investor>();
  const [loanInformation, setLoanInformation] = useState(originalLoanInformation);
  const [isDeleteDeferModalOpen, setIsDeleteDeferModalOpen] = useState(false);

  const { successToaster, errorToaster, warningToaster } = useToaster();

  const authContext = useContext(AuthContext);
  const loanContext = useContext(LoanContext);

  useEffect(() => {
    setTemporarySendToGlobal(originalLoanInformation.sendToGlobal);
    setLoanInformation(originalLoanInformation);
  }, [originalLoanInformation]);

  const classes = useStyles();

  const loadTitleCompanyOptions = debounce(async (inputText, callback) => {
    const { data: titleCompanies } = await apiFetch('/api/titleCompanies/dropdown-data', {
      params: { inputText },
    });

    callback(titleCompanies);
  }, 800);

  const refreshNotesList = async () => {
    const { data: notes } = await apiFetch(`/api/loans/${loanInformation.id}/notes`);
    const mappedNotes = notes.map<NoteData[]>(({ id, noteText, date, userName, isInternal }) => ({
      id,
      note: noteText,
      createdAt: date,
      modifiedBy: userName,
      isInternal,
    }));
    setLoanInformation(li => ({ ...li, ...{ notesData: mappedNotes } }));
    setIsDeleteDeferModalOpen(false);
  };

  const deleteDeferral = async () => {
    try {
      const { data: response } = await apiPost(
        `/api/call-tasks/delete-defer-loan/${loanInformation.id}`,
        {},
      );
      setLoanInformation(li => ({ ...li, deferUntil: '' }));
      successToaster('Successfully deleted the deferral');
    } catch (e) {
      if (e.response) {
        errorToaster('An error occurred while trying to delete the deferral');
      }
    }
  };

  const addNote = async (note: string) => {
    try {
      const { data: response } = await apiPost('/api/notes/insertnote', {
        orderId: loanInformation.id,
        noteText: note,
        userName: authContext.user ? authContext.user.mail!.split('@')[0].toLowerCase() : '',
        isInternal,
      });

      if (response > 0) {
        successToaster('Successfully added the new note');
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data?.errors) {
          errorToaster(
            <Fragment>
              {e.response.data.errors.map((error, i) => {
                return <p key={i}>{error.message}</p>;
              })}
            </Fragment>,
          );
        } else {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        }
      } else {
        errorToaster(e.message);
      }
    }

    await refreshNotesList();
  };

  const updateLoan = async (urlEndPoint: string, id: number) => {
    const updateType = urlEndPoint === 'investor' ? 'investor' : 'title company';

    try {
      const { data: response } = await apiPost(`/api/loans/update-${urlEndPoint}`, {
        loanId: loanInformation.id,
        foreignId: id,
      });

      if (response === 1) {
        successToaster(`Successfully updated the ${updateType}`);
        if (urlEndPoint === 'investor') {
          setIsInvestorEditable(false);
        } else {
          setIsTitleCompanyEditable(false);
        }

        loanContext.getLoanData();
      } else {
        throw new Error(`Failed to update the ${updateType}`);
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const updateTitleCompany = () => {
    if (!selectedTitleCompany.id) {
      warningToaster('Please select a title company before saving.');
      return;
    }

    updateLoan('title-company', selectedTitleCompany.id);
  };

  const updateInvestor = () => {
    if (!selectedInvestor?.id) {
      warningToaster('Please select an investor before saving.');
      return;
    }
    if (selectedInvestor.name === loanInformation.investor) {
      setIsInvestorEditable(false);
      return;
    }

    updateLoan('investor', selectedInvestor.id);
  };

  const updateInvestorNumber = async (investorNum: string) => {
    try {
      const { data: response } = await apiPost(`/api/loans/update-investor-number`, {
        loanId: loanInformation.id,
        investorNum,
      });

      if (response === 1) {
        successToaster(`Successfully updated the investor number`);
        setIsInvestorNumberEditable(false);

        loanContext.getLoanData();
      } else {
        throw new Error(`Failed to update the investor number`);
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const updateContact = async (type: string) => {
    if (!['phone', 'email'].includes(type)) {
      return;
    }
    if (type === 'phone' && (!temporaryContactNumber || temporaryContactNumber.length !== 10)) {
      errorToaster('Phone number is invalid. It must be 10 characters long.');
      return;
    }
    if (type === 'email' && !!temporaryContactEmail && !ValidateEmail(temporaryContactEmail)) {
      errorToaster('Email is invalid.');
      return;
    }

    try {
      const data: { loanIds: number[]; phone?: string; email?: string } = {
        loanIds: [loanInformation.id],
      };

      if (type === 'phone') {
        data.phone = temporaryContactNumber;
      } else {
        data.email = temporaryContactEmail;
      }

      const { status } = await apiPost(`/api/loans/updateLoanContact`, data);
      if (status === 200) {
        successToaster(`Successfully updated the contact ${type === 'phone' ? 'number' : 'email'}`);

        if (type === 'phone') {
          setIsContactNumberEditable(false);
        } else {
          setIsContactEmailEditable(false);
        }

        loanContext.getLoanData();
      } else {
        throw new Error(`Failed to update the contact ${type === 'phone' ? 'number' : 'email'}`);
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const updateDisposition = (disposition: OutreachDisposition) => {
    setLoanInformation(li => ({
      ...li,
      latestDisposition: {
        loanId: li.id,
        outreachDispositionId: disposition.id,
        stage: disposition.stage,
        dispositionReason: disposition.reason,
        dispositionCreatedAt: new Date(),
      },
    }));
  };

  return (
    <Fragment>
      <div className="roboto roboto" css={{ maxWidth: 1100, width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Paper className={classes.paper} elevation={0}>
              <span css={{ display: 'flex', justifyContent: 'space-between' }}>
                <h2 css={{ paddingBottom: 24 }}>Loan Information</h2>
                <DownloadFollowupMsg
                  clientName={loanInformation.client}
                  loanId={loanInformation.id}
                />
              </span>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={3}>
                  <div className={classes.label}>Loan Number</div>
                  <div>{loanInformation.appNumber}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.label}>Title #</div>
                  <div>{loanInformation.mtaNum}</div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={3}>
                  <div className={classes.label}>Date Funded</div>
                  <div>
                    {loanInformation.dateFunded &&
                      new Date(loanInformation.dateFunded).toLocaleDateString()}
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.label}>Loan Amount</div>
                  <div>
                    {loanInformation.loanAmount
                      ? `$${loanInformation.loanAmount
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
                      : null}
                  </div>
                </Grid>
              </Grid>
              <Grid container item xs={12} className={classes.grid}>
                <Grid item xs={6}>
                  <div className="df" style={{ gap: '8px' }}>
                    <div>Requires Original Document</div>
                    <div>
                      {loanInformation.requiresOriginalDocument ? (
                        <DoneIcon style={{ color: green[500] }} />
                      ) : (
                        <CloseIcon style={{ color: red[800] }} />
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={6}>
                  <div className={classes.label}>Buyer</div>
                  <div>{loanInformation.borrower}</div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.label}>Property Address</div>
                  <div>{loanInformation.propertyAddress}</div>
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={3}>
                  <div className={classes.label}>City</div>
                  <div>{loanInformation.city}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.label}>State</div>
                  <div>{loanInformation.state}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.label}>Zip</div>
                  <div>{loanInformation.zip}</div>
                </Grid>
                <Grid item xs={3}>
                  <div className={classes.label}>County</div>
                  <div>{loanInformation.county}</div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={6}>
                  <div className={classes.label}>Payment Stream</div>
                  <div>{loanInformation.paymentStream}</div>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.label}>Property Type</div>
                  <div>{loanInformation.propertyType}</div>
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={isContactNumberEditable || isContactEmailEditable ? 12 : 6}>
                  <div className={classes.label}>
                    Loan Level Contact #
                    <EditIcon
                      color="primary"
                      style={{ paddingLeft: '5px' }}
                      onClick={() => {
                        setIsContactNumberEditable(!isContactNumberEditable);
                        setTemporaryContactNumber(loanInformation.titleCompanyContactNumber);
                      }}
                    />
                  </div>
                  {isContactNumberEditable ? (
                    <Fragment>
                      <TextField
                        label="Contact #"
                        variant="outlined"
                        value={formatPhoneWithParenthesis(temporaryContactNumber)}
                        onChange={(e: React.ChangeEvent<{ value: string }>) =>
                          setTemporaryContactNumber(StripPhone(e.target.value))
                        }
                      />
                      <Button
                        disabled={
                          temporaryContactNumber === loanInformation.titleCompanyContactNumber
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => updateContact('phone')}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </Fragment>
                  ) : (
                    <a href={`tel:${loanInformation.titleCompanyContactNumber}`}>
                      <div>
                        {formatPhoneWithParenthesis(loanInformation.titleCompanyContactNumber)}
                      </div>
                    </a>
                  )}
                </Grid>
                <Grid item xs={isContactNumberEditable || isContactEmailEditable ? 12 : 6}>
                  <div className={classes.label}>
                    Loan Level Email
                    <EditIcon
                      color="primary"
                      style={{ paddingLeft: '5px' }}
                      onClick={() => {
                        setIsContactEmailEditable(!isContactEmailEditable);
                        setTemporaryContactEmail(loanInformation.titleCompanyContactEmail || '');
                      }}
                    />
                  </div>
                  {isContactEmailEditable ? (
                    <Fragment>
                      <TextField
                        label="Contact Email"
                        variant="outlined"
                        value={temporaryContactEmail}
                        onChange={(e: React.ChangeEvent<{ value: string }>) =>
                          setTemporaryContactEmail(e.target.value)
                        }
                      />
                      {!temporaryContactEmail && !!loanInformation.titleCompanyContactEmail && (
                        <p css={{ color: 'red' }}>Are you sure you want to delete the email?</p>
                      )}
                      <Button
                        disabled={
                          temporaryContactEmail === loanInformation.titleCompanyContactEmail
                        }
                        variant="contained"
                        color="primary"
                        onClick={() => updateContact('email')}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </Fragment>
                  ) : (
                    <a href={`mailto:${loanInformation.titleCompanyContactEmail}`}>
                      <span css={{ ':hover': { textDecoration: 'underline' }, color: '#0828CC' }}>
                        {loanInformation.titleCompanyContactEmail}
                      </span>
                    </a>
                  )}
                </Grid>
              </Grid>
              <Grid container className={classes.grid}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="end"
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        onClick={e => setTemporarySendToGlobal(e.target.checked)}
                        color={
                          temporarySendToGlobal === loanInformation.sendToGlobal
                            ? 'primary'
                            : 'error'
                        }
                        checked={temporarySendToGlobal}
                      />
                    }
                    label="Send to Global Email"
                    labelPlacement="end"
                    css={{
                      color:
                        temporarySendToGlobal === loanInformation.sendToGlobal ? 'default' : 'red',
                    }}
                  />
                  {!temporarySendToGlobal === loanInformation.sendToGlobal && (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          apiPost(`/api/loans/updateSendToGlobal`, {
                            int: loanInformation.id,
                          })
                            .then(() => loanContext.getLoanData())
                            .then(() =>
                              successToaster(
                                `Successfully uploaded sent to global to ${!loanInformation.sendToGlobal}`,
                              ),
                            )
                            .catch(error => {
                              errorToaster(
                                `There was an error updating send to global. Error: ${error}`,
                              );
                              setTemporarySendToGlobal(loanInformation.sendToGlobal);
                            })
                        }
                        className={classes.button}
                      >
                        Update
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => setTemporarySendToGlobal(loanInformation.sendToGlobal)}
                        className={classes.button}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <LatestDisposition
                    latestDisposition={loanInformation.latestDisposition}
                    loanId={loanInformation.id}
                    updateDisposition={updateDisposition}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={12}>
                  <div className={classes.label}>
                    <span>Title Company</span>
                    <EditIcon
                      color="primary"
                      style={{ paddingLeft: '5px' }}
                      onClick={() => setIsTitleCompanyEditable(x => !x)}
                    />
                  </div>
                  {isTitleCompanyEditable ? (
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <SearchSelect
                          placeholder="Search Title Companies"
                          loadOptions={loadTitleCompanyOptions}
                          label="Title company name"
                          filterConfig={{ matchFrom: 'all' }}
                          onChange={selected => setSelectedTitleCompany(selected)}
                          isAsync
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={updateTitleCompany}
                          className={classes.button}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <div>
                      <p>{loanInformation.titleCompany}</p>
                      {loanInformation.titleCompanySendToGlobal && (
                        <FormControlLabel
                          value="end"
                          control={<Checkbox checked disabled color="error" />}
                          label="Send to Global Email"
                          labelPlacement="end"
                        />
                      )}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={12}>
                  <div className={classes.label}>
                    <span>Investor</span>
                    <EditIcon
                      color="primary"
                      style={{ paddingLeft: '5px' }}
                      onClick={() => setIsInvestorEditable(!isInvestorEditable)}
                    />
                  </div>
                  {isInvestorEditable ? (
                    <Grid container spacing={3}>
                      <Grid item xs={8}>
                        <InvestorSearchSelect
                          onChange={selected => setSelectedInvestor(selected)}
                          selected={selectedInvestor}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={updateInvestor}
                          className={classes.button}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <div>{loanInformation.investor}</div>
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                spacing={3}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.grid}
              >
                <Grid item xs={isInvestorNumberEditable ? 12 : 6}>
                  <div className={classes.label}>
                    Investor #
                    <EditIcon
                      color="primary"
                      style={{ paddingLeft: '5px' }}
                      onClick={() => {
                        setIsInvestorNumberEditable(!isInvestorNumberEditable);
                        setTemporaryInvestorNumber(loanInformation.investorNum);
                      }}
                    />
                  </div>
                  {isInvestorNumberEditable ? (
                    <Fragment>
                      <TextField
                        label="Investor #"
                        variant="outlined"
                        value={temporaryInvestorNumber}
                        onChange={(e: React.ChangeEvent<{ value: string }>) =>
                          setTemporaryInvestorNumber(e.target.value)
                        }
                      />
                      <Button
                        disabled={temporaryInvestorNumber === loanInformation.investorNum}
                        variant="contained"
                        color="primary"
                        onClick={() => updateInvestorNumber(temporaryInvestorNumber)}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </Fragment>
                  ) : (
                    <div>{loanInformation.investorNum}</div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.label}>Pool number</div>
                  <div>{loanInformation.poolNumber}</div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Paper className={`${classes.paper} relative`} elevation={0}>
                {!!loanInformation.deferUntil && isFuture(new Date(loanInformation.deferUntil)) && (
                  <div className={classes.deferDate}>
                    <Modal
                      onClose={() => {
                        setIsDeleteDeferModalOpen(false);
                      }}
                      open={isDeleteDeferModalOpen}
                    >
                      <div className={classes.modalPaper}>
                        <h2>Delete Deferral</h2>
                        <p>Are you sure you want to delete the deferral?</p>
                        <Button
                          variant="contained"
                          className={classes.modalButton}
                          onClick={() => setIsDeleteDeferModalOpen(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          className={classes.modalButton}
                          startIcon={<DeleteIcon />}
                          onClick={deleteDeferral}
                        >
                          Delete
                        </Button>
                      </div>
                    </Modal>
                    <Tooltip title="Delete Deferral">
                      <DeleteIcon
                        style={{ color: red[500] }}
                        onClick={() => setIsDeleteDeferModalOpen(true)}
                      />
                    </Tooltip>
                    Deferred Until {new Date(loanInformation.deferUntil).toLocaleDateString()}
                  </div>
                )}
                <div className="df jcsb">
                  <div style={{ width: '50%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isInternal}
                          color="error"
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setIsInternal(event.target.checked)
                          }
                        />
                      }
                      label="Mark as internal"
                    />
                  </div>
                  <div style={{ position: 'relative', width: '50%' }}>
                    <DeferLoan
                      loanId={loanInformation.id}
                      refreshNotesList={refreshNotesList}
                      setLoanInformation={setLoanInformation}
                    />
                  </div>
                </div>
                <Notes
                  addNote={addNote}
                  notes={loanInformation.notesData}
                  noteType="Loan"
                  canEditAndDelete
                />
              </Paper>
              <Paper className={classes.paper} elevation={0} css={{ marginTop: 8 }}>
                <Notes notes={loanInformation.documentNotes} noteType="Document" />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

export default LoanInformation;

function DownloadFollowupMsg({ loanId, clientName }: { loanId: number; clientName: string }) {
  const { data: followupDocs, doFetch: checkIfRequiresFollowup } = useGetData<string[]>('', []);

  const download = async () => {
    const { data: downloadData } = await apiFetch<{ base64: string; fileName: string }>(
      `/api/followups/DownloadSingleLoanFollowup?loanId=${loanId}`,
    );
    convertToFileDownload(downloadData.base64, downloadData.fileName);
  };

  useEffect(() => {
    loanId !== 0 && checkIfRequiresFollowup(`/api/followups/loanRequiresFollowup?loanId=${loanId}`);
  }, [checkIfRequiresFollowup, loanId]);
  return (
    <Tooltip title={`Generate followup email for ${followupDocs.join(', ')}`}>
      <span>
        <IconButton
          color="primary"
          disabled={followupDocs.length === 0}
          onClick={download}
          size="large"
        >
          <DownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
