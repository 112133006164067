/** @jsx jsx */
import { jsx } from '@emotion/core';
import { startCase } from 'lodash';

export type Event = {
  id: number;
  documentId: number;
  event: string;
  timestamp: string;
  username: string;
  logEvent: string;
  requestUrl: string;
};

export const eventDisplayName = new Map([
  ['AuditPerformed', 'Passed or Failed Audit'],
  ['BulkShipped', 'Bulk Shipped'],
  ['ClientUpdated', 'Client Updated'],
  ['ConvertedToDigital', 'Converted to Digital'],
  ['CopiedToDp1', 'Processed'],
  ['CopyShipmentQueued', 'Copy Shipment Queued'],
  ['CoversheetCreated', 'CS Created'],
  ['CoversheetPreprinted', 'CS Preprinted'],
  ['CoversheetReprinted', 'Coversheet Reprinted'],
  ['CuringDocumentFound', 'Identified as Possible Cure'],
  ['Deleted', 'Document Deleted'],
  ['DeterminationPerformed', 'Determined'],
  ['DigitallyReShipped', 'Digitally ReShipped'],
  ['DigitallyShipped', 'Digitally Shipped'],
  ['InternallyShipped', 'Internally Shipped'],
  ['DigitalUploaded', 'Dropped'],
  ['DocumentAutoCured', 'Auto Corrected'],
  ['DocumentCured', 'Corrected'],
  ['DocumentTypeUpdated', 'Document Type Updated'],
  ['FailedVerification', 'Failed Verification'],
  ['InvestorFailedAudit', 'Investor Failed'],
  ['MatchedAutomatically', 'Matched Automatically'],
  ['MatchSuggestionAccepted', 'Matched'],
  ['MatchSuggestionDisplayed', 'Match Displayed'],
  ['MatchSuggestionRejected', 'Match Rejected'],
  ['PdfImageSaved', 'Scanned'],
  ['ReShipped', 'ReShipped'],
  ['ReturnedToClient', 'Returned To Client'],
  ['ScannedForSort', 'Scanned For Sort'],
  ['ShipmentDeleted', 'Shipment Deleted'],
  ['Shipped', 'Shipped'],
  ['Sorted', 'Sorted'],
  ['Stapled', 'Stapled'],
  ['UploadedThruTitlePortal', 'Uploaded By Title Company'],
  ['UploadedToExternalLocation', 'Uploaded To LOS'],
]);

export const EventDisplay = ({ docEvent, clients }: { docEvent: Event; clients }) => {
  let auditPassed = false;
  const { timestamp, event, logEvent, id, username } = docEvent;
  if (event === 'AuditPerformed') {
    const auditEvent = JSON.parse(logEvent);
    auditPassed = auditEvent?.Properties?.Audit?.Passed ?? false;
  }

  let deletedReason = null;
  if (event === 'Deleted') {
    const deletedEvent = JSON.parse(logEvent);
    deletedReason = deletedEvent?.Properties?.DocumentDeletedReason ?? null;
  }

  let resolution = '';
  if (event === 'DocumentCured' || event === 'DocumentAutoCured') {
    const cureEvent = JSON.parse(logEvent);
    const cureInfo = cureEvent?.Properties?.DocumentCureInfo;
    const resolutionType = cureInfo?.ResolutionType ?? cureInfo?.CorrectionType ?? '';
    const resolvedBy =
      cureInfo?.ResolvedViaDocumentId ?? cureInfo?.CorrectDocumentId ?? 'New Document';

    if (resolutionType === 'CorrectionNotNeeded') {
      resolution = 'No Correction Needed';
    } else if (resolutionType === 'Replacement') {
      resolution = `Replaced with ${resolvedBy}`;
    } else if (resolutionType === 'Supportive') {
      resolution = `Supported by ${resolvedBy}`;
    }
  }

  let oldType = '';
  let newType = '';
  if (event === 'DocumentTypeUpdated') {
    const updateEvent = JSON.parse(logEvent);
    const docTypeUpdateInfo = updateEvent?.Properties?.DocumentTypeUpdate;
    oldType = docTypeUpdateInfo?.From ?? '';
    newType = docTypeUpdateInfo?.To ?? '';
  }

  let oldClient = '';
  if (event === 'ClientUpdated') {
    const updateEvent = JSON.parse(logEvent);
    const logEventInfo = updateEvent?.Properties;
    oldClient = clients.find(c => c.id === parseInt(logEventInfo?.FromId))?.company ?? '';
  }

  let failedDoc = '';
  if (event === 'CuringDocumentFound') {
    const possibleCureInfo = JSON.parse(logEvent)?.Properties;
    failedDoc = possibleCureInfo?.FailedDocument?.Id ?? possibleCureInfo?.FailedPolicy?.Id ?? '';
  }

  let destination = '';
  if (event === 'UploadedToExternalLocation') {
    const uploadInfo = JSON.parse(logEvent)?.Properties;
    destination = uploadInfo?.Destination ?? '';
  }

  return (
    <div key={id}>
      <p className="bold fs16">{eventDisplayName.get(event)}:</p>
      <span className="fs18">
        {username?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ' - ')}
      </span>
      <span className="fs18">{new Date(timestamp).toLocaleDateString()}</span>
      {event === 'Deleted' && deletedReason && <div>{startCase(deletedReason)}</div>}
      {event === 'ClientUpdated' && (
        <div className="fs18">
          <span className="fs18">{oldClient}</span>
        </div>
      )}
      {event === 'AuditPerformed' && (
        <div className="fs18">
          <span style={{ backgroundColor: auditPassed ? '#d4edda' : '#f8d7da' }}>
            {auditPassed ? 'Passed' : 'Failed'}
          </span>
        </div>
      )}
      {(event === 'DocumentCured' || event === 'DocumentAutoCured') && (
        <div className="fs16">{resolution}</div>
      )}
      {event === 'DocumentTypeUpdated' && oldType !== '' && newType !== '' && (
        <div className="fs16">
          {oldType} to {newType}
        </div>
      )}
      {event === 'CuringDocumentFound' && <div className="fs16">For {failedDoc}</div>}
      {event === 'UploadedToExternalLocation' && <div className="fs16">To {destination}</div>}
    </div>
  );
};
