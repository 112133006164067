/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import { apiFetch } from '../adalConfig';
import DeterminationPageWrapper from '../components/DeterminationPageWrapper';
import Button from '../components/ui/Button';
import EscapeIcon from '../components/ui/EscapeIcon';
import Header from '../components/ui/Header';
import { Client } from '../globalTypes/objects';
import colors from '../styles/colors';
import { MatchOnlyOption, PageDisplay } from './NotFounds';
import UnassociatedDocuments from './UnassociatedDocuments';

const buttonWrap = {
  padding: 32,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: 24,
  maxWidth: 160,
};

const buttonContent = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const countCircle = {
  backgroundColor: colors.red,
  borderRadius: 4,
  color: colors.white,
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: 1,
  marginLeft: 16,
  padding: '1px 4px',
};

enum Mode {
  Default,
  Matching,
  Determining,
}

export default function TitleCompanyPortalLinks() {
  const [mode, setMode] = useState(Mode.Default);
  const [loadingMatchCount, setLoadingMatchCount] = useState(true);
  const [matchCount, setMatchCount] = useState(0);
  const [loadingDeterminationCount, setLoadingDeterminationCount] = useState(true);
  const [determinationCount, setDeterminationCount] = useState(0);

  const fetchMatchCount = async () => {
    setLoadingMatchCount(true);
    const { data: count }: { data: number } = await apiFetch(
      `/api/documents/titleUploadsMatchVerificationCount`,
    );
    setMatchCount(count);
    setLoadingMatchCount(false);
  };

  const fetchDeterminationCount = async () => {
    setLoadingDeterminationCount(true);
    const { data: count }: { data: number } = await apiFetch(
      `/api/documents/determination/get-determination-count?titleCompanyUploads=true`,
    );
    setDeterminationCount(count);
    setLoadingDeterminationCount(false);
  };

  useEffect(() => {
    if (mode === Mode.Default) {
      fetchMatchCount();
      fetchDeterminationCount();
    }
  }, [mode]);

  return (
    <Fragment>
      <Header headerText="Title Company Uploads" />
      {mode === Mode.Default && (
        <div css={buttonWrap}>
          <Button
            disabled={loadingMatchCount || matchCount === 0}
            onClick={() => setMode(Mode.Matching)}
            css={buttonContent}
          >
            Match
            {!loadingMatchCount && matchCount > 0 && <div css={countCircle}>{matchCount}</div>}
          </Button>
          <Button
            disabled={loadingDeterminationCount || determinationCount === 0}
            onClick={() => setMode(Mode.Determining)}
            css={buttonContent}
          >
            Audit
            {!loadingDeterminationCount && determinationCount > 0 && (
              <div css={countCircle}>{determinationCount}</div>
            )}
          </Button>
        </div>
      )}
      {mode === Mode.Matching && (
        <UnassociatedDocuments
          matchOnly={MatchOnlyOption.All}
          client={{} as Client}
          pageDisplay={PageDisplay.titleCompanyUploads}
          hideForm={() => setMode(Mode.Default)}
        />
      )}
      {mode === Mode.Determining && (
        <Fragment>
          <EscapeIcon onClick={() => setMode(Mode.Default)} />
          <DeterminationPageWrapper isTitleCompanyUploads />
        </Fragment>
      )}
    </Fragment>
  );
}
