/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import * as React from 'react';
import colors from '../../styles/colors';
import Icon, { IconType } from './Icon';

const errorMsgOuter: CSSObject = {
  color: colors.grayDark,
  display: 'block',
  fontSize: 16,
  margin: 'auto',
  textAlign: 'center',
  height: '100%',
};

const errorMsgHeader: CSSObject = {
  fontSize: 24,
  fontWeight: 'bold',
  marginBottom: 8,
  marginTop: 40,
};

const iconCircle: CSSObject = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '50%',
  display: 'flex',
  height: 224,
  justifyContent: 'center',
  margin: '116px auto 0',
  marginTop: 100,
  width: 224,
};

interface NoDataProps {
  message: string;
  subMessage?: string;
  icon?: IconType;
}

const NoData: React.FunctionComponent<NoDataProps> = ({
  message,
  subMessage,
  icon = IconType.NoErrors,
  children,
}) => {
  return (
    <div css={errorMsgOuter}>
      <div css={iconCircle}>
        <Icon icon={icon} />
      </div>
      <div css={errorMsgHeader}>{message}</div>
      <div>{subMessage}</div>
      {children}
    </div>
  );
};

export default NoData;
