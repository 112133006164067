/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import Link from '@mui/material/Link';
import DataTable from '../../components/ui/DataTable/DataTable';

type Props = {
  loanId: number;
};

const DeletedDocuments = ({ loanId }: Props) => {
  type RowData = {
    barcodeId: number;
    documentType: string;
    createdDate: Date;
    deletedDate: Date;
    deletedBy: string;
    hardCopy: boolean;
    deletedReason: string;
  };

  const columns = [
    {
      field: 'barcodeId',
      title: 'Barcode',
      render: (rowData: RowData) => (
        <Link target="_blank" href={`/documents/${rowData.barcodeId}`}>
          {rowData.barcodeId}
        </Link>
      ),
    },
    {
      field: 'documentType',
      title: 'Document Type',
    },
    {
      field: 'createdDate',
      title: 'Barcode Created',
      render: (rowData: RowData) =>
        rowData.createdDate === null ? null : new Date(rowData.createdDate).toLocaleDateString(),
    },
    {
      field: 'deletedDate',
      title: 'Barcode Deleted',
      render: (rowData: RowData) =>
        rowData.deletedDate === null ? null : new Date(rowData.deletedDate).toLocaleDateString(),
    },
    {
      field: 'deletedBy',
      title: 'Deleted By',
      render: (rowData: RowData) =>
        rowData.deletedBy === null ? null : rowData.deletedBy.split('@')[0],
    },
    {
      field: 'hardCopy',
      title: 'Hard Copy',
      render: (rowData: RowData) => (rowData.hardCopy ? 'Yes' : 'No'),
    },
    {
      field: 'deletedReason',
      title: 'Deleted Reason',
    },
  ];
  return (
    <Fragment>
      <DataTable<RowData>
        title="Deleted Documents"
        columns={columns}
        url={`/api/loans/${loanId}/deletedDocuments`}
        emptyDataMsg="There are no deleted barcodes for this loan"
      />
    </Fragment>
  );
};

export default DeletedDocuments;
