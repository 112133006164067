/** @jsx jsx React */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import camelCase from 'lodash/camelCase';
import Tab, { TabGroup } from '../components/ui/Tabs/Tab';
import SearchSelect from '../components/ui/SearchSelect';
import Header from '../components/ui/Header';
import Toggle from '../components/ui/Toggle';
import { apiFetch, apiPost } from '../adalConfig';
import { Client, Loan } from '../globalTypes/objects';

const tabs = {
  LOAN_NUMBER: 'Loan Number',
  ADDRESS: 'Address',
};

const DEBOUNCE_RATE = 800;

const matchLimit = 10;

const getMatches = async (searchBy: string, partialString: string, clients: Client[]) => {
  const { data = [] } = await apiFetch<Loan[]>(
    `api/Loans/SearchBy${camelCase(searchBy)}?searchString=${partialString}&includePartial=false`,
  );

  return data.slice(0, matchLimit).map(loan => {
    const client = clients.find(c => c.id === loan.clientID);
    const clientName = client?.company;
    const loanFullAddress = `${loan.propertyAddress}, ${loan.city}, ${loan.state}`;
    return {
      label: searchBy === tabs.LOAN_NUMBER ? loan.loanNumber : loanFullAddress,
      subLabel: clientName,
      value: loan,
    };
  });
};

const SearchLoans = () => {
  const [searchBy, setSearchBy] = useState(tabs.LOAN_NUMBER);
  const [clients, setClients] = useState<Client[]>([] as Client[]);
  const [loan, setLoan] = useState<Loan>();

  useEffect(() => {
    apiFetch<Client[]>('/api/clients/getAll').then(({ data }) => {
      setClients(data);
    });
  }, []);

  const loadOptions = debounce(
    // react-select expects a callback
    (input: string, callback) => {
      getMatches(searchBy, input, clients).then(callback);
    },
    DEBOUNCE_RATE,
    { leading: true, trailing: true },
  );

  const toggleManuallyCloseLoan = () => {
    const updatedLoan = { ...loan!, isManuallyClosed: !loan!?.isManuallyClosed };

    setLoan(updatedLoan);
    apiPost('/api/loans/update-is-manually-closed', updatedLoan);
  };

  return (
    <Fragment>
      {/* <Global styles={{ ' body': { backgroundColor: '#fff' } }} /> */}
      <Header headerText="Loans" />
      <div css={{ padding: 40, paddingBottom: 32 }}>
        <div css={{ marginBottom: 16 }}>
          <span css={{ marginRight: 8 }}>Search by</span>
          <TabGroup>
            <Tab onClick={() => setSearchBy(tabs.LOAN_NUMBER)}>Loan Number</Tab>
            <Tab onClick={() => setSearchBy(tabs.ADDRESS)}>Address</Tab>
          </TabGroup>
        </div>
        <div css={{ width: 412 }}>
          <SearchSelect
            placeholder={searchBy === tabs.ADDRESS ? 'Search addresses' : 'Search loans'}
            loadOptions={loadOptions}
            isAsync
            onChange={selection => setLoan(selection?.value)}
            key={searchBy}
            cacheOptions={false}
          />
        </div>
      </div>
      {loan && (
        <div
          css={{
            padding: 32,
            marginLeft: 40,
            borderRadius: 4,
            backgroundColor: '#fff',
            width: 412,
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
          }}
        >
          <h3>
            <span css={{ fontWeight: 700 }}>Borrower:</span> {loan.borrower}
          </h3>
          <h3 css={{ marginTop: 4 }}>
            <span css={{ fontWeight: 700 }}>Address:</span>{' '}
            {`${loan.propertyAddress}, ${loan.city}, ${loan.state}`}
          </h3>
          <div css={{ marginTop: 16 }}>
            <Toggle
              active={loan.isManuallyClosed}
              handleToggle={toggleManuallyCloseLoan}
              text="Manually Close"
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchLoans;
