/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, InputAdornment, Link, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useDebounce } from '../../Hooks';
import { DebounceRate, ValidateEmail } from '../../Utils';
import { apiFetch } from '../../adalConfig';
import { CopyIcon } from '../ui/icons';

export default function TitlePortalLinks() {
  const [email, setEmail] = useState('');
  const debouncedEmail = useDebounce(email, DebounceRate);

  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl('');
  }, [email]);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await apiFetch<string>(
        `/api/followups/getTitlePortalLink?email=${debouncedEmail}`,
      );
      setUrl(data);
    };

    ValidateEmail(debouncedEmail) && fetchData();
  }, [debouncedEmail]);

  const [copySuccess, setCopySuccess] = useState(false);

  function copyToClipboard(e) {
    navigator.clipboard.writeText(url);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  }

  return (
    <div css={{ textAlign: 'center', paddingTop: 20 }}>
      <TextField
        css={{ margin: 'auto', width: '50%' }}
        margin="normal"
        variant="outlined"
        label="Email Address"
        value={email}
        onChange={e => setEmail(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}
        helperText={
          email !== '' &&
          email === debouncedEmail &&
          !ValidateEmail(debouncedEmail) && (
            <span css={{ fontSize: '1rem', lineHeight: '1.2em', color: 'blue' }}>
              This is not a valid email address
            </span>
          )
        }
      />
      {!!url && (
        <div css={{ margin: 'auto' }}>
          <h1 css={{ padding: 20 }}>
            <Link target="_blank" href={url} underline="hover">
              Click here to go to title portal
            </Link>
          </h1>
          <Button
            color="inherit"
            startIcon={<CopyIcon css={{ maxWidth: 18 }} />}
            variant="contained"
            onClick={copyToClipboard}
          >
            Copy url to clipboard
          </Button>
          {copySuccess && <p css={{ color: 'green' }}>✓ Copied to clipboard</p>}
        </div>
      )}
    </div>
  );
}
