/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { apiFetch, apiPost } from '../../../../adalConfig';
import produce from 'immer';
import { SortResponse } from '../SortDocumentPanel';
import { useToaster } from '../../../../Hooks/toasters';
import { useClients } from '../../../../Hooks/useClients';
import ContainerCountsPanel from './ContainerCountsPanel';
import ScanForSort from './ScanForSort';
import { Client, DocumentAudit } from '../../../../globalTypes/objects';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import CreateIcon from '@mui/icons-material/Create';
import PrintIcon from '@mui/icons-material/Print';
import useLabelPrinters from '../../../../Hooks/useLabelPrinters';
import PrinterModal from './PrinterModal';
import Tooltip from '@mui/material/Tooltip';
import UIfx from 'uifx';
// @ts-ignore
import ErrorEffect from '../../ErrorEffect.wav';
import PrintingContext from '../../../../PrintingContext';
import { Coversheet } from '../../Imports/CoversheetPrint';
import { Coversheet as CoversheetType } from '../../../CureDocument';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '5fr 10fr',
    gap: '24px',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    minHeight: '200px',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    textAlign: 'center',
  },
  iconsContainer: {
    position: 'absolute',
    top: '0px',
    right: '10px',
    display: 'flex',
    gap: '5px',
  },
  editPrinter: {
    display: 'flex',
    gap: '4px',
  },
}));

export type DocumentException = {
  documentId: number;
  isInvestorException: boolean;
  isFlagged: boolean;
};

export type CompartmentData = {
  compartmentId: number;
  clientId: number;
  label: string;
  documentIds: number[];
  documentIdsReadyForSort: number[];
  readyToSort: boolean;
  weekOrMonthStart: string;
  documentsWithExceptions: DocumentException[];
  internalShippingDocumentMappings: { [key: number]: number } | null;
};

const getUnsortedDocumentsCount = async (clientId: number) => {
  const { data } = await apiFetch<CompartmentData[]>(
    `/api/documents/data-for-unsorted-documents?clientId=${clientId}`,
  );

  return data;
};

const removeDocumentFromCounts = (compartmentsData: CompartmentData[], documentId: number) => {
  return produce(compartmentsData, (draft: CompartmentData[]) => {
    draft.forEach(compartmentData => {
      let currentDocumentId = documentId;
      if (compartmentData.internalShippingDocumentMappings !== null) {
        const internalShippingMapping = Object.entries(
          compartmentData.internalShippingDocumentMappings || {},
        ).find(([, originalDocumentId]) => originalDocumentId === documentId);
        currentDocumentId = internalShippingMapping
          ? parseInt(internalShippingMapping[0])
          : documentId;
      }

      compartmentData.documentIds = compartmentData.documentIds.filter(
        d => d !== currentDocumentId,
      );
      compartmentData.documentIdsReadyForSort = compartmentData.documentIdsReadyForSort.filter(
        d => d !== currentDocumentId,
      );
      compartmentData.documentsWithExceptions = compartmentData.documentsWithExceptions.filter(
        d => d.documentId !== currentDocumentId,
      );
    });
  });
};

const updateCompartmentsIfMisplaced = (
  compartmentsData: CompartmentData[],
  documentId: number,
  newlyAssignedCompartmentId?: number,
) => {
  if (newlyAssignedCompartmentId === undefined) {
    return compartmentsData;
  }

  return produce(compartmentsData, (draft: CompartmentData[]) => {
    draft.forEach(compartmentData => {
      const inCurrentCompartment = compartmentData.documentIds.includes(documentId);
      const missingFromCompartment =
        compartmentData.compartmentId === newlyAssignedCompartmentId && !inCurrentCompartment;
      const inWrongCompartment =
        compartmentData.compartmentId !== newlyAssignedCompartmentId && inCurrentCompartment;

      if (missingFromCompartment) {
        compartmentData.documentIds.push(documentId);
      } else if (inWrongCompartment) {
        const index = compartmentData.documentIds.indexOf(documentId);
        compartmentData.documentIds.splice(index, 1);
      }
    });
  });
};

const errorSound = new UIfx({
  asset: ErrorEffect,
});

const SortClientDocuments = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [compartmentsData, setCompartmentsData] = useState<CompartmentData[]>([]);
  const [sortResponse, setSortResponse] = useState<SortResponse>({} as SortResponse);
  const [selectedCompartmentIndex, setSelectedCompartmentIndex] = useState<number | null>(null);
  const [readyToSortModalOpen, setReadyToSortModalOpen] = useState(false);
  const [exceptionsModalOpen, setExceptionsModalOpen] = useState(false);
  const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
  const [isProcessingSort, setIsProcessingSort] = useState(false);
  const [scannedBarcode, setScannedBarcode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPrintPaused, setIsPrintPaused] = useState(false);

  const clients = useClients();
  const { printers, printer, setPrinter, setPrinterId } = useLabelPrinters('sort.label.printer');
  const { errorToaster, infoToaster } = useToaster();
  const classes = useStyles();

  const printReactComponent = useContext(PrintingContext);

  const selectClient = async (newValue: Client | null) => {
    setSelectedClient(newValue);

    if (!newValue) {
      return;
    }

    try {
      const result = await getUnsortedDocumentsCount(newValue.id);
      setCompartmentsData(result);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const handleDocumentIdChange = async (documentId: string) => {
    if (!documentId) {
      return;
    }

    setScannedBarcode(documentId);
    try {
      setIsProcessingSort(true);
      const { data } = await apiPost<SortResponse>('/api/documents/sortDoc', {
        documentId,
        isNewSort: true,
        printerId: isPrintPaused ? 0 : printer?.id,
      });

      if (data.requiresPrinterId && !isPrintPaused) {
        setIsModalOpen(true);
      }

      setSortResponse(data);

      const parsedDocumentId = parseInt(documentId);
      if (data.isSorted) {
        const filteredData = removeDocumentFromCounts(compartmentsData, parsedDocumentId);
        setCompartmentsData(filteredData);

        await printCoversheetIfRequired(data.newCoversheetDocument);
        return;
      }

      const updatedCompartmentsData = updateCompartmentsIfMisplaced(
        compartmentsData,
        parsedDocumentId,
        data.compartmentId,
      );
      setCompartmentsData(updatedCompartmentsData);

      if (
        data.sortDocumentLocation !== null &&
        selectedClient?.company !== data.sortDocumentLocation.client
      ) {
        errorSound.play();
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsProcessingSort(false);
    }
  };

  const printCoversheetIfRequired = async (newCoversheetDocument: number | null) => {
    if (!newCoversheetDocument) {
      return;
    }

    const { data: coversheetData } = await apiFetch<CoversheetType>(
      `api/documents/get-coversheet-info?documentId=${newCoversheetDocument}`,
    );

    printReactComponent(
      <Coversheet {...coversheetData} audit={{} as DocumentAudit} notFound={false} />,
    );
  };

  const printLabel = async (documentId: number, printerId: number) => {
    if (isPrintPaused) {
      return;
    }

    try {
      await apiPost(`/api/documents/print-label`, {
        documentId,
        printerId: isPrintPaused ? 0 : printerId,
      });
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.message);
        return;
      }

      errorToaster(
        <Fragment>
          Cannot print!
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    }
  };

  const selectPrinter = async (isPrintPaused: boolean) => {
    setIsPrintPaused(isPrintPaused);

    if (isPrintPaused) {
      setIsModalOpen(false);
      return;
    }

    if (!printer) {
      return;
    }

    setPrinterId(printer.id);
    setIsModalOpen(false);

    if (sortResponse.requiresPrinterId) {
      await printLabel(parseInt(scannedBarcode), printer.id);
    }
  };

  const openModal = (modal: 'readyToSort' | 'exceptions', compartmentIndex: number) => {
    if (compartmentsData[compartmentIndex].documentsWithExceptions.length === 0) {
    }
    setSelectedCompartmentIndex(compartmentIndex);

    if (
      modal === 'readyToSort' &&
      compartmentsData[compartmentIndex].documentIdsReadyForSort.length > 0
    ) {
      setReadyToSortModalOpen(true);
      return;
    }

    if (
      modal === 'exceptions' &&
      compartmentsData[compartmentIndex].documentsWithExceptions.length > 0
    ) {
      setExceptionsModalOpen(true);
    }
  };

  return (
    <Fragment>
      <div className="mt3 relative">
        <div className={classes.iconsContainer}>
          {scannedBarcode.length > 0 && printer !== null && !isPrintPaused && (
            <div>
              <Tooltip title={`Reprint label for barcode ${scannedBarcode}`}>
                <PrintIcon
                  color="primary"
                  onClick={async () => {
                    infoToaster('Reprinting...');
                    await printLabel(parseInt(scannedBarcode), printer.id);
                  }}
                />
              </Tooltip>
            </div>
          )}
          {(printer !== null || isPrintPaused) && !isModalOpen && (
            <div className={classes.editPrinter}>
              <CreateIcon color="action" onClick={() => setIsModalOpen(true)} />
              {isPrintPaused ? 'Printing paused' : printer!.name}
            </div>
          )}
        </div>
        <div className="mb3">
          <Autocomplete
            options={clients}
            getOptionLabel={(option: Client) => option?.company || ''}
            onChange={(event: any, newValue: Client | null) => selectClient(newValue)}
            onOpen={() => setIsClientDropdownOpen(true)}
            onClose={() => setIsClientDropdownOpen(false)}
            blurOnSelect={true}
            value={selectedClient}
            style={{ width: 300 }}
            autoHighlight
            renderInput={params => (
              <TextField {...params} label="Choose a client" variant="outlined" />
            )}
          />
        </div>
        {selectedClient && (
          <div className={classes.container}>
            <ContainerCountsPanel
              clientName={selectedClient?.company || ''}
              unsortedDocumentsCounts={compartmentsData}
              openModal={openModal}
            />
            <ScanForSort
              clientName={selectedClient?.company || ''}
              sortResponse={sortResponse}
              onDocumentIdChange={handleDocumentIdChange}
              keepFocus={
                !readyToSortModalOpen &&
                !exceptionsModalOpen &&
                !isClientDropdownOpen &&
                !isModalOpen
              }
              isProcessingSort={isProcessingSort}
            />
          </div>
        )}
      </div>

      <PrinterModal
        printer={printer}
        setPrinter={setPrinter}
        printers={printers}
        isModalOpen={isModalOpen}
        selectPrinter={selectPrinter}
        isPrintPaused={isPrintPaused}
      />

      <Modal open={readyToSortModalOpen} onClose={() => setReadyToSortModalOpen(false)}>
        <div className={classes.paper}>
          <h2 className="m2">Barcodes ready for sort</h2>
          {selectedCompartmentIndex !== null &&
            readyToSortModal(compartmentsData[selectedCompartmentIndex])}
        </div>
      </Modal>

      <Modal open={exceptionsModalOpen} onClose={() => setExceptionsModalOpen(false)}>
        <div className={classes.paper}>
          <h2 className="m2">Document Exceptions</h2>
          <div>
            {selectedCompartmentIndex !== null &&
              exceptionModal(compartmentsData[selectedCompartmentIndex])}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const readyToSortModal = (compartmentData: CompartmentData) => {
  if (compartmentData.internalShippingDocumentMappings === null) {
    return (
      <div>
        {compartmentData.documentIdsReadyForSort.map(documentId => (
          <p key={documentId}>{documentId}</p>
        ))}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
      }}
    >
      <div>Barcode to sort</div>
      <div>Replacement Barcode</div>
      {compartmentData.documentIdsReadyForSort.map(documentId => (
        <Fragment key={documentId}>
          <div>{documentId}</div>
          <div>
            {compartmentData.internalShippingDocumentMappings &&
              compartmentData.internalShippingDocumentMappings[documentId]}
          </div>
        </Fragment>
      ))}
    </div>
  );
};

const exceptionModal = (compartmentData: CompartmentData) => {
  if (compartmentData.internalShippingDocumentMappings === null) {
    return (
      <div>
        {compartmentData.documentsWithExceptions.map(
          ({ documentId, isFlagged, isInvestorException }) => (
            <p key={documentId}>
              <span>{documentId}</span>
              {exception(isInvestorException, 'Investor Exception')}
              {exception(isFlagged, 'Flagged')}
            </p>
          ),
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
      }}
    >
      <div>Barcode to sort</div>
      <div></div>
      <div>Replacement Barcode</div>
      {compartmentData.documentsWithExceptions.map(
        ({ documentId, isFlagged, isInvestorException }) => (
          <Fragment key={documentId}>
            <div>{documentId}</div>
            <div>
              {exception(isInvestorException, 'Investor Exception')}
              {exception(isFlagged, 'Flagged')}
            </div>
            <div>
              {compartmentData.internalShippingDocumentMappings &&
                compartmentData.internalShippingDocumentMappings[documentId]}
            </div>
          </Fragment>
        ),
      )}
    </div>
  );
};

const exception = (isException: boolean, exceptionType: 'Investor Exception' | 'Flagged') => {
  if (!isException) {
    return <Fragment />;
  }

  return <span style={{ padding: '8px', color: 'red', fontStyle: 'italic' }}>{exceptionType}</span>;
};

export default SortClientDocuments;
