/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import colors from '../../../styles/colors';
import { DocumentStatus } from '../../../globalTypes/objects';
import { cardWrap, gridDimensions, statusDot } from './documentCardStyles';
import Checkbox from '../../ui/Checkbox';
import { getDocTypeName, SelectFunctionality } from '../../../Utils';
import { IndexDoc, useDocIndex } from './useDocIndex';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import { TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { DocumentTypeProperties } from './Determination';
import { apiPost } from '../../../adalConfig';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useToaster } from '../../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '340px',
      height: '175px',
    },
  }),
);

type DocumentTileProps = {
  data: IndexDoc;
  docTypes: DocumentTypeProperties[];
  refreshData: () => void;
};

export default function DocumentTile({ data, docTypes, refreshData }: DocumentTileProps) {
  const [indexData, setIndexData] = useDocIndex();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState<DocumentTypeProperties>(
    {} as DocumentTypeProperties,
  );

  const { successToaster, errorToaster } = useToaster();

  const classes = useStyles();

  const updateDocType = async () => {
    try {
      setIsSaving(true);
      const { data: response } = await apiPost<number>('/api/documents/update-document-type', {
        documentId: document.id,
        documentType: selectedDocType.value,
      });

      if (response >= 1) {
        setIsModalOpen(false);
        successToaster(
          <Fragment>
            <p>Successfully updated the document type.</p>
            <p>It may take a few seconds for the page to display the updated document type</p>
          </Fragment>,
        );

        // Need to keep this component alive before refreshing in order to update the state above
        setTimeout(refreshData, 10);
      } else {
        throw new Error('Failed to update the document type');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  const onDocSelect = e => {
    const selected = SelectFunctionality(
      e,
      document.id,
      indexData.displayedDocs.map(d => d.document.id),
      indexData.selected,
      indexData.lastSelected,
    );
    setIndexData({ selected, lastSelected: document.id });
  };

  const { loan, document, audit } = data;
  const selected = indexData.selected.has(document.id);
  const addressLine2 =
    loan &&
    (loan.city ? `${loan.city}${loan.state ? ',' : ''} ` : '') +
      (`${loan.state} ` || '') +
      loan.zip;

  return (
    <Fragment>
      <Link to={`documents/${document.id.toString()}`} css={[cardWrap, gridDimensions]}>
        <Checkbox
          checked={selected}
          onClick={e => {
            e.preventDefault();
            onDocSelect(e);
          }}
        />
        <div
          css={[
            statusDot,
            {
              backgroundColor: data.docStatus === DocumentStatus.Found ? colors.blueLight : false,
              border: `1px solid ${(data.docStatus === DocumentStatus.NotFound && colors.red) ||
                (data.docStatus === DocumentStatus.Unsorted && colors.blueLight) ||
                false}`,
            },
          ]}
        />
        <div css={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>
          {document.documentType ? (
            <Fragment>
              <span>{getDocTypeName(document.documentType)}</span>
              <span
                css={{ cursor: 'pointer', marginLeft: '20px' }}
                onClick={e => {
                  e.preventDefault();

                  setIsModalOpen(true);

                  if (!selectedDocType.value && docTypes.length) {
                    setSelectedDocType(
                      docTypes.find(docType => docType.value === document.documentType) ??
                        ({} as DocumentTypeProperties),
                    );
                  }
                }}
              >
                <EditIcon htmlColor={document.loanId ? 'red' : ''} />
              </span>
            </Fragment>
          ) : null}
          {audit && !audit.passed && !audit.resolvedAt ? (
            <div css={{ fontSize: 12, color: 'red' }}>FAILED AUDIT</div>
          ) : null}
        </div>
        <div css={{ fontSize: 16 }}>{document.id}</div>
        <div css={{ fontSize: 16 }}>{loan?.loanNumber || document?.loanNumber}</div>
        <div css={{ fontSize: 16 }}>{document.hardCopy ? 'Physical' : 'Digital'}</div>
        <div css={{ fontSize: 16 }}>
          {format(new Date(Date.parse(document.createdAt.toString())), 'MMMM d, yyyy')} at{' '}
          {format(new Date(Date.parse(document.createdAt.toString())), 'h:mma')}
        </div>
      </Link>

      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        BackdropComponent={Backdrop}
      >
        <Fade in={isModalOpen}>
          {!!docTypes.length ? (
            <div className={classes.paper}>
              <Autocomplete
                options={docTypes}
                renderOption={(props, docType) => (
                  <div key={docType.value} {...props}>
                    {docType?.label ?? ''}
                  </div>
                )}
                getOptionLabel={docType => docType?.label ?? ''}
                value={selectedDocType}
                onChange={(event, docType) => setSelectedDocType(docType)}
                renderInput={params => (
                  <TextField {...params} variant="outlined" label="Search For DocType" />
                )}
              />
              {!isSaving ? (
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-around"
                  style={{ marginTop: '10px' }}
                >
                  <Grid item xs={6}>
                    <Button variant="contained" onClick={() => setIsModalOpen(false)}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" color="primary" onClick={updateDocType}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <div css={{ margin: '20px', textAlign: 'center' }}>
                  <CircularProgress disableShrink />
                </div>
              )}
            </div>
          ) : (
            <Fragment />
          )}
        </Fade>
      </Modal>
    </Fragment>
  );
}

const boneStructure = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  pointerEvents: 'none',
};

const pulse = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
`;

const bones = {
  backgroundColor: colors.grayExLight,
  borderRadius: 8,
  height: 16,
  width: '100%',
  marginBottom: 10,
  background: 'linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%)',
  backgroundSize: '400% 400%',
  animation: `${pulse} 2s infinite linear`,
  ':last-of-type': { marginBottom: 0, width: '75%' },
};

export function DocTileSkeleton() {
  const scapula = (
    <div css={[cardWrap, boneStructure]}>
      <div css={bones} />
      <div css={bones} />
    </div>
  );
  return (
    <div>
      {scapula}
      {scapula}
      {scapula}
      {scapula}
      {scapula}
      {scapula}
      {scapula}
      {scapula}
      {scapula}
    </div>
  );
}
