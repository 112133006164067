/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableContainer,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import TableData from './TableData';
import TableHeader from './TableHeader';
import {
  consolidateTitleCompanies,
  ConsolidationMode,
  initialState,
  updateTitleCompany,
  useTitleCompanies,
} from './useTitleCompanies';
import { useStyles } from './TitleCompanyStyles';
import { errorText } from '../CallCenter/UpdateContactInfoPanel';
import { useToaster } from '../../Hooks/toasters';

export default function ConsolidationModal() {
  const [
    { consolidationMode, mainConsolidatedTitleCompany },
    setTitleCompanies,
  ] = useTitleCompanies();
  const classes = useStyles();
  return (
    <Dialog
      open={consolidationMode === ConsolidationMode.True}
      onClose={() => setTitleCompanies({ consolidationMode: ConsolidationMode.False })}
      maxWidth="xl"
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
        <IconButton
          classes={{ root: classes.dialogTitleRoot }}
          css={{ float: 'right', paddingBottom: 0 }}
          onClick={() => setTitleCompanies({ consolidationMode: ConsolidationMode.False })}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <span css={{ fontSize: 20, fontWeight: 'bold', color: '#686868' }}>Consolidation</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText css={{ color: '#686868', margin: '8px 0 0' }}>
          <div css={{ marginBottom: 20, fontSize: 14 }}>
            Please select the main consolidated title company
          </div>
          <div css={{ fontSize: 12 }}>Title Company:</div>
        </DialogContentText>
        <TableContainer component={Paper} classes={{ root: classes.containerBorder }}>
          <Table>
            <TableHeader consolidationModal />
            <TableData consolidationModal />
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogContent classes={{ root: classes.dialogContentWithoutScroll }}>
        <div css={{ color: '#0627ca', fontSize: 11, fontWeight: 'bold' }}>
          <div
            css={{
              width: 10,
              height: 10,
              backgroundColor: '#0627ca',
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: 10,
            }}
          />
          Consolidated Title Company
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setTitleCompanies({ consolidationMode: ConsolidationMode.Edit })}
          disabled={!mainConsolidatedTitleCompany}
        >
          Edit main company details
        </Button>
        <SaveButton />
      </DialogActions>
    </Dialog>
  );
}

export function SaveButton() {
  const [
    {
      mainConsolidatedTitleCompany,
      processingConsolidation,
      consolidationMode,
      selectedIds,
      data,
      consolidationError,
    },
    setTitleCompanies,
  ] = useTitleCompanies();

  const { errorToaster } = useToaster();

  const handleConsolidation = async () => {
    try {
      setTitleCompanies({ processingConsolidation: true });
      if (consolidationMode === ConsolidationMode.Edit) {
        try {
          await updateTitleCompany(mainConsolidatedTitleCompany!);
        } catch (e) {
          const errorMessage = errorText(e);
          errorToaster(errorMessage);

          throw e;
        }
      }
      const titleCosToDelete = selectedIds;
      titleCosToDelete.delete(mainConsolidatedTitleCompany?.id!);
      await consolidateTitleCompanies(mainConsolidatedTitleCompany!, [...titleCosToDelete]);
      setTitleCompanies({ ...initialState, selectionMode: true, data });
    } catch (error) {
      console.log(error);
      setTitleCompanies({ consolidationError: true });
    }
  };

  return (
    <div>
      {consolidationError && (
        <Typography css={{ '&&': { marginTop: -20, marginLeft: -80 } }} color="error">
          Something went wrong
        </Typography>
      )}
      <Button
        css={processingConsolidation ? { pointerEvents: 'none' } : {}}
        disableElevation={processingConsolidation}
        variant="contained"
        onClick={handleConsolidation}
        color="primary"
        disabled={!mainConsolidatedTitleCompany}
      >
        {processingConsolidation ? 'Saving...' : 'Save'}
      </Button>
    </div>
  );
}
