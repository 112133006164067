/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { useState, useEffect, Fragment } from 'react';
import { match } from 'react-router';
import ShortId from 'shortid';
import format from 'date-fns/format';
import { Shipment } from './Shipments';
import Panel, { panelWrap, PanelHeader } from '../ui/Panel';
import { apiFetch, apiPost } from '../../adalConfig';
import { ShippingHeader } from '../../pages/Shipping';
import colors from '../../styles/colors';
import { RowWrapper, HeaderWrapper } from '../ui/HeaderAndRows';
import { getDocTypeName } from '../../Utils';
import { Loan, Document } from '../../globalTypes/objects';
import Icon, { IconType } from '../ui/Icon';
import { usePrevious } from '../../Hooks';

const mainWrap: CSSObject = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  color: colors.black,
  fontSize: 14,
  marginTop: 40,
  padding: 16,
  width: '100%',
};

const cell: CSSObject = {
  display: 'inline-flex',
  flexDirection: 'column',
  height: 100,
  paddingRight: 8,
  width: '50%',
  span: { display: 'block', color: colors.grayDark, marginBottom: 8 },
};

const littleIconStyle: CSSObject = {
  color: colors.gray,
  cursor: 'pointer',
  marginRight: 6,
  stroke: colors.gray,
  '&:hover': { stroke: colors.blue, color: colors.blue },
};

const griddy: CSSObject = {
  gridColumnGap: '8px',
  gridTemplateColumns: '7fr 5fr 5fr 3fr',
};

type ShipmentDisplayProps = {
  shipmentId: number;
  match: match<{ shippingId: string }>;
};

// eslint-disable-next-line no-shadow
export default function ShipmentDisplay({ match }: ShipmentDisplayProps) {
  const [shipment, setShipment] = useState<Shipment>();
  const [updatedTracking, setUpdatedTracking] = useState<string>();
  const [editTracking, setEditTracking] = useState(false);
  const { shippingId } = match.params;

  useEffect(() => {
    const getShipment = async () => {
      const { data: fetchedShipment } = await apiFetch<Shipment>(
        `/api/shipping/shipment?id=${shippingId}`,
      );
      setShipment(fetchedShipment);
    };
    getShipment();
  }, [shippingId]);

  const prevShipment = usePrevious<Shipment | undefined>(shipment);
  useEffect(() => {
    const updateTracking = async () => {
      await apiPost('/api/shipping/trackingNumber', shipment!);
    };
    shipment && setUpdatedTracking(shipment.trackingNumber);

    // if tracking number was updated make api post
    shipment &&
      prevShipment &&
      shipment.trackingNumber !== prevShipment.trackingNumber &&
      updateTracking();
  }, [prevShipment, shipment]);

  const onEditTrackingClick = () => {
    setEditTracking(true);
  };

  const onTrackingXClick = () => {
    setEditTracking(false);
    setUpdatedTracking(shipment!.trackingNumber);
  };

  const onTrackingCheckClick = async () => {
    setEditTracking(false);
    setShipment({ ...shipment!, trackingNumber: updatedTracking! });
  };

  return (
    <Fragment>
      <ShippingHeader fixed />
      <div
        css={{
          height: 'calc(100vh - 66px)',
          marginLeft: 40,
          maxWidth: 1280,
          paddingTop: 32,
          marginTop: 64,
        }}
      >
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div css={{ width: '100%', maxWidth: 720 }}>
            <Panel>
              <PanelHeader text="Documents" />
              <HeaderWrapper styles={griddy}>
                <div>Borrower</div>
                <div>Loan number</div>
                <div>Document Type</div>
                <div>Format</div>
              </HeaderWrapper>
              <div css={{ overflow: 'auto', height: 600 }}>
                {shipment &&
                  shipment.documents.map(doc => <DocumentRow key={ShortId.generate()} doc={doc} />)}
              </div>
            </Panel>
          </div>
          <div css={panelWrap}>
            <Panel>
              <PanelHeader text="Shipment Information" />
              {shipment && (
                <div css={mainWrap}>
                  <div css={cell}>
                    <span>Investor</span>
                    {shipment.investor}
                  </div>
                  <div css={cell}>
                    <span>Tracking number</span>
                    {!editTracking && (
                      <div className="df">
                        <Icon
                          icon={IconType.Pencil}
                          css={littleIconStyle}
                          onClick={onEditTrackingClick}
                        />
                        {shipment!.trackingNumber}
                      </div>
                    )}
                    {editTracking && (
                      <div
                        css={{
                          border: `1px solid ${colors.blue}`,
                          borderRadius: 4,
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: 8,
                          svg: { height: 20, width: 20 },
                        }}
                      >
                        <Icon
                          icon={IconType.Check}
                          css={littleIconStyle}
                          onClick={onTrackingCheckClick}
                        />
                        <Icon icon={IconType.X} css={littleIconStyle} onClick={onTrackingXClick} />
                        <input
                          autoFocus
                          value={updatedTracking}
                          onChange={e => setUpdatedTracking(e.target.value)}
                          css={{ caretColor: colors.blue }}
                        />
                      </div>
                    )}
                  </div>
                  <div css={[cell, { height: 50 }]}>
                    <span>Clients</span>
                    {shipment.clients.map(c => (
                      <React.Fragment key={ShortId.generate()}>
                        <div>{c.company}</div>
                      </React.Fragment>
                    ))}
                  </div>
                  <div css={[cell, { height: 50 }]}>
                    <span>Date Sent</span>
                    {format(new Date(Date.parse(shipment!.dateSent)), 'MM/dd/yyyy')}
                  </div>
                </div>
              )}
            </Panel>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function DocumentRow({ doc }: { doc: { document: Document; loan: Loan } }) {
  return (
    <RowWrapper styles={griddy}>
      <div>{doc.loan.borrower}</div>
      <div>{doc.loan.loanNumber}</div>
      <div>{getDocTypeName(doc.document.documentType)}</div>
      <div>{doc.document.hardCopy ? 'Physical' : 'Digital'}</div>
    </RowWrapper>
  );
}
