/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import Link from '@mui/material/Link';
import DataTable from '../../components/ui/DataTable/DataTable';

type Props = {
  loanId: number;
};

const LoanMatches = ({ loanId }: Props) => {
  type RowData = {
    barcodeId: number;
    docType: string;
    matchType: string;
    matchCreatedDate: Date;
    extractedLoanNumber: string;
    extractedDateFunded: Date;
    extractedLoanAmount: number;
    matchQuality: string;
    skipped: boolean;
    deleted: boolean;
    irrelevantType: string;
    uploadedByTitleCompany: boolean;
  };

  const columns = [
    {
      field: 'barcodeId',
      title: 'Barcode Id',
      render: (rowData: RowData) => (
        <Link target="_blank" href={`/documents/${rowData.barcodeId}`}>
          {rowData.barcodeId}
        </Link>
      ),
    },
    {
      field: 'docType',
      title: 'Document Type',
    },
    {
      field: 'matchCreatedDate',
      title: 'Matched Date',
      render: rowData =>
        rowData.matchCreatedDate === null
          ? null
          : new Date(rowData.matchCreatedDate).toLocaleDateString(),
    },
    {
      field: 'extractedLoanNumber',
      title: 'Extracted Loan Number',
    },
    {
      field: 'extractedDateFunded',
      title: 'Extracted Date Funded',
      render: rowData =>
        rowData.extractedDateFunded === null
          ? null
          : new Date(rowData.extractedDateFunded).toLocaleDateString(),
    },
    {
      field: 'matchQuality',
      title: 'match Quality',
    },
    {
      field: 'matchType',
      title: 'Match Status',
    },
    {
      field: 'deleted',
      title: 'Deleted',
      render: rowData =>
          rowData.deleted === true
              ? `Yes${rowData.irrelevantType ? `, ${rowData.irrelevantType}`: ''}`
              : 'No',
    },
  ];
  return (
    <Fragment>
      <DataTable<RowData>
        title="Suggested Matches"
        columns={columns}
        url={`/api/loans/${loanId}/SuggestedMatches`}
        emptyDataMsg="There are no pending or rejected matches for this loan"
      />
    </Fragment>
  );
};

export default LoanMatches;
