import React from 'react';
import Slide from '@mui/material/Slide';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  root: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      'overflow-y': 'initial',
    },
    '& .css-uhb5lp': {
      'overflow-y': 'initial',
    },
  },
}));

export default function AlertDialogSlide(props) {
  const {
    isOpen,
    handleClose,
    onConfirm,
    confirmationHeader,
    confirmationText,
    disableConfirm,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      // @ts-ignore
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      className={classes.root}
    >
      <DialogTitle id="alert-dialog-slide-title">{confirmationHeader}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {confirmationText}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" disabled={disableConfirm}>
          Confirm
        </Button>
        <Button onClick={handleClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
