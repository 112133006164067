/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component } from 'react';
import colors from '../../../../../styles/colors';
import Button from '../../../../ui/Button';
import { apiPost } from '../../../../../adalConfig';
import { Note } from '../../../../../globalTypes/objects';

// #region css
const textAreaWrap: CSSObject = {
  backgroundColor: colors.white,
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  height: 160,
  marginBottom: 32,
  resize: 'none',
  width: '100%',
};
const buttonText: CSSObject = {
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: 16,
};

const isTyping: CSSObject = {
  border: '1px solid #0828cc',
  borderRadius: 4,
};
// #endregion

const updateNote = async (note: Note, id: number) => {
  await apiPost('/api/loanErrors/updateNote', { note, id });
};

type UpdateNoteModalProps = { note: Note; handleClose: () => void };
type UpdateNoteModalState = { noteText?: string };
export default class UpdateNoteModal extends Component<UpdateNoteModalProps, UpdateNoteModalState> {
  state = { noteText: undefined };

  componentDidMount() {
    const { note } = this.props.note;
    this.setState({ noteText: note });
  }

  saveNote = async () => {
    const { noteText } = this.state;
    const { note, handleClose } = this.props;
    await updateNote(noteText!, note.id);
    handleClose();
  };

  render() {
    const { noteText } = this.state;
    const { handleClose, note } = this.props;
    return (
      <div css={{ pointerEvents: 'auto', margin: '32px 0' }}>
        <textarea
          css={[textAreaWrap, noteText && isTyping]}
          value={noteText}
          onChange={e => this.setState({ noteText: e.target.value })}
          autoFocus
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            secondary
            onClick={e => {
              e.stopPropagation();
              handleClose();
            }}
            styleOverrides={buttonText}
          >
            Cancel
          </Button>
          <Button
            disabled={!noteText || note.note === noteText}
            onClick={e => {
              e.stopPropagation();
              this.saveNote();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}
