/** @jsx jsx */
import { jsx } from '@emotion/core';
import Icon, { IconType } from '../../ui/Icon';
import { Fragment } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  messageIconContainer: {
    width: '70px',
  },
  messageTextContainer: {
    width: '618px',
    height: '37px',
    margin: '16px 0 17px 18px',
    fontFamily: 'Roboto, Helvetica, sans-serif',
    fontSize: '27px',
    lineHeight: 'normal',
    textAlign: 'left',
    color: '#100f13',
    textTransform: 'uppercase',
  },
}));

const Message = ({ children, iconType }: { children: string; iconType: IconType }) => {
  const classes = useStyles();

  const messageText = (text: string) => {
    text = text.toLowerCase();
    const matchResults = text.match(/week|tomorrow/);
    if (matchResults) {
      const regularText = text.slice(0, matchResults.index);
      const boldedText = text.slice(matchResults.index);

      return (
        <Fragment>
          <span>{regularText}</span>
          <b>{boldedText}</b>
        </Fragment>
      );
    }

    return <Fragment>{text}</Fragment>;
  };

  return (
    <div className="df">
      <div className={classes.messageIconContainer}>
        <Icon icon={iconType} />
      </div>
      <div className={classes.messageTextContainer}>{messageText(children)}</div>
    </div>
  );
};

export default Message;
