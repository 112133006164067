/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { memo, useContext } from 'react';
import { ManualFollowupsProvider, ManualFollowUpsProps } from './useManualFollowups';
import ManualFollowupsPanel from './ManualFollowupsPanel';
import EmailValidationPanel from './EmailValidationPanel';
import OverrideEmailPanel from './OverrideEmailPanel';
import AdditionalRecipientPanel from './AdditionalRecipientPanel';
import TitlePortalEmailPanel from './TitlePortalEmailPanel';
import { AuthContext } from '../../AuthContext';
import { isAdmin } from '../../../Utils';

function ManualFollowups(props: ManualFollowUpsProps) {
  const { roles } = useContext(AuthContext);
  return (
    <ManualFollowupsProvider>
      <ManualFollowupsPanel {...props}>
        <EmailValidationPanel {...props} />
        <OverrideEmailPanel />
        <AdditionalRecipientPanel />
        {isAdmin(roles) && <TitlePortalEmailPanel {...props} />}
      </ManualFollowupsPanel>
    </ManualFollowupsProvider>
  );
}

export default memo(ManualFollowups);
