/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment, useState } from 'react';
import Header from '../components/ui/Header';
import { ScanIcon } from '../components/ui/icons';
import BarcodeInput from '../components/Utilities/BarcodeInput';
import { apiFetch } from '../adalConfig';
import { ConfirmModal as ConfirmModalType } from '../globalTypes/objects';
import ConfirmModal from '../components/ui/ConfirmModal';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 'auto',
    height: '70%',
    flexDirection: 'column',
  },
  scanContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  scanText: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '24px',
    marginBottom: '8px',
  },
  p: {
    fontSize: '16px',
    margin: 0,
  },
  iconDiv: {
    width: 224,
    height: 224,
    borderRadius: '50%',
    backgroundColor: '#f0f0f3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export default function BatchVerificationStart({ history }) {
  const classes = useStyles({});
  const [modalData, setModal] = useState<ConfirmModalType>({
    isOpen: false,
    header: '',
    message: '',
    confirmText: '',
    cancelText: '',
    handleCancel: () => {},
    handleConfirm: () => {},
  });
  const handleClick = () => {
    onDocScanned('822213');
  };
  const onDocScanned = documentId => {
    if (documentId) {
      checkIfDocInBatch(documentId);
    }
  };
  const checkIfBatchUploaded = async documentId => {
    const { data: isBatchUploaded } = await apiFetch<boolean>(
      `/api/documents/CheckIfBatchUploaded?docId=${documentId}`,
    );
    isBatchUploaded
      ? history.push(`/batch-verification/${documentId}`)
      : setModal({
          isOpen: true,
          header: 'Batch has not been uploaded yet.',
          confirmText: 'OK',
          message: 'Please try again later.',
        });
  };
  const checkIfDocInBatch = async documentId => {
    const { data: isDocInBatch } = await apiFetch<boolean>(
      `/api/batches/CheckIfDocumentInBatch?docId=${documentId}`,
    );
    isDocInBatch ? checkIfBatchUploaded(documentId) : checkIfDocInSystem(documentId);
  };

  const checkIfDocInSystem = async documentId => {
    const { data: document } = await apiFetch(`/api/documents/GetDocument?docId=${documentId}`);
    var header = '';
    header = document
      ? `The document has not yet been uploaded`
      : `The document is not recognized in the system`;
    setModal({
      isOpen: true,
      header: header,
      confirmText: 'OK',
      message: '',
    });
  };
  return (
    <Fragment>
      <div>
        <Header headerText="Batch Confirmation" />
        <div className={classes.container}>
          <div className={classes.scanContainer}>
            <div className={classes.iconDiv}>
              <ScanIcon />
            </div>
            <p className={classes.scanText}>Ready to scan</p>
            <p className={classes.p}>Start by scanning the barode of any document</p>
          </div>

          <Button onClick={handleClick}>click</Button>
        </div>
        <BarcodeInput hidden keepFocus onChange={onDocScanned} />
      </div>
      <ConfirmModal modalData={modalData} />
    </Fragment>
  );
}
