/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  Dispatch,
  Fragment,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MaterialTable, { Column, Icons, Localization, Options, Query } from 'material-table';
import makeStyles from '@mui/styles/makeStyles';
import dotObject from 'dot-object';
import { Container } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import isEqual from 'lodash/isEqual';
import icons from './Icons';
import { apiFetch } from '../../../adalConfig';
import {
  ActiveFiltersForApiRequest,
  ActiveFilterValue,
  Filter,
  FilterOption,
  FiltersForFilterType,
  FilterType,
  FilterTypes,
} from './types';
import Filters from './Filters';
import useFileExport from './FileExport';
import HeaderFeatures from './HeaderFeatures';
import { useToaster } from '../../../Hooks/toasters';
import {
  NumberParam,
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import './styles.css';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  container: {
    marginLeft: 0,
    marginRight: 0,
    padding: '8px 32px 32px',
    maxWidth: '100%',
  },
  formControl: {
    margin: 16,
  },
});

type Props = {
  title: string | ReactElement;
  columns: Column<object>[];
  url?: string;
  data?: object[];
  actions?: (tableRef) => any[];
  onRowClick?: (event, rowData, togglePanel) => void;
  detailPanel?: (rowData) => ReactNode;
  emptyDataMsg?: string;
  localization?: Localization;
  options?: Options<any>;
  onSelectionChange?: (rows) => void;
  groupBy?: (row, rows) => {};
  onTableRefReceived?: (refreshFunction: () => () => void) => void;
  headerStyle?: { [key: string]: string };
  renderCustomFilters?: JSX.Element;
  dataTableFilterTypes?: FilterTypes;
  setDataTableFilterTypes?: Dispatch<SetStateAction<FilterTypes>>;
  orderBy?: Column<any>;
  orderDirection?: 'asc' | 'desc';
  customExport?: (rows) => void;
  allowMultiSearch?: boolean;
  condensed?: boolean;
};

type OrderBy = {
  column: number | string;
  directionAscending: boolean;
};

type DataTableParams = {
  filters?: object;
  searchText: string;
  searchWhereIn?: string;
  orderBy: OrderBy;
  offset: number;
  fetch: number;
  getRowCount?: boolean;
  isExporting?: boolean;
};

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
type QueryExtension = {
  localFilters: {};
};

const DataTable = <RowData extends object>({
  title,
  columns,
  url,
  data,
  actions = tableRef => [],
  onRowClick,
  detailPanel,
  emptyDataMsg,
  options,
  onSelectionChange,
  groupBy,
  onTableRefReceived,
  headerStyle = {},
  renderCustomFilters,
  dataTableFilterTypes,
  setDataTableFilterTypes,
  orderBy = {},
  orderDirection = 'asc',
  customExport,
  allowMultiSearch = false,
  condensed = false,
}: Props) => {
  interface TableQuery extends Overwrite<Query<RowData>, QueryExtension> {}

  const [query, setQuery] = useQueryParams({
    pageSize: withDefault(NumberParam, 25),
    filters: ObjectParam,
    search: StringParam,
  });

  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [pastSearchText, setPastSearchText] = useState(query.search ?? '');
  const [pageSize, setPageSize] = useState(query.pageSize ?? 25);
  const [prevFilters, setPrevFilters] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [combinedOptions, setCombinedOptions] = useState(options || {});
  const [isExporting, setIsExporting] = useState(false);
  const [isBulkSearchMode, setIsBulkSearchMode] = useState(false);

  const fileExport = useFileExport<Partial<RowData>>();
  const xhr = useRef<CancelTokenSource | undefined>(undefined);
  const tableRef = useRef(null);
  const { errorToaster } = useToaster();
  const classes = useStyles();

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    if (!url) {
      return;
    }

    const { onQueryChange }: any = tableRef.current;
    onQueryChange({ localFilters: query.filters, pageSize: query.pageSize, search: query.search });

    return () => xhr.current?.cancel();
  }, [tableRef?.current]);

  useEffect(() => {
    const additionalOptions = {} as NonNullable<typeof options>;

    if (url) {
      additionalOptions.exportCsv = () => {
        if (!(tableRef && tableRef.current)) {
          return;
        }

        setIsExporting(true);
      };
    }

    if (!options?.maxBodyHeight) {
      const dataTableRoot = document.querySelector('.MuiTableBody-root');
      const dataTableFooter = document.querySelector<HTMLElement>('.MuiTableFooter-root');

      const distanceFromTop = dataTableRoot
        ? window.scrollY + dataTableRoot.getBoundingClientRect().top
        : 0;
      const tableFooterHeight = dataTableFooter ? dataTableFooter.offsetHeight : 0;

      additionalOptions.maxBodyHeight = `max(calc(100vh - ${distanceFromTop}px - ${tableFooterHeight}px), 300px)`;
    }

    setCombinedOptions(originalOptions => ({ ...originalOptions, ...additionalOptions }));
  }, [tableRef, url, setCombinedOptions]);

  useEffect(() => {
    if (isExporting) {
      const { onQueryChange }: any = tableRef.current;
      onQueryChange();
    }

    return () => xhr.current?.cancel();
  }, [isExporting]);

  const dataTableActions = () => {
    const actionsList: {}[] = [];

    if (!data) {
      actionsList.push({
        icon: icons.Refresh,
        tooltip: 'Refresh',
        isFreeAction: true,
        onClick: refresh,
      });
    }

    if (dataTableFilterTypes?.length) {
      actionsList.push({
        icon: icons.FilterListSharp,
        tooltip: 'Filters',
        isFreeAction: true,
        onClick: () => setIsDrawerOpen(true),
      });
    }

    return actionsList;
  };

  const getActiveFilterOptionIds = (filter: Filter): number[] =>
    filter.filterOptions
      .filter((filterOption: FilterOption) => filterOption.active)
      .map((filterOption: FilterOption) => filterOption.id);

  const isActiveFilter = (filter: Filter): boolean =>
    filter.filterOptions.find((filterOption: FilterOption) => filterOption.active) !== undefined;

  const columnsMap = useMemo(
    () => new Map(columns.map(column => [column.field, column])),
    [columns],
  );

  const refresh = useCallback(async () => {
    if (!(tableRef && tableRef.current)) {
      return;
    }

    const { retry }: any = tableRef.current;
    retry();

    setIsDrawerOpen(false);
  }, [tableRef]);

  const getFilterKeyValuePairs = useCallback(
    (filtersForFilterTypes: FiltersForFilterType): ActiveFiltersForApiRequest =>
      filtersForFilterTypes.filters
        .filter(isActiveFilter)
        .reduce((filtersForApiRequest: ActiveFiltersForApiRequest, filter: Filter) => {
          let activeFilterIds: ActiveFilterValue = getActiveFilterOptionIds(filter);
          if (filtersForFilterTypes.filterType === FilterType.Radio) {
            [activeFilterIds] = activeFilterIds;
          }

          filtersForApiRequest[filter.label] = activeFilterIds;

          return filtersForApiRequest;
        }, {}),
    [],
  );

  const prepareFilterForApiRequest = useCallback(
    (filterTypes: FilterTypes): ActiveFiltersForApiRequest =>
      filterTypes.reduce(
        (
          filtersForApiRequest: ActiveFiltersForApiRequest,
          filtersForFilterTypes: FiltersForFilterType,
        ) => ({
          ...filtersForApiRequest,
          ...getFilterKeyValuePairs(filtersForFilterTypes),
        }),
        {},
      ),
    [getFilterKeyValuePairs],
  );

  const refreshDataTableWithFilters = useCallback(() => {
    if (!(tableRef && tableRef.current && dataTableFilterTypes)) {
      return;
    }

    const apiFilters = prepareFilterForApiRequest(dataTableFilterTypes);
    if (Object.keys(apiFilters).length === 0) {
      return;
    }

    const { onQueryChange }: any = tableRef.current;
    onQueryChange({ localFilters: apiFilters });
  }, [prepareFilterForApiRequest, dataTableFilterTypes, tableRef]);

  const removeHiddenColumns = useCallback(
    (dataTableRow: RowData) =>
      Object.keys(dataTableRow).reduce((row, field) => {
        if (columnsMap.get(field)?.hidden !== true) {
          row[field] = dataTableRow[field];
        }

        return row;
      }, {} as Partial<RowData>),
    [columnsMap],
  );

  const exportDatatable = useCallback(
    (dataTableRows: RowData[]) => {
      fileExport(
        dataTableRows.map(removeHiddenColumns),
        options?.exportFileName?.toString() ?? title.toString(),
      );
    },
    [options?.exportFileName, removeHiddenColumns, fileExport, title],
  );

  // passed to the datatable to fetch the data
  const fetchTableData = useCallback(
    async (query: TableQuery) => {
      if (!url) {
        throw new Error('A data array or a URL is required by the datatable');
      }

      if (dataTableFilterTypes?.length && !query.hasOwnProperty('localFilters')) {
        return;
      }

      if (
        query.search !== pastSearchText ||
        totalDataCount === null ||
        !isEqual(query.localFilters, prevFilters)
      ) {
        query.orderDirection = orderDirection;
        query.orderBy = orderBy;
      }

      let columnName = '';
      let columnNumber = 1;
      if (query.orderBy && query.orderBy.field !== undefined) {
        columnName = query.orderBy.field.toString();
        const columnIndex = columns.findIndex(column => column.field === columnName);
        columnNumber = columnIndex !== -1 ? columnIndex + 1 : columnNumber;
      }

      const params: DataTableParams = {
        searchText: isBulkSearchMode ? '' : query.search,
        orderBy: {
          // if orderBy was passed by user, use the column name. Otherwise, use existing behavior of column number
          column: orderBy && query.orderBy.field !== undefined ? columnName : columnNumber,
          directionAscending: query.orderDirection !== 'desc',
        },
        offset: query.page * query.pageSize,
        fetch: query.pageSize,
        filters: query.localFilters,
        isExporting,
      };

      if (isBulkSearchMode) {
        params.searchWhereIn = query.search
          .split(',')
          .map(value => value.trim())
          .join(',');
      }

      if (query.search !== pastSearchText || totalDataCount === null) {
        params.getRowCount = true;
        setPastSearchText(query.search);
      }

      if (!isEqual(query.localFilters, prevFilters)) {
        params.getRowCount = true;
      }

      // We want to make sure that the latest filter is the one that's being displayed rather than the the latest request that returned (for example when a user selects two filters one after the other but the first request takes longer to run, we want to cancel the first request so that the datatable displays the the results of the latest filters selected)
      if (xhr.current) {
        xhr.current.cancel();
      }

      try {
        const ajaxRequest = axios.CancelToken.source();
        xhr.current = ajaxRequest;

        const { data: results } = await apiFetch<{ dataTableRows: RowData[]; count: number }>(url, {
          params: dotObject.dot(params),
          cancelToken: ajaxRequest.token,
        });

        xhr.current = undefined;

        setPrevFilters(query.localFilters);

        if (isExporting) {
          customExport
            ? customExport(results.dataTableRows)
            : exportDatatable(results.dataTableRows);

          setIsExporting(false);
        }

        const totalCount = results.count || totalDataCount;
        setTotalDataCount(totalCount);

        window.scrollTo(0, 0);

        setQuery(prev => {
          return {
            ...prev,
            pageSize: query.pageSize,
            filters: query.localFilters,
            search: query.search,
          };
        });

        return {
          data: results.dataTableRows,
          totalCount: totalCount || 0,
          page: query.page,
        };
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else if (!axios.isCancel(e)) {
          errorToaster(e.message);
        }

        xhr.current = undefined;

        return {
          data: [],
          totalCount: 0,
          page: 0,
        };
      }
    },
    [
      url,
      pastSearchText,
      totalDataCount,
      prevFilters,
      orderBy,
      orderDirection,
      columns,
      customExport,
      exportDatatable,
      isExporting,
      query,
      setQuery,
    ],
  );

  // EFFECTS
  useEffect(() => {
    onTableRefReceived && onTableRefReceived(() => refresh);
  }, [tableRef, onTableRefReceived, refresh]);

  useEffect(() => {
    refreshDataTableWithFilters();

    return () => xhr.current?.cancel();
  }, [dataTableFilterTypes]);

  const defaultHeaderStyles: { [key: string]: string } = {
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  };

  if (condensed) {
    defaultHeaderStyles.padding = '6px';
  }

  return (
    <Fragment>
      {dataTableFilterTypes && setDataTableFilterTypes && (
        <Filters
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onClick={() => setIsDrawerOpen(true)}
          dataTableFilterTypes={dataTableFilterTypes}
          setDataTableFilterTypes={setDataTableFilterTypes}
        >
          {renderCustomFilters}
        </Filters>
      )}
      <Container className={classes.container}>
        <HeaderFeatures
          dataTableFilterTypes={dataTableFilterTypes}
          tableRef={tableRef}
          allowMultiSearch={allowMultiSearch}
          isBulkSearchMode={isBulkSearchMode}
          setIsBulkSearchMode={setIsBulkSearchMode}
        />
        <MaterialTable
          icons={icons as Icons}
          title={<span style={{ textTransform: 'capitalize' }}>{title}</span>}
          columns={columns.map(c => {
            if (condensed) {
              c.cellStyle = { padding: '6px' };
            }

            return { ...c };
          })}
          data={data || fetchTableData}
          tableRef={tableRef}
          actions={[...dataTableActions(), ...actions(tableRef)]}
          onRowClick={onRowClick}
          detailPanel={detailPanel}
          isLoading={!!xhr.current}
          onSelectionChange={rows =>
            options && options.selection && onSelectionChange && onSelectionChange(rows)
          }
          onChangeRowsPerPage={setPageSize}
          parentChildData={groupBy ? (row, rows) => groupBy(row, rows) : () => null}
          localization={
            {
              body: {
                emptyDataSourceMessage: emptyDataMsg ?? 'No records to display',
                toolbar: {
                  exportCSVName: 'Export as Excel',
                },
              },
            } as Localization
          }
          options={{
            pageSize,
            pageSizeOptions: [10, 25, 50, 100, 250, 500],
            debounceInterval: 500,
            exportButton: true,
            exportAllData: true,
            emptyRowsWhenPaging: false,
            searchAutoFocus: true,
            thirdSortClick: false,
            ...combinedOptions,
            search: combinedOptions.search !== false && !isBulkSearchMode,
            searchText: pastSearchText,
            headerStyle: { ...defaultHeaderStyles, ...headerStyle },
            rowStyle: (rowData: RowData) => {
              const defaultRowStyles: { [key: string]: string } = {
                fontSize: '12.25px',
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              };

              const { rowStyle: rowStyleFunc }: any = combinedOptions;
              const rowStyle = rowStyleFunc && rowStyleFunc(rowData);

              return {
                ...defaultRowStyles,
                ...rowStyle,
              };
            },
          }}
        />
      </Container>
    </Fragment>
  );
};

export default DataTable;
