import React from 'react';

export default function NativePdfRenderer({ link, rerender }) {
  return (
    <div className="aspect_ratio_keeper">
      <object
        width="100%"
        height="100%"
        data={`${link}&rerender=${rerender}#view=FitH`}
        type="application/pdf"
        style={{
          background: `transparent url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='50px' width='120px'><text x='0' y='15' fill='gray' font-size='20'>Loading...</text></svg>") no-repeat fixed center`,
        }}
      />
    </div>
  );
}
