export default function beamerInit(id, args = {}) {
  if (!window || !id) return;

  // @ts-ignore
  window.beamer_config = {
    product_id: id,
    bottom: 10,
    right: 10,
    callback: () => {
      const el = document.getElementById('beamerSelector');
      if (el?.style) {
        el.style.width = '50px';
        el.style.height = '50px';
      }
      window.onbeforeprint = () => {
        if (el?.style) el.style.display = 'none';
      };

      window.onafterprint = () => {
        if (el?.style) el.style.display = '';
      };
    },
    ...args,
  };

  const beamerURL = 'https://app.getbeamer.com/js/beamer-embed.js';

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');

  script.defer = true;
  script.src = beamerURL;
  head.appendChild(script);
}
