import React, { useState, useEffect } from 'react';
import TextInput from '../ui/Inputs/TextInput';

// onChange may need to be a useCallback if it is setting state

type SearchAnyInputProps = {
  onChange: (loanNumber: string) => any;
  keepFocus?: boolean;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
  hidden?: boolean;
  [key: string]: any;
};
export default function SearchAnyInput({
  onChange,
  keepFocus,
  inputRef,
  hidden,
  ...rest
}: SearchAnyInputProps) {
  const [searchText, setSearchText] = useState<string>();

  useEffect(() => {
    onChange(searchText!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <TextInput
      ref={inputRef}
      // @ts-ignore
      spellCheck="false"
      {...(keepFocus && {
        onBlur: e => {
          e.preventDefault();
          e.target.focus();
        },
        autoFocus: true,
      })}
      onKeyDown={e => {
        if (keepFocus && e.key === 'Tab') {
          e.preventDefault();
          e.target.focus();
        }
        const previousScan = searchText !== undefined;
        if (previousScan) {
          setSearchText(undefined);
          e.target.value = '';
        }
        if (e.key === 'Enter') {
          setSearchText(e.target.value.replace(/\D+/g, ''));
        }
      }}
      {...(hidden && {
        css: { opacity: 0, pointerEvents: 'none', position: 'absolute', top: -9999, left: -9999 },
        tabIndex: '-1',
      })}
      placeholder="Search [Loan Number or Barcode Id]"
      {...rest}
    />
  );
}
