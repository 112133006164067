/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { startCase } from 'lodash';
import { useImmer } from 'use-immer';
import { LoadingButton } from '@mui/lab';
import Header from '../../components/ui/Header';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import TextInput from '../../components/ui/Inputs/TextInput';
import { apiPost } from '../../adalConfig';

type CheckAccessForm = {
  clientId: string;
  instanceId: string;
  secretKey: string;
};

type EncompassAccessResult =
  | { kind: 'INITIAL' }
  | { kind: 'SUCCESS'; accessToken: string }
  | { kind: 'FAIL'; error: object };

const initState: CheckAccessForm = {
  clientId: '',
  instanceId: '',
  secretKey: '',
};

async function checkAccess(form: CheckAccessForm) {
  const res = await apiPost<{
    [key: string]: string;
  }>(`/api/EncompassTools/GetEncompassToken`, form);
  return res.data;
}

export default function EncompassTools() {
  const [form, setForm] = useImmer<CheckAccessForm>(initState);
  const [accessResult, setAccessResult] = useState<EncompassAccessResult>({ kind: 'INITIAL' });

  const formIsValid = form.instanceId && form.clientId && form.secretKey;

  const checkAccessMutation = useMutation({
    mutationFn: checkAccess,
    onSuccess: data => {
      if (data.accessToken) setAccessResult({ kind: 'SUCCESS', accessToken: data.accessToken });
      else setAccessResult({ kind: 'FAIL', error: data });
    },
    onError: error => {
      if (typeof error === 'object' && error !== null) setAccessResult({ kind: 'FAIL', error });
    },
  });

  function checkAccessFormSubmitted(err: React.FormEvent<HTMLFormElement>) {
    err.preventDefault();
    checkAccessMutation.mutate(form);
  }

  return (
    <div>
      <Header headerText="Encompass Tools" />
      <div css={{ marginLeft: '50px', marginTop: '50px' }}>
        <Panel>
          <PanelHeader text="Enter Credentials" />
          <form onSubmit={checkAccessFormSubmitted}>
            <TextInput
              value={form.clientId}
              placeholder="Client Id"
              onChange={e =>
                setForm(draft => {
                  draft.clientId = e.target.value;
                })
              }
            />
            <TextInput
              value={form.instanceId}
              placeholder="Instance Id"
              onChange={e =>
                setForm(draft => {
                  draft.instanceId = e.target.value;
                })
              }
            />
            <TextInput
              value={form.secretKey}
              placeholder="Secret Key"
              onChange={e =>
                setForm(draft => {
                  draft.secretKey = e.target.value;
                })
              }
            />
            <div css={{ marginTop: '25px', display: 'flex', justifyContent: 'center' }}>
              <LoadingButton
                color="primary"
                variant="contained"
                size="large"
                loading={checkAccessMutation.isLoading}
                disabled={checkAccessMutation.isLoading || !formIsValid}
                type="submit"
              >
                Check Access
              </LoadingButton>
            </div>
          </form>

          <div css={{ textAlign: 'left', marginTop: '20px', marginLeft: '15px' }}>
            {accessResult.kind === 'SUCCESS' && (
              <div>
                <div css={{ marginBottom: '15px' }}>Successfully connected!</div>
                <div>Access Token: {accessResult.accessToken}</div>
              </div>
            )}
            {accessResult.kind === 'FAIL' && (
              <div>
                <div css={{ marginBottom: '15px' }}>Unable to connect: </div>
                {Object.entries(accessResult.error).map(([key, val]) => (
                  <pre>
                    <span>{startCase(key)}: </span>
                    <span>{val}</span>
                  </pre>
                ))}
              </div>
            )}
          </div>
        </Panel>
      </div>
    </div>
  );
}
