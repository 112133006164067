/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Fragment } from 'react';

import FileUploadIcon, { FaceType } from '../FileUploadIcon';
import Button from '../../ui/Button';

const outerContainer: CSSObject = {
  width: '70%',
  textAlign: 'center',
  fontSize: 16,
  margin: 'auto',
};

const errorImage: CSSObject = {
  marginTop: '188px',
  marginBottom: 0,
  display: 'flex',
  justifyContent: 'center',
};

const h3: CSSObject = {
  marginTop: '40px',
  marginBottom: '16px',
  fontSize: 24,
  fontStyle: 'normal',
};

const line1: CSSObject = { color: 'red' };

const line2: CSSObject = { marginBottom: 40, lineHeight: '1.8' };

type ImportErrorProps = {
  errorMessage: string | JSX.Element;
  reset: () => void;
};

export default ({ errorMessage, reset }: ImportErrorProps) => (
  <Fragment>
    <div css={outerContainer}>
      <div css={errorImage}>
        <FileUploadIcon type={FaceType.Error} />
      </div>
      <h3 css={h3}>Spreadsheet Error</h3>
      <div css={line1}>{errorMessage} </div>
      <div css={line2}>Please edit spreadsheet and try importing again.</div>
      <Button large onClick={reset}>
        Try Again
      </Button>
    </div>
  </Fragment>
);
