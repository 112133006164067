import { CSSObject } from '@emotion/core';

import colors from '../../../styles/colors';

export const containerStyles = (isFocused: boolean): CSSObject => ({
  position: 'relative',
  verticalAlign: 'top',
  zIndex: isFocused ? 30 : 10,
});

export const inputStyles = (isError: boolean): CSSObject => ({
  backgroundColor: '#fff',
  border: `solid 1px ${isError ? colors.red : colors.grayLight}`,
  borderRadius: 4,
  display: 'flex',
  fontSize: 14,
  height: 48,
  overflow: 'hidden',
  padding: '0px 16px 0 40px',
  textOverflow: 'ellipsis',
  transition: 'color .2s',
  whiteSpace: 'nowrap',
  ' div': {
    padding: 0,
    margin: 0,
  },
});

export const openMenuStyles: CSSObject = {
  background: colors.white,
  border: 'solid 1px #e5e5ea',
  borderRadius: 4,
  boxSizing: 'border-box',
  cursor: 'pointer',
  marginTop: 10,
  maxHeight: 450,
  overflow: 'auto',
  padding: '9px 9px',
  position: 'absolute',
  textAlign: 'left',
  width: '100%',
};

export const itemStyles = (isFocus: boolean): CSSObject => ({
  backgroundColor: isFocus ? colors.grayExLight : 'white',
  borderRadius: 4,
  color: colors.black,
  margin: 0,
  padding: '9px 16px',
  transition: 'background-color .3s ease-out, color .3s ease-out',
  ':hover': { backgroundColor: colors.grayExLight, cursor: 'pointer' },
});

export const searchglassStyles = (isFocused: boolean): CSSObject => ({
  display: 'inline-block',
  left: 16,
  position: 'absolute',
  color: isFocused ? colors.blue : colors.gray,
  top: 17,
  transition: 'all .2s',
  zIndex: 10,
});

export const matchedStyles = { color: colors.blue };
