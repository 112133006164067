/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';
import colors from '../../../../../styles/colors';

// #region  CSS

const outerCon: CSSObject = {
  color: colors.black,
  fontSize: 14,
  marginTop: 32,
  width: 476,
};

const header: CSSObject = {
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 26,
};

const columnHeader: CSSObject = {
  color: colors.grayDark,
  marginBottom: 8,
};

const column: CSSObject = {
  display: 'inline-block',
  marginRight: 40,
  verticalAlign: 'top',
};

const skeleton: CSSObject = {
  width: 128,
  height: 16,
  borderRadius: 8,
  backgroundColor: colors.grayExLight,
  ':nth-of-type(2)': { marginBottom: 4 },
};

// #endregion

export default function LoanInformation() {
  return (
    <div css={outerCon}>
      <p css={header}>Error Information</p>
      <div css={column}>
        <div css={columnHeader}>Spreadsheet</div>
        <div css={skeleton} />
        <div css={skeleton} />
      </div>
      <div css={column}>
        <div css={columnHeader}>Loan number</div>
        <div css={skeleton} />
      </div>
    </div>
  );
}
