/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { apiPost } from '../../adalConfig';
import { Client } from '../../globalTypes/objects';
import { LenderAlias } from './LenderAliasObjects';
import ClientRow from './ClientRow';
import { useToaster } from '../../Hooks/toasters';

const BorderlessTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const SetWidthTableCell = withStyles({
  root: {
    width: '250px',
  },
})(TableCell);

export default function AliasRow(props: {
  alias: LenderAlias;
  rejectedAliases: number[];
  setRejectedAliases: Dispatch<SetStateAction<number[]>>;
  clients: Client[];
}) {
  const { alias, rejectedAliases, setRejectedAliases, clients } = props;

  const [loadingAliasRejection, setLoadingAliasRejection] = useState<boolean>(false);
  const [pendingClientAliases, setPendingClientAliases] = useState<number[]>(
    alias.clientAliases.filter(c => c.aliasType === null).map(c => c.clientId),
  );

  const { successToaster, errorToaster } = useToaster();

  const rejectAlias = async (aliasToReject: LenderAlias) => {
    try {
      setLoadingAliasRejection(true);
      await apiPost(`/api/LenderAlias/Reject-Lender-Alias`, {
        Id: aliasToReject.lenderNameId,
        Lender: aliasToReject.lenderName,
        Rejected: true,
      });
      setRejectedAliases([...rejectedAliases, alias.lenderNameId]);
      successToaster('Successfully rejected alias');
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setLoadingAliasRejection(false);
    }
  };

  return (
    <Fragment>
      <TableRow sx={{ paddingBottom: 0 }}>
        <BorderlessTableCell sx={{ fontWeight: 700, fontSize: 16 }}>
          {alias.lenderName}
        </BorderlessTableCell>
        <BorderlessTableCell align="right">
          {alias.clientAliases.every(c => pendingClientAliases.includes(c.clientId)) && (
            <Button
              variant="outlined"
              disabled={rejectedAliases.includes(alias.lenderNameId) || loadingAliasRejection}
              onClick={() => rejectAlias(alias)}
            >
              {loadingAliasRejection && <CircularProgress />}
              {!loadingAliasRejection && rejectedAliases.includes(alias.lenderNameId)
                ? 'Rejected: Not A Name'
                : 'Reject: Not A Name'}
            </Button>
          )}
        </BorderlessTableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingTop: 0 }} colSpan={3}>
          {!rejectedAliases.includes(alias.lenderNameId) && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <SetWidthTableCell>Client</SetWidthTableCell>
                  <SetWidthTableCell>Alias Type</SetWidthTableCell>
                  <SetWidthTableCell>Some Documents and Locations</SetWidthTableCell>
                  <SetWidthTableCell>Total Documents</SetWidthTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alias.clientAliases.map(clientAlias => (
                  <ClientRow
                    key={clientAlias.clientId}
                    clientAlias={clientAlias}
                    setPendingClientAliases={setPendingClientAliases}
                    alias={alias}
                    clients={clients}
                    rejected={rejectedAliases.includes(alias.lenderNameId)}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
