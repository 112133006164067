/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import SortDocumentPanel from '../components/Documents/Sort/SortDocumentPanel';
import MonthlyUnsortedCountPanel from '../components/Documents/Sort/MonthyUnsortedCountPanel';

const panelsWrap = {
  margin: '40px',
  marginTop: 40,
  maxWidth: 1280,
  '@media(min-width: 1024px)': {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default function SortDocuments() {
  const [docId, setDocId] = useState<number>();
  const location = useLocation();
  // eslint-disable-next-line prefer-destructuring
  const client = location.state?.client;
  return (
    <div css={panelsWrap}>
      {client && <MonthlyUnsortedCountPanel client={client} docId={docId} />}
      <SortDocumentPanel onChange={id => setDocId(id)} client={client} />
    </div>
  );
}
