/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import Panel, { PanelHeader, panelWrap } from '../ui/Panel';
import SearchSelect from '../ui/SearchSelect';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import TextInput from '../ui/Inputs/TextInput';
import Button from '../ui/Button';
import BackButton from './BackButton';
import { apiFetch, apiPost } from '../../adalConfig';
import { Client, DocumentType } from '../../globalTypes/objects';
import DeleteLoanCard from './DeleteLoanCard';
import { convertToFileDownload } from '../../Utils';

// #region css
const panelsWrap: CSSObject = {
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: 1280,
  margin: 40,
  marginTop: 24,
};
// #endregion

type DeleteLoanState = {
  loanNumbers?: string;
  clients: Client[];
  clientId?: number;
  results: LoanResultProps[];
  status: StatusTypes;
};

export default class DeleteLoan extends Component<any, DeleteLoanState> {
  state = {
    loanNumbers: '',
    clients: [] as Client[],
    clientId: (undefined as unknown) as number,
    results: [] as LoanResultProps[],
    status: StatusTypes.initial,
  };

  async componentDidMount() {
    const { data: clients } = await apiFetch<Client[]>('/api/clients/getAll');
    await this.setState({ clients });
  }

  deleteLoans = async () => {
    this.setState({ status: StatusTypes.loading });
    const { loanNumbers, clientId } = this.state;
    const array = loanNumbers!
      .replace(/,\s*$/, '')
      .replace(/[\n\r]/g, '')
      .replace(/\s/g, '')
      .split(',');
    try {
      const { data: results } = await apiPost('/api/loans/deleteLoan', {
        loanNumbers: array,
        clientId,
      });
      this.setState({ results: results.deletedLoans, status: StatusTypes.success });
      convertToFileDownload(results.downloadData, 'Deleted Loans.xlsx', 'xlsx');
    } catch (error) {
      console.log(error);
      this.setState({ status: StatusTypes.error });
    }
  };

  render() {
    const { loanNumbers, clients, clientId, results, status } = this.state;
    return (
      <Fragment>
        <BackButton to="/admin">Admin Page</BackButton>
        <div css={panelsWrap}>
          <div css={panelWrap}>
            <Panel>
              <PanelHeader text="Delete Loan" />
              <TextInput
                value={loanNumbers}
                onChange={e => this.setState({ loanNumbers: e.target.value })}
                placeholder="Enter loan numbers separated by ,"
                labelOverrides={{ margin: '24px 0' }}
                label="Loan Number"
              />
              <div>
                <p css={{ marginBottom: 8 }}>Client</p>
                <SearchSelect
                  options={clients.map(client => ({
                    label: client.company,
                    value: client.id,
                  }))}
                  placeholder="Search clients"
                  onChange={selection => {
                    this.setState({ clientId: selection ? selection.value : null });
                  }}
                />
              </div>
              <div css={{ marginTop: 32 }}>
                <StatusButton
                  status={status}
                  disabled={loanNumbers.length === 0 || !clientId || results.length !== 0}
                  text="Delete Loans"
                  onClick={this.deleteLoans}
                />
              </div>
            </Panel>
            {results.length !== 0 && (
              <Link to={this.props.location.pathname}>
                <Button styleOverrides={{ marginTop: 32 }}>Clear</Button>
              </Link>
            )}
          </div>
          {results.length !== 0 && (
            <Panel>
              <PanelHeader text="Results" />
              <p css={{ fontSize: 16, marginTop: 16 }}>
                Client: {clients.find(c => c.id === clientId)!.company}
              </p>
              {results.map(r => (
                <DeleteLoanCard key={r.loanNumber} {...r} />
              ))}
            </Panel>
          )}
        </div>
      </Fragment>
    );
  }
}

export enum ResultType {
  Error,
  Success,
  DoesNotExist,
}

export type LoanResultProps = {
  loanNumber: string;
  result: ResultType;
  amountCredited?: number;
  error?: string;
  documents?: DocumentResult[];
};

export type DocumentResult = {
  documentType: string;
  result: ResultType;
  error?: string;
  amountCredited?: number;
};
