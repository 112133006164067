/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';

import colors from '../../../styles/colors';
import VerificationPanel from '../VerificationPanel';

// #region css
const listItemsWrap = {
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  marginTop: 24,
  maxHeight: 510,
  overflowY: 'scroll',
  padding: 32,
};

const textAreaWrap = {
  backgroundColor: colors.grayExLight,
  borderRadius: 4,
  height: 160,
  marginTop: 32,
  resize: 'none',
  width: 328,
  '::placeholder': { color: colors.black, fontWeight: 700 },
};

// #endregion

export default function StepThreeFound({
  loan,
  docType,
  handleChange,
  verificationFailures: verifFails,
  note: failedVerifNote,
}) {
  const [note, setNote] = useState(failedVerifNote ?? '');
  const [verificationFailures, setVerificationFailures] = useState(verifFails ?? ([] as number[]));

  useEffect(() => {
    handleChange({ valid: true, data: { verificationFailures, note } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note, verificationFailures]);

  return (
    <Fragment>
      <VerificationPanel
        loan={loan}
        isMortgage={docType === 'Mortgage'}
        isPolicy={docType === 'Policy'}
        setVerificationFailures={setVerificationFailures}
        importingDoc
        alreadyFailed={verificationFailures}
        extractedDocumentData={undefined}
      />
      <textarea
        type="text"
        css={textAreaWrap}
        placeholder="Note"
        value={note}
        onChange={e => setNote(e.target.value)}
      />
    </Fragment>
  );
}
