import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { CheckIcon, ErrorsIcon } from '../ui/icons';

import colors from '../../styles/colors';

const useStyles = makeStyles(() => ({
  document: {
    alignItems: 'center',
    display: 'flex',
    height: '45px',
    padding: '16px 25px',
    borderRadius: '4px',
    margin: '4px',
    border: `1px solid ${colors.black}`,
  },
  documentVerified: {
    alignItems: 'center',
    backgroundColor: `${colors.grayLight}`,
    display: 'flex',
    height: '45px',
    padding: '16px 25px',
    borderRadius: '4px',
    margin: '4px',
  },
  padding: {
    padding: '5px',
  },
}));
export default function BatchDocument({ document, handleScan }) {
  const classes = useStyles({});

  let design = { icon: <span />, class: classes.document };

  if (document.isVerified) design = { icon: <CheckIcon />, class: classes.documentVerified };
  else if (document.isNotUploaded) design.icon = <ErrorsIcon />;

  return (
    <div className={design.class}>
      {design.icon}
      <p className={classes.padding}> docprobe-{document.documentId} </p>
      <button onClick={handleScan.bind(this, document.documentId)}> scan doc</button>
    </div>
  );
}
