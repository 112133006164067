/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useGetData } from '../../../Hooks';
import { Shipping as ShippingType } from '../../../components/ReportsDashboard/types';
import { ShippingDisplayCard } from '../../../components/ReportsDashboard/ShippingDisplayCard';

import { backgroundD, DeeForty, dpTitle, fullScreenStyles } from './DocumentIntake';
import { useToaster } from '../../../Hooks/toasters';

const pageWrap = {
  backgroundColor: '#f2f2f2',
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
  position: 'relative',
  padding: '20px 24px',
};

const dpoverrides = {
  position: 'relative',
  top: 0,
  left: 0,
  transform: 'rotate(0deg)',
  textAlign: 'center',
  marginBottom: 16,
};

const Shipping = () => {
  const { data, refetch, isSuccess } = useGetData<ShippingType>('/api/reports/shipping', {
    percentOfMonthlyGoal: 0,
    percentThatAreHardCopies: 0,
    readyToBeShipped: 0,
    digitalsNotBeingShipped: 0,
    readyToBeShippedAsOfToday: 0,
    digitalExclusionsAsOfToday: 0,
    topFiveInvestors: [],
    totalDocsShippedThisMonth: 0,
    totalDocsShippedToday: 0,
    totalYearlyToDate: 0,
  });
  const [showLoader, setShowLoader] = useState(true);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [showFSButton, setShowFSButton] = useState(true);

  const { successToaster } = useToaster();

  useEffect(() => {
    const TWO_MINUTES = 120000;
    const id = setInterval(refetch, TWO_MINUTES);
    setIntervalId(id);

    return clearInterval(intervalId as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (showLoader) {
      setShowLoader(false);
    } else {
      successToaster('The reports refreshed.');
    }
  }, [isSuccess]);

  const inputEl = useRef(null);

  const makeFullScreen = () => {
    setShowFSButton(false);
    inputEl.current.requestFullscreen();
  };
  return (
    <div css={pageWrap} ref={inputEl} onClick={() => inputEl.current.exitFullScreen()}>
      {showLoader ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress size="75" disableShrink />
        </div>
      ) : (
        <Fragment>
          <div
            css={[
              backgroundD,
              { overflow: 'visible', svg: { top: -8, marginLeft: 0, width: 1054, left: -202 } },
            ]}
          >
            <DeeForty />
          </div>
          <div css={[dpTitle, dpoverrides]}>Docprobe</div>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              position: 'relative',
              zIndex: 10,
              '> div': {
                backgroundColor: '#fff',
                borderRadius: '19px',
                boxShadow: '104px 103px 204px -60px rgba(0, 0, 0, 0.08)',
              },
            }}
          >
            <ShippingDisplayCard {...data} />
          </div>
          {showFSButton && (
            <div css={fullScreenStyles} onClick={makeFullScreen}>
              Full Screen
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default Shipping;
