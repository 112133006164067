/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo, useState } from 'react';
import produce, { enableMapSet } from 'immer';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddNoteInput from '../Notes/AddNoteInput';
import { apiFetch, apiPost } from '../../adalConfig';
import BarcodeTextArea from '../Utilities/BarcodeTextArea';
import { useToaster } from '../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3),
      border: '1px solid #EEEEEE',
    },
  }),
);

const BulkLoansNote = () => {
  const [documentIds, setDocumentIds] = useState<Set<number>>(new Set());
  const [isInternal, setIsInternal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { successToaster, errorToaster } = useToaster();

  const documentIdsArray = useMemo(() => Array.from(documentIds), [documentIds.size]);

  const classes = useStyles();

  enableMapSet();

  const wasDocumentUploaded = async (documentId: number): Promise<boolean> => {
    const { data: isUploaded } = await apiFetch<boolean>(
      '/api/documents/check-document-uploaded-status',
      {
        params: { documentId },
      },
    );

    return isUploaded;
  };

  const addDocumentId = async (barcode: string) => {
    if (!barcode) {
      return;
    }

    const documentId = +barcode.toLowerCase().replace('docprobe-', '');
    const includingAddedDocumentId = produce(documentIds, (draft: Set<number>) => {
      draft.add(documentId);
    });
    try {
      if (await wasDocumentUploaded(documentId)) {
        setDocumentIds(includingAddedDocumentId);
        setErrorMessage('');
      } else {
        setErrorMessage("This document wasn't uploaded into our system");
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const addNote = async (note: string, isInternal: boolean) => {
    if (documentIds.size === 0) {
      errorToaster('Please scan a barcode before adding the note');
      return;
    }

    try {
      const { data: response } = await apiPost(
        '/api/notes/insert-note-for-multiple-loans-by-document',
        {
          noteText: note,
          isInternal: isInternal,
          documentIds: documentIdsArray,
        },
      );
      if (response) {
        successToaster('Successfully added the note');
        setDocumentIds(new Set());
        setIsInternal(false);
      } else {
        errorToaster('Failed to add the note');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return (
    <div style={{ margin: '15px' }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <BarcodeTextArea
            addBarcode={addDocumentId}
            barcodes={documentIdsArray}
            errorMessage={errorMessage}
          />
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <FormControlLabel
              control={
                <Checkbox
                  color="error"
                  checked={isInternal}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setIsInternal(event.target.checked)
                  }
                />
              }
              label="Mark as internal"
            />

            <AddNoteInput addNote={addNote} isInternal={isInternal} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkLoansNote;
