/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { produce } from 'immer';
import { isEmpty } from 'lodash';
import DataTable from '../ui/DataTable/DataTable';
import icons from '../ui/DataTable/Icons';
import { FailAfterShipIcon } from '../ui/icons';
import { apiFetch, apiPost } from '../../adalConfig';
import DocTypeSelect from '../Utilities/DocTypeSelect';
import { convertToBase64, isAccountRep, isAdmin, openOrDownloadFile } from '../../Utils';
import Button from '../ui/Button';
import ConditionalComponent from '../ConditionalComponent';
import {
  Failure,
  isFailure,
  isLoading,
  isSuccess,
  Loading,
  NotAsked,
  RemoteData,
  Success,
} from '../../globalTypes/RemoteData';
import { DocumentAudit, DocumentType, TitleCompany } from '../../globalTypes/objects';
import InvestorFail from './InvestorFail';
import { Event, EventDisplay, eventDisplayName } from '../Documents/EventDisplay';
import { LoanInformation } from './types';
import { VerificationErrorCoversheetProps } from '../Documents/Imports/VerificationErrorCoversheet';
import { AuthContext } from '../AuthContext';
import { useClients } from '../../Hooks/useClients';
import { useToaster } from '../../Hooks/toasters';
import useFileExport from '../ui/DataTable/FileExport';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import LoanDocumentChangeLogDialog from './LoanDocumentChangeLogDialog';

const fasIcon = {
  '& svg': {
    opacity: 0.35,
  },
};

type ExportData = {
  'Document ID': number;
  'Document Type': string;
  'Barcode Generated': string;
  'Hard Copy': string;
  Shipped: string;
  'Tracking #': string;
  'Search & Retrieval': string;
  User: string;
  Events: string;
};

type RowData = {
  id: number;
  documentType: string;
  docTypeId: number;
  dateUploaded?: Date;
  hardCopy: boolean;
  isInvestorFailure: boolean;
  searcherCost: number;
  documentCreatedBy: string;
  events: Event[];
  audit: DocumentAudit;
  verificationFailures: string[];
  verificationNote: string;
  shippedDates: string[];
  trackingNumbers: string[];
};

type Props = {
  loan: LoanInformation;
  setCoversheetData: Dispatch<SetStateAction<VerificationErrorCoversheetProps>>;
  coversheetData: VerificationErrorCoversheetProps;
};

const LoanDocuments = ({ loan, setCoversheetData, coversheetData }: Props) => {
  const { id: loanId } = loan;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [documentRowData, setDocumentRowData] = useState({ documentId: 0, documentType: 0 });
  const [addingDocument, setAddingDocument] = useState(false);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});
  const [isChangeLogDialogOpen, setIsChangeLogDialogOpen] = useState(false);

  const { roles } = useContext(AuthContext);
  const { errorToaster } = useToaster();
  const clients = useClients();
  const fileExport = useFileExport<ExportData>();

  const columns = [
    {
      field: 'id',
      title: 'ID',
    },
    {
      field: 'documentType',
      title: 'Doc Type',
    },
    {
      field: 'dateUploaded',
      title: 'Barcode Generated',
      render: (rowData: RowData) =>
        rowData.dateUploaded && new Date(rowData.dateUploaded).toLocaleDateString(),
    },
    {
      field: 'hardCopy',
      title: 'Hard Copy',
      render: rowData =>
        rowData.hardCopy ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />,
    },
    {
      field: 'shipped',
      title: 'Shipped',
      render: (rowData: RowData) =>
        !!rowData.shippedDates.length && (
          <Fragment>
            {rowData.shippedDates.map((shippedDate, i) => (
              <p key={i}>{new Date(shippedDate).toLocaleDateString()}</p>
            ))}
          </Fragment>
        ),
    },
    {
      field: 'trackingNumber',
      title: 'Tracking #',
      render: (rowData: RowData) =>
        !!rowData.trackingNumbers.length && (
          <Fragment>
            {rowData.trackingNumbers.map((trackingNumber, i) => (
              <p key={i}>{trackingNumber}</p>
            ))}
          </Fragment>
        ),
    },
    {
      field: 'searcherCost',
      title: 'Search & Ret',
      render: (rowData: RowData) =>
        `$${rowData.searcherCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
    },
    {
      field: 'documentCreatedBy',
      title: 'User',
      sorting: false,
      render: (rowData: RowData) =>
        rowData.documentCreatedBy?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ''),
    },
    {
      field: 'events',
      title: 'Events',
      sorting: false,
      render: (rowData: RowData) => {
        if (!rowData?.events?.length) {
          return <Fragment />;
        }

        const mostRecentDocEvent = rowData.events[rowData.events.length - 1];
        return (
          <p>
            <b>{eventDisplayName.get(mostRecentDocEvent.event)}</b>:{' '}
            {new Date(mostRecentDocEvent.timestamp).toLocaleDateString()}
          </p>
        );
      },
    },
  ];

  const failDocument = (rowData: RowData) => {
    setDocumentRowData({ documentId: rowData.id, documentType: rowData.docTypeId });
    setIsModalOpen(true);
  };

  const downloadDocument = async (rowData: RowData) => {
    try {
      const { data } = await apiFetch('/api/documents/getDocumentPdf', {
        responseType: 'blob',
        params: { documentId: rowData.id },
      });

      const url = window.URL.createObjectURL(data);
      openOrDownloadFile(url, `Document - ${rowData.id}.pdf`);
    } catch (e) {
      if (e.response) {
        let errorMessage = await new Response(e.response.data).text();
        errorMessage = errorMessage.split('\n')[0];

        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const printCoversheet = async (rowData: RowData) => {
    setCoversheetData({
      documentId: rowData.id,
      borrowerName: loan.borrower,
      clientName: loan.client,
      documentTypeDescription: rowData.documentType,
      dateDocumentDrawn: loan.dateFunded,
      investorName: loan.investor,
      notFound: false,
      audit: rowData.audit,
      accountRep: loan.clientAccountRep,
      verificationFailures: rowData.verificationFailures ?? [],
      note: rowData.verificationNote,
      titleCompany: { name: loan.titleCompany } as TitleCompany,
      loanNumber: loan.appNumber,
    });
    const docData = rowData;
    docData.documentType = rowData.documentType.split(' ').join('');
    apiPost(`/Api/Documents/LogReprintCoversheet/${docData.id}`, {});
  };

  const exportDatatable = (dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map<ExportData>(row => ({
      'Document ID': row.id,
      'Document Type': row.documentType,
      'Barcode Generated': row.dateUploaded ? new Date(row.dateUploaded).toLocaleDateString() : '',
      'Hard Copy': row.hardCopy ? 'Yes' : 'No',
      Shipped: row.shippedDates.length
        ? row.shippedDates
            .map((shippedDate, i) => new Date(shippedDate).toLocaleDateString())
            .join('\n')
        : '',
      'Tracking #': row.trackingNumbers.length ? row.trackingNumbers.join('\n') : '',
      'Search & Retrieval': `$${row.searcherCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
      User: row.documentCreatedBy?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ''),
      Events: row.events
        ?.map(
          docEvent =>
            `${eventDisplayName.get(docEvent.event)} - ${new Date(
              docEvent.timestamp,
            ).toLocaleDateString()}`,
        )
        .join('\n'),
    }));

    fileExport(exportData, `Loan Documents - ${loan.appNumber}`);
  };

  return (
    <div>
      <div className="df jcsb aic px4">
        <div>
          {!addingDocument && <Button onClick={() => setAddingDocument(true)}>Add Document</Button>}
          {addingDocument && (
            <div css={{ width: '20rem' }}>
              <AddDocument
                loan={loan}
                onSuccess={() => {
                  setAddingDocument(false);
                  refreshDatatable();
                }}
              />
            </div>
          )}
        </div>
        <div>
          {isAdmin(roles) && (
            <Tooltip title="View change log">
              <IconButton color="primary" onClick={() => setIsChangeLogDialogOpen(x => !x)}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
          )}
          <span className="bold">Required Documents: </span>
          <span style={{ textTransform: 'capitalize' }}>{loan.requiredDocuments.join(', ')}</span>
        </div>
      </div>

      <DataTable<RowData>
        title="Loan Documents"
        columns={columns}
        url={`/api/loans/${loanId}/documents`}
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        customExport={exportDatatable}
        detailPanel={(rowData: RowData) => (
          <ConditionalComponent display={rowData.events?.length}>
            <div
              style={{
                padding: '16px 60px',
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridRowGap: 16,
              }}
            >
              {rowData.events?.map(docEvent => EventDisplay({ docEvent, clients }))}
            </div>
          </ConditionalComponent>
        )}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        actions={tableRef => [
          (rowData: RowData) => ({
            icon: () => (
              <span css={rowData.isInvestorFailure && fasIcon}>
                <FailAfterShipIcon />
              </span>
            ),
            disabled: rowData.isInvestorFailure,
            tooltip: rowData.isInvestorFailure
              ? 'Investor Failure Already Reported'
              : 'Report Investor Failure',
            onClick: (event, rowData) => failDocument(rowData),
            hidden: !rowData.shippedDates.length || !isAccountRep(roles),
          }),
          {
            icon: icons.ArrowDownwardIcon,
            tooltip: 'Download Document',
            onClick: (event, rowData) => downloadDocument(rowData),
          },
          {
            icon: icons.Barcode,
            tooltip: isEmpty(coversheetData) ? 'Print Coversheet' : '', // tooltip text shows on print dialogue
            onClick: (event, rowData) => printCoversheet(rowData),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          search: false,
        }}
      />
      {isModalOpen && (
        <InvestorFail
          documentId={documentRowData.documentId}
          documentType={documentRowData.documentType}
          refreshDatatable={refreshDatatable}
          setIsModalOpen={setIsModalOpen}
        />
      )}

      <LoanDocumentChangeLogDialog
        loanId={loanId}
        open={isChangeLogDialogOpen}
        handleCloseDialog={() => setIsChangeLogDialogOpen(false)}
      />
    </div>
  );
};

interface DocumentForm {
  documentType: DocumentType | undefined;
  loanId: number;
  clientId: number;
  fileContents: string;
  filename: string | undefined;
  uploadedToLoan: boolean;
}

function documentFormIsValid(documentForm: DocumentForm): true | string {
  if (!documentForm.documentType || documentForm.documentType === DocumentType.Unknown) {
    return 'Please select a document type.';
  }
  if (!documentForm.fileContents) {
    return 'Please choose a document to upload.';
  }

  return true;
}

const initForm = loan => ({
  documentType: undefined,
  clientId: loan.clientId,
  fileContents: '',
  loanId: loan.id,
  uploadedToLoan: true,
  filename: undefined,
  file: undefined,
});

function AddDocument({ loan, onSuccess }) {
  const { id: loanId } = loan;
  const [documentForm, setDocumentForm] = useState<DocumentForm>(() => initForm(loan));

  const [submitDocumentReq, setSubmitDocumentReq] = useState<RemoteData<unknown, Error>>(NotAsked);
  const [formValidationError, setFormValidationError] = useState<string>();

  function submitDocument(form: DocumentForm) {
    setSubmitDocumentReq(NotAsked);
    setFormValidationError(undefined);
    const formValidation = documentFormIsValid(form);
    if (formValidation === true) {
      setSubmitDocumentReq(Loading);
      apiPost(`/Api/Documents/CreateSoftCopy`, documentForm)
        .then(res => {
          setSubmitDocumentReq(Success(res.data));
          setDocumentForm(initForm(loan));
        })
        .catch(e => {
          setSubmitDocumentReq(Failure(e));
          setDocumentForm(initForm(loan));
        });
    } else {
      setFormValidationError(formValidation);
    }
  }

  useEffect(() => {
    setFormValidationError(undefined);
  }, [documentForm]);

  return (
    <Fragment>
      <DocTypeSelect
        docType={documentForm.documentType}
        onSelect={docType =>
          setDocumentForm(
            produce(documentForm, formDraft => {
              formDraft.documentType = docType;
            }),
          )
        }
      />
      <input
        type="file"
        accept=".pdf"
        onInput={async e => {
          // @ts-ignore
          const file = e.target.files[0];
          const fileContents = await convertToBase64(file);
          if (!fileContents) return;
          setDocumentForm(
            produce(documentForm, documentFormDraft => {
              documentFormDraft.fileContents = fileContents;
              documentFormDraft.filename = file?.name;
            }),
          );
        }}
      />
      <Button
        type="submit"
        onClick={() => {
          submitDocument(documentForm);
        }}
      >
        Submit
      </Button>
      <div>
        <h3 css={{ color: 'red' }}>{formValidationError}</h3>
        {isLoading(submitDocumentReq) && <div>Uploading...</div>}
        {isFailure(submitDocumentReq) && <div>There was a problem uploading the document.</div>}
        {isSuccess(submitDocumentReq) && <div>Successfully uploaded!</div>}
      </div>
    </Fragment>
  );
}

export default LoanDocuments;
