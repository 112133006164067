/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import { XLargeIcon } from './icons';
import colors from '../../styles/colors';

const messagaeWrapS: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.blackText,
  borderRadius: 4,
  bottom: 32,
  color: colors.gray,
  display: 'flex',
  height: 56,
  justifyContent: 'space-between',
  left: 400,
  padding: '0 24px',
  position: 'fixed',
  width: 299,
  ' span': { cursor: 'pointer' },
};

const undo: CSSObject = {
  alignItems: 'center',
  borderRadius: 3,
  color: colors.white,
  display: 'flex',
  height: 32,
  justifyContent: 'space-around',
  transition: 'border .2s, background-color .2s',
  width: 56,
  ':hover': {
    backgroundColor: colors.grayDark,
  },
};

type MessageTabProps = {
  handleUndo: () => void;
  handleClose: () => void;
  messageText: string;
  buttonText?: string;
};

export default function MessageTab({
  handleUndo,
  handleClose,
  messageText,
  buttonText,
}: MessageTabProps) {
  return (
    <div css={messagaeWrapS}>
      {messageText}
      <span css={undo} onClick={handleUndo}>
        {buttonText || 'Undo'}
      </span>
      <span onClick={handleClose}>
        <XLargeIcon />
      </span>
    </div>
  );
}
