/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useRef } from 'react';
import BarcodeInput from './BarcodeInput';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { roleTypes } from '../../constants';
import { AuthContext } from '../AuthContext';
import Alert from '@mui/material/Alert';
import ConditionalComponent from '../ConditionalComponent';

type Props = {
  addBarcode: (value: string) => void;
  barcodes: Array<string | number>;
  errorMessage?: string;
};

const BarcodeTextArea = ({ addBarcode, barcodes, errorMessage }: Props) => {
  const { roles } = useContext(AuthContext);

  const barcodeRef = useRef<HTMLInputElement>();

  return (
    <Fragment>
      <BarcodeInput
        inputRef={barcodeRef}
        onChange={addBarcode}
        hidden={!roles.includes(roleTypes.Dev)}
      />
      <TextareaAutosize
        minRows={12}
        aria-label="maximum height"
        placeholder="Scanned barcodes will be displayed here"
        value={barcodes.join('\n')}
        style={{ width: '100%', resize: 'none' }}
        onClick={() => barcodeRef.current!.focus({ preventScroll: true })}
      />
      <ConditionalComponent display={errorMessage}>
        <Alert severity="error">{errorMessage}</Alert>
      </ConditionalComponent>
    </Fragment>
  );
};

export default BarcodeTextArea;
