import React from 'react';
import { parse } from 'json2csv';
import { useToaster } from '../../../Hooks/toasters';

const useFileExport = <T extends object>() => {
  const { errorToaster } = useToaster();

  return (dataTableRows: T[], fileName: string) => {
    try {
      const csv = parse(dataTableRows);

      const blob = new Blob([csv]);
      const a = window.document.createElement('a');
      const downloadUrl = window.URL.createObjectURL(blob);
      a.href = downloadUrl;
      a.download = `${fileName}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      errorToaster('An error occured during export');
    }
  };
};

export default useFileExport;
