/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import produce from 'immer';
import FileDropper from '../../components/ui/FileDropper';
import { ClientData, ClientFiles as Files } from './types';
import { apiFetch } from '../../adalConfig';
import { EditTwoIcon } from '../../components/ui/icons';
import { ActionButton, linkS } from './cardStyles';
import ClientFilesDisplay from './ClientFilesDisplay';
import { useToaster } from '../../Hooks/toasters';

type Props = {
  clientFiles: Files;
  setClientFiles: (files: Files) => void;
  clientData: ClientData;
  clientId: number;
};

const ClientFiles = ({ clientFiles, setClientFiles, clientData, clientId }: Props) => {
  const [editMode, setEditMode] = useState(false);

  const { errorToaster } = useToaster();

  const isNewClient = !clientId;

  const updateFile = <K extends keyof Files>(updatedField: K, newValue: Files[K]) => {
    const newClientData = produce(clientFiles, (draft: Files) => {
      draft[updatedField] = newValue;
    });

    setClientFiles(newClientData);
  };

  const downloadFile = async fileType => {
    try {
      const { data } = await apiFetch(`/api/clients/${clientId}/Download/${fileType}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(data);
      open(url);
      window.URL.revokeObjectURL(url);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return (
    <Fragment>
      {editMode || isNewClient ? (
        <Fragment>
          <div className="df aic jcfe mb3">
            <ActionButton onClick={() => setEditMode(!editMode)}>
              <EditTwoIcon />
            </ActionButton>
          </div>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridColumnGap: 24,
              gridRowGap: 24,
              'label span': { fontWeight: 'bold' },
            }}
          >
            <div>
              <div className="df jcsb mt2">
                <span className="bold">Closing Instructions</span>
                {!isNewClient && clientData.closingInstructions && (
                  <button css={linkS} onClick={() => downloadFile('ClosingInstructions')}>
                    View
                  </button>
                )}
              </div>
              <FileDropper
                onChange={(file: File) => updateFile('closingInstructions', file || undefined)}
                file={clientFiles.closingInstructions}
                acceptableFileTypes=""
                noIcon
                styles={{ marginTop: 16 }}
              />
            </div>
            <div>
              <div className="df jcsb mt2">
                <span className="bold">Signed Agreement</span>
                {!isNewClient && clientData.signedAgreement && (
                  <button css={linkS} onClick={() => downloadFile('SignedAgreement')}>
                    View
                  </button>
                )}
              </div>
              <FileDropper
                onChange={(file: File) => updateFile('signedAgreement', file || undefined)}
                file={clientFiles.signedAgreement}
                acceptableFileTypes=""
                noIcon
                styles={{ marginTop: 16 }}
              />
            </div>
            <div>
              <div className="df jcsb mt2">
                <span className="bold">Authorization Letter</span>
                {!isNewClient && clientData.authorizationLetter && (
                  <button css={linkS} onClick={() => downloadFile('AuthorizationLetter')}>
                    View
                  </button>
                )}
              </div>
              <FileDropper
                onChange={(file: File) => updateFile('authorizationLetter', file || undefined)}
                file={clientFiles.authorizationLetter}
                acceptableFileTypes=""
                noIcon
                styles={{ marginTop: 16 }}
              />
            </div>
          </div>
        </Fragment>
      ) : (
        <ClientFilesDisplay
          isNewClient={isNewClient}
          clientData={clientData}
          downloadFile={downloadFile}
          setEditMode={() => setEditMode(!editMode)}
        />
      )}
    </Fragment>
  );
};

export default ClientFiles;
