/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { AppBar, Tab, Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonIcon from '@mui/icons-material/Person';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TollIcon from '@mui/icons-material/Toll';
import { QrCodeIcon } from '../components/ui/icons';
import FileSamplesPage from '../components/kb/FileSamplesPage';
import { TabName } from '../components/kb/types';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function KnowledgeBase() {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(TabName.General);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab icon={<InfoOutlinedIcon />} label="General Info" />
          <Tab icon={<PhoneIcon />} label="Contact Center" />
          <Tab icon={<PersonalVideoIcon />} label="Digitals/Matching" />
          <Tab icon={<QrCodeIcon />} label="Physical Processing" />
          <Tab icon={<PersonIcon />} label="Account Managers" />
          <Tab icon={<TollIcon />} label="Pods" />
          <Tab icon={<InsertDriveFileOutlinedIcon />} label="Authorization Letters" />
          <Tab
            icon={<OpenInNewIcon />}
            label="Training Site"
            onClick={() => open('https://docprobe3-testing.azurewebsites.net/outreach')}
          />
        </Tabs>
      </AppBar>

      <div css={{ padding: 24 }}>
        <FileSamplesPage tab={currentTab} />
      </div>
    </div>
  );
}
