export enum FilterType {
  Radio,
  Checkbox,
  Select,
  Custom,
}

export type FilterTypes = FiltersForFilterType[];

export type FiltersForFilterType = {
  filterType: FilterType;
  filters: Filter[];
};

export type Filter = {
  label: string;
  filterOptions: FilterOption[];
};

export type FilterOption = {
  id: number | string; // for custom date inputs we'll need to send the date in the query string
  label: string;
  active: boolean;
};

export type ActiveFilterValue = number | number[];

export type ActiveFiltersForApiRequest = {
  [key: string]: ActiveFilterValue;
};
