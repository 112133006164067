/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { Client } from '../../globalTypes/objects';

type Props = {
  completedLoans: LoanInfo[];
  client: Client;
};

export type LoanInfo = {
  loanId: number;
  appNumber: string;
  borrower: string;
  dateFunded: Date;
  titleCompany: string;
  mortgageDate: Date;
  policyDate: Date;
};

const CompletedLoans = ({ completedLoans, client }: Props) => {
  const columns = [
    {
      field: 'loanId',
      title: 'LoanId',
    },
    {
      field: 'appNumber',
      title: 'Loan Number',
    },
    {
      field: 'borrower',
      title: `Borrower`,
    },
    {
      field: 'dateFunded',
      title: `Date Funded`,
      render: (rowData: LoanInfo) => new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'titleCompany',
      title: 'Title Company',
    },
    {
      field: 'mortgageDate',
      title: `mortgage Date`,
      render: (rowData: LoanInfo) => new Date(rowData.mortgageDate).toLocaleDateString(),
    },
    {
      field: 'policyDate',
      title: `Policy Date`,
      render: (rowData: LoanInfo) => new Date(rowData.policyDate).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      {completedLoans && (
        <DataTable<LoanInfo>
          title={`Completed Loans For - ${client.company}`}
          columns={columns}
          data={completedLoans}
          options={{
            selection: false,
            search: false,
            exportFileName: `Completed Loans for -  ${client.company}`,
          }}
        />
      )}
    </Fragment>
  );
};

export default CompletedLoans;
