/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, TableCell, TableFooter, TablePagination, TableRow } from '@mui/material';
import React from 'react';
import colors from '../../styles/colors';
import { ConsolidationMode, useTitleCompanies } from './useTitleCompanies';

export default function TableBottom() {
  const [
    {
      selectionMode,
      selectedIds,
      paginationOptions: { page, rowsPerPage, totalCount },
    },
    setTitleCompanies,
  ] = useTitleCompanies();
  return (
    <TableFooter css={{ position: 'sticky', bottom: 0, width: '100%', backgroundColor: '#fafafa' }}>
      <TableRow>
        {selectionMode && (
          <TableCell colSpan={3}>
            <span css={{ fontSize: 14, paddingRight: 16 }}>
              <span css={{ color: colors.blue }}>{selectedIds.size} selected </span>
              for Consolidation
            </span>
            <Button
              disabled={selectedIds.size <= 1}
              variant="contained"
              color="primary"
              onClick={() => setTitleCompanies({ consolidationMode: ConsolidationMode.True })}
            >
              Consolidate
            </Button>
          </TableCell>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          rowsPerPage={rowsPerPage}
          page={page}
          count={totalCount}
          onPageChange={(_, pageNumber) =>
            setTitleCompanies({ paginationOptions: { page: pageNumber, rowsPerPage, totalCount } })
          }
          onRowsPerPageChange={event =>
            setTitleCompanies({
              paginationOptions: { page, rowsPerPage: +event.target.value, totalCount },
            })
          }
        />
      </TableRow>
    </TableFooter>
  );
}
