import { apiFetch } from '../../../adalConfig';
import { FilterOption, FilterType, FilterTypes } from '../../../components/ui/DataTable/types';
import { useEffect, useState } from 'react';
import { useClients } from '../../../Hooks/useClients';
import { useInvestors } from '../../../Hooks/useInvestors';
import { useGetData } from '../../../Hooks';
import { TitleCompany } from './GenerateReport';

const getFilter = async () => {
  const { data: correctionPageFilters } = await apiFetch<{ filterTypes: FilterTypes }>(
    '/api/accountReps/filters',
  );
  return correctionPageFilters.filterTypes.reduce((all, curr) => {
    if (![FilterType.Radio, FilterType.Checkbox].includes(curr.filterType)) {
      return all;
    }

    return [...all, ...curr.filters];
  }, []);
};

export const useFilters = () => {
  const [clientsFilter, setClientsFilter] = useState<FilterOption[]>([]);
  const [outstandingDocumentsFilter, setOutstandingDocumentsFilter] = useState<FilterOption[]>([]);
  const [mayHaveCureFilter, setMayHaveCureFilter] = useState<FilterOption[]>([]);
  const [documentStatusFilter, setDocumentStatusFilter] = useState<FilterOption[]>([]);
  const [failureSensitivityFilter, setFailureSensitivityFilter] = useState<FilterOption[]>([]);

  const clients = useClients();
  const investors = useInvestors();
  const { data: titleCompanies } = useGetData<TitleCompany[]>(
    '/api/titleCompanies/dropdown-data',
    [],
  );

  useEffect(() => {
    getFilter().then(filters => {
      for (const filter of filters) {
        switch (filter.label) {
          case 'Clients':
            setClientsFilter(filter.filterOptions);
            break;
          case 'OutstandingDocuments':
            setOutstandingDocumentsFilter(filter.filterOptions);
            break;
          case 'MayHaveCure':
            setMayHaveCureFilter(filter.filterOptions);
            break;
          case 'DocumentStatus':
            setDocumentStatusFilter(filter.filterOptions);
            break;
          case 'FailureSensitivity':
             setFailureSensitivityFilter(filter.filterOptions);
             break;
        }
      }
    });
  }, []);

  return {
    clientsFilter,
    outstandingDocumentsFilter,
    mayHaveCureFilter,
    documentStatusFilter,
    failureSensitivityFilter,
    clients,
    investors,
    titleCompanies,
  };
};
