/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useGetData } from '../../../Hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { Sorting as SortingType } from '../../../components/ReportsDashboard/types';
import {
  SortingDisplayCard,
  TopFiveClients,
} from '../../../components/ReportsDashboard/SortingDisplayCard';
import { useToaster } from '../../../Hooks/toasters';

const Sorting = () => {
  const { data, refetch, isSuccess } = useGetData<SortingType>('/api/reports/sorting', {
    percentOfMonthlyGoal: 0,
    readyToBeSorted: 0,
    sortedToday: 0,
    topFiveClients: [],
    totalDocsSortedThisMonth: 0,
    totalYearly: 0,
    readyToBeSortedAsOfToday: 0,
  });
  const [showLoader, setShowLoader] = useState(true);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();

  const { successToaster } = useToaster();

  useEffect(() => {
    const TWO_MINUTES = 120000;
    const id = setInterval(refetch, TWO_MINUTES);
    setIntervalId(id);

    return clearInterval(intervalId as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (showLoader) {
      setShowLoader(false);
    } else {
      successToaster('The reports refreshed.');
    }
  }, [isSuccess]);

  return (
    <Fragment>
      {showLoader ? (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <CircularProgress size="75" disableShrink />
        </div>
      ) : (
        <Fragment>
          <SortingDisplayCard {...data} />
          <TopFiveClients data={data.topFiveClients} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Sorting;
