/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import Refresh from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { apiFetch } from '../../adalConfig';
import Tab, { TabGroup } from '../ui/Tabs/Tab';
import EmbedReport from './EmbedReport';
import Autocomplete from '@mui/material/Autocomplete';
import { Client } from '../../globalTypes/objects';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useClients } from '../../Hooks/useClients';
import Chip from '@mui/material/Chip';

export type EmbedReportInfo = {
  embedToken: string;
  id: string;
  name: string;
  embedUrl: string;
  requiresFilter: boolean;
};

const EmbedContainer = () => {
  const [pbiEmbedInfo, setPbiEmbedInfo] = useState<EmbedReportInfo[]>();
  const [selectedReportId, setSelectedReportId] = useState(0);
  const [tempSelectedClient, setTempSelectedClient] = useState<Client | null>(null);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const clients = useClients();

  function getEmbedInfo() {
    apiFetch<EmbedReportInfo[]>('/api/PowerBI/GetEmbedInfo').then(({ data }) => {
      setPbiEmbedInfo(data);
    });
  }

  useEffect(() => {
    getEmbedInfo();
  }, []);

  const resetClient = () => {
    setTempSelectedClient(null);
    setSelectedClient(null);
  };

  const onReportSelected = (reportIndex: number) => {
    resetClient();
    setSelectedReportId(reportIndex);
  };

  if (!pbiEmbedInfo?.length) {
    return <Fragment />;
  }

  return (
    <div style={{ margin: 32, marginTop: 16 }}>
      <div style={{ paddingBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
        <TabGroup selectionDefaultIdx={0}>
          {pbiEmbedInfo.map((embedData, i) => (
            <Tab key={embedData.id} onClick={() => onReportSelected(i)}>
              {embedData.name}
            </Tab>
          ))}
        </TabGroup>
        <div>
          {pbiEmbedInfo[selectedReportId]?.requiresFilter && selectedClient !== null && (
            <Chip icon={<KeyboardBackspaceIcon />} label="Select Client" onClick={resetClient} />
          )}
          <IconButton onClick={getEmbedInfo} size="large">
            <Refresh />
          </IconButton>
        </div>
      </div>

      {pbiEmbedInfo[selectedReportId].requiresFilter && !selectedClient ? (
        <Fragment>
          <Fragment>
            <div className="my2">
              <Autocomplete
                options={clients}
                getOptionLabel={(option: Client) => option?.company || ''}
                onChange={(event: any, newValue: Client | null) => setTempSelectedClient(newValue)}
                value={selectedClient}
                style={{ width: 300 }}
                autoHighlight
                renderInput={params => (
                  <TextField {...params} label="Choose a client" variant="outlined" />
                )}
              />
            </div>

            <Button
              variant="contained"
              color="primary"
              disabled={!tempSelectedClient}
              onClick={() => setSelectedClient(tempSelectedClient)}
            >
              Submit
            </Button>
          </Fragment>
        </Fragment>
      ) : (
        <EmbedReport report={pbiEmbedInfo[selectedReportId]} selectedClient={selectedClient} />
      )}
    </div>
  );
};

export default EmbedContainer;
