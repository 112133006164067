/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import { FunctionComponent } from 'react';
import colors from '../../styles/colors';

const tooTipWrap: CSSObject = {
  display: 'inline-flex',
  position: 'relative',
  justifyContent: 'center',
  '&:hover': {
    ' > span': {
      opacity: 1,
      transition: 'all 0s ease 1s',
    },
  },
};

const toolTip: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.black,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  position: 'absolute',
  top: -56,
  opacity: 0,
  width: 'auto',
  whiteSpace: 'nowrap',
  padding: '0 16px',
  ' span': {
    borderColor: '#333 transparent transparent transparent',
    borderStyle: 'solid',
    borderWidth: '6px 6px 0 6px',
    height: 0,
    position: 'absolute',
    bottom: -6,
    width: 0,
  },
};

type ToolTipProps = {
  toolTipText: string;
  wrapOverrides?: CSSObject;
  toolTipOverrides?: CSSObject;
};
const ToolTip: FunctionComponent<ToolTipProps> = ({
  children,
  toolTipText,
  wrapOverrides,
  toolTipOverrides,
}) => {
  return (
    <div css={[tooTipWrap, wrapOverrides]}>
      {children}
      <span css={[toolTip, toolTipOverrides]}>
        {toolTipText}
        <span />
      </span>
    </div>
  );
};

export default ToolTip;
