import colors from '../../../styles/colors';
import { gridDimensions } from './documentCardStyles';

export const clientNameWrap = {
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: 20,
  position: 'absolute',
  top: 17,
  left: 227,
  '&:before': {
    content: '""',
    borderLeft: `1px solid ${colors.gray}`,
    position: 'absolute',
    left: -32,
    height: 32,
    top: -1,
  },
  div: { marginLeft: 8 },
};

export const contentWrap = {
  alignItems: 'flex-start',
  display: 'grid',
  gridColumnGap: '24px',
  gridTemplateColumns: 'auto 220px',
  margin: 32,
};

export const tabsWrap = {
  borderBottom: `1px solid ${colors.grayLight}`,
  display: 'inline-flex',
  flexDirection: 'column',
  marginBottom: 14,
  padding: '0 24px 24px',
  width: '100%',
  ':last-child': {
    borderBottom: 'none',
    paddingBottom: 0,
    marginBottom: 24,
  },
};

export const listHeader = {
  ...gridDimensions,
  alignItems: 'center',
  display: 'grid',
  fontWeight: 700,
  padding: 16,
  paddingLeft: 24,
  zIndex: 100,
};

export const boxWrap = {
  borderRadius: 5,
  maxHeight: 'calc(100vh - 160px)',
  overflow: 'auto',
};

export const sidebarWrap = {
  alignItems: 'center',
  backgroundColor: '#fff',
  border: `1px solid ${colors.gray}`,
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'relative',
  width: 220,
  marginTop: 52,
};
