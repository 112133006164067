/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import colors from '../../styles/colors';

// #region css
const toggleWrap: CSSObject = {
  backgroundColor: colors.grayExLight,
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 16,
  cursor: 'pointer',
  height: 33,
  width: 58,
};

const toggleCircle: CSSObject = {
  backgroundColor: colors.gray,
  borderRadius: '50%',
  height: 24,
  left: 0,
  margin: 4,
  position: 'relative',
  transition: 'all 100ms ease',
  width: 24,
};
// #endregion

type ToggleProps = {
  active: boolean;
  handleToggle: () => any;
  text: string;
};
export default function Toggle({ active, handleToggle, text }: ToggleProps) {
  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <div css={toggleWrap} onClick={handleToggle}>
        <div css={[toggleCircle, active && { backgroundColor: colors.blue, left: 22 }]} />
      </div>
      <span css={{ paddingLeft: 16 }}>{text}</span>
    </div>
  );
}
