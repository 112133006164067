/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import shortid from 'shortid';

import Circle from '../ui/Circle';
import { IconType } from '../ui/Icon';
import colors from '../../styles/colors';
import { ResultType, LoanResultProps, DocumentResult } from './DeleteLoan';

// #region css
const resultsWrap: CSSObject = {
  borderRadius: 4,
  marginTop: 24,
  border: `2px solid ${colors.grayLight}`,
  ':first-of-type': { marginTop: 0 },
};

const resultRow: CSSObject = {
  alignItems: 'center',
  display: 'grid',
  gridTemplateColumns: '48px 1fr',
  fontSize: 16,
};

const errorStyles: CSSObject = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: 110,
  '[data-style]': {
    svg: { color: 'red' },
  },
};

const headerRow: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.grayLight,
  color: colors.blue,
  display: 'flex',
  fontSize: 20,
  height: 56,
  justifyContent: 'center',
  textAlign: 'center',
  svg: { color: colors.blue },
};

const rowWrap: CSSObject = {
  padding: 8,
  border: '1px solid red',
  span: {
    width: '37%',
    display: 'inline-block',
    textTransform: 'capitalize',
    ':first-child': { width: '33%' },
    ':last-child': { width: '30%' },
  },
  ':nth-child(odd)': { backgroundColor: colors.grayExLight, borderRadius: 4 },
};

// #endregion

export default function DeleteLoanCard({
  loanNumber,
  result,
  amountCredited,
  documents = [],
  error,
}: LoanResultProps) {
  return (
    <div css={resultsWrap}>
      <HeaderRow text={loanNumber} />
      <div css={{ padding: 16 }}>
        {result === ResultType.Error && (
          <div css={errorStyles}>
            <ResultRow text="There was an error deleting this loan" icon={IconType.XMedium} />
            <ResultRow text={error!} icon={IconType.Errors} />
          </div>
        )}
        {result === ResultType.DoesNotExist && (
          <ResultRow text="Loan does not exist" icon={IconType.Errors} />
        )}
        {result === ResultType.Success && (
          <Fragment>
            {result === ResultType.Success && (
              <ResultRow text="Loan successfully deleted" icon={IconType.TrashCan} />
            )}
            {amountCredited && (
              <ResultRow text={`Loan credited $${amountCredited}`} icon={IconType.Loans} />
            )}
            {documents.map(d => (
              <div
                key={shortid.generate()}
                css={{ border: `2px solid ${colors.grayLight}`, marginBottom: 16, borderRadius: 4 }}
              >
                <HeaderRow text={d.documentType} />
                {d.result === ResultType.Error && (
                  <div css={errorStyles}>
                    <ResultRow
                      text="There was an error marking this document as not-found."
                      icon={IconType.XMedium}
                    />
                    <div
                      css={{
                        marginTop: 8,
                        svg: { color: 'red' },
                      }}
                    >
                      <ResultRow text={d.error!} icon={IconType.Errors} />
                    </div>
                  </div>
                )}
                {d.result === ResultType.Success && (
                  <Fragment>
                    <ResultRow
                      text="Document successfully marked as not-found"
                      icon={IconType.TrashCan}
                    />
                    {!!d.amountCredited && (
                      <ResultRow
                        text={`Document credited $${d.amountCredited}`}
                        icon={IconType.Loans}
                      />
                    )}
                  </Fragment>
                )}
              </div>
            ))}
          </Fragment>
        )}
      </div>
    </div>
  );
}

function HeaderRow({ text }: { text: string }) {
  return <div css={headerRow}>{text}</div>;
}

function ResultRow({ text, icon }: { text: string; icon: IconType }) {
  return (
    <div css={resultRow} data-style>
      <Circle small icon={icon} styleoverrides={{ color: colors.blue }} />
      <div css={{ overflow: 'scroll' }}>{text}</div>
    </div>
  );
}
