import React, { useEffect, useState } from 'react';
import { apiFetch } from '../adalConfig';
import Button from '../components/ui/Button';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      maxWidth: 1100,
      width: '100%',
      margin: '10px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    table: {
      margin: '10px',
      '& table, td, th': {
        border: '1px solid #ddd',
        textAlign: 'left',
      },
      '& table': {
        borderCollapse: 'collapse',
        width: '100%',
      },

      '& th, td': {
        padding: '4px',
        textAlign: 'left',
        borderBottom: '1px solid #ddd',
      },

      '& tr:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  }),
);

const WarehouseDocsTable = ({ warehouseCountsByClient }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.table}>
      <Grid item xs={6}>
        <table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Ready to Ship</th>
              <th>Order from Warehouse</th>
            </tr>
          </thead>
          <tbody>
            {warehouseCountsByClient.map(doc => (
              <tr key={doc.clientId}>
                <td>{doc.company}</td>
                <td>{doc.readyToShip}</td>
                <td>
                  <Button>Order from Warehouse</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

const ReadyToShip = () => {
  const classes = useStyles();
  const [counts, setCounts] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [warehouseCountsByClient, setWarehouseCountsByClient] = useState([]);

  const getWarehouseCountsByClient = async investorId => {
    const { data } = await apiFetch(
      `/api/documents/GetWarehouseDocsReadyToShipCount?investorid=${investorId}`,
    );
    setWarehouseCountsByClient(data);
  };

  useEffect(() => {
    const getCounts = async () => {
      const { data } = await apiFetch('/api/documents/GetReadyToShipCounts');
      setCounts(data);
    };
    getCounts();
  }, []);

  useEffect(() => {
    if (!selectedInvestor) {
      return;
    }

    getWarehouseCountsByClient(selectedInvestor);
  }, [selectedInvestor]);

  return (
    <>
      <div style={{ height: 500, overflowY: 'auto', marginTop: 30 }}>
        <Grid container spacing={3} className={classes.table}>
          <Grid item xs={6}>
            <table>
              <thead>
                <tr>
                  <th>Investor Name</th>
                  <th>Ready to Ship</th>
                  <th>Warehouse Ready to Ship</th>
                  <th>View by Client</th>
                </tr>
              </thead>
              <tbody>
                {counts.map(investor => (
                  <tr key={investor.investorId}>
                    <td>{investor.investorName}</td>
                    <td>{investor.readyToShipCount}</td>
                    <td>{investor.warehouseReadyToShipCount}</td>
                    <td>
                      <Button onClick={() => setSelectedInvestor(investor.investorId)}>
                        View in Warehouse by Client
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
      {!!warehouseCountsByClient.length && (
        <WarehouseDocsTable warehouseCountsByClient={warehouseCountsByClient} />
      )}
    </>
  );
};

export default ReadyToShip;
