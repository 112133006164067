/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component } from 'react';
import colors from '../../styles/colors';
import Button from './Button';

// #region css
const textAreaWrap: CSSObject = {
  backgroundColor: colors.white,
  border: '1px solid #e5e5ea',
  borderRadius: 4,
  height: 160,
  marginBottom: 32,
  resize: 'none',
  width: 476,
  caretColor: colors.blue,
  ' :focus': { border: `1px solid ${colors.blue}` },
};

const buttonText: CSSObject = {
  display: 'inline-block',
  verticalAlign: 'top',
  marginRight: 16,
};

const isTyping: CSSObject = {
  border: '1px solid #0828cc',
  borderRadius: 4,
};
// #endregion

export type NoteModalProps = {
  handleClose: (...args: any[]) => any;
  handleSave: (text: string) => any;
};
type NoteInnerModalState = {
  noteText?: string;
};
export default class NoteInnerModal extends Component<NoteModalProps, NoteInnerModalState> {
  state = { noteText: undefined };

  render() {
    const { noteText } = this.state;
    const { handleClose, handleSave } = this.props;
    return (
      <div css={{ margin: '32px 0' }}>
        <textarea
          css={[textAreaWrap, noteText && isTyping]}
          value={noteText}
          onChange={e => this.setState({ noteText: e.target.value })}
          autoFocus
        />
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button secondary onClick={handleClose} styleOverrides={buttonText}>
            Cancel
          </Button>
          <Button disabled={!noteText} onClick={() => handleSave(noteText!)}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
