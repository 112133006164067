/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useReducer, useEffect } from 'react';
import colors from '../../../styles/colors';
import { usePostData, usePrevious } from '../../../Hooks';
import StatusButton, { StatusTypes } from '../../ui/StatusButton';
import { downloadFiles, buildArrayFetchString } from '../../../Utils';
import { useDocIndex } from './useDocIndex';
import { IconType } from '../../ui/Icon';

// #region types
enum DownloadAction {
  Loading,
  Success,
  Error,
  Refresh,
}

type Action = {
  type: DownloadAction;
  payload?: Error;
};

type DownloadState = {
  downloadError: Error | undefined;
  downloadSuccess: boolean;
  downloadLoading: boolean;
};

interface ActionButtonsProps {
  refreshData: () => void;
}
// #endregion

// #region FUNCTIONS

const downloadReducer = (state: DownloadState, action: Action): DownloadState => {
  switch (action.type) {
    case DownloadAction.Loading:
      return { ...state, downloadLoading: true };
    case DownloadAction.Success:
      return { ...state, downloadSuccess: true, downloadLoading: false };
    case DownloadAction.Error:
      return { ...state, downloadLoading: false, downloadError: action.payload };
    case DownloadAction.Refresh:
      return { downloadSuccess: false, downloadError: undefined, downloadLoading: false };
    default:
      throw new Error();
  }
};

const getDeleteButtonText = (
  selectedCount: number,
  { deleteError, deleteSuccess, deleteLoading },
) => {
  const count = selectedCount;
  const docVersion = count !== 1 ? 'docs' : 'doc';

  if (deleteError) return `There was an error deleting the ${count} ${docVersion}`;
  if (deleteSuccess) return `${count} ${docVersion} deleted successfully`;
  if (deleteLoading) return `Deleting ${count} ${docVersion}`;
  return `Delete ${count} ${docVersion}`;
};

const getDeleteButtonStatus = ({ deleteError, deleteSuccess, deleteLoading }) => {
  if (deleteError) return StatusTypes.error;
  if (deleteSuccess) return StatusTypes.success;
  if (deleteLoading) return StatusTypes.loading;
  return StatusTypes.initial;
};

const getDownloadButtonText = (
  selectedCount,
  { downloadError, downloadSuccess, downloadLoading },
) => {
  const count = selectedCount;
  const docVersion = count !== 1 ? 'docs' : 'doc';
  if (downloadError) return `There was an error downloading the ${count} ${docVersion}`;
  if (downloadSuccess) return `${count} ${docVersion} downloaded successfully`;
  if (downloadLoading) return `Downloading ${count} ${docVersion}`;
  return `Download ${count} ${docVersion}`;
};

const getDownloadButtonStatus = ({ downloadError, downloadSuccess, downloadLoading }) => {
  if (downloadError) return StatusTypes.error;
  if (downloadSuccess) return StatusTypes.success;
  if (downloadLoading) return StatusTypes.loading;
  return StatusTypes.initial;
};

// #endregion

export default function ActionButtons({ refreshData }: ActionButtonsProps) {
  const [indexData, setIndexData] = useDocIndex();
  const { selected, isBusy, currentClient, displayedDocs } = indexData;

  const {
    doPost: deleteDocs,
    error: deleteError,
    isSuccess: deleteSuccess,
    isLoading: deleteLoading,
    refreshState: deleteRefresh,
  } = usePostData();

  const [{ downloadError, downloadSuccess, downloadLoading }, downloadDispatch] = useReducer(
    downloadReducer,
    {
      downloadSuccess: false,
      downloadError: undefined,
      downloadLoading: false,
    },
  );

  useEffect(() => {
    setIndexData({ isBusy: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading, downloadLoading]);

  useEffect(() => {
    if (deleteSuccess || deleteError) {
      if (selected.size > 0) setIndexData({ selected: new Set() });
      deleteRefresh();
      refreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteError, deleteRefresh, deleteSuccess, refreshData]);

  useEffect(() => {
    if (downloadSuccess || downloadError) {
      downloadDispatch({ type: DownloadAction.Refresh });
      if (selected.size > 0) setIndexData({ selected: new Set() });
      setIndexData({ isBusy: false });
    }
  });

  const deleteMultipleDocs = async () => {
    const arrToDelete = displayedDocs.reduce((acc: number[], doc) => {
      selected.has(doc.document.id) && acc.push(doc.document.id);
      return acc;
    }, []);
    await deleteDocs('/api/documents/deleteMultipleDocs', arrToDelete);
  };

  const downloadSelectedDocs = async () => {
    try {
      downloadDispatch({ type: DownloadAction.Loading });

      const urlParams = buildArrayFetchString([...selected], 'docIds');
      await downloadFiles(
        `/api/documents/downloadMultipleDocuments?${urlParams}`,
        {},
        currentClient.company,
      );

      downloadDispatch({ type: DownloadAction.Success });
    } catch (error) {
      downloadDispatch({ type: DownloadAction.Error, payload: error });
    }
  };

  const disabled =
    selected.size === 0 || (!deleteError && !deleteLoading && !deleteSuccess && isBusy);

  return (
    <div
      css={[
        {
          alignItems: 'center',
          borderRadius: 36,
          bottom: -10,
          display: 'flex',
          height: 0,
          justifyContent: 'space-between',
          left: 415,
          overflow: 'hidden',
          position: 'absolute',
          textAlign: 'center',
          transition: 'height .2s ease-out',
          zIndex: 50,
          'button:first-of-type': { marginRight: 16 },
        },
        !disabled && {
          backgroundColor: '#f7f7f9',
          height: 72,
          padding: '0 8px',
          border: `1px solid ${colors.blue}`,
          transition: 'height .2s ease-out',
        },
      ]}
    >
      <StatusButton
        initialIcon={IconType.TrashCan}
        onClick={deleteMultipleDocs}
        text={getDeleteButtonText(selected.size, {
          deleteError,
          deleteSuccess,
          deleteLoading,
        })}
        status={getDeleteButtonStatus({ deleteError, deleteSuccess, deleteLoading })}
      />
      <StatusButton
        initialIcon={IconType.Download}
        onClick={downloadSelectedDocs}
        text={getDownloadButtonText(selected.size, {
          downloadError,
          downloadLoading,
          downloadSuccess,
        })}
        status={getDownloadButtonStatus({ downloadError, downloadLoading, downloadSuccess })}
      />
    </div>
  );
}
