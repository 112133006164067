/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';

import Panel, { PanelHeader } from '../../../ui/Panel';
import Button from '../../../ui/Button';
import LoanInformation from '../LoanInformation';
import ErrorForm from './ErrorForm';
import { loanErrorIssues, FormData, LoanError } from '../ImportErrorsUtils';
import { Client } from '../../../../globalTypes/objects';

const stringsOrEmpty = (string: string) => string || '';

const makeValidate = (issues: string[], formData: FormData) => {
  if (
    issues.includes(loanErrorIssues.BORROWER_NAME) &&
    !(formData.borrowerFirstName && formData.borrowerLastName)
  ) {
    return false;
  }
  if (issues.includes(loanErrorIssues.PROPERTY_ADDRESS) && !formData.propertyAddress) {
    return false;
  }
  if (issues.includes(loanErrorIssues.PROPERTY_CITY) && !formData.propertyCity) {
    return false;
  }
  if (issues.includes(loanErrorIssues.PROPERTY_STATE) && !formData.propertyState) {
    return false;
  }
  if (
    issues.includes(loanErrorIssues.PROPERTY_ZIP) &&
    !(
      (formData.propertyZip && formData.propertyZip.length === 5) ||
      (formData.propertyZip && formData.propertyZip.length === 10)
    )
  ) {
    return false;
  }
  if (issues.includes(loanErrorIssues.PROPERTY_COUNTY) && !formData.propertyCounty) {
    return false;
  }
  if (
    (issues.includes(loanErrorIssues.INVESTOR_NAME) || issues.includes(loanErrorIssues.SERVICER)) &&
    !formData.investorId
  ) {
    return false;
  }
  if (
    (issues.includes(loanErrorIssues.TITLE_COMPANY_NAME) ||
      issues.includes(loanErrorIssues.TITLE_COMPANY_ADDRESS)) &&
    !formData.titleCompanyId
  ) {
    return false;
  }
  return true;
};

const getResolvedError = (error: LoanError, formData: FormData) => {
  Object.keys(error).forEach(
    key => error[key] === null || (formData[key] === '' && delete error[key]),
  );
  Object.keys(formData).forEach(
    key => formData[key] === null || (formData[key] === '' && delete formData[key]),
  );
  return {
    ...error,
    loan: {
      ...error.loan,
      ...formData,
      ...{
        ...(formData.borrowerFirstName && {
          borrowerFullName:
            stringsOrEmpty(formData.borrowerFirstName) + stringsOrEmpty(formData.borrowerLastName!),
        }),
      },
    },
  };
};

type MissingDataPanelProps = {
  error: LoanError;
  onChange: (resolvedError: any) => any;
  client: Client;
};
export default class MissingDataPanel extends Component<MissingDataPanelProps> {
  onFormChange = (formData: FormData) => {
    const { error, onChange } = this.props;
    const isValid = makeValidate(error.loanErrorIssues, formData);
    const resolvedError = isValid && getResolvedError(error, formData);
    onChange(resolvedError);
  };

  render() {
    const { client, error } = this.props;
    const loanInformation = [
      {
        client: client.company,
        // spreadsheetDate: error.spreadsheetDate,
        borrower: error.loan!.borrowerFullName,
        loanNumber: error.loan.loanNumber,
        propertyAddress: `${stringsOrEmpty(error.loan.propertyAddress)} ${stringsOrEmpty(
          error.loan.propertyCity,
        )} ${stringsOrEmpty(error.loan.propertyState)}`,
      },
    ];
    const contactInfo = {
      investorName: error.loan.investorName,
      titleCompanyName: error.loan.titleCompanyName,
      titleCompanyAddress: error.loan.titleCompanyAddress,
      titleCompanyCity: error.loan.titleCompanyCity,
      titleCompanyState: error.loan.titleCompanyState,
      titleCompanyZip: error.loan.titleCompanyZip,
      titleCompanyPhone: error.loan.titleCompanyPhone,
      titleCompanyFax: error.loan.titleCompanyFax,
      titleCompanyEmail: error.loan.titleCompanyEmail,
    };
    return (
      <Panel>
        <PanelHeader text="Missing data" />
        <div css={{ margin: '32px 0' }}>
          <ErrorForm
            issues={error.loanErrorIssues}
            onChange={this.onFormChange}
            contactInfo={contactInfo}
            errorId={error.id}
          />
        </div>
        <LoanInformation loanInformation={loanInformation} includeMoreInfo />
      </Panel>
    );
  }
}
