import React, { useState, useReducer, useEffect, Reducer } from 'react';
import { apiPost } from '../adalConfig';

enum ActionType {
  postInit = 'POST_INIT',
  postSuccess = 'POST_SUCCESS',
  postFailure = 'POST_FAILURE',
  refreshPostState = 'REFRESH_POST_STATE',
}

type Action = {
  type: ActionType;
  payload?: any;
};

function dataPostReducer<T>(state: State<T>, action: Action): State<T> {
  switch (action.type) {
    case ActionType.postInit:
      return { ...state, isLoading: true, error: false, isSuccess: false };
    case ActionType.postSuccess:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSuccess: true,
        data: action.payload,
      };
    case ActionType.postFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionType.refreshPostState:
      return initialState(state.data);
    default:
      throw new Error();
  }
}

type State<T> = {
  isLoading: Boolean;
  error: Error | Boolean;
  isSuccess: Boolean;
  data: T;
};

const initialState = <T extends unknown>(data: T): State<T> => {
  return {
    isLoading: false,
    error: false,
    isSuccess: false,
    data,
  };
};

export default function usePostData<T>(
  initialUrl?: string,
  initialPostBody?: {},
  initialData?: T | undefined,
) {
  const [url, setUrl] = useState(initialUrl || '');
  const [postBody, setPostBody] = useState(initialPostBody || {});

  const [state, dispatch] = useReducer<Reducer<State<T | undefined>, Action>>(
    dataPostReducer,
    initialState(initialData),
  );

  useEffect(() => {
    let didCancel = false;

    const postData = async () => {
      dispatch({ type: ActionType.postInit });

      try {
        const result = await apiPost(url, postBody, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (!didCancel) {
          dispatch({ type: ActionType.postSuccess, payload: result.data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: ActionType.postFailure, payload: error });
        }
      }
    };

    url && postData();

    return () => {
      didCancel = true;
    };
  }, [postBody, url]);

  const doPost = (newUrl: string, newPostBody: {}) => {
    setUrl(newUrl);
    setPostBody(newPostBody);
  };

  const refreshState = () => {
    dispatch({ type: ActionType.refreshPostState });
  };

  return { ...state, doPost, refreshState };
}
