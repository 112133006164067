/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { CallCenterProgressHeader as Header } from './types';

type Props = {
  callCenterProgressHeader: Header;
};

const CallCenterProgressHeader = ({ callCenterProgressHeader }: Props) => {
  const columns = [
    {
      field: 'rep',
      title: 'User',
    },
    {
      field: 'totalType',
      title: 'Type',
    },
    {
      field: 'monthOne',
      title: `${callCenterProgressHeader.monthOne}`,
    },
    {
      field: 'monthTwo',
      title: `${callCenterProgressHeader.monthTwo}`,
    },
    {
      field: 'weekOne',
      title: `${callCenterProgressHeader.weekOne}`,
    },
    {
      field: 'weekTwo',
      title: `${callCenterProgressHeader.weekTwo}`,
    },
  ];

  return (
    <Fragment>
      {callCenterProgressHeader?.data && (
        <DataTable<Header>
          title="Outreach Progress - By User"
          columns={columns}
          data={callCenterProgressHeader.data}
          headerStyle={{ whiteSpace: 'normal' }}
          options={{
            maxBodyHeight: '500px',
            search: false,
            exportFileName: 'Outreach Progress - By User',
            rowStyle: rowData => ({
              backgroundColor: rowData.totalType === 'Loans Called' ? '#f7f7f7' : '#fff',
            }),
          }}
        />
      )}
    </Fragment>
  );
};

export default CallCenterProgressHeader;
