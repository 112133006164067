/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import useEmailStyles from './ManualFollowupStyles';
import {
  EmailStatus,
  EmailWithStatus,
  EmailWithStatusAndContact,
  LoanContact,
  LoanEmailData,
  ManualFollowUpsProps,
  TitlePortalMessage,
  useManualFollowups,
} from './useManualFollowups';
import EmailValidationInput from './EmailValidationInput';
import { apiPost } from '../../../adalConfig';
import { convertToFileDownload } from '../../../Utils';
import { Note } from '../../CallCenter/types';
import { TitleCompanyContact } from '../../../globalTypes/objects';

export async function sentTitlePortalEmails(
  selectedTitlePortalEmails: EmailWithStatusAndContact[],
  loans: LoanEmailData[],
  updateNotes: (notes: Note[]) => void,
  updateTitlePortalContacts: (contacts: LoanContact[]) => void,
) {
  // make soft invalid emails valid
  const emailsToMakeValid = selectedTitlePortalEmails
    .filter(sel => sel.status === EmailStatus.SoftInvalid)
    .map(sel => sel.email);
  try {
    emailsToMakeValid.length > 0 &&
      (await apiPost('/api/emails/keepUsingBouncedEmails', emailsToMakeValid));
  } catch (error) {
    console.log('error validating soft invalids', error);
    throw error;
  }

  // add emails to loans:
  // get contactIds
  try {
    const { data: contacts } = await apiPost<TitleCompanyContact[]>(
      '/api/titleCompanyContacts/createAndGetContactIds',
      selectedTitlePortalEmails.map(sel => sel.email),
    );
    selectedTitlePortalEmails.map(sel => {
      sel.contactId = contacts.find(c => c.email === sel.email)?.id;
    });
  } catch (error) {
    console.log('error inserting contacts and getting ids', error);
    throw error;
  }
  // add emails and notes to loans
  try {
    const loansContacts = loans.reduce((acc, loan) => {
      const loanContacts = selectedTitlePortalEmails
        .filter(sel => sel.email !== loan.email && !loan.titlePortalEmails?.includes(sel.email))
        .map(sel => ({ loanId: loan.loanId, contactId: sel.contactId, email: sel.email }));
      return [...acc, ...loanContacts];
    }, [] as LoanContact[]);
    loansContacts.length > 0 &&
      (await apiPost<Note[]>('/api/titleCompanyContacts/addContactsToLoans', loansContacts).then(
        ({ data: notes }) => {
          updateNotes(notes);
          updateTitlePortalContacts(loansContacts);
        },
      ));
  } catch (error) {
    console.log('error adding loans_contacts to db', error);
    throw error;
  }

  // send emails
  // put title portal messages on queue
  const loansWithDocs = loans.reduce(
    (acc, loan) => ({ ...acc, [loan.loanId]: loan.missingDocuments }),
    {},
  );
  try {
    const messages: TitlePortalMessage[] = selectedTitlePortalEmails
      .filter(sel => sel.status !== EmailStatus.HardInvalid)
      .map(sel => ({
        email: sel.email,
        loansWithDocs,
      }));

    messages.length > 0 &&
      (await apiPost('/api/followups/publishTitlePortalFollowupsToQueue', {
        titlePortalEmails: messages,
      }));
  } catch (error) {
    console.log('error send title portal emails (putting messages on queue)', error);
    throw error;
  }
  // download hard invalid emails
  try {
    const hardInvalids = selectedTitlePortalEmails.filter(
      sel => sel.status === EmailStatus.HardInvalid,
    );
    hardInvalids.length > 0 &&
      (await apiPost<{ base64: string; fileName: string }>(
        '/api/followups/downloadTitlePortalEmails',
        {
          emailAddresses: hardInvalids.map(e => e.email),
          loansWithDocs,
        },
      ).then(({ data: downloadData }) =>
        convertToFileDownload(downloadData.base64, downloadData.fileName),
      ));
  } catch (error) {
    console.log('error downloading title portal emails for hard invalid emails', error);
    throw error;
  }
}

export default function TitlePortalEmailPanel({ loans }: ManualFollowUpsProps) {
  const classes = useEmailStyles();

  const [{ emailsSent, titlePortalMode, emails }, setManualFollowups] = useManualFollowups();
  const [selectedEmails, setSelectedEmails] = useState<Set<string>>(
    new Set(loans.map(l => l.email)),
  );
  const [email, setEmail] = useState<EmailWithStatus>();
  const [typedEmails, setTypedEmails] = useState<EmailWithStatus[]>([]);

  useEffect(() => {
    setManualFollowups({
      selectedTitlePortalEmails: [...selectedEmails].map(
        sel => emails.find(e => e.email === sel) ?? typedEmails.find(e => e.email === sel)!,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmails, emails]);

  const addTypedEmail = () => {
    if (email) {
      setTypedEmails(typed => [...typed, email]);
      setSelectedEmails(selected => {
        selected.add(email!.email);
        return new Set(selected);
      });
      setEmail(undefined);
    }
  };

  return (
    <Accordion
      disabled={emailsSent}
      defaultExpanded
      className={emailsSent ? classes.disableChildren : ''}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b>Title Portal Email</b>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Switch
              checked={titlePortalMode}
              onChange={() => setManualFollowups({ titlePortalMode: !titlePortalMode })}
              color="primary"
            />
          }
          label="Send Title Portal Email"
        />
        {titlePortalMode && (
          <Fragment>
            {emails.map(e => (
              <EmailCheckbox
                key={e.email}
                email={e.email}
                status={e.status}
                isSelected={selectedEmails.has(e.email)}
                onSelect={() =>
                  setSelectedEmails(selected => {
                    selected.has(e.email) ? selected.delete(e.email) : selected.add(e.email);
                    return new Set(selected);
                  })
                }
              />
            ))}
            <Divider />
            {typedEmails.map(e => (
              <EmailCheckbox
                key={e.email}
                email={e.email}
                status={e.status}
                isSelected={selectedEmails.has(e.email)}
                onSelect={() =>
                  setSelectedEmails(selected => {
                    selected.has(e.email) ? selected.delete(e.email) : selected.add(e.email);
                    return new Set(selected);
                  })
                }
              />
            ))}
            <div
              onBlur={addTypedEmail}
              onKeyPress={e => {
                if (e.which === 13) {
                  // keycode 13: Enter
                  addTypedEmail();
                }
              }}
            >
              <EmailValidationInput
                key={typedEmails.length}
                setValidatedEmail={emailWithStatus => setEmail(emailWithStatus)}
              />
            </div>
          </Fragment>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

function EmailCheckbox({
  email,
  status,
  isSelected,
  onSelect,
}: {
  email: string;
  status: EmailStatus;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color={status === EmailStatus.HardInvalid ? 'error' : 'primary'}
          checked={isSelected}
          onChange={onSelect}
        />
      }
      label={
        <p css={{ color: status === EmailStatus.HardInvalid ? 'red' : 'black' }}>
          {email}
          {status === EmailStatus.HardInvalid && (
            <Tooltip
              title={
                <p>
                  Email is not working. <br /> Email will download for you to send manually.
                </p>
              }
            >
              <IconButton size="large">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </p>
      }
    />
  );
}
