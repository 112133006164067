/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NoteData } from '../Loans/types';
import { apiPost } from '../../adalConfig';
import { LoanContext } from '../../pages/ClientSingleLoan';
import { useToaster } from '../../Hooks/toasters';

import { orange, red } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    textareaWrap: {
      width: '100%',
      resize: 'none',
    },
  }),
);

type PostData = {
  id: number;
  noteText?: string;
};

type Props = {
  noteData: NoteData;
};

const EditAndDeleteNote = ({ noteData }: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editedNote, setEditedNote] = useState<string>(noteData.note);

  const { successToaster, errorToaster } = useToaster();

  const loanContext = useContext(LoanContext);

  const classes = useStyles();

  const post = async (urlEndPoint: string) => {
    const postType = urlEndPoint === 'update-note' ? 'update' : 'delete';
    const postData: PostData = {
      id: noteData.id,
    };

    if (urlEndPoint === 'update-note') {
      postData.noteText = editedNote;
    }

    try {
      const { data: response } = await apiPost(`/api/notes/${urlEndPoint}`, postData);
      if (response === 1) {
        successToaster(`Successfully ${postType}d the note.`);

        if (urlEndPoint === 'update-note') {
          setIsEditModalOpen(false);
          setEditedNote(noteData.note);
        } else {
          setIsDeleteModalOpen(false);
        }

        loanContext.getLoanData();
      } else {
        throw new Error(`Failed to ${postType} the note.`);
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const saveNote = () => post('update-note');
  const deleteNote = () => post('delete-note');

  return (
    <Fragment>
      <div className="df jcsb">
        <Tooltip title="Edit Note">
          <EditIcon style={{ color: orange[500] }} onClick={() => setIsEditModalOpen(true)} />
        </Tooltip>
        <Tooltip title="Delete Note">
          <DeleteIcon style={{ color: red[500] }} onClick={() => setIsDeleteModalOpen(true)} />
        </Tooltip>
      </div>

      <Modal onClose={() => setIsEditModalOpen(false)} open={isEditModalOpen}>
        <div className={classes.paper}>
          <p className="mt2 bold">Note</p>
          <div>
            <TextareaAutosize
              minRows={3}
              value={editedNote}
              onChange={e => setEditedNote(e.target.value)}
              className={classes.textareaWrap}
              autoFocus
              onFocus={event => event.target.select()}
            />
          </div>
          <div className="df jcfe mb2">
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                setIsEditModalOpen(false);
                setEditedNote(noteData.note);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={saveNote}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>

      <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <div className={classes.paper}>
          <h2>Delete Note</h2>
          <p>Are you sure you want to delete the note?</p>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            className={classes.button}
            startIcon={<DeleteIcon />}
            onClick={deleteNote}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
};

export default EditAndDeleteNote;
