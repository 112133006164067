/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import Panel from '../components/ui/Panel';
import { apiFetch, apiPost } from '../adalConfig';
import Header from '../components/ui/Header';
import BatchContent from '../components/Batches/BatchContent';
import BatchScanning from '../components/Batches/BatchScanning';
import BarcodeInput from '../components/Utilities/BarcodeInput';
import ConfirmModal from '../components/ui/ConfirmModal';
import { ConfirmModal as ConfirmModalType } from '../globalTypes/objects';
import CircularProgress from '@mui/material/CircularProgress';

type BatchVerificationProps = {
  match: any;
};
type BatchDocument = {
  id: number;
  batchId: number;
  documentId: string;
  from: number;
  to: number;
  isVerified: boolean;
  isNotUploaded: boolean;
};
const panelMargin = {
  marginLeft: 'auto',
  marginRight: 'auto',
};
const container = {
  margin: '40px',
  maxWidth: 1300,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
};
export default class BatchVerification extends Component<BatchVerificationProps> {
  state = {
    batchDocuments: [] as BatchDocument[],
    showScanning: false,
    showLoader: false,
    modal: {
      isOpen: false,
      header: '',
      message: '',
      confirmText: '',
      cancelText: '',
      handleCancel: () => {},
      handleConfirm: () => {},
      cancelDisabled: false,
    } as ConfirmModalType,
  };

  constructor(props) {
    super(props);
    // just for button check
    this.onDocScanned = this.onDocScanned.bind(this);
    this.redirectToStart = this.redirectToStart.bind(this);
  }

  componentDidMount() {
    const { barcode } = this.props.match.params;
    this.getDocuments(barcode);
  }

  async getDocuments(barcode) {
    const { data: batchDocuments } = await apiFetch<BatchDocument[]>(
      `/api/batches/GetDocumentsByBatch/${barcode}`,
    );
    await this.setState({ batchDocuments });
    this.onDocScanned(barcode);
  }

  getDocument = (id, documents) => {
    return documents.find(x => x.documentId === id);
  };

  setDocumentVerified = id => {
    const documents = [...this.state.batchDocuments];
    const document = this.getDocument(id, documents);
    if (document) document.isVerified = true;
    this.setState({ batchDocuments: documents });
  };

  setDocumentUploaded = (id, isUploaded) => {
    const documents = [...this.state.batchDocuments];
    const document = this.getDocument(id, documents);
    if (document) {
      document.isNotUploaded = !isUploaded;
      document.isVerified = false;
    }
    this.setState({ batchDocuments: documents });
  };

  cancelScan = () => {
    const documents = [...this.state.batchDocuments];
    documents.forEach(doc => {
      doc.isVerified = false;
      doc.isNotUploaded = false;
    });
    this.setState({ batchDocuments: documents });
  };

  finishScan = () => {
    if (this.checkScanFinished()) this.sendBatchToWarehouse();
    else if (this.checkNotVerifiedDocuments())
      this.setState({
        modal: {
          isOpen: true,
          header: 'Ooops!',
          confirmText: 'Keep scanning',
          handleCancel: this.sendBatchToWarehouse,
          cancelText: 'OK',
          message: "Some of the documents in the batch weren't scanned.",
        },
      });
    else
      this.setState({
        modal: {
          isOpen: true,
          header: 'Ooops!',
          confirmText: 'Try again',
          handleCancel: this.sendBatchToWarehouse,
          cancelText: 'OK',
          message: (
            <span>
              Some of the documents will not be sent.
              <br /> Because they are not uploaded yet.
            </span>
          ),
        },
      });
  };

  sendBatchToWarehouse = async () => {
    this.setState({ showLoader: true });
    const batch = this.state.batchDocuments.filter(d => d.isVerified === true);
    this.changeModalDisabledState(true);
    const response = await apiPost(`/api/Documents/SendDocumentsToWarehouse/`, batch)
      .then(res => {
        this.changeModalDisabledState(false);
        this.setState({
          modal: {
            isOpen: true,
            header: 'Well done!',
            confirmText: 'OK',
            message: 'Batch scanning completed successfully.',
            handleConfirm: this.redirectToStart,
          },
        });
      })
      .catch(e => {
        this.changeModalDisabledState(false);
        let header = '';
        let message = '';
        if (e.response.status === 400) {
          const error = JSON.parse(e.response.data);
          header = error.Message;
          message = error.Description;
        } else {
          header = 'Error!';
          message = 'Failed to send the batch to the warehouse.';
        }
        this.setState({
          modal: {
            isOpen: true,
            header,
            confirmText: 'OK',
            message,
          },
        });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  checkIfDocUploaded = async documentId => {
    const { data: isDocUploaded } = await apiFetch<boolean>(
      `/api/documents/checkIfDocUploaded?docId=${documentId}`,
    );
    this.setDocumentUploaded(documentId, isDocUploaded);
    isDocUploaded
      ? this.setDocumentVerified(documentId)
      : this.setState({
          modal: {
            isOpen: true,
            header: 'Document has not been uploaded yet.',
            confirmText: 'OK',
            message: (
              <span>
                Make sure to remove it from the batch <br /> And send back to the scanner to rescan.
              </span>
            ),
          },
        });
    if (this.checkScanFinished()) this.sendBatchToWarehouse();
  };

  checkIfDocInAnotherBatch = async documentId => {
    const { data: isDocInBatch } = await apiFetch<boolean>(
      `/api/batches/CheckIfDocumentInBatch?docId=${documentId}`,
    );
    isDocInBatch
      ? this.setState({
          modal: {
            isOpen: true,
            header: "Document doesn't belong to the batch.",
            confirmText: 'OK',
            message: (
              <span>
                Make sure to remove it from the batch <br /> And send back to the scanner to rescan.
              </span>
            ),
          },
        })
      : this.setState({
          modal: {
            isOpen: true,
            header: `Document is not recognized in the system`,
            confirmText: 'OK',
            message: '',
          },
        });
  };

  onDocScanned = documentId => {
    if (documentId) {
      this.showScanning();
      if (this.getDocument(documentId, this.state.batchDocuments))
        this.checkIfDocUploaded(documentId);
      else {
        this.checkIfDocInAnotherBatch(documentId);
      }
    }
  };

  showScanning = () => {
    this.setState({ showScanning: true });
    setTimeout(() => {
      this.setState({ showScanning: false });
    }, 1000);
  };

  checkScanFinished = () => {
    return this.state.batchDocuments.every(d => d.isVerified === true);
  };

  private changeModalDisabledState(disabled) {
    var modalState = { ...this.state.modal };
    modalState.cancelDisabled = disabled;
    this.setState({ modal: modalState });
  }

  redirectToStart() {
    this.props.history.push('/batch-verification-start');
  }

  private checkNotVerifiedDocuments() {
    const verifiedDoucuments = this.state.batchDocuments.filter(doc => doc.isVerified === true)
      .length;
    const notUploadedDocuments = this.state.batchDocuments.filter(doc => doc.isNotUploaded === true)
      .length;
    const countDocuments = this.state.batchDocuments.length;
    if (verifiedDoucuments + notUploadedDocuments === countDocuments) return false;
    return true;
  }

  render() {
    const { batchDocuments, showScanning, modal } = this.state;
    return (
      <Fragment>
        <Header headerText="Batch Confirmation" />
        {this.state.showLoader ? (
          <div className="center-in-parent">
            <CircularProgress size="45" disableShrink />
          </div>
        ) : (
          <Fragment>
            <div css={container}>
              <Panel css={panelMargin}>
                <BatchScanning
                  showScanning={showScanning}
                  finishScan={this.finishScan}
                  cancelScan={this.cancelScan}
                />
              </Panel>
              <Panel css={panelMargin}>
                <BatchContent batchDocuments={batchDocuments} handleScan={this.onDocScanned} />
              </Panel>
              <BarcodeInput hidden keepFocus onChange={this.onDocScanned} />
            </div>
            <ConfirmModal modalData={modal} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
