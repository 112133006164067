/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Client } from '../globalTypes/objects';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useClients } from '../Hooks/useClients';
import ClientMapping from '../components/Admin/LoanImportMapping/ClientMapping';
import { useGetData } from '../Hooks';

const LoanImportMapping = () => {
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);

  const { data: systemMappingFields } = useGetData<string[]>(
    '/api/loans/system-import-mappings-fields',
    [],
  );
  const clients = useClients();

  return (
    <div className="m3">
      <Autocomplete
        options={clients}
        getOptionLabel={(option: Client) => option?.company || ''}
        onChange={(event: any, newValue: Client | null) => setSelectedClient(newValue)}
        value={selectedClient}
        style={{ width: '300px' }}
        autoHighlight
        renderInput={params => <TextField {...params} label="Choose a client" variant="outlined" />}
      />

      {!!selectedClient && (
        <ClientMapping client={selectedClient} systemMappingFields={systemMappingFields} />
      )}
    </div>
  );
};

export default LoanImportMapping;
