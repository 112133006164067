/** @jsx jsx */
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import { FunctionComponent } from 'react';
import colors from '../../styles/colors';
import { LeftArrowIcon } from '../ui/icons';

const linkWrap = {
  alignItems: 'center',
  backgroundColor: colors.blue,
  border: `2px solid ${colors.blue}`,
  color: colors.white,
  display: 'inline-flex',
  marginLeft: 40,
  marginTop: 24,
  padding: '4px 8px',
  borderRadius: 4,
  transition: 'all .2s',
  '&:hover': {
    backgroundColor: colors.blueStatus,
  },
};

type BackButtonProps = {
  children: string;
  to: string;
  styles?: object;
};

const BackButton: FunctionComponent<BackButtonProps> = ({ children, to, styles }) => {
  return (
    <NavLink to={to} css={[linkWrap, styles]}>
      <LeftArrowIcon />
      <span css={{ marginLeft: 8, fontWeight: 700 }}>{children}</span>
    </NavLink>
  );
};

export default BackButton;
