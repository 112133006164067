import React from 'react';
import AlertContext from '../../components/AlertContext';
import ClientSearchReportTemplate from './ClientSearchReportTemplate';

export default () => (
  <AlertContext.Consumer>
    {({ setAlert }) => (
      <ClientSearchReportTemplate
        url="/api/reports/outstandingLoans"
        report="Outstanding Loans (Excluding Loans In The Pipeline)"
        setAlert={setAlert}
      />
    )}
  </AlertContext.Consumer>
);
