/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import words from 'lodash/words';
import Panel from '../../ui/Panel';
import colors from '../../../styles/colors';
import Toggle from '../../ui/Toggle';
import StepHeader from '../../Utilities/StepUI/StepHeader';
import LoanDropdown from '../../Utilities/Dropdowns/LoanDropdown';
import TextInput from '../../ui/Inputs/TextInput';
import { DocumentType, FlaggedDoc, Investor, Loan } from '../../../globalTypes/objects';
import Checkbox from '../../ui/Checkbox';
import { apiFetch } from '../../../adalConfig';
import { ErrorsIcon, RightCaretIcon } from '../../ui/icons';
import { SearchType } from '../../Utilities/Dropdowns/NoResults';

// #region css
const panelWrap = {
  marginBottom: 24,
  maxWidth: 720,
};
const boxStyles = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: 4,
  marginTop: 32,
  maxWidth: 656,
  padding: '32px 32px',
};

const disabledOverlay = {
  cursor: 'normal',
  position: 'relative',
  ':before': {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    bottom: 0,
    content: '""',
    height: '100%',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: '20',
  },
};

// #endregion

export const CorrectionDocs = (digital, duplicate) => [
  DocumentType.Assignment,
  DocumentType.CEMA,
  DocumentType.SecondMortgage,
  DocumentType.HECM,
  DocumentType.Mortgage,
  ...[digital && duplicate && DocumentType.Policy], // policy will only get correction docs digitally and if there is existing doc
];

interface Props {
  docType: { label: string; value: DocumentType };
  digital: any;
  allowMultipleDocs: any;
  investor: Investor;
  gotLoan: (loan: Loan) => void;
  [key: string]: any;
}
interface State {
  loan: Partial<Loan>;
  flaggedDocs: FlaggedDoc[];
  [key: string]: any;
}

export default class StepTwo extends React.Component<Props, State> {
  state: State = {
    loan: {},
    client: {},
    clients: [],
    notFound: false,
    duplicate: false,
    identifyingNumber: '',
    isCorrection: false,
    flaggedDocs: [],
  };

  async componentDidMount() {
    const { client, loan, notFound, isCorrection, identifyingNumber, clients } = this.props;

    this.setState({
      clients: clients.map(c => ({ id: c.id, name: c.company })),
    });
    this.setState({ client, isCorrection });
    identifyingNumber && this.setState({ identifyingNumber });
    loan && this.setState({ loan, notFound });
  }

  async componentDidUpdate(_, prevState) {
    const { loan, notFound, client, duplicate, identifyingNumber, isCorrection } = this.state;
    if (!isEqual(loan, prevState.loan)) {
      await this.checkDuplicateDocument();
    }
    if (
      !isEqual(loan, prevState.loan) ||
      notFound !== prevState.notFound ||
      !isEqual(client, prevState.client) ||
      identifyingNumber !== prevState.identifyingNumber ||
      isCorrection !== prevState.isCorrection
    ) {
      const valid = this.validate();
      this.props.handleChange(
        valid
          ? {
              loan,
              client,
              notFound,
              duplicate,
              identifyingNumber,
              isCorrection,
            }
          : { client },
      );
    }
  }

  validate = () => {
    const { loan, notFound, client, identifyingNumber } = this.state;
    const { allowMultipleDocs, docType, digital } = this.props;
    return (
      (!isEmpty(loan) &&
        (allowMultipleDocs && docType.value !== DocumentType.Misc
          ? identifyingNumber.length === 5
          : true)) ||
      (notFound &&
        (allowMultipleDocs && docType.value !== DocumentType.Misc
          ? identifyingNumber.length === 5
          : true) &&
        (!isEmpty(client) || digital))
    );
  };

  checkDuplicateDocument = async () => {
    const { loan }: State = this.state;
    const { docType } = this.props;
    const { data: duplicate } = await apiFetch(
      `/api/documents/isDuplicate?loanId=${loan?.id}&documentType=${docType.value}`,
    );
    this.setState({ duplicate });
  };

  checkForFlaggedDocs = async (input: string, searchBy: SearchType) => {
    const { client }: State = this.state;
    const { docType }: Props = this.props;

    const url = `/api/documents/SearchForFlaggedDocs?clientId=${client.id}&documentType=${docType.value}&search=${input}&searchBy=${searchBy}`;
    const { data: flaggedDocs } = await apiFetch<FlaggedDoc[]>(url);
    this.setState({ flaggedDocs });
  };

  handleLoanSelected = selection => {
    const loan = (selection?.value || {}) as Loan;
    this.setState(prev => ({ ...prev, loan }));
    this.props.gotLoan(loan);
  };

  render() {
    const {
      clients,
      client,
      loan,
      notFound,
      duplicate,
      identifyingNumber,
      isCorrection,
    }: { [key: string]: any; loan: Partial<Loan> } = this.state;

    const { docType, digital, allowMultipleDocs, investor }: Props = this.props;

    const disabled = notFound;
    const disableSecondBox = isEmpty(client) && !digital;

    // @ts-ignore
    return (
      <React.Fragment>
        <Panel styles={panelWrap}>
          <StepHeader text="Associate document with existing loan" number="2" />
          <div>
            <div css={{ margin: '16px 0' }}>
              {this.state.flaggedDocs.length > 0 && <Toast flaggedDocs={this.state.flaggedDocs} />}
              <div
                // @ts-ignore
                css={[boxStyles, disableSecondBox && disabledOverlay]}
              >
                <div css={{ display: 'flex' }}>
                  <div css={{ marginRight: 48 }}>
                    <LoanDropdown
                      onInputDebounced={(i, searchBy) => this.checkForFlaggedDocs(i, searchBy)}
                      autoFocus={!isEmpty(client) || notFound}
                      loan={loan as Loan}
                      client={client}
                      onChange={this.handleLoanSelected}
                      disabled={disabled}
                      includeDate
                      includeBorrower
                      includeLoanNumber
                      includeAddress
                    />
                    {!isEmpty(loan) && !loan.investorID && (
                      <h4 css={{ color: colors.red }}>
                        We are still locating the investor for this document
                      </h4>
                    )}
                    {allowMultipleDocs && (
                      <TextInput
                        label={allowMultipleDocs}
                        labelOverrides={{ marginTop: 16 }}
                        placeholder="Enter last 5 digits"
                        maxLength={5}
                        value={identifyingNumber}
                        onChange={e => this.setState({ identifyingNumber: e.target.value })}
                      />
                    )}
                  </div>
                  {CorrectionDocs(digital, duplicate).includes(docType.value) && (
                    <div css={{ marginTop: 75 }}>
                      <Checkbox
                        checked={isCorrection}
                        onClick={() => this.setState({ isCorrection: !isCorrection })}
                        text="Correction"
                      />
                    </div>
                  )}
                </div>
                {digital && loan.investorID && investor.requiresOriginalDoc && (
                  <h4 css={{ color: colors.red }}>
                    Investor {investor.name} requires original document
                  </h4>
                )}
                {duplicate && (
                  <h4 css={{ color: colors.red }}>
                    {docType.value !== DocumentType.Policy &&
                      `${docType.label} already processed for this loan.`}
                    {CorrectionDocs(digital, duplicate).includes(docType.value) &&
                      !isCorrection &&
                      ' Please note if this is a correction.'}
                  </h4>
                )}
                <div css={{ marginTop: 32 }}>
                  <Toggle
                    text="Not Found"
                    active={notFound}
                    handleToggle={() => this.setState({ notFound: !notFound, loan: {} })}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </React.Fragment>
    );
  }
}

const lightlyToasted = {
  marginTop: 24,
  border: `1px solid ${colors.red}`,
  borderRadius: 4,
  padding: 16,
  paddingBottom: 0,
  display: 'inline-block',
  svg: { marginRight: 8 },
  ul: {
    padding: 16,
    paddingLeft: 28,
    lineHeight: '1.7',
    border: '1px solid #c7c7cc',
    borderRadius: 4,
    marginTop: 16,
    ':first-of-type': { marginTop: 0 },
  },
  li: { listStyle: 'square' },
  position: 'absolute',
  width: 500,
  right: 25,
  backgroundColor: '#fff',
  top: 80,
  zIndex: '30',
};

const openButton = {
  height: 24,
  width: 24,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #757575',
  borderRadius: '50%',
  transform: 'rotate(-90deg)',
  transition: 'all .2s',
  svg: { marginRight: 0 },
};

class Toast extends React.Component<{ flaggedDocs: FlaggedDoc[] }> {
  state = { isOpen: true };

  render() {
    const { flaggedDocs } = this.props;

    const { isOpen } = this.state;
    return (
      <div
        // @ts-ignore
        css={[lightlyToasted, isOpen && { paddingBottom: 16 }]}
      >
        <div className="df aic bold jcsb" css={{ fontSize: 16, paddingBottom: 16 }}>
          <div className="dif aic">
            <ErrorsIcon css={{ color: '#ff3b30' }} /> The following document
            {flaggedDocs.length > 1 && 's'} {flaggedDocs.length > 1 ? 'have ' : 'has'} been flagged:
          </div>
          <button
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            css={[openButton, isOpen && { transform: 'rotate(90deg)' }]}
          >
            <RightCaretIcon />
          </button>
        </div>
        <div
          css={[
            { height: 0, overflow: 'hidden', transition: 'all .2s' },
            isOpen && { height: 200, overflowY: 'scroll', paddingRight: 16, paddingTop: 8 },
          ]}
        >
          {flaggedDocs.length > 0 &&
            flaggedDocs.map(fd => (
              <ul key={fd.id}>
                <li>
                  <span className="bold"> Loan #:</span> {fd.loanNumber}
                </li>
                <li>
                  <span className="bold"> Type:</span>{' '}
                  {words(DocumentType[fd.documentType]).join(' ')}
                </li>
                {fd.borrower && (
                  <li>
                    <span className="bold">Borrower: </span>
                    {fd.borrower}
                  </li>
                )}
                {fd.address && (
                  <li>
                    <span className="bold">Address: </span>
                    {fd.address}
                  </li>
                )}
                {fd.reason && (
                  <li>
                    <span className="bold"> Reason:</span> {fd.reason}
                  </li>
                )}
              </ul>
            ))}
        </div>
      </div>
    );
  }
}
