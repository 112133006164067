/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Card } from '@mui/material';
import useEmailStyles from './ManualFollowupStyles';

export default function ErrorCard({ error }) {
  const classes = useEmailStyles();
  return (
    <Card raised className={classes.errorCard}>
      Error! Something went wrong :(
      <div css={{ fontsize: 12 }}>{error}</div>
    </Card>
  );
}
