import React, { useEffect, version } from 'react';
import { AuditModalStyled, Overlay } from './auditStyles';
import AuditModal from './AuditModal';
import { AuditFailProvider } from './useAuditFailContext';
import { AuditPoint, DocumentType } from '../../../globalTypes/objects';
import { AuditModalHeaderProps } from './AuditModalHeader';

export enum AuditFailVersion {
  modal,
  panel,
}

type AuditFailProps = AuditModalHeaderProps & {
  className?: string; // comes in from styled component
  version: AuditFailVersion;
  alreadySelectedFailures?: AuditPoint[];
  docType: DocumentType;
  investorOnly?: boolean;
};

export default function AuditFail({ version, docType, investorOnly, ...props }: AuditFailProps) {
  return (
    <AuditFailProvider docType={docType} investorOnly={investorOnly}>
      {version === AuditFailVersion.modal && (
        <Overlay>
          <AuditModalStyled {...props} />
        </Overlay>
      )}
      {version === AuditFailVersion.panel && <AuditModal {...props} />}
    </AuditFailProvider>
  );
}
