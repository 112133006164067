/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useMemo, useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Option } from '../../../ui/Downshift/types';
import { useClients } from '../../../../Hooks/useClients';
import { useToaster } from '../../../../Hooks/toasters';
import Panel, { PanelHeader } from '../../../ui/Panel';
import { apiFetch } from '../../../../adalConfig';
import SearchSelect from '../../../ui/SearchSelect';
import ReportModal, { RowData } from './ReportModal';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '84px',
      height: '39px',
    },
  }),
);

export enum ReportType {
  AllClients,
  AllClientsGroupedByClient,
  SpecificClient,
}

const Report = () => {
  const [selectedClient, setSelectedClient] = useState<Option | undefined>();
  const [selectedSearchOption, setSelectedSearchOption] = useState<ReportType>(
    ReportType.AllClients,
  );
  const [monthAndYear, setMonthAndYear] = useState(new Date().toLocaleDateString('en-CA'));
  const [isLoading, setIsLoading] = useState<boolean>();
  const [datatableData, setDatatableData] = useState<RowData[]>([]);

  const monthAndYearAsDate = useMemo(() => new Date(new Date(monthAndYear).setUTCHours(12)), [
    monthAndYear,
  ]);

  const clients = useClients();
  const { errorToaster, warningToaster } = useToaster();

  const classes = useStyles();

  const clientOptions = useMemo(
    () =>
      clients.map(client => ({
        label: client.company,
        value: client.id,
      })),
    [clients],
  );

  const canSubmit = useMemo(() => {
    if (isLoading) {
      return false;
    }

    return selectedClient || selectedSearchOption !== ReportType.SpecificClient;
  }, [selectedClient, selectedSearchOption, isLoading]);

  const submit = async () => {
    if (!canSubmit) {
      warningToaster('Please select a client before submitting');
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await apiFetch<RowData[]>('/api/reports/revenue-breakdown-report', {
        params: { reportType: selectedSearchOption, monthAndYear, clientId: selectedClient?.value },
      });
      setDatatableData(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Panel>
      <PanelHeader largeText text="Revenue Breakdown Report" />
      <div className="mt3">
        <RadioGroup
          value={selectedSearchOption}
          onChange={(e: React.ChangeEvent<HTMLInputElement>, value) => {
            setSelectedSearchOption(parseInt(value) as ReportType);
            setSelectedClient(undefined);
          }}
        >
          <FormControlLabel value={ReportType.AllClients} control={<Radio />} label="All Clients" />
          <FormControlLabel
            value={ReportType.AllClientsGroupedByClient}
            control={<Radio />}
            label="All Clients Grouped By Client"
          />
          <FormControlLabel
            value={ReportType.SpecificClient}
            control={<Radio />}
            label="Search By Client"
          />
        </RadioGroup>
        {selectedSearchOption === ReportType.SpecificClient && (
          <SearchSelect
            options={clientOptions}
            defaultValue={selectedClient}
            placeholder="Search clients"
            onChange={selection => setSelectedClient(selection)}
          />
        )}
        <div>
          <b>Select a month</b>
          <div>
            <DatePicker
              minDate={new Date(2000, 0, 1)}
              maxDate={new Date()}
              inline
              selected={monthAndYearAsDate}
              onChange={dateTime =>
                dateTime && setMonthAndYear(dateTime.toLocaleDateString('en-CA'))
              }
              placeholderText="Click to select"
              dateFormat="MMMM yyyy"
              showMonthYearPicker
            />
          </div>
        </div>
        <div className="mt1">
          {isLoading ? (
            <div className={`${classes.loaderButton} center-in-parent`}>
              <CircularProgress size="20" disableShrink />
            </div>
          ) : (
            <Button variant="contained" color="primary" disabled={!canSubmit} onClick={submit}>
              Submit
            </Button>
          )}
        </div>
        {!!datatableData && (
          <ReportModal
            datatableData={datatableData}
            setDatatableData={setDatatableData}
            reportType={selectedSearchOption}
            client={selectedClient}
            month={monthAndYearAsDate.toLocaleString('default', { month: 'long' })}
          />
        )}
      </div>
    </Panel>
  );
};

export default Report;
