import styled from '@emotion/styled';

import AuditModal from './AuditModal';

export const AuditModalStyled = styled(AuditModal)`
  width: 100%;
  max-width: 1360px;
  max-height: calc(100% - 56px);
  height: auto;
  top: 32px;
  left: unset;
  position: relative;
  top: unset;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px,
    rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  border-radius: 6px;
  .guf {
    display: block;
    column-count: 3;
    column-gap: 24px;
    padding: 24px;
    .cheftza {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 24px;
    }
  }
  .note_submit_wrap {
    width: 100%;
  }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 255px);
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Root = styled.div`
  border-left: 1px solid #e5e5ea;
  z-index: 100;
  position: relative;
  background-color: #fff;
  /* position: fixed; */
  overflow: auto;
  right: 0;
  height: 100vh;
  top: 0;
  z-index: 500;
  width: 520;
  .rosh {
    font-size: 24px;
    font-weight: 900;
    padding: 0px 24px;
    height: 56px;
    border-bottom: 1px solid #e5e5ea;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
  }
  .chitzionias {
    overflow: auto;
    max-height: calc(100vh - 72px);
  }
  .guf {
    background-color: #fff;
    width: 100%;
    padding: 0px 0px 16px 16px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: auto;
  }
  .cheftza {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ddd;
    break-inside: avoid;
    margin-right: 15px;
    margin-top: 20px;
    padding-bottom: 16px;
    position: relative;
    width: calc(33% - 15px);
    width: calc(50% - 15px);
    .right_side {
      width: 150px;
    }
    .MuiCheckbox-root {
      padding: 2px;
      padding-right: 8px;
    }
    .MuiListItemText-root {
      margin: 0;
    }
    .MuiListItemText-primary {
      white-space: normal;
    }
    .cheftza_rosh {
      padding: 8px 16px;
      /* position: sticky; */
      /* top: 0; */
      background-color: #fff;
      font-weight: 500;
      font-size: 18px;
      width: 100%;
      background-color: rgba(244, 246, 248, 0.6);
      z-index: 20;
      border-bottom: 1px solid #ddd;
      border-radius: 5px 5px 0 0;
    }
  }
  .note_submit_wrap {
    width: calc(33% - 15px);
    margin-top: 20px;
  }
  .main_note {
    border: 1px solid #e5e5ea;
    background-color: rgba(244, 246, 248, 0.4);
    border-radius: 4px;
    width: 100%;
    max-height: 100px;
    width: 100%;
  }
`;

export const RowWrap = styled.div<{ open?: boolean }>`
  background-color: ${props => (props.open ? '#f1f1f1' : '#fff')};
  :focus-within {
    background-color: #e5e5ea5c;
  }
  .notes_dropdown {
    transition: all 0.2s ease;
    min-height: ${props => (props.open ? '48px' : '0')};
    overflow: hidden;
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    svg {
      :hover {
        color: blue;
      }
    }
  }
  svg {
    cursor: pointer;
  }
  .MuiListItem-root {
    padding-left: 16;
    width: calc(100% - 24px);
  }
  .note_field {
    border: 1px solid #e5e5ea;
    width: calc(100% - 16px);
    margin-left: 16px;
    height: 32px;
    display: block;
    border-radius: 4px;
  }
`;

export const AuditPointsWrap = styled.ul`
  border-radius: 5px;
  border: 1px solid #eee;
  margin-top: 16px;
  .audit_title {
    background-color: rgba(244, 246, 248, 0.6);
    border-bottom: 1px solid #eee;
    color: #444;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    padding: 12px 16px;
  }
  ul {
    background-color: #fff;
    padding: 16px 16px 16px 24px;
  }
  li {
    list-style-type: disc;
    margin-top: 8px;
    :first-of-type {
      margin-top: 0;
    }
    > div:first-of-type {
      font-weight: 500;
    }
  }
`;
