/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { DocumentTypeProperties, DocumentTypesContext } from '../../../DocumentTypesContext';
import { apiPost } from '../../../adalConfig';
import { RowData } from '../../../pages/ClientDocumentIndex';
import { useToaster } from '../../../Hooks/toasters';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '340px',
      height: '175px',
    },
  }),
);

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedDocument: RowData | null;
  setSelectedDocument: (value: RowData | null) => void;
  selectedDocType: DocumentTypeProperties;
  setSelectedDocType: (value: DocumentTypeProperties) => void;
  refreshDatatable: () => void;
};

const UpdateDocumentTypeModal = ({
  isOpen,
  setIsOpen,
  selectedDocument,
  setSelectedDocument,
  selectedDocType,
  setSelectedDocType,
  refreshDatatable,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);

  const { docTypes } = useContext(DocumentTypesContext);

  const { successToaster, errorToaster, warningToaster } = useToaster();

  const classes = useStyles();

  const updateDocType = async () => {
    if (!selectedDocument) {
      setSelectedDocument(null);
      setIsOpen(false);
      warningToaster('No document selected');

      return;
    }

    try {
      setIsSaving(true);
      const { data: response } = await apiPost<number>('/api/documents/update-document-type', {
        documentId: selectedDocument.documentId,
        documentType: selectedDocType.value,
      });

      if (response >= 1) {
        setSelectedDocument(null);
        setIsOpen(false);
        successToaster(
          <Fragment>
            <p>Successfully updated the document type.</p>
            <p>It may take a few seconds for the page to display the updated document type</p>
          </Fragment>,
        );

        refreshDatatable();
      } else {
        errorToaster('Failed to update the document type');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      BackdropComponent={Backdrop}
    >
      <Fade in={isOpen}>
        {!!docTypes.length ? (
          <div className={classes.paper}>
            <Autocomplete
              options={docTypes}
              renderOption={(props, docType) => (
                <div key={docType.value} {...props}>
                  {docType?.label ?? ''}
                </div>
              )}
              getOptionLabel={docType => docType?.label ?? ''}
              value={selectedDocType}
              onChange={(event, docType) => setSelectedDocType(docType)}
              renderInput={params => (
                <TextField {...params} variant="outlined" label="Search For DocType" />
              )}
            />
            {!isSaving ? (
              <Grid
                container
                spacing={2}
                justifyContent="space-around"
                style={{ marginTop: '10px' }}
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedDocument(null);
                      setIsOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="primary" onClick={updateDocType}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <div css={{ margin: '20px', textAlign: 'center' }}>
                <CircularProgress disableShrink />
              </div>
            )}
          </div>
        ) : (
          <Fragment />
        )}
      </Fade>
    </Modal>
  );
};

export default UpdateDocumentTypeModal;
