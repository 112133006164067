/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { Client, FlaggedDoc } from '../../globalTypes/objects';
import { apiPost } from '../../adalConfig';
import { useClients } from '../../Hooks/useClients';
import { getDocTypes } from '../../Utils';
import Dropdown from '../../components/ui/Dropdown';
import { FlaggedDocumentWithDetail } from './FlaggedDocsList';
import { useToaster } from '../../Hooks/toasters';

type DocType = {
  label: string;
  value: number;
};
type NewFlaggedDoc = {
  clientId: number;
  documentType: number;
  loanNumber: string;
  reason: string;
  address: string;
  borrower: string;
};

type Props = {
  defaultClientId: number;
  setIsFlagDocumentOpen: Dispatch<SetStateAction<boolean>>;
  flaggedDocs: FlaggedDocumentWithDetail[];
  setFlaggedDocs: Dispatch<SetStateAction<FlaggedDoc[]>>;
  setHasPartialData: Dispatch<SetStateAction<boolean>>;
};
export default function SetFlagDocument({
  defaultClientId,
  setIsFlagDocumentOpen,
  flaggedDocs,
  setFlaggedDocs,
  setHasPartialData,
}: Props) {
  const clients = useClients();
  useEffect(() => {
    getDocTypes().then(setDocTypes);
  }, []);

  const [address, setAddress] = useState<string>('');
  const [borrower, setBorrower] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [loanNumber, setLoanNumber] = useState<string>('');
  const [docTypes, setDocTypes] = useState<DocType[]>([]);
  const [selectedDocType, setSelectedDocType] = useState<DocType | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [flaggedDocument, setFlaggedDocument] = useState<FlaggedDocumentWithDetail | null>(null);
  const [newFlaggedDocument, setNewFlaggedDocument] = useState<NewFlaggedDoc | null>(null);
  const [isValidForSubmit, setIsValidForSubmit] = useState<boolean>(false);

  useEffect(() => {
    const docType = selectedDocType?.value ?? -1;
    if (clientId || selectedDocType || loanNumber || reason || address || borrower) {
      setHasPartialData(true);
    } else {
      setHasPartialData(false);
    }
    if (clientId && docType && loanNumber && reason && address && borrower) {
      setIsValidForSubmit(true);
    } else if (!flaggedDocument) {
      setIsValidForSubmit(false);
    }
    if (clientId && docType && loanNumber) {
      const flaggedDoc = flaggedDocs.filter(
        fd =>
          fd.clientId === clientId &&
          fd.documentType === docType.valueOf() &&
          fd.loanNumber.trim() === loanNumber.trim(),
      )[0];
      if (flaggedDoc) {
        setIsValidForSubmit(true);
      }
      setFlaggedDocument(flaggedDoc);
      const newFlaggedDoc: NewFlaggedDoc = {
        clientId,
        documentType: docType,
        loanNumber,
        reason,
        address,
        borrower,
      };
      setNewFlaggedDocument(newFlaggedDoc);
    }
  }, [
    setHasPartialData,
    flaggedDocs,
    clientId,
    flaggedDocument,
    loanNumber,
    reason,
    address,
    selectedDocType,
    borrower,
  ]);

  const [status, setStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { errorToaster } = useToaster();

  const setModalClose = async () => {
    setIsFlagDocumentOpen(false);
  };

  const removeCurrentRow = fd => setFlaggedDocs(rows => rows.filter(fdRow => fdRow.id !== fd.id));
  const addRow = (newFd: FlaggedDoc) =>
    setFlaggedDocs(bindFlaggedDocsToClients([newFd], clients).concat(flaggedDocs));

  const flagDoc = async () => {
    let data;
    if (newFlaggedDocument) {
      setStatus(StatusTypes.loading);
      data = await apiPost<FlaggedDoc>(`/api/documents/flagdocument`, newFlaggedDocument);
    }
    return data;
  };
  const flagDocument = async () => {
    flagDoc()
      .then(({ data: newFd }) => {
        setStatus(StatusTypes.success);
        addRow(newFd);
      })
      .catch(e => {
        if (!e.response.data?.errors) {
          errorToaster(e.response.data.split('\n')[0]);
          return;
        }

        errorToaster(
          <Fragment>
            {e.response.data.errors.map((error, i) => (
              <p key={i}>{error.message}</p>
            ))}
          </Fragment>,
        );

        setStatus(StatusTypes.error);
      })
      .finally(() => setModalClose());
  };

  const unFlagDocument = () => {
    if (flaggedDocument) {
      apiPost(`/api/documents/unflagdocument`, flaggedDocument);
    }
    setStatus(StatusTypes.loading);
    removeCurrentRow(flaggedDocument);
    setModalClose();
  };
  if (defaultClientId) {
    setClientId(defaultClientId);
  }
  const bindFlaggedDocsToClients = (fds, clientList) =>
    fds.map(fd => {
      return {
        ...fd,
        client: clientList.find(c => fd.clientId === c.id) as Client,
        documentDetails: [],
      };
    });

  const onDocFlagged = doc => {
    setFlaggedDocs(bindFlaggedDocsToClients([doc], clients).concat(flaggedDocs));
  };

  return (
    <React.Fragment>
      <div css={{ margin: 40, maxWidth: 1300 }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Panel>
            <PanelHeader text="Flag Document" />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                height: '28rem',
              }}
            >
              <ClientSearch onChange={c => setClientId(c.id)} selectedClient={defaultClientId} />
              <div className="df jcsb aife">
                <div css={{ width: 200 }}>
                  <Dropdown
                    options={docTypes}
                    placeholder="Document Type"
                    value={selectedDocType}
                    css={{ width: 200 }}
                    onChange={docType => setSelectedDocType(docType)}
                  />
                </div>
                <div css={{ width: 330 }}>
                  <TextInput
                    placeholder="Loan Number"
                    name="loanNumber"
                    value={loanNumber}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setLoanNumber(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setAddress(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Borrower"
                    name="borrower"
                    value={borrower}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setBorrower(e.target.value)
                    }
                  />
                </div>
              </div>
              <TextInput
                placeholder="Reason"
                name="reason"
                value={reason}
                onChange={(e: React.ChangeEvent<{ value: string }>) => setReason(e.target.value)}
              />
              <div css={{ display: 'flex' }}>
                <div css={{ paddingRight: 10 }}>
                  <StatusButton onClick={setModalClose} text="Cancel" />
                </div>
                {flaggedDocument ? (
                  <StatusButton
                    status={status}
                    text="Unflag"
                    onClick={unFlagDocument}
                    styleOverrides={{ backgroundColor: 'orange', fontSize: '24' }}
                    disabled={!isValidForSubmit}
                  />
                ) : (
                  <StatusButton
                    status={status}
                    onClick={flagDocument}
                    text="Flag"
                    disabled={!isValidForSubmit}
                  />
                )}
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </React.Fragment>
  );
}
