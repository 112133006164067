/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import Panel, { PanelHeader } from '../components/ui/Panel';
import { EmailIcon } from '../components/ui/icons';
import TextInput from '../components/ui/Inputs/TextInput';
import StatusButton, { getButtonStatus } from '../components/ui/StatusButton';
import { ValidateEmail, stripStringOfWhitespaceAndSplitByComma } from '../Utils';
import { usePostData } from '../Hooks';

export default function OneOffEmail() {
  const [recipients, setRecipients] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [templateId, setTemplateId] = useState('');
  const [fromTitle, setFromTitle] = useState('');
  const { doPost: sendEmail, isSuccess, isLoading, error } = usePostData('', '');
  return (
    <Panel>
      <PanelHeader largeText text="Send One Off Email" />
      <h3>Recipients</h3>
      <textarea
        css={{ outline: '1px solid blue' }}
        value={recipients}
        placeholder="Enter emails separated by ,"
        onChange={e => setRecipients(e.target.value)}
      />
      <br />
      <TextInput
        value={fromEmail}
        label="From Email"
        placeholder="From Email"
        onChange={e => setFromEmail(e.target.value)}
      />
      <br />
      <TextInput
        value={fromTitle}
        label="From Title"
        placeholder="From Title"
        onChange={e => setFromTitle(e.target.value)}
      />
      <br />
      <TextInput
        value={templateId}
        label="Template Id"
        placeholder="Template Id"
        onChange={e => setTemplateId(e.target.value)}
      />

      <StatusButton
        status={getButtonStatus(isLoading, !!error, isSuccess)}
        disabled={!ValidateEmail(fromEmail) || !recipients || !fromTitle || !templateId}
        onClick={() =>
          sendEmail('/api/emails/sendOneOffEmail', {
            from: fromEmail,
            fromTitle,
            templateId,
            recipients: stripStringOfWhitespaceAndSplitByComma(recipients),
          })
        }
      >
        Send Email
      </StatusButton>
    </Panel>
  );
}
