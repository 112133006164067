/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DocumentsAuditedAutomaticallyDrillDown, GuildRetainedDocuments as RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import Link from '@mui/material/Link';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { roleTypes } from '../../constants';
import useFileExport from '../../components/ui/DataTable/FileExport';

type Props = {
  reportData: RowData[];
  fromDate: Date;
};

const GuildRetainedDocuments = ({ reportData, fromDate }: Props) => {
  const columns = [
    {
      field: 'documentId',
      title: 'Barcode',
      render: ({ documentId }: RowData) => (
        <Link target="_blank" href={`/documents/${documentId}`}>
          {documentId}
        </Link>
      ),
    },
    {
      field: 'loanNumber',
      title: 'Loan Number',
      render: ({ loanId, loanNumber }: RowData) => (
        <Link target="_blank" href={`/loans/${loanId}`}>
          {loanNumber}
        </Link>
      ),
    },
    {
      field: 'region',
      title: 'Region',
    },
    {
      field: 'borrower',
      title: 'Borrower',
    },
    {
      field: 'propertyAddress',
      title: 'Property Address',
    },
    {
      field: 'state',
      title: 'State',
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: (rowData: RowData) =>
        rowData.dateFunded && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'investor',
      title: 'Investor',
    },
    {
      field: 'dateCreated',
      title: 'Date Created',
      render: (rowData: RowData) =>
        rowData.dateFunded && new Date(rowData.dateCreated).toLocaleDateString(),
    },
  ];

  const fileExport = useFileExport();
  const exportDatatable = (columnsParam: any[], dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map(rowData => {
      return {
        Barcode: rowData.documentId,
        Region: rowData.region,
        LoanNumber: rowData.loanNumber,
        Borrower: rowData.borrower,
        DateFunded: new Date(rowData.dateFunded).toLocaleDateString(),
        DateCreated: new Date(rowData.dateCreated).toLocaleDateString(),
        PropertyAddress: rowData.propertyAddress,
        State: rowData.state,
        Investor: rowData.investor,
        RequiredEndorsements: rowData.requiredEndorsements,
        Hyperlink: `=HYPERLINK(\"https://internal.docprobe.net/documents/${rowData.documentId}\")`,
      };
    });

    const fileName = 'Guild Retained Documents';

    fileExport(exportData, fileName);
  };

  return (
    <DataTable<RowData>
      title="Guild Retained Documents"
      columns={columns}
      data={reportData}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportCsv: exportDatatable,
      }}
    />
  );
};

export default GuildRetainedDocuments;
