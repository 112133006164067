/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Card from '@mui/material/Card';
import {
  blueWords,
  cardWrap,
  circleWrap,
  dataPointStyle,
  month,
  number,
  subTitleStyle,
  thirdPart,
  titleStyle,
  today,
  todayWrap,
  week,
} from './styles';
import { ClientAndInvestorData, Shipping } from './types';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const grayWords = {
  fontWeight: 'bold',
  color: '#777',
};

const newqueman = {
  display: 'flex',
  marginTop: 8,
  '> div': {
    width: '50%',
  },
};

export const ShippingDisplayCard = (data: Shipping) => (
  <Card css={[cardWrap, { maxWidth: 498, marginRight: 0, zIndex: 10 }]}>
    <div css={titleStyle}>Ship</div>
    <div>
      <div css={todayWrap}>
        <div css={today}>today</div>
        <div css={number}>{data.totalDocsShippedToday.toLocaleString()}</div>
        <div css={grayWords}>{data.percentThatAreHardCopies}% that are hard copies</div>
        <div css={newqueman}>
          <div className="pr2">
            <span css={subTitleStyle}>queue</span>
            <span css={dataPointStyle}>{data.readyToBeShipped.toLocaleString()}</span>
          </div>
          <div>
            <span css={subTitleStyle}>new</span>
            <span css={dataPointStyle}>{data.readyToBeShippedAsOfToday.toLocaleString()}</span>
          </div>
        </div>
        <div css={{ borderTop: '1px solid #e5e5e3', marginTop: 16, paddingTop: 8 }}>
          <div css={grayWords}>Digitals Not Being Shipped</div>
          <div css={newqueman}>
            <div className="pr2">
              <span css={subTitleStyle}>queue</span>
              <span css={dataPointStyle}>{data.digitalsNotBeingShipped.toLocaleString()}</span>
            </div>
            <div>
              <span css={subTitleStyle}>new</span>
              <span css={dataPointStyle}>{data.digitalExclusionsAsOfToday.toLocaleString()}</span>
            </div>
          </div>
        </div>
      </div>

      <div css={[thirdPart, { paddingTop: 22 }]}>
        <div className="pr2">
          <div css={[week, { fontSize: 20, marginTop: -5 }]}>monthly goal</div>
          <div css={circleWrap}>
            <CircularProgressWithLabel percent={data.percentOfMonthlyGoal} />
          </div>
        </div>
        <div>
          <div>
            <div css={month}>month</div>
            <div css={{ fontWeight: 'bold', paddingBottom: 16, marginTop: 4 }}>
              {data.totalDocsShippedThisMonth.toLocaleString()}
            </div>
          </div>
          <div
            css={{
              paddingTop: 15,
              borderTop: '1px solid #e5e5e3',
            }}
          >
            <div css={month}>year</div>
            <div css={{ fontWeight: 'bold', marginTop: 4 }}>
              {data.totalYearlyToDate.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TopFiveInvestors data={data.topFiveInvestors} />
  </Card>
);

export const TopFiveInvestors = ({ data }: { data: ClientAndInvestorData[] }) => (
  <div css={{ borderTop: '1px solid #ccc', paddingTop: 16, marginTop: 20 }}>
    <div
      css={[
        blueWords,
        {
          lineHeight: '1.2',
          fontSize: 16,
        },
      ]}
    >
      Top 5 Investors
    </div>
    <div css={todayWrap}>
      {data.map(investorData => (
        <div
          key={investorData.investor}
          css={{
            borderTop: '1px solid #e5e5e3',
            paddingTop: 10,
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            ':first-of-type': {
              borderTop: 'none',
              marginTop: 0,
            },
          }}
        >
          <span css={{ fontSize: 14 }}>{investorData.investor}</span>
          <span css={{ fontWeight: 'bold', marginLeft: 8, fontSize: 18 }}>
            {investorData.documentCountReadyToBeShipped}
          </span>
        </div>
      ))}
    </div>
  </div>
);
