import React, { useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../components/ui/Tabs/MaterialTabPanel';
import CreateUser from './CreateUser';
import Header from '../../components/ui/Header';
import ViewUsers from './ViewUsers';
import Analytics from './Analytics';
import { AuthContext } from '../../components/AuthContext';
import { isClientAdmin } from '../../Utils';

const tabList = ['Create User', 'View Users', 'Analytics'];

export default function ClientPortalUserManagement() {
  const [tab, setTab] = useState(0);

  const { roles } = useContext(AuthContext);

  if (isClientAdmin(roles)) {
    return (
      <div>
        <Header headerText="Client Portal User Management" />
        <div className="m3">
          <Tabs
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={(event, newValue: number) => setTab(newValue)}
          >
            {tabList.map((tabLabel, i) => (
              <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
            ))}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <CreateUser />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ViewUsers />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Analytics />
          </TabPanel>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header headerText="Client Portal User Management" />
      <div className="m3">
        <ViewUsers />
      </div>
    </div>
  );
}
