/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import { Fragment } from 'react';

import Sidebar from '../components/Layout/Sidebar';
import zIndex from '../styles/zIndex';
import { useLocalStorage } from '../Hooks';

const innerContent: CSSObject = {
  position: 'relative',
  zIndex: zIndex.innerContent,
};

export default function Layout({ children, routesAuthorizedRoles }) {
  const [isOpen, openTheThing] = useLocalStorage('isSidebarOpen', true);
  const mainContent: CSSObject = {
    transition: 'all .15s',
    width: 'calc(100% - 72px)',
    marginLeft: 72,
    '@media print': { width: '100%', marginLeft: 0 },
  };

  return (
    <Fragment>
      <Sidebar handleOpen={() => openTheThing(!isOpen)} isOpen={isOpen} />
      <div
        className="main_layout_wrap_overridde"
        css={[mainContent, isOpen && { width: 'calc(100% - 256px)', marginLeft: 256 }]}
      >
        <div css={innerContent}>{children}</div>
      </div>
    </Fragment>
  );
}
