import React from 'react';

import TabLink, { TabLinkGroup } from '../../ui/Tabs/TabLink';
import { SidebarConsumer } from '../../Layout/Sidebar/SidebarContext';

export default function ImportErrorTabs() {
  return (
    <SidebarConsumer>
      {({ associationErrorCount, loanErrorCount }) => (
        <React.Fragment>
          <TabLinkGroup>
            <TabLink
              text="Association Errors"
              number={associationErrorCount}
              url="/errors/associations"
            />
            <TabLink text="Loan Errors" number={loanErrorCount} url="/errors/loans" />
          </TabLinkGroup>
        </React.Fragment>
      )}
    </SidebarConsumer>
  );
}
