/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { Filter, FilterOption } from './types';
import produce from 'immer';

type Props = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
};

const RadioFilter = ({ filter, setFilter }: Props) => {
  const filterLabel = filter.label.replace(/([A-Z])/g, ' $1');

  return (
    <div style={{ borderBottom: '1px solid #e5e5e5' }}>
      <FormControl component="fieldset" style={{ paddingLeft: 32 }}>
        <FormLabel component="legend" style={{ paddingTop: '9px', textTransform: 'capitalize' }}>
          {filterLabel}
        </FormLabel>
        <RadioGroup
          value={filter.filterOptions.find(option => option.active)?.id}
          onChange={e => {
            const newlySetFilter = produce(filter, (draft: Filter) => {
              const newActiveOptionId = parseInt(e.target.value);
              draft.filterOptions.forEach(
                option => (option.active = option.id === newActiveOptionId),
              );
            });

            setFilter(newlySetFilter);
          }}
        >
          {filter.filterOptions.map((option: FilterOption) => (
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Radio color="primary" />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default RadioFilter;
