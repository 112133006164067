/** @jsx jsx */
import { useState, useEffect, Fragment, useCallback, useMemo, memo } from 'react';
import { jsx, keyframes, CSSObject, ClassNames, css } from '@emotion/core';
import range from 'lodash/range';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import ShortId from 'shortid';
import { Processing } from '../ui/icons';
import { apiFetch } from '../../adalConfig';

const pdfWrap: CSSObject = {
  borderRadius: 4,
  display: 'inline-block',
  '::-webkit-scrollbar': {
    height: 8,
    width: 8,
    backgroundColor: 'rgba(255,255,255, 0.9)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 6,
    backgroundColor: 'rgba(8, 40, 204, 0.8)',
  },
};

const pageNumberStyles = {
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  borderRadius: 5,
  color: '#fff',
  display: 'flex',
  fontSize: 18,
  fontWeight: 'bold',
  height: 40,
  left: 24,
  overflow: 'hidden',
  position: 'absolute',
  top: 24,
  width: 60,
  justifyContent: 'center',
  zIndex: 20,
  cursor: 'pointer',
  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
};

const DocumentPage = memo<{
  pageNum: number;
  onPageSelectChange: (pageNum: number) => void;
  showPageNumbers: boolean;
  isSelected: boolean;
}>(
  ({ pageNum, onPageSelectChange, showPageNumbers, isSelected }) => {
    return (
      <div
        css={[
          {
            marginTop: 16,
            border: '11px solid gray',
            position: 'relative',
            ':first-of-type': {
              marginTop: 0,
            },
          },
          isSelected && {
            border: '2px solid #0828cc',
            borderRadius: 5,
            boxShadow:
              '0px 3px 3px -2px rgba(8,40,204,.2), 0px 3px 4px 0px rgba(8,40,204,.14), 0px 1px 8px 0px rgba(8,40,204,.12)',
            overflow: 'hidden',
          },
        ]}
      >
        {showPageNumbers && (
          <div
            onClick={() => onPageSelectChange(pageNum)}
            // @ts-ignore
            css={[pageNumberStyles, isSelected && { backgroundColor: 'rgba(8, 40, 204, 0.8)' }]}
          >
            {pageNum}
          </div>
        )}

        <Page key={ShortId.generate()} pageNumber={pageNum} scale={1.5} />
      </div>
    );
  },
  (prev, next) => prev.isSelected === next.isSelected,
);

type DocumentPdfDisplayProps = {
  onLoadSuccess?: () => void;
  docReference: number | string;
  onLoadError?: () => void;
  styleOverrides?: {};
  refreshPdf: number;
  showPageNumbers: boolean;
  onPageSelectChange: (pageNum: number) => void;
  pagesSelected: Set<number>;
  pageCount: number;
  setPageCount: (pageCount: number) => void;
  handlePasswordProtectedDoc?: () => void | undefined;
};

export default function DocumentPdfDisplay({
  onLoadSuccess,
  docReference,
  onLoadError,
  styleOverrides = {},
  refreshPdf = 0,
  showPageNumbers = false,
  onPageSelectChange = () => {},
  pagesSelected = new Set(),
  pageCount,
  setPageCount,
  handlePasswordProtectedDoc = () => undefined,
}: DocumentPdfDisplayProps) {
  const [fileUrl, setFileUrl] = useState<string>();
  const [loadingUrl, setLoadingUrl] = useState(true);
  const [isPasswordProtected, setIsPasswordProtected] = useState(false);

  useEffect(() => {
    setPageCount(0);
    setLoadingUrl(true);
    if (typeof docReference === 'number') {
      const getFileUrl = async () => {
        const url = await getRenderUrl(docReference);
        setFileUrl(url);
        setLoadingUrl(false);
      };
      getFileUrl();
    } else {
      setFileUrl(docReference);
      setLoadingUrl(false);
    }
  }, [docReference, refreshPdf]);

  return (
    <div css={[pdfWrap, { ...styleOverrides }]}>
      <Document
        // @ts-ignore
        onPassword={(callback, reason) => {
          if (handlePasswordProtectedDoc) {
            handlePasswordProtectedDoc();
            return;
          }
          switch (reason) {
            case 1: {
              // eslint-disable-next-line no-alert
              const password = prompt('Enter the password to open this PDF file.');
              callback(password);
              break;
            }
            case 2: {
              // eslint-disable-next-line no-alert
              const password = prompt('Invalid password. Please try again.');
              callback(password);
              break;
            }
            default:
          }
        }}
        file={fileUrl}
        noData={loadingUrl ? <PdfLoader /> : 'No PDF file specified.'}
        onLoadSuccess={res => {
          onLoadSuccess && onLoadSuccess();
          setPageCount(res.numPages);
        }}
        onLoadError={onLoadError}
        loading={<PdfLoader isPasswordProtected={isPasswordProtected} />}
      >
        {pageCount > 0 &&
          range(1, pageCount + 1).map(pageNum => (
            <DocumentPage
              isSelected={pagesSelected.has(pageNum)}
              onPageSelectChange={onPageSelectChange}
              key={pageNum}
              pageNum={pageNum}
              showPageNumbers={showPageNumbers}
            />
          ))}
      </Document>
    </div>
  );
}

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;

const spinner = {
  color: '#0828CC',
  animation: `${rotate} 2s infinite linear`,
  svg: {
    transform: 'scale(1.5)',
  },
};

const loaderWrap = {
  alignItems: 'center',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  width: 986,
};

function PdfLoader({ isPasswordProtected = false }) {
  return (
    <div
      // @ts-ignore
      css={loaderWrap}
    >
      {!isPasswordProtected ? (
        <Fragment>
          <div css={{ fontSize: 24, marginBottom: 24 }}>Loading PDF</div>
          <div css={spinner}>
            <Processing />
          </div>
        </Fragment>
      ) : (
        <div css={{ fontSize: 24, marginBottom: 24 }}>Password Protected!</div>
      )}
    </div>
  );
}

export const getRenderUrl = async (documentId: number) => {
  const { data: file } = await apiFetch(`/api/documents/getDocumentPdf?documentId=${documentId}`, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(file);
  return url;
};
