/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useEffect, useState } from 'react';
import { match } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import Partition from 'lodash/partition';
import { apiFetch, apiPost } from '../adalConfig';
import Header from '../components/ui/Header';
import StatusButton, { StatusTypes } from '../components/ui/StatusButton';
import { convertToBase64, isAdmin, isClientAdmin } from '../Utils';
import { cardWrap, LogoWrap } from './styles/clientStyles';
import ClientDataFields from './clientComponents/ClientDataFields';
import ClientFiles from './clientComponents/ClientFiles';
import {
  ChangeLogs,
  ChargeData,
  ChargeType,
  ClientData,
  ClientFiles as Files,
  ClientSystemAccess,
  PricingOption,
  PricingStructure as PricingStructureType,
  TierSetting,
  User,
} from './clientComponents/types';
import ClientFees from './clientComponents/ClientFees';
import { AuthContext } from '../components/AuthContext';
import ConditionalComponent from '../components/ConditionalComponent';
import { ClientReturnAddress } from '../globalTypes/objects';
import PricingStructure from './clientComponents/PricingStructure';
import { useToaster } from '../Hooks/toasters';
import ChangeLogsModal from './clientComponents/ChangeLogsModal';
import Button from '@mui/material/Button';

type Props = {
  match: match<{ clientId: string }>;
  history: any;
};

type FileData = {
  base64File: string;
  fileExtension?: string;
};

type PostData = {
  client: ClientData;
  charges: ChargeData[];
  pricingStructure: Partial<PricingStructureType>;
  coop: string;
  podNumber: number | null;
  closingInstructions?: FileData;
  signedAgreement?: FileData;
  authorizationLetter?: FileData;
  clientSystemAccess: ClientSystemAccess;
  accountRep: User;
  auditor: User;
  clientServiceCoordinator: User;
};

const initialClientDataState: ClientData = {
  company: '',
  firstName: '',
  lastName: '',
  address: '',
  poBox: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  fax: '',
  email: '',
  invoiceEmail: '',
  invoiceEmailCC: '',
  clientInbox: '',
  contactName: '',
  startDate: null,
  podNumber: null,
  arSupport: '',
  requireUploadToEncompass: false,
  inactive: false,
  dropboxFolder: '',
  isCoop: false,
  coop: '',
  los: '',
  documentTransferMethod: '',
  reportFrequency: '',
  reportSource: '',
  logo: '',
  closingInstructions: '',
  signedAgreement: '',
  authorizationLetter: '',
  followupsRange: 15,
  baselineLoansPerMonth: 0,
  agedPipeline: false,
  noFollowupPackage: false,
  returnAddress: ClientReturnAddress.OceanAvenue1133,
  loanDataContactFirstName: '',
  loanDataContactLastName: '',
  loanDataContactEmail: '',
  pricingStructure: undefined,
  isCancelled: false,
  serviceLevelId: 1,
};

const getChargeTypes = async (): Promise<ChargeType[]> => {
  const { data } = await apiFetch<ChargeType[]>('/api/feeTypes');
  return data;
};

const initialChargesState = async (): Promise<ChargeData[][]> => {
  const chargeTypes: ChargeData[] = (await getChargeTypes()).map(chargeType => ({
    feeTypeId: chargeType.id,
    description: chargeType.description,
    amount: 0,
  }));

  return Partition(chargeTypes, chargeType => chargeType.description.startsWith('New File - '));
};

const initialClientFilesState: Files = {
  authorizationLetter: undefined,
  closingInstructions: undefined,
  signedAgreement: undefined,
};

const initialClientSystemAccessState: ClientSystemAccess = {
  link: '',
  username: '',
  password: '',
};

const initialAccountRepState: User = {
  id: '',
  username: '',
};

const initialAuditorState: User = {
  id: '',
  username: '',
};

const initialClientServiceCoordinatorState: User = {
  id: '',
  username: '',
  displayName: '',
};

const defaultTierSettings: TierSetting[] = [
  {
    from: undefined,
    to: undefined,
    price: 0,
  },
];

const clientChangeLogs = async (clientId: number) => {
  const { data } = await apiFetch<ChangeLogs[]>(
    `/api/clients/client-change-logs?clientId=${clientId}`,
  );

  return data;
};

const ClientForm = ({ match, history }: Props) => {
  const [clientData, setClientData] = useState<ClientData>({ ...initialClientDataState });
  const [charges, setCharges] = useState<ChargeData[][]>([]);
  const [pricingOption, setPricingOption] = useState<PricingOption | undefined>(undefined);
  const [tierSettings, setTierSettings] = useState<TierSetting[]>(defaultTierSettings);
  const [clientFiles, setClientFiles] = useState<Files>(initialClientFilesState);
  const [systemAccess, setSystemAccess] = useState<ClientSystemAccess>(
    initialClientSystemAccessState,
  );
  const [accountRep, setAccountRep] = useState(initialAccountRepState);
  const [auditor, setAuditor] = useState(initialAuditorState);
  const [clientServiceCoordinator, setClientServiceCoordinator] = useState(
    initialClientServiceCoordinatorState,
  );
  const [loadingStatus, setLoadingStatus] = useState(StatusTypes.initial);
  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);
  const [changeLogs, setChangeLogs] = useState<ChangeLogs[] | null>(null);

  const { roles } = useContext(AuthContext);

  const { successToaster, errorToaster } = useToaster();

  const isNewClient = !match.params.clientId;

  const getClientData = async () => {
    setIsLoadingClient(true);
    try {
      const {
        data: {
          client,
          coop,
          podNumber,
          charges: fees,
          pricingStructure,
          clientSystemAccess,
          accountRep,
          auditor,
          clientServiceCoordinator,
        },
      } = await apiFetch<PostData>(`/api/clients/ClientData/${match.params.clientId}`);

      const valueOrDefault = (value, type: 'boolean' | 'date' | 'number' | 'string' | 'object') => {
        if (value) {
          return type === 'boolean' ? Boolean(value) : type === 'date' ? new Date(value) : value;
        }
        switch (type) {
          case 'boolean':
            return false;
          case 'number':
            return 0;
          case 'string':
            return '';
          case 'date':
            return null;
          case 'object':
            return {};
          default:
            return undefined;
        }
      };

      setClientData({
        company: valueOrDefault(client.company, 'string'),
        firstName: valueOrDefault(client.firstName, 'string'),
        lastName: valueOrDefault(client.lastName, 'string'),
        address: valueOrDefault(client.address, 'string'),
        poBox: valueOrDefault(client.poBox, 'string'),
        city: valueOrDefault(client.city, 'string'),
        state: valueOrDefault(client.state, 'string'),
        zip: valueOrDefault(client.zip, 'string'),
        phone: valueOrDefault(client.phone, 'string'),
        fax: valueOrDefault(client.fax, 'string'),
        email: valueOrDefault(client.email, 'string'),
        arSupport: valueOrDefault(client.arSupport, 'string'),
        invoiceEmail: valueOrDefault(client.invoiceEmail, 'string'),
        invoiceEmailCC: valueOrDefault(client.invoiceEmailCC, 'string'),
        clientInbox: valueOrDefault(client.clientInbox, 'string'),
        contactName: valueOrDefault(client.contactName, 'string'),
        startDate: valueOrDefault(client.startDate, 'string'),
        podNumber: valueOrDefault(podNumber, 'number'),
        requireUploadToEncompass: valueOrDefault(client.requireUploadToEncompass, 'boolean'),
        inactive: valueOrDefault(client.inactive, 'boolean'),
        dropboxFolder: valueOrDefault(client.dropboxFolder, 'string'),
        isCoop: valueOrDefault(coop, 'boolean'),
        coop: valueOrDefault(coop, 'string'),
        los: valueOrDefault(client.los, 'string'),
        documentTransferMethod: valueOrDefault(client.documentTransferMethod, 'string'),
        reportFrequency: valueOrDefault(client.reportFrequency, 'string'),
        reportSource: valueOrDefault(client.reportSource, 'string'),
        logo: valueOrDefault(client.logo, 'string'),
        closingInstructions: valueOrDefault(client.closingInstructions, 'string'),
        signedAgreement: valueOrDefault(client.signedAgreement, 'string'),
        authorizationLetter: valueOrDefault(client.authorizationLetter, 'string'),
        followupsRange: valueOrDefault(client.followupsRange, 'number'),
        baselineLoansPerMonth: valueOrDefault(client.baselineLoansPerMonth, 'number'),
        agedPipeline: valueOrDefault(client.agedPipeline, 'boolean'),
        noFollowupPackage: valueOrDefault(client.noFollowupPackage, 'boolean'),
        returnAddress: client.returnAddress,
        loanDataContactFirstName: valueOrDefault(client.loanDataContactFirstName, 'string'),
        loanDataContactLastName: valueOrDefault(client.loanDataContactLastName, 'string'),
        loanDataContactEmail: valueOrDefault(client.loanDataContactEmail, 'string'),
        pricingStructure: valueOrDefault(pricingStructure, 'object'),
        isCancelled: valueOrDefault(client.isCancelled, 'boolean'),
        serviceLevelId: valueOrDefault(client.serviceLevelId, 'number'),
      });

      const feesMap = fees.reduce((map, feeData) => {
        map.set(feeData.feeTypeId, feeData);
        return map;
      }, new Map<number, ChargeData>());

      const initialCharges = await initialChargesState();
      setCharges(
        initialCharges.map(chargeGroup =>
          chargeGroup.map(chargeType => feesMap.get(chargeType.feeTypeId) || chargeType),
        ),
      );

      setPricingOption({
        id: pricingStructure?.id ?? 0,
        value: pricingStructure?.pricingOptionValue,
      });
      setTierSettings(pricingStructure?.tierSettings ?? defaultTierSettings);

      setSystemAccess({
        link: valueOrDefault(clientSystemAccess?.link, 'string'),
        username: valueOrDefault(clientSystemAccess?.username, 'string'),
        password: valueOrDefault(clientSystemAccess?.password, 'string'),
      });

      setAccountRep(valueOrDefault(accountRep, 'object'));
      setAuditor(valueOrDefault(auditor, 'object'));
      setClientServiceCoordinator(valueOrDefault(clientServiceCoordinator, 'object'));

      setIsLoadingClient(false);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }

      history.push('/400');
    }
  };

  useEffect(() => {
    if (isNewClient) {
      initialChargesState().then(setCharges);
    } else {
      getClientData();
    }
  }, [isNewClient]);

  const onSubmit = async () => {
    setLoadingStatus(StatusTypes.loading);
    const clonedClientData = { ...clientData };
    const { coop } = clientData;
    const { podNumber } = clientData;

    delete clonedClientData.isCoop;
    delete clonedClientData.coop;

    const postData: PostData = {
      client: clonedClientData,
      charges: charges.flat(),
      pricingStructure: {
        id: pricingOption?.id,
        pricingOption: pricingOption?.id,
        tierSettings,
      },
      coop,
      podNumber,
      clientSystemAccess: systemAccess,
      accountRep,
      auditor,
      clientServiceCoordinator,
    };

    if (clientFiles.closingInstructions) {
      const fileExtension = clientFiles.closingInstructions.name.split('.').pop();
      const base64File = await convertToBase64(new Blob([clientFiles.closingInstructions]));
      postData.closingInstructions = { fileExtension, base64File };
    }

    if (clientFiles.signedAgreement) {
      const fileExtension = clientFiles.signedAgreement.name.split('.').pop();
      const base64File = await convertToBase64(new Blob([clientFiles.signedAgreement]));
      postData.signedAgreement = { fileExtension, base64File };
    }

    if (clientFiles.authorizationLetter) {
      const fileExtension = clientFiles.authorizationLetter.name.split('.').pop();
      const base64File = await convertToBase64(new Blob([clientFiles.authorizationLetter]));
      postData.authorizationLetter = { fileExtension, base64File };
    }

    try {
      const url = isNewClient ? '/api/Clients/NewClient' : `/api/Clients/${match.params.clientId}`;
      const { data } = await apiPost<number>(url, postData);

      if (isNewClient) {
        setClientData({ ...initialClientDataState });
        setCharges(await initialChargesState());
        setSystemAccess(initialClientSystemAccessState);
        setAccountRep(initialAccountRepState);
        setAuditor(initialAuditorState);
        setClientServiceCoordinator(initialClientServiceCoordinatorState);

        if (data > 0) {
          successToaster('Successfully added the client data');
          history.push(`/clients/${data}`);
        } else {
          errorToaster('Failed to add the client data');
        }
      } else if (data === 1) {
        successToaster('Successfully edited the client data');
        setLoadingStatus(StatusTypes.success);

        setTimeout(() => location.reload(), 1500);
      } else {
        errorToaster('Failed to edit the client data');
        setLoadingStatus(StatusTypes.error);
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data?.errors) {
          errorToaster(
            <Fragment>
              {e.response.data.errors.map((error, i) => {
                return <p key={i}>{error.message}</p>;
              })}
            </Fragment>,
          );
        } else {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        }
      } else {
        errorToaster(e.message);
      }

      setLoadingStatus(StatusTypes.error);
    }
  };

  if (isLoadingClient) {
    return (
      <div className="center-in-parent">
        <CircularProgress disableShrink size={60} />
      </div>
    );
  }

  return (
    <Fragment>
      <Header headerText={!isNewClient ? clientData.company : 'Add New Client'} fixed />
      <div
        css={{
          padding: 40,
          marginTop: 64,
          backgroundColor: '#fff',
          position: 'relative',
        }}
      >
        {isAdmin(roles) && match.params.clientId && (
          <div style={{ position: 'absolute', top: 0, right: 40 }}>
            <Button
              onClick={async () => {
                const logs = await clientChangeLogs(parseInt(match.params.clientId));
                setChangeLogs(logs);
              }}
            >
              View Change History
            </Button>
          </div>
        )}
        {clientData.logo && (
          <LogoWrap>
            <img src={clientData.logo} />
          </LogoWrap>
        )}
        <div css={[cardWrap, { marginTop: 0, position: 'relative' }]}>
          <ClientDataFields
            clientData={clientData}
            setClientData={setClientData}
            isNewClient={isNewClient}
            systemAccess={systemAccess}
            setSystemAccess={setSystemAccess}
            accountRep={accountRep}
            setAccountRep={setAccountRep}
            auditor={auditor}
            setAuditor={setAuditor}
            clientServiceCoordinator={clientServiceCoordinator}
            setClientServiceCoordinator={setClientServiceCoordinator}
          />
          <hr />
          <ClientFiles
            clientFiles={clientFiles}
            setClientFiles={setClientFiles}
            clientData={clientData}
            clientId={parseInt(match.params.clientId ?? 0)}
          />
        </div>

        <ConditionalComponent display={isClientAdmin(roles)}>
          <div css={[cardWrap, { marginTop: 40 }]}>
            <ClientFees charges={charges} setCharges={setCharges} isNewClient={isNewClient} />
          </div>
          <div css={[cardWrap, { marginTop: 40 }]}>
            <PricingStructure
              isNewClient={isNewClient}
              pricingOption={pricingOption}
              setPricingOption={setPricingOption}
              tierSettings={tierSettings}
              setTierSettings={setTierSettings}
            />
          </div>
        </ConditionalComponent>

        <div css={{ marginTop: 24 }}>
          <StatusButton
            text="Submit"
            status={loadingStatus}
            disabled={!clientData.company || !isClientAdmin(roles)}
            onClick={onSubmit}
          />
        </div>
      </div>

      <ChangeLogsModal changeLogsList={changeLogs} clearChangeLogs={() => setChangeLogs(null)} />
    </Fragment>
  );
};

export default ClientForm;
