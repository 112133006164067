/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Link } from '@mui/material';
import { LoanInformation as LoanInfo } from './ImportErrorsUtils';

import colors from '../../../styles/colors';
import GetAppIcon from '@mui/icons-material/GetApp';
import { downloadFile } from '../../../Utils';
import { ContactType } from '../../../globalTypes/objects';
import { useToaster } from '../../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';

// #region  CSS

const mainWrap: CSSObject = {
  color: colors.black,
  fontSize: 14,
  marginTop: 40,
  width: '100%',
};

const header: CSSObject = {
  fontWeight: 'bold',
  fontSize: 16,
  marginBottom: 24,
};

const columnHeader: CSSObject = {
  color: colors.grayDark,
  marginBottom: 8,
};

const bottomRowHeader: CSSObject = {
  ...columnHeader,
  marginTop: 32,
};

// #endregion

type LoanInformationProps = {
  loanInformation: LoanInfo[];
  includeMoreInfo?: boolean;
  styles?: CSSObject;
  associationId: number;
  type: ContactType;
};
export default function LoanInformation({
  loanInformation,
  includeMoreInfo,
  styles,
  associationId,
  type,
}: LoanInformationProps) {
  const [isExporting, setIsExporting] = useState(false);

  const { errorToaster } = useToaster();

  const exportImportSpreadsheet = async () => {
    setIsExporting(true);
    try {
      await downloadFile(
        `/api/associations/import-spreadsheet?id=${associationId}&recordType=${
          type === ContactType.TitleCompany ? 1 : 2
        }`,
        'Import Spreadsheet.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        true,
      );
    } catch (e) {
      errorToaster(`Failed to export. ${e.message}`);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div css={[mainWrap, styles]}>
      <p css={header}>Loan information</p>
      <div style={{ display: 'flex', gap: '30px' }}>
        <div>
          <div css={columnHeader}>Spreadsheet</div>
          {loanInformation.map(info => (
            <React.Fragment key={info.client}>
              <div>{info.client}</div>
              <div>{info.spreadsheetDate}</div>
            </React.Fragment>
          ))}
          {includeMoreInfo ? (
            <React.Fragment>
              <div css={bottomRowHeader}>Borrower name</div>
              {loanInformation.map(info => (
                <React.Fragment key={info.client}>
                  <div>{info.borrower}</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : null}
        </div>
        <div>
          <div css={columnHeader}>Loan number</div>
          {loanInformation.map(info => (
            <React.Fragment key={info.client}>
              {info.loanNumber === 'Multiple' ? (
                <div>{info.loanNumber}</div>
              ) : (
                <Link target="_blank" href={`/loans/${info.loanId}`}>
                  {info.loanNumber}
                </Link>
              )}
            </React.Fragment>
          ))}
          {includeMoreInfo ? (
            <React.Fragment>
              <div css={bottomRowHeader}>Property address</div>
              {loanInformation.map(info => (
                <React.Fragment key={info.client}>
                  <div>{info.propertyAddress}</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ) : null}
        </div>
        <div>
          <div css={columnHeader}>Import Spreadsheet</div>
          {isExporting ? (
            <div className="df jcc">
              <CircularProgress size="20" disableShrink />
            </div>
          ) : (
            <GetAppIcon
              style={{ cursor: 'pointer', display: 'block', margin: 'auto' }}
              onClick={exportImportSpreadsheet}
            />
          )}
        </div>
      </div>
    </div>
  );
}
