/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { AssociationData as RowData } from './types';

type Props = {
  investorAssociations: RowData[];
};

const columns = [
  {
    field: 'client',
    title: 'Client',
  },
  {
    field: 'appNumbers',
    title: 'App Numbers',
    render: (associationData: RowData) => (
      <div css={{ width: 300 }}>{associationData.appNumbers}</div>
    ),
  },
  {
    field: 'investorAssociation',
    title: 'Investor Association',
  },
  {
    field: 'investor',
    title: 'Investor',
  },
  {
    field: 'associatedBy',
    title: 'Associated By',
  },
  {
    field: 'associatedAt',
    title: 'Associated At',
    render: (associationData: RowData) =>
      new Date(associationData.associatedAt).toLocaleDateString(),
  },
];

const AssociationData = ({ investorAssociations }: Props) => {
  return (
    <DataTable<RowData>
      title="Investor Associations"
      columns={columns}
      data={investorAssociations}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Investor Associations',
      }}
    />
  );
};

export default AssociationData;
