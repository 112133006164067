import React, { Fragment } from 'react';

const AppVersion = () => (
  <Fragment>
    <h1>Branch: {BRANCH}</h1>
    <h1>Commit Hash: {COMMITHASH}</h1>
    <h1>Details: {VERSION}</h1>
  </Fragment>
);

export default AppVersion;
