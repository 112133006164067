/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { DocumentTypeProperties, DocumentTypesContext } from '../../DocumentTypesContext';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { v7 as uuidV7 } from 'uuid';
import produce from 'immer';
import { useToaster } from '../../Hooks/toasters';
import FormLabel from '@mui/material/FormLabel';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    minHeight: '150px',
    maxHeight: '90%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 3),
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: '16px',
    height: '100%',
  },
  noteContainer: {
    border: '1px solid black',
    borderRadius: '6px',
    padding: '4px',
  },
  textareaWrap: {
    width: '100%',
    resize: 'none',
  },
}));

type Props = {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (settings: Setting[], noteText: string) => void;
  isSaving: boolean;
};

export type Setting = {
  documentType: DocumentTypeProperties | null;
  isRequired: boolean;
  key: string;
};

const defaultSetting = () => ({ documentType: null, isRequired: false, key: uuidV7() } as Setting);

const updatedSettings = (newSetting: Setting, settings: Setting[]) => {
  return produce(settings, draft => {
    draft.forEach(filter => {
      if (filter.key === newSetting.key) {
        filter.documentType = newSetting.documentType;
        filter.isRequired = newSetting.isRequired;
      }
    });
  });
};

const RequiredDocumentSettings = ({ setIsModalOpen, onSubmit, isSaving }: Props) => {
  const [settings, setSettings] = useState<Setting[]>([defaultSetting()]);
  const [noteText, setNoteText] = useState('');

  const hasValidSetting = useMemo(() => settings.some(x => x.documentType !== null), [settings]);

  const { docTypes: documentTypes } = useContext(DocumentTypesContext);
  const classes = useStyles();
  const { errorToaster } = useToaster();

  const updateSetting = (newSetting: Setting) => {
    const updated = updatedSettings(newSetting, settings);
    setSettings(updated);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.mainContainer}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          {settings.map((setting, i, array) => (
            <div className="df jcsb aic" key={setting.key}>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <div>
                  <Autocomplete
                    sx={{ width: 300 }}
                    autoHighlight
                    options={documentTypes}
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField {...params} label="Select Document Type" variant="outlined" />
                    )}
                    onChange={(_, value) => {
                      const settingExists = settings.some(
                        setting => value !== null && setting.documentType === value,
                      );

                      if (settingExists) {
                        errorToaster('A setting for this document type is already set');
                        value = null;
                      }

                      updateSetting({ ...setting, documentType: value });
                    }}
                    value={setting.documentType}
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => updateSetting({ ...setting, isRequired: e.target.checked })}
                      />
                    }
                    label="Require"
                    value={setting.isRequired}
                  />
                </div>
              </div>
              <div>
                {array.length - 1 === i && (
                  <AddBoxIcon
                    style={{ cursor: 'pointer' }}
                    fontSize="large"
                    color="action"
                    onClick={() =>
                      setSettings(x => [
                        ...x.filter(x => x.documentType !== null),
                        defaultSetting(),
                      ])
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <FormControl sx={{ width: 1 }}>
          <FormLabel>Additional note text</FormLabel>
          <div className={classes.noteContainer}>
            <TextareaAutosize
              minRows={3}
              placeholder="Type here"
              value={noteText}
              onChange={e => setNoteText(e.target.value)}
              className={classes.textareaWrap}
            />
          </div>
        </FormControl>

        <div className="df jcfe mt2" style={{ gap: '8px' }}>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <LoadingButton
            color="primary"
            loading={isSaving}
            variant="contained"
            disabled={!hasValidSetting}
            onClick={() =>
              onSubmit(
                settings.filter(setting => setting.documentType !== null),
                noteText,
              )
            }
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};

export default RequiredDocumentSettings;
