/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import snakeCase from 'lodash/snakeCase';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import SearchSelect from '../../components/ui/SearchSelect';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import { apiFetch } from '../../adalConfig';
import { errorAlert } from '../../components/ui/Alert';
import { downloadFile } from '../../Utils';

const searchSelectStyles = {
  width: '230px',
  marginRight: '24px',
};

export default class extends Component {
  state = {
    clients: [],
    clientId: undefined,
    loadingStatus: StatusTypes.initial,
    isAllClientsReportLoading: false,
  };

  async componentDidMount() {
    const { data: clients } = await apiFetch('/api/clients/getAll');
    this.setState({ clients: clients.map(c => ({ id: c.id, name: c.company })) });
  }

  getReport = (url, report) => async () => {
    const { clientId, clients } = this.state;

    const client = clients.find(({ id }) => id === clientId);

    try {
      this.setState({ loadingStatus: StatusTypes.loading });

      url += url.includes('?') ? `&clientId=${clientId}` : `/?clientId=${clientId}`;

      await downloadFile(url, `${client.name}_${snakeCase(report)}.csv`);

      this.setState({ loadingStatus: StatusTypes.success });
    } catch (e) {
      this.setState({ loadingStatus: StatusTypes.error });
      throw new Error(e);
    }
  };

  downloadAllClientsReport = async () => {
    const { setAlert } = this.props;

    try {
      this.setState({ isAllClientsReportLoading: true });
      await downloadFile(
        '/api/reports/outstandingLoans?clientId=0',
        'all_clients_outstandingloans.csv',
      );
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        setAlert(errorAlert(errorMessage || e.message));
      } else {
        setAlert(errorAlert(e.message));
      }
    } finally {
      this.setState({ isAllClientsReportLoading: false });
    }
  };

  allClientsReport = () => {
    const { url } = this.props;
    if (url !== '/api/reports/outstandingLoans') {
      return <></>;
    }

    const { isAllClientsReportLoading } = this.state;

    return isAllClientsReportLoading ? (
      <div className="center-in-parent" style={{ width: '100px' }}>
        <CircularProgress disableShrink size={25} />
      </div>
    ) : (
      <Button
        onClick={async e => {
          e.preventDefault();
          await this.downloadAllClientsReport();
        }}
      >
        All Clients
        <ArrowDownwardIcon style={{ paddingLeft: '3px', color: 'blue' }} />
      </Button>
    );
  };

  render() {
    const { url, report, children } = this.props;
    const { clients, clientId, loadingStatus } = this.state;
    return (
      <Panel styles={{ margin: 40 }}>
        <PanelHeader largeText text={report} />
        <div css={{ marginTop: 24 }}>
          <SearchSelect
            styleOverrides={searchSelectStyles}
            options={clients.map(client => ({
              label: client.name,
              value: client.id,
            }))}
            placeholder="Search clients"
            {...(clientId &&
              clients.length !== 0 && {
                defaultValue: {
                  label: clients.find(c => c.id === clientId)?.name,
                  value: clientId,
                },
              })}
            onChange={selection => {
              if (selection)
                this.setState({ clientId: selection.value, loadingStatus: StatusTypes.initial });
            }}
          />
          {children}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 32,
            }}
          >
            <StatusButton
              text="Download"
              disabled={!clientId}
              status={loadingStatus}
              onClick={this.getReport(url, report)}
            />
            {this.allClientsReport()}
          </div>
        </div>
      </Panel>
    );
  }
}
