export type SalesRep = {
  azureId: string;
  username: string;
};

export type OverviewRowData = {
  lenderId: number;
  lender: string;
  commissionStartDate: Date;
  baselineLoansPerMonth: number;
  averageLoansPreYearOne: number;
  totalLoansForYearOne: number;
  totalLoansForYearTwo: number;
  chargeForPreYearOne: number;
  chargeForYearOne: number;
  chargeForYearTwo: number;
  commissionForYearOne: number;
  commissionForYearTwo: number;
  paymentType: string;
};

export type DrillDownRowData = {
  lender: string;
  commissionStartDate: Date;
  baselineLoansPerMonth: number;
  monthAndYear: Date;
  loansPerMonth: number;
  revenue: number;
  commission: number;
};

export enum ModalStatus {
  opened,
  closed,
  loading,
}
