import React, { Component } from 'react';

import ImportForm from '../components/Imports/NewImport/ImportForm';
import ImportSuccess from '../components/Imports/NewImport/ImportSuccess';
import ImportError from '../components/Imports/NewImport/ImportError';
import { SidebarConsumer } from '../components/Layout/Sidebar/SidebarContext';
import { ClientsContext } from '../ClientsContext';

type NewImportProps = {
  handleExit: () => void;
};

export type NewImportState = {
  importCount: number | undefined;
  errorMessage: string | JSX.Element | undefined;
};

export default class NewImport extends Component<NewImportProps, NewImportState> {
  state = {
    importCount: undefined,
    errorMessage: undefined,
  };

  handleSubmit = ({ importCount, errorMessage }: Partial<NewImportState>) => {
    this.setState({ importCount, errorMessage });
  };

  reset = () => {
    this.setState({ importCount: undefined, errorMessage: undefined });
  };

  render() {
    const { errorMessage, importCount } = this.state;
    const { handleExit } = this.props;
    if (errorMessage) {
      return <ImportError errorMessage={errorMessage!} reset={this.reset} />;
    }
    if (importCount) {
      return <ImportSuccess importCount={importCount!} handleExit={handleExit} />;
    }
    return (
      <SidebarConsumer>
        {({ updateErrorCount }) => (
          <ClientsContext.Consumer>
            {clients => (
              <ImportForm
                onSubmit={this.handleSubmit}
                handleExit={handleExit}
                updateErrorCount={updateErrorCount}
                clients={clients}
              />
            )}
          </ClientsContext.Consumer>
        )}
      </SidebarConsumer>
    );
  }
}
