/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import DatePicker from 'react-datepicker';
import isValid from 'date-fns/isValid';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import toDate from 'date-fns/toDate';

import colors from '../../../styles/colors';
import TextInput from '../../ui/Inputs/TextInput';
import Dropdown from '../../ui/Dropdown';
import ZipInput from '../../ui/Inputs/ZipInput';
import states from '../../../states';

// #region css
const boxStyles = {
  border: `1px solid ${colors.grayLight}`,
  marginTop: 32,
  padding: 32,
};
// #endregion

export default class StepThreeNotFound extends Component {
  state = {
    loanNumber: '',
    dateDocumentsDrawn: undefined,
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    zip: '',
  };

  componentDidMount() {
    const { digital, docType, handleChange } = this.props;
    // disable validation for digital mortgage and policies
    const isDigitalMortgageOrPolicy = digital && (docType === 'Mortgage' || docType === 'Policy');
    handleChange({ valid: isDigitalMortgageOrPolicy, data: {} });
  }

  componentDidUpdate(_, prevState) {
    if (prevState !== this.state) {
      const {
        loanNumber,
        dateDocumentsDrawn,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        zip,
      } = this.state;
      const valid = this.validate(this.props.docType);
      this.props.handleChange({
        valid,
        data: valid
          ? {
              loanNumber,
              dateDocumentsDrawn,
              propertyStreet: `${addressLineOne} ${addressLineTwo}`,
              propertyCity: city,
              propertyState: state,
              propertyZip: zip,
            }
          : {},
      });
    }
  }

  handleChange = (value, field) => {
    this.setState({ [field]: value });
  };

  validate = docType => {
    const { dateDocumentsDrawn, loanNumber, addressLineOne } = this.state;
    if (dateDocumentsDrawn) {
      const asDate = toDate(dateDocumentsDrawn);
      if (
        !isValid(dateDocumentsDrawn) ||
        !isBefore(asDate, new Date('2100-01-01')) ||
        !isAfter(asDate, new Date('2000-01-01'))
      ) {
        return false;
      }
    }

    const { digital } = this.props;
    const isMortgageOrPolicy = docType === 'Mortgage' || docType === 'Policy';
    if (digital) {
      // we don't require any info for digital mortgage and policy
      // we do require for other doctypes (but we don't care about dateDocsDrawn)
      return isMortgageOrPolicy || !isEmpty(loanNumber) || !isEmpty(addressLineOne);
    }
    // if we are physical we validate ddd (& the other data for other docTypes)
    return isMortgageOrPolicy
      ? !!dateDocumentsDrawn
      : !!dateDocumentsDrawn && (!isEmpty(loanNumber) || !isEmpty(addressLineOne));
  };

  render() {
    const { digital } = this.props;
    const {
      loanNumber,
      dateDocumentsDrawn,
      addressLineOne,
      addressLineTwo,
      city,
      state,
      zip,
    } = this.state;
    return (
      <div css={boxStyles}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <TextInput
            labelOverrides={{ width: '48%' }}
            label="Loan number"
            value={loanNumber}
            onChange={e => this.handleChange(e.target.value, 'loanNumber')}
          />
          {!digital && (
            <DatePicker
              placeholderText="mm/dd/yyyy"
              customInput={<TextInput label="Date documents drawn (Mortgage date)" />}
              selected={dateDocumentsDrawn}
              showMonthDropdown
              showYearDropdown
              onChange={date => this.handleChange(date, 'dateDocumentsDrawn')}
            />
          )}
        </div>
        <div css={{ marginTop: 32 }}>
          <span css={{ fontWeight: 'bold' }}>Property address</span>
          <TextInput
            labelOverrides={{ width: '100%', paddingTop: 8 }}
            label="Street"
            value={addressLineOne}
            onChange={e => this.handleChange(e.target.value, 'addressLineOne')}
          />
          <TextInput
            labelOverrides={{ width: '100%', paddingTop: 8 }}
            placeholder="Attention, suite, unit, building, floor, etc."
            value={addressLineTwo}
            onChange={e => this.handleChange(e.target.value, 'addressLineTwo')}
          />
          <TextInput
            labelOverrides={{ width: '100%', paddingTop: 32 }}
            label="City"
            value={city}
            onChange={e => this.handleChange(e.target.value, 'city')}
          />
          <div css={{ paddingTop: 32, display: 'inline-flex' }}>
            <div css={{ width: 288 }}>
              State
              <Dropdown
                css={{ paddingTop: 8 }}
                placeholder="Select state"
                options={Object.entries(states).map(([k, v]) => ({
                  label: v,
                  value: k,
                }))}
                {...(state && {
                  value: { label: states[state], value: state },
                })}
                onChange={selection => this.handleChange(selection.value, 'state')}
              />
            </div>
            <ZipInput
              labelOverrides={{ width: '50%', marginLeft: 16 }}
              label="Zip"
              value={zip}
              onChange={e => this.handleChange(e.target.value, 'zip')}
            />
          </div>
        </div>
      </div>
    );
  }
}
