/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
/* eslint-disable react/sort-comp */
/* eslint-disable no-dupe-class-members */
import React, { useState, createRef } from 'react';
import Dropzone, { DropEvent, DropzoneRef } from 'react-dropzone';

import Button from './Button';
import FileUploadIcon, { FaceType } from '../Imports/FileUploadIcon';
import Icon, { IconType } from './Icon';
import colors from '../../styles/colors';

const dropzoneStyles: CSSObject = {
  border: 'dashed 2px #d1d1d6',
  borderRadius: '7px',
  height: '410px',
  margin: '32 auto 0 auto',
  position: 'relative',
  textAlign: 'center',
  width: '100%',
  ':focus-within': {
    border: `dashed 2px ${colors.blue}`,
    outline: 0,
  },
};

const dropzoneContent: CSSObject = {
  display: 'inline-block',
  left: '50%',
  position: 'absolute',
  top: '50%',
  transform: 'translateX(-50%) translateY(-50%) ',
  width: 200,
};

const h3: CSSObject = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginTop: '24px',
  marginBottom: '8px',
};

const filename: CSSObject = {
  display: 'inline-block',
};

const xImage: CSSObject = {
  transform: 'translateY(5px)',
  paddingLeft: 12,
  marginTop: '-20px',
  cursor: 'pointer',
  display: 'inline-block',
};

const iconCircle: CSSObject = {
  height: 200,
  width: 200,
  display: 'flex',
  alignItems: 'center',
  img: { maxHeight: 130 },
};

const p: CSSObject = {
  fontSize: '16px',
  margin: 0,
};

const errorMessage: CSSObject = {
  fontSize: '16px',
  color: 'red',
};

type FileDropperProps = {
  onChange: (file: File | undefined) => any;
  clientMismatch?: boolean;
  acceptableFileTypes: string;
  file: File | undefined;
  noIcon?: boolean;
  buttonDisabled?: boolean;
  children?: JSX.Element;
  styles?: object;
};

export default function FileDropper({
  onChange,
  clientMismatch,
  acceptableFileTypes,
  file,
  noIcon,
  styles,
  buttonDisabled = false,
  children,
}: FileDropperProps) {
  const [hovering, setHovering] = useState(false);

  const dropzoneRef = createRef<DropzoneRef>();

  const handleChange = (acceptedFiles?: File[], rejectedFiles?: File[], event?: DropEvent) => {
    if (!onChange) {
      throw new Error('Component requires onChange');
    }
    setHovering(false);
    acceptedFiles && acceptedFiles[0] ? onChange(acceptedFiles[0]) : onChange(undefined);
  };
  const iconType = hovering ? (
    <FileUploadIcon type={FaceType.Hover} styles={iconCircle} />
  ) : (
    <FileUploadIcon type={FaceType.NoFile} styles={iconCircle} />
  );
  return (
    <Dropzone
      onDrop={handleChange}
      accept={acceptableFileTypes}
      ref={dropzoneRef}
      onDragEnter={() => setHovering(true)}
      onDragLeave={() => setHovering(false)}
      noClick
    >
      {({ getInputProps, getRootProps }) => (
        <div {...getRootProps({ css: [dropzoneStyles, noIcon && { height: 280 }, styles] })}>
          <div css={dropzoneContent}>
            <input {...getInputProps({ disabled: false })} />
            {file ? (
              <React.Fragment>
                {!noIcon && <FileUploadIcon type={FaceType.YesFile} styles={iconCircle} />}
                <h3 css={h3}>Got your file</h3>
                <div>
                  <div css={filename}>{file.name}</div>
                  <Icon
                    css={xImage}
                    icon={IconType.XLarge}
                    onClick={() => handleChange(undefined)}
                  />
                </div>
                {clientMismatch && <p css={errorMessage}>Client does not match file name.</p>}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {!noIcon && iconType}
                <h3 css={h3}>No file yet</h3>
                <p css={p}>Drag and drop to import file</p>
                <Button
                  disabled={buttonDisabled}
                  styleOverrides={{ marginTop: '24px' }}
                  onClick={() => dropzoneRef.current!.open()}
                >
                  Select File
                </Button>
                {children}
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
}
