/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';

import Icon, { IconType } from '../ui/Icon';

export enum FaceType {
  Error = IconType.ErrorFace,
  NoFile = IconType.NoFileFace,
  YesFile = IconType.GotItFace,
  Sucess = IconType.SuccessFace,
  Hover = IconType.HoverFace,
  NoResults = IconType.NoResults,
}

const circleStyles: CSSObject = {
  width: 224,
  height: 224,
  borderRadius: '50%',
  backgroundColor: '#f7f7f9',
  display: 'flex',
  justifyContent: 'center',
};

type FileUploadIconProps = {
  type: FaceType;
  styles?: CSSObject;
};

export default ({ type, styles }: FileUploadIconProps) => (
  <div css={[circleStyles, styles]}>
    <Icon icon={(type as unknown) as IconType} />
  </div>
);
