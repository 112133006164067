/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';
import colors from '../../styles/colors';

// #region CSS

const headerStyles: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.grayLight}`,
  display: 'flex',
  height: 64,
  paddingLeft: 32,
  position: 'relative',
  top: 0,
  width: '100%',
};

const headerTextStyles: CSSObject = {
  display: 'block',
  fontSize: 24,
  fontWeight: 'bold',
  paddingRight: 32,
};

const fixedTop: CSSObject = {
  position: 'fixed',
  top: 0,
  zIndex: 250,
};

// #endregion

export type HeaderProps = { headerText?: string; children?: any; fixed?: boolean; styles?: object };

export default ({ headerText, children, fixed, styles }: HeaderProps) => (
  <div css={[headerStyles, fixed && fixedTop, styles]}>
    <div css={headerTextStyles}>{headerText}</div>
    <div>{children}</div>
  </div>
);
