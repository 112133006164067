import React, { Fragment, useState } from 'react';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Link from '@mui/material/Link';
import { DocumentCorrectionsDrillDownReport, DrillDownData, RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  documentsCorrectedByUser: RowData[];
};

const docCorrectionsDrillDownColumns = [
  {
    field: 'barcode',
    title: 'Barcode',
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
  },
  {
    field: 'lender',
    title: 'Lender',
  },
  {
    field: 'user',
    title: 'User',
    render: (rowData: DocumentCorrectionsDrillDownReport) =>
      rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
  },
  {
    field: 'date',
    title: 'Date',
    render: (rowData: DocumentCorrectionsDrillDownReport) =>
      new Date(rowData.date).toLocaleDateString(),
  },
  {
    field: 'dateFunded',
    title: 'Date Funded',
    render: (rowData: DocumentCorrectionsDrillDownReport) =>
      new Date(rowData.dateFunded).toLocaleDateString(),
  },
  {
    field: 'failedAuditDate',
    title: 'Failed Audit Date',
    render: (rowData: DocumentCorrectionsDrillDownReport) =>
      rowData.failedAuditDate !== null && new Date(rowData.failedAuditDate!).toLocaleDateString(),
  },
  {
    field: 'failedVerificationDate',
    title: 'Failed Verification Date',
    render: (rowData: DocumentCorrectionsDrillDownReport) =>
      rowData.failedVerificationDate !== null &&
      new Date(rowData.failedVerificationDate!).toLocaleDateString(),
  },
  {
    field: 'failedAuditReason',
    title: 'Failed Audit Reason',
  },
  {
    field: 'failedAuditNotes',
    title: 'Failed Audit Notes',
  },
  {
    field: 'failedVerificationNotes',
    title: 'Failed Verification Notes',
  },
];

// eslint-disable-next-line import/prefer-default-export
export const DocumentsCorrectedByUser = ({ documentsCorrectedByUser }: Props) => {
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns = [
    {
      field: 'count',
      title: 'Count',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          component="button"
          variant="body2"
          underline="hover"
          onClick={() =>
            setDrillDownData({
              date: new Date(rowData.date),
              user: rowData.user,
            })
          }
        >
          {rowData.count}
        </Link>
      ),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'user',
      title: 'User',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'date',
      title: 'Date',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      render: (rowData: RowData) => new Date(rowData.date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <DataTable<RowData>
        title="Documents Corrected - By User"
        columns={columns}
        data={documentsCorrectedByUser}
        options={{
          maxBodyHeight: '500px',
          search: false,
        }}
      />

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <div className={classes.paper}>
          <DataTable<DocumentCorrectionsDrillDownReport>
            title="Documents Corrected Drill Down Report"
            columns={docCorrectionsDrillDownColumns}
            url={`/api/reports/document-corrections-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              documentEvent: 'DocumentCured',
            })}`}
            options={{
              search: false,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
