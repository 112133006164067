/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { roleTypes } from '../../constants';
import { TitleCompany } from '../../globalTypes/objects';
import { AuthContext } from '../AuthContext';
import { useTitleCompanies } from './useTitleCompanies';

export default function TableHeader({
  consolidationModal = false,
}: {
  consolidationModal?: boolean;
}) {
  const [
    { data, selectedIds, selectionMode, selectedRows },
    setTitleCompanies,
  ] = useTitleCompanies();
  const authContext = useContext(AuthContext);

  const handleSelectAllCheckboxClick = () => {
    const allSelected = selectedIds.size >= data.length && data.every(d => selectedIds.has(d.id));
    const newSelectedIds = new Set(selectedIds);
    data.map(d => (allSelected ? newSelectedIds.delete(d.id) : newSelectedIds.add(d.id)));
    setTitleCompanies({ selectedIds: newSelectedIds });
  };

  useEffect(() => {
    const newSelectedRows = [] as TitleCompany[];
    selectedIds.forEach(id => {
      const row = data.find(d => d.id === id) ?? selectedRows.find(d => d.id === id);
      newSelectedRows.push(row!);
    });
    setTitleCompanies({ selectedRows: newSelectedRows });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const showSelection = selectionMode && !consolidationModal;
  return (
    <TableHead>
      <TableRow>
        {showSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="error"
              checked={selectedIds.size > 0 && selectedIds.size === data.length}
              onChange={handleSelectAllCheckboxClick}
            />
          </TableCell>
        )}
        {authContext.roles.includes(roleTypes.Dev) && (
          <SortableHeaderCell label="Id" consolidationModal={consolidationModal} />
        )}
        <SortableHeaderCell label="Name" consolidationModal={consolidationModal} />
        <SortableHeaderCell label="Phone" consolidationModal={consolidationModal} />
        <SortableHeaderCell label="Email" consolidationModal={consolidationModal} />
        <SimpleHeaderCell label="Address" consolidationModal={consolidationModal} />
      </TableRow>
    </TableHead>
  );
}

type HeaderCellProps = {
  label: string;
  consolidationModal: boolean;
};

function SimpleHeaderCell({ label, consolidationModal: consolidationDialog }: HeaderCellProps) {
  return (
    <TableCell>
      <span css={consolidationDialog ? { color: '#a2a2a2' } : {}}>{label}</span>
    </TableCell>
  );
}

function SortableHeaderCell({ label, consolidationModal: consolidationDialog }: HeaderCellProps) {
  const [
    {
      sortOptions: { column, directionAscending },
    },
    setTitleCompanies,
  ] = useTitleCompanies();
  if (consolidationDialog)
    return <SimpleHeaderCell label={label} consolidationModal={consolidationDialog} />;
  return (
    <TableCell>
      <TableSortLabel
        active={column === label}
        direction={column === label ? (directionAscending ? 'asc' : 'desc') : 'asc'}
        onClick={() => {
          setTitleCompanies({
            sortOptions: {
              column: label,
              directionAscending: column === label ? !directionAscending : true,
            },
          });
        }}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
}
