/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import isEmpty from 'lodash/isEmpty';
import Panel, { PanelHeader } from '../ui/Panel';
import Tab, { TabGroup } from '../ui/Tabs/Tab';
import TextInput from '../ui/Inputs/TextInput';
import SearchSelect from '../ui/SearchSelect';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import colors from '../../styles/colors';
import BackButton from './BackButton';
import { apiFetch, apiPost } from '../../adalConfig';
import { Client } from '../../globalTypes/objects';

export type AssocationType = 'Investor' | 'Title';

const getAssociationId = async (loanNumber: string, clientId: number, type: AssocationType) => {
  const { data: id } = await apiFetch(
    `/api/associations/get${type}AssociationId?loanNumber=${loanNumber}&&clientId=${clientId}`,
  );
  return id;
};

const deleteAssociation = async (associationId: number, type: AssocationType) => {
  await apiPost(`/api/associations/delete${type}Association`, { int: associationId });
};

const tabsWrap = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: '100px',
  padding: '8px',
  display: 'inline-block',
  margin: '24px 0',
};

type DeleteAssociationState = {
  loanNumber: string;
  clientId?: number;
  clients: Client[];
  type: AssocationType;
  status: StatusTypes;
  errorMessage: boolean | string;
};

export default class DeleteAssociation extends Component<any, DeleteAssociationState> {
  state = {
    loanNumber: '',
    clientId: undefined,
    clients: [] as Client[],
    type: 'Investor' as AssocationType,
    status: StatusTypes.initial,
    errorMessage: false,
  };

  async componentDidMount() {
    const { data: clients } = await apiFetch<Client[]>('/api/clients/getAll');
    await this.setState({ clients });
  }

  onDeleteClick = async () => {
    this.setState({ status: StatusTypes.loading });
    try {
      const { loanNumber, clientId, type } = this.state;
      const id = await getAssociationId(loanNumber, clientId!, type);
      if (id === 0) {
        this.setState({ errorMessage: true, status: StatusTypes.initial });
        return;
      }
      await deleteAssociation(id, type);
      this.setState({ status: StatusTypes.success });
      setTimeout(() => {
        this.props.history.push(this.props.location.pathname);
      }, 2000);
    } catch (error) {
      this.setState({ status: StatusTypes.error });
      console.log(error);
    }
  };

  render() {
    const { loanNumber, clients, status, clientId, errorMessage } = this.state;
    return (
      <Fragment>
        <div>
          <BackButton to="/admin">Admin Page</BackButton>
        </div>
        <Panel styles={{ margin: 40, marginTop: 24 }}>
          <PanelHeader text="Delete Association" />
          <div css={tabsWrap}>
            <TabGroup>
              <Tab
                onClick={() =>
                  this.setState({
                    type: 'Investor',
                    status: StatusTypes.initial,
                    errorMessage: false,
                  })
                }
              >
                Investor
              </Tab>
              <Tab
                onClick={() =>
                  this.setState({
                    type: 'Title',
                    status: StatusTypes.initial,
                    errorMessage: false,
                  })
                }
              >
                Title Company
              </Tab>
            </TabGroup>
          </div>
          <TextInput
            value={loanNumber}
            onChange={e => this.setState({ loanNumber: e.target.value })}
            placeholder="Loan number"
            onClick={() => this.setState({ status: StatusTypes.initial, errorMessage: false })}
          />
          <div
            onClick={() => this.setState({ status: StatusTypes.initial, errorMessage: false })}
            css={{ margin: '24px 0' }}
          >
            <SearchSelect
              options={clients.map(client => ({
                label: client.company,
                value: client.id,
              }))}
              placeholder="Search clients"
              onChange={selection => {
                this.setState({ clientId: selection?.value, status: StatusTypes.initial });
              }}
            />
          </div>
          <StatusButton
            status={status}
            text="Delete Association"
            disabled={!clientId || isEmpty(loanNumber)}
            onClick={this.onDeleteClick}
            styleOverrides={{ width: 230 }}
          />
          {errorMessage && (
            <span css={{ color: colors.red }}>Loan number does not exist for this client</span>
          )}
        </Panel>
      </Fragment>
    );
  }
}
