/** @jsx jsx */
import { jsx } from '@emotion/core';
import { RowData, SidebarOptions } from '../types';
import {
  actions,
  ExportData,
  exportDatatable,
  formatNotes,
  sortDataByPhoneAndClient,
} from '../Datatable';
import MaterialDataTable from '../../ui/DataTable/DataTable';
import useFileExport from '../../ui/DataTable/FileExport';
import { roleTypes } from '../../../constants';
import { Dispatch, Fragment, SetStateAction, useContext } from 'react';
import { poBoxDisplay } from '../../../pages/CallCenter';
import colors from '../../../styles/colors';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { AuthContext } from '../../AuthContext';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import differenceInDays from 'date-fns/differenceInDays';

const useStyles = makeStyles((theme: Theme) => ({
  linkStyle: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

type Props = {
  dataTableData: RowData[];
  selectedRows: RowData[];
  setSelectedRows: Dispatch<SetStateAction<RowData[]>>;
  setSidebarOption: Dispatch<SetStateAction<SidebarOptions>>;
  setRowOfSelectedNote: Dispatch<SetStateAction<RowData | null>>;
};

const DataTable = ({
  dataTableData,
  selectedRows,
  setSelectedRows,
  setSidebarOption,
  setRowOfSelectedNote,
}: Props) => {
  const { roles } = useContext(AuthContext);
  const classes = useStyles();
  const fileExport = useFileExport<ExportData>();

  const columns = [
    {
      field: 'id',
      title: 'ID',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'calculatedOutreachStage',
      title: 'Stage',
    },
    {
      field: 'appNumber',
      title: 'Loan/Title Number',
      render: (rowData: RowData) => (
        <Fragment>
          <div>
            <Link target="_blank" href={`/loans/${rowData.id}`}>
              {rowData.appNumber}
            </Link>
          </div>
          <div style={{ color: 'grey' }}>{rowData.titleNumber}</div>
        </Fragment>
      ),
    },
    {
      field: 'titleCompany',
      title: 'Title Company',
      render: (rowData: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{rowData.titleCompany}</div>
          <div style={{ color: 'grey' }}>
            <div>
              <a
                className={classes.linkStyle}
                href={`tel:${rowData.titleCompanyPhoneGlobal?.replace(/\D/g, '')}`}
              >
                {rowData.titleCompanyPhoneGlobal}
              </a>
            </div>
          </div>
          <span css={{ color: 'blue' }}>{rowData.titleCompanyEmailGlobal}</span>
        </div>
      ),
    },
    {
      field: 'clientInformation',
      title: 'Client',
      render: (rowData: RowData) => (
        <div style={{ height: '66px', overflowY: 'auto', paddingRight: '2px' }}>
          <div>{rowData.clientInfo.client}</div>
          <span style={{ color: 'blue' }}>{rowData.clientInfo.clientInbox}</span>
          <div style={{ color: 'grey' }}>
            <div>{poBoxDisplay(rowData.clientInfo)}</div>
          </div>
        </div>
      ),
    },
    {
      field: 'propertyInfo',
      title: 'Property Info',
      render: (rowData: RowData) => (
        <Fragment>
          <div>
            <b>{rowData.propertyInfo.borrower}</b>
          </div>
          <div>{rowData.propertyInfo.address}</div>
          <div>
            <span>{rowData.propertyInfo.city}</span>&nbsp;
            <span>{rowData.propertyInfo.state}</span>&nbsp;
            <span>{rowData.propertyInfo.zip}</span>&nbsp;
          </div>
          <div>{rowData.propertyInfo.county}</div>
        </Fragment>
      ),
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: (rowData: RowData) => rowData && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'dateFunded',
      title: 'Days Aged',
      render: ({ dateFunded, daysAgedDeadline }: RowData) => {
        if (!dateFunded) {
          return null;
        }

        const daysAged = differenceInDays(new Date(), new Date(dateFunded));
        return `${daysAged}/${daysAgedDeadline}`;
      },
    },
    {
      field: 'missingDocuments',
      title: 'Missing Documents',
      render: (rowData: RowData) => (
        <Fragment>
          {rowData.missingDocuments.split(',').map((doc, i, arr) => (
            <Fragment key={doc}>
              <span
                style={{
                  color: rowData.requiresOriginalDoc && doc.trim() !== 'Policy' ? 'red' : 'inherit',
                }}
              >
                {doc}
              </span>
              {i !== arr.length - 1 && ', '}
            </Fragment>
          ))}
        </Fragment>
      ),
    },
    {
      field: 'loanLevelContactInformation',
      title: 'Loan Contacts',
      customSort: sortDataByPhoneAndClient,
      render: (rowData: RowData) => (
        <div css={{ maxHeight: 128, overflow: 'auto' }}>
          {(rowData.loanLevelContactInformation.phone ||
            rowData.loanLevelContactInformation.email) && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark }}>Loan Level:</div>
              <div>
                <a
                  className={classes.linkStyle}
                  href={`tel:${rowData.loanLevelContactInformation.phone?.replace(/\D/g, '')}`}
                >
                  {rowData.loanLevelContactInformation.phone}
                </a>
              </div>
              <div>{rowData.loanLevelContactInformation.email}</div>
            </Fragment>
          )}
          {rowData.titlePortalContactEmails.length > 0 && (
            <Fragment>
              <div css={{ fontWeight: 'bold', color: colors.grayDark, paddingTop: 8 }}>
                Title Portal Contacts:
              </div>
              {rowData.titlePortalContactEmails.map(e => (
                <div key={e}>{e}</div>
              ))}
            </Fragment>
          )}
        </div>
      ),
    },
    {
      field: 'pastNotes',
      title: 'Past Notes',
      render: (rowData: RowData) =>
        rowData.pastNotes.length > 0 && (
          <div className="df">
            <div
              style={{ width: '310px', height: '66px', overflowY: 'auto', whiteSpace: 'normal' }}
            >
              {formatNotes(rowData.pastNotes)}
            </div>
            <div>
              <OpenWithIcon
                style={{ fontSize: '10px' }}
                onClick={() => setRowOfSelectedNote(rowData)}
              />
            </div>
          </div>
        ),
    },
  ];

  return (
    <MaterialDataTable<RowData>
      title="Escalated Outstanding Loans"
      columns={columns}
      data={dataTableData}
      condensed
      options={{
        selection: true,
        rowStyle: (rowData: RowData) => ({
          backgroundColor: rowData.isResolved ? 'rgb(237, 247, 237)' : '#FFF',
        }),
        exportCsv: (columns, dataTableRows) =>
          exportDatatable(columns, dataTableRows, false, fileExport, 'Escalated Outstanding Loans'),
      }}
      onSelectionChange={rows => {
        setSelectedRows(rows);
        setSidebarOption(undefined);
      }}
      actions={() =>
        actions(
          selectedRows.every(r => r.isResolved),
          selectedRows.some(r => r.isResolved),
          setSidebarOption,
        )
      }
    />
  );
};

export default DataTable;
