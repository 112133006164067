/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';
import { apiPost } from '../../adalConfig';
import { AuditState, isFailedAudit } from '../../globalTypes/objects';
import { useToaster } from '../../Hooks/toasters';
import AuditFail, { AuditFailVersion } from '../Documents/Audit';

type InvestorFailProps = {
  documentId: number;
  documentType: number;
  refreshDatatable: () => void;
  setIsModalOpen: (value: boolean) => void;
};

function InvestorFail({
  documentId,
  documentType,
  setIsModalOpen,
  refreshDatatable,
}: InvestorFailProps) {
  const { successToaster, errorToaster } = useToaster();

  const [audit, setAudit] = useImmer<AuditState>({
    kind: 'FailedAudit',
    failureReasons: [],
    documentId,
    notes: '',
    failureSensitivity: null,
    passed: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDisabled = useMemo(
    () => !isFailedAudit(audit) || !audit.failureReasons.length || isSubmitting,
    [audit, isSubmitting],
  );

  useEffect(() => {
    const submitFailure = async () => {
      if (isDisabled || !isFailedAudit(audit)) return;
      setIsSubmitting(true);
      try {
        const { failureReasons, notes, failureSensitivity } = audit;
        await apiPost('/api/documentAudits/fail-after-ship', [
          { failureReasons, documentId, notes, failureSensitivity },
        ]);
        successToaster('Successfully added fail event.');
        failAfterShipReset();
        refreshDatatable();
      } catch (e) {
        if (e.response) {
          if (e.response.data?.errors) {
            errorToaster(
              <Fragment>
                {Object.values(e.response.data?.errors).map((errorMessage: string) => {
                  return <p key={errorMessage}>{errorMessage}</p>;
                })}
              </Fragment>,
            );
          } else {
            const errorMessage = e.response.data.split('\n')[0];
            errorToaster(errorMessage || e.message);
          }
        } else {
          errorToaster(e.message);
        }
      }
      setIsSubmitting(false);
      setIsModalOpen(false);
    };

    const failAfterShipReset = () => {
      setAudit({ kind: 'FailedAudit', failureReasons: [], notes: '', passed: false, documentId, failureSensitivity: null });
      setIsModalOpen(false);
    };
    isFailedAudit(audit) && audit.failureReasons.length > 0 && submitFailure();
  }, [
    audit,
    documentId,
    errorToaster,
    isDisabled,
    refreshDatatable,
    setAudit,
    setIsModalOpen,
    successToaster,
  ]);

  return (
    <AuditFail
      investorOnly
      continueBtnTxt="Submit Failure"
      closeModal={() => {
        setIsModalOpen(false);
      }}
      saveFailedAuditData={(failedReasons, sensitiveTier) => {
        setAudit(draft => {
          if (!isFailedAudit(draft)) return;
          draft.failureReasons = failedReasons;
          draft.failureSensitivity = sensitiveTier;
        });
      }}
      docType={documentType}
      version={AuditFailVersion.modal}
    />
  );
}

export default InvestorFail;
