/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as React from 'react';
import { Component, Fragment, FunctionComponent } from 'react';
import { format } from 'date-fns';

import BackButton from './BackButton';
import PanelJs, { PanelHeader } from '../ui/Panel';
import ClientSearch from '../Utilities/ClientSearch';
import colors from '../../styles/colors';
import CircleJs from '../ui/Circle';
import LoanDropdown from '../Utilities/Dropdowns/LoanDropdown';
import { Client, Loan } from '../../globalTypes/objects';
import { CheckIcon } from '../ui/icons';
import { apiFetch, apiPost } from '../../adalConfig';
import ActionCircle from '../ui/ActionCircle';
import { IconType } from '../ui/Icon';
import { HeaderWrapper, RowWrapper } from '../ui/HeaderAndRows';
import { DocumentOldWithShipment } from './types';

const Panel = PanelJs as any;
const PanelHeaderTs = PanelHeader as any;
const Circle = CircleJs as any;

const stepOne = {
  borderBottom: `1px solid ${colors.grayLight}`,
  display: 'flex',
  padding: '16px 0',
  marginTop: 16,
};

interface DeleteDocumentState {
  client: undefined | Client;
  loan: Loan;
  documents: DocumentOldWithShipment[];
  showDocSection: boolean;
}

export default class DeleteDocument extends Component<any, DeleteDocumentState> {
  state = {
    client: undefined,
    loan: {} as Loan,
    documents: [] as DocumentOldWithShipment[],
    showDocSection: false,
  };

  resetDocSection = () => this.setState({ showDocSection: false, documents: [] });

  getDocs = async () => {
    this.resetDocSection();
    const { client, loan } = this.state;
    if (!loan) {
      return;
    }
    const { data: documents } = await apiFetch<DocumentOldWithShipment[]>(
      `/api/documents/getDocumentsByLoanNum?clientId=${client!.id}&loanNumber=${loan.loanNumber}`,
    );
    this.setState({ showDocSection: true, documents });
  };

  deleteDocument = async (id: number) => {
    await apiPost('/api/documents/deleteOldDocument', { int: id });
    await this.getDocs();
  };

  render() {
    const { loan, documents, showDocSection, client } = this.state;
    return (
      <Fragment>
        <div css={{ marginBottom: 24 }}>
          <BackButton to="/admin">Admin Page</BackButton>
        </div>
        <Panel styles={{ marginLeft: 40, maxWidth: 700 }}>
          <PanelHeaderTs text="Delete Document" />
          <div>
            <div css={stepOne}>
              <Circle small text="1" styleoverrides={{ backgroundColor: colors.grayLight }} />
              <div css={{ marginBottom: 24, width: 320, marginLeft: 32 }}>
                <h3 css={{ marginBottom: 16 }}>Client</h3>
                <ClientSearch
                  onChange={(client: Client) =>
                    this.setState({ client }, () => this.resetDocSection())
                  }
                />
              </div>
            </div>
            <div>
              <div css={{ padding: '16px 0', display: 'flex' }}>
                <Circle small text="2" styleoverrides={{ backgroundColor: colors.grayLight }} />
                <div css={{ marginLeft: 32 }}>
                  <h3 css={{ marginBottom: 16 }}>Loan Number or Address</h3>
                  <LoanDropdown
                    loan={loan}
                    client={client}
                    onChange={selection =>
                      this.setState({ loan: selection?.value || {} }, async () => {
                        await this.getDocs();
                      })
                    }
                  />
                </div>
              </div>
            </div>
            {showDocSection &&
              (documents.length == 0 ? (
                <NoDocuments />
              ) : (
                <Fragment>
                  <HeaderWrapper styles={{ gridTemplateColumns: '4fr 4fr 3fr 3fr 6fr' }}>
                    <div>Document Type</div>
                    <div>Date Uploaded</div>
                    <div>Hard Copy</div>
                    <div>Shipped</div>
                    <div>File Name</div>
                  </HeaderWrapper>
                  {documents.map((d: DocumentOldWithShipment) => (
                    <DocumentRow key={d.id} document={d} deleteDocument={this.deleteDocument} />
                  ))}
                </Fragment>
              ))}
          </div>
        </Panel>
      </Fragment>
    );
  }
}

function NoDocuments() {
  return <div>No documents with this client and loan number</div>;
}

const fileCellWrap = {
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'space-between',
};

const fileNameStyles = {
  overflow: 'hidden',
  paddingRight: 8,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: 100,
};

type DocumentRowProps = {
  document: DocumentOldWithShipment;
  deleteDocument: (id: number) => void;
};

const DocumentRow: FunctionComponent<DocumentRowProps> = ({ document, deleteDocument }) => {
  return (
    <RowWrapper styles={{ gridTemplateColumns: '4fr 4fr 3fr 3fr 6fr' }}>
      <div>{document.docType}</div>
      <div>{format(new Date(Date.parse(document.dateUploaded)), 'MM/dd/yyyy')}</div>
      <div>{document.hardCopy ? <CheckIcon /> : ''}</div>
      <div>{document.trackingNumber ? <CheckIcon /> : ''}</div>
      <div css={fileCellWrap}>
        <div css={fileNameStyles} title={document.docLocation}>
          {document.docLocation}
        </div>
        <ActionCircle onClick={() => deleteDocument(document.id)} icon={IconType.TrashCan} />
      </div>
    </RowWrapper>
  );
};
