/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import colors from '../../styles/colors';
import Icon, { IconType } from './Icon';

const style: CSSObject = {
  textAlign: 'center',
};

const text: CSSObject = {
  fontSize: '.8em',
  color: colors.gray,
};

const searchFilterSec: CSSObject = {
  width: 356,
  height: '85px',
};

const searchFilterInputArea: CSSObject = {
  height: '56px',
  position: 'relative',
  width: '100%',
};

const searchglassStyles: CSSObject = {
  float: 'left',
  left: 26,
  position: 'absolute',
  top: 18,
  transition: 'all .2s',
  zIndex: 10,
  color: colors.gray,
};

const xStyles: CSSObject = {
  float: 'left',
  left: 29,
  position: 'absolute',
  top: 20,
  transition: 'all .2s',
  zIndex: 10,
  color: colors.blue,
};

const searchFilterInput: CSSObject = {
  border: `solid 1px ${colors.grayLight}`,
  borderRadius: 100,
  boxSizing: 'border-box',
  color: colors.blackText,
  fontSize: 16,
  height: 56,
  overflow: 'hidden',
  textIndent: 52,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
  caretColor: colors.blue,
  '::placeholder': {
    color: colors.gray,
    fontSize: '13px',
  },
};

const searchInstructions: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'sans-serif',
  fontSize: 14,
  margin: '10px auto 0 auto',
  width: 298,
};

const returnSec: CSSObject = {
  marginLeft: '18px',
};

interface SearchFilterProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  excludeHelperText?: boolean;
  clearSearch: () => void;
  styleOverrides?: object;
}
export default function SearchFilter({
  excludeHelperText,
  clearSearch,
  styleOverrides,
  value,
  ...props
}: SearchFilterProps) {
  return (
    <div css={{ ...searchFilterSec, ...styleOverrides }}>
      <div css={searchFilterInputArea}>
        <span onClick={clearSearch}>
          {value ? (
            <Icon icon={IconType.XMedium} css={xStyles} />
          ) : (
            <Icon icon={IconType.Search} css={searchglassStyles} />
          )}
        </span>
        <input css={searchFilterInput} value={value} {...props} />
        {!excludeHelperText && (
          <p css={{ ...text, ...style, ...searchInstructions }}>
            <span>
              <b>Tab </b> or
            </span>
            <Icon icon={IconType.UpArrow} />
            <Icon icon={IconType.DownArrowIcon} />
            to navigate
            <b css={returnSec}>Return</b>
            <Icon icon={IconType.Return} />
            to select
          </p>
        )}
      </div>
    </div>
  );
}
