/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { PanelHeader } from '../../ui/Panel';
import StepCircle, { StepStatusTypes } from '../../ui/StepCircle';

export default function StepHeader({ text, number }) {
  return (
    <Fragment>
      {number !== 0 ? (
        <PanelHeader
          text={text}
          iconComponent={<StepCircle status={StepStatusTypes.active} text={number} />}
        />
      ) : (
        <PanelHeader text={text} />
      )}
    </Fragment>
  );
}
