/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SearchBoxSection from '../components/Imports/ImportErrors/LoanErrors/SearchBoxSection';
import Header from '../components/ui/Header';
import Button from '../components/ui/Button';
import { apiFetch } from '../adalConfig';
import ClientWorkFlow from '../components/ClientWorkflow';
import { convertToFileDownload } from '../Utils';
import { useToaster } from '../Hooks/toasters';
import { useClients } from '../Hooks/useClients';

const buttonStyles = {
  alignItems: 'center',
  borderRadius: 4,
  display: 'flex',
  marginLeft: 8,
  svg: {
    marginLeft: 8,
    transition: 'all .2s',
  },
};

export type Workflow = {
  workflowData: WorkflowData[];
  dates: Date[];
};

export type WorkflowData = {
  clientId: number;
  clientName: string;
  latestDateFunded?: Date;
  threeMonthAverage: number;
  currentMonthCount: number;
  currentMonthUploadCount: number;
  lastImportDate?: Date;
  lastImportVolume: number;
  weeklyLoansCount: number[];
};

export default function Workflow() {
  const [selectedClientIds, setSelectedClientIds] = useState<number[]>([]);
  const [clientOpened, setClientOpened] = useState({});
  const [workflowData, setWorkflowData] = useState<WorkflowData[]>([]);
  const [dates, setDates] = useState<Date[]>([]);
  const [collapseAll, setCollapseAll] = useState(true);
  const [loading, setLoading] = useState(false);

  const clients = useClients();
  const { errorToaster } = useToaster();

  useEffect(() => {
    const isClientOpenedMap = clients.reduce((map, client) => {
      map[client.id] = true;
      return map;
    }, {});
    setClientOpened(isClientOpenedMap);
  }, [clients]);

  useEffect(() => {
    setLoading(true);
    apiFetch<Workflow>(`/api/workflow/getWorkflowData`).then(({ data }) => {
      setWorkflowData(data.workflowData);
      setDates(data.dates);
      setLoading(false);
    });
  }, []);

  const onDownloadClick = async () => {
    try {
      const { data } = await apiFetch(`/api/workflow/downloadWorkflow`);
      convertToFileDownload(
        data,
        `Workflow - ${new Date(Date.now()).toLocaleDateString()}.xlsx`,
        'xlsx',
      );
    } catch (e) {
      errorToaster(e.message);
    }
  };

  const clientsToRender = selectedClientIds.length ? selectedClientIds : clients.map(c => c.id);
  const dataToRender = workflowData
    ? workflowData.filter(wd => clientsToRender.includes(wd.clientId))
    : [];

  return (
    <div>
      <Header headerText="Workflow" fixed />
      <div css={{ padding: 40, maxWidth: 1400, marginTop: 64 }}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchBoxSection
            selectedClientIds={selectedClientIds}
            setSelectedClient={id => setSelectedClientIds([...selectedClientIds, id])}
            removeSelectedClient={id =>
              setSelectedClientIds(selectedClientIds.filter(clientId => clientId !== id))
            }
            clients={clients.map(c => ({
              name: c.company,
              id: c.id,
            }))}
          />
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button styleOverrides={buttonStyles} onClick={onDownloadClick}>
              Export to Excel
            </Button>
            <Button
              styleOverrides={buttonStyles}
              onClick={() => {
                const copy = { ...clientOpened };
                Object.keys(copy).forEach(key => (copy[key] = !collapseAll));
                setCollapseAll(!collapseAll);
                setClientOpened(copy);
              }}
              title="Click to collapse or expand list of clients"
            >
              {collapseAll ? 'Collapse List' : 'Expand List'}
            </Button>
          </div>
        </div>
        {loading && (
          <div css={{ position: 'fixed', top: '50%', left: '50%' }}>
            <CircularProgress size={50} />
          </div>
        )}
        {dataToRender.map(d => (
          <ClientWorkFlow
            key={d.clientId}
            data={d}
            dates={dates}
            clientOpened={clientOpened[+d.clientId]}
            collapseClicked={(clientId, isCollapsed) => {
              const copy = { ...clientOpened };
              copy[clientId] = isCollapsed;
              setClientOpened(copy);
            }}
          />
        ))}
      </div>
    </div>
  );
}
