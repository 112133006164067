/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import Checkbox from '../../../ui/Checkbox';
import { CalendarIcon, NoteIcon, FollowUpsIcon } from '../../../ui/icons';
import colors from '../../../../styles/colors';
import { rowWrap, missingDataStyle, pinnedDotWrap, notesSection } from './css_index';
import { LoanErrorIndexLoan } from '../../../../pages/LoanErrorIndex';

// eslint-disable-next-line consistent-return
const formatIssues = (issues: string[]) => {
  const { length } = issues;
  // eslint-disable-next-line default-case
  switch (true) {
    case length === 1: {
      return issues[0];
    }
    case length === 2: {
      return `${issues[0]} and ${issues[1]}`;
    }
    case length === 3: {
      return `${issues[0]}, ${issues[1]}, and ${issues[2]}`;
    }
    case length > 3: {
      return `${issues.slice(0, 3).join(', ')}...`;
    }
  }
};

const sentEmailStyles: CSSObject = {
  color: colors.blue,
  display: 'inline-block',
  left: -16,
  position: 'relative',
};

type ErrorRowProps = {
  loanError: LoanErrorIndexLoan;
  isSelected: boolean;
  index: number;
  onSelect: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => any;
};
export default function ErrorRow({
  loanError: { lastNote, clientId, spreadsheetDate, loanNumber, issues, isPinned, emailSent },
  isSelected,
  index,
  onSelect,
}: ErrorRowProps) {
  return (
    <Link to={`/errors/loans/${clientId}?index=${index}`}>
      <div css={rowWrap}>
        <div css={{ width: 24, marginRight: 16 }}>
          <Checkbox
            onClick={e => {
              e.preventDefault();
              onSelect(e);
            }}
            checked={isSelected}
          />
        </div>
        <div css={missingDataStyle}>
          {isPinned && (
            <div css={pinnedDotWrap}>
              <div />
            </div>
          )}
          {formatIssues(issues)}
        </div>
        <div css={{ width: 224, color: colors.blue }}>
          <CalendarIcon />
          <span css={{ color: colors.black, verticalAlign: 'top', marginLeft: 16 }}>
            {spreadsheetDate || 'Unknown'}
          </span>
        </div>
        <div css={{ width: 200 }}>{loanNumber}</div>
        {emailSent && (
          <span css={sentEmailStyles}>
            <FollowUpsIcon />
          </span>
        )}
        <div css={notesSection}>
          <NoteIcon />
          <div>
            {!lastNote ? (
              <p>No notes</p>
            ) : (
              <Fragment>
                <span>{format(new Date(Date.parse(lastNote.createdAt)), 'MMMM d, yyyy h:mma')}</span>
                <p>{lastNote.note}</p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
