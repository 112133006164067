/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import MappedLoanImportDataTable, { RowData } from './MappedLoanImportDataTable';
import Modal from '@mui/material/Modal';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import { useClient } from '../../Hooks/useClients';
import { purple } from '@mui/material/colors';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      minHeight: '300px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    },
    headerSection: {
      fontWeight: '600',
      display: 'grid',
      gridTemplateColumns: '1.5fr 1.5fr 3fr',
      columnGap: '8px',
      padding: '16px 32px 10px 32px',
      letterSpacing: '0.2px',
      fontSize: '14px',
    },
    importStats: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      color: 'blue',
    },
    notificationSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '8px',
    },
    buttonsSection: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '6px',
      padding: '0px 32px 16px 0px',
    },
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '88px',
      height: '34px',
    },
  }),
);

export type ImportData = {
  rawLoansData: RowData[];
  loansCount: number;
  missingTitleData: number;
  missingInvestorData: number;
  priorToClientStartDate: number;
  errors: string[];
  warnings: string[];
};

type Props = {
  clientId: number;
  fileName: string;
  importData: ImportData;
  confirmSubmit: () => void;
  cancelSubmit: () => void;
  isImporting: boolean;
};

const ImportReviewModal = ({
  clientId,
  fileName,
  importData,
  confirmSubmit,
  cancelSubmit,
  isImporting,
}: Props) => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);

  const client = useClient(clientId);
  const classes = useStyles();

  if (!client) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <Modal open={!!importData.rawLoansData?.length}>
        <div className={classes.container}>
          <div className={classes.headerSection}>
            <div className={classes.importStats}>
              <div>{client.company}</div>
              <div style={{ color: 'black' }}>File Name: {fileName}</div>
            </div>
            <div className={classes.importStats}>
              <div>
                <b>Total Imported Loans</b>: {importData.loansCount}
              </div>
              <div>
                <b>Missing Title Data</b>: {importData.missingTitleData}
              </div>
              <div>
                <b>Missing Investor Data</b>: {importData.missingInvestorData}
              </div>
              <div>
                <b>Prior To Client Start Date</b>: {importData.priorToClientStartDate}
              </div>
            </div>
            <div className={classes.notificationSection}>
              {importData.errors?.length > 0 && (
                <div className="df jcc">
                  <div className="pr1">
                    <WarningIcon color="warning" />
                  </div>
                  <Link
                    onClick={() => {
                      setIsErrorDialogOpen(true);
                    }}
                    style={{ color: purple[500] }}
                  >
                    <div>
                      <div>This file contains critical errors and can't be imported.</div>
                      <div>Click here for details</div>
                    </div>
                  </Link>
                </div>
              )}

              {importData.warnings?.length > 0 && (
                <div className="df jcc">
                  <div className="pr1">
                    <ErrorOutlineIcon color="info" />
                  </div>
                  <Link
                    onClick={() => {
                      setIsWarningDialogOpen(true);
                    }}
                    style={{ color: purple[500] }}
                  >
                    <div>
                      <div>This file is missing expected columns based on the custom mappings</div>
                      <div>Click here for details</div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div style={{ overflowY: 'auto' }}>
            <MappedLoanImportDataTable datatableData={importData.rawLoansData} />
          </div>

          <div className={classes.buttonsSection}>
            <Button variant="contained" onClick={cancelSubmit}>
              Cancel
            </Button>
            {isImporting ? (
              <div className={`${classes.loaderButton} center-in-parent`}>
                <CircularProgress size="20" disableShrink />
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={confirmSubmit}
                disabled={importData.errors?.length > 0}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </Modal>

      <Dialog onClose={() => setIsErrorDialogOpen(false)} open={isErrorDialogOpen}>
        <DialogTitle>Import Errors</DialogTitle>
        <div className="df col p2" style={{ gap: '6px' }}>
          {importData.errors?.map(error => (
            <div key={error}>{error}</div>
          ))}
        </div>
      </Dialog>

      <Dialog onClose={() => setIsWarningDialogOpen(false)} open={isWarningDialogOpen}>
        <DialogTitle>Columns not included in import</DialogTitle>
        <div className="df col p2" style={{ gap: '6px' }}>
          {importData.warnings?.map(warning => (
            <div key={warning}>{warning}</div>
          ))}
        </div>
      </Dialog>
    </Fragment>
  );
};

export default ImportReviewModal;
