/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext } from 'react';
import Barcode from 'react-barcode';
import shortId from 'shortid';
import { format } from 'date-fns';
import * as QRCode from 'qrcode.react';
import { CoversheetProps } from './CoversheetPrint';
import { DocumentAudit, TitleCompany } from '../../../globalTypes/objects';

const sectionWrap = {
  width: '50%',
  p: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '25px',
  },
};

const rowWrap = {
  border: '1px solid gray',
  borderBottom: 'none',
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ':nth-child(odd)': {
    backgroundColor: '#f7f7f9',
  },
  p: {
    width: '50%',
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: 16,
    lineHeight: '29px',
    padding: '4px 8px ',
  },
};

export type VerificationErrorCoversheetProps = CoversheetProps & {
  accountRep: string;
  verificationFailures: string[];
  note: string;
  titleCompany: TitleCompany;
  loanNumber: string;
};

interface Props {
  [key: string]: any;
  audit: DocumentAudit;
}

export default function VerificationErrorCoversheet({
  accountRep,
  verificationFailures,
  note,
  titleCompany,
  documentTypeDescription,
  investorName,
  borrowerName,
  clientName,
  dateDocumentDrawn,
  loanNumber,
  documentId,
  audit,
}: Props) {
  return (
    <div
      css={{
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        css={{
          fontSize: 66,
          lineHeight: 1.2,
          fontWeight: 600,
          marginBottom: 24,
        }}
      >
        DOCPROBE
      </div>
      <div css={{ width: 600, borderBottom: '1px solid gray' }}>
        <div css={[rowWrap, { height: '50px', p: { fontSize: 24, fontWeight: 700 } }]}>
          <p>Client:</p>
          <p>{clientName}</p>
        </div>
        <div css={[rowWrap, { height: '50px', p: { fontSize: 24, fontWeight: 700 } }]}>
          <p>Account Manager:</p>
          <p>{accountRep}</p>
        </div>
        {audit && !audit.passed && (
          <div
            css={[
              rowWrap,
              {
                p: { fontWeight: 600 },
                alignItems: 'flex-start',
              },
            ]}
          >
            <p>FAILED AUDIT</p>
            <p>
              {audit.failureReasons?.map(reason => reason.reasonDescription).join('\n')}
              <div>{audit.notes}</div>
            </p>
          </div>
        )}
        <div css={[rowWrap, { alignItems: 'flex-start' }]}>
          <p>Verification Failures:</p>
          <p>
            {verificationFailures &&
              verificationFailures.map(f => <div key={shortId.generate()}>{f}</div>)}
          </p>
        </div>
        <div css={[rowWrap, { alignItems: 'flex-start' }]}>
          <p>Note:</p>
          <p>{note}</p>
        </div>
        <div css={rowWrap}>
          <p>Document Type:</p>
          <p>{documentTypeDescription}</p>
        </div>
        <div css={rowWrap}>
          <p>Loan Number:</p>
          <p>{loanNumber}</p>
        </div>
        <div css={rowWrap}>
          <p>Borrower Name:</p>
          <p>{borrowerName}</p>
        </div>
        <div css={rowWrap}>
          <p>Date Document Drawn:</p>
          <p>{format(new Date(Date.parse(dateDocumentDrawn)), 'MM/dd/yyyy')}</p>
        </div>
        <div css={rowWrap}>
          <p>Investor:</p>
          <p>{investorName}</p>
        </div>
        <div css={rowWrap}>
          <p>Title Company:</p>
          <p>{titleCompany?.name}</p>
        </div>
      </div>
      <div css={{ marginTop: 32 }}>
        <Barcode value={`docprobe-${documentId.toString()}`} />
      </div>
      <div css={{ alignSelf: 'flex-end', position: 'absolute', bottom: 32 }}>
        <QRCode
          value={`docprobe-${documentId.toString()}`}
          size={128}
          includeMargin
          css={{ marginRight: '32' }}
        />
      </div>
    </div>
  );
}
