/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useMemo } from 'react';
import ConditionalComponent from '../../ConditionalComponent';
import Grid from '@mui/material/Grid';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGetData } from '../../../Hooks';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 'calc(100vh - 120px)',
    },
    tableHeader: {
      '& th': {
        backgroundColor: '#fff',
      },
    },
  }),
);

type BaseReportDataPart = {
  queueCount: number;
  queueByFilterCount?: number;
  resolveCount: number;
};

type ClientReportDataPart = BaseReportDataPart & {
  clientId: number;
  client: string;
};

type TitleCompanyReportDataPart = BaseReportDataPart & {
  titleCompanyId: number;
  titleCompany: string;
};

type ReportData = {
  groupedByClient: ClientReportDataPart[];
  groupedByTitleCompany: TitleCompanyReportDataPart[];
};

const Reports = () => {
  const { data: report, isLoading } = useGetData<ReportData>('/api/call-tasks/queue-report', {
    groupedByClient: [],
    groupedByTitleCompany: [],
  });

  const aggregatedResult = useMemo<BaseReportDataPart>(
    () =>
      report.groupedByClient.reduce(
        (total, current) => {
          total.queueCount += current.queueCount;
          // @ts-ignore
          total.queueByFilterCount += current.queueByFilterCount ?? 0;
          total.resolveCount += current.resolveCount;
          return total;
        },
        { queueByFilterCount: 0, queueCount: 0, resolveCount: 0 } as BaseReportDataPart,
      ),
    [report.groupedByClient],
  );

  const aggregatedTitleResult = useMemo<BaseReportDataPart>(
    () =>
      report.groupedByTitleCompany.reduce(
        (total, current) => {
          total.queueCount += current.queueCount;
          // @ts-ignore
          total.queueByFilterCount += current.queueByFilterCount ?? 0;
          total.resolveCount += current.resolveCount;
          return total;
        },
        { queueByFilterCount: 0, queueCount: 0, resolveCount: 0 } as BaseReportDataPart,
      ),
    [report.groupedByTitleCompany],
  );

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {isLoading ? (
        <div css={{ margin: '0 auto' }}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <Fragment>
          <ConditionalComponent display={report.groupedByClient.length}>
            <Grid item xs={6} style={{ position: 'relative' }}>
              <p
                css={{
                  position: 'absolute',
                  top: -21,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontWeight: 600,
                }}
              >
                Client Queue Report
              </p>
              <TableContainer component={Paper} className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>Client</TableCell>
                      <TableCell align="center">Title Companies Queued</TableCell>
                      <TableCell align="center">Title Companies Queued - Filtered</TableCell>
                      <TableCell align="center">Title Companies Processed</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.groupedByClient.map(clientData => (
                      <TableRow key={clientData.clientId}>
                        <TableCell>{clientData.client}</TableCell>
                        <TableCell align="center">{clientData.queueCount}</TableCell>
                        <TableCell align="center">{clientData.queueByFilterCount}</TableCell>
                        <TableCell align="center">{clientData.resolveCount}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: 'yellow' }}>
                      <TableCell>
                        <b>Total</b>
                      </TableCell>
                      <TableCell align="center">{aggregatedResult.queueCount}</TableCell>
                      <TableCell align="center">{aggregatedResult.queueByFilterCount}</TableCell>
                      <TableCell align="center">{aggregatedResult.resolveCount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </ConditionalComponent>

          <ConditionalComponent display={report.groupedByTitleCompany.length}>
            <Grid item xs={6} style={{ position: 'relative' }}>
              <p
                css={{
                  position: 'absolute',
                  top: -21,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontWeight: 600,
                }}
              >
                Title Company Queue Report
              </p>
              <TableContainer component={Paper} className={classes.container}>
                <Table size="small" stickyHeader>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>Title Companies</TableCell>
                      <TableCell align="center" style={{ width: '25%' }}>
                        Clients Queued
                      </TableCell>
                      <TableCell align="center" style={{ width: '25%' }}>
                        Clients Queued - Filtered
                      </TableCell>
                      <TableCell align="center" style={{ width: '25%' }}>
                        Clients Processed
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.groupedByTitleCompany.map(titleCompanyData => (
                      <TableRow key={titleCompanyData.titleCompanyId}>
                        <TableCell>{titleCompanyData.titleCompany}</TableCell>
                        <TableCell align="center">{titleCompanyData.queueCount}</TableCell>
                        <TableCell align="center">{titleCompanyData.queueByFilterCount}</TableCell>
                        <TableCell align="center">{titleCompanyData.resolveCount} </TableCell>
                      </TableRow>
                    ))}
                    <TableRow style={{ backgroundColor: 'yellow' }}>
                      <TableCell>
                        <b>Total</b>
                      </TableCell>
                      <TableCell align="center">{aggregatedTitleResult.queueCount}</TableCell>
                      <TableCell align="center">
                        {aggregatedTitleResult.queueByFilterCount}
                      </TableCell>
                      <TableCell align="center">{aggregatedTitleResult.resolveCount}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </ConditionalComponent>
        </Fragment>
      )}
    </Grid>
  );
};

export default Reports;
