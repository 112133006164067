/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import colors from '../../styles/colors';
import Button from './Button';

const outerCon = {
  width: '100%',
  height: '100%',
  backgroundColor: colors.blue,
  color: colors.white,
  position: 'relative',
};

const leftCon = {
  position: 'absolute',
  top: 138,
  left: 96,
};

const number = {
  alignContent: 'center',
  display: 'flex',
  fontSize: 400,
  fontWeight: 'bold',
  height: 276,
  justifyContent: 'center',
  lineHeight: '0.62',
  width: 212,
};

const messageCon = {
  marginTop: 98,
};

const title = {
  fontSize: 36,
  fontWeight: 'bold',
};

const message = {
  fontSize: 16,
  width: '264',
  lineHeight: '1.5',
  marginTop: 15,
};
const buttonStyles = {
  marginTop: 32,
};

const zero = {
  position: 'absolute',
  bottom: 98,
  left: '45%',
};

const secondFour = {
  position: 'absolute',
  right: 98,
  top: 394,
};

export type ErrorType = {
  errorTitle: string;
  errorName: number;
  firstNumber: number;
  lastNumber: number;
  errorMessage: string | JSX.Element;
};

export default function ErrorPage({ errorType }: { errorType: ErrorType }) {
  return (
    <div css={outerCon}>
      <div css={leftCon}>
        <div css={number}>{errorType.firstNumber}</div>

        <div css={messageCon}>
          <div css={title}>
            {errorType.errorTitle}
            {errorType.errorName}
          </div>
          <div css={message}>{errorType.errorMessage}</div>
          <Button secondary styleOverrides={buttonStyles}>
            go back
          </Button>
        </div>
      </div>
      <div css={[number, zero]}>0</div>
      <div css={[number, secondFour]}>{errorType.lastNumber}</div>
    </div>
  );
}
