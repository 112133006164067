/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';

import SuggestionTile from './SuggestionTile';
import SearchFilter from '../../../ui/SearchFilter';
import EmptySearch from './EmptySearch';
import Panel, { PanelHeader } from '../../../ui/Panel';
import Circle from '../../../ui/Circle';
import colors from '../../../../styles/colors';
import { AssociationError, AssocState, errorType } from '../ImportErrorsUtils';
import StatusButton, { StatusTypes } from '../../../ui/StatusButton';
import SuggestionTileSkeleton from './SkeletonComponents/SuggestionTileSkeleton';
import { apiFetch } from '../../../../adalConfig';
import { IconType } from '../../../ui/Icon';
import { ContactInformation } from '../../../../globalTypes/objects';

// #region css

const suggestionButtonWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: 0,
  marginBottom: 32,
  width: '100%',
  ' > div': {
    color: colors.black,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1,
    textTransform: 'uppercase',
  },
};

const assocButtonA: CSSObject = {
  margin: '0px',
};

const scrollArea: CSSObject = {
  minHeight: 224,
  maxHeight: 688,
  overflowY: 'scroll',
  overflowX: 'hidden',
};
// #endregion

const getSuggestions = async ({ type, id }: AssociationError) => {
  if (type === errorType.title) {
    return [];
  }
  const { data: suggestions } = await apiFetch<ContactInformation[]>(
    `/api/associations/getInvestorSuggestions?associationErrorId=${id}`,
  );
  return suggestions;
};
const DEBOUNCE_RATE = 800;
const andSign = '%26';
const getSearchSuggestions = async (associationError: AssociationError, searchString: string) => {
  const url =
    associationError.type === errorType.title
      ? 'titleCompanies/searchTitleCompanies'
      : 'investors/searchInvestors';
  const { data: searchSuggestions } = await apiFetch<ContactInformation[]>(
    `/api/${url}?searchString=${searchString.replace('&', andSign)}`,
  );
  return searchSuggestions;
};

const getAssocButtonText = currentAssocState => {
  if (currentAssocState === AssocState.associating) {
    return 'Associating';
  }
  return currentAssocState === AssocState.associated ? 'Associated' : 'Associate';
};

const getAssocButtonStatus = currentAssocState => {
  if (currentAssocState === AssocState.assocError) {
    return StatusTypes.error;
  }
  if (currentAssocState === AssocState.associated) {
    return StatusTypes.success;
  }
  if (currentAssocState === AssocState.associating) {
    return StatusTypes.loading;
  }
  return StatusTypes.initial;
};

const getCircle = (error: AssociationError) => (
  <Circle
    styleoverrides={{ border: 'none' }}
    icon={error.type === errorType.title ? IconType.TitleCompany : IconType.Investor}
  />
);

type AssociationPanelProps = {
  associationError: AssociationError;
  onAssociateClick: () => any;
  onMatchSelect: (id: any) => any;
  selectedMatchId?: number;
  updateAssociationErrorCount: () => any;
  loadingSkeleton: boolean;
  disablePaginator: (action: boolean) => any;
};

type AssociationPanelState = {
  searchString: string;
  searchSuggestions: ContactInformation[];
  defaultSuggestions: ContactInformation[];
  loadingSuggestions?: boolean;
};
export default class AssociationPanel extends Component<
  AssociationPanelProps,
  AssociationPanelState
> {
  state = {
    searchString: '',
    searchSuggestions: [] as ContactInformation[],
    defaultSuggestions: [] as ContactInformation[],
    loadingSuggestions: false,
  };

  scrollArea: HTMLDivElement | null = null;

  async componentWillReceiveProps(nextProps: AssociationPanelProps) {
    const { associationError, disablePaginator } = this.props;
    if (
      nextProps.associationError.id === associationError.id &&
      nextProps.associationError.errorType === associationError.errorType
    ) {
      return;
    }
    this.setState({ defaultSuggestions: [], loadingSuggestions: true });
    disablePaginator(true);
    const suggestions = await getSuggestions(nextProps.associationError);
    await this.setState({ defaultSuggestions: suggestions, searchString: '' });
    if (this.scrollArea) {
      this.scrollArea.scrollTop = 0;
    }
    await disablePaginator(false);
    this.setState({ loadingSuggestions: false });
  }

  getDebouncedSearch = debounce(
    async searchString => {
      const { associationError } = this.props;
      const searchSuggestions = await getSearchSuggestions(associationError, searchString);
      this.setState({ searchSuggestions });
    },
    DEBOUNCE_RATE,
    { leading: true, trailing: true },
  );

  onSearchBarChange = async searchString => {
    this.setState({ searchString, loadingSuggestions: true });
    if (!searchString) {
      await this.setState({ searchSuggestions: [], loadingSuggestions: false });
      this.getDebouncedSearch.cancel();
      return;
    }
    await this.getDebouncedSearch(searchString);
    this.setState({ loadingSuggestions: false });
  };

  render() {
    const {
      associationError,
      onAssociateClick,
      onMatchSelect,
      selectedMatchId,
      updateAssociationErrorCount,
      loadingSkeleton,
    } = this.props;

    const { searchString, defaultSuggestions, searchSuggestions, loadingSuggestions } = this.state;

    const suggestionsToRender = searchString ? searchSuggestions : defaultSuggestions;

    const isAddNewContact =
      associationError.currentAddState === AssocState.adding ||
      associationError.currentAddState === AssocState.added;

    const isBusy =
      associationError.currentAddState === AssocState.associating ||
      associationError.currentAddState === AssocState.associated ||
      isAddNewContact;

    return (
      <Panel>
        {loadingSkeleton ? (
          <PanelHeader
            data-test="associate header"
            iconComponent={<Circle styleoverrides={{ border: 'none' }} />}
          />
        ) : (
          <PanelHeader
            data-test="associate header"
            text={`Associate with existing ${
              associationError.type === errorType.title ? 'Title Company' : 'Investor'
            }`}
            iconComponent={getCircle(associationError)}
          />
        )}

        <SearchFilter
          styleOverrides={{ marginTop: 32, width: '100%' }}
          data-test="Search List"
          value={searchString}
          excludeHelperText
          placeholder={`Search ${
            associationError.type === errorType.title ? 'Title Companies' : 'Investors'
          }`}
          clearSearch={() => this.onSearchBarChange('')}
          onChange={e => this.onSearchBarChange(e.target.value)}
        />
        {!suggestionsToRender.length && !loadingSuggestions && searchString ? (
          <EmptySearch searchString={searchString} />
        ) : (
          <Fragment>
            <div css={suggestionButtonWrap}>
              <div>Suggestions</div>
              <StatusButton
                data-test="Associate Button"
                styleOverrides={assocButtonA}
                disabled={
                  associationError.currentAssocState === AssocState.unmatched ||
                  isAddNewContact ||
                  loadingSkeleton
                }
                onClick={async () => {
                  await onAssociateClick();
                  updateAssociationErrorCount();
                }}
                status={getAssocButtonStatus(associationError.currentAssocState)}
                text={getAssocButtonText(associationError.currentAssocState)}
              />
            </div>

            <div css={scrollArea} ref={el => (this.scrollArea = el)}>
              {loadingSuggestions ? (
                <Fragment>
                  <SuggestionTileSkeleton />
                  <SuggestionTileSkeleton />
                  <SuggestionTileSkeleton />
                </Fragment>
              ) : (
                !!suggestionsToRender?.length &&
                suggestionsToRender.map(suggestion => (
                  <SuggestionTile
                    key={suggestion.id}
                    contactInformation={suggestion}
                    handleClick={() => onMatchSelect(suggestion.id)}
                    isSelected={suggestion.id === selectedMatchId && !isAddNewContact}
                    isUnselectable={isBusy}
                    showCircle
                  />
                ))
              )}
            </div>
          </Fragment>
        )}
      </Panel>
    );
  }
}
