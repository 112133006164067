/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { apiPost } from '../../../adalConfig';
import { RowData } from '../../../pages/ClientDocumentIndex';
import { useToaster } from '../../../Hooks/toasters';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  selectedDocument: RowData | null;
  refreshDatatable: () => void;
};

const DeleteDocumentModal = ({ isOpen, setIsOpen, selectedDocument, refreshDatatable }: Props) => {
  const { successToaster, errorToaster } = useToaster();

  const deleteDocument = async () => {
    if (!selectedDocument) {
      errorToaster('An unexpected error occured.');
      return;
    }

    try {
      const { data } = await apiPost('/api/documents/deleteMultipleDocs', [
        selectedDocument.documentId,
      ]);

      if (data) {
        setIsOpen(false);
        successToaster('Successfully deleted the document');
        refreshDatatable();
      } else {
        errorToaster('Failed to delete the document');
      }
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle style={{ cursor: 'move' }}>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete this document?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => setIsOpen(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={deleteDocument} color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDocumentModal;
