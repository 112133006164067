/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import ClientSearch from '../../components/Utilities/ClientSearch';
import TextInput from '../../components/ui/Inputs/TextInput';
import StatusButton, { StatusTypes } from '../../components/ui/StatusButton';
import Panel, { PanelHeader } from '../../components/ui/Panel';
import { Client, Loan } from '../../globalTypes/objects';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import LoanDropdown from '../../components/Utilities/Dropdowns/LoanDropdown';
import { parseRequestError } from '../../Utils';
import { RowData } from './ClosingPackagesList';
import { useClients } from '../../Hooks/useClients';

type ClosingPackage = {
  id: number;
  clientId: number;
  loanNumber: string;
  propertyAddress: string;
  borrowerName: string;
  titleCompanyName: string;
  inboundTrackingNumber: string;
  outboundTrackingNumber: string;
  shippingCost: string;
};

type Props = {
  closingPackage: RowData;
  onClose: () => void;
};

export default function EditClosingPackage({ onClose, closingPackage }: Props) {
  const clients = useClients();
  const client = clients.find(c => c.company === closingPackage.client);

  const [address, setAddress] = useState<string>(closingPackage.propertyAddress);
  const [borrower, setBorrower] = useState<string>(closingPackage.borrowerName);
  const [titleCompanyName, setTitleCompanyName] = useState<string>(closingPackage.titleCompanyName);
  const [loanNumber, setLoanNumber] = useState<string>(closingPackage.loanNumber);
  const [inboundTracking, setInboundTracking] = useState<string>(
    closingPackage.inboundTrackingNumber,
  );
  const [outboundTracking, setOutboundTracking] = useState<string>(
    closingPackage.outboundTrackingNumber,
  );
  const [shippingCost, setShippingCost] = useState<string>(closingPackage.shippingCost.toString());

  const isValidForSubmit = useMemo(
    () =>
      client?.id &&
      loanNumber &&
      inboundTracking &&
      outboundTracking &&
      shippingCost &&
      titleCompanyName &&
      address &&
      borrower,
    [
      client,
      loanNumber,
      address,
      borrower,
      titleCompanyName,
      inboundTracking,
      outboundTracking,
      shippingCost,
    ],
  );

  const updatedClosingPackage = useMemo(
    () =>
      ({
        id: closingPackage.id,
        clientId: client?.id ?? 0,
        loanNumber,
        inboundTrackingNumber: inboundTracking,
        outboundTrackingNumber: outboundTracking,
        shippingCost,
        titleCompanyName,
        propertyAddress: address,
        borrowerName: borrower,
      } as ClosingPackage),
    [
      client,
      loanNumber,
      address,
      borrower,
      titleCompanyName,
      inboundTracking,
      outboundTracking,
      shippingCost,
    ],
  );
  const [status, setStatus] = useState<StatusTypes>(StatusTypes.initial);

  const { errorToaster, successToaster } = useToaster();

  const edit = async () => {
    if (updatedClosingPackage) {
      setStatus(StatusTypes.loading);
      await apiPost(`/api/closingpackages/updateclosingpackage`, updatedClosingPackage);
    }
  };

  const editClosingPackage = () => {
    edit()
      .then(() => {
        setStatus(StatusTypes.success);
        successToaster('Closing package updated');
      })
      .catch(e => {
        const firstError = parseRequestError(e)[0];
        errorToaster(firstError);

        setStatus(StatusTypes.error);
      })
      .finally(() => onClose());
  };

  return (
    <Fragment>
      <div css={{ margin: 40, maxWidth: 1300 }}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Panel>
            <PanelHeader text="Update Closing Package" />
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
              }}
            >
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Inbound Tracking #"
                    name="inboundTackingNumber"
                    value={inboundTracking}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setInboundTracking(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Loan Number"
                    name="loanNumber"
                    value={loanNumber}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setLoanNumber(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Address"
                    name="address"
                    value={address}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setAddress(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Borrower"
                    name="borrower"
                    value={borrower}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setBorrower(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife">
                <div css={{ width: '100%' }}>
                  <TextInput
                    placeholder="Title Company Name"
                    name="titleCompanyName"
                    value={titleCompanyName}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setTitleCompanyName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="df jcsb aife mb1">
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Outbound Tracking Number"
                    name="outboundTracking"
                    value={outboundTracking}
                    onChange={(e: React.ChangeEvent<{ value: string }>) =>
                      setOutboundTracking(e.target.value)
                    }
                  />
                </div>
                <div css={{ width: 250 }}>
                  <TextInput
                    placeholder="Shipping Cost"
                    name="shippingCost"
                    value={shippingCost}
                    onChange={(e: React.ChangeEvent<{ value: string }>) => {
                      const { value } = e.target;

                      // Regular expression to match a valid decimal number
                      const decimalPattern = /^\d*\.?\d{0,2}$/;

                      if (decimalPattern.test(value)) {
                        setShippingCost(value);
                      }
                    }}
                  />
                </div>
              </div>
              <div css={{ display: 'flex' }}>
                <div css={{ paddingRight: 10 }}>
                  <StatusButton onClick={onClose} text="Cancel" />
                </div>
                <StatusButton
                  status={status}
                  onClick={editClosingPackage}
                  text="Save"
                  disabled={!isValidForSubmit}
                />
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </Fragment>
  );
}
