/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import MaterialButton from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal';
import { ExtractedDocumentData } from './types/document';
import { apiPost } from '../../adalConfig';
import { DocumentVerificationMatch, Loan } from '../../globalTypes/objects';
import colors from '../../styles/colors';
import { getToken } from '../../auth-config';

const useStyles = makeStyles({
  report: {
    marginTop: '6px',
    marginBottom: '20px',
    maxHeight: '600px',
    overflow: 'auto',
    '& table, td, th': {
      border: '1px solid #ddd',
      textAlign: 'left',
    },
    '& table': {
      width: '100%',
    },

    '& th, td': {
      padding: '4px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '12% 12% 17% 10% 10% 30% 9%',
  },
  gridHeader: {
    padding: '5px',
    fontWeight: 'bold',
  },
  gridRow: {
    padding: '5px',
    backgroundColor: colors.grayLight,
    border: '.5px solid #FFFFFF',
  },
  gridBorder: {
    border: '.5px solid #e5e5ea',
    padding: '5px',
  },
});
type Props = {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  refresh: () => void;
  documentId: number;
  extractedInfo: ExtractedDocumentData | null;
};

const BroadMatchPanel = ({
  showModal,
  setShowModal,
  refresh,
  documentId,
  extractedInfo,
}: Props) => {
  const [matches, setMatches] = useState<Loan[]>([]);

  function authHeader(token) {
    return { Authorization: `Bearer ${token}` };
  }

  async function getBroadMatches(docId): Promise<Loan[]> {
    const authToken = await getToken();
    return fetch(`/Api/BroadMatches/GetAll/${docId}`, {
      headers: authHeader(authToken),
    }).then(res => {
      return res.json();
    });
  }
  useEffect(() => {
    getBroadMatches(documentId).then(results => {
      setMatches(results);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId]);

  function createManualMatch(loanMatch) {
    return apiPost<DocumentVerificationMatch>('/api/documentMatch/createManualMatchSuggestion', {
      documentId,
      loanId: loanMatch.id,
    });
  }

  function rejectAll(docId) {
    return apiPost(`/Api/BroadMatches/RejectAll/${docId}`, {
      documentId,
    });
  }

  const classes = useStyles();

  return (
    <div>
      {showModal && (
        <Modal
          styles={{ width: '1150' }}
          headerText="Broad Match Suggestions"
          customFooter={
            <div css={{ display: 'flex', gap: 10 }}>
              <Button onClick={() => setShowModal(false)} secondary>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  rejectAll(documentId).then(r => refresh());
                  setShowModal(false);
                }}
              >
                Reject All
              </Button>
            </div>
          }
        >
          <div style={{ width: '100%', justifyContent: 'center' }} className={classes.report}>
            <div className={classes.grid}>
              <div className={classes.gridHeader}></div>
              <div className={classes.gridHeader}>Loan Number</div>
              <div className={classes.gridHeader}>Borrower</div>
              <div className={classes.gridHeader}>Date Funded</div>
              <div className={classes.gridHeader}>Loan Amount</div>
              <div className={classes.gridHeader}>Property Address</div>
              <div className={classes.gridHeader}></div>
            </div>
            <div className={classes.grid} style={{ marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }} className={classes.gridRow}>
                Extracted Data
              </div>
              <div className={classes.gridRow}>{extractedInfo?.loanNumber}</div>
              <div className={classes.gridRow}>{extractedInfo?.borrower}</div>
              <div className={classes.gridRow}>
                {extractedInfo?.dateFunded &&
                  format(new Date(Date.parse(extractedInfo?.dateFunded.toString())), 'MM/dd/yyyy')}
              </div>
              <div className={classes.gridRow}>
                {extractedInfo?.loanAmount &&
                  `$${new Intl.NumberFormat().format(extractedInfo?.loanAmount)}`}
              </div>

              <div className={classes.gridRow}>
                {extractedInfo?.propertyAddress &&
                  `${extractedInfo?.propertyAddress}, ${extractedInfo?.city} ${extractedInfo?.state} ${extractedInfo?.zip}`}
              </div>

              <div className={classes.gridRow}></div>
            </div>

            {matches.map(match => (
              <div className={classes.grid}>
                <div
                  css={{
                    borderTop: `${match !== matches[0] && `none !important`}`,
                    borderBottom: `${match !== matches[matches.length - 1] && `none !important`}`,
                    fontWeight: 'bold',
                  }}
                  className={classes.gridBorder}
                >
                  {match === matches[Math.floor(matches.length / 2)] && `Loan Information`}
                </div>

                <div className={classes.gridBorder}>{match.loanNumber}</div>
                <div className={classes.gridBorder}>{match.borrower}</div>
                <div className={classes.gridBorder}>
                  {format(new Date(Date.parse(match.dateDocumentsDrawn.toString())), 'MM/dd/yyyy')}
                </div>

                <div className={classes.gridBorder}>
                  {match.loanAmount && `$${new Intl.NumberFormat().format(match.loanAmount)}`}
                </div>
                <div className={classes.gridBorder}>
                  {match.propertyAddress &&
                    `${match.propertyAddress}, ${match.city} ${match.state} ${match.zip}`}
                </div>

                <div className={classes.gridBorder}>
                  <Tooltip title="Accept Broad Match">
                    <div className="df jcfe">
                      <MaterialButton
                        style={{ height: '20px', borderRadius: '2px', padding: '9px' }}
                        color="primary"
                        variant="outlined"
                        target="_blank"
                        href={`/documents/match?documentId=${documentId}&loanId=${match.id}`}
                        onClick={() => {
                          createManualMatch(match).then(r => refresh());
                          setShowModal(false);
                        }}
                      >
                        Accept &#8250;
                      </MaterialButton>
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};
export default BroadMatchPanel;
