/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useMemo, useState } from 'react';
import { DrillDownRowData, ModalStatus, OverviewRowData } from './types';
import Link from '@mui/material/Link';
import DataTable from '../../components/ui/DataTable/DataTable';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import format from 'date-fns/format';
import { Modal } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      marginLeft: '-32px',
      marginTop: '-16px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  dataTableData: OverviewRowData[];
  isManager: boolean;
  loadDrillDownReport: (lenderId: number) => Promise<void>;
  drillDownDataTableData: DrillDownRowData[];
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const drillDownColumns = [
  {
    field: 'monthAndYear',
    title: 'Month',
    options: {
      filter: false,
    },
    render: (rowData: DrillDownRowData) => format(new Date(rowData.monthAndYear), 'MMMM (yyyy)'),
  },
  {
    field: 'loansPerMonth',
    title: 'Loans Per Month',
    options: {
      filter: false,
    },
    render: (rowData: DrillDownRowData) => rowData.loansPerMonth,
  },
  {
    field: 'revenue',
    title: 'Revenue',
    options: {
      filter: false,
    },
    render: (rowData: DrillDownRowData) => formatter.format(rowData.revenue),
  },
  {
    field: 'commission',
    title: 'Commission',
    options: {
      filter: false,
    },
    render: (rowData: DrillDownRowData) => formatter.format(rowData.commission),
  },
];

const IsraelDashboard = ({
  dataTableData,
  isManager,
  loadDrillDownReport,
  drillDownDataTableData,
}: Props) => {
  const [modalStatus, setModalStatus] = useState<ModalStatus>(ModalStatus.closed);

  const firstDrillDownRow = useMemo(
    () => (drillDownDataTableData.length ? drillDownDataTableData[0] : ({} as DrillDownRowData)),
    [drillDownDataTableData],
  );

  const classes = useStyles();

  const overViewReportColumns = [
    {
      field: 'lenderId',
      title: 'Lender Id',
      hidden: true,
      options: {
        filter: false,
      },
    },
    {
      field: 'lender',
      title: 'Lender',
      options: {
        filter: false,
      },
      cellStyle: {
        whiteSpace: 'nowrap',
      },
      render: (rowData: OverviewRowData) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={async () => {
            setModalStatus(ModalStatus.loading);
            await loadDrillDownReport(rowData.lenderId);
            setModalStatus(ModalStatus.opened);
          }}
        >
          {rowData.lender}
        </Link>
      ),
    },
    {
      field: 'commissionForYearOne',
      title: 'Total Commission To Date',
      options: {
        filter: false,
      },
      render: (rowData: OverviewRowData) =>
        formatter.format(rowData.commissionForYearOne + rowData.commissionForYearTwo),
    },
    {
      field: 'commissionForYearTwo',
      title: 'Total Commission For Y2',
      hidden: true,
      options: {
        filter: false,
      },
    },
    {
      field: 'chargeForYearOne',
      title: 'Total Lender Was Charged Y1',
      hidden: !isManager,
      options: {
        filter: false,
      },
      render: (rowData: OverviewRowData) => formatter.format(rowData.chargeForYearOne),
    },
    {
      field: 'chargeForYearTwo',
      title: 'Total Lender Was Charged Y2',
      hidden: !isManager,
      options: {
        filter: false,
      },
      render: (rowData: OverviewRowData) => formatter.format(rowData.chargeForYearTwo),
    },
  ];

  return (
    <Fragment>
      <div style={{ marginTop: '32' }}>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <DataTable<OverviewRowData>
              title="Sales Rep Overview Report"
              columns={overViewReportColumns}
              data={dataTableData}
              headerStyle={{ whiteSpace: 'normal' }}
            />
          </Grid>
        </Grid>
      </div>

      <Modal
        open={modalStatus === ModalStatus.opened || modalStatus === ModalStatus.loading}
        onClose={() => {
          setModalStatus(ModalStatus.closed);
        }}
      >
        <div className={classes.modal}>
          {modalStatus === ModalStatus.loading ? (
            <div className="center-in-parent">
              <CircularProgress size="25" disableShrink />
            </div>
          ) : (
            <Fragment>
              <div style={{ display: 'flex', justifyContent: 'space-around', margin: '15px 0px' }}>
                <span>Lender: {firstDrillDownRow.lender}</span>
                <span>
                  Start Date: {new Date(firstDrillDownRow.commissionStartDate).toLocaleDateString()}
                </span>
              </div>
              <DataTable<DrillDownRowData>
                title="Sales Rep Drill-Down Report"
                columns={drillDownColumns}
                data={drillDownDataTableData}
              />
            </Fragment>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default IsraelDashboard;
