/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import Header, { HeaderProps } from '../components/ui/Header';
import {
  initialShipping,
  ShippingContext,
  ShippingProvider,
  useShipping,
} from '../components/Shipping/useShipping';
import ShippingSteps from '../components/Shipping/ShippingSteps';
import HeaderButton from '../components/Utilities/StepUI/HeaderButton';
import StepPanel from '../components/Utilities/StepUI/StepPanel';
import StepOne from '../components/Shipping/StepOne';
import StepTwo from '../components/Shipping/StepTwo';
import StepThree from '../components/Shipping/StepThree';
import { HeaderLink, HeaderLinkGroup } from '../components/ui/Tabs/HeaderLink';
import { ShippingClientsProvider } from '../components/Shipping/ShippingClientsContext';

// eslint-disable-next-line consistent-return
function getCurrentStepComponent(currentStep: number) {
  // eslint-disable-next-line default-case
  switch (currentStep) {
    case 1:
      return StepOne;
    case 2:
      return StepTwo;
    case 3:
      return StepThree;
  }
}

export const shippingValidation = (
  shipping: ShippingContext,
  stepNum: 1 | 2 | undefined = undefined,
) => {
  const { currentStep } = shipping;
  switch (stepNum || currentStep) {
    case 1:
      return shipping.investor && shipping.trackingNum;
    default:
      return shipping.physicalDocs.length > 0;
  }
};

export default function Shipping() {
  return (
    <ShippingProvider>
      <ShippingClientsProvider>
        <ShippingUI />
      </ShippingClientsProvider>
    </ShippingProvider>
  );
}

function ShippingUI() {
  const [shipping, setShipping] = useShipping();

  return (
    <Fragment>
      <ShippingHeader showButton setShipping={setShipping} />
      <StepPanel
        stepper={ShippingSteps}
        component={getCurrentStepComponent(shipping.currentStep)!}
      />
    </Fragment>
  );
}

export function ShippingHeader({
  showButton,
  setShipping,
  ...props
}: {
  showButton?: boolean;
  setShipping?: (shipping: ShippingContext) => void;
} & HeaderProps) {
  return (
    <Header headerText="Shipping" {...props}>
      <HeaderLinkGroup styles={{ left: 194 }}>
        <HeaderLink to="/shipping" exact>
          <span>Create Shipment</span>
        </HeaderLink>
        <HeaderLink to="/shipping/shipments">
          <span>View Shipments</span>
        </HeaderLink>
      </HeaderLinkGroup>
      {showButton && <HeaderButton onClick={() => setShipping!(initialShipping)} />}
    </Header>
  );
}
