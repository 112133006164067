import React, { createContext, useContext, useMemo, useState } from 'react';
import { Investor } from '../../globalTypes/objects';
import { Printer } from '../Utilities/Dropdowns/PrinterDropdown';

export type ShipmentDTO<T> = {
  docs: T[];
  trackingNum: string;
  investorId: number;
  printerId: number;
};

export type ShippingContext = {
  investor: Investor;
  trackingNum: string;
  physicalDocs: ShippingDoc[];
  digitalDocs: ShippingDoc[];
  currentStep: 1 | 2 | 3;
  printer: Printer | undefined;
};

type ShippingContextValue = [ShippingContext, (updateShipping: Partial<ShippingContext>) => void];

export type ShippingDoc = {
  clientId: number;
  docId: number;
};

export type DocumentShipFormat = {
  documentId: number;
  shipFormat: ShipFormat;
};

export enum ShipFormat {
  Physical,
  Digital,
}

export const initialShipping: ShippingContext = {
  currentStep: 1,
  investor: {} as Investor,
  physicalDocs: [],
  digitalDocs: [],
  trackingNum: '',
  printer: undefined,
};
const ShippingContext = createContext({} as ShippingContextValue);

function ShippingProvider(props) {
  const [shipping, setShipping] = useState<ShippingContext>(initialShipping);
  const value = useMemo(() => {
    const setShippingFunc = (obj: Partial<ShippingContext>) =>
      setShipping({ ...shipping, ...(obj as ShippingContext) });
    return [shipping, setShippingFunc];
  }, [shipping]);
  return <ShippingContext.Provider value={value} {...props} />;
}

function useShipping() {
  const context = useContext(ShippingContext);
  if (!context) {
    throw new Error('useShipping must be used within a ShippingProvider');
  }
  return context;
}

export { ShippingProvider, useShipping };
