/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useRef, useEffect } from 'react';

type OnOutsideClickProps = { onOutsideClick: () => void; children: React.ReactNode };

export default function OnOutsideClick({ onOutsideClick, children, styles }: OnOutsideClickProps) {
  const node = useRef(null);
  useEffect(() => {
    const handleClick = e => {
      if (!node.current || node.current.contains(e.target)) {
        return;
      }
      onOutsideClick();
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [onOutsideClick]);
  return (
    <div ref={node} css={styles}>
      {children}
    </div>
  );
}
