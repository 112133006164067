import axios, { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { FetchOptions, useFetch } from 'react-async';
import { useEffect, useState } from 'react';
import { localStorageKeys } from './constants';
import { getToken, msalInstance } from './auth-config';

localStorage.setItem(localStorageKeys.IS_REFRESH_REQUIRED, 'false');

const setIsRefreshRequired = responsePromise => {
  const isRefreshRequired = localStorage.getItem(localStorageKeys.IS_REFRESH_REQUIRED);
  if (isRefreshRequired === 'true') {
    return;
  }

  responsePromise.then(response => {
    const appVersion = response.headers['build-hash-version'];
    // will be equal to null if the build version changed
    if (
      appVersion !== undefined &&
      document.querySelector(`script[src="/dist/${appVersion}"]`) === null
    ) {
      localStorage.setItem(localStorageKeys.IS_REFRESH_REQUIRED, 'true');
    }
  });
};

export async function apiFetch<T>(url: string, config: AxiosRequestConfig = {}) {
  const accessToken = await getToken();
  config.headers = { Authorization: `Bearer ${accessToken}` };

  const responsePromise = axios.get<T>(url, config);
  setIsRefreshRequired(responsePromise);

  return responsePromise;
}

export async function apiPost<T>(url: string, data: object, config: AxiosRequestConfig = {}) {
  const accessToken = await getToken();

  config.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...config,
  };

  const responsePromise = axios.post<T>(url, data, config);
  setIsRefreshRequired(responsePromise);

  return responsePromise;
}

export async function graphApiFetch<T>() {
  const account = msalInstance.getActiveAccount();

  const response = await msalInstance.acquireTokenSilent({
    scopes: ['https://graph.microsoft.com/.default'],
    account: account || undefined,
  });

  const config = {
    headers: {
      Authorization: `Bearer ${response.accessToken}`,
      'Content-Type': 'application/json',
    },
  };

  return axios.get<T>('https://graph.microsoft.com/v1.0/me', config);
}

export async function fetchWithAuth(url: string, init = {}) {
  const token = await getToken();
  return fetch(url, merge({ headers: { Authorization: `Bearer ${token}` } }));
}

export interface AuthHeader {
  Authorization: string;
}

export function useFetchWithAuth<T>(url: string, init = {}, options: FetchOptions<T> = {}) {
  const [token, setToken] = useState('');
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [errorToken, setErrorToken] = useState(undefined);

  useEffect(() => {
    const fetchToken = async () => {
      setIsLoadingToken(true);
      try {
        const token = await getToken();
        setToken(token);
      } catch (error) {
        setErrorToken(error);
      } finally {
        setIsLoadingToken(false);
      }
    };

    fetchToken();
  }, []);

  const res = useFetch<T>(
    url,
    merge({ headers: { Authorization: `Bearer ${token}` } }, init),
    merge({ json: true }, options),
  );

  res.error = res.error || errorToken;
  res.isLoading = res.isLoading || isLoadingToken;

  useEffect(() => {
    // When the token becomes available, trigger the API request
    if (token.length > 0) {
      res.reload();
    }
  }, [token]);

  return res;
}
