/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import TabPanel from '../components/ui/Tabs/MaterialTabPanel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Report from '../components/Admin/Reports/RevenueBreakdown/Report';
import AddChargeOrCredit from '../components/Admin/Reports/RevenueBreakdown/AddChargeOrCredit';
import { AuthContext } from '../components/AuthContext';
import { roleTypes } from '../constants';

const RevenueBreakdownReport = () => {
  const [tab, setTab] = useState(0);

  const { roles } = useContext(AuthContext);

  const isExecutiveManagement = useMemo(
    () =>
      roles.findIndex(role => role === roleTypes.ExecutiveManagement || role === roleTypes.Dev) !==
      -1,
    [roles],
  );

  return (
    <div className="m5">
      {!isExecutiveManagement ? (
        <AddChargeOrCredit />
      ) : (
        <Fragment>
          <Tabs
            indicatorColor="primary"
            value={tab}
            textColor="primary"
            onChange={(event: React.ChangeEvent<{}>, newValue: number) => setTab(newValue)}
          >
            {['Report', 'Add Charge/Credit'].map((tabLabel, i) => (
              <Tab key={tabLabel} label={tabLabel} id={`tabpanel-${i}`} />
            ))}
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Report />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <AddChargeOrCredit />
          </TabPanel>
        </Fragment>
      )}
    </div>
  );
};

export default RevenueBreakdownReport;
