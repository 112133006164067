/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../../components/AuthContext';
import { apiFetch } from '../../adalConfig';
import { SalesRep } from './types';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { roleTypes } from '../../constants';
import { useToaster } from '../../Hooks/toasters';

const LandingPage = () => {
  const [salesReps, setSalesReps] = useState<SalesRep[]>([]);
  const [selectedSalesRep, setSelectedSalesRep] = useState<SalesRep>();

  const { roles } = useContext(AuthContext);

  const { errorToaster } = useToaster();

  const history = useHistory();

  const salesRepUrl = '/sales-reps-dashboard';

  const prepSalesRepDropdown = async () => {
    try {
      const { data } = await apiFetch('/api/sales-reps/sales-rep-dropdown');
      setSalesReps(data);
    } catch (e) {
      if (e.response) {
        const errorMessage = e.response.data.split('\n')[0];
        errorToaster(errorMessage || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const isManager =
    roles.findIndex(role => role === roleTypes.SalesManager || role === roleTypes.Dev) !== -1;

  useEffect(() => {
    if (isManager) {
      prepSalesRepDropdown();
    } else {
      history.push(salesRepUrl);
    }
  }, []);

  return (
    salesReps && (
      <div style={{ margin: '10px' }}>
        <Autocomplete
          options={salesReps}
          getOptionLabel={option => option.username}
          style={{ width: 300 }}
          renderInput={params => <TextField {...params} label="Sales Reps" variant="outlined" />}
          value={selectedSalesRep}
          onChange={(e, newValue) => setSelectedSalesRep(newValue)}
        />

        <Button
          color="primary"
          variant="contained"
          style={{ marginTop: '6px' }}
          onClick={() =>
            selectedSalesRep?.azureId &&
            history.push(`${salesRepUrl}?sales-rep-id=${selectedSalesRep.azureId}`)
          }
        >
          Submit
        </Button>
      </div>
    )
  );
};

export default LandingPage;
