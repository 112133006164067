import React, { Fragment, useState, useEffect } from 'react';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import { IconType } from '../ui/Icon';
import InvestorSearchSelect from '../Utilities/InvestorSearchSelect';
import { Investor, ContactType, ContactInformation } from '../../globalTypes/objects';
import Panel, { PanelHeader } from '../ui/Panel';
import AddNewContactWrapper from '../Utilities/Forms/AddNewContactWrapper';
import AddNewContactForm from '../Utilities/Forms/AddNewContactForm';
import { apiPost } from '../../adalConfig';
import BackButton from './BackButton';

export default function Investors() {
  const [selectedInvestor, setSelectedInvestor] = useState<Investor>();

  return (
    <Fragment>
      <div>
        <BackButton to="/admin">Admin Page</BackButton>
      </div>
      <Panel styles={{ margin: '40px', marginTop: 24 }}>
        <PanelHeader text="Manage Investors" />
        <AddNewContactWrapper contactInformation={{ type: ContactType.Investor }} onSave={() => {}}>
          <StatusButton
            styleOverrides={{ width: 250, marginTop: 32 }}
            initialIcon={IconType.Plus}
            status={StatusTypes.initial}
            onClick={() => {}}
          >
            Add Investor
          </StatusButton>
        </AddNewContactWrapper>
        <br />
        <label>Edit Investor</label>
        <InvestorSearchSelect onChange={selected => setSelectedInvestor(selected)} />
        {selectedInvestor && (
          <EditInvestor
            investor={selectedInvestor}
            closeForm={() => setSelectedInvestor(undefined)}
          />
        )}
      </Panel>
    </Fragment>
  );
}

const EditInvestor = ({ investor, closeForm }: { investor: Investor; closeForm: () => any }) => {
  const contactInfo: Partial<ContactInformation> = {
    type: ContactType.Investor,
    ...investor,
  };

  const updateContact = async (contact: ContactInformation) => {
    await apiPost('/api/investors/update', contact);
    closeForm();
  };
  return (
    <AddNewContactForm
      contactInformation={contactInfo}
      onCancelContact={closeForm}
      onSaveContact={updateContact}
    />
  );
};
