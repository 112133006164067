/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextFieldProps } from '@mui/material';
import InputMask from './InputMask';
import { StripPhone } from '../../../Utils';
import { TextInputProps } from './TextInput';

export const beforeMaskedValueChange = (newState, oldState, userInput, mask, propsValue) => {
  let { value, selection } = newState;
  let cursorPosition = selection ? selection.start : null;

  if (
    (value.endsWith('-') && userInput !== '-' && !propsValue.endsWith('-')) ||
    (value.endsWith('(') && userInput !== '(' && !propsValue)
  ) {
    if (cursorPosition === value.length) {
      cursorPosition -= 1;
      selection = { start: cursorPosition, end: cursorPosition };
    }
    value = value.slice(0, -1);
  }

  return {
    value,
    selection,
  };
};

export const phoneMask = '(999) 999-9999';

type PhoneInputProps =
  | (Omit<TextInputProps, 'onChange'> & {
      onChange: (value: string) => any;
      useMaterialUI?: false;
    })
  | (Omit<TextFieldProps, 'onChange'> & {
      onChange: (value: string) => any;
      useMaterialUI: true;
    });

export default function PhoneInput({ onChange, value, ...props }: PhoneInputProps) {
  return (
    <InputMask
      // @ts-ignore
      beforeMaskedValueChange={(...args) => beforeMaskedValueChange(...args, value)}
      mask={phoneMask}
      maskChar=""
      value={value}
      onChange={e => onChange!(StripPhone(e.target.value))} // returns value without dashes
      {...props}
    />
  );
}
