/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';

const overlay = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 72,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  zIndex: '10000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const localOverlay = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  zIndex: '10000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const floaterOne = keyframes`
    from { transform: rotate(0deg) }
    to  { transform: rotate(360deg) }
`;
export default function SpinChildren({
  children,
  displayOverlay,
  displayLocalOverlay,
  speed,
}: any) {
  return (
    <div css={[displayOverlay && overlay, displayLocalOverlay && localOverlay]}>
      <div
        className="relative"
        css={{
          animation: `${floaterOne} ${speed || '1'}s infinite linear`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
