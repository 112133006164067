import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { apiFetch, fetchWithAuth } from './adalConfig';
import {
  ClientReturnAddress,
  Document,
  DocumentPageDto,
  DocumentType,
} from './globalTypes/objects';
import { roleTypes } from './constants';
import { routesAuthorizedRoles } from './Router';
import { AxiosError } from 'axios';

// eslint-disable-next-line no-useless-escape
export const EmailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export function ValidateEmail(email: string) {
  if (EmailRegex.test(email)) {
    return true;
  }
  return false;
}

export function StripPhone(phone: string) {
  return phone.replace(/\D+/g, '');
}

export function FormatPhoneWithSpaces(phone: string) {
  return [phone.slice(0, 3), phone.slice(3, 6), phone.slice(6, 10)].join(' ');
}

export function formatPhoneWithParenthesis(phone: string) {
  if (!phone) {
    return '';
  }

  phone = StripPhone(phone);
  const phoneLength = phone.length;
  if (phoneLength <= 7) {
    return phone.replace(/(\d{3})(\d{1,4})/, '$1-$2');
  }
  if (phoneLength <= 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{1,4})/, '($1) $2-$3');
  }

  return phone;
}

export const DisplayTimeout = 1000;
export const DebounceRate = 800;

export const convertToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result as string;
      let encoded = base64data.replace(/^data:(.*;base64,)?/, '');
      if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4));
      }
      resolve(encoded as string);
    };
  });
};

export const fileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Text = reader.result as string;

      const breakPoint = 'base64,';
      const index = base64Text.indexOf(breakPoint) + breakPoint.length;

      resolve(base64Text.slice(index));
    };
    reader.onerror = error => reject(error);
  });

export function SelectFunctionality(
  event: KeyboardEvent,
  currentSelectedId: number,
  allIds: number[],
  selectedIds: Set<number>,
  prevSelectedId: number | undefined,
) {
  const selected = selectedIds;
  if (!prevSelectedId) {
    selected.add(currentSelectedId);
    return new Set(selected);
  }
  const lowerIndex = Math.min(allIds.indexOf(prevSelectedId), allIds.indexOf(currentSelectedId));
  const higherIndex = Math.max(allIds.indexOf(prevSelectedId), allIds.indexOf(currentSelectedId));
  if (selected.has(currentSelectedId)) {
    if (event.shiftKey) {
      const idsToDeselect = allIds.slice(lowerIndex, higherIndex + 1);
      idsToDeselect.map(i => selected.delete(i));
    } else {
      selected.delete(currentSelectedId);
    }
  } else if (event.shiftKey) {
    const idsToSelect = allIds.slice(lowerIndex, higherIndex + 1);
    idsToSelect.map(i => selected.add(i));
  } else {
    selected.add(currentSelectedId);
  }
  return isEqual(selected, selectedIds) ? selected : new Set(selected);
  // needs to be new Set otherwise hooks won't rerender
}

export function SelectAllFunctionality(allIds: number[], selectedIds: Set<number>) {
  return new Set(allIds.length === selectedIds.size ? [] : allIds);
}

export function convertToByteArray(b64Data) {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return byteArrays;
}

export function convertToFileDownload(b64Data, filename, filetype = 'application/octet-stream') {
  const byteArrays = convertToByteArray(b64Data);
  const blob = new Blob(byteArrays, { type: filetype });
  downloadBlob(blob, filename);
}

export function downloadBlob(blob, filename) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
}

export async function downloadFile(
  url: string,
  filename: string,
  filetype = 'application/octet-stream',
  useFileNameFromServer = false,
) {
  const { headers, data } = await apiFetch(url, {
    responseType: 'blob',
  });
  if (data.size === 0) throw new Error('There were no files returned to download');
  const blob = new Blob([data], { type: filetype });

  let fileNameFromServer;
  const fileParts = headers['content-disposition']?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);

  if (fileParts?.length >= 2) {
    fileNameFromServer = fileParts[1].replace(/"/g, '');
    if (useFileNameFromServer && fileNameFromServer) {
      filename = fileNameFromServer;
    }
  }

  downloadBlob(blob, filename);
}

export const downloadFiles = async (
  url: string,
  params: object,
  fileName: string,
  onDownloadProgress = (e: ProgressEvent) => {},
) => {
  const { data } = await apiFetch(url, {
    params,
    responseType: 'blob',
    onDownloadProgress,
  });
  if (data.size === 22) throw new Error('There were no files returned to download');

  const blob = new Blob([data], { type: 'application/zip' });
  downloadBlob(blob, `${fileName}.zip`);
};

export const openOrDownloadFile = (url: string, fileName: string) => {
  // eslint-disable-next-line no-restricted-globals
  const fileWindow = open(url);

  // AdBlockers can block opening up new tabs with pdfs. In such events we'll download the file
  setTimeout(() => {
    if (!fileWindow || fileWindow.closed) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      window.URL.revokeObjectURL(url);
    }
  }, 800);
};

export function buildArrayFetchString<T>(array: T[], paramName: string) {
  return array.reduce((param, d, i) => `${param}&${paramName}[${i}]=${d}`, '').substr(1);
}

export function getDocTypeName(docType: DocumentType) {
  const docTypes = Object.entries(DocumentType).find(([_key, val]) => val === docType);
  return docTypes![0];
}

export const docTypes = Object.entries(DocumentType)
  .map(([id, name]) => ({
    id: Number(id),
    docType: name,
  }))
  .filter(({ id }) => id);

export function elementScrolledToBottom(e) {
  const element = e.target;
  return element.scrollHeight - element.scrollTop <= 310; // element.clientHeight has a maxHeight of 300
}

export const isAdmin = (userRoles: string[]) =>
  userRoles.findIndex(
    role =>
      role === roleTypes.ExecutiveManagement || role === roleTypes.Admin || role === roleTypes.Dev,
  ) !== -1;

export const isClientAdmin = (userRoles: string[]) =>
  userRoles.findIndex(
    role =>
      role === roleTypes.ExecutiveManagement ||
      role === roleTypes.Admin ||
      role === roleTypes.ClientAdmin ||
      role === roleTypes.Dev,
  ) !== -1;

export const isAccountRep = (userRoles: string[]) =>
  userRoles.findIndex(
    role =>
      role === roleTypes.ExecutiveManagement ||
      role === roleTypes.Admin ||
      role === roleTypes.AccountRep ||
      role === roleTypes.Dev,
  ) !== -1;

export const isTeamLead = (userRoles: string[]) =>
  userRoles.findIndex(
    role =>
      role === roleTypes.DigitalTeamLead ||
      role === roleTypes.QCTeamLead ||
      role === roleTypes.ScanningTeamLead ||
      role === roleTypes.ShippingTeamLead ||
      role === roleTypes.Dev,
  ) !== -1;

export const isBasicUser = (userRoles: string[]) =>
  userRoles.findIndex(role =>
    [
      roleTypes.ExecutiveManagement,
      roleTypes.Admin,
      roleTypes.Dev,
      roleTypes.CallCenterAdmin,
      roleTypes.AccountRep,
      roleTypes.BasicUser,
    ].includes(role),
  ) !== -1;

export const isDataAssociation = (userRoles: string[]) =>
  userRoles.findIndex(role =>
    [
      roleTypes.DataAssociation,
      roleTypes.ExecutiveManagement,
      roleTypes.Admin,
      roleTypes.Dev,
    ].includes(role),
  ) !== -1;

export const hasAccessToRoute = (roles: string[], routeUrl: string) => {
  const authorizedRoles = routesAuthorizedRoles.get(routeUrl.toLocaleLowerCase());

  return (
    authorizedRoles &&
    (!authorizedRoles.length || roles.some(role => authorizedRoles.includes(role)))
  );
};

export const parseRequestError = (e: AxiosError): string[] => {
  if (!e.response) {
    return [e.message];
  }

  if (e.response.data?.errors) {
    return e.response.data.errors.map(({ message }) => message);
  }

  const errorMessage = e.response.data.split('\n')[0];
  return [errorMessage || e.message];
};

export function stripStringOfWhitespaceAndSplitByComma(strng: string) {
  return strng
    .replace(/,\s*$/, '')
    .replace(/[\n\r]/g, '')
    .replace(/\s/g, '')
    .split(',');
}

export function objectDiff(obj1, obj2) {
  return Object.entries(obj1)
    .filter(([key, value]) => {
      return obj2[key] !== value;
    })
    .map(([key]) => key);
}

export const getDocTypes = async () => {
  const { data: documentTypes } = await apiFetch<{ id: number; description: string }[]>(
    '/api/Documents/GetTypes',
  );
  return documentTypes
    .map(docType => ({
      label: docType.description,
      value: docType.id,
    }))
    .sort((a, b) => (['Mortgage', 'Policy'].includes(a.label) ? -1 : 0));
};

export async function documentPageData(id: number | string): Promise<DocumentPageDto | null> {
  const res = await fetchWithAuth(`/Api/Documents/DocumentPage/${id}`);
  const text = await res.text();
  return text === '' ? null : JSON.parse(text);
}

export function isDocument(document?: Document | number): document is Document {
  if (!document) return false;
  return typeof document !== 'number';
}

export const clientReturnAddressDescriptions = new Map<ClientReturnAddress, string>([
  [ClientReturnAddress.OceanAvenue1125, '1125 Ocean Avenue'],
  [ClientReturnAddress.OceanAvenue1133, '1133 Ocean Avenue'],
  [ClientReturnAddress.SwarthmoreAvenue1820, '1820 Swarthmore Avenue'],
  [ClientReturnAddress.ChambersAvenue160, '160 Chambers Avenue'],
]);

export function isBlank(val: unknown) {
  return isEmpty(val) && isNil(val);
}

// add item if not in array, remove if exists
// uses Lodash's `isEqual` by default to compare items
export function toggle<T>(arr: T[], item: T, comparer: (a: T, b: T) => boolean = isEqual): T[] {
  const removed = arr.filter(i => !comparer(i, item));
  return removed.length === arr.length ? arr.concat(item) : removed;
}

export type Nullable<T, K extends keyof T> = {
  [P in keyof T]: P extends K ? T[P] | null : T[P];
};
