/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FunctionComponent } from 'react';
import Circle from './Circle';
import colors from '../../styles/colors';
import ActionCircle from './ActionCircle';
import { IconType } from './Icon';

const escape = {
  color: colors.grayDark,
  fontSize: 14,
  marginRight: 15,
  marginTop: 20,
  position: 'absolute',
  right: 0,
  textAlign: 'center',
  top: 0,
};

interface EscapeIconProps {
  onClick: () => void;
  styleOverrides?: object;
}

const EscapeIcon: FunctionComponent<EscapeIconProps> = props => (
  <div css={escape} {...props}>
    <ActionCircle icon={IconType.XLarge} />
    <br />
    esc
  </div>
);

export default EscapeIcon;
