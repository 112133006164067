/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import colors from '../../../../styles/colors';

export const wrapStyles: CSSObject = {
  alignItems: 'center',
  display: 'inline-flex',
  height: 48,
  justifyContent: 'center',
  position: 'relative',
  transition: 'border .2s, background-color .2s',
  width: 48,
  borderRadius: '50%',
  ' svg': { color: colors.blue },
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: colors.grayLight,
  },
};

export const tooltipStyles: CSSObject = { '&:before': { left: 38 } };

export const emailClient: CSSObject = {
  '&:hover': {
    '&:before': {
      content: '"Email client"',
      width: 112,
      left: '-32px',
    },
  },
};

export const rowWrap: CSSObject = {
  alignItems: 'center',
  backgroundColor: colors.white,
  boxShadow: `0 0 0 1px ${colors.grayLight}`,
  borderRadius: 4,
  display: 'flex',
  height: 80,
  marginBottom: 8,
  padding: '0 32px',
  transition: 'all .2s',
  width: '100%',
  '&:hover': {
    boxShadow: `0 0 0 1.4px ${colors.blue}`,
    cursor: 'pointer',
  },
};

export const pinnedDotWrap: CSSObject = {
  marginRight: 16,
  width: 10,
  ' div': {
    backgroundColor: colors.red,
    borderRadius: '50%',
    height: 10,
    width: 10,
  },
};

export const notesSection: CSSObject = {
  alignItems: 'center',
  display: 'inline-flex',
  width: 221,
  ' p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 152,
  },
  ' svg': { color: colors.blue, marginRight: 16 },
  ' span': { color: colors.grayDark },
};

export const missingDataStyle: CSSObject = {
  alignItems: 'center',
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 700,
  paddingRight: 8,
  width: 352,
};

export const headerWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  height: 48,
};
export const clientName: CSSObject = {
  fontSize: '20',
  fontWeight: 700,
  margin: '0 24px 0 16px',
};
export const errorHeaderWrap: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 32,
  margin: '24px 0 10px',
  ' div': {
    fontWeight: 700,
    color: colors.grayDark,
  },
};
