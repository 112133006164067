/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { Fragment, useEffect, useState } from 'react';
import ShortId from 'shortid';

import { ShipmentDTO, ShippingContext, ShippingDoc, useShipping } from './useShipping';
import { StepComponentPanel } from '../Utilities/StepUI/StepPanel';
import { Document, Loan } from '../../globalTypes/objects';
import { apiFetch, apiPost } from '../../adalConfig';
import { buildArrayFetchString, getDocTypeName } from '../../Utils';
import { IconType } from '../ui/Icon';
import ActionCircle from '../ui/ActionCircle';
import { HeaderWrapper, RowWrapper } from '../ui/HeaderAndRows';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import { SuccessTimeout } from '../Imports/ImportErrors/ImportErrorsUtils';
import { printCoverletterFiles, printTransmittal as sendTransmittalForPrint } from './StepTwo';

const griddy = {
  gridColumnGap: '8px',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
};

export default function StepThree() {
  const [shipping] = useShipping();

  const [loading, setLoading] = useState(true);
  const [submittedDigitals, setSubmittedDigitals] = useState<
    { document: Document; loan: Loan }[]
  >();
  const [printTransmittalStatus, setPrintTransmittalStatus] = useState(StatusTypes.initial);
  const [printCoverletterStatus, setPrintCoverletterStatus] = useState(StatusTypes.initial);

  useEffect(() => {
    const getSubmitted = async () => {
      const { data: submitted } = await apiFetch<{ document: Document; loan: Loan }[]>(
        `/api/shipping/digitalDocInfo?${buildArrayFetchString(
          shipping.digitalDocs.map(d => d.docId),
          'docIds',
        )}`,
      );
      setSubmittedDigitals(submitted);
      setLoading(false);
    };
    getSubmitted();
  }, [shipping.digitalDocs]);

  return (
    <StepComponentPanel headerText="Validate" number={3}>
      {loading ? null : (
        <Fragment>
          {shipping.digitalDocs.length > 0 && (
            <Fragment>
              <HeaderWrapper styles={griddy}>
                <div>Borrower Name</div>
                <div>Loan Number</div>
                <div>Document Type</div>
              </HeaderWrapper>
              {submittedDigitals!.map(d => (
                <DigitalRow document={d.document} loan={d.loan} key={ShortId.generate()} />
              ))}
            </Fragment>
          )}
          <br />
          <StatusButton
            onClick={() => printTransmittal(shipping, setPrintTransmittalStatus)}
            status={printTransmittalStatus}
          >
            Reprint Transmittal
          </StatusButton>
          {/* give ability to reprint investor coverletters (not manifests for now) */}
          {shipping.investor.coverletterBasePath &&
            !shipping.investor.manifestMaximum &&
            shipping.physicalDocs.length && (
              <StatusButton
                onClick={() =>
                  printCoverletter(
                    shipping,
                    shipping.physicalDocs.map(d => d.docId),
                    setPrintCoverletterStatus,
                  )
                }
                status={printCoverletterStatus}
              >
                Reprint Coverletters
              </StatusButton>
            )}
        </Fragment>
      )}
    </StepComponentPanel>
  );
}

const printDigital = async (document: Document, loan: Loan, shipping: ShippingContext) => {
  const digitalDTO: ShipmentDTO<ShippingDoc> = {
    trackingNum: shipping.trackingNum,
    docs: [{ docId: document.id, clientId: loan.clientID }],
    investorId: loan.investorID,
    printerId: shipping.printer!.id,
  };
  await apiPost('/api/shipping/digitals', digitalDTO);
};

const printTransmittal = async (
  shipping: ShippingContext,
  setStatus: (status: StatusTypes) => void,
) => {
  setStatus(StatusTypes.loading);
  const transmittalDTO: ShipmentDTO<ShippingDoc> = {
    trackingNum: shipping.trackingNum,
    docs: [...shipping.physicalDocs, ...shipping.digitalDocs],
    investorId: shipping.investor.id,
    printerId: shipping.printer!.id,
  };

  await sendTransmittalForPrint(transmittalDTO);

  setStatus(StatusTypes.success);
  setTimeout(() => {
    setStatus(StatusTypes.initial);
  }, SuccessTimeout);
};

const printCoverletter = async (
  shipping: ShippingContext,
  docs: number[],
  setStatus: (status: StatusTypes) => void,
) => {
  setStatus(StatusTypes.loading);
  await printCoverletterFiles(shipping, docs);
  setStatus(StatusTypes.success);
  setTimeout(() => {
    setStatus(StatusTypes.initial);
  }, SuccessTimeout);
};

const DigitalRow = ({ document, loan }: { document: Document; loan: Loan }) => {
  const [shipping] = useShipping();

  return (
    <RowWrapper styles={griddy}>
      <div>{loan.borrower}</div>
      <div>{loan.loanNumber}</div>
      <div>{getDocTypeName(document.documentType)}</div>
      <ActionCircle
        onClick={() => printDigital(document, loan, shipping)}
        icon={IconType.Printer}
      />
    </RowWrapper>
  );
};
