/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { LinearProgress } from '@mui/material';
import { Client } from '../globalTypes/objects';
import ClientSearch from './Utilities/ClientSearch';
import colors from '../styles/colors';
import Panel, { PanelHeader } from './ui/Panel';
import StatusButton, { StatusTypes } from './ui/StatusButton';
import { DisplayTimeout, downloadFiles } from '../Utils';
import DateRange from './Admin/DateRange';
import { apiFetch } from '../adalConfig';

enum Mode {
  singleDate,
  dateRange,
}

export default function DownloadDocuments({
  location,
  history,
}: {
  location: Location;
  history: any;
}) {
  const [client, setClient] = useState<Client>();
  const currentDate = new Date();
  const [from, setFrom] = useState(currentDate);
  const [to, setTo] = useState(currentDate);
  const [status, setStatus] = useState(StatusTypes.initial);
  const [bytesDownloaded, setBytesDownloaded] = useState<number>();
  const [downloadSize, setDownloadSize] = useState<number>();

  const percentDownloaded = useMemo(() => {
    if (status === StatusTypes.initial || status === StatusTypes.error) return null;
    if (status === StatusTypes.success) return 100;
    if (status === StatusTypes.loading && bytesDownloaded && downloadSize) {
      return Math.min(100, (bytesDownloaded / (downloadSize * 0.8)) * 100); // adjust download size to account for zip compression
    }
    return null;
  }, [bytesDownloaded, downloadSize, status]);

  const download = async () => {
    setStatus(StatusTypes.loading);
    try {
      apiFetch<number>(`/Api/Documents/GetBatchDownloadSize`, {
        params: { clientId: client!.id, clientName: client!.company, to, from },
      }).then(({ data }) => {
        setDownloadSize(data);
      });

      await downloadFiles(
        '/api/documents/downloadDocumentsByDate',
        {
          clientId: client!.id,
          clientName: client!.company,
          to,
          from,
        },
        client!.company,
        progressEvent => {
          setBytesDownloaded(progressEvent.loaded);
        },
      );
    } catch {
      setStatus(StatusTypes.error);
    }
    setStatus(StatusTypes.success);
    setTimeout(() => {
      setStatus(StatusTypes.initial);
    }, DisplayTimeout);
  };

  return (
    <React.Fragment>
      <Panel styles={{ margin: 40 }}>
        <PanelHeader text="Download Documents" headerStyles={{ marginBottom: 32 }} />
        <ClientSearch onChange={c => setClient(c)} />
        <br />
        <DateRange
          handleFromChange={f => setFrom(f)}
          handleToChange={t => {
            setTo(t);
          }}
        />
        <StatusButton disabled={!client} onClick={() => download()} text="Submit" status={status} />
        {percentDownloaded && (
          <Fragment>
            <LinearProgress
              css={{ marginTop: 15 }}
              variant="determinate"
              value={percentDownloaded}
            />
            <div css={{ textAlign: 'center', marginTop: 3, color: colors.grayDark }}>
              {Math.round(percentDownloaded)}%
            </div>
          </Fragment>
        )}
      </Panel>
    </React.Fragment>
  );
}
