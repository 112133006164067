/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import ShortId from 'shortid';

import colors from '../../../styles/colors';
import { Client } from '../../../globalTypes/objects';

const searchByStyles = {
  fontSize: 11,
  fontWeight: 700,
  textTransform: 'uppercase',
};

type NoResultsProps = {
  handleClick: (searchBy: SearchType) => void;
  searchBy: SearchType;
  clientMatches: Client[];
};

export enum SearchType {
  LoanNumber = 'Loan Number',
  Address = 'Address',
  Borrower = 'Borrower Name',
}

export default function NoResults({ handleClick, searchBy, clientMatches }: NoResultsProps) {
  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <span css={{ color: colors.black, lineHeight: '18px' }}>No results</span>
        <span css={searchByStyles}>
          SEARCH BY&nbsp;&nbsp;
          {Object.entries(SearchType)
            .map(s => s[1])
            .filter(value => value !== searchBy)
            .map(value => (
              <span
                key={ShortId.generate()}
                css={{ ...searchByStyles, ...{ color: colors.blue } }}
                onClick={() => handleClick(value)}
              >
                {value}&nbsp;&nbsp;&nbsp;
              </span>
            ))}
        </span>
      </div>
      {clientMatches.length !== 0 && (
        <div css={{ color: colors.red, textAlign: 'left', marginTop: 16 }}>
          <p css={{ paddingBottom: 6 }}>{searchBy} exists in:</p>
          {clientMatches.map(c => (
            <Fragment key={c.id}>
              <div css={{ fontWeight: 700, marginBottom: -8 }}>{c.company}</div>
              <br />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
}
