/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { ClientInvestors as RowData } from './types';

type Props = {
  investorsPerClient: RowData[];
};

const columns = [
  {
    field: 'client',
    title: 'Lender',
  },
  {
    field: 'investors',
    title: 'Investors',
    render: (investorsPerClient: RowData) =>
      investorsPerClient.investors.map(investor => <div>{investor}</div>),
  },
];

const ClientInvestors = ({ investorsPerClient }: Props) => {
  return (
    <DataTable<RowData>
      title="Active Investors Per Client"
      columns={columns}
      data={investorsPerClient}
      options={{
        maxBodyHeight: '500px',
        search: false,
        exportFileName: 'Active Investors Per Client',
      }}
    />
  );
};

export default ClientInvestors;
