/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import DataTable from '../../components/ui/DataTable/DataTable';

export type RowData = {
  client: string;
  amountPerMonth: number;
  amountPerQuarter: number;
};

const columns = [
  {
    field: 'client',
    title: 'Client',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'amountPerMonth',
    title: 'Amount Per Month',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'amountPerQuarter',
    title: 'Amount Per Quarter (Billable)',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
];

const LoansReceived = () => {
  const [clientLoansDate, setClientLoanDate] = useState<Date | null>(null);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});

  useEffect(() => {
    clientLoansDate !== null && refreshDatatable();
  }, [clientLoansDate]);

  return (
    <Fragment>
      <b>Loan Volume Counts</b>
      <div>
        <DatePicker
          selected={clientLoansDate}
          onChange={dateTime => setClientLoanDate(dateTime)}
          placeholderText="Click to select a date"
          dateFormat="MMMM yyyy"
          showMonthYearPicker
        />
      </div>
      {clientLoansDate !== null && (
        <DataTable<RowData>
          title="Loans Received - By Client"
          columns={columns}
          url={`/api/reports/loans-received-count?monthAndYear=${
            clientLoansDate.toISOString().split('T')[0]
          }`}
          onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
          options={{
            maxBodyHeight: '500px',
          }}
        />
      )}
    </Fragment>
  );
};

export default LoansReceived;
