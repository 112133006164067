import { CSSObject } from '@emotion/core';
import colors from '../../../styles/colors';

export const borderStyles: CSSObject = {
  border: `1px solid ${colors.grayLight}`,
  borderRadius: '100px',
  padding: '8px',
  display: 'inline-block',
};

export const tabStyles: CSSObject = {
  color: colors.blueLight,
  border: `1px solid ${colors.blueLight}`,
  borderRadius: 100,
  lineHeight: '48px',
  fontSize: '13px',
  textAlign: 'center',
  fontFamily: 'sans-serif',
  fontWeight: 'bold',
  padding: '0 25px',
  marginLeft: 8,
  display: 'inline-block',
  height: 48,
  transition: 'all .2s',
  cursor: 'pointer',
  ' :first-of-type': {
    marginLeft: 0,
  },
  ':hover': {
    backgroundColor: colors.blueLight,
    color: colors.white,
    ' span': {
      color: colors.white,
    },
  },
};

export const selectedTabStyles: CSSObject = {
  '&&': {
    backgroundColor: colors.blueLight,
    color: colors.white,
    pointerEvents: 'none',
  },
};

export const numStyles: CSSObject = {
  borderLeft: '1px solid currentColor',
  color: colors.blueLight,
  display: 'inline-block',
  fontSize: '13px',
  fontWeight: 'bold',
  lineHeight: '15px',
  marginLeft: '13px',
  paddingLeft: 8,
};

export const selectedNumStyles: CSSObject = {
  color: colors.white,
};

export const disabledTab: CSSObject = {
  border: `1px solid ${colors.grayLight}`,
  color: colors.grayLight,
  pointerEvents: 'none',
  ':hover': {
    border: `1px solid ${colors.grayLight}`,
    backgroundColor: colors.white,
    color: colors.grayLight,
  },
};
