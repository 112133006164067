/* eslint-disable default-case */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DocumentType } from '../../globalTypes/objects';
import Dropdown from '../ui/Dropdown';

const docTypes = Object.entries(DocumentType)
  .filter(([key, val]) => typeof val === 'number')
  .map(([key, val]) => ({ label: key, value: val }));

export default function DocTypeSelect({
  docType,
  onSelect,
}: {
  docType: DocumentType | undefined;
  onSelect: (dt: DocumentType) => any;
}) {
  return (
    <Dropdown
      options={docTypes}
      placeholder="Document Type"
      onChange={({ value }: { value: DocumentType }) => onSelect(value)}
      value={docType ? docTypes.find(({ value }) => value == docType) : ''}
    />
  );
}
