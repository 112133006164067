/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, ReactNode, useContext, useState } from 'react';
import queryString from 'query-string';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from '@mui/material';
import DataTable from '../components/ui/DataTable/DataTable';
import ConditionalComponent from '../components/ConditionalComponent';
import { apiFetch } from '../adalConfig';
import { DocumentTypeProperties, DocumentTypesContext } from '../DocumentTypesContext';
import { Event, EventDisplay } from '../components/Documents/EventDisplay';
import UpdateDocumentTypeModal from '../components/Documents/Index/UpdateDocumentTypeModal';
import DeleteDocumentModal from '../components/Documents/Index/DeleteDocumentModal';
import DocumentSuggestedMatchesModal from '../components/Documents/Index/DocumentSuggestedMatchesModal';
import useDataTableFilters from '../components/ui/DataTable/UseDataTableFilters';
import colors from '../styles/colors';
import { openOrDownloadFile } from '../Utils';
import { useClient, useClients } from '../Hooks/useClients';
import { useToaster } from '../Hooks/toasters';

export type RowData = {
  loanId: number;
  documentId: number;
  documentType: string;
  appNumber: string;
  hardCopy: boolean;
  documentCreatedBy: string;
  createdAt: Date;
  passedAudit: boolean | null;
  failedVerification: boolean;
  corrected: boolean;
  failedAuditNote: string;
  events: Event[];
};

const ClientDocumentIndex = ({ location }) => {
  const clients = useClients();
  const clientId = queryString.parse(location.search).clientId as string;
  const clientName = useClient(parseInt(clientId))?.company;

  const { dataTableFilterTypes, setDataTableFilterTypes } =
    useDataTableFilters('/api/documents/filters');

  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});
  const [selectedDocument, setSelectedDocument] = useState<RowData | null>(null);
  const { docTypes } = useContext(DocumentTypesContext);
  const [selectedDocType, setSelectedDocType] = useState<DocumentTypeProperties>(
    {} as DocumentTypeProperties,
  );
  const [isUpdateDocTypeModalOpen, setIsUpdateDocTypeModalOpen] = useState(false);
  const [confirmedDeleteDialogOpen, setConfirmedDeleteDialogOpen] = useState(false);
  const [isDocumentSuggestedMatchesModalOpen, setIsDocumentSuggestedMatchesModalOpen] =
    useState(false);

  const { errorToaster } = useToaster();

  const prepareDocTypeUpdateModal = (event, rowData: RowData) => {
    event.preventDefault();

    setSelectedDocument(rowData);

    if (!selectedDocType.value && docTypes.length) {
      setSelectedDocType(
        docTypes.find(docType => docType.label === rowData.documentType) ??
          ({} as DocumentTypeProperties),
      );
    }
    setIsUpdateDocTypeModalOpen(true);
  };

  const columns = [
    {
      field: 'documentId',
      title: 'Barcode',
    },
    {
      field: 'documentType',
      title: 'Document Type',
      render: (rowData: RowData) => (
        <div style={{ fontWeight: 'bold' }}>
          <span
            css={{ cursor: 'pointer', marginRight: '10px' }}
            onClick={e => prepareDocTypeUpdateModal(e, rowData)}
          >
            <EditIcon htmlColor={rowData.appNumber ? 'red' : ''} />
          </span>
          <span>{rowData.documentType}</span>

          <ConditionalComponent display={rowData.passedAudit === false && !rowData.corrected}>
            <div style={{ color: 'red', fontSize: '12px' }}>Failed Audit</div>
          </ConditionalComponent>

          <ConditionalComponent display={rowData.failedVerification && !rowData.corrected}>
            <div style={{ color: 'red', fontSize: '12px' }}>Failed Verification</div>
          </ConditionalComponent>

          <ConditionalComponent display={rowData.corrected}>
            <div style={{ color: colors.blue, fontSize: '12px' }}>Corrected</div>
          </ConditionalComponent>
        </div>
      ),
    },
    {
      field: 'appNumber',
      title: 'Loan Number',
      render: (rowData: RowData) => (
        <Link target="_blank" href={`/loans/${rowData.loanId}`}>
          {rowData.appNumber}
        </Link>
      ),
    },
    {
      field: 'hardCopy',
      title: 'Format',
      render: (rowData: RowData) => (rowData.hardCopy ? 'Physical' : 'Digital'),
    },
    {
      field: 'documentCreatedBy',
      title: 'User',
      sorting: false,
      render: (rowData: RowData) =>
        rowData.documentCreatedBy?.replace(/@(?:docprobe\.net|madisoncres\.com)/g, ''),
    },
    {
      field: 'createdAt',
      title: 'Date Created',
      render: (rowData: RowData) => rowData && new Date(rowData.createdAt).toLocaleDateString(),
    },
    {
      field: 'passedAudit',
      title: 'Passed Audit',
      hidden: true,
    },
    {
      field: 'failedAuditNote',
      title: 'Failed Audit Note',
      hidden: true,
    },
    {
      field: 'failedVerification',
      title: 'Failed Verification',
      hidden: true,
    },
  ];

  const downloadDocument = async (documentId: number) => {
    try {
      const { data } = await apiFetch('/api/documents/getDocumentPdf', {
        responseType: 'blob',
        params: { documentId },
      });

      const url = window.URL.createObjectURL(data);
      openOrDownloadFile(url, `Document - ${documentId}.pdf`);
    } catch (e) {
      if (e.response) {
        const errorMessage = await new Response(e.response.data).text();
        errorToaster(errorMessage.split('\n')[0] || e.message);
      } else {
        errorToaster(e.message);
      }
    }
  };

  const detailPanel = (rowData: RowData): ReactNode => (
    <ConditionalComponent display={rowData.events?.length}>
      <div
        style={{
          padding: '16px 60px',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridRowGap: '16px',
        }}
      >
        {rowData.events?.map(docEvent => EventDisplay({ docEvent, clients }))}
      </div>
      {!!rowData.failedAuditNote && (
        <div style={{ padding: '16px 60px' }}>
          <span style={{ backgroundColor: 'yellow' }}>
            Failed Audit Note: {rowData.failedAuditNote}
          </span>
        </div>
      )}
    </ConditionalComponent>
  );

  return (
    <Fragment>
      <DataTable<RowData>
        title={
          <span>
            Documents
            <span css={{ verticalAlign: 'middle' }}>
              <KeyboardArrowRightIcon color="action" />
            </span>
            {clientName}
          </span>
        }
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        columns={columns}
        url={`/api/documents/document-index-data?clientId=${clientId}`}
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        detailPanel={detailPanel}
        options={{
          actionsColumnIndex: -1,
          exportFileName: `${clientName} - Document Index`,
        }}
        actions={() => [
          {
            icon: () => <OpenInNewIcon style={{ color: 'rgb(76, 175, 80)' }} />,
            tooltip: 'View Document',
            onClick: (event, rowData: RowData) => open(`/documents/${rowData.documentId}`),
          },
          {
            icon: () => <DeleteIcon style={{ color: 'red' }} />,
            tooltip: 'Delete Document',
            onClick: (event, rowData: RowData) => {
              setSelectedDocument(rowData);
              setConfirmedDeleteDialogOpen(true);
            },
          },
          {
            icon: () => <CloudDownloadIcon color="action" />,
            tooltip: 'Download Document',
            onClick: (event, rowData: RowData) => downloadDocument(rowData.documentId),
          },
          (rowData: RowData) => ({
            icon: () => <FindInPageIcon color="primary" />,
            tooltip: 'Search For Suggested Match',
            onClick: (event, rowData: RowData) => {
              setSelectedDocument(rowData);
              setIsDocumentSuggestedMatchesModalOpen(true);
            },
            hidden: !!rowData.appNumber,
          }),
        ]}
      />

      <UpdateDocumentTypeModal
        isOpen={isUpdateDocTypeModalOpen}
        setIsOpen={setIsUpdateDocTypeModalOpen}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        selectedDocType={selectedDocType}
        setSelectedDocType={setSelectedDocType}
        refreshDatatable={refreshDatatable}
      />

      <DeleteDocumentModal
        isOpen={confirmedDeleteDialogOpen}
        setIsOpen={setConfirmedDeleteDialogOpen}
        selectedDocument={selectedDocument}
        refreshDatatable={refreshDatatable}
      />

      <DocumentSuggestedMatchesModal
        isOpen={isDocumentSuggestedMatchesModalOpen}
        setIsOpen={setIsDocumentSuggestedMatchesModalOpen}
        selectedDocument={selectedDocument}
      />
    </Fragment>
  );
};

export default ClientDocumentIndex;
