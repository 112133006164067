/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import { DocumentEventDrillDownRowData, DrillDownData, RowData } from './types';
import DataTable from '../../components/ui/DataTable/DataTable';
import queryString from 'query-string';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  pdfImageSavedEventsByUser: RowData[];
};

const docEventDrillDownColumns = [
  {
    field: 'barcode',
    title: 'Barcode',
    options: {
      filter: false,
    },
  },
  {
    field: 'loanNumber',
    title: 'Loan Number',
    options: {
      filter: false,
    },
  },
  {
    field: 'lender',
    title: 'Lender',
    options: {
      filter: false,
    },
  },
  {
    field: 'user',
    title: 'User',
    render: (rowData: DocumentEventDrillDownRowData) =>
      rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
    options: {
      filter: false,
    },
  },
  {
    field: 'date',
    title: 'Date',
    render: (rowData: DocumentEventDrillDownRowData) => new Date(rowData.date).toLocaleDateString(),
    options: {
      filter: false,
    },
  },
];

export const ScannedIntoAzureByUser = ({ pdfImageSavedEventsByUser }: Props) => {
  const [drillDownData, setDrillDownData] = useState<DrillDownData | null>(null);

  const classes = useStyles();

  const columns = [
    {
      field: 'count',
      title: 'Count',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() =>
            setDrillDownData({
              date: new Date(rowData.date),
              user: rowData.user,
            })
          }
        >
          {rowData.count}
        </Link>
      ),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'user',
      title: 'User',
      options: {
        filter: false,
      },
      render: (rowData: RowData) => rowData.user?.replace(/@(?:docprobe.net|madisoncres.com)/g, ''),
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
    },
    {
      field: 'date',
      title: 'Date',
      options: {
        filter: false,
      },
      cellStyle: {
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      render: (rowData: RowData) => new Date(rowData.date).toLocaleDateString(),
    },
  ];

  return (
    <Fragment>
      <DataTable<RowData>
        title="Physical Docs Scanned (to Azure) - By User"
        columns={columns}
        data={pdfImageSavedEventsByUser}
        options={{
          maxBodyHeight: '500px',
          search: false,
        }}
      />

      <Modal open={!!drillDownData} onClose={() => setDrillDownData(null)}>
        <div className={classes.paper}>
          <DataTable<DocumentEventDrillDownRowData>
            title="Scanned Events Drill Down Report"
            columns={docEventDrillDownColumns}
            url={`/api/reports/document-event-drill-down-report?${queryString.stringify({
              user: drillDownData?.user,
              date: drillDownData?.date.toISOString().split('T')[0],
              documentEvent: 'PdfImageSaved',
            })}`}
            options={{
              search: false,
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};
