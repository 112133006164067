import { useGetData } from './index';
import { Printer } from '../components/Utilities/Dropdowns/PrinterDropdown';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';

const useLabelPrinters = (localStorageKey: string) => {
  const { data: printers } = useGetData<Printer[]>('/api/printers/label-printers', []);

  const [printer, setPrinter] = useState<Printer | null>(null);

  const [printerId, setPrinterId] = useLocalStorage<number>(localStorageKey);

  useEffect(() => {
    if (!printerId || printers.length === 0) {
      return;
    }

    const localStoragePrinter = printers.find(p => p.id === printerId);
    if (localStoragePrinter) {
      setPrinter(localStoragePrinter);
    }
  }, [printerId, printers]);

  return { printers, printer, setPrinter, setPrinterId };
};

export default useLabelPrinters;
