export function DeeForty() {
  return (
    <svg
      enableBackground="new 0 0 1807 1824"
      viewBox="0 0 1807 1824"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m790.1 522.4v130.2h-135.1v1171.4h-130.4v-1171.5h-130.5v1171.5h-130.3v-1171.5h-130.4v1171.5h-130.4v-1171.5-130.2zm144.9-266.1c332 0 599.5 304.7 599.5 640.7 0 341.6-267.5 651.8-599.5 651.8h-152.1v-130.7h107.3c283.2 0 514.9-233.6 514.9-520.6 0-281.8-231.7-510.2-514.9-510.2h-890.2v-131.1h2.2zm872 648.3c0 481.9-377.6 919.4-846.5 919.4h-176v-130.2h113.5c429.8 0 781.4-354.2 781.4-789.2 0-427.1-351.5-773.6-781.4-773.6l-895 .1v-130.3h957.5c468.9 0 846.5 429.8 846.5 903.8z"
        fill="#f1f1f1"
        fillRule="evenodd"
      />
    </svg>
  );
}

export const pageWrap = {
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: '#666',
  position: 'relative',
  overflow: 'hidden',
};

export const backgroundD = {
  position: 'absolute',
  width: '100%',
  maxWidth: '85%',
  height: 'auto',
};

export const titleWrap = {
  textShadow: '0px -1px 0px rgba(0, 0, 0, 0.5)',
  fontSize: 82,
  position: 'absolute',
  top: 32,
  right: 64,
  zIndex: 25,
  fontWeight: 300,
  span: {
    color: '#0828cc',
    fontWeight: 400,
  },
};

export const stepsWrap = {
  fontSize: 28,
  padding: '56px 0px 56px 56px',
  height: '90vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
};

export const stepStyle = {
  left: -325,
  borderRadius: '12px',
  height: 64,
  paddingLeft: 24,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  transition: 'left .5s .5s, opacity .4s',
  whiteSpace: 'nowrap',
  width: 320,
  cursor: 'default',
  '&:hover': {
    background: 'rgba(59, 63, 67, 0.8)',
    boxShadow: ' -10px 9px 25px -4px rgba(0, 0, 0, 0.1)',
    color: '#fff',
    '& .info-box__main-wrap': {
      opacity: 1,
      display: 'block',
    },
  },
};

export const infoBoxWrap = {
  background: 'rgba(59, 63, 67, 0.8)',
  boxShadow: ' -10px 9px 25px -4px rgba(0, 0, 0, 0.1)',
  borderRadius: '12px',
  color: '#fff',
  opacity: 0,
  display: 'none',
  fontSize: 16,
  height: 600,
  left: 336,
  padding: 32,
  position: 'absolute',
  top: 0,
  whiteSpace: 'normal',
  width: 600,
};
