/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Typography } from '@mui/material';

type TabPanelProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};

const TabPanel = ({ children, value, index }: TabPanelProps) => (
  <Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
    {value === index && <Fragment>{children}</Fragment>}
  </Typography>
);

export default TabPanel;
