/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import Circle from '../../../../ui/Circle';
import colors from '../../../../../styles/colors';
import { IconType } from '../../../../ui/Icon';

const noNotesCircle: CSSObject = {
  backgroundColor: colors.grayExLight,
  height: 168,
  width: 168,
};

const noNotesHeader: CSSObject = {
  color: colors.grayDark,
  fontSize: 24,
  fontWeight: 700,
  margin: '24px auto 8px',
};

export default function NoNotes() {
  return (
    <div css={{ textAlign: 'center' }}>
      <Circle styleoverrides={noNotesCircle} icon={IconType.NoNotes} />
      <div css={noNotesHeader}>No notes</div>
      <p css={{ fontSize: '16', color: colors.grayDark, marginBottom: 16 }}>
        Add notes to manage your workflow
      </p>
    </div>
  );
}
