/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DownArrowIcon } from '../../ui/icons';
import { format } from 'date-fns';
import Barcode from 'react-barcode';
import * as QRCode from 'qrcode.react';
import { DocumentAudit } from '../../../globalTypes/objects';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles({
  pageContainer: {
    height: '100%',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  topRotatedSection: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    fontSize: 60,
    transform: 'rotate(-180deg)',
  },
  topSectionContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  arrow: {
    width: 48,
    height: 48,
  },
  topSectionDate: {
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  documentDataContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px',
  },
  docprobeName: {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
    marginBottom: 24,
    marginTop: 24,
  },
  documentData: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    columnGap: 8,
    '& > *:nth-child(2n+1)': { textAlign: 'right' as const },
    '& > *:nth-child(2n+2)': { fontWeight: 700 },
    lineHeight: '36px',
    fontSize: 22,
  },
  bottomSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  scanCodeSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
    alignItems: 'center',
    padding: '24px',
  },
  qrCode: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export type CoversheetProps = {
  documentId: number;
  clientName: string;
  borrowerName: string;
  documentTypeDescription: string;
  dateDocumentDrawn: string;
  investorName: string;
  notFound: boolean;
  audit: DocumentAudit;
};

type Props = CoversheetProps & { afterPrintCallback: () => void };

const CoversheetPrint = (props: Props) => {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
    onAfterPrint: props.afterPrintCallback,
  });

  const hasData = !!props.documentId;

  useEffect(() => {
    if (!hasData) {
      return;
    }

    handlePrint();
  }, [props.documentId]);

  if (!hasData) {
    return <Fragment />;
  }

  return (
    <div css={{ display: 'none', '@media print': { display: 'block' } }} ref={componentRef}>
      <Coversheet {...props} />
    </div>
  );
};

export default CoversheetPrint;

export const Coversheet = ({
  documentId,
  clientName,
  borrowerName,
  documentTypeDescription,
  dateDocumentDrawn,
  investorName,
  notFound,
  audit,
}: CoversheetProps) => {
  const classes = useStyles();

  const isDisplayingFailed = Boolean(
    audit && Object.hasOwn(audit, 'passed') && !audit.passed && !audit.isCorrected,
  );

  return (
    <div className={classes.pageContainer} css={{ '@page': { margin: 0 } }}>
      <div className={classes.topRotatedSection}>
        <div className={classes.topSectionContainer}>
          <DownArrowIcon className={classes.arrow} />
          <div className={classes.topSectionDate}>
            <div style={{ fontSize: 32 }}>{new Date().toLocaleDateString()}</div>
            <div>{documentId}</div>
          </div>
          <DownArrowIcon className={classes.arrow} />
        </div>
      </div>

      <div className={classes.documentDataContainer}>
        <div className={classes.docprobeName}>DOCPROBE</div>
        <div className={classes.documentData}>
          <p>Client:</p>
          <p>{clientName}</p>
          <p>Borrower:</p>
          <p>{borrowerName}</p>
          <p>Document Type:</p>
          <p>{documentTypeDescription}</p>
          <p>Date:</p>
          <p>
            {dateDocumentDrawn
              ? format(new Date(Date.parse(dateDocumentDrawn)), 'MM/dd/yyyy')
              : null}
          </p>
          <p>Investor:</p>
          <p>{investorName}</p>
        </div>
      </div>
      <div className={classes.bottomSection}>
        <div>
          {notFound && (
            <div>
              <p style={{ textAlign: 'center', fontSize: 45 }}>NP</p>
            </div>
          )}
          {isDisplayingFailed && (
            <div>
              <p style={{ textAlign: 'center', fontSize: 45 }}>
                Failed Audit
                <div style={{ fontSize: 20 }}>
                  {audit.failureReasons?.map(reason => (
                    <p key={reason.id}>{reason.description}</p>
                  ))}
                  <p>{audit.notes}</p>
                </div>
              </p>
            </div>
          )}
        </div>

        <div className={classes.scanCodeSection}>
          <div style={{ display: 'block', marginTop: isDisplayingFailed ? 16 : 32 }}>
            <Barcode value={`docprobe-${documentId.toString()}`} />
          </div>
          <div className={classes.qrCode}>
            <QRCode
              value={`docprobe-${documentId.toString()}`}
              size={128}
              includeMargin
              css={{ marginRight: '32' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
