import React from 'react';

import ErrorPage, { ErrorType } from '../components/ui/ErrorPage';

const error: ErrorType = {
  errorTitle: 'Error ',
  errorName: 400,
  firstNumber: 4,
  lastNumber: 0,
  errorMessage: 'Bad Request. Please edit the request and try again.',
};

export default function Page400() {
  return <ErrorPage errorType={error} />;
}
