/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Dropdown from '../../ui/Dropdown';
import Panel from '../../ui/Panel';
import StepHeader from '../../Utilities/StepUI/StepHeader';
import FileDropper from '../../ui/FileDropper';
import { CorrectionDocs } from './StepTwo';
import { panelWrap } from '../../Utilities/StepUI/styles';

import SearchSelect from '../../ui/SearchSelect';
import CustomButton from '../../ui/Button';
import Checkbox from '../../ui/Checkbox';

// #region css
const docTypeTable = {
  display: 'table',
  width: '100%',
  marginTop: 8,
};

const docTypeTableRow = {
  display: 'table-row',
  textAlign: 'center',
};

const docTypeLargeTableCell = {
  display: 'table-cell',
  width: 294,
};

const docTypeTableCell = {
  display: 'table-cell',
  verticalAlign: 'middle',
  paddingLeft: 32,
};

// #endregion

export default class StepOne extends React.Component {
  state = {
    docType: '',
    clients: [],
    failedAuditReasons: [],
    client: undefined,
    file: undefined,
    audit: undefined,
    auditDeferred: false,
    isCopy: false,
  };

  async componentDidMount() {
    const { client, docType, docStatus, file, clients, documentTypes, isCopy } = this.props; // come back and do this in constructor

    this.setState({
      clients: clients.map(c => ({ id: c.id, name: c.company })),
    });
    (docType || file || client?.name) &&
      this.setState({
        docStatus,
        docType: documentTypes.find(doc => doc.label === docType),
        client,
        file,
        isCopy,
      });
  }

  componentDidUpdate(_, prevState) {
    const { docStatus, docType, file, isCorrection, client, isCopy } = this.state;
    if (
      docStatus !== prevState.docStatus ||
      docType !== prevState.docType ||
      file !== prevState.file ||
      isCorrection !== prevState.isCorrection ||
      client !== prevState.client ||
      isCopy !== prevState.isCopy
    ) {
      const valid = this.validate();
      this.props.handleChange(
        valid ? { docStatus, docType, file, isCorrection, client, isCopy } : {},
      );
    }
  }

  validate = () =>
    (this.props.digital && !!this.state.file) ||
    (!!this.state.docType && !isEmpty(this.state.client));

  handleDocTypeClick = docType => {
    const selectedDocType = this.props.documentTypes.find(t => t.label === docType);
    this.setState({ docType: selectedDocType });
    if (selectedDocType.label === 'Policy') {
      this.setState({ isCopy: false });
    }
  };

  render() {
    const { docType, file, clients, client, isCopy } = this.state;
    const { digital, onSubmit, submitStatus, documentTypes } = this.props;

    const isMortgage = docType === documentTypes.find(t => t.label === 'Mortgage');
    const isPolicy = docType === documentTypes.find(t => t.label === 'Policy');

    return (
      <>
        <Panel styles={[panelWrap, { display: 'inline-block' }]}>
          <StepHeader text="Identify Document" number={digital ? 0 : 1} />
          <div css={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
            <div css={{ width: '100%', marginRight: 32 }}>
              {digital ? (
                <div css={{ maxWidth: 294 }}>
                  <SearchSelect
                    placeholder="Client"
                    options={clients.map(c => ({
                      label: c.name,
                      value: c.id,
                    }))}
                    value={client ? { label: client.name, value: client.id } : ''}
                    onChange={c => {
                      this.setState({
                        client: {
                          name: c.label,
                          id: c.value,
                        },
                      });
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div css={{ marginBottom: 24 }}>
                    <span>Select client</span>
                    <div css={{ maxWidth: 262, marginTop: 8 }}>
                      <SearchSelect
                        placeholder="Client"
                        options={clients.map(c => ({
                          label: c.name,
                          value: c.id,
                        }))}
                        value={client ? { label: client.name, value: client.id } : ''}
                        onChange={c => {
                          this.setState({
                            client: {
                              name: c.label,
                              id: c.value,
                            },
                            docType: '',
                          });
                          this.props.handleClientChange();
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <span>Select document type</span>
                    <div css={docTypeTable}>
                      <div css={docTypeTableRow}>
                        <div css={docTypeLargeTableCell}>
                          <Dropdown
                            placeholder="Document Type"
                            options={documentTypes}
                            value={docType}
                            isSearchable
                            onChange={selection => {
                              this.setState({ docType: selection });
                              !CorrectionDocs(digital).includes(selection.value) &&
                                this.props.handleDocTypeChange();
                            }}
                          />
                        </div>
                        <div css={docTypeTableCell}>
                          <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            size="large"
                            css={{ height: 46 }}
                          >
                            {isMortgage ? (
                              <Button variant="contained">Mortgage</Button>
                            ) : (
                              <Button onClick={() => this.handleDocTypeClick('Mortgage')}>
                                Mortgage
                              </Button>
                            )}

                            {isPolicy ? (
                              <Button variant="contained">Policy</Button>
                            ) : (
                              <Button onClick={() => this.handleDocTypeClick('Policy')}>
                                Policy
                              </Button>
                            )}
                          </ButtonGroup>
                        </div>
                      </div>
                      <div css={{ marginTop: 32 }}>
                        <Checkbox
                          disabled={isPolicy}
                          labelOverrides={{ width: '200px' }}
                          checked={isCopy}
                          onClick={() => this.setState({ isCopy: !isCopy })}
                          text="This is a Copy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {digital && (
            <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FileDropper
                buttonDisabled={!client}
                onChange={f => this.setState({ file: f })}
                file={file}
                acceptableFileTypes=".pdf"
              />
              <CustomButton
                onClick={() => onSubmit().then(() => this.setState({ file: undefined }))}
                disabled={!file || submitStatus === 'LOADING'}
                css={{ marginTop: '2rem' }}
              >
                Import
              </CustomButton>
            </div>
          )}
        </Panel>
      </>
    );
  }
}
