import React from 'react';

import LoanInformationSkeleton from './LoanInformationSkeleton';
import UnidentifiedTileSkeleton from './UnidentifiedTileSkeleton';
import Panel, { PanelHeader } from '../../../../ui/Panel';
import Circle from '../../../../ui/Circle';

export default function ErrorPanel() {
  return (
    <Panel>
      <PanelHeader iconComponent={<Circle styleoverrides={{ border: 'none' }} />} />
      <UnidentifiedTileSkeleton />
      <LoanInformationSkeleton />
    </Panel>
  );
}
