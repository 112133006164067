/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, Fragment, SetStateAction, useContext, useMemo } from 'react';
import { AuthContext } from '../../components/AuthContext';
import ConditionalComponent from '../../components/ConditionalComponent';
import { EditTwoIcon } from '../../components/ui/icons';
import { ActionButton, InnerCardHeader, nameS } from './feesStyles';
import { PricingOption, TierSetting } from './types';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { isClientAdmin } from '../../Utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    td: {
      fontWeight: 'bold',
      marginRight: 5,
      color: '#47517B',
      backgroundColor: '#f5f6f8',
      borderRadius: 5,
      padding: 8,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

type Props = {
  onEditClick: Dispatch<SetStateAction<boolean>>;
  pricingOption?: PricingOption;
  tierSettings: TierSetting[];
};

const sortTierSettings = (a: TierSetting, b: TierSetting) => (a.from ?? 0) - (b.from ?? 0);

const loanVolumeDisplay = (from: number | undefined, to: number | undefined) => {
  if (!from) {
    return `< ${(to ?? 0) + 1}`;
  }

  if (!to) {
    return `> ${from - 1}`;
  }

  return `${from} - ${to}`;
};

const PricingStructureDisplayCard = ({ onEditClick, pricingOption, tierSettings }: Props) => {
  const { roles } = useContext(AuthContext);

  const sortedSettings = useMemo(() => tierSettings.sort(sortTierSettings), [tierSettings]);

  const classes = useStyles();

  return (
    <Fragment>
      <div className="df aic jcsb mb3">
        <div css={nameS}>Pricing Structure: {pricingOption?.value}</div>
        <ConditionalComponent display={isClientAdmin(roles)}>
          <ActionButton onClick={onEditClick}>
            <EditTwoIcon />
          </ActionButton>
        </ConditionalComponent>
      </div>
      {pricingOption?.value === 'Tiered Pricing' && (
        <div>
          <table style={{ width: '100%' }}>
            <thead className="df jcsa aic">
              <th>
                <InnerCardHeader>Loan Volume Per Month</InnerCardHeader>
              </th>
              <th>
                <InnerCardHeader>Price Per Document</InnerCardHeader>
              </th>
            </thead>
            <tbody className="df jcsa aic full-width">
              <div className="full-width">
                {sortedSettings.map((setting, i) => (
                  <div className="df jcsb aic m2" style={{ gap: '16px' }} key={i}>
                    <td className={classes.td}>
                      <div>{loanVolumeDisplay(setting.from, setting.to)}</div>
                    </td>
                    <td className={classes.td}>
                      <div>${setting.price}</div>
                    </td>
                  </div>
                ))}
              </div>
            </tbody>
          </table>
        </div>
      )}
    </Fragment>
  );
};

export default PricingStructureDisplayCard;
