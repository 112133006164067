/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import Grid from '@mui/material/Grid';
import MaterialButton from '@mui/material/Button';

import queryString from 'query-string';
import Panel, { PanelHeader } from '../components/ui/Panel';
import ClientSearch from '../components/Utilities/ClientSearch';
import Button from '../components/ui/Button';
import UnassociatedDocuments from './UnassociatedDocuments';
import { Client, Document } from '../globalTypes/objects';
import SearchSelect from '../components/ui/SearchSelect';
import colors from '../styles/colors';
import { apiFetch } from '../adalConfig';
import Header from '../components/ui/Header';
import ClientQueueStats from '../components/ClientQueueStats';

const countCircle = {
  backgroundColor: colors.red,
  borderRadius: 4,
  color: colors.white,
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: 1,
  marginLeft: 16,
  padding: '1px 4px',
};

const matchButton = {
  display: 'inline-flex',
  minWidth: 161,
  alignItems: 'center',
};

const matchButtonMargin = {
  marginRight: 24,
};

const buttonRow = {
  display: 'flex',
  justifyContent: 'begin',
  marginTop: '2rem',
};

const searchNotFounds = async (input, clientId) => {
  const { data } = await apiFetch<Document[]>(
    `/api/documents/notfounds?clientId=${clientId}&searchTerm=${input}`,
  );
  return data.map(document => ({
    label: document.documentTypeName,
    subLabel: document.propertyStreet?.trim() || document.loanNumber || document.id,
    value: document,
  }));
};

enum Mode {
  initial = 'initial',
  matching = 'matching',
  finding = 'finding',
  report = 'report',
}

export type ClientData = {
  client: Client;
  matchAmount?: number;
  physicalMatches: number;
  digitalMatches: number;
  policyMatches: number;
  otherMatches: number;
};

export enum MatchOnlyOption {
  Digital,
  Physical,
  Policy,
  OtherDocType,
  All,
}

export type MatchSuggestionParams = {
  documentId: number;
  loanId: number;
};

export enum PageDisplay {
  normal,
  skippedMatch,
  specificMatch,
  titleCompanyUploads,
}

export default function NotFounds({ location: { search } }) {
  const [clientData, setClientData] = useState<ClientData>({} as ClientData);
  const [currentMode, setCurrentMode] = useState<Mode>(Mode.finding);
  const [matchOnlyOption, setMatchOnlyOption] = useState<MatchOnlyOption>(MatchOnlyOption.All);
  const [isDisplayingSkipped, setIsDisplayingSkipped] = useState(false);

  const getDocuments = debounce((input, callback) => {
    searchNotFounds(input, clientData.client.id).then(callback);
  }, 800);

  const downloadDoc = async (doc: Document) => {
    const response = await apiFetch<BlobPart>(
      `/api/documents/getDocumentPdf?documentId=${doc.id}`,
      {
        responseType: 'blob',
      },
    );

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response.data]));
    link.setAttribute('download', `${doc.fileSubpath}`);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (search) {
      setCurrentMode(Mode.matching);
    }
  }, [search]);

  useEffect(() => {
    const getMatchAmount = async () => {
      const { data: matchAmounts } = await apiFetch<
        { hardCopy: number; documentType: number; count: number }[]
      >(`/api/documents/matchVerificationCountByType?clientId=${clientData?.client.id}`);
      const policyPhysicalMatches =
        matchAmounts.find(row => row.hardCopy === 1 && row.documentType === 1)?.count ?? 0;
      const policyDigitalMatches =
        matchAmounts.find(row => row.hardCopy === 0 && row.documentType === 1)?.count ?? 0;
      const otherPhysicalMatches =
        matchAmounts.find(row => row.hardCopy === 1 && row.documentType === 0)?.count ?? 0;
      const otherDigitalMatches =
        matchAmounts.find(row => row.hardCopy === 0 && row.documentType === 0)?.count ?? 0;

      const physicalMatches = otherPhysicalMatches + policyPhysicalMatches;
      const digitalMatches = otherDigitalMatches + policyDigitalMatches;
      const policyMatches = policyPhysicalMatches + policyDigitalMatches;
      const otherMatches = otherPhysicalMatches + otherDigitalMatches;

      const matchAmount = physicalMatches + digitalMatches;

      setClientData({
        ...clientData,
        matchAmount,
        physicalMatches,
        digitalMatches,
        policyMatches,
        otherMatches,
      });
    };
    clientData?.client && getMatchAmount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData?.client]);

  const disabled = !clientData?.client;
  const urlQueryString = search ? queryString.parse(search) : undefined;

  const onMatchOptionClick = matchOnlyOptionClicked => {
    setIsDisplayingSkipped(false);
    setMatchOnlyOption(matchOnlyOptionClicked);
    setCurrentMode(Mode.matching);
  };

  return (
    <Fragment>
      <Header headerText="Not Founds" />
      {currentMode !== Mode.matching && (
        <Panel styles={{ margin: 40, maxWidth: 675 }}>
          <PanelHeader text="Select client" />
          <div css={{ margin: '24px 0 32px' }}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <ClientSearch onChange={client => setClientData({ client })} />
              </Grid>
              <Grid item xs={4}>
                <MaterialButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsDisplayingSkipped(true);
                    setCurrentMode(Mode.matching);
                  }}
                >
                  Show Skipped
                </MaterialButton>
              </Grid>
            </Grid>
          </div>
          {currentMode === Mode.finding && (
            <div css={{ margin: '24px 0 32px' }}>
              <SearchSelect
                onChange={({ value }) => downloadDoc(value) && setCurrentMode(Mode.initial)}
                isAsync
                loadOptions={getDocuments}
                filterOption={() => true}
                placeholder="Type barcode, loan number, or address"
              />
            </div>
          )}
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <div css={[buttonRow, { marginTop: 0 }]}>
              <Button
                disabled={disabled || clientData?.matchAmount === 0}
                onClick={() => onMatchOptionClick(MatchOnlyOption.All)}
                // @ts-ignore
                styleOverrides={[
                  matchButtonMargin,
                  clientData?.matchAmount && clientData.matchAmount > 0 && matchButton,
                ]}
              >
                Match
                {clientData?.matchAmount && clientData.matchAmount > 0 ? (
                  <div css={countCircle}>{clientData.matchAmount}</div>
                ) : null}
              </Button>

              <Button
                disabled={disabled || clientData?.physicalMatches === 0}
                onClick={() => onMatchOptionClick(MatchOnlyOption.Physical)}
                // @ts-ignore
                styleOverrides={[
                  matchButtonMargin,
                  clientData?.physicalMatches && clientData?.physicalMatches > 0 && matchButton,
                ]}
              >
                Match Physical
                {clientData?.physicalMatches && clientData?.physicalMatches > 0 ? (
                  <div css={countCircle}>{clientData.physicalMatches}</div>
                ) : null}
              </Button>

              <Button
                disabled={disabled || clientData?.digitalMatches === 0}
                onClick={() => onMatchOptionClick(MatchOnlyOption.Digital)}
                // @ts-ignore
                styleOverrides={[
                  matchButtonMargin,
                  clientData?.digitalMatches && clientData?.digitalMatches > 0 && matchButton,
                ]}
              >
                Match Digital
                {clientData?.digitalMatches && clientData?.digitalMatches > 0 ? (
                  <div css={countCircle}>{clientData.digitalMatches}</div>
                ) : null}
              </Button>
            </div>
            <div css={buttonRow}>
              <Button
                disabled={disabled || clientData?.policyMatches === 0}
                onClick={() => onMatchOptionClick(MatchOnlyOption.Policy)}
                // @ts-ignore
                styleOverrides={[
                  matchButtonMargin,
                  clientData?.policyMatches && clientData?.policyMatches > 0 && matchButton,
                ]}
              >
                Match Policy
                {clientData?.policyMatches && clientData?.policyMatches > 0 ? (
                  <div css={countCircle}>{clientData.policyMatches}</div>
                ) : null}
              </Button>

              <Button
                disabled={disabled || clientData?.otherMatches === 0}
                onClick={() => onMatchOptionClick(MatchOnlyOption.OtherDocType)}
                // @ts-ignore
                styleOverrides={[
                  matchButtonMargin,
                  clientData?.otherMatches && clientData?.otherMatches > 0 && matchButton,
                ]}
              >
                Match Other Doc Type
                {clientData?.otherMatches && clientData?.otherMatches > 0 ? (
                  <div css={countCircle}>{clientData.otherMatches}</div>
                ) : null}
              </Button>
            </div>
            <div css={buttonRow}>
              <Button disabled={disabled} onClick={() => setCurrentMode(Mode.finding)}>
                Download Doc
              </Button>
            </div>
            <div css={buttonRow}>
              <Button onClick={() => setCurrentMode(Mode.report)}>Match Queue Stats</Button>
            </div>
          </div>
        </Panel>
      )}
      {currentMode === Mode.matching && (
        <UnassociatedDocuments
          matchOnly={matchOnlyOption}
          client={clientData?.client}
          pageDisplay={
            isDisplayingSkipped
              ? PageDisplay.skippedMatch
              : search
              ? PageDisplay.specificMatch
              : PageDisplay.normal
          }
          hideForm={() => {
            setCurrentMode(Mode.initial);
            setClientData(undefined);
          }}
          queryParams={(urlQueryString as unknown) as MatchSuggestionParams}
        />
      )}
      {currentMode === Mode.report && (
        <ClientQueueStats onClose={() => setCurrentMode(Mode.initial)} isMatching />
      )}
    </Fragment>
  );
}
