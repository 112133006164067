import { Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useEmailStyles = makeStyles((theme: Theme) => {
  return createStyles({
    details: {
      flexDirection: 'column',
    },
    input: {
      width: '100%',
      marginBottom: 18,
    },
    errorCard: {
      backgroundColor: 'red',
      color: 'white',
      fontSize: 20,
      marginTop: 17,
      marginBottom: 23,
      padding: 10,
      textAlign: 'center',
    },
    divider: {
      marginBottom: 18,
    },
    disableChildren: { pointerEvents: 'none' },
    successCard: {
      color: 'green',
      marginTop: 17,
      marginBottom: 23,
      padding: 10,
      textAlign: 'center',
    },
    button: { marginTop: 17, marginBottom: 23 },
    checkboxLabel: {
      '& > *:last-of-type': {
        width: '100%',
      },
    },
  });
});

export default useEmailStyles;
