import styled from '@emotion/styled';

export const LogoWrap = styled.div`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e5e5ea;
  padding: 16px;
  position: fixed;
  right: 40px;
  top: 8px;
  z-index: 500000;
  box-shadow: 0 21px 73px -6px rgba(19, 21, 50, 0.09);
  img {
    max-height: 70px;
    width: 100%;
  }
  @media (max-width: 1440px) {
    img {
      max-height: 48px;
    }
  }
`;

export const cardWrap = {
  backgroundColor: '#fff',
  border: '1px solid #E5E5EA',
  borderRadius: 5,
  marginTop: 32,
  maxWidth: 1024,
  padding: 32,
};

export const twoColGrid = {
  ...cardWrap,
  '> div': {
    padding: 24,
    display: 'grid',
    gridColumnGap: 24,
    gridRowGap: 24,
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
  },
};

export const linkS = {
  cursor: 'pointer',
  color: 'blue',
  textDecoration: 'underline',
  transition: 'all .2s',
  '&:hover': {
    color: '#0018ab',
    transition: 'all .2s',
  },
};

export const nameS = {
  fontWeight: 'bold',
  fontSize: 20,
  lineHeight: 24,
};
