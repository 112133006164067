/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { cardWrap, circleWrap, dataPointStyle, month, subTitleStyle, thirdPart, titleStyle, week, } from './styles';
import CircularProgressWithLabel from './CircularProgressWithLabel';

type GenericCardType = {
  title: string;
  today: number;
  percentOfGoal: number;
  month: number;
  year: number;
};

type GenericCardWithoutChildType = GenericCardType & {
  kind: 'without-child';
  queue: number;
  new: number;
};

type GenericCardWithChildType = GenericCardType & {
  kind: 'with-child';
  children: JSX.Element;
};

type TodayType = {
  title: string;
  value: number;
};

type QueNewType = {
  que: number;
  newOnes: number;
};

type PercentageCompleteType = {
  timeSpan: string;
  progress: number;
};

type MonthYearType = {
  monthCount: number;
  yearCount: number;
};

export default function GenericCard(data: GenericCardWithoutChildType | GenericCardWithChildType) {
  return (
    <div css={cardWrap} className="darkmode-bgc">
      <div>
        <div css={titleStyle}>{data.title}</div>
        <Today title="today" value={data.today} />
        {data.kind === 'with-child' ? (
          data.children
        ) : (
          <QueNew que={data.queue} newOnes={data.new} />
        )}
      </div>

      <div css={thirdPart}>
        <PercentageComplete timeSpan="Week" progress={data.percentOfGoal} />
        <MonthYear monthCount={data.month} yearCount={data.year} />
      </div>
    </div>
  );
}

function Today({ title, value }: TodayType) {
  return (
    <div>
      <div
        css={{ color: '#6dd400', fontSize: 40, fontWeight: 'bold', marginTop: 48, lineHeight: 1 }}
      >
        {title}
      </div>
      <div css={{ color: '#000', fontWeight: 'bold', fontSize: 70, lineHeight: 1, marginTop: 8 }}>
        {value.toLocaleString()}
      </div>
    </div>
  );
}

function QueNew({ que, newOnes }: QueNewType) {
  return (
    <div className="df aic mt2">
      <span css={subTitleStyle}>que</span>
      <span css={dataPointStyle} className="mr2">
        {que.toLocaleString()}
      </span>
      <span css={subTitleStyle}>new</span>
      <span css={dataPointStyle}>{newOnes.toLocaleString()}</span>
    </div>
  );
}

function PercentageComplete({ timeSpan, progress }: PercentageCompleteType) {
  return (
    <div>
      <div css={week}>{timeSpan}</div>
      <div css={circleWrap}>
        <CircularProgressWithLabel percent={progress} />
      </div>
    </div>
  );
}

function MonthYear({ monthCount, yearCount }: MonthYearType) {
  return (
    <div>
      <div>
        <div css={month}>month</div>
        <div css={{ fontWeight: 'bold', paddingBottom: 19, fontSize: 16 }}>
          {monthCount.toLocaleString()}
        </div>
      </div>
      <div
        css={{
          paddingTop: 15,
          borderTop: '1px solid #e5e5e3',
        }}
      >
        <div css={month}>year</div>
        <div css={{ fontWeight: 'bold', fontSize: 16 }}>{yearCount.toLocaleString()}</div>
      </div>
    </div>
  );
}
