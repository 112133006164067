/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import FileDropper from '../components/ui/FileDropper';
import Button from '@mui/material/Button';
import { apiPost } from '../adalConfig';
import { useToaster } from '../Hooks/toasters';
import { convertToBase64 } from '../Utils';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import Header from '../components/ui/Header';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      gap: '8px',
    },
    button: {
      margin: theme.spacing(1),
    },
    loaderButton: {
      border: '1px solid #0828CC',
      borderRadius: '4px',
      width: '88px',
      height: '34px',
    },
  }),
);

const ImportDocumentNotes = () => {
  const [file, setFile] = useState<File | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { successToaster, errorToaster } = useToaster();
  const classes = useStyles();

  const uploadSpreadsheet = async () => {
    if (isSubmitting) {
      return;
    }

    if (file === undefined) {
      return;
    }

    const documentNotesImport = { spreadsheet: await convertToBase64(file) };
    try {
      setIsSubmitting(true);
      await apiPost('/api/document-notes/import-from-spreadsheet', documentNotesImport);

      setFile(undefined);

      successToaster('Document notes saved successfully');
    } catch (e) {
      if (!e.response.data?.errors) {
        errorToaster(e.response.data);
        return;
      }

      errorToaster(
        <Fragment>
          {e.response.data.errors.map((error, i) => (
            <p key={i}>{error.message}</p>
          ))}
        </Fragment>,
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <Header headerText="Upload Document Notes" />
      <div className={classes.container}>
        <div className="df col mat w-three-quarters" style={{ gap: '8px' }}>
          <FileDropper
            onChange={f => setFile(f)}
            file={file}
            acceptableFileTypes=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />

          <div className="mat">
            {isSubmitting ? (
              <div className={`${classes.loaderButton} center-in-parent`}>
                <CircularProgress size="20" disableShrink />
              </div>
            ) : (
              <Button onClick={uploadSpreadsheet} variant="contained" disabled={file === undefined}>
                Upload
              </Button>
            )}
          </div>
        </div>
        <div className="m4">
          <Alert severity="info">
            <List>
              <ListItem>
                <ListItemText>
                  The required columns in the spreadsheet are 'barcode' and 'note' (case
                  insensitive)
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  You can leave addition columns in the spreadsheet. All other columns will be
                  ignored
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  Only files in excel format are accepted (i.e. 'Excel Workbook' - not 'Excel
                  97-2004' or 'CSV')
                </ListItemText>
              </ListItem>
            </List>
          </Alert>
        </div>
      </div>
    </Fragment>
  );
};

export default ImportDocumentNotes;
