/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DataTable from '../../components/ui/DataTable/DataTable';
import Grid from '@mui/material/Grid';
import { apiFetch } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import CircularProgress from '@mui/material/CircularProgress';
import dotObject from 'dot-object';
import { useInvestors } from '../../Hooks/useInvestors';
import Autocomplete from '@mui/material/Autocomplete';
import {Client, Investor} from '../../globalTypes/objects';
import DatePicker from 'react-datepicker';
import makeStyles from '@mui/styles/makeStyles';
import {useClients} from "../../Hooks/useClients";

const useStyles = makeStyles({
    filters: {
        '& .react-datepicker-popper': { zIndex: '9999 !important' },
    },
});

type LoadState = 'initial' | 'loading' | 'loaded';

type RowData = {
    client: string;
    appNumber: string;
    docId: string;
    failReason: string;
    ncnReason: string;
    billable: boolean;
    active: boolean;
    dateCredited: Date;
};

type ReportFilters = {
    rangeStart: Date | null;
    rangeEnd: Date | null;
    clientId: number | null;
};

const canSubmit = (filters: ReportFilters) =>
    Boolean(filters.clientId && filters.rangeStart);

const loadReportData = async (filters: ReportFilters) => {
    const x = dotObject.dot({ filters });
    const { data } = await apiFetch<RowData[]>('/api/reports/correction-credits', {
        params: x,
    });
    return data;
};

const columns = [
    {
        field: 'client',
        title: 'Lender',
    },
    {
        field: 'appNumber',
        title: 'Loan Number',
    },
    {
        field: 'docId',
        title: 'Doc Id',
    },
    {
        field: 'failReason',
        title: 'Fail Reason',
    },
    {
        field: 'ncnReason',
        title: 'NCN Reason',
    },
    {
        field: 'billable',
        title: 'Billable',
    },
    {
        field: 'active',
        title: 'Active',
    },
    {
        field: 'dateCredited',
        title: 'Date Credited',
        render: (rowData: RowData) =>
            rowData.dateCredited && new Date(rowData.dateCredited).toLocaleDateString(),
    },
];

const CorrectionCreditsReport = () => {
    const [datatableData, setDatatableData] = useState<RowData[]>([]);
    const [reportFilters, setReportFilters] = useState<ReportFilters>({
        rangeStart: null,
        rangeEnd: null,
        clientId: null,
    });
    const [loadState, setLoadState] = useState<LoadState>('initial');

    const clients = useClients();
    const { errorToaster } = useToaster();
    const classes = useStyles();

    return (
        <Fragment>
            <div className={`my3 mx4 df jcsb ${classes.filters}`}>
                <div>
                    <DatePicker
                        maxDate={new Date()}
                        selected={reportFilters.rangeStart}
                        selectsStart
                        startDate={reportFilters.rangeStart}
                        endDate={reportFilters.rangeEnd}
                        placeholderText="Select a start date"
                        customInput={<TextField style={{ width: 400 }} />}
                        onChange={startDate => setReportFilters(x => ({ ...x, rangeStart: startDate }))}
                    />
                </div>
                <div>
                    <DatePicker
                        maxDate={new Date()}
                        selected={reportFilters.rangeEnd}
                        selectsEnd
                        startDate={reportFilters.rangeStart}
                        endDate={reportFilters.rangeEnd}
                        placeholderText="Select an end date"
                        customInput={<TextField style={{ width: 400 }} />}
                        onChange={endDate => setReportFilters(x => ({ ...x, rangeEnd: endDate }))}
                    />
                </div>
                <div>
                    <Autocomplete
                        options={clients}
                        getOptionLabel={(option: Client) => option.company || ''}
                        value={clients.find(x => x.id === reportFilters.clientId)}
                        onChange={(event: any, newValue: Client | null) =>
                            setReportFilters(x => ({ ...x, clientId: newValue ? newValue.id : null }))
                        }
                        style={{ width: 400 }}
                        autoHighlight
                        renderInput={params => (
                            <TextField {...params} label="Choose a client" variant="outlined" />
                        )}
                    />
                </div>
                <div>
                    <Button
                        style={{ marginLeft: '15px' }}
                        variant="contained"
                        color="primary"
                        disabled={!canSubmit(reportFilters)}
                        onClick={async () => {
                            if (!canSubmit(reportFilters)) {
                                return;
                            }

                            try {
                                setLoadState('loading');
                                const reportData = await loadReportData(reportFilters);
                                setDatatableData(reportData);
                            } catch (e) {
                                errorToaster(e.response?.data || 'Failed to load report');
                            } finally {
                                setLoadState('loaded');
                            }
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </div>

            {loadState === 'initial' && <Fragment />}
            {loadState === 'loading' && (
                <div className="center-in-parent">
                    <CircularProgress size="45" disableShrink />
                </div>
            )}
            {loadState === 'loaded' && (
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable<RowData>
                            title="Correction Credits Report"
                            columns={columns}
                            data={datatableData}
                            options={{
                                exportFileName: 'Correction Credits Report',
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </Fragment>
    );
};

export default CorrectionCreditsReport;
