import colors from '../../../styles/colors';

export const topStyles = { padding: 40, borderTop: `1px solid ${colors.grayLight}` };

export const panelWrap = {
  marginBottom: 24,
  display: 'none',
  verticalAlign: 'top',
  maxWidth: 720,
};
