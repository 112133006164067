import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  id: number;
  documentType: string;
  lender: string;
  username: string;
  createdAt: Date;
  failedAudit: string;
  failedVerification: string;
};

const columns = [
  {
    field: 'id',
    title: 'Barcode',
  },
  {
    field: 'documentType',
    title: 'Document Type',
  },
  {
    field: 'lender',
    title: 'Lender',
  },
  {
    field: 'username',
    title: 'Username',
  },
  {
    field: 'createdAt',
    title: 'Date Created',
    render: (rowData: RowData) =>
      rowData.createdAt && new Date(rowData.createdAt).toLocaleDateString(),
  },
  {
    field: 'failedAudit',
    title: 'Failed Audit',
  },
  {
    field: 'failedVerification',
    title: 'Failed Verification',
  },
];

const WaitingToBeScannedIntoAzureDrillDown = () => (
  <DataTable<RowData>
    title="Scan Queue Drill Down Report"
    columns={columns}
    url="/api/reports/documents-waiting-to-be-scanned-drilldown"
    options={{
      search: false,
    }}
  />
);

export default WaitingToBeScannedIntoAzureDrillDown;
