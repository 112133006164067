/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, ReactNode, useState } from 'react';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

type Props = {
  label: string;
  value?: number;
  children?: ReactNode;
};

const QueueDisplay = ({ label, value, children }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const classes = useStyles();

  return (
    <Fragment>
      <b>{label}</b>:{' '}
      {children ? (
        <Link
          component="button"
          underline="hover"
          variant="body2"
          onClick={() => setIsModalOpen(true)}
        >
          {value}
        </Link>
      ) : (
        value
      )}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className={classes.paper}>{children}</div>
      </Modal>
    </Fragment>
  );
};

export default QueueDisplay;
