/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import { FunctionComponent } from 'react';
import colors from '../../styles/colors';

const headerWrap: CSSObject = {
  borderBottom: `1px solid ${colors.grayLight}`,
  display: 'grid',
  fontSize: 14,
  fontWeight: 700,
  marginTop: 16,
  padding: '24px 0',
};

const rowWrap: CSSObject = {
  alignItems: 'center',
  borderTop: `1px solid ${colors.grayLight}`,
  display: 'grid',
  padding: '16px 0',
  ':first-of-type': { borderTop: 'none' },
};

const HeaderWrapper: FunctionComponent<{ styles: CSSObject }> = ({ children, styles }) => {
  return <div css={[headerWrap, styles]}>{children}</div>;
};

const RowWrapper: FunctionComponent<{ styles: CSSObject }> = ({ children, styles }) => {
  return <div css={[rowWrap, styles]}>{children}</div>;
};

export { HeaderWrapper, RowWrapper };
