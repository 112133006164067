/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../ui/Button';
import Circle from '../../../ui/Circle';
import colors from '../../../../styles/colors';
import { IconType } from '../../../ui/Icon';

const mainWrap: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 104,
  width: '100%',
};

const resolvedText: CSSObject = {
  color: colors.black,
  fontSize: 24,
  fontWeight: 700,
  marginTop: 40,
};

const resolvedMessage: CSSObject = { margin: '16px 0 40px', textAlign: 'center' };

export default function AllErrorsResolved({ clientName }: { clientName: string }) {
  return (
    <div css={mainWrap}>
      <Circle styleoverrides={{ height: 224, width: 224 }} icon={IconType.ResolvedFolder} />
      <h2 css={resolvedText}>Resolved</h2>
      <span css={resolvedMessage}>
        All loan errors for {clientName}
        <br />
        have been resolved
      </span>
      <Link to="/errors/loans">
        <Button>done</Button>
      </Link>
    </div>
  );
}
