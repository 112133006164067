/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { AggregateProcessingStatsRowData as RowData } from './types';

type Props = {
  aggregateProcessingStats: RowData[];
  client: string;
};

const columns = [
  {
    field: 'event',
    title: 'Event',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'daily',
    title: 'Daily',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => rowData.daily.toLocaleString(),
  },
  {
    field: 'weekToDate',
    title: 'Week To Date',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => rowData.weekToDate.toLocaleString(),
  },
  {
    field: 'monthToDate',
    title: 'Month To Date',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => rowData.monthToDate.toLocaleString(),
  },
  {
    field: 'yearToDate',
    title: 'Year To Date',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => rowData.yearToDate.toLocaleString(),
  },
];

const AggregateProcessingStats = ({ aggregateProcessingStats, client }: Props) => (
  <DataTable<RowData>
    title={`Aggregate Processing Stats - ${client ?? 'All Clients'}`}
    columns={columns}
    data={aggregateProcessingStats}
    options={{
      exportFileName: `Aggregate Processing Stats - ${client ?? 'All Clients'}`,
      maxBodyHeight: '500px',
      search: false,
    }}
  />
);

export default AggregateProcessingStats;
