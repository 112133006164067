/** @jsx jsx */
import { jsx } from '@emotion/core';
import {Button, Radio, RadioGroup} from '@mui/material';
import React, {useMemo, useState} from 'react';
import { AuditPoint } from '../../../globalTypes/objects';
import { useAuditFailContext } from './useAuditFailContext';
import FormControlLabel from "@mui/material/FormControlLabel";

export type AuditModalHeaderProps = {
  closeModal: () => void;
  saveFailedAuditData: (failedReasons: AuditPoint[], sensitiveTier: number | null) => void;
  continueBtnTxt?: string;
};
export default function AuditModalHeader({
  closeModal,
  saveFailedAuditData,
  continueBtnTxt = 'Continue Audit Fun',
}: AuditModalHeaderProps) {
  const [isSensitiveFailure, setIsSensitiveFailure] = useState(false);
  const [sensitivityTier, setSensitivityTier] = useState<number | null>(null);
  const [{ failedReasons, auditPointCategories }] = useAuditFailContext();
  const auditPoints = auditPointCategories?.flatMap(category => category.auditPoints);

  const disableSubmit = useMemo(() => {
    const noReasonsSelected = failedReasons.length === 0;
    const auditPointNoteRequired = failedReasons.some(
      reason => reason.notesRequired && !reason.notes,
    );
    const auditPointOptionNoteRequired = failedReasons.some(reason =>
      reason.options.some(option => option.notesRequired && !option.notes),
    );
    const notesRequired = auditPointNoteRequired || auditPointOptionNoteRequired;
    const optionRequired = failedReasons.some(
      reason =>
        reason.options.length === 0 &&
        !!auditPoints?.find(point => point.id === reason.id)!.options.length,
    );
    return noReasonsSelected || notesRequired || optionRequired;
  }, [auditPoints, failedReasons]);

  return (
    <div className="rosh">
        <div className="df aic">
            <div style={{ marginRight: 16 }}>Audit</div>
            <Button style={{ marginRight: 16 }} color="primary" onClick={() => {
                setIsSensitiveFailure(x => !x)
                setSensitivityTier(null);
            }}>
                Sensitive Failure
            </Button>
            {isSensitiveFailure &&  <RadioGroup
                css={{ display: 'flex', flexDirection: 'row' }}
            >
                <FormControlLabel
                    value="1"
                    control={<Radio color="primary" style={{padding: 2}} />}
                    label="Tier 1"
                    onClick={() => setSensitivityTier(1)}
                    checked={sensitivityTier === 1}
                />
                <FormControlLabel
                    value="2"
                    control={<Radio color="primary" style={{padding: 2}}/>}
                    label="Tier 2"
                    onClick={() => setSensitivityTier(2)}
                    checked={sensitivityTier === 2}
                />
            </RadioGroup>}
        </div>

        <div className="df aic">
        <Button style={{ marginRight: 16 }} color="secondary" onClick={closeModal}>
          Cancel and Clear
        </Button>
        <Button
          disabled={disableSubmit}
          color="primary"
          onClick={() => {
            saveFailedAuditData(failedReasons, sensitivityTier);
          }}
        >
          {continueBtnTxt}
        </Button>
      </div>
    </div>
  );
}
