/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment, useEffect, useState } from 'react';
import {
  AlertProps,
  Chip,
  Dialog,
  DialogTitle,
  Link,
  Modal,
  Snackbar,
  Tooltip,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MuiAlert from '@mui/material/Alert';
import CommentIcon from '@mui/icons-material/Comment';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { Document, DocumentEmailAttachment, DocumentType } from '../globalTypes/objects';
import DataTable from './ui/DataTable/DataTable';
import CureDocument from './CureDocument';
import { formatPhoneWithParenthesis } from '../Utils';
import { apiFetch, apiPost } from '../adalConfig';
import TitleCompanySearchSelect from './Utilities/TitleCompanySearchSelect';
import useDataTableFilters from './ui/DataTable/UseDataTableFilters';
import AddNoteInput from './Notes/AddNoteInput';
import ClientSearch from './Utilities/ClientSearch';
import useFileExport from './ui/DataTable/FileExport';
import InvestorSearchSelect from './Utilities/InvestorSearchSelect';
import { useToaster } from '../Hooks/toasters';
import EmailAttachments from './Documents/EmailAttachments';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      margin: '16px 3px 0px',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const detailPanelStyles = props => css`
  padding: 16px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(4, 2fr) 5fr;
  grid-gap: 32px;
  background-color: ${props.correctionId ? '#EDF7ED' : '#FFF'};
  position: relative;
  ::before {
    content: '';
    position: absolute;
    top: -1px;
    width: 100%;
    border-top: 1px solid ${props.correctionId ? '#EDF7ED' : '#FFF'};
    z-index: 5;
  }
`;

const subSectionStyles = css`
  background-color: rgb(225, 225, 225, 0.3);
  border-radius: 8px;
  padding: 8px;
`;

const emailStyles = css`
  color: #0828cc;
  :hover {
    text-decoration: underline;
  }
`;

const attachmentButtonStyles = css`
  color: #0828cc;
  :hover {
    text-decoration: underline;
  }
`;

interface RowData {
  documentId: number;
  documentType: DocumentType;
  isInvestorFailure: boolean;
  hardCopy: boolean;
  auditNote?: string;
  failedAuditReasons?: string;
  failedVerificationNote?: string;
  loanId: number;
  loanNumber: string;
  titleNumber?: string;
  borrower: string;
  investor: string;
  client: string;
  clientId?: number;
  propertyAddress: string;
  city: string;
  state: string;
  zip: string;
  county?: string;
  dateFunded: Date;
  dateFailedAudit?: Date;
  dateFailedVerification?: Date;
  loanLevelPhone?: string;
  loanLevelEmail?: string;
  titleCompany?: string;
  titleCompanyPhone?: string;
  titleCompanyEmail?: string;
  titleCompanyId?: number;
  documentSender?: string;
  correctionId?: number;
  lastNoteDate?: Date;
  documentNotes: DocumentNote[];
}

type ExportData = {
  Lender: string;
  'Loan Number': string;
  'Title Number'?: string;
  Borrower: string;
  Investor: string;
  'Property Address': string;
  City: string;
  State: string;
  Zip: string;
  County?: string;
  'Date Funded': string;
  'Date Failed Audit'?: string;
  'Date Failed Verification'?: string;
  'Document ID': number;
  'Document Type': DocumentType;
  Format: string;
  'Failed Audit Reasons'?: string;
  'Failed Audit Note'?: string;
  'Investor Failure': string;
  'Failed Verification Fields/ Note'?: string;
  'Title Company'?: string;
  'Loan Level Phone'?: string;
  'Loan Level Email'?: string;
  'Document Sender'?: string;
  'Last Action Taken'?: string;
};

export interface DocumentNote {
  id: number;
  noteText: string;
  createdAt: Date;
  createdBy: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AccountRepsPage() {
  const [currentlyCuring, setCurrentlyCuring] = useState<false | RowData>(false);
  const [curingDocument, setCuringDocument] = useState<Document>();
  const [cureMessage, setCureMessage] = useState<null | string>(null);

  const [newNoteDocuments, setNewNoteDocuments] = useState<RowData[]>([]);
  const [newNoteOpen, setNewNoteOpen] = useState(false);

  const [attachmentModalOpen, setAttachmentModalOpen] = useState<false | RowData>(false);
  const [attachments, setAttachments] = useState<DocumentEmailAttachment[]>([]);

  const { successToaster, errorToaster } = useToaster();

  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});

  const classes = useStyles();

  const {
    onCustomOptionSelected,
    dataTableFilterTypes,
    setDataTableFilterTypes,
    customFilters,
    checkboxFilters,
    clearCustomFilter,
    clearCheckBoxFilter,
  } = useDataTableFilters('/api/accountReps/filters');

  const titleCompanyFilter = customFilters.find(filter => filter.label === 'TitleCompanies');
  const clientFilter = customFilters.find(filter => filter.label === 'ClientSearch');
  const investorFilter = customFilters.find(filter => filter.label === 'InvestorSearch');
  const documentStatusFilter = checkboxFilters.find(filter => filter.label === 'DocumentStatus');
  const failureSensitivityFilter = checkboxFilters.find(filter => filter.label === 'FailureSensitivity');

  const activeTitleCompanyFilterOption = titleCompanyFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );
  const activeClientFilterOption = clientFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );
  const activeInvestorFilterOption = investorFilter?.filterOptions.find(
    filterOption => filterOption.active,
  );

  const titleCompany: { name: string; id: number } | null = activeTitleCompanyFilterOption
    ? { id: activeTitleCompanyFilterOption.id, name: activeTitleCompanyFilterOption.label }
    : null;
  const client: { name: string; id: number } | null = activeClientFilterOption
    ? { id: activeClientFilterOption.id, name: activeClientFilterOption.label }
    : null;
  const investor: { name: string; id: number } | null = activeInvestorFilterOption
    ? { id: activeInvestorFilterOption.id, name: activeInvestorFilterOption.label }
    : null;

  const onDocumentCured = (message: string) => {
    setCurrentlyCuring(false);
    setCureMessage(message);
    if (!message.startsWith('ERROR')) {
      refreshDatatable();
    }
  };

  useEffect(() => {
    if (!(currentlyCuring && currentlyCuring.documentId)) {
      return;
    }

    apiFetch(`/Api/Documents/GetDocument?docId=${currentlyCuring.documentId}`).then(({ data }) =>
      setCuringDocument(data),
    );
  }, [currentlyCuring]);

  useEffect(() => {
    if (!(attachmentModalOpen && attachmentModalOpen.documentId)) {
      return;
    }

    apiFetch(`/Api/DocumentEmail/GetRelatedAttachments/${attachmentModalOpen.documentId}`).then(
      ({ data }) => setAttachments(data),
    );
  }, [attachmentModalOpen]);

  const saveNote = async (note: string) => {
    if (newNoteDocuments.length > 0) {
      const postData = {
        noteText: note,
        documentIds: newNoteDocuments.map(row => row.documentId),
      };

      try {
        await apiPost('/api/Document-Notes/add-bulk-note', postData);
        successToaster('Successfully added note(s).');
        refreshDatatable();
      } catch (e) {
        if (e.response) {
          const errorMessage = e.response.data.split('\n')[0];
          errorToaster(errorMessage || e.message);
        } else {
          errorToaster(e.message);
        }
      }
      setNewNoteDocuments([]);
      setNewNoteOpen(false);
    }
  };

  const columns = [
    {
      field: 'client',
      title: 'Client',
      sorting: false,
      render: (rowData: RowData) => (
        <div
          css={{ cursor: 'pointer' }}
          onClick={() => {
            // @ts-ignore
            onCustomOptionSelected(clientFilter, {
              id: rowData.clientId,
              label: rowData.client,
            });
          }}
        >
          {rowData.client}
        </div>
      ),
    },
    {
      field: 'titleCompany',
      title: 'Title Company',
      sorting: false,
      render: (rowData: RowData) => (
        <div
          css={{ cursor: 'pointer' }}
          onClick={() => {
            // @ts-ignore
            onCustomOptionSelected(titleCompanyFilter, {
              id: rowData.titleCompanyId,
              label: rowData.titleCompany,
            });
          }}
        >
          {rowData.titleCompany}
        </div>
      ),
    },
    {
      field: 'borrower',
      title: 'Borrower',
      sorting: false,
    },
    {
      field: 'investor',
      title: 'Investor',
      sorting: false,
    },
    {
      field: 'documentType',
      title: 'Doc Type',
      sorting: false,
    },
    {
      field: 'dateFunded',
      title: 'Date Funded',
      render: rowData =>
        rowData.dateFunded !== null && new Date(rowData.dateFunded).toLocaleDateString(),
    },
    {
      field: 'dateFailedVerification',
      title: 'Date Failed',
      sorting: false,
      render: rowData => (
        <Fragment>
          {rowData.dateFailedAudit !== null && (
            <div>{new Date(rowData.dateFailedAudit).toLocaleDateString()}</div>
          )}
          {rowData.dateFailedVerification !== null && (
            <div>{new Date(rowData.dateFailedVerification).toLocaleDateString()}</div>
          )}
        </Fragment>
      ),
    },
    {
      field: 'hardCopy',
      title: 'Format',
      sorting: false,
      render: rowData => (rowData.hardCopy ? 'Physical' : 'Digital'),
    },
    {
      field: 'county',
      title: 'County',
      sorting: false,
    },
    {
      field: 'auditNote',
      title: 'Failed Audit Reasons and Note',
      sorting: false,
      render: rowData => (
        <Fragment>
          {rowData.isInvestorFailure && <div>Investor Fail -</div>}
          <div>{rowData.failedAuditReasons?.replace(/_/g, ' ').toLowerCase()}</div>
          <div>{rowData.auditNote}</div>
        </Fragment>
      ),
    },
    {
      field: 'failedVerificationNote',
      title: 'Failed Verification Fields/ Notes',
      sorting: false,
    },
    {
      field: 'lastNoteDate',
      title: 'Last Action Date',
      render: (rowData: RowData) =>
        rowData.lastNoteDate && <div>{new Date(rowData.lastNoteDate).toLocaleDateString()}</div>,
      sorting: false,
    },
  ];

  const detailsDisplay = (rowData: RowData) => (
    <div css={detailPanelStyles(rowData)}>
      <div>
        <div css={[subSectionStyles, { display: 'inline-block' }]}>
          {!rowData.correctionId && (rowData.dateFailedVerification || rowData.dateFailedAudit) && (
            <span css={{ padding: 4, paddingRight: 8 }}>
              <Tooltip title="Resolve">
                <PlaylistAddCheckIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCurrentlyCuring(rowData);
                  }}
                />
              </Tooltip>
            </span>
          )}

          <span css={{ padding: 4 }}>
            <Tooltip title="Add a Note">
              <CommentIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setNewNoteOpen(true);
                  setNewNoteDocuments([rowData]);
                }}
              />
            </Tooltip>
          </span>
        </div>
      </div>

      <div>
        <p>
          <span>Barcode ID: </span>
          <Link target="_blank" href={`/documents/${rowData.documentId}`}>
            {rowData.documentId}
          </Link>
        </p>
        {rowData.loanId && rowData.loanId > 0 && (
          <Fragment>
            <p>
              <span>Loan ID: </span>
              <Link target="_blank" href={`/loans/${rowData.loanId}`}>
                {rowData.loanId}
              </Link>
            </p>
            <p>
              <span>Loan Number: </span>
              <Link target="_blank" href={`/loans/${rowData.loanId}`}>
                {rowData.loanNumber}
              </Link>
            </p>
          </Fragment>
        )}
      </div>

      <div>
        {(rowData.titleCompanyPhone || rowData.titleCompanyEmail) && (
          <Fragment>
            <p>Global Contact Info:</p>
            <a href={`tel:${rowData.titleCompanyPhone}`}>
              <p>
                {rowData.titleCompanyPhone && formatPhoneWithParenthesis(rowData.titleCompanyPhone)}
              </p>
            </a>
            <a href={`mailto:${rowData.titleCompanyEmail}`}>
              <p css={emailStyles}>{rowData.titleCompanyEmail}</p>
            </a>
          </Fragment>
        )}
      </div>
      <div>
        {(rowData.loanLevelPhone || rowData.loanLevelEmail) && (
          <Fragment>
            <p>Loan Level Contact:</p>
            <a href={`tel:${rowData.loanLevelPhone}`}>
              <p>{rowData.loanLevelPhone && formatPhoneWithParenthesis(rowData.loanLevelPhone)}</p>
            </a>
            <a href={`mailto:${rowData.loanLevelEmail}`}>
              <p css={emailStyles}>{rowData.loanLevelEmail}</p>
            </a>
          </Fragment>
        )}
      </div>
      <div>
        {rowData.documentSender && (
          <Fragment>
            <p>Document Sender:</p>
            <a href={`mailto:${rowData.documentSender}`}>
              <p css={emailStyles}>{rowData.documentSender}</p>
            </a>
            <button
              css={attachmentButtonStyles}
              onClick={() => {
                setAttachmentModalOpen(rowData);
              }}
            >
              View Attachments
            </button>
          </Fragment>
        )}
      </div>

      {rowData.documentNotes && (
        <div>
          <b css={{ padding: '0px 8px 8px' }}>NOTES:</b>
          <NotesDisplay notes={rowData.documentNotes} />
        </div>
      )}
    </div>
  );

  const fileExport = useFileExport<ExportData>();

  const exportDatatable = (dataTableRows: RowData[]) => {
    const exportData = dataTableRows.map(row => {
      const lastNote = row.documentNotes && row.documentNotes[row.documentNotes.length - 1];
      const exportRow: ExportData = {
        'Document ID': row.documentId,
        'Document Type': row.documentType,
        'Loan Number': row.loanNumber,
        'Title Number': row.titleNumber,
        Lender: row.client,
        Borrower: row.borrower,
        Investor: row.investor,
        'Property Address': row.propertyAddress,
        City: row.city,
        State: row.state,
        Zip: row.zip,
        County: row.county,
        'Date Funded': new Date(row.dateFunded).toLocaleDateString(),
        'Date Failed Audit': row.dateFailedAudit
          ? new Date(row.dateFailedAudit).toLocaleDateString()
          : '',
        'Date Failed Verification': row.dateFailedVerification
          ? new Date(row.dateFailedVerification).toLocaleDateString()
          : '',
        Format: row.hardCopy ? 'Physical' : 'Digital',
        'Failed Audit Reasons':
          row.failedAuditReasons && `${row.failedAuditReasons?.replace(/_/g, ' ').toLowerCase()}`,
        'Failed Audit Note': row.auditNote,
        'Investor Failure': row.isInvestorFailure ? 'Yes' : 'No',
        'Failed Verification Fields/ Note': row.failedVerificationNote,
        'Title Company': row.titleCompany,
        'Loan Level Email': row.loanLevelEmail,
        'Loan Level Phone': row.loanLevelPhone,
        'Document Sender': row.documentSender,
        'Last Action Taken':
          lastNote &&
          `${new Date(lastNote.createdAt).toLocaleDateString()} - ${lastNote.createdBy} - ${
            lastNote.noteText
          }`,
      };

      return exportRow;
    });

    fileExport(exportData, 'Corrections Pipeline Docs');
  };

  return (
    <Fragment>
      <div css={{ paddingLeft: 29 }}>
        {titleCompanyFilter?.filterOptions &&
          titleCompanyFilter.filterOptions.length > 0 &&
          titleCompany?.id && (
            <div className={classes.root}>
              <Chip
                variant="outlined"
                onClick={() => clearCustomFilter(titleCompanyFilter)}
                label="Clear Title Company Filter"
              />
            </div>
          )}
        {clientFilter?.filterOptions && clientFilter.filterOptions.length > 0 && client?.id && (
          <div className={classes.root}>
            <Chip
              variant="outlined"
              onClick={() => clearCustomFilter(clientFilter)}
              label="Clear Client Filter"
            />
          </div>
        )}
        {investorFilter?.filterOptions &&
          investorFilter.filterOptions.length > 0 &&
          investor?.id && (
            <div className={classes.root}>
              <Chip
                variant="outlined"
                onClick={() => clearCustomFilter(investorFilter)}
                label="Clear Investor Filter"
              />
            </div>
          )}
        {documentStatusFilter?.filterOptions && documentStatusFilter.filterOptions.length > 0 && (
          <div className={classes.root}>
            <Chip
              variant="outlined"
              onClick={() => clearCheckBoxFilter(documentStatusFilter)}
              label="Clear Document Status Filter"
            />
          </div>
        )}
          {failureSensitivityFilter?.filterOptions && failureSensitivityFilter.filterOptions.length > 0 && (
              <div className={classes.root}>
                  <Chip
                      variant="outlined"
                      onClick={() => clearCheckBoxFilter(failureSensitivityFilter)}
                      label="Clear Failure Sensitivity Filter"
                  />
              </div>
          )}
      </div>

      <DataTable<RowData>
        title="Correction Pipeline Documents"
        orderBy={{ field: 'dateFunded' }}
        orderDirection="asc"
        columns={columns}
        url="/Api/AccountReps/Documents"
        detailPanel={(rowData: RowData) => detailsDisplay(rowData)}
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        customExport={exportDatatable}
        dataTableFilterTypes={dataTableFilterTypes}
        setDataTableFilterTypes={setDataTableFilterTypes}
        renderCustomFilters={
          <div css={{ margin: '10px 5px' }}>
            {titleCompanyFilter && (
              <TitleCompanySearchSelect
                // @ts-ignore
                selected={titleCompany}
                onChange={({ id, name }) =>
                  onCustomOptionSelected(titleCompanyFilter, { id, label: name, active: true })
                }
              />
            )}
            {clientFilter && (
              <ClientSearch
                selectedClient={client?.id}
                onChange={({ id, company }) =>
                  onCustomOptionSelected(clientFilter, { id, label: company, active: true })
                }
                styleOverrides={{ marginTop: 16 }}
              />
            )}
            {investorFilter && (
              <InvestorSearchSelect
                selected={investor}
                onChange={({ id, name }) =>
                  onCustomOptionSelected(investorFilter, { id, label: name, active: true })
                }
                css={{ marginTop: 16 }}
              />
            )}
          </div>
        }
        options={{
          rowStyle: rowData => ({
            backgroundColor: rowData.correctionId ? '#EDF7ED' : '#FFF' /* #c8f7c8 */,
          }),
          selection: true,
        }}
        actions={() => [
          {
            key: 'add-note',
            tooltip: 'Add note for all selected documents',
            icon: () => <PostAddIcon color="primary" />,
            onClick: () => setNewNoteOpen(!newNoteOpen),
          },
        ]}
        onSelectionChange={rows => setNewNoteDocuments(rows)}
      />

      {/* RESOLVE DOCUMENT DIALOG */}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={currentlyCuring !== false}
        onClose={() => {
          setCurrentlyCuring(false);
          setCuringDocument(undefined);
        }}
      >
        <DialogTitle>
          Resolve Document {curingDocument?.id} {curingDocument?.hardCopy ? 'PHYSICAL' : 'DIGITAL'}
          <div css={{ fontWeight: 400, fontSize: 15 }}>
            {currentlyCuring && currentlyCuring.loanId !== 0 && (
              <div>Loan ID: {currentlyCuring.loanId}</div>
            )}
            {currentlyCuring && currentlyCuring.borrower && (
              <div>Borrower: {currentlyCuring.borrower}</div>
            )}
            {currentlyCuring && currentlyCuring.documentType && (
              <div>Document Type: {currentlyCuring.documentType}</div>
            )}
          </div>
        </DialogTitle>
        {curingDocument && <CureDocument document={curingDocument} onCure={onDocumentCured} />}
      </Dialog>

      {/* ADD NEW NOTE MODAL */}
      <Modal
        open={newNoteOpen}
        onClose={() => {
          setNewNoteOpen(false);
          setNewNoteDocuments([]);
        }}
      >
        <div className={classes.paper}>
          <AddNoteInput
            noteType={
              newNoteDocuments.length === 1
                ? `${newNoteDocuments[0].documentType} - Document ${newNoteDocuments[0].documentId}`
                : 'Bulk Document'
            }
            addNote={saveNote}
          />
        </div>
      </Modal>

      {/* SUCCESS MESSAGES */}
      <Snackbar
        open={cureMessage !== null}
        autoHideDuration={6000}
        onClose={() => setCureMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          severity={!cureMessage?.startsWith('ERROR') ? 'success' : 'error'}
          onClose={() => setCureMessage(null)}
        >
          {cureMessage === 'DIGITAL' && 'Success! Doc is now available for shipping.'}
          {cureMessage === 'PHYSICAL' && 'Success! Please print this document.'}
          {cureMessage === 'REPLACEMENT' && 'Success! Document has been replaced.'}
          {cureMessage === 'NOTE' && 'Success! Doc marked as passed.'}
          {cureMessage?.startsWith('ERROR') && cureMessage}
        </Alert>
      </Snackbar>

      <Modal open={attachmentModalOpen !== false} onClose={() => setAttachmentModalOpen(false)}>
        <div className={classes.paper}>
          <EmailAttachments attachments={attachments} />
        </div>
      </Modal>
    </Fragment>
  );
}

export const NotesDisplay = ({ notes }: { notes: DocumentNote[] }) => (
  <div css={[subSectionStyles, { padding: 16, maxHeight: 100, overflowY: 'scroll' }]}>
    {notes
      .sort((a, b) => Date.parse(a.createdAt.toString()) - Date.parse(b.createdAt.toString()))
      .map((documentNote: DocumentNote) => (
        <div key={documentNote.id}>
          <div
            css={{
              display: 'grid',
              gridTemplateColumns: '100px 100px 1fr',
            }}
          >
            <b>{new Date(documentNote.createdAt).toLocaleDateString()}</b>
            <b>{documentNote.createdBy}</b>
            <div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{documentNote.noteText}</p>
            </div>
          </div>
        </div>
      ))}
  </div>
);
