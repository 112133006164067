import React, { useState } from 'react';
import { ShippingClient } from './ClientModal';
import { apiFetch } from '../../adalConfig';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { useLocalStorage } from '@rehooks/local-storage';
import { useToaster } from '../../Hooks/toasters';
import parseISO from 'date-fns/parseISO';

type ShippingClientsContext = {
  getShippingClients: (investorId: number) => Promise<ShippingClient[]>;
  isLoadingShippingClients: boolean;
};

const ShippingClientsContext = React.createContext({} as ShippingClientsContext);

type InvestorData = {
  shippingClients: ShippingClient[];
  timeOfFetch: string;
};

export type ShippingClientsByInvestor = {
  [key: number]: InvestorData;
};

export const SHIPPING_CLIENT_LS_KEY = 'shipping.clients.by.investor';
export const CACHE_TIME = 60;

const filterStaleInvestorData = (investorData: ShippingClientsByInvestor) =>
  Object.entries(investorData).reduce((final, [investorId, dataForInvestor]) => {
    if (differenceInMinutes(new Date(), parseISO(dataForInvestor.timeOfFetch)) < CACHE_TIME) {
      final[investorId] = dataForInvestor;
    }

    return final;
  }, {} as ShippingClientsByInvestor);

export const ShippingClientsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [investorData, setInvestorData] = useLocalStorage<ShippingClientsByInvestor>(
    SHIPPING_CLIENT_LS_KEY,
    [],
  );
  const { errorToaster } = useToaster();

  const getShippingClients = async (investorId: number) => {
    if (
      investorData[investorId] &&
      differenceInMinutes(new Date(), parseISO(investorData[investorId].timeOfFetch)) < CACHE_TIME
    ) {
      return investorData[investorId].shippingClients;
    }

    try {
      setIsLoading(true);
      const { data } = await apiFetch<ShippingClient[]>(
        `/api/shipping/shippingClients?investorId=${investorId}`,
      );

      const investorShippingClients: InvestorData = {
        shippingClients: data,
        timeOfFetch: new Date().toISOString(),
      };

      const filteredInvestorData = filterStaleInvestorData(investorData);
      setInvestorData({ ...filteredInvestorData, [investorId]: investorShippingClients });

      return data;
    } catch (e) {
      errorToaster('Failed to retrieve investor stats');
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ShippingClientsContext.Provider
      value={{ getShippingClients, isLoadingShippingClients: isLoading }}
    >
      {children}
    </ShippingClientsContext.Provider>
  );
};

export default ShippingClientsContext;
