import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import TextInput from './TextInput';

export default function InputNumber({
  thousandSeparator,
  value,
  onChange,
  prefix,
  ...props
}: NumberFormatProps) {
  return (
    <NumberFormat
      thousandSeparator={thousandSeparator}
      value={value}
      onChange={onChange}
      prefix={prefix}
      customInput={TextInput}
      {...props}
    />
  );
}
