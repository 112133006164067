import difference from 'lodash/difference';
import { Loan, ContactType } from '../../../globalTypes/objects';

export const DebounceRate = 800;

export const errorType = {
  title: 'titleError',
  investor: 'investorError',
};

export const AssocState = {
  unmatched: 'UNMATCHED',
  matched: 'MATCHED',
  associating: 'ASSOCIATING',
  associated: 'ASSOCIATED',
  adding: 'ADDING',
  added: 'ADDED',
  assocError: 'ASSOC_ERROR',
  addError: 'ADD_ERROR',
};

export const loanErrorIssues = {
  BORROWER_NAME: 'Borrower name',
  PROPERTY_ADDRESS: 'Property address',
  PROPERTY_CITY: 'Property city',
  PROPERTY_STATE: 'Property state',
  PROPERTY_ZIP: 'Property zip',
  PROPERTY_COUNTY: 'Property county',
  INVESTOR_NAME: 'Investor name',
  TITLE_COMPANY_NAME: 'Title company name',
  TITLE_COMPANY_ADDRESS: 'Title company address',
  SERVICER: 'Servicer',
};

export const SuccessTimeout = 1000;

export const isEasyToResolve = (issues: string[]) => {
  const pinnedIssues = [loanErrorIssues.PROPERTY_ZIP, loanErrorIssues.PROPERTY_COUNTY];
  return difference(issues, pinnedIssues).length === 0;
};

export type AssociationError = {
  id: number;
  errorType: ContactType;
  type: ContactType;
  currentAssocState: any;
  currentAddState: any;
  associationInformation: LoanInformation[];
  errorIndex: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  incomplete: boolean;
};

export type LoanInformation = {
  client: string;
  spreadsheetDate?: string;
  borrower: string;
  loanId: number;
  loanNumber: string;
  propertyAddress: string;
};

export type FormData = {
  borrowerFirstName?: string;
  borrowerLastName?: string;
  propertyAddress?: string;
  propertyCity?: string;
  propertyState?: string;
  propertyZip?: string;
  propertyCounty?: string;
  investorName: string;
  investorAddress?: string;
  titleCompanyName: string;
  titleCompanyAddress: string;
  investorId?: number;
  titleCompanyId?: number;
};

export type LoanError = {
  id: number;
  clientId: number;
  loanId: number;
  loanErrorDataId: number;
  loanErrorIssues: string[];
  isPinned?: boolean;
  loan: RawLoanData;
};

export type RawLoanData = {
  id: number;
  loanNumber: string;
  borrowerFullName: string;
  propertyAddress: string;
  propertyCity: string;
  propertyState: string;
  propertyZip: string;
  propertyType: string;
  propertyCounty: string;
  investorName: string;
  investorId: string;
  servicer: string;
  investorCommitmentNumber: string;
  loanAmount: number;
  paymentStream: string;
  dateDocumentsDrawn: string;
  titleCompanyId: number;
  titleCompanyName: string;
  titleOrderNumber: string;
  titleCompanyAddress: string;
  titleCompanyCity: string;
  titleCompanyState: string;
  titleCompanyZip: string;
  titleCompanyPhone: string;
  titleCompanyFax: string;
  titleCompanyEmail: string;
  error: string;
};
