/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { PanelHeader } from '../../../ui/Panel';
import BarcodeInput from '../../../Utilities/BarcodeInput';
import { SortResponse } from '../SortDocumentPanel';
import ResultPanel from './ResultPanel';

const useStyles = makeStyles(theme => ({
  barcodePanel: {
    padding: '24px',
    borderRadius: '4px 4px 0 0',
    border: 'solid 1px #e6e4e8',
    backgroundColor: '#fffeff',
  },
}));

type Props = {
  clientName: string;
  sortResponse: SortResponse;
  onDocumentIdChange: (documentId: string) => Promise<void>;
  keepFocus: boolean;
  isProcessingSort: boolean;
};

const ScanForSort = ({
  clientName,
  sortResponse,
  onDocumentIdChange,
  keepFocus,
  isProcessingSort,
}: Props) => {
  const classes = useStyles();

  return (
    <div style={{ position: 'sticky', top: '0', height: '450px' }}>
      <div
        css={{
          textAlign: 'center',
          alignContent: 'center',
        }}
      >
        <div className={classes.barcodePanel}>
          <PanelHeader text="Sort Document" />
          <BarcodeInput keepFocus={keepFocus} onChange={onDocumentIdChange} />
        </div>
      </div>
      <ResultPanel
        clientName={clientName}
        sortResponse={sortResponse}
        isProcessingSort={isProcessingSort}
      />
    </div>
  );
};

export default ScanForSort;
