/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Checkbox, ListItemText, MenuItem } from '@mui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { Updater, useImmer } from 'use-immer';

import Panel from '../../ui/Panel';
import StepHeader from '../../Utilities/StepUI/StepHeader';
import { apiFetch } from '../../../adalConfig';
import { panelWrap } from '../../Utilities/StepUI/styles';
import { AuditSection, Label } from './stepOneStyles';
import CustomAuditPanel from '../Audit/CustomAuditPanel';
import {
  AuditPoint,
  AuditState,
  CustomAuditRules,
  DocumentType,
  isFailedAudit,
  isPassedAudit,
} from '../../../globalTypes/objects';
import AuditFail, { AuditFailVersion } from '../Audit';
import FailedAuditDisplay from '../Audit/FailedAuditDisplay';

const getClientsWithCustomAuditRules = async docType => {
  const { data: clients } = await apiFetch<number[]>(`/api/CustomAudit/Clients/${docType}`);
  return clients;
};

const getCustomAuditRules = async (clientId, docType, loanId) => {
  const { data: rules } = await apiFetch<CustomAuditRules[]>(
    `/api/CustomAudit/CustomAuditRequirements/${clientId}/${docType}/${loanId}`,
  );
  return rules;
};

// hooks can only be used in functional components, so we wrap the
// StepFour component in a functional component and forward the props
export default function StepFour(props) {
  const useAuditState = useImmer<AuditState>({ kind: 'NotAudited' });
  const useAuditModal = useState(false);

  return <StepFourImpl {...props} useAuditState={useAuditState} useAuditModal={useAuditModal} />;
}

interface Props {
  [propKey: string]: any;
  useAuditState: [AuditState, Updater<AuditState>];
  useAuditModal: [boolean, Dispatch<SetStateAction<boolean>>];
}

interface State {
  file: undefined;
  docType: string;
  customAuditRules: any[];
  client: undefined;
  notFound: boolean;
  customAuditClients: any[];
  auditDeferred: boolean;
  docTypeId: number | null;
}

class StepFourImpl extends React.Component<Props, State> {
  state = {
    docType: '',
    docTypeId: null,
    customAuditClients: [],
    customAuditRules: [],
    notFound: false,
    client: undefined,
    file: undefined,
    auditDeferred: false,
  };

  async componentDidMount() {
    const { docType, docTypeId, file, client, notFound, loanId, documentTypes } = this.props; // come back and do this in constructor

    (docType || file) &&
      this.setState({
        client,
        docType: documentTypes.find(doc => doc.label === docType),
        docTypeId: documentTypes.find(doc => doc.value === docTypeId),
        file,
        notFound,
        loanId,
      });

    const customAuditClients = await getClientsWithCustomAuditRules(docTypeId);
    this.setState({ customAuditClients });
    const isVUclient = client?.id === 293;

    const hasCustomAudits = customAuditClients?.some(c => c === client?.id);
    if ((hasCustomAudits && notFound) || isVUclient) {
      this.setState({ auditDeferred: true });
    }
    const customAuditRules = await getCustomAuditRules(client?.id, docTypeId, loanId);
    this.setState({ customAuditRules });
  }

  componentDidUpdate(prevProps, prevState) {
    const { docType, file, client, auditDeferred } = this.state;
    const {
      useAuditState: [audit],
    } = this.props;

    const {
      useAuditState: [prevAuditState],
    } = prevProps;

    if (
      docType !== prevState.docType ||
      file !== prevState.file ||
      client !== prevState.client ||
      audit !== prevAuditState ||
      auditDeferred !== prevState.auditDeferred
    ) {
      this.props.handleChange({ auditDeferred, audit });
    }
  }

  render() {
    const { client, docType, auditDeferred, customAuditClients, customAuditRules, notFound } =
      this.state;

    const {
      useAuditState: [audit, setAudit],
      useAuditModal: [auditModal, setAuditModal],
    } = this.props;

    const isVUclient = client?.id === 293;
    const isUnableToAudit =
      customAuditClients.some(c => c === client?.id) && notFound && !isVUclient;
    const hasCustomAuditRules = !notFound && customAuditRules.length !== 0;

    return (
      <Panel styles={[panelWrap, { display: 'inline-block' }]}>
        <StepHeader text="Audit Document" number="4" />

        {hasCustomAuditRules && <CustomAuditPanel rules={customAuditRules} passedStandardAudit={false} />}
        {docType && (
          <AuditSection>
            <form>
              {isUnableToAudit && (
                <div css={{ paddingBottom: '16px', color: 'red', fontStyle: 'italic' }}>
                  This document needs to be matched before audit!
                </div>
              )}
              {isVUclient && (
                <div css={{ paddingBottom: '16px', color: 'red', fontStyle: 'italic' }}>
                  This client requires digital audit!
                </div>
              )}
              <div className="df col">
                <div css={{ height: 48, display: 'flex', alignItems: 'center' }}>
                  <Label>
                    <input
                      onChange={() => {
                        this.setState({ auditDeferred: false });
                        setAudit(draft => {
                          draft.kind = 'PassedAudit';
                          if (!isPassedAudit(draft)) return;
                          draft.passed = true;
                        });
                      }}
                      type="radio"
                      name="audit"
                      disabled={isUnableToAudit || isVUclient}
                    />
                    Pass
                  </Label>
                </div>
                <div css={{ height: 48, display: 'flex', alignItems: 'center' }}>
                  <Label>
                    <input
                      onChange={() => {
                        this.setState({
                          auditDeferred: false,
                        });
                        setAudit(draft => {
                          draft.kind = 'FailedAudit';
                          if (!isFailedAudit(draft)) return;
                          draft.passed = false;
                          draft.notes = '';
                          draft.failureReasons = [];
                        });
                      }}
                      type="radio"
                      name="audit"
                      disabled={isUnableToAudit || isVUclient}
                    />
                    Fail
                  </Label>
                  {isFailedAudit(audit) && !auditDeferred && (
                    <Button variant="text" color="primary" onClick={() => setAuditModal(true)}>
                      Choose Reasons
                    </Button>
                  )}
                </div>
                <div css={{ height: 48, display: 'flex', alignItems: 'center' }}>
                  <Label>
                    {isUnableToAudit || isVUclient ? (
                      <input
                        onChange={() => this.setState({ auditDeferred: true })}
                        type="radio"
                        name="audit"
                        checked={this.state.auditDeferred}
                      />
                    ) : (
                      <input
                        onChange={() => this.setState({ auditDeferred: true })}
                        type="radio"
                        name="audit"
                        checked={this.state.auditDeferred}
                      />
                    )}
                    Defer Audit
                  </Label>
                </div>
              </div>
              {isFailedAudit(audit) &&
                !auditDeferred &&
                audit.failureReasons.length > 0 &&
                !auditModal && <FailedAuditDisplay failedReasons={audit.failureReasons} />}
              {isFailedAudit(audit) && !auditDeferred && auditModal && (
                <AuditFail
                  closeModal={() => setAuditModal(false)}
                  saveFailedAuditData={failedReasons => {
                    setAudit(draft => {
                      if (!isFailedAudit(draft)) return;
                      draft.failureReasons = failedReasons;
                    });
                    setAuditModal(false);
                  }}
                  docType={this.props.docTypeId}
                  version={AuditFailVersion.modal}
                  alreadySelectedFailures={audit.failureReasons}
                />
              )}
            </form>
          </AuditSection>
        )}
      </Panel>
    );
  }
}
