import React, { ReactNode, useContext, useMemo } from 'react';
import { Updater, useImmer } from 'use-immer';
import {
  AuditPoint,
  AuditPointCategory,
  AuditPointOption,
  DocumentType,
} from '../../../globalTypes/objects';
import useAuditPoints from '../../../Hooks/useAuditPoints';

type AuditFailContextValue = [
  { failedReasons: AuditPoint[]; auditPointCategories: AuditPointCategory[] | undefined },
  Updater<AuditPoint[]>,
];

const AuditFailContext = React.createContext({} as AuditFailContextValue);

type AuditFailProviderProps = {
  docType: DocumentType;
  investorOnly?: boolean;
  children: ReactNode;
};
function AuditFailProvider({ docType, investorOnly = false, children }: AuditFailProviderProps) {
  const [failedReasons, updateFailedReasons] = useImmer<AuditPoint[]>([]);
  const auditPointCategories = useAuditPoints(docType, investorOnly);
  const auditPointCategoriesWithTag = auditPointCategories?.map<AuditPointCategory>(apc => ({
    ...apc,
    auditPoints: apc.auditPoints.map(ap => ({
      ...ap,
      kind: 'AuditPoint',
      options: ap.options.map(option => ({
        ...option,
        kind: 'AuditPointOption',
      })),
    })),
  }));

  const value: AuditFailContextValue = useMemo(
    () => [
      { failedReasons, auditPointCategories: auditPointCategoriesWithTag },
      updateFailedReasons,
    ],
    [auditPointCategoriesWithTag, failedReasons, updateFailedReasons],
  );
  return <AuditFailContext.Provider value={value}>{children}</AuditFailContext.Provider>;
}

const useAuditFailContext = () => {
  const context = useContext(AuditFailContext);

  if (!context) {
    throw new Error('useAuditFailContext must be used within a AuditFailProvider');
  }

  return context;
};

export { AuditFailProvider, useAuditFailContext };
