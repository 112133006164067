/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { AssociationsGroupedByUser as RowData } from './types';

type Props = {
  associationsGroupedByUser: RowData[];
};

const columns = [
  {
    field: 'associationType',
    title: 'Association Type',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'count',
    title: 'Count',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'user',
    title: 'User',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  },
  {
    field: 'date',
    title: 'Date',
    options: {
      filter: false,
    },
    cellStyle: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    render: (rowData: RowData) => rowData.date && new Date(rowData.date).toLocaleDateString(),
  },
];

const AssociationsGroupedByUser = ({ associationsGroupedByUser }: Props) => (
  <DataTable<RowData>
    title="Associations - By User"
    columns={columns}
    data={associationsGroupedByUser}
    options={{
      maxBodyHeight: '500px',
      search: false,
    }}
  />
);

export default AssociationsGroupedByUser;
