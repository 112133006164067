import { Configuration, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    clientId: AZURE_AD_CRED.applicationId,
    authority: `https://login.microsoftonline.com/${AZURE_AD_CRED.tenantId}`,
    redirectUri: window.location.origin,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // to display logs in console uncomment code below
        /*switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }*/
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: [`api://${AZURE_AD_CRED.applicationId}/User.Read`],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getToken = async () => {
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account || undefined,
  });

  return response.accessToken;
};

export const authHeader = async () => {
  const token = await getToken();
  return {
    Authorization: `Bearer ${token}`,
  };
};
