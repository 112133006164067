/* eslint-disable no-console */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { stringifyUrl } from 'query-string';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { Card, CardContent, CircularProgress } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DocumentsPage from './DocumentsPage';
import { DocumentPageDto } from '../globalTypes/objects';
import {
  Failure,
  isFailure,
  isSuccess,
  NotAsked,
  RemoteData,
  Success,
} from '../globalTypes/RemoteData';
import { documentPageData } from '../Utils';
import { apiPost, fetchWithAuth } from '../adalConfig';

async function getDocumentForDetermination(
  clientId: number | undefined,
  skipped: number | undefined,
  physicals: number | undefined,
  digitals: number | undefined,
  isTitleCompanyUploads: boolean,
): Promise<number> {
  const url = stringifyUrl({
    url: `/Api/Documents/Determination/GetNext`,
    query: {
      clientId: clientId?.toString(),
      skipped: skipped?.toString(),
      physicals: physicals?.toString(),
      digitals: digitals?.toString(),
      titleCompanyUploads: isTitleCompanyUploads.toString(),
    },
  });

  const res = await fetchWithAuth(url);
  if (!res.ok) {
    throw new Error('Cannot load document. Please refresh page.');
  }
  const maybeDocId = await res.text();
  return parseInt(maybeDocId, 10);
}

const getPageData = async (
  query,
  isTitleCompanyUploads,
): Promise<RemoteData<DocumentPageDto | null, Error>> => {
  try {
    const documentId = await getDocumentForDetermination(
      query.clientid,
      query.skipped,
      query.physicals,
      query.digitals,
      isTitleCompanyUploads,
    );

    if (documentId === 0) {
      return Success(null);
    }

    const data = await documentPageData(documentId);
    return Success(data);
  } catch (e) {
    return Failure(e);
  }
};

const reLockRecord = async (documentId: number) => {
  try {
    await apiPost(`/api/documents/determination/re-lock-record`, {}, { params: { documentId } });
    return true;
  } catch (e) {
    return false;
  }
};

export default function DeterminationPageWrapper({
  isTitleCompanyUploads = false,
}: {
  isTitleCompanyUploads?: boolean;
}) {
  const [query, setQuery] = useQueryParams({
    clientid: withDefault(NumberParam, undefined),
    skipped: withDefault(NumberParam, undefined),
    physicals: withDefault(NumberParam, undefined),
    digitals: withDefault(NumberParam, undefined),
  });

  const [pageData, setPageData] = useState<RemoteData<DocumentPageDto | null, Error>>(NotAsked);
  const [nextPageData, setNextPageData] =
    useState<RemoteData<DocumentPageDto | null, Error>>(NotAsked);
  const [isLoadingPageData, setIsLoadingPageData] = useState(false);

  const loadDocument = useCallback(() => {
    if (isSuccess(nextPageData) && nextPageData.data) {
      rotateResults(nextPageData.data.document.id, structuredClone(nextPageData));
      return;
    }

    loadInitialResults();
  }, [
    isTitleCompanyUploads,
    query.clientid,
    query.skipped,
    query.physicals,
    query.digitals,
    nextPageData,
    setPageData,
    setNextPageData,
  ]);

  useEffect(loadDocument, []);

  const rotateResults = useCallback(
    async (documentId: number, nextPageData: Success<DocumentPageDto | null>) => {
      setIsLoadingPageData(true);

      const ableToLock = await reLockRecord(documentId);
      if (ableToLock) {
        setPageData(nextPageData);
      } else {
        const mainPageData = await getPageData(query, isTitleCompanyUploads);
        setPageData(mainPageData);
      }

      setIsLoadingPageData(false);

      const futurePageData = await getPageData(query, isTitleCompanyUploads);
      setNextPageData(futurePageData);
    },
    [setPageData, setNextPageData],
  );

  const loadInitialResults = useCallback(async () => {
    setIsLoadingPageData(true);

    const mainPageData = await getPageData(query, isTitleCompanyUploads);
    setPageData(mainPageData);

    setIsLoadingPageData(false);

    const futurePageData = await getPageData(query, isTitleCompanyUploads);
    setNextPageData(futurePageData);
  }, [setPageData, setNextPageData]);

  const doingSkipped = !!query.skipped;
  const doingPhysicals = !!query.physicals;
  const doingDigitals = !!query.digitals;

  if (isLoadingPageData) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <Fragment>
      {isFailure(pageData) && (
        <Card css={{ display: 'inline-block', marginLeft: 24, marginTop: 24 }}>
          <CardContent css={{ display: 'flex', alignItems: 'center' }}>
            <ErrorOutlineIcon className="mr1" />
            Whoops, there was an error: {pageData.error.message}
          </CardContent>
        </Card>
      )}
      {isSuccess(pageData) && !pageData.data && (
        <Card css={{ display: 'inline-block', marginLeft: 24, marginTop: 24 }}>
          <CardContent css={{ display: 'flex', alignItems: 'center' }}>
            <CheckIcon className="mr1" />
            No documents need to be audited now.
          </CardContent>
        </Card>
      )}
      {isSuccess(pageData) && pageData.data && (
        <DocumentsPage
          determinationProps={{
            onNext: loadDocument,
            doingSkipped,
            doingPhysicals,
            doingDigitals,
            clientId: query.clientid,
            isTitleCompanyUploads,
          }}
          pageData={pageData.data}
        />
      )}
    </Fragment>
  );
}
