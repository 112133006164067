import { useContext } from 'react';
import { InvestorsContext } from '../InvestorsContext';

export function useInvestor(investorId: number) {
  const investors = useContext(InvestorsContext);
  return investors.find(investor => investor.id === investorId);
}

export function useInvestors() {
  return useContext(InvestorsContext);
}
