import React from 'react';
import { useFetchWithAuth } from './adalConfig';
import { Investor } from './globalTypes/objects';

export const InvestorsContext = React.createContext<Investor[]>([]);

function InvestorsProvider({ children }) {
  const investorReq = useFetchWithAuth<Investor[]>(`/Api/Investors/GetAll`);
  return investorReq.isFulfilled ? (
    <InvestorsContext.Provider value={investorReq.data}>{children}</InvestorsContext.Provider>
  ) : null;
}
const InvestorsConsumer = InvestorsContext.Consumer;

export { InvestorsProvider, InvestorsConsumer };
