/** @jsx jsx */
import { jsx } from '@emotion/core';
import { format } from 'date-fns';

import colors from '../../../styles/colors';
import { CalendarIcon } from '../../ui/icons';

const cardwrap = {
  backgroundColor: colors.white,
  border: `solid 1px ${colors.grayLight}`,
  borderRadius: 4,
  height: 256,
  padding: 32,
  paddingRight: 26,
  transition: 'all .2s',
  '&:hover': { border: `1px solid ${colors.blue}` },
};

const listView = {
  height: 50,
  display: 'grid',
  padding: 16,
  height: 58,
  gridTemplateColumns: '340px 430px 240px',
  gridColumnGap: 24,
  alignItems: 'center',
};

const circleList = {
  marginTop: 0,
  span: {
    display: 'none',
  },
  '& .list-circle': {
    backgroundColor: '#fff',
    height: 'auto',
  },
};

const circle = {
  alignItems: 'center',
  backgroundColor: colors.gray,
  borderRadius: '50%',
  color: colors.black,
  display: 'inline-flex',
  fontSize: 16,
  fontWeight: 'bold',
  height: 56,
  justifyContent: 'center',
  width: 56,
};

const circled = {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  span: { fontSize: 9, width: 56, marginBottom: 4 },
};

export default function DocumentIndexTile({
  clientName,
  lastImportDate,
  notFoundsCount,
  unsortedCount,
  foundsCount,
  totalSuggestions,
  isList,
}) {
  return (
    <div css={[cardwrap, isList && listView]}>
      <span css={{ fontSize: 14, fontWeight: 'bold' }}>{clientName}</span>
      <div className="df jcsb mt4" css={isList && circleList}>
        <div css={circled}>
          <span>Not Founds</span>
          <div css={[circle, { backgroundColor: colors.gray }]} className="list-circle">
            {notFoundsCount}
          </div>
        </div>
        <div css={circled}>
          <span>Founds</span>
          <div css={[circle, { backgroundColor: colors.grayLight }]} className="list-circle">
            {foundsCount}
          </div>
        </div>
        <div css={circled}>
          <span>Unsorted</span>
          <div css={[circle, { backgroundColor: colors.grayLight }]} className="list-circle">
            {unsortedCount}
          </div>
        </div>
        <div css={circled}>
          <span>Suggestions</span>
          <div css={[circle, { backgroundColor: colors.grayLight }]} className="list-circle">
            {totalSuggestions}
          </div>
        </div>
      </div>
      <div
        css={[
          { display: 'inline-flex', alignItems: 'center', marginTop: 40 },
          isList && { marginTop: 0, paddingLeft: 60 },
        ]}
      >
        <CalendarIcon css={{ color: colors.blue }} />
        <div css={{ paddingLeft: 16 }}>
          {lastImportDate
            ? format(new Date(Date.parse(lastImportDate)), 'MMMM d, yyyy')
            : 'Unknown'}
        </div>
      </div>
    </div>
  );
}
