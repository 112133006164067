import React, { useEffect, useMemo, useState } from 'react';
import { getDocTypes } from './Utils';
import { DocumentType } from './globalTypes/objects';

export type DocumentTypeProperties = {
  label: string;
  value: DocumentType;
};

export const DocumentTypesContext = React.createContext<{ docTypes: DocumentTypeProperties[] }>({
  docTypes: [],
});

function DocumentTypesProvider({ children }) {
  const [docTypes, setDocTypes] = useState<DocumentTypeProperties[]>([]);
  const docTypesProp = useMemo(
    () => ({
      docTypes,
    }),
    [docTypes],
  );

  useEffect(() => {
    getDocTypes().then(setDocTypes);
  }, []);

  return docTypes.length ? (
    <DocumentTypesContext.Provider value={docTypesProp}>{children}</DocumentTypesContext.Provider>
  ) : null;
}
const DocumentTypesConsumer = DocumentTypesContext.Consumer;

export { DocumentTypesProvider, DocumentTypesConsumer };
