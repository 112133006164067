/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';

type RowData = {
  titleCompany: string;
  count: number;
};

const columns = [
  {
    field: 'titleCompany',
    title: 'Title Company',
  },
  {
    field: 'count',
    title: 'Pending Association',
  },
];

const TitleCompaniesPendingAssociation = () => (
  <DataTable<RowData>
    title="Title Companies Pending Association"
    columns={columns}
    url="/api/associations/title-companies-pending-association-by-name"
  />
);

export default TitleCompaniesPendingAssociation;
