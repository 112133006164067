/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Investor } from '../../../globalTypes/objects';
import Modal from '../../ui/Modal';
import FileDropper from '../../ui/FileDropper';
import { apiPost } from '../../../adalConfig';
import Toggle from '../../ui/Toggle';
import TextInput from '../../ui/Inputs/TextInput';
import { convertToBase64 } from '../../../Utils';
import colors from '../../../styles/colors';

export default function InvestorCoverletterModal({
  investor,
  handleClose,
}: {
  investor: Investor;
  handleClose: () => any;
}) {
  const [file, setFile] = useState<File>();
  const [isMultipleType, setIsMultipleType] = useState(false);
  const [multipleMaximum, setMultipleMaximum] = useState<number>();

  const onMaximumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value.replace(/\D/g, '');
    setMultipleMaximum(value);
  };

  const saveCoverletter = async () => {
    investor.coverletterBasePath = `${investor.name}.pdf`;
    investor.manifestMaximum = multipleMaximum;
    const convertedFile = await convertToBase64(file as Blob);
    await apiPost('/api/investors/updateCoverletter', {
      investor,
      file: convertedFile,
    });
    handleClose();
  };

  return (
    <Modal
      headerText="Add/Edit Coverletter"
      handleCancel={handleClose}
      handleSave={saveCoverletter}
      saveDisabled={!file}
      styles={{ height: 780, marginBottom: 100, width: 700 }}
    >
      {/* <Link
        to="/admin/coverletterGuidelines"
        target="_blank"
        css={{ marginTop: 10, color: colors.blue, textDecoration: 'underline' }}
      >
        Coverletter guidelines
      </Link> */}
      <div css={{ marginTop: 20, marginBottom: 10 }}>
        <Toggle
          text="Multiple coverletter"
          active={isMultipleType}
          handleToggle={() => setIsMultipleType(!isMultipleType)}
        />
        {isMultipleType && (
          <TextInput
            label="Maximum number of documents on coverletter"
            value={multipleMaximum}
            onChange={onMaximumChange}
          />
        )}
      </div>
      <FileDropper
        onChange={f => {
          if (f) setFile(f);
        }}
        acceptableFileTypes=".pdf"
        file={file}
        css={{ marginBottom: 30 }}
      />
    </Modal>
  );
}
