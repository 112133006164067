/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { TitleUploadsNotPreAssigned as RowData } from './types';

type Props = {
    titleUploadsNotPreAssigned: RowData[];
    fromDate: Date;
};

const TitleUploadsNotPreAssigned = ({ titleUploadsNotPreAssigned, fromDate }: Props) => {
    const columns = [
        {
            field: 'email',
            title: 'Title Email Address',
        },
        {
            field: 'count',
            title: 'Documents Uploaded',
        }
    ];

    return (
        <Fragment>
            {titleUploadsNotPreAssigned && (
                <DataTable<RowData>
                    title={`Title Uploads Not On Title Portal From  ${fromDate.toLocaleDateString()}`}
                    columns={columns}
                    data={titleUploadsNotPreAssigned}
                    options={{
                        selection: false,
                        search: false,
                        exportFileName: `Title Uploads Not On Title Portal From   ${fromDate.toLocaleDateString()}`,
                    }}
                />
            )}
        </Fragment>
    );
};

export default TitleUploadsNotPreAssigned;
