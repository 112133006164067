/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component, Fragment } from 'react';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import EditTitleCo from './EditTitleCo';
import AddNewContactWrapper from '../Utilities/Forms/AddNewContactWrapper';
import Panel, { PanelHeader } from '../ui/Panel';
import StatusButton, { StatusTypes } from '../ui/StatusButton';
import SearchSelect from '../ui/SearchSelect';
import BackButton from './BackButton';
import { apiFetch } from '../../adalConfig';
import { IconType } from '../ui/Icon';
import { ContactInformation, ContactType } from '../../globalTypes/objects';

const searchTitleCompanies = async partialString => {
  const { data } = await apiFetch(
    `/api/titleCompanies/searchTitleCompanies?searchString=${partialString}`,
  );
  return data.map(titleCompany => {
    const titleCompanyName = titleCompany.name;
    const titleCompanyAddress = titleCompany.address;
    return {
      label: titleCompanyName,
      subLabel: titleCompanyAddress,
      value: titleCompany.id,
    };
  });
};
const DEBOUNCE_RATE = 800;

export default class TitleCompanies extends Component {
  state = { editContact: {} as ContactInformation };

  loadTitleCompanyOptions = debounce((input, callback) => {
    searchTitleCompanies(input).then(callback);
  }, DEBOUNCE_RATE);

  render() {
    const { editContact } = this.state;
    return (
      <Fragment>
        <div>
          <BackButton to="/admin">Admin Page</BackButton>
        </div>
        <Panel styles={{ margin: '40px', marginTop: 24 }}>
          <PanelHeader text="Manage Title Companies" />
          <AddNewContactWrapper
            contactInformation={{ type: ContactType.TitleCompany }}
            onSave={() => {}}
          >
            <StatusButton
              initialIcon={IconType.Plus}
              status={StatusTypes.initial}
              text="Add New Title Company "
              styleOverrides={{ width: 250, marginTop: 32 }}
              onClick={() => {}}
            />
          </AddNewContactWrapper>
          <div css={{ marginTop: 32, width: 412 }}>
            <label>Edit Title Company</label>
            <div css={{ marginTop: 8 }}>
              <SearchSelect
                placeholder="Search Title Companies"
                loadOptions={this.loadTitleCompanyOptions}
                label="Title company name"
                filterConfig={{ matchFrom: 'all' }}
                onChange={async edited => {
                  const { data: titleCo } = await apiFetch(
                    `/api/titlecompanies/get?id=${edited.value}`,
                  );
                  this.setState({ editContact: titleCo });
                }}
                isAsync
              />
            </div>
          </div>
        </Panel>
        {!isEmpty(editContact) && (
          <EditTitleCo
            contactInformation={editContact}
            onCancelContact={() => this.setState({ editContact: {} })}
            onSaveContact={() => this.setState({ editContact: {} })}
          />
        )}
      </Fragment>
    );
  }
}
