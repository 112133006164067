import React, { Component } from 'react';

import AddNewContactForm from './AddNewContactForm';
import { errorType } from '../../Imports/ImportErrors/ImportErrorsUtils';
import { apiPost } from '../../../adalConfig';
import { ContactInformation } from '../../../globalTypes/objects';

const createNewContact = async (contactInformation: ContactInformation) => {
  const url =
    contactInformation.type === errorType.title
      ? '/api/titleCompanies/insertTitleCompany'
      : '/api/investors/insertInvestor';
  const { data } = await apiPost(url, contactInformation);
  return data;
};

const concatAddress = info =>
  (info.address ? info.address : '') +
  (info.city ? `, ${info.city}` : '') +
  (info.state ? `, ${info.state}` : '') +
  (info.zip ? ` ${info.zip}` : '');

type AddNewContactWrapperState = {
  showForm: boolean;
};
type AddNewContactWrapperProps = {
  onSave: (contact: SelectOption<string>) => void;
  contactInformation: Partial<ContactInformation>;
};

export default class AddNewContactWrapper extends Component<
  AddNewContactWrapperProps,
  AddNewContactWrapperState
> {
  state = { showForm: false };

  onSaveContact = async (contactInformation: ContactInformation) => {
    this.setState({ showForm: false });
    const info =
      contactInformation.type === errorType.title
        ? {
            ...contactInformation,
            address: concatAddress(contactInformation),
          }
        : contactInformation;
    const id = await createNewContact(info);
    const newContact = {
      value: id,
      label: info.name,
      subLabel: concatAddress(info),
    };
    this.props.onSave(newContact);
  };

  render() {
    const { showForm } = this.state;
    const { contactInformation, children } = this.props;
    return (
      <>
        <div onClick={() => this.setState({ showForm: true })}>{children}</div>
        {showForm && (
          <AddNewContactForm
            contactInformation={contactInformation}
            onCancelContact={() => this.setState({ showForm: false })}
            onSaveContact={info => this.onSaveContact(info)}
          />
        )}
      </>
    );
  }
}
