/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import axios from 'axios';
import { Client, ErrorInformation, RowData } from './types';
import Modal from '../ui/Modal';
import { AuthContext } from '../AuthContext';
import { useToaster } from '../../Hooks/toasters';

type Props = {
  logLoanEvent: (urlPath: string, note?: string | null, loanIds?: number[]) => Promise<number>;
  getDatatableData: (id?: number) => Promise<void>;
  datatableData: RowData[];
  selectedClient: Client;
  isClientMode: boolean;
  setDatatableErrors: (errorInformation: ErrorInformation[]) => void;
  datatableErrors: ErrorInformation[];
  loansRemaining: number;
  clearDatatable: () => void;
  setIsModalOpen: (value: boolean) => void;
  isModalOpen: boolean;
};

const ConfirmationModal = ({
  logLoanEvent,
  getDatatableData,
  datatableData,
  selectedClient,
  isClientMode,
  setDatatableErrors,
  datatableErrors,
  loansRemaining,
  clearDatatable,
  setIsModalOpen,
  isModalOpen,
}: Props) => {
  const [isLoadingResolveModal, setIsLoadingResolveModal] = useState(false);

  const { user } = useContext(AuthContext);

  const { successToaster, errorToaster } = useToaster();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      report: {
        margin: '10px',
        maxHeight: '300px',
        overflow: 'auto',
        '& table, td, th': {
          border: '1px solid #ddd',
          textAlign: 'left',
        },
        '& table': {
          borderCollapse: 'collapse',
          width: '100%',
        },

        '& th, td': {
          padding: '4px',
          textAlign: 'left',
          borderBottom: '1px solid #ddd',
        },

        '& tr:hover': {
          backgroundColor: '#f5f5f5',
        },
      },
    }),
  );
  const classes = useStyles();

  const postErrorMessage = async e => {
    let errorMessage = e.message;
    if (e.response) {
      errorMessage = e.response.data.split('\n')[0] || e.message;
    }

    errorToaster(errorMessage);
    await axios.post(
      'https://hooks.slack.com/services/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',
      JSON.stringify({
        text: `*${location.pathname}${location.search}*
        The mail message was: *${JSON.stringify(errorMessage)}*
        The user that experienced this error was: *${user?.mail}*
        Error Message: *${e.message}*`,
      }),
    );
  };

  const resolve = async () => {
    setIsLoadingResolveModal(true);

    const resolvedLoans = datatableData.filter(row => row.isResolved).map(row => row.id);
    await logLoanEvent('resolve', null, resolvedLoans);
    if (isClientMode) {
      try {
        await getDatatableData(selectedClient.id);
      } catch (e) {
        await postErrorMessage(e);
      }
    } else {
      clearDatatable();
    }

    setIsModalOpen(false);

    successToaster('Successfully resolved the selected loans.');

    setIsLoadingResolveModal(false);
  };

  return (
    <Fragment>
      <Dialog
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-dialog-title"
        open={isModalOpen}
      >
        <DialogTitle id="simple-dialog-title">Confirmation</DialogTitle>
        {isLoadingResolveModal ? (
          <div className="center-in-parent" style={{ height: '200px', width: '300px' }}>
            <CircularProgress size="40" disableShrink />
          </div>
        ) : (
          <Fragment>
            <DialogContent>
              <DialogContentText>
                You have {loansRemaining} unresolved loans. <br />
                Please confirm that you sent all your emails. <br />
                Click <i>Resolve</i> to continue.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsModalOpen(false)} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button onClick={resolve} color="primary" variant="contained">
                Resolve
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>

      {!!datatableErrors.length && (
        <Modal
          headerText="Errors"
          handleCancel={() => setIsModalOpen(false)}
          styles={{ width: '800px' }}
          customFooter={
            <Button variant="contained" color="primary" onClick={() => setDatatableErrors([])}>
              Close
            </Button>
          }
        >
          <div className={classes.report}>
            <table>
              <caption>Reason for empty results</caption>
              <thead>
                <tr>
                  <th>Title Company</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {datatableErrors.map(errorInformation => (
                  <tr key={errorInformation.titleCompanyId}>
                    <td>{errorInformation.titleCompany}</td>
                    <td>{errorInformation.error}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default ConfirmationModal;
