/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { Fragment } from 'react';
import Header from '../../ui/Header';
import Icon, { IconType } from '../../ui/Icon';
import { clientNameWrap } from './clientDocumentIndex';

export default function DocumentHeader({ client }: { client?: string }) {
  return (
    <div>
      <Header headerText="Documents" />
      <div css={{ maxWidth: 1360 }}>
        <div css={clientNameWrap}>
          <Icon icon={IconType.Client} />
          <div>{client}</div>
        </div>
      </div>
    </div>
  );
}
