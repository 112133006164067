/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, SetStateAction } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DataTable from '../../../ui/DataTable/DataTable';
import Modal from '@mui/material/Modal';
import { Option } from '../../../ui/Downshift/types';
import { ReportType } from './Report';

export type RowData = {
  client: string;
  reportType: string;
  count: number;
  total: number;
  invoiceId: number;
};

type Props = {
  datatableData: RowData[];
  setDatatableData: Dispatch<SetStateAction<RowData[]>>;
  reportType: ReportType;
  client?: Option;
  month: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      minHeight: '200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    },
  }),
);

const columns = (reportType: ReportType) => [
  {
    field: 'client',
    title: 'Client',
    hidden: reportType === ReportType.AllClients,
  },
  {
    field: 'reportType',
    title: 'Report Type',
  },
  {
    field: 'count',
    title: 'Count',
    render: (rowData: RowData) => rowData.count.toLocaleString(),
  },
  {
    field: 'total',
    title: 'Total Charge/Credit',
    render: (rowData: RowData) =>
      rowData.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  },
  {
    field: 'invoiceId',
    title: 'Invoice #',
    hidden: reportType === ReportType.AllClients,
  },
];

const reportTitle = (month: string, clientName: string) => {
  let title = `${month} Revenue Breakdown Report`;

  if (clientName) {
    title += ` - ${clientName}`;
  }

  return title;
};

const ReportModal = ({ datatableData, setDatatableData, reportType, client, month }: Props) => {
  const classes = useStyles();

  return (
    <Modal open={!!datatableData.length} onClose={() => setDatatableData([])}>
      <div className={classes.paper}>
        <DataTable<RowData>
          title={reportTitle(month, client?.label ?? '')}
          columns={columns(reportType)}
          data={datatableData}
          options={{
            exportFileName: reportTitle(month, client?.label ?? ''),
            search: false,
          }}
        />
      </div>
    </Modal>
  );
};

export default ReportModal;
