/** @jsx jsx */
import { jsx } from '@emotion/core';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import ShortId from 'shortid';

import { useMemo, useState } from 'react';
import Modal from '../ui/Modal';
import colors from '../../styles/colors';
import Toggle from '../ui/Toggle';
import { useClients } from '../../Hooks/useClients';

export type ShippingClient = {
  id: number;
  client: string;
  physicalAmount: number;
  digitalAmount: number;
  oldestDoc?: Date;
};

export type ModalClient = {
  client: ShippingClient;
  onClick: () => void;
  selected: ShippingClient;
};

export type ClientModal = {
  clients: ShippingClient[];
  show: boolean;
  selected: ShippingClient | {};
};

type ClientModalProps = {
  setClientModal: (clientModal: ClientModal) => void;
  clientModal: ClientModal;
  handleModalSave: Function;
};

const row = {
  display: 'grid',
  gridTemplateColumns: '2fr 1.5fr 1fr 1fr',
};

export const ClientModal = ({ setClientModal, clientModal, handleModalSave }: ClientModalProps) => {
  const [includeAllClients, setIncludeAllClients] = useState(false);
  const allClients = useClients().map<ShippingClient>(
    c =>
      clientModal.clients.find(x => x.id === c.id) ?? {
        id: c.id,
        client: c.company,
        physicalAmount: 0,
        digitalAmount: 0,
      },
  );
  const clients = useMemo(() => (includeAllClients ? allClients : clientModal.clients), [
    allClients,
    clientModal.clients,
    includeAllClients,
  ]);
  return (
    <Modal
      handleCancel={() => setClientModal({ ...clientModal, show: false })}
      handleSave={handleModalSave}
      headerText="Add client"
      saveDisabled={isEmpty(clientModal.selected)}
      styles={{ height: 570, marginBottom: 66 }}
    >
      <div css={{ marginTop: 16 }}>
        <Toggle
          active={includeAllClients}
          handleToggle={() => setIncludeAllClients(x => !x)}
          text="Include all clients"
        />
      </div>
      <div className="relative bold">
        <div className="mt2 mb1 df px3 absolute top index-30 full-width bg-white" css={row}>
          <div>Client</div>
          <div>Physicals</div>
          <div>Digitals</div>
          <div>Oldest Doc</div>
        </div>
      </div>
      <div css={{ overflow: 'auto', height: 290, marginTop: 48 }}>
        {clients
          .sort((a, b) => a.client.localeCompare(b.client))
          .map(c => (
            <ModalClient
              key={ShortId.generate()}
              client={c}
              onClick={() => setClientModal({ ...clientModal, selected: c })}
              selected={clientModal.selected as ShippingClient}
            />
          ))}
      </div>
    </Modal>
  );
};

const clientWrap = {
  height: 66,
  border: `1px solid ${colors.white}`,
  display: 'grid',
  gridTemplateColumns: '2fr 1.5fr 1fr 1fr',
  '&:hover': {
    border: `1px solid ${colors.blue}`,
  },
};

export const ModalClient = ({ client, onClick, selected }: ModalClient) => {
  return (
    <div
      className="df aic px3 jcsb radius pointer"
      css={[clientWrap, selected.id && selected.id === client.id && { border: '1px solid blue' }]}
      onClick={onClick}
    >
      <div>{client.client}</div>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <span>{client.physicalAmount}</span>
      </div>
      <div css={{ display: 'flex', justifyContent: 'center' }}>
        <span>{client.digitalAmount}</span>
      </div>
      <span className="tar">
        {client.oldestDoc ? format(new Date(Date.parse(client.oldestDoc)), 'MM/dd/yyyy') : ''}
      </span>
    </div>
  );
};
