import React, { Component } from 'react';
import { apiFetch } from '../../../adalConfig';

interface SidebarContext {
  associationErrorCount: number | undefined;
  loanErrorCount: number | undefined;
  updateLoanErrorCount: () => Promise<void>;
  updateAssociationErrorCount: () => Promise<void>;
  updateErrorCount: () => Promise<void>;
}

const SidebarContext = React.createContext<SidebarContext>({} as SidebarContext);

interface SidebarProviderState {
  associationErrorCount: number | undefined;
  loanErrorCount: number | undefined;
}

class SidebarProvider extends Component<{}, SidebarProviderState> {
  state = {
    associationErrorCount: undefined,
    loanErrorCount: undefined,
  };

  updateAssociationErrorCount = async () => {
    const { data } = await apiFetch('/api/associations/getassociationerrorcount', {});
    await this.setState({ associationErrorCount: data });
  };

  updateLoanErrorCount = async () => {
    const { data } = await apiFetch('/api/loanErrors/getLoanErrorCount', {});
    await this.setState({ loanErrorCount: data });
  };

  updateErrorCount = async () => {
    await this.updateAssociationErrorCount();
    await this.updateLoanErrorCount();
  };

  componentDidMount = async () => {
    await this.updateErrorCount();
  };

  render() {
    const { associationErrorCount, loanErrorCount } = this.state;
    const { children } = this.props;
    return (
      <SidebarContext.Provider
        value={{
          updateAssociationErrorCount: this.updateAssociationErrorCount,
          updateLoanErrorCount: this.updateLoanErrorCount,
          updateErrorCount: this.updateErrorCount,
          associationErrorCount,
          loanErrorCount,
        }}
      >
        {children}
      </SidebarContext.Provider>
    );
  }
}

const SidebarConsumer = SidebarContext.Consumer;

export { SidebarProvider, SidebarConsumer };
