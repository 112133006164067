/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';

import { Component, Fragment } from 'react';

import Panel, { PanelHeader } from '../../ui/Panel';
import Button from '../../ui/Button';
import TextInput from '../../ui/Inputs/TextInput';
import { errorType } from '../../Imports/ImportErrors/ImportErrorsUtils';
import Dropdown from '../../ui/Dropdown';
import zIndex from '../../../styles/zIndex';
import PhoneInput from '../../ui/Inputs/PhoneInput';
import states from '../../../states';
import ZipInput from '../../ui/Inputs/ZipInput';
import { ValidateEmail } from '../../../Utils';
import { ContactInformation, ContactType, Investor } from '../../../globalTypes/objects';
import colors from '../../../styles/colors';
import InvestorCoverletterModal from './InvestorCoverletterModal';
import Checkbox from '../../ui/Checkbox';

// #region css
const screen: CSSObject = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  zIndex: zIndex.modal,
  display: 'flex',
  justifyContent: 'center',
};

const inputSmall: CSSObject = { width: 229 };

const labelStyle: CSSObject = { marginTop: 22 };

const secondAddressLabelStyle: CSSObject = { marginTop: 15 };

const width = { width: 229 };

const dropDownStyle: CSSObject = {
  display: 'block',
  marginTop: 0,
};

const inputsGridSection: CSSObject = {
  display: 'flex',
  justifyContent: 'space-between',
};

const left: CSSObject = { width: 230, verticalAlign: 'top' };

const right: CSSObject = { width: 230, verticalAlign: 'top' };

const buttonPosition: CSSObject = {
  marginTop: 33,
  display: 'flex',
  justifyContent: 'flex-end',
};

const littleIconStyle: CSSObject = {
  color: colors.gray,
  cursor: 'pointer',
  paddingTop: 6,
  marginRight: 6,
  stroke: colors.gray,
  '&:hover': { stroke: colors.blue, color: colors.blue },
};
// #endregion

const MAX_NAME_TITLE = 250;
const MAX_NAME_INVESTOR = 100;
const MAX_ADDRESS_INVESTOR = 100;
const MAX_CITY_INVESTOR = 50;
const MAX_EMAIL = 50;
const MAX_ADDITIONAL_EMAIL = 255;

export type ContactState = {
  contactInformation: Partial<ContactInformation>;
  nameError: boolean;
  addressError: boolean;
  phoneError: boolean;
  faxError: boolean;
  emailError: boolean;
  cityError: boolean;
  zipError: boolean;
  additionalEmailError: boolean;
  showCoverletterModal: boolean;
  orignalContactInformation?: Partial<ContactInformation>;
};

export type StateErrors = Omit<ContactState, 'contactInformation'>;

type AddNewContactFormProps = {
  contactInformation: Partial<ContactInformation>;
  onSaveContact: (contact: ContactInformation) => void;
  onCancelContact: () => void;
};
export default class AddNewContactForm extends Component<AddNewContactFormProps, ContactState> {
  state = {
    contactInformation: {} as ContactInformation,
    nameError: false,
    addressError: false,
    cityError: false,
    zipError: false,
    phoneError: false,
    faxError: false,
    emailError: false,
    additionalEmailError: false,
    showCoverletterModal: false,
  };

  componentDidMount() {
    const { contactInformation } = this.props;

    const match = contactInformation.state
      ? Object.keys(states).find(k => k === contactInformation.state!.toUpperCase())
      : undefined;
    this.setState({
      contactInformation: {
        ...contactInformation,
        firstAddress: contactInformation.address,
        state: match || contactInformation.state,
        zip: contactInformation.zip && contactInformation.zip.substring(0, 10),
      },
    });
  }

  onChange = (value, field, value2 = '', field2 = '') => {
    const { contactInformation } = this.state;
    this.setState({
      contactInformation: { ...contactInformation, [field]: value, [field2]: value2 },
    });
  };

  changeErrorValue = (value, field: keyof ContactState) => {
    this.setState({
      [field]: value,
    } as Pick<ContactState, keyof ContactState>);
  };

  onSaveClick = async (
    exceededName,
    exceededAddress,
    exceededCity,
    exceededEmail,
    exceededAdditionalEmail,
  ) => {
    await this.checkValidation(
      exceededName,
      exceededAddress,
      exceededCity,
      exceededEmail,
      exceededAdditionalEmail,
    );
    const {
      nameError,
      addressError,
      cityError,
      zipError,
      phoneError,
      faxError,
      emailError,
      additionalEmailError,
    } = this.state;
    if (
      nameError ||
      addressError ||
      cityError ||
      zipError ||
      phoneError ||
      faxError ||
      emailError ||
      additionalEmailError
    ) {
      return;
    }
    const { contactInformation } = this.state;
    const { firstAddress, secondAddress } = contactInformation;
    const { onSaveContact } = this.props;
    await this.setState({
      contactInformation: {
        ...contactInformation,
        address: (firstAddress || '') + (secondAddress ? ` ${secondAddress}` : ''),
      },
    });
    await onSaveContact(this.state.contactInformation);
  };

  checkValidation = (
    exceededName,
    exceededAddress,
    exceededCity,
    exceededEmail,
    exceededAdditionalEmail,
  ) => {
    const { contactInformation } = this.state;
    const { name, firstAddress, zip, phone, fax, email, additionalEmail } = contactInformation;
    this.changeErrorValue(!name || exceededName, 'nameError');
    this.changeErrorValue(
      (!firstAddress && contactInformation.type === errorType.title) || exceededAddress,
      'addressError',
    );
    this.changeErrorValue(exceededCity, 'cityError');
    this.changeErrorValue(zip && !(zip.length === 5 || zip.length === 10), 'zipError');
    this.changeErrorValue(phone && phone.length !== 10, 'phoneError');
    this.changeErrorValue(
      (fax && fax.length !== 10) || (contactInformation.type === errorType.title && !fax && !email),
      'faxError',
    );
    this.changeErrorValue(
      (email && !ValidateEmail(email)) ||
        exceededEmail ||
        (contactInformation.type === errorType.title && !email && !fax),
      'emailError',
    );
    this.changeErrorValue(
      additionalEmail && (exceededAdditionalEmail || !ValidateEmail(additionalEmail)),
      'additionalEmailError',
    );
  };

  getRidOfError = (field: keyof StateErrors) => {
    this.setState({ [field]: false } as Pick<StateErrors, keyof StateErrors>);
  };

  beforeMaskedValueChange = (newState, oldState, userInput, mask, field) => {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    if (
      (value.endsWith('-') &&
        userInput !== '-' &&
        !this.state.contactInformation[field].endsWith('-')) ||
      (value.endsWith('(') && userInput !== '(' && !this.state.contactInformation[field])
    ) {
      if (cursorPosition === value.length) {
        cursorPosition -= 1;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  render() {
    const {
      contactInformation,
      nameError,
      addressError,
      cityError,
      zipError,
      phoneError,
      faxError,
      emailError,
      additionalEmailError,
      showCoverletterModal,
    } = this.state;
    const {
      name,
      firstAddress,
      secondAddress,
      city,
      state,
      zip,
      phone,
      fax,
      email,
      requiresOriginalDoc,
      sendToGlobal,
      additionalEmail,
      type,
    } = contactInformation;
    const maxName =
      contactInformation.type === errorType.investor ? MAX_NAME_INVESTOR : MAX_NAME_TITLE;
    const maxAddress =
      contactInformation.type === errorType.investor ? MAX_ADDRESS_INVESTOR : Infinity;
    const maxCity = contactInformation.type === errorType.investor ? MAX_CITY_INVESTOR : Infinity;
    const address = firstAddress && firstAddress + secondAddress && secondAddress;
    const { onCancelContact } = this.props;
    const exceededName = name && name.length > maxName;
    const exceededAddress = address && address.length > maxAddress;
    const exceededCity = city && city.length > maxCity;
    const exceededEmail = email && email.length > MAX_EMAIL;
    const exceededAdditionalEmail =
      additionalEmail && additionalEmail.length > MAX_ADDITIONAL_EMAIL;
    return (
      <Fragment>
        {!showCoverletterModal && (
          <div css={screen}>
            <Panel styles={{ margin: '40px 0', overflowY: 'scroll' }}>
              <PanelHeader largeText text="Add new contact" />
              {/* currently only allowing to add a coverletter once investor already exists
               (i.e. on edit, not create) because it was easier and quicker to code this way.
               May need to be changed to support users' needs. */}
              {contactInformation.type === ContactType.Investor && contactInformation.id && (
                <div css={{ marginTop: 20 }}>
                  <div css={{ marginRight: 6, float: 'left' }}>
                    Coverletter: &nbsp;
                    {((contactInformation as unknown) as Investor).coverletterBasePath || 'N/A'}
                  </div>
                  <Button
                    secondary
                    styleOverrides={{ height: 30, minWidth: 80 }}
                    onClick={() => this.setState({ showCoverletterModal: true })}
                  >
                    {((contactInformation as unknown) as Investor).coverletterBasePath
                      ? 'Edit'
                      : 'Add'}
                  </Button>
                </div>
              )}
              <TextInput
                labelOverrides={labelStyle}
                label={
                  contactInformation.type === errorType.title
                    ? 'Title company name'
                    : 'Investor/Servicer name'
                }
                value={name || ''}
                onChange={e => this.onChange(e.target.value, 'name')}
                error={
                  nameError &&
                  (exceededName ? `Name cannot exceed ${maxName} characters` : 'Required')
                }
                onFocus={() => this.getRidOfError('nameError')}
              />
              <TextInput
                labelOverrides={labelStyle}
                label="Street"
                value={firstAddress || ''}
                onChange={e => this.onChange(e.target.value, 'firstAddress')}
                error={
                  addressError &&
                  (exceededAddress ? `Address cannot exceed ${maxAddress} characters` : 'Required')
                }
                onFocus={() => this.getRidOfError('addressError')}
              />
              <TextInput
                labelOverrides={secondAddressLabelStyle}
                placeholder="Attention, suite, unit, building, floor, etc."
                value={secondAddress || ''}
                onChange={e => this.onChange(e.target.value, 'secondAddress')}
                error={addressError && exceededAddress}
                {...(exceededAddress && { onFocus: () => this.getRidOfError('addressError') })}
              />
              <TextInput
                labelOverrides={labelStyle}
                label="City"
                value={city || ''}
                onChange={e => this.onChange(e.target.value, 'city')}
                error={cityError && `City cannot exceed ${maxCity} characters`}
              />
              <div css={inputsGridSection}>
                <div css={left}>
                  <div css={[labelStyle, { marginBottom: 8 }]}>State</div>
                  <Dropdown
                    widthOverride={width}
                    styleOverrides={dropDownStyle}
                    options={Object.entries(states).map(([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                    {...(state && {
                      value: { label: states[state], value: state },
                    })}
                    placeholder="Select State"
                    onChange={selection => this.onChange(selection.value, 'state')}
                  />
                  <PhoneInput
                    labelOverrides={labelStyle}
                    styleOverrides={inputSmall}
                    label="Phone"
                    value={phone || ''}
                    onChange={value => this.onChange(value, 'phone')}
                    error={phoneError && 'Invalid phone'}
                    onClick={() => this.getRidOfError('phoneError')}
                    onKeyUp={e => (e.which === 9 ? this.getRidOfError('phoneError') : null)}
                  />
                </div>
                <div css={right}>
                  <ZipInput
                    labelOverrides={labelStyle}
                    styleOverrides={inputSmall}
                    label="Zip"
                    value={zip || ''}
                    onChange={e => this.onChange(e.target.value, 'zip')}
                    error={zipError && 'Invalid zip'}
                    onClick={() => this.getRidOfError('zipError')}
                    onKeyUp={e => (e.which === 9 ? this.getRidOfError('zipError') : null)}
                  />
                  <PhoneInput
                    labelOverrides={labelStyle}
                    styleOverrides={inputSmall}
                    label="Fax"
                    value={fax || ''}
                    onChange={value => this.onChange(value, 'fax')}
                    error={faxError && ((!email && !fax && 'Contact required') || 'Invalid fax')}
                    onClick={() => {
                      this.getRidOfError('faxError');
                      !fax && !email && this.getRidOfError('emailError');
                    }}
                    onKeyUp={e =>
                      e.which === 9 &&
                      (this.getRidOfError('faxError'),
                      !fax && !email && this.getRidOfError('emailError'))
                    }
                  />
                </div>
              </div>
              <TextInput
                labelOverrides={labelStyle}
                label="Email"
                value={email || ''}
                onChange={e =>
                  this.setState({
                    contactInformation: {
                      ...contactInformation,
                      email: e.target.value,
                      sendToGlobal: !e.target.value ? false : sendToGlobal,
                    },
                  })
                }
                type="email"
                error={
                  emailError &&
                  ((!email && !fax && 'Contact required') ||
                    (exceededEmail
                      ? `Email cannot exceed ${MAX_EMAIL} characters`
                      : 'Invalid email'))
                }
                onFocus={() => {
                  this.getRidOfError('emailError');
                  !fax && !email && this.getRidOfError('faxError');
                }}
              />

              {type === ContactType.Investor && (
                <div css={{ marginTop: 8 }}>
                  <Checkbox
                    checked={requiresOriginalDoc}
                    text="Requires Original Document"
                    labelOverrides={{ whiteSpace: 'nowrap' }}
                    onClick={() => this.onChange(!requiresOriginalDoc, 'requiresOriginalDoc')}
                  />
                </div>
              )}

              {type === ContactType.TitleCompany && email && (
                <div css={{ marginTop: 8 }}>
                  <Checkbox
                    checked={sendToGlobal}
                    text="Send to global"
                    labelOverrides={{ whiteSpace: 'nowrap' }}
                    onClick={() =>
                      this.onChange(!sendToGlobal, 'sendToGlobal', '', 'additionalEmail')
                    }
                  />
                </div>
              )}

              {sendToGlobal && (
                <TextInput
                  labelOverrides={labelStyle}
                  label="Additional Email"
                  value={additionalEmail || ''}
                  onChange={e => this.onChange(e.target.value, 'additionalEmail')}
                  type="email"
                  error={
                    additionalEmailError &&
                    (exceededAdditionalEmail
                      ? `Email cannot exceed ${MAX_ADDITIONAL_EMAIL} characters`
                      : 'Invalid email')
                  }
                  onFocus={() => {
                    this.getRidOfError('additionalEmailError');
                  }}
                />
              )}

              <div css={buttonPosition}>
                <Button onClick={onCancelContact} secondary>
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    this.onSaveClick(exceededName, exceededAddress, exceededCity, exceededEmail)
                  }
                  styleOverrides={{ marginLeft: 16 }}
                >
                  Save
                </Button>
              </div>
            </Panel>
          </div>
        )}
        {showCoverletterModal && (
          <InvestorCoverletterModal
            investor={(contactInformation as unknown) as Investor}
            handleClose={() => this.setState({ showCoverletterModal: false })}
          />
        )}
      </Fragment>
    );
  }
}
