/** @jsx jsx */
import { jsx, CSSObject } from '@emotion/core';
import React, { Component, Children } from 'react';

export class RadioGroup extends Component {
  state = {
    checked: 0,
  };

  children = this.props.children;

  validateChildren = () => {
    if (Children.count(this.children) < 2) {
      throw new Error(`RadioGroup: Requires 2 or more children`);
    }
  };

  render() {
    this.validateChildren();
    const { handleChange, styles } = this.props;
    return (
      <div css={[styles]}>
        {Children.map(this.children, (child, idx) =>
          React.cloneElement(child, {
            idx,
            handleChange: idx => {
              this.setState({ checked: idx });
              handleChange(child!.props.value || child.props.text);
            },
            checked: idx === this.state.checked,
          }),
        )}
      </div>
    );
  }
}

const radioLine: CSSObject = {
  height: '25px',
  boxSizing: 'border-box',
  position: 'relative',
  marginTop: '16px',
};

const radioBorder: CSSObject = {
  display: 'inline-block',
  width: '24px',
  height: '24px',
  border: 'solid 1.4px #e5e5ea',
  borderRadius: '100px',
  boxSizing: 'border-box',
  textAlign: 'center',
  position: 'relative',
  marginRight: '15px',
  transition: 'border .2s',
  ':hover': {
    border: 'solid 1.4px #0828cc',
  },
};

const radioButton: CSSObject = {
  width: '15px',
  height: '15px',
  background: 'blue',
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const radioLabel: CSSObject = {
  display: 'inline-block',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: '14px',
  fontFamily: 'sans-serif',
};

export function Radio({ value, handleChange, checked, text, idx, styles }) {
  return (
    <div css={[radioLine, styles]} onClick={() => handleChange(idx)}>
      <div css={radioBorder}>{checked && <div css={radioButton} />}</div>
      <label css={radioLabel}>{text}</label>
    </div>
  );
}
