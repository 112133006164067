/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';
import { IfRejected } from 'react-async';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useFetchWithAuth } from '../adalConfig';
import FileDropper from '../components/ui/FileDropper';
import StatusButton from '../components/ui/StatusButton';
import { Client } from '../globalTypes/objects';
import { useClients } from '../Hooks/useClients';
import SearchSelect from '../components/ui/SearchSelect';
import Checkbox from '../components/ui/Checkbox';

const pageWrap = {
  padding: 40,
  '.MuiAlert-root': {
    fontSize: 14,
  },
};

type DownloadDocumentsState =
  | {
      status: 'NotChecked';
    }
  | { status: 'Checked'; excludeFailedDocuments: boolean };

export default function InvestorReport() {
  const [file, setFile] = useState<File>();
  const [client, setClient] = useState<Client>();
  const [downloadDocuments, setDownloadDocuments] = useState<DownloadDocumentsState>({
    status: 'NotChecked',
  });
  const clients = useClients();
  const submitReportReq = useFetchWithAuth<any>(
    `/Api/InvestorReports`,
    {
      method: 'POST',
    },
    {
      json: false,
      onResolve: async (response) => {
        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = file?.name!;
        document.body.appendChild(a);
        a.click();
        setFile(undefined);
        setDownloadDocuments({ status: 'NotChecked' });
      },
      onReject: async (err: Error & { response: Response }) => {
        const { response } = err;
        if (err.response.status === 400) {
          const message = (await response.text()).replace(/"/g, '');
          submitReportReq.setError(new Error(message));
          setFile(undefined);
        }
      },
    },
  );

  async function handleSubmit() {
    if (!file || !client) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('clientId', client.id.toString());
    formData.append('downloadDocuments', downloadDocuments.status === 'Checked' ? '1' : '0');
    if (downloadDocuments.status === "Checked") {
      formData.append('excludeFailedDocuments', downloadDocuments.excludeFailedDocuments ? '1' : '0');
    }
    submitReportReq.run({ body: formData });
  }

  return (
    <div css={pageWrap}>
      <div css={{ maxWidth: 1024, margin: 'auto' }}>
        <div style={{ marginTop: '2rem' }}>
          <React.Fragment>
            <SearchSelect
              options={clients.map((c) => ({ label: c.company, value: c.id }))}
              placeholder="Select a client"
              onChange={({ value: clientId }) =>
                setClient(clients.find(({ id }) => id === clientId))
              }
            />
          </React.Fragment>
        </div>
        <FileDropper
          onChange={(f) => setFile(f as File)}
          file={file}
          acceptableFileTypes=".csv, .xls, .xlsx"
          buttonDisabled={false}
          // @ts-ignore
          styles={{
            backgroundColor: '#fff',
            marginBottom: 32,
            padding: 40,
            height: '100%',
            maxHeight: 500,
          }}
        />

        <IfRejected
          // @ts-ignore
          state={submitReportReq}
        >
          {(err) => (
            <Snackbar open>
              <Alert severity="error" variant="filled">
                {err.message}
              </Alert>
            </Snackbar>
          )}
        </IfRejected>

        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div
            css={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >
            <div css={{ width: '33%' }}>
              <div
                css={{ cursor: 'pointer' }}
                onClick={() =>
                  setDownloadDocuments(({ status }) =>
                    status === 'NotChecked'
                      ? { status: 'Checked', excludeFailedDocuments: false }
                      : { status: 'NotChecked' },
                  )
                }
              >
                <div css={{ alignSelf: 'center', display: 'flex' }}>
                  <Checkbox checked={downloadDocuments.status === 'Checked'} />
                  <span css={{ fontSize: 18, marginLeft: '1em' }}>Request documents</span>
                </div>
              </div>
              {downloadDocuments.status === 'Checked' && (
                <div
                  css={{ cursor: 'pointer', marginTop: '1em' }}
                  onClick={() =>
                    setDownloadDocuments((current) =>
                      current.status === 'Checked'
                        ? { ...current, excludeFailedDocuments: !current.excludeFailedDocuments }
                        : current,
                    )
                  }
                >
                  <div css={{ alignSelf: 'center', display: 'flex' }}>
                    <Checkbox checked={downloadDocuments.excludeFailedDocuments} />
                    <span css={{ fontSize: 18, marginLeft: '1em' }}>Exclude Failed Documents</span>
                  </div>
                </div>
              )}
            </div>

            {downloadDocuments.status === 'Checked' && (
              <div>
                <div css={{ fontSize: 16 }}>
                  Look out for an email with a link to retrieve requested documents.
                </div>
              </div>
            )}
            <div css={{ marginTop: '1.5em' }}>
              <StatusButton
                onClick={() => handleSubmit()}
                text="Submit"
                disabled={submitReportReq.isLoading || !client || !file}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
