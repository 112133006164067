/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import FullDisplay from './FullDisplay';
import SortResponse from './SortResponse';
import { SortResponse as SortResponseType } from '../SortDocumentPanel';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: 'solid 1px #e6e4e8',
      borderTop: 'none',
      borderRadius: '0 0 4px 4px',
      overflow: 'hidden',
    },
    alert: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  }),
);

type Props = {
  clientName: string;
  sortResponse: SortResponseType;
  isProcessingSort: boolean;
};

export const isWrongClient = (selectedClient: string, clientFromResponse: string | undefined) => {
  if (clientFromResponse === undefined) {
    return false;
  }

  return selectedClient !== clientFromResponse;
};

const ResultPanel = ({ clientName, sortResponse, isProcessingSort }: Props) => {
  const classes = useStyles();

  if (Object.keys(sortResponse).length === 0) {
    return <Fragment />;
  }

  if (isProcessingSort) {
    return (
      <div className="center-in-parent" style={{ height: '150px' }}>
        <CircularProgress size="45" disableShrink />
      </div>
    );
  }

  return (
    <Fragment>
      {sortResponse.fullResult ? (
        <FullDisplay clientName={clientName} sortResponse={sortResponse} />
      ) : (
        <div className={classes.container}>
          <SortResponse
            alertType={sortResponse.alertType}
            primaryMessage={sortResponse.message}
            secondaryMessage={sortResponse.additionalInformation}
            isWrongClient={isWrongClient(clientName, sortResponse?.sortDocumentLocation?.client)}
          />
        </div>
      )}
      {!!sortResponse.compartmentLabel && (
        <div className={classes.alert}>
          <Alert severity="info">{sortResponse.compartmentLabel}</Alert>
        </div>
      )}
    </Fragment>
  );
};

export default ResultPanel;
