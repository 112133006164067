/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import DataTable from '../../components/ui/DataTable/DataTable';
import { roleTypes } from '../../constants';
import { AuthContext } from '../../components/AuthContext';
import { apiFetch, apiPost } from '../../adalConfig';
import { useToaster } from '../../Hooks/toasters';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import { green, red } from '@mui/material/colors';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    settings: {
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 1fr',
      width: '75%',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      maxWidth: '610px',
    },
  }),
);

const DigitalDeliverySettingsListing = () => {
  const [isLoadingDocumentTypeSettings, setIsLoadingDocumentTypeSettings] = useState(false);
  const [documentTypeDigitalDeliverySettings, setDocumentTypeDigitalDeliverySettings] = useState<
    DocumentTypeDigitalDeliverySetting[] | null
  >(null);
  const [refreshDatatable, setRefreshDatatable] = useState(() => () => {});
  const [settingToDelete, setSettingToDelete] = useState<RowData | undefined>(undefined);

  const classes = useStyles();

  const { roles } = useContext(AuthContext);

  const { errorToaster } = useToaster();

  const columns = [
    {
      field: 'id',
      title: 'Id',
      hidden: !roles.includes(roleTypes.Dev),
    },
    {
      field: 'lender',
      title: 'Lender',
      render: (rowData: RowData) => rowData.lender ?? '*',
    },
    {
      field: 'investor',
      title: 'Investor',
    },
    {
      field: 'shouldShipDigitally',
      title: 'Should Ship Digitally (Master Setting)',
      render: (rowData: RowData) =>
        rowData.shouldShipDigitally ? (
          <DoneIcon style={{ color: green[500] }} />
        ) : (
          <CloseIcon style={{ color: red[800] }} />
        ),
    },
    {
      field: 'allowGinnieMaeOverride',
      title: 'Allow Ginnie Mae Override',
      render: (rowData: RowData) =>
        rowData.allowGinnieMaeOverride ? (
          <DoneIcon style={{ color: green[500] }} />
        ) : (
          <CloseIcon style={{ color: red[800] }} />
        ),
    },
  ];

  const getDocumentTypeSettings = async (digitalDeliverySettingId: number) => {
    setIsLoadingDocumentTypeSettings(true);
    try {
      const { data } = await apiFetch<DocumentTypeDigitalDeliverySetting[]>(
        `/api/digital-delivery-settings/document-type-settings?digitalDeliverySettingId=${digitalDeliverySettingId}`,
      );
      setDocumentTypeDigitalDeliverySettings(data);
    } catch (e) {
      let errorMessage = e.message;
      if (e.response) {
        errorMessage = e.response.data.split('\n')[0] || e.message;
      }

      errorToaster(errorMessage);
    } finally {
      setIsLoadingDocumentTypeSettings(false);
    }
  };

  const displayAllFormatDocs = (setting: DocumentTypeDigitalDeliverySetting) => {
    if (!setting.shouldShipDigitally) {
      return <div style={{ paddingLeft: '2px' }}>- -</div>;
    }

    return setting.onlyDigital ? (
      <CloseIcon style={{ color: red[800] }} />
    ) : (
      <DoneIcon style={{ color: green[500] }} />
    );
  };

  return (
    <Fragment>
      <DataTable<RowData>
        title="Digital Delivery Settings"
        columns={columns}
        url="/api/digital-delivery-settings"
        onTableRefReceived={refreshFunction => setRefreshDatatable(refreshFunction)}
        actions={() => {
          return [
            {
              icon: () => <MenuOpenIcon color="primary" />,
              tooltip: 'View Document Type Overrides',
              onClick: (event, rowData: RowData) => getDocumentTypeSettings(rowData.id),
            },
            {
              icon: () => <DeleteIcon style={{ color: 'red' }} />,
              tooltip: 'Delete Setting',
              onClick: async (event, rowData: RowData) => setSettingToDelete(rowData),
            },
          ];
        }}
        options={{
          actionsColumnIndex: -1,
        }}
      />

      <Modal
        open={documentTypeDigitalDeliverySettings !== null}
        onClose={() => setDocumentTypeDigitalDeliverySettings(null)}
      >
        <div className={classes.paper}>
          {isLoadingDocumentTypeSettings ? (
            <div className="center-in-parent" style={{ height: '200px', width: '300px' }}>
              <CircularProgress size="40" disableShrink />
            </div>
          ) : (
            <Fragment>
              <h2 className="my2">Document Type Overide Settings</h2>
              {documentTypeDigitalDeliverySettings?.length ? (
                <div>
                  <div className={classes.settings}>
                    <div className="bold">Document Type</div>
                    <div className="bold">Shipping Digitally</div>
                    <div className="bold">All formats (physical/digital)</div>
                  </div>

                  <Divider className={classes.divider} />

                  {documentTypeDigitalDeliverySettings.map(setting => (
                    <div className={classes.settings} key={setting.id}>
                      <div>{setting.documentType}</div>
                      <div>
                        {setting.shouldShipDigitally ? (
                          <Tooltip title="Will be digitally shipped">
                            <DoneIcon style={{ color: green[500] }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Won't be digitally shipped">
                            <CloseIcon style={{ color: red[800] }} />
                          </Tooltip>
                        )}
                      </div>
                      <div>{displayAllFormatDocs(setting)}</div>
                    </div>
                  ))}
                </div>
              ) : (
                <h3 className="df jcc">No Override Settings</h3>
              )}
            </Fragment>
          )}
        </div>
      </Modal>

      <Dialog
        onClose={() => setSettingToDelete(undefined)}
        aria-labelledby="delete-setting-title"
        open={settingToDelete !== undefined}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="delete-setting-title">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h3>
              Deleting Setting for {settingToDelete?.investor} /{' '}
              {settingToDelete?.lender ?? 'Master Setting'}
            </h3>
          </div>
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="delete-setting-dialog-description">
            <span className="db">Are you sure you want to delete this setting?</span>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setSettingToDelete(undefined)}>Cancel</Button>
          <Button
            onClick={async () => {
              await apiPost(`/api/digital-delivery-settings/delete/${settingToDelete?.id}`, {});
              refreshDatatable();
              setSettingToDelete(undefined);
            }}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DigitalDeliverySettingsListing;
