export enum TabName {
  General,
  Contact,
  Digitals,
  Physicals,
  AccountManagers,
  Pods,
  Letters,
}

export interface File {
  type: 'pdf' | 'xls' | 'video';
  title: string;
  src: string;
  tabs: TabName[];
  downloadable?: boolean;
}
