import React, { useState, useReducer, useEffect, useCallback, Reducer } from 'react';
import { apiFetch } from '../adalConfig';

enum ActionType {
  fetchInit = 'FETCH_INIT',
  fetchSuccess = 'FETCH_SUCCESS',
  fetchFailure = 'FETCH_FAILURE',
  refresh = 'REFRESH',
  fetchPrevious = 'FETCH_PREVIOUS',
}

type Action = {
  type: ActionType;
  payload?: any;
};

type State<T> = {
  isLoading: Boolean;
  error: Error | Boolean;
  isSuccess: Boolean;
  data: T;
  prevResult: any | undefined;
};

type Return<T> = State<T> & {
  doFetch: (newUrl: string) => void;
  refresh: () => void;
  refetch: () => void;
};

function dataFetchReducer<T>(state: State<T>, action: Action): State<T> {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, error: false, isSuccess: false, prevResult: undefined };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: false,
        isSuccess: true,
        prevResult: undefined,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        prevResult: undefined,
      };
    case 'REFRESH':
      return {
        ...getInitialState(state.data),
        prevResult: state.isSuccess ? ActionType.fetchSuccess : ActionType.fetchFailure,
      };
    case 'FETCH_PREVIOUS':
      return {
        ...state,
        isSuccess: state.prevResult === ActionType.fetchSuccess,
        error: state.prevResult === ActionType.fetchFailure,
        prevResult: undefined,
      };
    default:
      throw new Error();
  }
}

function getInitialState<T>(initialData: T): State<T> {
  return {
    isLoading: false,
    error: false,
    isSuccess: false,
    data: initialData,
    prevResult: undefined,
  };
}

export default function useGetData<T>(initialUrl: string, initialData: T): Return<T> {
  const [url, setUrl] = useState(initialUrl);

  const [state, dispatch] = useReducer<Reducer<State<T>, Action>>(
    dataFetchReducer,
    getInitialState(initialData),
  );

  const fetchData = useCallback(
    async (didCancel: Boolean) => {
      dispatch({ type: ActionType.fetchInit });

      try {
        const result = await apiFetch(url!);

        if (!didCancel) {
          dispatch({ type: ActionType.fetchSuccess, payload: result.data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: ActionType.fetchFailure, payload: error });
        }
      }
    },
    [url],
  );

  useEffect(() => {
    let didCancel = false;

    url && fetchData(didCancel);

    return () => {
      didCancel = true;
    };
  }, [fetchData, url]);

  const doFetch = (newUrl: string) => {
    if (url === newUrl && state.prevResult) {
      // same url, but state was refreshed
      dispatch({ type: ActionType.fetchPrevious, payload: state.data });
    } else {
      setUrl(newUrl);
    }
  };

  const refetch = useCallback(() => {
    url && fetchData(false);
  }, [fetchData, url]);

  const refresh = useCallback(() => {
    dispatch({ type: ActionType.refresh });
  }, []);

  return { ...state, doFetch, refresh, refetch };
}
