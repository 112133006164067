import React from 'react';

import ErrorPage, { ErrorType } from '../components/ui/ErrorPage';

const error: ErrorType = {
    errorTitle: 'Error ',
    errorName: 403,
    firstNumber: 4,
    lastNumber: 3,
    errorMessage: 'Forbidden: You don\'t have permission to access this page.',
};

export default function Page403() {
    return <ErrorPage errorType={error} />;
}
