/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

import { AuditPointRow } from './AuditModalRow';
import { useAuditFailContext } from './useAuditFailContext';

export default function AuditModalBody() {
  const [{ auditPointCategories }] = useAuditFailContext();
  return (
    <div className="chitzionias">
      <div className="guf">
        {auditPointCategories?.map(category => (
          <div className="cheftza" key={category.id}>
            <div className="cheftza_rosh">{category.categoryName}</div>
            {category.auditPoints.map(auditPoint => (
              <AuditPointRow key={auditPoint.id} auditPoint={auditPoint} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
