/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import DataTable from '../../components/ui/DataTable/DataTable';
import { DrillDownData, ReportType, ShippingDrillDownRowData } from './DetailedShippingReport';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { apiFetch } from '../../adalConfig';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  drillDownData: DrillDownData | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '1200px',
      maxHeight: '90%',
      overflow: 'auto',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

const ShippingDrillDown = ({ drillDownData }: Props) => {
  const [reportData, setReportData] = useState<ShippingDrillDownRowData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getDrillDownData = async () => {
      setIsLoading(true);
      const { data } = await apiFetch<ShippingDrillDownRowData[]>(
        `/api/reports/shipping-drill-down-report?id=${drillDownData?.id}&idType=${
          drillDownData?.reportType === ReportType.Investor ? 2 : 0
        }`,
      );
      setReportData(data);
      setIsLoading(false);
    };

    getDrillDownData();
  }, []);

  const classes = useStyles();

  const shippingDrillDownColumns = [
    {
      field: 'loanNumber',
      title: 'Loan Number',
    },
    {
      field: 'barcode',
      title: 'Barcode',
    },
    {
      field: 'docType',
      title: 'Document Type',
    },
    {
      field: 'format',
      title: 'Format',
    },
    {
      field: 'requiresOriginal',
      title: 'Original only',
      render: ({ requiresOriginal }: ShippingDrillDownRowData) => (requiresOriginal ? 'Yes' : 'No'),
    },
    {
      field: 'dateUploaded',
      title: 'Available For Shipping Since',
      render: (rowData: ShippingDrillDownRowData) =>
        new Date(rowData.dateUploaded).toLocaleDateString(),
    },
    {
      field: 'client',
      title: 'Client',
      hidden: drillDownData?.id !== 0 && drillDownData?.reportType === ReportType.Investor,
    },
    {
      field: 'investor',
      title: 'Investor',
      hidden: drillDownData?.id !== 0 && drillDownData?.reportType === ReportType.Client,
    },
  ];

  return (
    <div className={classes.paper}>
      {isLoading ? (
        <div className="center-in-parent">
          <CircularProgress disableShrink size={60} />
        </div>
      ) : (
        <DataTable<ShippingDrillDownRowData>
          title={
            <div>
              <p>
                {drillDownData?.reportType === ReportType.Investor ? 'Investor' : 'Client'} -
                Drilldown Report
              </p>
              <p>
                <span>
                  <b>{drillDownData?.name}</b>
                </span>
              </p>
            </div>
          }
          columns={shippingDrillDownColumns}
          data={reportData}
          options={{
            search: false,
            exportFileName: 'Shipping drilldown report',
          }}
        />
      )}
    </div>
  );
};

export default ShippingDrillDown;
