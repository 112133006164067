/** @jsx jsx */
import { Component } from 'react';
import { jsx } from '@emotion/core';
import ShortId from 'shortid';

import Checkbox from '../../../ui/Checkbox';
import ClientHeader from './ClientHeader';
import ErrorRow from './ErrorRow';
import { missingDataStyle, errorHeaderWrap } from './css_index';
import { apiPost } from '../../../../adalConfig';
import { SelectFunctionality } from '../../../../Utils';
import LoanErrorNoteModal from './LoanErrorNoteModal';
import { LoanErrorClient } from '../../../../pages/LoanErrorIndex';
import { Client } from '../../../../globalTypes/objects';

const SHOW_ERROR = 10000;

type ClientSectionProps = {
  clientWithSelection: boolean;
  setClientWithSelection: (client: number) => any;
  refresh: () => any;
  onLoanErrorDeleted: (id: number) => any;
  showNoteUndo: (noteIds: number[]) => any;
  client: LoanErrorClient;
};
type ClientSectionState = {
  selected: Set<number>;
  lastSelected?: number;
  addNote?: boolean;
  errorMessage?: string;
  emailMessage?: boolean;
};
export default class ClientSection extends Component<ClientSectionProps, ClientSectionState> {
  state = {
    selected: new Set<number>(),
    lastSelected: undefined,
    addNote: false,
    errorMessage: undefined,
    emailMessage: undefined,
  };

  componentWillReceiveProps(nextProps: ClientSectionProps) {
    if (!nextProps.clientWithSelection) {
      this.setState({ selected: new Set() });
    }
  }

  onErrorSelect = async (e, loanErrorId, loanErrorIds) => {
    this.setState({ errorMessage: undefined });
    const { selected, lastSelected } = this.state;
    const newSelected = SelectFunctionality(e, loanErrorId, loanErrorIds, selected, lastSelected);
    this.setState({ selected: newSelected, lastSelected: loanErrorId });
    const { setClientWithSelection } = this.props;
    setClientWithSelection(this.state.selected.size);
  };

  onAllErrorSelect = async loanErrors => {
    this.setState({ errorMessage: undefined });
    const { selected } = this.state;
    if (selected.size === loanErrors.length) {
      await this.setState({ selected: new Set() });
    } else {
      await this.setState({ selected: new Set(loanErrors.map(l => l.loanErrorId)) });
    }
    const { setClientWithSelection } = this.props;
    setClientWithSelection(this.state.selected.size);
  };

  onAddNoteClick = () => {
    const { selected } = this.state;
    selected.size
      ? this.setState({ addNote: true })
      : this.setState({ errorMessage: 'No loan errors selected' });
  };

  onDeleteErrorClick = () => {
    const { refresh, onLoanErrorDeleted } = this.props;
    const { selected } = this.state;
    const loanErrorIds = Array.from(selected);
    loanErrorIds.forEach(id => {
      apiPost(`/api/loanErrors/delete/${id}`, {})
        .then(() => {
          onLoanErrorDeleted(id);
          refresh();
        })
        .catch(e => {
          refresh();
          throw e;
        });
    });
  };

  handleNoteClose = async (noteIds: number[]) => {
    this.setState({ addNote: false });
    const { showNoteUndo, refresh } = this.props;
    refresh();
    showNoteUndo(noteIds);
  };

  onEmailClick = async () => {
    const { selected, emailMessage } = this.state;
    const { loanErrors } = this.props.client;
    if (emailMessage) {
      return;
    }
    if (!selected.size) {
      this.setErrorMessage('No loan errors selected');
      return;
    }
    if (loanErrors.some(l => selected.has(l.loanErrorId) && l.emailSent)) {
      this.setErrorMessage('Email already sent for selected loans');
      return;
    }
    this.setState({ emailMessage: true });
    const loanErrorIds = [...selected];
    const { client } = this.props;
    await apiPost('/api/loanErrors/sendEmail', {
      loanErrorIds,
      client: client.client,
    });
    this.props.refresh();
    this.setState({ selected: new Set<number>(), emailMessage: false });
  };

  setErrorMessage = errorMessage => {
    this.setState({ errorMessage });
    setTimeout(() => this.setState({ errorMessage: undefined }), SHOW_ERROR);
  };

  render() {
    const { selected, addNote, errorMessage, emailMessage } = this.state;
    const { loanErrors = [], client = {} as Client } = this.props.client;
    const sortedLoans = loanErrors.reduce(
      (acc, loanError) => (loanError.isPinned ? [loanError, ...acc] : [...acc, loanError]),
      [],
    );
    return (
      <div
        css={{
          marginTop: 66,
          '&:first-child': {
            marginTop: 40,
          },
        }}
      >
        <ClientHeader
          client={client}
          onAddNoteClick={this.onAddNoteClick}
          onEmailClick={this.onEmailClick}
          onDeleteErrorClick={this.onDeleteErrorClick}
          errorMessage={errorMessage}
          showActions
          emailMessage={emailMessage}
        />
        <div css={errorHeaderWrap}>
          <div css={{ width: 24, marginRight: 16 }}>
            <Checkbox
              onClick={() => this.onAllErrorSelect(sortedLoans)}
              checked={selected.size === sortedLoans.length}
            />
          </div>
          <div css={[missingDataStyle, { fontSize: 14 }]}>Missing data</div>
          <div css={{ width: 224 }}>Spreadsheet</div>
          <div css={{ width: 200 }}>Loan Number</div>
          <div css={{ width: 215 }}>Last Note</div>
        </div>
        {sortedLoans.map((loanError, index) => (
          <ErrorRow
            key={ShortId.generate()}
            loanError={loanError}
            index={index}
            isSelected={selected.has(loanError.loanErrorId)}
            onSelect={e =>
              this.onErrorSelect(
                e,
                loanError.loanErrorId,
                sortedLoans.map(l => l.loanErrorId),
              )
            }
          />
        ))}

        {addNote && (
          <LoanErrorNoteModal handleClose={this.handleNoteClose} selected={[...selected]} />
        )}
      </div>
    );
  }
}
