/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { ReactNode, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MaterialAlert from '@mui/material/Alert';
import { AlertTitle } from '@mui/material';
import { Alert as AlertType, AlertSeverity } from '../../globalTypes/objects';

type Props = {
  alertData: AlertType;
  position?: AlertPosition;
};

export const defaultAlertData: AlertType = {
  isOpen: false,
  message: '',
  severity: AlertSeverity.Info,
};

type AlertPosition = {
  vertical: 'bottom' | 'top';
  horizontal: 'center' | 'left' | 'right';
};

const Alert = ({ alertData, position = { vertical: 'top', horizontal: 'right' } }: Props) => {
  const [alert, setAlert] = useState<AlertType>(defaultAlertData);

  useEffect(() => {
    setAlert(alertData);
  }, [alertData]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(defaultAlertData);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: position.vertical,
        horizontal: position.horizontal,
      }}
      open={alert.isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <MaterialAlert onClose={handleClose} severity={alert.severity}>
        <AlertTitle style={{ textTransform: 'capitalize' }}>{alert.severity}</AlertTitle>
        {alert.message}
      </MaterialAlert>
    </Snackbar>
  );
};

export default Alert;

export type AlertMessage = string | ReactNode;

export const successAlert = (message: AlertMessage = '') => ({
  isOpen: true,
  message,
  severity: AlertSeverity.Success,
});

export const errorAlert = (message: AlertMessage = '') => ({
  isOpen: true,
  message,
  severity: AlertSeverity.Error,
});

export const infoAlert = (message: AlertMessage = '') => ({
  isOpen: true,
  message,
  severity: AlertSeverity.Info,
});

export const warningAlert = (message: AlertMessage = '') => ({
  isOpen: true,
  message,
  severity: AlertSeverity.Warning,
});
