/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import PricingStructureDisplayCard from './PricingStructureDisplayCard';
import { EditTwoIcon } from '../../components/ui/icons';
import { ActionButton } from './cardStyles';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { produce } from 'immer';
import { PricingOption, TierSetting } from './types';
import { apiFetch } from '../../adalConfig';

type Props = {
  isNewClient: boolean;
  pricingOption?: PricingOption;
  setPricingOption: Dispatch<SetStateAction<PricingOption>>;
  tierSettings: TierSetting[];
  setTierSettings: Dispatch<SetStateAction<TierSetting[]>>;
};

const canRemoveTierSetting = (tierSettings: TierSetting[]): boolean => tierSettings.length > 1;

const canAddNewTierSetting = (tierSettings: TierSetting[]): boolean => {
  if (!tierSettings.length) {
    return true;
  }

  const latestEntry = tierSettings[tierSettings.length - 1];
  return !!latestEntry.to && latestEntry.to >= 0;
};

const createTierSetting = (tierSettings: TierSetting[]): TierSetting | null => {
  if (!canAddNewTierSetting(tierSettings)) {
    return null;
  }

  const newStart = tierSettings.length
    ? (tierSettings[tierSettings.length - 1].to ?? 0) + 1
    : undefined;

  return {
    from: newStart,
    to: undefined,
    price: 0,
  };
};

const AddNewTierSetting = (
  tierSettings: TierSetting[],
  setTierSettings: Dispatch<SetStateAction<TierSetting[]>>,
) => {
  return () => {
    const newTierSetting = createTierSetting(tierSettings);
    if (newTierSetting === null) {
      return;
    }

    setTierSettings([...tierSettings, newTierSetting]);
  };
};

const PricingStructure = ({
  isNewClient,
  pricingOption,
  setPricingOption,
  tierSettings,
  setTierSettings,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [pricingOptions, setPricingOptions] = useState<PricingOption[]>([]);

  useEffect(() => {
    const getPricingStructures = async () => {
      const { data } = await apiFetch<PricingOption[]>('/api/clients/pricing-structures');
      setPricingOptions(data);
    };

    getPricingStructures();
  }, []);

  const updateTierFieldValue = (
    newValue: string | undefined,
    index: number,
    field: 'from' | 'to',
  ) => {
    const numericValue = parseInt(newValue ?? '');
    if (!isNaN(numericValue) && numericValue < 0) {
      return;
    }

    setTierSettings(
      produce(tierSettings, draft => {
        draft[index][field] = isNaN(numericValue) ? undefined : numericValue;
      }),
    );
  };

  const updatePrice = (newValue: string | undefined, index: number) => {
    let numericValue = parseFloat(newValue ?? '');
    if (!isNaN(numericValue) && numericValue < 0) {
      return;
    }

    setTierSettings(
      produce(tierSettings, draft => {
        if (isNaN(numericValue)) {
          numericValue = 0;
        }

        draft[index]['price'] = parseFloat(numericValue.toFixed(2));
      }),
    );
  };

  if (!isNewClient && !editMode) {
    return (
      <PricingStructureDisplayCard
        onEditClick={() => setEditMode(!editMode)}
        pricingOption={pricingOption}
        tierSettings={tierSettings}
      />
    );
  }

  return (
    <div css={{ '&&': { padding: 16, position: 'relative' } }}>
      <ActionButton
        onClick={() => setEditMode(!editMode)}
        css={{ position: 'absolute', right: 0, top: 0 }}
      >
        <EditTwoIcon />
      </ActionButton>

      <div>
        <div style={{ fontWeight: 'bold' }}>Pricing Structure</div>
        <Autocomplete
          options={pricingOptions}
          getOptionLabel={(option: PricingOption) => option?.value || pricingOptions[0].value}
          onChange={(event: React.ChangeEvent<{}>, newValue: PricingOption) => {
            setPricingOption(newValue);
          }}
          value={pricingOption || pricingOptions[0]}
          style={{ width: '300px', marginTop: '16px' }}
          autoHighlight
          renderInput={params => (
            <TextField {...params} label="Pricing Structure Options" variant="outlined" />
          )}
        />
      </div>
      {pricingOption?.value === 'Tiered Pricing' && (
        <div>
          {tierSettings.map((tierSetting, i) => (
            <Fragment key={i}>
              <div style={{ fontWeight: 'bold', marginTop: '16px' }}>
                Tier {i + 1} - Loan Volume
              </div>
              <div className="df jcfs aic" style={{ gap: '50px', marginTop: '16px' }}>
                <TextField
                  label="From"
                  type="number"
                  value={tierSetting.from?.toString() ?? ''}
                  onChange={e => updateTierFieldValue(e.target.value, i, 'from')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="To"
                  type="number"
                  value={tierSetting.to?.toString() ?? ''}
                  onChange={e => updateTierFieldValue(e.target.value, i, 'to')}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Price Per Loan"
                  type="number"
                  value={tierSetting.price?.toString() ?? ''}
                  onChange={e => updatePrice(e.target.value, i)}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
                <IndeterminateCheckBoxIcon
                  style={{ cursor: 'pointer' }}
                  fontSize="large"
                  color={canRemoveTierSetting(tierSettings) ? 'action' : 'disabled'}
                  onClick={() =>
                    canRemoveTierSetting(tierSettings) &&
                    setTierSettings(tierSettings.filter((setting, index) => index !== i))
                  }
                />
                {tierSettings.length - 1 === i && (
                  <AddBoxIcon
                    style={{ cursor: 'pointer' }}
                    fontSize="large"
                    color={canAddNewTierSetting(tierSettings) ? 'action' : 'disabled'}
                    onClick={AddNewTierSetting(tierSettings, setTierSettings)}
                  />
                )}
              </div>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default PricingStructure;
