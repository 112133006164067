import React from 'react';
import Select, { components } from 'react-select';

import { containerStyles, inputStyles, itemStyles, openMenuStyles } from './css';

const customStyles = {
  control: (styles, { selectProps: { isError } }) => ({
    ...styles,
    ...inputStyles(isError),
  }),
  container: (styles, { isFocused, error }) => ({
    ...styles,
    ...containerStyles(isFocused, error),
  }),
  menu: () => ({
    // This removes original library style
  }),
  menuList: () => openMenuStyles,
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ...itemStyles(isFocused, isSelected),
  }),
};

const Option = ({ children, ...props }) => (
  <components.Option {...props}>{children}</components.Option>
);

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>{data.label}</components.SingleValue>
);

const selectProps = props => ({
  components: {
    IndicatorSeparator: null,
    Option: props.Option || Option,
    SingleValue: props.SingleValue || SingleValue,
  },
  styles: customStyles,
  isSearchable: false,
  ...props,
});

export default function Dropdown(props) {
  return <Select {...selectProps(props)} />;
}
